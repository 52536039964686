import React, { useEffect, useRef, useState } from 'react';
import { BiUser } from 'react-icons/bi';
import { BsCameraVideoFill, BsGlobe2 } from 'react-icons/bs';
import { CgMenuGridO } from 'react-icons/cg';
import { HiArrowRight } from 'react-icons/hi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdClose } from 'react-icons/md';
import { VscThreeBars } from 'react-icons/vsc';
import { Link, useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import './home.css';
import c1 from '../assets/home/c1.png';
import c2 from '../assets/home/c2.png';
import c3 from '../assets/home/c3.png';
import c4 from '../assets/home/c4.png';
import c5 from '../assets/home/c5.png';
import c6 from '../assets/home/c6.png';
import google from '../assets/home/google.png';
import ss from '../assets/home/ss.png';
import t1 from '../assets/home/t1.png';
import t2 from '../assets/home/t2.jpeg';
import t3 from '../assets/home/t3.jpeg';
import t4 from '../assets/home/t4.jpeg';
import t5 from '../assets/home/t5.jpeg';
import t6 from '../assets/home/t6.png';
import tl1 from '../assets/home/tl1.png';
import tl2 from '../assets/home/tl2.png';
import tl3 from '../assets/home/tl3.png';
import tl4 from '../assets/home/tl4.png';
import tl5 from '../assets/home/tl5.svg';
import tl6 from '../assets/home/tl6.png';
import track from '../assets/home/track.png';
import tt from '../assets/home/tt.png';
import GlobNavbar from '../components/GlobNavbar';
import { whitelabelName, whitelabelVideo } from '../utils/branding';
import { useTypewriter } from 'react-simple-typewriter';

const Home = () => {
  const [active, setActive] = useState(1);

  // useEffect(() => {
  //   const s = document.createElement('script');
  //   s.setAttribute('src', `https://embed.tour.video/leasemagnets.js`);
  //   s.setAttribute('async', 'async');
  //   s.setAttribute('defer', 'defer');

  //   s.addEventListener(
  //     'load',
  //     () =>
  //       window.LeaseMagnets &&
  //       window.LeaseMagnets({
  //         uuid: '9a4d7524-71d5-41e3-83aa-6d93b33a64c5',
  //         primaryColor: '#4285f4',
  //       })
  //   );
  //   s.addEventListener('error', (e) => console.error(e));

  //   document.body.appendChild(s);

  //   return () => {
  //     window.destroyLeaseMagnets && window.destroyLeaseMagnets();
  //     document.body.removeChild(s); // Clean up
  //   };
  // }, []);

  useEffect(() => {
    const s = document.createElement('script');
    s.setAttribute('src', `https://embed.tour.video/leasemagnets.js`);
    s.setAttribute('async', 'async');
    s.setAttribute('defer', 'defer');

    s.addEventListener(
      'load',
      () =>
        window.LeaseMagnets &&
        window.LeaseMagnets({
          uuid: '9a4d7524-71d5-41e3-83aa-6d93b33a64c5',
          layout: 'left',
          // primaryColor: '#ff2d55',
          widgetType: 'circle',
        })
    );
    s.addEventListener('error', (e) => console.error(e));

    document.body.appendChild(s);

    return () => {
      window.destroyLeaseMagnets && window.destroyLeaseMagnets();
      document.body.removeChild(s); // Clean up
    };
  }, []);

  const history = useHistory();

  const openScheduleTour = () => {
    window.setLeaseMagnetsOpen(true);
    window.setLeaseMagnetsScre('thank_you.customer_support');
  };

  const communitySliderData = [
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/cc03b26d-c873-4d09-d8e1-517e0e062400/150pxProfileTYG',
      caption: '25k Tours',
      communityName: 'Marshall Louisville',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/bc2ef844-b351-4404-23c5-ca2352fcc000/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/6f8904fc-89d8-4375-2fc2-7c9d6dadf200/150pxProfileTYG',
      caption: '1.4k International Tours',
      communityName: 'Hawks Landing',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/adeba541-555e-44cf-b8f4-64d2158c7d00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/734d98e7-d07f-47a7-20ed-7b500f420b00/150pxProfileTYG',
      caption: '$70k+ Revenue Generated from Tour',
      communityName: 'Campus Walk',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/f579de30-ea39-4214-0612-8f80d34eaa00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/4909e798-3cd4-44ba-509f-f14a9ec01200/150pxProfileTYG',
      caption: '21k Tours',
      communityName: 'Latitude',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/106dc1ed-9f24-4b2a-eaf5-f7a4ab370000/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/e2fcd283-7b3d-4463-c327-fbc3c50f6100/150pxProfileTYG',
      caption: '$85k Revenue Generated',
      communityName: 'Noble 2500',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/1ba9d2e5-baf5-4417-a0d3-c23a48f57a00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/7167ce7e-3135-49a8-0ea4-2a614e967900/150pxProfileTYG',
      caption: '15k Tours + $300k Revenue Generated',
      communityName: 'CastleRock San Marcos',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/64756cb6-51b5-4b38-eda5-79740913ec00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/1d911fb6-23ba-4507-141a-0da95278c900/150pxProfileTYG',
      caption: '3k Tours +  $220k Revenue Generated',
      communityName: 'Paloma West Midtown',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/38bf0294-d7f5-4a2f-4a86-c309b2db9c00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/0f63db51-d9f6-420f-8eba-759fd0073100/150pxProfileTYG',
      caption: '7.4k Tours + $850k Revenue Generated',
      communityName: 'One Park',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/1e5b8871-d023-4b4c-bdc2-d7d8a0683400/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/3c550765-aae7-4a77-2507-7f66e635b000/150pxProfileTYG',
      caption: '18k Tours',
      communityName: 'Lodges at 777',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/ac58e77c-37da-42ff-8898-0cba57e6af00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/b01ef428-6a14-4661-4b0b-4d3c9f04e600/150pxProfileTYG',
      caption: '12k Tours + $550k Revenue Generated',
      communityName: 'HWH Luxury Living',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/1fa1af12-4aa5-4820-49ef-3a3bcbdebe00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/e1d7b510-f60a-4b43-c83d-2146ad4e3a00/150pxProfileTYG',
      caption: '125% increase in conversion',
      communityName: 'Douglas Heights',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/98a44ab2-8726-4204-c056-694f926b9600/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/41105640-d9a0-46e9-b7f4-287dba728300/150pxProfileTYG',
      caption: '150% increase in conversion',
      communityName: 'Arba',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/a4849728-6920-4ef4-c889-e29365a86500/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/ff27792c-3d5f-4ec8-f42d-7b91dd582f00/150pxProfileTYG',
      caption: '$129k in Revenue generated',
      communityName: 'The Rowan',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/b731c164-2f1f-475b-0d41-fce2f08b2a00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/fe586b4f-4e04-420c-7978-b9a9f361e700/150pxProfileTYG',
      caption: '120% Conversion Rate',
      communityName: 'The Rockland',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/b731c164-2f1f-475b-0d41-fce2f08b2a00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/25542c0e-6369-40eb-e7b6-53aaa48cd800/150pxProfileTYG',
      caption: '$40k Revenue Generated',
      communityName: 'The Hue',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/2322595b-7320-491c-239d-4248af567300/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/3f316b49-e66a-49bf-237e-61ec9ae9f900/150pxProfileTYG',
      caption: '5.5k Tours + $40k in Leadgen',
      communityName: 'The Academy on Charles',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/411c005f-4415-495f-659e-3dde79202900/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/ff99c810-45b3-4221-90f5-7f72749e3100/150pxProfileTYG',
      caption: '6.2k Tours + $241k in Leadgen',
      communityName: 'The Village at Chandler Crossings',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/c065befd-23d3-41aa-b45e-ad736ceb6700/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/3f36217d-64a7-4206-fe12-c8f321649c00/150pxProfileTYG',
      caption: '6.2k Tours + 340 5-Star Testimonials',
      communityName: 'The Edge on Euclid',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/f19125bc-b789-473d-236f-8c502895d900/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/6acb8d71-74f4-4a8a-ecbf-e3e083661e00/150pxProfileTYG',
      caption: '300+ Applicants',
      communityName: 'Desai Accelerator',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/67d332fd-c506-46fb-40e0-a161d24da800/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/3e1a95d3-e381-45e7-b62a-5bb064736700/150pxProfileTYG',
      caption: '$200k Revenue from Tour',
      communityName: 'HCC College',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/ba63783c-ba12-4505-2593-42feb2ce9700/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/26a7fd6e-0571-474e-85c5-8ab6dd4fcd00/150pxProfileTYG',
      caption: '$240k generated + 340 Reviews',
      communityName: 'Forward Medical',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/cee35afd-a1ce-45ed-d89a-60ad6ad7bd00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/baab8f17-bc67-42a7-520c-842d652afe00/150pxProfileTYG',
      caption: '210% Conversion Rate & Hired 2 Folks',
      communityName: 'Lambda School/ Bloomtech',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/53ea0757-f5f8-4c61-67f3-b7d64e53f300/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/b9d63c8a-cdf3-48b2-9540-055236e5ab00/150pxProfileTYG',
      caption: '150% increase in Conversion',
      communityName: 'PNOE',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/56cd2bef-a390-4a20-6228-d0b3454e2100/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/7a2cff03-6b58-4f53-80fe-c93743be6400/150pxProfileTYG',
      caption: '8k Onboarded',
      communityName: 'DESO',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/b831e49d-f1dd-4b10-4131-3984734e4300/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/b4f16062-a6ac-4a2f-b210-11b3a9611600/150pxProfileTYG',
      caption: '140%+ Onboarding increase + 215.7k Followers',
      communityName: 'Creator.app',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/df398489-f15f-415d-c69a-9b05a182b400/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/24662403-ae4b-4abb-6231-3a4244bb1100/150pxProfileTYG',
      caption: '210% increase in conversion',
      communityName: 'RAMP',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/9be7e2b4-42b0-40b7-b1eb-4f5893488f00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/1c9259ef-1877-4774-ec5e-707eb62f0e00/150pxProfileTYG',
      caption: 'Onboarded 15k new Engineers',
      communityName: 'WeWork',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/09a53245-fe76-4620-2f8e-c45aedced900/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/3a47b3a7-75a1-4142-7ddb-db9c28f43100/150pxProfileTYG',
      caption: '124%+ in Conversion',
      communityName: 'University of Michigan',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/67a6cb75-2399-4794-a589-4fba7e8db300/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/179e0c4a-3ab1-4f91-045d-fcbe4e26b500/150pxProfileTYG',
      caption: 'Onboarded 7k Community',
      communityName: 'SaaStr',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/ededba95-3e75-4d19-4f5a-ac9e128c1a00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/6ebcf654-666c-4462-81f8-88a65b3b6d00/150pxProfileTYG',
      caption: '150% in Conversion + $440k in Sales',
      communityName: 'X Communities',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/d7fde5fe-6428-42b8-b44b-912124a15a00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/b06d761a-2dba-4242-1b00-175602644100/150pxProfileTYG',
      caption: '12k Tours + $440k in Sales',
      communityName: 'Two East Oak',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/fe2cff69-52a8-4a5b-a891-a8f86e13a600/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/9e688e13-1762-45d9-ca59-836284857c00/150pxProfileTYG',
      caption: '150% in Onboarding Conversion',
      communityName: 'YC',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/055141a0-8e56-4a3a-20bd-dd089d3b2b00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/2ce0b833-5605-4d7c-cdc1-20f8fcb60500/150pxProfileTYG',
      caption: '$1.2m in Tour-driven Sales',
      communityName: 'Independence Village Oxford',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/de18508f-0142-4ef2-47d6-96d66ecd6f00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/7a0947d9-1dfe-4645-32a7-44cab66a7c00/150pxProfileTYG',
      caption: '4k Tours and $800k in Tour-driven Sales',
      communityName: 'The George',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/8b6b4e88-4b7f-4c3c-6915-c3a75d9d2600/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/e7361926-fa46-4963-42fe-bf99570cbe00/150pxProfileTYG',
      caption: '2k Tours and $750k in Tour-driven Sales',
      communityName: 'Park Place',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/a202f566-30a4-4b09-972c-1cb221772b00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/06828e5d-6e41-4981-824b-24d7cf61a600/150pxProfileTYG',
      caption: '89%+ in Conversion',
      communityName: 'Speechify',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/806e516a-3bd3-4dee-5385-1577afad3c00/400pxHeight',
      tourLink: '',
    },
  ];

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const ourResultsRefTYG = useRef(null);

  const [person, setPerson] = useState(false);
  React.useEffect(() => {
    let header = document.getElementById('showheader');
    document.getElementById('header_box').onmouseover = function callheader() {
      header.style.display = 'block';
    };

    document.getElementById('showheader').onmouseover = function callheader() {
      header.style.display = 'block';
    };
    document.getElementById('showheader').onmouseout = function callheader() {
      header.style.display = 'none';
    };

    document.getElementById('header_box').onmouseout = function callheader() {
      header.style.display = 'none';
    };
  }, []);

  let testimonials = [
    {
      name: 'Brian Nalezny',
      position: 'Regional Manager, PeakMade',
      review:
        'Tour is easy to work with and extremely receptive. They took my suggestion to add a Mandarin tour option and immediately came back with an updated product for us. We are in a beneficial partnership that has translated to an immediate increase in leads at properties where we use the tool',
      image: t1,
      cimg: tl1,
    },
    {
      name: 'Andrea Arbuckle',
      position: 'Regional Leasing/Marketing Manager, Landmark Properties',
      review:
        "Easy to use and effective. I feel better knowing that I'm in control of who can see my data.",
      image: t2,
      cimg: tl2,
    },
    {
      name: 'Amanda Kopko',
      position: 'McKinney Properties',
      review:
        '...This is what we use Tour for. It helps with the introduction to our website and allows someone browsing apartments at 1 am to feel like they are actually walking in the door. It gives them an in person experience before they even pick up the phone.',
      image: t3,
      cimg: tl3,
    },
    {
      name: 'KrisAnn Kizerr',
      position: 'Director, Pierce Education Properties',
      review:
        '“Tour is a great way to offer a creative solution to that problem. We now have properties where 30-40% of the people that sign have never toured in person. Their first time seeing the property will be move-in day.”',
      image: t4,
      cimg: tl4,
    },
    {
      name: 'David Ramos',
      position: 'DMG Investments',
      review:
        '“Huge fan of what you guys are building and think there’s a lot of fit outside of the property management space. I could see luxury realtors/brokers using the tool!”',
      image: t5,
      cimg: tl5,
    },
    {
      name: 'TjChambers',
      position: 'Chambers REA / EVP at Asset Living',
      review:
        '“These guys are building the tour video digital marketing platform of the future — I’ve recommended them to countless clients already!”',
      image: t6,
      cimg: tl6,
    },
  ];

  // let words2 = ;
  const [text2] = useTypewriter({
    words: ['sales', 'leasing', 'conversion'],
    loop: 0,
    onLoopDone: () => console.log(`loop completed after 3 runs.`),
  });

  const [text1] = useTypewriter({
    words: [
      'customers',
      'leases',
      'sales',
      'referrals',
      'leads',
      'demos',
      'applications',
      'students',
      'signups',
    ],
    loop: 0,
    onLoopDone: () => console.log(`loop completed after 3 runs.`),
  });

  // console.log("mobText", mobText)
  return (
    <div className=" w-full md:min-h-screen relative overflow-x-hidden">
      <NewHeader />
      <div className=" container">
        <div className=" relative w-full h-full mt-10 rounded-2xl overflow-hidden">
          {/* <img src="https://res.cloudinary.com/kingdomkid/image/upload/v1655051910/build_tour_sample_TYG_2_uwvm0a.png"
        className=" relative "
        width="100%"
        style={{ objectFit: 'cover', height: '669.71px' }}
         /> */}

          <div className=" block top-40 md:top-52 left-0 w-full h-full z-20 text-black mt-10">
            <div className=" container  px-3 ">
              {/* <h1 className=" text-4xl text-black font_uber text-left">
                Build a Digital Sales Assistant® that scales & converts
              </h1> */}
              {/* <p  className=" text-black text-sm pt-2">
                  <h2 className="text-lg mt-[-15px] mb-2	">
                    better than your{' '}
                    <span className=" underline">in-person tour</span>!
                  </h2>
                </p> */}

              {/* <iframe
                  // width="50%"
                  style={{ marginRght: '30px', marginTop: '10px', width: '600px', height: '500px' }}
                  className="w-80 mr-30 mt-30 rounded-md hidden lg:block"
                  src="https://embed.tour.video/?uuid=0d8aa956-2d8d-4254-b018-15c1672c5d9a&inline=true&screen=intro.main_intro_2&inlineCTA=Take%20a%20tour&hideButtons=false&tourMsg=undefined&primaryColor=%234399DF"
                /> */}

              <div className="flex flex-col items-center gap-4 lg:gap-8">
                <div className="backer-pill flex items-center pt-3 justify-center py-2  gap-0 rounded-full">
                  {communitySliderData.slice(0, 6).map((item, ind) => (
                    <img
                      className={
                        ind !== 0
                          ? ' w-5 h-5 lg:w-7 lg:h-7 rounded-full -ml-1 '
                          : ' w-5 h-5 lg:w-7 lg:h-7  rounded-full '
                      }
                      src={item?.agent}
                      alt=""
                    />
                    // <div
                    // className={
                    //   ind !== 0
                    //     ? ' w-7 h-7 rounded-full relative overflow-hidden  -ml-1'
                    //     : ' w-7 h-7 overflow-hidden relative rounded-full '
                    // }
                    // >

                    //   <div
                    //     className=" absolute top-0 left-0 w-7 h-full rounded-full z-20"
                    //     style={{ background: 'rgba(0,0,0,0.1)' }}
                    //   ></div>
                    // </div>
                  ))}

                  <p className=" ml-3 lg:ml-4 text-xs  ">
                    Loved by 200+ Communities & Property Managers
                  </p>
                </div>
                <h1
                  className="text-3xl md:text-6xl mt-4 lg:mt-0 hidden lg:block font_uber font-bold leading-snug text-center"
                  style={{
                    maxWidth: '900px',
                    letterSpacing: '2px',
                    lineHeight: '1.35',
                  }}
                >
                  Build a digital sales assistant <br /> that scales your
                  <span
                    className="font_uber font-bold hero-text-blue rounded-md "
                    style={{
                      backgroundColor: '#5c88df',
                      color: 'white',
                      padding: '0 10px',
                      margin: '0 -8px',
                      marginLeft: '0.2rem',
                    }}
                  >
                    {text2}
                  </span>
                </h1>
                <h1
                  className="text-3xl md:text-6xl mt-4 block lg:hidden lg:mt-0 font_uber font-bold leading-snug text-center"
                  style={{
                    maxWidth: '900px',
                    letterSpacing: '2px',
                    lineHeight: '1.4',
                  }}
                >
                  {/* Build a digital sales assistant <br /> that scales your{' '} */}
                  Build an interactive tour that gets you more
                  <span
                    className="font_uber font-bold hero-text-blue rounded-md "
                    style={{
                      backgroundColor: '#5c88df',
                      color: 'white',
                      padding: '0 10px',
                      paddingBottom: '6px',
                      margin: '0 -8px',
                      marginLeft: '0.2rem',
                    }}
                  >
                    {text1}
                  </span>
                </h1>
                <span className="text-gray-500 text-sm lg:text-base text-center">
                  An intelligent way to <b>convert</b> anonymous <b>visitors</b>{' '}
                  into qualified <b>leads</b>
                </span>
                {/* 
      <div className="flex">
        <input type="text email-input p-4" placeholder="Email address"></input>
        <button className="get-started-button">Get Started</button>
      </div> */}

                <div className=" flex items-start lg:items-center lg:gap-3 text-black text-xl font_uber flex-col lg:flex-row">
                  <Link to="/book-demo">
                    <button className=" mt-4 px-3 text-sm py-3 flex items-center gap-2 hover:bg-hvr bg-pr rounded-sm text-white">
                      <BsCameraVideoFill /> Book personalized 1-1 demo
                    </button>
                  </Link>
                  <Link to="/signin">
                    <button className=" mt-4 px-3 font-light text-sm hidden text-black py-3  lg:flex items-center gap-2 hover:bg-gray-100 bg-gray-50 rounded-sm ">
                      <img
                        src={google}
                        className=" w-6 h-6 object-cover"
                        alt=""
                      />{' '}
                      Try Tour for 30 days
                    </button>
                  </Link>
                </div>
              </div>

              <div className=" mt-16 flex items-center gap-4 w-full text-center"></div>
            </div>
          </div>

          {/* <div className=" px-2 block lg:hidden w-full py-10 absolute z-30 -bottom-36 left-0">

<Slider {...settings2}>
              {[1, 2, 4, 5, 6, 7, 8, 8].map((item) => (
                <div className=" relative  overflow-hidden  border rounded-sm w-full">
                  <div className=" relative hvr_parent">
                    <img
                      src="https://assets.website-files.com/6114bc308fac2d36f9df818c/611525f6cd52a76e755963c3_one-park-full-p-500.jpeg"
                      className="min_h_pic hvr_pic"
                      style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                      }}
                      alt=""industries
                    />
                    <div className=" w-full h-full cursor-pointer absolute top-0 left-0 hvr_bg z-10"></div>
                    <p className=" absolute left-0 text-center w-full z-20  text-white text-sm font-light hvr_text ">
                      Lorem ipsum dolor sit amet{' '}
                    </p>
                    <div className=" w-full flex absolute top-1 left-1 z-20 items-center justify-between pl-2 py-1">
                      <div className=" flex items-center gap-2 cursor-pointer text-white">
                        <h5 className=" text-white text-lg ">Card title</h5>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div> */}
        </div>
      </div>

      <div className=" container">{TourFunctions()}</div>
      <div className=" w-full relative h-full lg:mt-10  lg:pt-10   ">
        <div className=" inline-flex gap-2 animation_right">
          {communitySliderData.slice(12).map((item) => (
            <div
              style={{ width: '370px' }}
              className=" overflow-hidden relative  rounded-2xl  h-40"
            >
              <img
                className=" w-full h-full object-cover"
                src={item?.cover}
                alt=""
              />
              <div
                className=" absolute top-0 left-0 w-full h-full rounded-2xl flex items-end gap-3 justify-start p-3"
                // style={{ background: 'rgba(255,255,255,0.1)' }}
                style={{
                  backgroundImage:
                    ' linear-gradient(to top, rgba(0,0,0,0.8) , rgba(0,0,0,.2))',
                }}
              >
                <div className=" flex items-center justify-center gap-3 z-40 ">
                  <img
                    src={item?.agent}
                    className=" w-16 h-16 rounded-md object-cover "
                    alt=""
                  />
                  <div className=" text-white">
                    <h3 className=" font-bold">{item?.communityName}</h3>
                    <p className=" text-sm">{item?.caption}</p>
                  </div>
                </div>
              </div>
              <div
                className=" absolute top-0 left-0 z-20 w-full h-full"
                style={{ background: 'rgba(255,255,255,0.2)' }}
              ></div>
            </div>
          ))}
        </div>
        <div className=" inline-flex gap-2 animation_left">
          {communitySliderData.slice(0, 12).map((item, idx) => (
            <div
              style={{ width: '370px' }}
              className=" overflow-hidden  rounded-2xl relative h-40"
            >
              <img
                className=" w-full h-full object-cover"
                src={item?.cover}
                alt=""
              />
              <div
                className=" absolute top-0 left-0 w-full h-full rounded-2xl flex items-end gap-3 justify-start p-3"
                // style={{ background: 'rgba(0,0,0,0.3)' }}
                style={{
                  backgroundImage:
                    ' linear-gradient(to top, rgba(0,0,0,0.5) , rgba(0,0,0,0))',
                }}
              >
                <div className=" flex items-center justify-center gap-3">
                  <img
                    src={item?.agent}
                    className=" w-16 h-16 rounded-full object-cover"
                    alt=""
                  />
                  <div className=" text-white">
                    <h3 className=" font-bold">{item?.communityName}</h3>
                    <p className=" text-sm">{item?.caption}</p>
                  </div>
                </div>
              </div>
              <div
                className=" absolute top-0 left-0 z-20 w-full h-full"
                style={{ background: 'rgba(255,255,255,0.5)' }}
              ></div>
            </div>
          ))}
        </div>
      </div>
      <div className=" container px-2 lg:px-0  ">
        <div className="  ">
          <div className="  w-full  justify-center flex-wrap hidden lg:flex">
            {/* <Slider {...settings}>
              {[1, 2, 4, 5, 6, 7, 8, 8].map((item) => (
                <div key={item} className=" bg-white p-3 rounded-2xl shadow-sm">
                  <p className=" font-light text-lg text-gray-700">
                    The explanations are clear, the teachers are responsible and
                    friendly, and the homework is real practice.
                  </p>
                  <div className="flex items-center justify-between">
                    <div className=" pt-4 flex items-center gap-3">
                      <img
                        className=" w-10 h-10 rounded-full object-cover"
                        src="https://assets.website-files.com/617fa48948c7ab24b715140e/617fa55023c6a906cc955301_customers_ava-02.jpg"
                        alt=""
                      />
                      <div>
                        <h6 className=" text-gray-800 text-sm">Rob Cuzer</h6>
                        <p className=" text-xs text-gray-600">CEO</p>
                      </div>
                    </div>
                    <img className="  h-10 mt-4 object-cover" src="https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/cb28d891-bf55-4dfb-3117-8483e6eb2300/150pxProfileTYG" alt="" />
                  </div>
                </div>
              ))}
            </Slider> */}
            {/* {testimonials.map((item, ind) => (
              <div
                key={ind}
                className=" bg-white p-3 rounded-2xl shadow-sm w-full"
                style={{ width: '300px' }}
              >
                <p className="  text-sm text-gray-700 h-36 overflow-y-scroll">
                  {item.review}
                </p>
                <div className="flex items-center justify-between">
                  <div className=" pt-4 flex items-center gap-3">
                    <img
                      className=" w-10 h-10 rounded-full object-cover"
                      src={item.image}
                      alt=""
                    />
                    <div>
                      <h6 className=" text-gray-800 text-sm">{item.name}</h6>
                      <p className=" text-xs text-gray-600">{item.position}</p>
                    </div>
                  </div>
                  <img
                    className="  h-10 w-24 mt-4 object-contain"
                    src={item.cimg}
                    alt=""
                  />
                </div>
              </div>
            ))} */}
          </div>
          {/* <div
            // style={{
            //   backgroundImage:
            //     'linear-gradient(180deg, rgba(0, 0, 0, 0) 0, #000000 76.56%)',
            // }}
            className="  py-10    w-full  lg:bg-transparent  left-0 z-20"
          >
            <div className="container relative">
              <h1 className=" text-2xl pt-10 block lg:hidden text-center">
                Build a great tour for:
              </h1>
              <h1 className="  text-2xl pt-10 lg:block hidden">
                Build a great tour for:
              </h1>
              <div className=" py-4 flex items-center gap-5 flex-col lg:flex-row">
                <h1
                  onClick={() => setPerson(false)}
                  className={
                    person
                      ? ' text-lg font_uber text-gray-500 relative cursor-pointer border_parent'
                      : ' text-lg  font_uber cursor-pointer text-black relative '
                  }
                >
                  🏢 👩 In-Person businesses
                  {person === false ? (
                    <div className=" border_bottom absolute bg-black z-20  left-0 -bottom-2"></div>
                  ) : (
                    <div className=" border_hover absolute bg-black z-20  left-0 -bottom-2"></div>
                  )}
                </h1>{' '}x
                <p className=" text-xs text-black">or</p>
                <h1
                  onClick={() => setPerson(true)}
                  className={
                    person
                      ? ' text-lg b font_uber text-black relative '
                      : ' border_parent text-lg b font_uber text-gray-500 relative '
                  }
                >
                  💻👨‍ Digital businesses
                  {person === true ? (
                    <div className=" border_bottom absolute bg-black z-20  left-0 -bottom-2"></div>
                  ) : (
                    <div className=" border_hover absolute bg-black z-20  left-0 -bottom-2"></div>
                  )}
                </h1>
              </div>
              <div
                className={`flex   items-center justify-center relative lg:justify-start flex-wrap w-full  lg:px-0 lg:w-2/3 `}
              >
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === false ? 'button_animation' : ' hidden'
                  }`}
                  onClick={() => {
                    console.log('attempt to open student living tour TYG');
                    window.tempLeaseMagnets({
                      showButton: false,
                      startOpen: true,
                      layout: 'center',
                      primaryColor: '#872322',
                      startScreen: 'intro.main',
                      buttonLabel: 'Take a virtual tour & $250 off',
                      backgroundOpacity: '0.7',
                      desktopWidth: '80',
                      widgetType: 'circle',
                      uuid: '7c54522f-2ee7-451d-a2c6-56edee14c1d9',
                      blockEvents: 'keydown',
                    });
                  }}
                >
                  Student Living
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === false ? 'button_animation' : ' hidden'
                  }`}
                >
                  Restaurants
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === false ? 'button_animation' : ' hidden'
                  }`}
                >
                  Multifamily Apartments
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === false ? 'button_animation' : ' hidden'
                  }`}
                >
                  Senior Living
                </button>

                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === false ? 'button_animation' : ' hidden'
                  }`}
                  onClick={() => {
                    console.log('attempt to open university tour TYG');
                    window.tempLeaseMagnets({
                      showButton: false,
                      startOpen: true,
                      layout: 'center',
                      primaryColor: '#872322',
                      startScreen: 'intro.main',
                      buttonLabel: 'Take a virtual tour & $250 off',
                      backgroundOpacity: '0.7',
                      desktopWidth: '80',
                      widgetType: 'circle',
                      uuid: 'cedee600-ec79-4b1e-a159-892c4fd58761',
                      blockEvents: 'keydown',
                    });
                  }}
                >
                  Universities
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === false ? 'button_animation' : ' hidden'
                  }`}
                >
                  Coworking
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === false ? 'button_animation' : ' hidden'
                  }`}
                >
                  Coliving
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === false ? 'button_animation' : ' hidden'
                  }`}
                >
                  Healthcare
                </button>
              </div>

              <div
                className={`flex relative  items-center  flex-wrap justify-center lg:justify-start w-full  lg:px-0 lg:w-2/3 `}
              >
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === true ? 'button_animation' : ' hidden'
                  }`}
                >
                  E-Learning
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === true ? 'button_animation' : ' hidden'
                  }`}
                >
                  Bootcamps
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === true ? 'button_animation' : ' hidden'
                  }`}
                >
                  E-Commerce
                </button>

                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === true ? 'button_animation' : ' hidden'
                  }`}
                >
                  Product Demo
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === true ? 'button_animation' : ' hidden'
                  }`}
                >
                  Onboarding
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === true ? 'button_animation' : ' hidden'
                  }`}
                >
                  Hiring
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === true ? 'button_animation' : ' hidden'
                  }`}
                >
                  Creators
                </button>
              </div>
            </div>
          </div> */}
        </div>
        {/* ------------- */}

        {/* <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4 items-center my-20">
          <div>
            <h1 data-aos="fade-up" className=" text-5xl font_uber">
              Drag and drop to <br /> build your tour.
            </h1>
            <p data-aos="fade-up" className=" font_uber text-sm pt-3">
              Building your tour is easy as dragging and dropping your
              individual clips and <br /> hitting save.
            </p>
          </div>
          <div className=" relative ">
            <img
              src={drag_top}
              style={{ objectFit: 'contain', height: '100%', width: '100%' }}
              alt=""
            />
            <img
              src={drag}
              style={{ objectFit: 'contain', height: '100%', width: '100%' }}
              className=" absolute transform transition ease-linear duration-500 hover:scale-105  bottom-0 left-0 z-10"
              alt=""
            />
          </div>
        </div> */}

        {/* <div className=" grid grid-cols-1 lg:grid-cols-2  lg:gap-4 items-center ">
          <div id="else">
            <h1 data-aos="fade-up" className=" text-5xl font_uber">
              Track the analytics <br /> behind each tour
            </h1>
            <p data-aos="fade-up" className=" font_uber text-sm pt-3">
              Register every tour click as actionable data for you to make
              marketing <br /> decisions as well. Yes like Segment but video
              tours.
            </p>
          </div>
          <div className=" relative ">
            <img
              src={track}
              style={{ objectFit: 'contain', height: '100%', width: '100%' }}
              alt=""
            />
            <div className=" absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <video
                className=" relative "
                style={{
                  objectFit: 'cover',
                  height: '520px',
                  width: '340px',
                  borderRadius: '20px',
                }}
                loop={true}
                autoPlay={true}
                muted={true}
              >
                <source
                  src="https://assets.website-files.com/5e45b17b74507185e599a039/5f95fe348b955b74d1fb8704_commons-intro-transcode.webm"
                  type="video/mp4"
                />
              </video>
              <div className=" absolute top-20 left-0 h-full w-full z-20 flex flex-col gap-4 items-center justify-center">
                <button
                  className=" px-5 py-3 text-xl font-bold text-white rounded-3xl cursor-pointer home_border"
                  style={{ background: 'rgba(0,0,0,0.2)' }}
                >
                  {' '}
                  <span
                    style={{ background: '#df4343' }}
                    className=" px-2 py-1 rounded-full"
                  >
                    A
                  </span>{' '}
                  Live Example
                </button>
                <button
                  className=" px-5 py-3 text-xl font-bold text-white rounded-3xl cursor-pointer home_border"
                  style={{ background: 'rgba(0,0,0,0.2)' }}
                >
                  {' '}
                  <span
                    style={{ background: '#df4343' }}
                    className=" px-2 py-1 rounded-full"
                  >
                    B
                  </span>{' '}
                  How It Works
                </button>
                <button
                  className=" px-5 py-3 text-xl font-bold text-white rounded-3xl cursor-pointer home_border"
                  style={{ background: 'rgba(0,0,0,0.2)' }}
                >
                  {' '}
                  <span
                    style={{ background: '#df4343' }}
                    className=" px-2 py-1 rounded-full"
                  >
                    C
                  </span>{' '}
                  <Link to="#" onClick={() => openScheduleTour()}>
                    {' '}
                    Book A Demo{' '}
                  </Link>
                </button>
              </div>
              <div className=" rounded-full hidden lg:block w-44 h-44 overflow-hidden absolute bottom-10 right-10 z-30 border-pr border-4">
                <video
                  className=" relative "
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                  }}
                  loop={true}
                  autoPlay={true}
                  muted={true}
                >
                  <source
                    src="https://assets.website-files.com/5e45b17b74507185e599a039/5f95fe348b955b74d1fb8704_commons-intro-transcode.webm"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div className=" rounded-full block lg:hidden w-24 h-24 overflow-hidden absolute top-0 left-32 z-30 border-pr border-4">
                <video
                  className=" relative "
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                  }}
                  loop={true}
                  autoPlay={true}
                  muted={true}
                >
                  <source
                    src="https://assets.website-files.com/5e45b17b74507185e599a039/5f95fe348b955b74d1fb8704_commons-intro-transcode.webm"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4 items-start my-10">
          <div>
            <h1 data-aos="fade-up" className=" text-5xl font_uber">
              Set the standard <br /> for virtual tours.
            </h1>
            <p data-aos="fade-up" className=" font_uber text-sm pt-3">
              The best-in-class asset owners and property managers trust us with
              their <br /> virtual leasing process.
            </p>
          </div>
          <div className=" grid grid-cols-2 gap-2 lg:gap-8 ">
            <div>
              <div className=" h-80 overflow-hidden rounded-xl">
                <img
                  className=" transform hover:scale-110 transition duration-300 ease-linear cursor-pointer"
                  src="https://assets.website-files.com/6114bc308fac2d36f9df818c/611525f6cd52a76e755963c3_one-park-full-p-500.jpeg"
                  style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                  alt=""
                />
              </div>
              <div className=" w-full flex items-center justify-center pt-2">
                <img
                  src="https://assets.website-files.com/6114bc308fac2d36f9df818c/6115221d8fac2db0afe20ef1_opc-logo-header-10.png"
                  style={{ objectFit: 'contain' }}
                  className=" w-32"
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className=" h-80 overflow-hidden rounded-xl">
                <img
                  className=" transform hover:scale-110 transition duration-300 ease-linear cursor-pointer"
                  src="https://assets.website-files.com/6114bc308fac2d36f9df818c/6115264bf1c289a47e7040c0_indepence%20village.jpeg"
                  style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                  alt=""
                />
              </div>
              <div className=" w-full flex items-center justify-center pt-2">
                <img
                  src="https://assets.website-files.com/6114bc308fac2d36f9df818c/611521e5829dd3158899a9cb_indepence-village.png"
                  style={{ objectFit: 'contain' }}
                  className=" w-32"
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className=" h-80 overflow-hidden rounded-xl">
                <img
                  className=" transform hover:scale-110 transition duration-300 ease-linear cursor-pointer"
                  src="https://assets.website-files.com/6114bc308fac2d36f9df818c/61152743829dd3c32999d79a_hwh-luxury-living-topshot.jpeg"
                  style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                  alt=""
                />
              </div>
              <div className=" w-full flex items-center justify-center pt-2">
                <img
                  src="https://assets.website-files.com/6114bc308fac2d36f9df818c/6114eca70aadb7684e9fe904_hwh-logo-5-p-500.png"
                  style={{ objectFit: 'contain' }}
                  className=" w-32"
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className=" h-80 overflow-hidden rounded-xl">
                <img
                  className=" transform hover:scale-110 transition duration-300 ease-linear cursor-pointer"
                  src="https://assets.website-files.com/6114bc308fac2d36f9df818c/611528465c747345a36a8bb9_edge-scene2-p-500.jpeg"
                  style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                  alt=""
                />
              </div>
              <div className=" w-full flex items-center justify-center pt-2">
                <img
                  src="https://assets.website-files.com/6114bc308fac2d36f9df818c/6115211ad2468230384ebd1f_PeakMadeRealEstate-Logo-Horizontal-RGB-01-e1601937465577-p-500.png"
                  style={{ objectFit: 'contain' }}
                  className=" w-32 "
                  alt=""
                />
              </div>
            </div>
          </div>
        </div> */}
        {/* ----------------------Skill ------------------------- */}

        <div
          className=" grid grid-cols-1 lg:grid-cols-3 gap-10 my-10"
          style={{ background: '#fefdfe' }}
        >
          {/* <div className=" relative">
            <h1 className=" text-6xl font_uber pt-20">
              Explore <br />
              Tourversity:
            </h1>
            <div className=" hidden lg:flex items-center bg-white shadow-sm absolute bottom-10 -right-16 z-40 overflow-hidden border ">
              <input
                type="text"
                value={'Examples for Property Management'}
                className=" border-none outline-none bg-white bg-transparent  px-2 w-full h-full text-xl"
              />
              <Link to="/learn">
                <button className=" p-4 text-xl bg-pr hover:bg-hvr text-white">
                  Go
                </button>
              </Link>
            </div>
          </div> */}
          {/* <div
            className=" lg:col-span-2 flex items-center gap-3 lg:gap-9"
            onClick={() => history.push('/learn')}
          >
            <div className=" relative rounded-2xl cursor-pointer">
              <img
                className=" rounded-2xl overflow-hidden object-cover transition-all ease-linear duration-500 cursor-pointer"
                style={
                  active === 1
                    ? { width: '400px', height: '590px' }
                    : { height: '590px', width: '120px' }
                }
                src="https://assets.website-files.com/617fa48948c7ab24b715140e/6183bce7320d030e82c15028_hero_person-1-p-500.jpeg"
                alt=""
              />
              {active === 1 && (
                <div
                  style={{
                    backgroundImage:
                      ' linear-gradient(to top, rgba(0,0,0,0.5) , rgba(0,0,0,0))',
                  }}
                  className=" absolute top-0 z-10 left-0 w-full h-full flex items-center flex-col justify-end gap-10"
                >
                  <div className=" lg:flex items-center gap-10 pb-20 hidden">
                    <h1 className=" text-white text-4xl font_uber">
                      Property
                      <br />
                      Management
                    </h1>
                    <div>
                      <h1 className=" text-white text-4xl font_uber">20</h1>
                      <p className=" text-sm text-center text-white font_uber">
                        Examples
                      </p>
                    </div>
                  </div>
                  <div className=" flex items-center flex-col gap-10 pb-20 lg:hidden">
                    <h1 className=" text-white text-4xl font_uber text-center">
                      Student & Senior
                      <br />
                      Living
                    </h1>
                    <div>
                      <h1 className=" text-white text-4xl font_uber">10+</h1>
                      <p className=" text-sm text-center text-white font_uber">
                        Examples
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {active !== 1 && (
                <div
                  onMouseOver={() => setActive(1)}
                  style={{
                    backgroundImage:
                      ' linear-gradient(to top, rgba(0,0,0,0.5) , rgba(0,0,0,0))',
                  }}
                  className=" absolute  top-0 z-10 left-0 w-full h-full flex items-center flex-col justify-end gap-10 pb-10"
                >
                  <div
                    style={{ background: '#1e1e2f' }}
                    className=" flex items-center gap-10 w-full pr-10  h-28 pb-10"
                  >
                    <h1 className=" text-white  text-2xl lg:text-4xl font_uber transform rotate-90 -pl-10">
                      Property
                    </h1>
                  </div>
                </div>
              )}
            </div>

            <div className=" relative cursor-pointer">
              <img
                className=" rounded-2xl object-cover transition-all ease-in-out duration-500 cursor-pointer"
                style={
                  active === 2
                    ? { width: '400px', height: '590px' }
                    : { height: '590px', width: '120px' }
                }
                src="https://assets.website-files.com/617fa48948c7ab24b715140e/6183bce3e584104be2e330e0_hero_person-2-p-500.jpeg"
                alt=""
              />
              {active === 2 && (
                <div
                  style={{
                    backgroundImage:
                      ' linear-gradient(to top, rgba(0,0,0,0.5) , rgba(0,0,0,0))',
                  }}
                  className=" absolute top-0 z-10 left-0 w-full h-full flex items-center flex-col justify-end gap-10"
                >
                  <div className=" lg:flex items-center gap-10 pb-20 hidden">
                    <h1 className=" text-white text-4xl font_uber">
                      Multifamily
                    </h1>
                    <div>
                      <h1 className=" text-white text-4xl font_uber">5+</h1>
                      <p className=" text-sm text-center  text-white font_uber">
                        Examples
                      </p>
                    </div>
                  </div>
                  <div className=" flex items-center flex-col gap-10 pb-20 lg:hidden">
                    <h1 className=" text-white text-4xl font_uber">
                      Multifamily
                    </h1>
                    <div>
                      <h1 className=" text-white text-4xl font_uber">8 </h1>
                      <p className=" text-sm text-center  text-white font_uber">
                        Examples
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {active !== 2 && (
                <div
                  onMouseOver={() => setActive(2)}
                  style={{
                    backgroundImage:
                      ' linear-gradient(to top, rgba(0,0,0,0.5) , rgba(0,0,0,0))',
                  }}
                  className=" absolute top-0 z-10 left-0 w-full h-full flex items-center flex-col justify-end gap-10 pb-10"
                >
                  <div
                    style={{ background: '#1e1e2f' }}
                    className=" flex items-center gap-10 w-full  h-28 pb-10"
                  >
                    <h1 className=" text-white text-2xl lg:text-4xl font_uber transform rotate-90">
                      Universities
                    </h1>
                  </div>
                </div>
              )}
            </div>

            <div className=" relative cursor-pointer">
              <img
                className=" rounded-2xl object-cover transition-all ease-in-out duration-500 cursor-pointer"
                style={
                  active === 3
                    ? { width: '400px', height: '590px' }
                    : { height: '590px', width: '120px' }
                }
                src="https://assets.website-files.com/617fa48948c7ab24b715140e/6183bb01e5841050e8e31f3b_hero_person-3-p-500.jpeg"
                alt=""
              />
              {active === 3 && (
                <div
                  style={{
                    backgroundImage:
                      ' linear-gradient(to top, rgba(0,0,0,0.5) , rgba(0,0,0,0))',
                  }}
                  className=" absolute top-0 z-10 left-0 w-full h-full flex items-center flex-col justify-end gap-10"
                >
                  <div className=" lg:flex items-center gap-10 pb-20 hidden">
                    <h1 className=" text-white text-4xl font_uber">
                      University
                    </h1>
                    <div>
                      <h1 className=" text-white text-4xl font_uber">5+</h1>
                      <p className=" text-sm text-center text-white font_uber">
                        Topics
                      </p>
                    </div>
                  </div>
                  <div className=" flex items-center flex-col gap-10 pb-20 lg:hidden">
                    <h1 className=" text-white text-4xl font_uber">
                      University
                    </h1>
                    <div>
                      <h1 className=" text-white text-4xl font_uber">5+</h1>
                      <p className=" text-sm text-center text-white font_uber">
                        Topics
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {active !== 3 && (
                <div
                  onMouseOver={() => setActive(3)}
                  style={{
                    backgroundImage:
                      ' linear-gradient(to top, rgba(0,0,0,0.5) , rgba(0,0,0,0))',
                  }}
                  className=" absolute top-0 z-10 left-0 w-full h-full flex items-center flex-col justify-end gap-10 pb-10"
                >
                  <div
                    style={{ background: '#1e1e2f' }}
                    className=" flex items-center gap-10 w-full  h-28 pb-10"
                  >
                    <h1 className=" text-white text-2xl lg:text-4xl font_uber transform rotate-90">
                      University
                    </h1>
                  </div>
                </div>
              )}
            </div>
          </div> */}
        </div>
        <div className=" grid grid-cols-2 gap-10 md:grid-cols-3 lg:grid-cols-6 py-10 ">
          <img
            src="https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/cb28d891-bf55-4dfb-3117-8483e6eb2300/150pxProfileTYG"
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src="https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/396aaede-5b67-4999-0305-2acb85d49100/150pxProfileTYG"
            style={{
              objectFit: 'contain',
              height: '100%',
              width: '100%',
              maxHeight: '100px',
            }}
            alt=""
          />
          <img
            src="https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/8129369c-e6c0-4739-c38f-0bfd314ac100/150pxProfileTYG"
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src="https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/2112919b-8483-4433-86f2-1f5fce768200/150pxProfileTYG"
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src="https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/df8f61d7-a7dd-4399-cdd4-d4d243947d00/150pxProfileTYG"
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src="https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/433615b9-4d54-4ac8-6c7b-167d003e2f00/150pxProfileTYG"
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
        </div>
      </div>
      <div className=" bg-black mt-20 py-16">
        <h2 className=" text-3xl font-bold pt-2 text-white text-center ">
          Don’t wait for your competitor <br />
          to build a better tour
        </h2>
        <p className=" text-sm pt-2 text-white text-center ">
          Use guided video, CTA’s and special offers, and <br />
          testimonials to increase conversion by 150% today
        </p>
        <div className=" flex  justify-center items-center lg:gap-3 text-white text-xl  font_uber w-full flex-col lg:flex-row">
          <button className=" mt-4 px-3 font-thin text-sm py-3 flex items-center gap-2 hover:bg-hvr bg-pr rounded-sm text-white">
            <BsCameraVideoFill /> Book personalized 1-1 demo
          </button>
          <button className=" mt-4 px-3 font-thin text-sm py-3 flex items-center gap-2 hover:bg-gray-800 bg-black rounded-sm text-white">
            <img src={google} className=" w-6 h-6 object-cover" alt="" /> Build
            your tour for free
          </button>
        </div>
        <div className=" container pt-16 pb-4">
          <div className=" grid grid-cols-1 gap-5 pl-5 lg:pl-0 lg:grid-cols-4">
            <div className=" lg:col-span-2">
              <p className="flex mb-2 text-2xl text-white">Tour </p>
              <p className=" text-gray-400 opacity-40 text-sm flex mb-2">
                Video.pro LLC{' '}
              </p>
              {/* <p className=" text-gray-400 text-sm flex">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_24_57)">
                    <rect width="24" height="24" rx="5.4" fill="#FF5100"></rect>
                    <rect
                      x="0.5"
                      y="0.5"
                      width="23"
                      height="23"
                      rx="4.9"
                      stroke="#FF844B"
                    ></rect>
                    <path
                      d="M7.54102 7.31818H9.28604L11.9458 11.9467H12.0552L14.715 7.31818H16.46L12.7662 13.5028V17.5H11.2349V13.5028L7.54102 7.31818Z"
                      fill="white"
                    ></path>
                  </g>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="4.9"
                    stroke="#FF5100"
                    stroke-opacity="0.1"
                  ></rect>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="4.9"
                    stroke="url(#paint0_radial_24_57)"
                  ></rect>
                  <defs>
                    <radialGradient
                      id="paint0_radial_24_57"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(7.35) rotate(58.475) scale(34.1384)"
                    >
                      <stop stop-color="white" stop-opacity="0.56"></stop>
                      <stop
                        offset="0.28125"
                        stop-color="white"
                        stop-opacity="0"
                      ></stop>
                    </radialGradient>
                    <clipPath id="clip0_24_57">
                      <rect width="24" height="24" rx="5.4" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
                <b className="text-white ml-1 mr-1">YCombinator</b> and{' '}
                <b className="text-white ml-1 mr-1">Zell Founder's Fund</b>
              </p> */}
            </div>
            <div className=" flex flex-col items-start">
              <h6 className=" text-white font-medium font_uber">🏢 Business</h6>
              <Link className=" text-gray-400 text-sm font_uber mt-2" to="/">
                Terms and conditions
              </Link>
              <Link className=" text-gray-400 text-sm font_uber" to="/">
                Reviews
              </Link>
              <Link className=" text-gray-400 text-sm font_uber" to="/">
                Contact
              </Link>
            </div>
            <div className=" flex flex-col items-start">
              <h6 className=" text-white font-medium font_uber">👋 Support</h6>
              <p className=" text-gray-400 text-sm pt-2">
                📞 +1 (586) 258-8588
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function TourFunctions() {
    const [imgActive, setImgActive] = useState([]);

    return (
      <div className=" lg:pt-10 pb-6 px-3 lg:px-0">
        {/* <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 pt-4">
    <div
      onMouseOver={() => setImgActive(0)}
      className={
        imgActive === 0
          ? ' px-3 py-6 border rounded-2xl cursor-pointer'
          : ' px-3 py-6 hover:border cursor-pointer rounded-2xl'
      }
    >
      <button
        className={
          imgActive === 0
            ? ' sm_bg text-pr font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
            : ' bg-gray-100 text-gray-500 font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
        }
      >
        Concierge not Chatbot
      </button>
      <p className=" text-gray-500 text-xs pt-3">
        Deliver a 1:1 personally guided tour with your best leasing
        consultant, right from your homepage.
      </p>
      {imgActive === 0 && (
        <div className="flex items-center justify-between text-sm pt-5 font-medium">
          Start converting more of your visitors
          <HiOutlineArrowRight className="w-5 h-5" />
        </div>
      )}
    </div>
    <div
      onMouseOver={() => setImgActive(3)}
      className={
        imgActive === 3
          ? ' px-3 py-6 border rounded-2xl cursor-pointer'
          : ' px-3 py-6 hover:border cursor-pointer rounded-2xl'
      }
    >
      <button
        className={
          imgActive === 3
            ? ' sm_bg text-pr font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
            : ' bg-gray-100 text-gray-500 font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
        }
      >
        Targeting & Tour Analytics
      </button>
      <p className=" text-gray-500 text-xs pt-3">
        Deliver a 1:1 personally guided tour with your best leasing
        consultant, right from your homepage.
      </p>
      {imgActive === 3 && (
        <div className="flex items-center justify-between text-sm pt-5 font-medium">
          Start converting more of your visitors
          <HiOutlineArrowRight className="w-5 h-5" />
        </div>
      )}
    </div>
    <div
      onMouseOver={() => setImgActive(1)}
      className={
        imgActive === 1
          ? ' px-3 py-6 border rounded-2xl cursor-pointer'
          : ' px-3 py-6 hover:border cursor-pointer rounded-2xl'
      }
    >
      <button
        className={
          imgActive === 1
            ? ' sm_bg text-pr font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
            : ' bg-gray-100 text-gray-500 font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
        }
      >
        Targeting & Tour Analytics
      </button>
      <p className=" text-gray-500 text-xs pt-3">
        Deliver a 1:1 personally guided tour with your best leasing
        consultant, right from your homepage.
      </p>
      {imgActive === 1 && (
        <div className="flex items-center justify-between text-sm pt-5 font-medium">
          Start converting more of your visitors
          <HiOutlineArrowRight className="w-5 h-5" />
        </div>
      )}
    </div>
    <div
      onMouseOver={() => setImgActive(2)}
      className={
        imgActive === 2
          ? ' px-3 py-6 border rounded-2xl cursor-pointer'
          : ' px-3 py-6 hover:border cursor-pointer rounded-2xl'
      }
    >
      <button
        className={
          imgActive === 2
            ? ' sm_bg text-pr font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
            : ' bg-gray-100 text-gray-500 font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
        }
      >
        Targeting & Tour Analytics
      </button>
      <p className=" text-gray-500 text-xs pt-3">
        Deliver a 1:1 personally guided tour with your best leasing
        consultant, right from your homepage.
      </p>
      {imgActive === 2 && (
        <div className="flex items-center justify-between text-sm pt-5 font-medium">
          Start converting more of your visitors
          <HiOutlineArrowRight className="w-5 h-5" />
        </div>
      )}
    </div>
          </div> */}

        {/* <h1 className=" font_uber text-3xl mt-5">
          The modern Tour is changing, and it breaks down into 3 simple steps.
        </h1> */}
        {/* <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 pt-4">
          <div>
            <button className=" sm_bg text-pr font_uber text-sm px-2 font-semibold py-1 rounded-3xl">
              Your Digital Sales Assistant
            </button>
            <p className=" text-gray-500 pt-2">
              Review example tour playbooks and transcripts
            </p>
          </div>
          <div>
            <button className=" sm_bg text-pr font_uber text-sm px-2 font-semibold py-1 rounded-3xl">
              Deliver 4x more tours
            </button>
            <p className=" text-gray-500 pt-2">
              Record your tour, collect your testimonials, and build with a few
              clicks
            </p>
          </div>
          <div>
            <button className=" sm_bg text-pr font_uber text-sm px-2 font-semibold py-1 rounded-3xl">
              AI FAQ and Followup
            </button>
            <p className=" text-gray-500 pt-2">
              Embed your tour or stitch them together like an instagram story to
              create a personalized tour
            </p>
          </div>
          <div>
            <button className=" sm_bg text-pr font_uber text-sm px-2 font-semibold py-1 rounded-3xl">
              Personalized Video
            </button>
            <p className=" text-gray-500 pt-2">
              Share your Tours via a link, embed code or an integration
            </p>
          </div>
        </div> */}

        {/* <div>
        {imgActive === 0 && <img className='transition-opacity ease-in duration-700 opacity-100 "' src={'https://res.cloudinary.com/dlmowqiof/image/upload/v1656515249/Group_113_1_bbf1oc.png'} alt="" />}
        {imgActive === 1 && <img src={'https://res.cloudinary.com/dlmowqiof/image/upload/v1656515023/Group_107_z5avdz.png'} alt="" />}
        {imgActive === 2 && <img src={'https://res.cloudinary.com/dlmowqiof/image/upload/v1656515008/Group_109_emiky0.png'} alt="" />}
        {imgActive === 3 && <img src={'https://res.cloudinary.com/dlmowqiof/image/upload/v1656514993/Group_112_gvajzn.png'} alt="" />}
      </div> */}
      </div>
    );
  }
};

export const NewHeader = () => {
  const [toggle, setToggle] = useState(true);
  const [tactive, setTactive] = useState(false);

  return (
    <div className=" w-full bg-white  relative z-30 border-b-1 border-black">
      <div className="container relative">
        <div className=" items-center justify-between hidden lg:flex">
          <div className=" flex items-center">
            <h1 className="font_uber font-bold py-4 text-2xl cursor-pointer text-black">
              Tour
            </h1>
            <div className=" relative font_uber pl-10 flex items-center justify-center gap-8">
              <Link
                to="/"
                id="header_box"
                className=" flex items-center py-4  relative justify-center gap-1 text-black font-light "
              >
                <CgMenuGridO /> Product
              </Link>
              <Link
                to="/results"
                className=" flex items-center py-4 justify-center gap-1 text-black font-light "
              >
                Results
              </Link>
            </div>
          </div>
          <div className=" flex items-center py-4 gap-4">
            <Link
              to="/"
              className=" flex items-center text-sm justify-center gap-1 text-black font-light "
            >
              <BsGlobe2 /> EN
            </Link>
            <Link
              to="/signin"
              className=" flex items-center text-sm justify-center gap-1 text-black font-light "
            >
              <BiUser />
              Sign In
            </Link>
            <Link
              to="/signup"
              className=" flex items-center hover:bg-gray hover:text-black text-sm justify-center gap-1 bg-black font-light text-white py-2  px-2"
            >
              Sign Up
            </Link>
          </div>
        </div>
        <div id="showheader" className=" absolute w-full  top-16 left-0">
          <GlobNavbar />
        </div>
        {toggle && (
          <div className=" items-center justify-between flex lg:hidden px-2 py-3">
            <div className=" flex items-center">
              <h1 className="font_uber font-bold text-2xl cursor-pointer text-black">
                {whitelabelName()}
              </h1>
            </div>
            <div className=" flex items-center gap-2">
              <Link
                to="/"
                className=" flex items-center text-sm justify-center gap-1 text-black font-light "
              >
                <BsGlobe2 className=" cursor-pointer text-black w-5 h-5" />
              </Link>
              <Link
                to="/signin"
                className=" flex items-center text-sm justify-center gap-1 text-blackblack font-light "
              >
                <BiUser className=" cursor-pointer text-black w-5 h-5" /> Sign
                In
              </Link>
              <Link
                to="/signup"
                className=" flex items-center rounded-3xl hover:bg-white hover:text-black text-xs justify-center gap-1 bg-white font-light text-black py-2  px-2"
              >
                Sign Up
              </Link>
              {toggle ? (
                <VscThreeBars
                  onClick={() => setToggle(false)}
                  className=" cursor-pointer text-black w-5 h-5"
                />
              ) : (
                <MdClose
                  onClick={() => setToggle(true)}
                  className=" cursor-pointer text-black w-5 h-5"
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div
        style={
          !toggle
            ? {
                height: '100vh',
                borderTop: '1px solid rgba(255,255,255,0.3)',
                background: '#000',
                overflowY: 'scroll',
                zIndex: '9999999999',
              }
            : { height: '0px', overflow: ' hidden' }
        }
        className="fixed top-0  bg-black left-0 transition-all  ease-linear   duration-300  w-full block lg:hidden"
      >
        {toggle === false && (
          <div className=" items-center justify-between flex lg:hidden px-2 py-3">
            <div className=" flex items-center">
              <h1 className="font_uber font-bold text-2xl cursor-pointer text-white">
                {whitelabelName()}
              </h1>
            </div>
            <div className=" flex items-center gap-2">
              <Link
                to="/"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BsGlobe2 className=" cursor-pointer text-black w-5 h-5" />
              </Link>
              <Link
                to="/signin"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BiUser className=" cursor-pointer text-black w-5 h-5" />
              </Link>
              <Link
                to="/signup"
                className=" flex items-center rounded-3xl hover:bg-white hover:text-black text-xs justify-center gap-1 bg-white font-light text-black py-2  px-2"
              >
                Sign Up
              </Link>
              {toggle ? (
                <VscThreeBars
                  onClick={() => setToggle(false)}
                  className=" cursor-pointer text-black w-5 h-5"
                />
              ) : (
                <MdClose
                  onClick={() => setToggle(true)}
                  className=" cursor-pointer text-black w-5 h-5"
                />
              )}
            </div>
          </div>
        )}
        <div className=" px-5">
          <div
            onClick={() => setTactive(!tactive)}
            className=" flex cursor-pointer items-center justify-between w-full pb-3 text-3xl     gap-1 text-white font_uber pt-10"
          >
            Product
            {tactive === false ? <IoIosArrowDown /> : <IoIosArrowUp />}
          </div>
          {tactive && (
            <div className=" w-full mt-3">
              <div className=" w-full  shadow-2xl h-full flex flex-col py-4 gap-10">
                <div className=" lg:pl-20">
                  <h6 className=" font_cwb text-sm font-semibold text-white">
                    USE CASES
                  </h6>
                  <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                    <img src={tt} className=" w-7 h-7 object-cover" alt="" />
                    <div>
                      <h6 className=" font_cwb text-sm text-white">
                        Tour & Triggers{' '}
                      </h6>
                      <p className="text-xs text-gray-200">
                        Scale your best in-person tour 24/7 on your website
                      </p>
                    </div>
                  </div>
                  <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                    <img
                      src="https://www.intercom.com/_next/static/images/solutions--engagement-bc48a36991ab0b915c148bd61c6c1d06.svg"
                      className=" w-7 h-7 object-cover"
                      alt=""
                    />
                    <div>
                      <h6 className=" font_cwb text-sm text-white">
                        Trust & Testimonials
                      </h6>
                      <p className="text-xs text-gray-200">
                        Build more trust through testimonials
                      </p>
                    </div>
                  </div>
                  <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                    <img
                      src="https://www.intercom.com/_next/static/images/solutions--marketing-0fc6ec0aeab50485489ad1f6da1a1e5d.svg"
                      className=" w-7 h-7 object-cover"
                      alt=""
                    />
                    <div>
                      <h6 className=" font_cwb text-sm text-white">
                        Targeting & Retargeting
                      </h6>
                      <p className="text-xs text-gray-200">
                        Create targeted specials and online campaigns
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" lg:pl-20">
                  <h6 className=" font_cwb text-sm font-semibold text-white">
                    LEARN
                  </h6>
                  <h6 className=" font_cwb text-sm text-white mt-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                    <Link to="https://tourversity.com"> Welcome Videos</Link>
                  </h6>
                  <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                    <Link to="https://tourversity.com"> Tour Videos</Link>
                  </h6>
                  <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                    <Link to="https://tourversity.com"> Analytics</Link>
                  </h6>
                  <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                    <Link to="https://tourversity.com"> Retargeting</Link>
                  </h6>
                  <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                    <Link to="https://tourversity.com">
                      {' '}
                      CTA’s and Triggers
                    </Link>
                  </h6>
                  <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                    <Link to="https://tourversity.com"> Integrations</Link>
                  </h6>
                </div>
                <div className=" lg:pl-20">
                  <h6 className=" font_cwb text-sm font-semibold text-white">
                    INDUSTRIES
                  </h6>
                  <h6 className=" font_cwb text-sm text-white mt-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                    <Link to="/home"> Real Estate</Link>
                  </h6>
                  <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                    <Link to="/"> Universities</Link>
                  </h6>
                  <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                    <Link to="/"> Senior living & Healthcare</Link>
                  </h6>
                </div>

                <div className=" lg:pl-20">
                  <h6 className=" font_cwb text-sm font-semibold text-white">
                    WATCH A DEMO
                  </h6>
                  <div className=" mt-3">
                    <img src={ss} className=" h-20 object-cover " alt="" />
                  </div>
                  <p className=" text-gray-200 text-sm py-2">
                    Learn from a professional what makes <br />
                    Tour work behind the scenes
                  </p>
                  <h6 className=" font_cwb text-sm text-white mt-2 transform font-medium flex items-center gap-24 transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                    <Link to="#" onClick={() => openScheduleTour()}>
                      {' '}
                      BOOK A DEMO
                    </Link>
                    <HiArrowRight className=" w-5 h-5" />
                  </h6>
                </div>
              </div>
            </div>
          )}
          <Link
            style={{ borderTop: '1px solid rgba(255,255,255,0.3)' }}
            to="/results"
            className=" flex items-center  text-3xl d justify-between pt-3  gap-1 text-white font_uber "
          >
            Results
            <p></p>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Home;
