import TextField from '@material-ui/core/TextField';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Header } from '../../components';
import CardForm from '../../components/billing/StripeCharge';
import FundRequestslist from '../../components/FundRequestslist';
import { AccessLevelFunction } from '../../components/Reusable/AccessLevelWrapper';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import RewardsTable from '../../components/RewardsTable';
import * as loader from '../../lottie/loader.json';
import { API_HOST } from '../../utils/request';
// ReactCanvasConfetti methods //
const filter = createFilterOptions();
function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}
const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
};
function getAnimationSettings(originXA, originXB) {
  return {
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: 0,
    particleCount: 150,
    origin: {
      x: randomInRange(originXA, originXB),
      y: Math.random() - 0.2,
    },
  };
}
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
// ReactCanvasConfetti methods //

const Rewards = ({
  name,
  community_id,
  analytics = true,
  activity = true,
  url,
}) => {
  const [showstripeform, setshowstripeform] = useState(false);
  const [credits, setcredits] = useState('');
  const [stripeloading, setstripeloading] = useState(false);
  const { magnetData } = useSelector((state) => state.getMagnet);
  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret:
  //     'sk_test_51K5VDmCgQX5AncW3KeM1ThhJk92tTxU6wJAd4Rb72wt8WZGpOE0Gl1k4s0etiyccH7oxBnr9moVangnsDGZ8Sw0500Gaz9tZNx',
  // };
  const stripe = useStripe();
  const elements = useElements();
  const params = useParams();
  console.log('params', params);

  const [rewardCheck, setRewardCheck] = useState('Pending');
  const [pendingcheck, setpendingcheck] = useState('Pending');
  const [openCreditDialog, setOpenCreditDialog] = useState(false);
  const [selectedgiftTemplate, setSelectedGiftTemplate] = useState({
    name: 'Food delivery, groceries, or quick snack on us',
    description:
      'Perfect for testimonials, reviews, one-off resident interactions',
    dollars: '$',
    giftbitTemplateId: 'HKDFJPCBIMEP',
    coverImage: require('../../assets/rewards-templates/reward-1.png').default,
    subjectLinePrefix: 'A back to work bundle',
    bottomImage: 't',
  });
  const [giftingAmount, setGiftAmount] = useState(0);
  const [customAmount, setCustomAmount] = useState(false);
  const [selectedCreditAmount, setSelectedCreditAmount] = useState(0);
  const [enableCreditCustomAmount, setEnableCreditCustomAmount] =
    useState(false);
  const [accesslvl, setacesslvl] = useState();
  const [billingdetails, setbillingdetails] = useState({
    name: '',
    address: '',
    phonenumber: '',
  });
  const [gifttopending, setgifttopending] = useState('');
  const [dialoguestatus, setdialoguestatus] = useState();
  const [selectedrewardId, setselectedrewardId] = useState();
  const [openSendGiftConfirmDialog, setOpenSendGiftConfirmDialog] =
    useState(false);
  const [openSendedGiftDialog, setopenSendedGiftDialog] = useState(false);

  const [page, setPage] = useState(1);

  const [reward, setReward] = useState({
    toName: '',
    toEmail: '',
    fromName: '',
    fromEmail: '',
    subject: ``,
    message: '',
  });
  const [selectedrewarddata, setselectedrewarddata] = useState('');

  var templateSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var gitAmountSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4.2,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
        },
      },
    ],
  };

  var addCreditAmountSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const handleSubmit = async (event) => {
    if (
      !billingdetails.name ||
      !billingdetails.address ||
      !billingdetails.phonenumber ||
      !selectedCreditAmount
    ) {
      return toast('All fields not filled out (properly), please try again?');
    }
    if (event) {
      event.preventDefault();
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      console.log(result.error.message);
    } else {
      processpayment(result.token);
      console.log('stripe_result', result);
      // pass the token to your backend API
    }
  };

  // update credits
  const getcommunity = async () => {
    const res = await axios.get(
      `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
    );
    console.log('response', res);
    console.log('credits', res.data?.payload[0]?.credits);
    if (res.data?.payload[0]?.credits) {
      setcredits(res.data?.payload[0]?.credits);
    } else {
      setcredits(0);
    }
  };

  // shift this to backend
  const postcommunity = async (newcredits) => {
    console.log('newcredits', newcredits);
    const res = await axios.post(
      `https://api.directual.com/good/api/v5/data/community/newCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83`,
      {
        id: community_id,
        credits: newcredits,
      }
    );
    console.log('postres', res);
    getcommunity();
  };
  const managerewardindirectual = async (obj) => {
    console.log('obj', obj);
    const res = await axios.post(
      `https://api.directual.com/good/api/v5/data/request_history/getRequestHistoryByTypeAndCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=206&requestType=reward_request`,
      {
        ...obj,
      }
    );
    getrewardrequestlist();
    console.log('directual_res', res);
    return res;
  };

  const getaccesslevel = async () => {
    const tempAccessLevel = await AccessLevelFunction(community_id);
    setacesslvl(tempAccessLevel);
  };

  useEffect(() => {
    getaccesslevel();
    getcommunity();
  }, []);

  const sendgift = async () => {
    console.log('credentials', reward);
    if (credits < giftingAmount) {
      return toast("You don't have enough credits");
    } else if (!selectedgiftTemplate) {
      return toast('Please select a gifting template');
    }
    const res = await axios.get(
      `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
    );
    console.log('response', res);
    console.log('credits', res.data?.payload[0]?.credits);
    if (res.data?.payload[0]?.credits < giftingAmount) {
      return toast("You don't have enough credits");
    } else {
      const firstname = reward.toName.split(' ')[0];
      const lastname = reward.toName.split(' ')[1];
      const d = new Date();
      let expiry = new Date(d.setDate(d.getDate() + 50))
        .toLocaleString()
        .split(',')[0];
      try {
        const res = await axios.post(`${API_HOST}/send_gift`, {
          uniqueId: Math.floor(Math.random() * 1000 + Date.now()),
          firstname,
          lastname,
          email: reward.toEmail,
          templateId: selectedgiftTemplate.giftbitTemplateId,
          amount: giftingAmount * 100,
          message: reward.message,
          subject: reward.subject,
          expiry: expiry.split('/').reverse().join('-'),
        });
        console.log('gift_res', res);
        if (res?.data?.response?.status == 200) {
          let myInterval = setInterval(nextTickAnimation, 500);
          await postcommunity(credits - giftingAmount);
          console.log({
            ...selectedrewarddata,
            giftbitid: res?.data?.response?.campaign?.uuid,
          });
          await managerewardindirectual({
            id: selectedrewardId,
            rewardId: 'completed',
            data: {
              ...selectedrewarddata,
              giftbitid: res?.data?.response?.campaign?.uuid,
            },
          });
          clearInterval(myInterval);
          setopenSendedGiftDialog(true);
        } else {
          toast(
            `Error! Please try again, ${
              res?.data?.response?.message ? res?.data?.response?.message : ''
            }`
          );
        }
        setcancelexpand(Math.random());
      } catch (error) {
        console.log(error);
      }
    }
  };
  const processpayment = async (token) => {
    try {
      setstripeloading(true);
      const res = await axios.post(`${API_HOST}/update_credits`, {
        token_id: token.id,
        name: billingdetails.name,
        address: billingdetails.address,
        phonenumber: billingdetails.phonenumber,
        amount: selectedCreditAmount,
      });
      console.log('payment response', res);
      if (res.data?.res?.status == 'succeeded') {
        //setShowCreditDialog(false);
        const res2 = await managerewardindirectual({
          data: {
            amount: selectedCreditAmount,
            funding_request_date: new Date(),
            cost: selectedCreditAmount,
            //value: Number(selectedCreditAmount) + 4.7,
            stripeChargeId: res?.data?.res?.id,
            receipt_url: res?.data?.res?.receipt_url,
          },
          //rewardId: 'pending',
          type: 'funding_request',
          //type: 'reward_request',
          src_community_id: params.community_id,
        });
        console.log('saving_request', res2);
        setOpenCreditDialog(false);
        if (res2?.data?.status == 'OK') {
          toast(
            'Your request for credit has been saved to be accepted by the admin'
          );
        }

        setOpenSendGiftConfirmDialog(false);
        //postcommunity(credits + selectedCreditAmount);
        setstripeloading(false);
      }
    } catch (error) {
      console.log(error);
      setstripeloading(false);
    }
  };

  const [templates, setTemplates] = useState([
    {
      name: 'Food delivery, groceries, or quick snack on us',
      description:
        'Perfect for testimonials, reviews, one-off resident interactions',
      dollars: '$',
      giftbitTemplateId: 'HKDFJPCBIMEP',
      coverImage: require('../../assets/rewards-templates/reward-1.png')
        .default,
      subjectLinePrefix: 'A back to work bundle',
      bottomImage: 't',
    },
    {
      name: 'Fun evening out / weekend on us',
      description: 'Perfect for resident events and renewals',
      dollars: '$$',
      giftbitTemplateId: 'MFNKAOFEBKYF',
      coverImage: require('../../assets/rewards-templates/reward-2.png')
        .default,
      subjectLinePrefix: 'A back to work bundle',
      bottomImage: 't',
    },
    {
      name: 'Back to work bundle / clothe shopping',
      description: 'Perfect for testimonials, giveaways, and leasing specials',
      dollars: '$$$',
      giftbitTemplateId: 'MFNKAOFEBKYF',
      coverImage: require('../../assets/rewards-templates/reward-3.png')
        .default,
      subjectLinePrefix: 'A back to work bundle',
      bottomImage: 't',
    },
  ]);

  const [giftAmounts, setGiftAmounts] = useState([
    { id: 1, image: '', title: '10' },
    { id: 2, image: '', title: '20' },
    { id: 3, image: '', title: '25' },
    { id: 4, image: '', title: '50' },
    { id: 5, image: '', title: '100' },
  ]);

  const [creditAmounts, setCreditAmounts] = useState([
    { id: 1, image: '', title: '100', val: 100 },
    { id: 3, image: '', title: '250', val: 250 },
    { id: 4, image: '', title: '500', val: 500 },
  ]);

  const [cancelexpand, setcancelexpand] = useState('');

  const handleCloseCreditDialog = () => {
    setOpenCreditDialog(false);
  };

  const subjectAreaRef = useRef();
  const messageAreaRef = useRef();
  function subjectAreaAdjust() {
    subjectAreaRef.current.style.height = '1px';
    subjectAreaRef.current.style.height =
      20 + subjectAreaRef.current.scrollHeight + 'px';
  }

  function messageAreaAdjust() {
    messageAreaRef.current.style.height = '1px';
    messageAreaRef.current.style.height =
      20 + messageAreaRef.current.scrollHeight + 'px';
  }

  useEffect(() => {
    if (reward.toName) {
      setReward({
        ...reward,
        subject: `${reward.toName}, from ${reward.fromName} at ${name}`,
      });
    }
  }, [reward.toName]);

  useEffect(() => {
    if (reward.fromName) {
      setReward({
        ...reward,
        subject: `${reward.toName}, from ${reward.fromName} at ${name}`,
      });
    }
  }, [reward.fromName]);

  const handleCloseGiftDialog = () => {
    setOpenSendGiftConfirmDialog(false);
  };

  const handleConfirmGiftButton = async () => {
    if (gifttopending) {
      // save gift in pending, in directual
      if (!selectedgiftTemplate) {
        return toast('Please select a gifting template');
      }
      if (!reward.message) {
        return toast('Please input your message');
      }
      const res = await managerewardindirectual({
        data: {
          giftingTemplate: {
            subjectLine: reward.subject,
            personalMessage: reward.message,
            id: selectedgiftTemplate?.giftbitTemplateId,
          },
          date: new Date(),
          requesterEmail: reward.fromEmail,
          cost: giftingAmount,
          notes: '',
          requesterName: reward.fromName,
          recipientName: reward.toName,
          recipientEmail: reward.toEmail,
          value: Number(giftingAmount) + 4.7,
        },
        rewardId: 'pending',
        type: 'reward_request',
        src_community_id: params.community_id,
      });
      console.log('saving_request', res);
      if (res.data.status == 'OK') {
        toast(
          `Your rewards request for ${reward.toName} is pending approval by admin`
        );
      }
    } else {
      sendgift();
    }

    setOpenSendGiftConfirmDialog(false);
  };

  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(0.1, 0.3));
      refAnimationInstance.current(getAnimationSettings(0.7, 0.9));
    }
  }, []);

  const [msg, setmsg] = useState(
    'I was hoping you could do me a favor and leave my team and me a review? Thank you in advance.'
  );
  const messagelist = [
    {
      msg: 'I was hoping you could do me a favor and leave my team and me a review? Thank you in advance.',
    },
    {
      msg: 'I wanted to just say sincerely thank you for leaving us a review. It really meant alot to us.',
    },
    {
      msg: 'I wanted to just say sincerely thank you for leaving us a review. Just wanted to check in to see if you were ever were able to leave us us a Google Review?\n It really means alot to us.',
    },
    {
      msg: 'Just wanted to share a special token as a thank you for kind words in the review and being an awesome resident here. \n It really means alot to us.',
    },
  ];

  const [rewardList, setrewardList] = useState([]);
  const [org_rewardlist, setorg_rewardlist] = useState([]);
  const filterrewardlist = () => {
    let mod_rewardList = [...org_rewardlist];
    if (rewardCheck == 'Pending') {
      mod_rewardList = mod_rewardList.filter((item) => {
        return item.rewardId == 'pending';
      });
    } else if (rewardCheck == 'Completed') {
      mod_rewardList = mod_rewardList.filter((item) => {
        return item.rewardId == 'completed';
      });
    }
    console.log('mod', mod_rewardList);
    setrewardList([...mod_rewardList]);
  };
  useEffect(() => {
    if (rewardCheck) {
      filterrewardlist();
    }
  }, [rewardCheck, org_rewardlist]);

  useEffect(() => {}, []);

  const getrewardrequestlist = async () => {
    try {
      console.log('magnetData.community_id', params.community_id);
      const res = await axios.get(
        `https://api.directual.com/good/api/v5/data/request_history/getRequestHistoryByTypeAndCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${params.community_id}&requestType=reward_request`
      );
      console.log('res', res);
      setrewardList(res.data.payload);
      setorg_rewardlist(res.data.payload);
      //filterrewardlist()
    } catch (error) {
      console.log('error', error);
    }
  };
  const [fundingrequestlist, setfundingrequestlist] = useState([]);
  const getfundingrequestlist = async () => {
    try {
      console.log('magnetData.community_id', params.community_id);
      const res = await axios.get(
        `https://api.directual.com/good/api/v5/data/request_history/getRequestHistoryByTypeAndCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${params.community_id}&requestType=funding_request`
      );
      console.log('fundingrequestlist', res);
      setfundingrequestlist(res.data.payload);
      // setorg_rewardlist(res.data.payload);
      //filterrewardlist()
    } catch (error) {
      console.log('error', error);
    }
  };
  const [fundedlist, setfundedlist] = useState([]);
  const getfundedlist = async () => {
    try {
      console.log('magnetData.community_id', params.community_id);
      const res = await axios.get(
        `https://api.directual.com/good/api/v5/data/request_history/getRequestHistoryByTypeAndCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${params.community_id}&requestType=funded`
      );
      console.log('getfundedlist', res);
      setfundedlist(res.data.payload);
      // setorg_rewardlist(res.data.payload);
      //filterrewardlist()
    } catch (error) {
      console.log('error', error);
    }
  };

  const [dropdownList, setDropdownList] = useState([]);
  const rewardsAutoCompleteList = async () => {
    try {
      console.log('magnetData.community_id', params.community_id);
      const res = await axios.get(
        `https://api.directual.com/good/api/v5/data/users/usersPerCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=&communityId=${params.community_id}`
      );
      console.log('NewResponse', res.data.payload[0]);

      setDropdownList(res.data.payload);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    rewardsAutoCompleteList();
    getrewardrequestlist();
    getfundingrequestlist();
    getfundedlist();
  }, [params.community_id]);
  const [selectName, setSelectName] = useState('');
  const [value, setValue] = useState(null);
  const [emailValue, setEmailValue] = useState(null);

  const { screenToEdit } = useSelector((state) => state.magnetStates);
  const _magnetData = useSelector((state) => state.getMagnet);
  const { template } = _magnetData;

  const [category, screen] = useMemo(
    () => screenToEdit.split('.'),
    [screenToEdit]
  );
  var categoryTitle = useMemo(
    () => (category ? template?.categories[category]?.title : ''),
    [category, template]
  );

  return (
    <div className=" w-full min-h-screen relative">
      <Header>
        <div className="dashboard-hdr flex aic">
          <div className="left flex aic">
            <a href={url} target="_blank">
              {' '}
              <OpenInNewIcon />{' '}
            </a>{' '}
            &nbsp;&nbsp;
            <Link to="/" className="item flex aic">
              <div className="txt font s14 b3 anim">{name}</div>
              {name ? <span className="slash">&nbsp;/&nbsp;</span> : null}
            </Link>
            <div className="item flex aic">
              <div
                onClick={() => {
                  _dispatch({
                    type: 'SCREEN_TO_EDIT',
                    payload: '',
                  });
                  window.history.pushState({}, `View ${community_id}`, `#`);
                }}
                className="txt font s14 b5 black"
              >
                Rewards
              </div>
              {categoryTitle && <span className="slash">&nbsp;/&nbsp;</span>}
            </div>
            {categoryTitle && (
              <div className="item flex aic">
                <div className="txt font s14 b5 black">{categoryTitle}</div>
              </div>
            )}
          </div>
          <div className="right flex aic">
            <div className="right flex aic">
              <button
                onClick={() => {
                  setOpenCreditDialog(true);
                }}
                className="add-credits-btn cleanbtn font s15 b4 color"
              >
                Add credits{' '}
                <button class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
                  ${credits}
                </button>
              </button>
            </div>
          </div>
        </div>
      </Header>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
      <div className="rewards-page flex flex-col">
        <div className="wrapper flex flex-col">
          <div className="block">
            <div className="title font s15 b4 black flex aic">
              Choose your gifting template
            </div>
            <div className="templates">
              <Slider {...templateSlider}>
                {templates.map((item, idx) => (
                  <div>
                    <div
                      className={`template-blk ${
                        selectedgiftTemplate?.giftbitTemplateId ===
                        item.giftbitTemplateId
                          ? 'active'
                          : ''
                      }`}
                      onClick={() => setSelectedGiftTemplate(item)}
                    >
                      <div
                        style={{ backgroundImage: `url(${item.coverImage})` }}
                        className="graphic rel"
                      ></div>
                      <div className="ftr flex">
                        <div className="dollar-sign font s13 b5 cfff">
                          {item.dollars}
                        </div>
                        <div className="meta flex flex flex-col">
                          <div className="lbl font s13 b6 black wordwrap">
                            {item.name}
                          </div>
                          <div className="disc font s12 c222">
                            {item.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          <div className="flex forms-section">
            <div className="gift-side">
              <div className="block">
                <div className="title font s15 b4 black flex aic">
                  Who do you want to send gift to?
                </div>
                <div className="form flex flex-col">
                  <div className="field flex flex-col">
                    <div className="tit font s16 b5 black">To:</div>
                    <div className="blk flex items-center gap-2">
                      <Autocomplete
                        id="country-select-demo"
                        className=" w-full"
                        options={dropdownList}
                        autoHighlight
                        value={value}
                        onChange={(event, newValue) => {
                          if (newValue && newValue.inputValue) {
                            setValue({
                              ...value,
                              name: newValue.inputValue,
                            });
                            setReward({
                              ...reward,
                              toName: newValue.inputValue,
                            });
                          } else if (newValue === null) {
                            setValue(newValue);
                          } else {
                            setReward({
                              ...reward,
                              toName: newValue.name,
                              toEmail: newValue.email,
                            });
                            setValue(newValue);
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          // Suggest the creation of a new value
                          if (params.inputValue !== '') {
                            filtered.push({
                              inputValue: params.inputValue,
                              name: `Add "${params.inputValue}"`,
                            });
                          }

                          return filtered;
                        }}
                        getOptionLabel={(option) =>
                          option.name ? option.name : ''
                        }
                        renderOption={(option) => (
                          <>
                            <p>
                              {' '}
                              {option.name && option.name}{' '}
                              {option.email && `(${option.email})`}
                            </p>
                          </>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Name"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                      {/* {console.log(value)} */}
                      <Autocomplete
                        id="country-select-demo"
                        className=" w-full"
                        options={dropdownList}
                        autoHighlight
                        value={value}
                        onChange={(event, newValue) => {
                          if (newValue && newValue.inputValue) {
                            setValue({ ...value, email: newValue.inputValue });
                            setReward({
                              ...reward,
                              toEmail: newValue.inputValue,
                            });
                          } else if (newValue === null) {
                            setValue(newValue);
                          } else {
                            setReward({
                              ...reward,
                              toName: newValue.name,
                              toEmail: newValue.email,
                            });
                            setValue(newValue);
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          // Suggest the creation of a new value
                          if (params.inputValue !== '') {
                            filtered.push({
                              inputValue: params.inputValue,
                              email: `Add "${params.inputValue}"`,
                            });
                          }

                          return filtered;
                        }}
                        getOptionLabel={(option) =>
                          option.email ? option.email : ''
                        }
                        renderOption={(option) => (
                          <>
                            <p>
                              {option.email && option.email}{' '}
                              {option.name && `(${option.name})`}
                            </p>
                          </>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Email"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />

                      {/* <input
                        type="text"
                        placeholder="Email"
                        className="cleanbtn iput font s14 black"
                        defaultValue={reward.toEmail}
                        onChange={(e) =>
                          setReward({
                            ...reward,
                            toEmail: e.target.value,
                          })
                        }
                      /> */}
                    </div>
                  </div>
                  <div className="field flex flex-col">
                    <div className="tit font s16 b5 black">From:</div>
                    <div className="blk flex aix">
                      <input
                        type="text"
                        placeholder="Name"
                        className="cleanbtn iput font s14 black"
                        defaultValue={reward.fromName}
                        onChange={(e) =>
                          setReward({
                            ...reward,
                            fromName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="title font s15 b4 black flex aic mt-3">
                    Choose your gifting amount
                  </div>
                  <div className="gifts-blk flex aic">
                    <Slider {...gitAmountSlider}>
                      {giftAmounts.map((item, idx) => (
                        <div>
                          <button
                            onClick={() => {
                              setGiftAmount(item.title);
                              setCustomAmount(false);
                            }}
                            className={`cleanbtn gift-item font flex aic ${
                              giftingAmount === item.title ? 'active' : ''
                            }`}
                          >
                            ${item.title}
                          </button>
                        </div>
                      ))}
                      {customAmount ? (
                        <div>
                          <div
                            className={`cleanbtn gift-item flex aic jic ${
                              customAmount ? 'active' : ''
                            }`}
                          >
                            <div className="sign font">$</div>
                            <input
                              type="number"
                              className="cleanbtn iput font"
                              placeholder="Amount"
                              onChange={(e) =>
                                setGiftAmount(Number(e.target.value))
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <button
                            onClick={() => {
                              setCustomAmount(true);
                              setGiftAmount('');
                            }}
                            className="cleanbtn gift-item font s14 b4 black flex aic"
                          >
                            Custom
                          </button>
                        </div>
                      )}
                    </Slider>
                  </div>
                </div>
              </div>
              <div className="mail-template-form flex flex-col">
                <div className="hdr">
                  <div className="field from flex">
                    <div className="lbl font s15 c222">From:</div>
                    <input className="iput font s15 c222" value={name} />
                  </div>
                  <div className="field flex">
                    <div className="lbl font s15 b6 c222">Subject:</div>
                    <textarea
                      ref={subjectAreaRef}
                      className="iput area font s15 c222"
                      onKeyUp={subjectAreaAdjust}
                      value={reward.subject}
                      onChange={(e) =>
                        setReward({ ...reward, subject: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="amount font s32 b6 cfff">${giftingAmount}</div>
                <textarea
                  ref={messageAreaRef}
                  className="cleanbtn message font s16 b6 c222"
                  placeholder="Your Message"
                  onKeyUp={messageAreaAdjust}
                  onChange={(e) =>
                    setReward({
                      ...reward,
                      message: e.target.value,
                    })
                  }
                />
                <div className="your-gift flex flex-col">
                  <div className="lbl font s16 b6 black">
                    Reedeem your gift:
                  </div>
                  <div
                    className="gift-image"
                    style={{
                      backgroundImage: `url(${selectedgiftTemplate?.coverImage})`,
                    }}
                  />
                </div>
              </div>
              <button
                onClick={() => {
                  if (giftingAmount == 0) {
                    return toast('Please select an amount');
                  }
                  //else if (giftingAmount > credits) {
                  //   return toast("You don't have enough credits");
                  // }
                  setgifttopending(true);
                  setOpenSendGiftConfirmDialog(true);
                }}
                className="cleanbtn send-btn font s16 b6 cfff"
              >
                Send
              </button>
            </div>
            <div className="reward-request-side flex flex-col">
              <div className="hdr flex aic">
                <div className="title font">{`${
                  rewardCheck == 'Pending'
                    ? 'Pending Rewards Request'
                    : 'Completed Rewards Request'
                }`}</div>
                <div className="actions flex aic">
                  <div
                    className={`action flex aic ${
                      rewardCheck === 'Pending' ? 'on' : ''
                    }`}
                    onClick={() => {
                      setRewardCheck('Pending');
                      setcancelexpand(Math.random());
                    }}
                  >
                    <div className="checkbox" />
                    <div className="lbl font">Pending</div>
                  </div>
                  <div
                    className={`action flex aic ${
                      rewardCheck === 'Completed' ? 'on' : ''
                    }`}
                    onClick={() => {
                      setRewardCheck('Completed');
                      setcancelexpand(Math.random());
                    }}
                  >
                    <div className="checkbox" />
                    <div className="lbl font">Completed</div>
                  </div>
                </div>
              </div>
              <div className="reward-content">
                <RewardsTable
                  rewardCheck={rewardCheck}
                  rewardList={rewardList}
                  page={page}
                  setmsg={setmsg}
                  selectedgiftTemplate={selectedgiftTemplate}
                  setSelectedGiftTemplate={setSelectedGiftTemplate}
                  templates={templates}
                  messagelist={messagelist}
                  setselectedrewardId={setselectedrewardId}
                  setGiftAmount={setGiftAmount}
                  setOpenSendGiftConfirmDialog={setOpenSendGiftConfirmDialog}
                  setdialoguestatus={setdialoguestatus}
                  managerewardindirectual={managerewardindirectual}
                  setReward={setReward}
                  cancelexpand={cancelexpand}
                  setgifttopending={setgifttopending}
                  setselectedrewarddata={setselectedrewarddata}
                  accesslvl={accesslvl}
                />
              </div>
            </div>

            <div className="reward-request-side flex flex-col">
              <div className="hdr flex aic">
                <div className="title font">{`${
                  pendingcheck == 'Pending'
                    ? 'Pending Fund Request'
                    : 'Completed Fund Request'
                }`}</div>
                <div className="actions flex aic">
                  <div
                    className={`action flex aic ${
                      pendingcheck === 'Pending' ? 'on' : ''
                    }`}
                    onClick={() => {
                      setpendingcheck('Pending');
                      setcancelexpand(Math.random());
                    }}
                  >
                    <div className="checkbox" />
                    <div className="lbl font">Pending</div>
                  </div>
                  <div
                    className={`action flex aic ${
                      pendingcheck === 'Completed' ? 'on' : ''
                    }`}
                    onClick={() => {
                      setpendingcheck('Completed');
                      setcancelexpand(Math.random());
                    }}
                  >
                    <div className="checkbox" />
                    <div className="lbl font">Completed</div>
                  </div>
                </div>
              </div>
              <div className="reward-content">
                <FundRequestslist
                  pendingcheck={pendingcheck}
                  rewardList={rewardList}
                  page={page}
                  fundingrequestlist={fundingrequestlist}
                  fundedlist={fundedlist}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnimatedDialog
        open={openCreditDialog}
        onClose={handleCloseCreditDialog}
        maxWidth={'xs'}
        BackdropProps={{
          style: { backgroundColor: 'rgba(17, 24, 39, 0.9)' },
        }}
      >
        <div className="add-credit-dialog flex-col">
          <div className="hdr flex aic">
            <div className="title font s18 b6 c222">
              Add more&nbsp;
              <span className="color">Credit</span>
            </div>
            <button
              className="cleanbtn cross-btn fontr s30 c222 flex aic anim"
              onClick={handleCloseCreditDialog}
            >
              &times;
            </button>
          </div>
          <div className="form">
            <CardForm
              processpayment={processpayment}
              handleSubmit={handleSubmit}
            />
            <div className="blk">
              <div className="title font s16 b6 c222">Billing details</div>
              <input
                type="text"
                placeholder="Name"
                className="cleanbtn iput font s15 black"
                onChange={(e) => {
                  setbillingdetails({
                    ...billingdetails,
                    name: e.target.value,
                  });
                }}
              />
              <input
                type="text"
                placeholder="Street Address, State, Country"
                className="cleanbtn iput font s15 black"
                onChange={(e) => {
                  setbillingdetails({
                    ...billingdetails,
                    address: e.target.value,
                  });
                }}
              />
              <input
                type="number"
                placeholder="Phone number"
                className="cleanbtn iput font s15 black"
                onChange={(e) => {
                  setbillingdetails({
                    ...billingdetails,
                    phonenumber: e.target.value,
                  });
                }}
              />
            </div>
            <div className="credit-amounts flex aic">
              <Slider {...addCreditAmountSlider}>
                {creditAmounts.map((item, idx) => (
                  <div>
                    <button
                      onClick={() => {
                        setSelectedCreditAmount(item.val);
                        setEnableCreditCustomAmount(false);
                      }}
                      className={`cleanbtn item font flex aic ${
                        selectedCreditAmount === item.val ? 'active' : ''
                      }`}
                    >
                      ${item.title}
                    </button>
                  </div>
                ))}
                {enableCreditCustomAmount ? (
                  <div>
                    <div
                      className={`cleanbtn item flex aic jic ${
                        enableCreditCustomAmount ? 'active' : ''
                      }`}
                    >
                      <div className="sign font">$</div>
                      <input
                        type="number"
                        className="cleanbtn iput font"
                        placeholder="Amount"
                        onChange={(e) =>
                          setSelectedCreditAmount(Number(e.target.value))
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <button
                      onClick={() => {
                        setEnableCreditCustomAmount(true);
                        setSelectedCreditAmount('');
                      }}
                      className="cleanbtn item font flex aic"
                    >
                      Custom
                    </button>
                  </div>
                )}
              </Slider>
            </div>
            <button
              onClick={() => handleSubmit()}
              className="cleanbtn purchase-btn font s15 b5 cfff"
            >
              {stripeloading && <Lottie options={defaultOptions} width={30} />}
              {!stripeloading && 'Purchase Credit'}
            </button>
          </div>
        </div>
      </AnimatedDialog>

      {/* Send Gift Conformation Dialog */}
      <AnimatedDialog
        open={openSendGiftConfirmDialog}
        onClose={handleCloseGiftDialog}
        fullWidth
        maxWidth="xs"
      >
        <div className="add-category-dialog">
          <div className="hdr flex aic">
            <div className="title font s18 b6 c000">Send Gift</div>
            <button
              className="cross-btn cleanbtn fontr"
              onClick={handleCloseGiftDialog}
            >
              &times;
            </button>
          </div>
          <div className="wrap flex flex-col">
            <div className="msg font s15 b4 black">
              Are you sure you want to send gift{' '}
              <span className="s16 b6">${giftingAmount}</span> amount.
            </div>
          </div>
          <div className="ftr flex aic">
            <button
              onClick={handleConfirmGiftButton}
              className="cleanbtn add-cata font s15 cfff"
            >
              Confirm
            </button>
            <button
              onClick={handleCloseGiftDialog}
              className="cleanbtn add-cata cancel font s15 c333"
            >
              Cancel
            </button>
          </div>
        </div>
      </AnimatedDialog>
      <AnimatedDialog
        open={openSendedGiftDialog}
        //onClose={handleCloseGiftDialog}
        fullWidth
        maxWidth="xs"
      >
        <div className="add-category-dialog">
          <div className="hdr flex aic">
            <div className="title font s18 b6 c000"></div>
            <button
              className="cross-btn cleanbtn fontr"
              onClick={() => setopenSendedGiftDialog(false)}
            >
              &times;
            </button>
          </div>
          <div className="wrap flex flex-col">
            <div className="msg font s15 b4 black">
              Congratulation! Gift has been emailed to the user.
            </div>
          </div>
        </div>
      </AnimatedDialog>
    </div>
  );
};

export default Rewards;
