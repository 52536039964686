import firebase from './base';
import { get } from './request';

export async function getUserCommunities() {
  return await get('/communities');
}

export async function getIsUserNewUser() {
  let data = await firebase.db.collection('users').doc(firebase.uid()).get();

  if (!data.data()) {
    await getUserCommunities();
    data = await firebase.db.collection('users').doc(firebase.uid()).get();
  }
  return !(data.data() && data.data().old_user);
}
