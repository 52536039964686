import React, { useState, useEffect } from 'react';
import CustomHeader from '../../components/Reusable/CustomHeader';
import { BiSearch, BiPencil, BiDownload } from 'react-icons/bi';
import { FiVideo } from 'react-icons/fi';
import { FaTiktok } from 'react-icons/fa';
import { LuMonitor } from 'react-icons/lu';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { HiOutlineDownload } from 'react-icons/hi';
import { supabase } from '../../services/supabase';

import { useRef } from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';

const VideoLibrary = ({ name, community_id, url, title }) => {
  const [allVidoes, setAllVidoes] = useState([]);
  const [open, setOpen] = useState(false);

  const getVideos = async () => {
    // console.log('community_id', community_id);
    const { data, error } = await supabase
      .from('VideoLibrary')
      .select('*')
      .eq('community_id', community_id);
    if (error) {
      console.error('Error fetching from supabase for roomIds', error);
      return;
    }
    if (!data.length) {
      console.error(`Community with id ${community_id} not found in supabase`);
      return;
    }
    // if (initialLoading) setRooms(data[0].rooms);
    return data;
  };

  const [load, setLoad] = useState(false);

  const getAllVideos = async () => {
    const videos = await getVideos();
    setAllVidoes(videos);
    // console.log("videos", videos)
  };
  useEffect(() => {
    getAllVideos();
    if (load) {
      setLoad(false);
    }
  }, [load]);

  const [selectedItem, setSelectedItem] = useState([]);
  const [showItem, setShowItem] = useState(null);

  const deleteRow = async (id) => {
    try {
      const { data, error } = await supabase
        .from('VideoLibrary')
        .delete()
        .eq('id', id);

      if (error) {
        toast.error(` Error deleting video: ${error}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        return;
      }
      setLoad(true);
      toast.success('Video Deleted Successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (error) {
      toast.error(` Error deleting video: ${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  return (
    <div className=" min-h-screen" style={{ background: '#F4F5F7' }}>
      <ModalComponent item={showItem} open={open} setOpen={setOpen} />
      <CustomHeader
        url={url}
        name={name}
        community_id={community_id}
        title={title}
      />
      <SearchBar
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
      <div className=" grid p-3 grid-cols-1 gap-5 lg:grid-cols-5">
        {allVidoes.map((item, ind) => (
          <VideoCard
            setShowItem={setShowItem}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            key={ind}
            item={item}
            setOpen={setOpen}
            deleteRow={deleteRow}
          />
        ))}
      </div>
    </div>
  );
};

const VideoCard = ({
  item,
  selectedItem,
  setSelectedItem,
  setShowItem,
  setOpen,
  deleteRow,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="max-w-xs bg-white relative cursor-pointer rounded-lg shadow-md "
        onClick={() => {
          setShowItem(item);
          setOpen(true);
        }}
      >
        <div className="relative h-36">
          <img
            src={
              isHovered && item?.thumbnail_gif
                ? item?.thumbnail_gif
                : item?.thumbnail_img
            }
            className="w-full h-full object-cover"
            alt=""
          />
          {isHovered && (
            <>
              <div className="overlay absolute top-0 left-0 w-full h-full bg-black opacity-40"></div>
            </>
          )}
        </div>

        <div className="bg-white px-4 py-3 ">
          <h2 className="text-gray-600  font-semibold ">
            {item?.filename?.slice(0, 15)}...
          </h2>
          {/* <p className="text-gray-800 text-xs">Username - Date</p> */}
        </div>
      </div>
      {isHovered &&
        selectedItem?.filter((i) => i?.id === item?.id)?.length === 0 && (
          <>
            <div className="absolute top-2 z-10 left-2">
              <input
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedItem([...selectedItem, item]);
                  } else {
                    let filterdItems = selectedItem?.filter(
                      (i) => i?.id !== item?.id
                    );
                    setSelectedItem([...filterdItems]);
                  }
                }}
                type="checkbox"
                className="w-5 h-5"
              />
            </div>

            <div className="absolute top-2 z-10 right-2">
              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button>
                  <BsThreeDotsVertical
                    className="text-white cursor-pointer"
                    size={20}
                  />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-5 overflow-hidden -top-2 z-10 mt-2 w-40  origin-top-right rounded-md bg-white shadow-md ">
                    <Menu.Item>
                      <p className="px-3  py-2 text-xs flex items-center hover:bg-gray-100 gap-2 cursor-pointer font-semibold">
                        <BiPencil className="w-4 h-4" />
                        Rename
                      </p>
                    </Menu.Item>
                    <Menu.Item>
                      <p className="px-3  py-2 text-xs flex items-center hover:bg-gray-100 gap-2 cursor-pointer font-semibold">
                        <BiDownload className="w-4 h-4" />
                        Download
                      </p>
                    </Menu.Item>
                    <Menu.Item onClick={() => deleteRow(item?.id)}>
                      <p className="px-3  py-2 text-xs flex items-center hover:bg-gray-100 gap-2 cursor-pointer font-semibold">
                        <MdOutlineDeleteOutline className="w-4 h-4" />
                        Delete
                      </p>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </>
        )}

      {selectedItem?.filter((i) => i?.id === item?.id)?.length != 0 && (
        <>
          <div className="absolute z-10 top-2 left-2">
            <input
              defaultChecked
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedItem([...selectedItem, item]);
                } else {
                  let filterdItems = selectedItem?.filter(
                    (i) => i?.id !== item?.id
                  );
                  console.log('filterdItems', filterdItems);
                  setSelectedItem([...filterdItems]);
                }
              }}
              type="checkbox"
              className="w-5 h-5"
            />
          </div>
        </>
      )}
    </div>
  );
};

const SearchBar = ({ selectedItem, setSelectedItem }) => {
  return (
    <div className=" w-full bg-white py-3 relative flex items-center justify-between px-4">
      <div className="flex items-center gap-2">
        <BiSearch />
        <input
          type="text"
          className=" bg-none outline-none border-none text-sm text-gray-500"
          placeholder="Search... "
        />
      </div>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-pr px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400">
            New Video
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56  origin-top-right rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div>
              <input type="file" className="hidden" id="upload" name="upload" />
              <label htmlFor="upload">
                <Menu.Item>
                  <p className="px-4  py-2 text-sm flex items-center hover:bg-gray-100 gap-2 cursor-pointer font-semibold">
                    <AiOutlineCloudUpload className="w-6 h-6" />
                    Upload File
                  </p>
                </Menu.Item>
              </label>
            </div>
            <div>
              <Menu.Item>
                <p className="px-4 py-2 text-sm flex items-center gap-2 hover:bg-gray-100 cursor-pointer font-semibold">
                  <FiVideo className="w-5 h-5" />
                  Record Video
                </p>
              </Menu.Item>
            </div>
            <div>
              <Menu.Item>
                <p className="px-4 py-2 text-sm flex items-center gap-2 hover:bg-gray-100 cursor-pointer font-semibold">
                  <LuMonitor className="w-5 h-5" />
                  Record Screen
                </p>
              </Menu.Item>
            </div>
            <div>
              <Menu.Item>
                <p className="px-4 py-2  text-sm flex items-center gap-2 hover:bg-gray-100 cursor-pointer font-semibold">
                  <FaTiktok className="w-5 h-5" />
                  Import from URL
                </p>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {selectedItem?.length !== 0 && (
        <div className=" absolute top-0 left-0 w-full h-full bg-gray-900 flex flex-col px-4 items-center justify-center">
          <div className="w-full flex items-center justify-between">
            <p className="text-white text-sm">
              {selectedItem?.length} selected
            </p>
            <div className="flex items-center gap-3">
              <div className="px-2 py-2 border rounded-md cursor-pointer border-white flex hover:bg-gray-50 transition-all ease-in hover:bg-opacity-10 items-center justify-center">
                <MdOutlineDeleteOutline className="w-5 h-5 text-white" />
              </div>
              <div className="px-2 py-2 border rounded-md cursor-pointer border-white flex hover:bg-gray-50 transition-all ease-in hover:bg-opacity-10 items-center justify-center">
                <HiOutlineDownload className="w-5 h-5 text-white" />
              </div>
              <div
                onClick={() => setSelectedItem([])}
                className="px-2 py-2 border rounded-md cursor-pointer text-sm text-white border-white flex hover:bg-gray-50 transition-all ease-in hover:bg-opacity-10 items-center justify-center"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function ModalComponent({ item, open, setOpen }) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform z-50 overflow-hidden rounded-lg bg-white p-5 text-left shadow-xl transition-all sm:my-8 max-w-2xl">
                <video
                  autoPlay
                  controls
                  src={item?.fileUrl}
                  style={{ zIndex: '327894732' }}
                  width={'500px'}
                  height={'500px'}
                  type="video/mp4"
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default VideoLibrary;
