import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import {
  get_typewriter_custom_stats,
  getISOdate,
} from '../tour-magnet-tabs/utils';

export const MagnetSummaryTypewriter = ({ magnetId }) => {
  const [words, setwords] = useState(['']);
  const getTypeWriterwordlist = async () => {
    setwords([
      `Delivered over ${await get_typewriter_custom_stats(
        30,
        'open_tour',
        magnetId
      )} tours the last 30 days`,
      `Delivered over ${await get_typewriter_custom_stats(
        360,
        'open_tour',
        magnetId
      )} tours the past year`,
    ]);
  };
  useEffect(() => {
    if (magnetId) {
      getTypeWriterwordlist();
    }
  }, [magnetId]);

  const [text] = useTypewriter({
    words,
    loop: 0,
    onLoopDone: () => console.log(`loop completed after 3 runs.`),
  });

  function GenerateImgBasedOnTypewriter() {
    if (text.substring(0, 1) == 'S') {
      return (
        <img
          src={
            'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/3c62e876-8122-475c-dba6-7e5a333dc700/public'
          }
          className=" ml-2 w-10 h-10"
          alt=""
        />
      );
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="ml-2 w-10 h-10 text-white"
      >
        <path
          fillRule="evenodd"
          d="M15.22 6.268a.75.75 0 01.968-.432l5.942 2.28a.75.75 0 01.431.97l-2.28 5.941a.75.75 0 11-1.4-.537l1.63-4.251-1.086.483a11.2 11.2 0 00-5.45 5.174.75.75 0 01-1.199.19L9 12.31l-6.22 6.22a.75.75 0 11-1.06-1.06l6.75-6.75a.75.75 0 011.06 0l3.606 3.605a12.694 12.694 0 015.68-4.973l1.086-.484-4.251-1.631a.75.75 0 01-.432-.97z"
          clipRule="evenodd"
        />
      </svg>
    );
  }

  return (
    <div>
      {/* search bar */}
      <input
        style={{
          color: 'white',
          backdropFilter: 'blur(21px)',
        }}
        id="search"
        type="text"
        placeholder={text} //"Paste a go/link or Search on google"
        className=" w-full  border-none individual_input outline-none h-24 pl-20 text-2xl rounded-md text-white-100 input_blur placeholder-gray-200"
        // onKeyDown={function (e) {
        //   if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        //     // search();
        //   }
        // }}
        // value={searchFilter.query}
        // onChange={(newValue) =>
        //   setSearchFilter({
        //     ...searchFilter,
        //     query: newValue.target.value,
        //   })
        // }
      />

      <div className=" absolute top-12 mt-2 left-6  item-center z-30">
        {GenerateImgBasedOnTypewriter()}
      </div>
    </div>
  );
};
