import AnimatedDialog from '../../../components/Reusable/AnimatedDialog';
import { AiOutlineClose } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import StarRatingChip from './StarRatingChip';

export function calcNeededReviewsForGoal(
  currReviewCt,
  currReviewRating,
  goalRating,
  expectedIndivRating
) {
  return (
    Math.ceil(currReviewCt * currReviewRating - goalRating * currReviewCt) /
    (goalRating - expectedIndivRating)
  );
}
export function displayStars(rating) {
  let stars = '';
  for (let i = 0; i < rating; i++) {
    stars = stars + '⭐';
  }
  return stars;
}

export default function EditGoalModal({
  reviewMetadata,
  setReviewMetadata,
  saveReviewMetadata,
  open,
  setOpen,
}) {
  const [localReviews, setLocalReviews] = useState(reviewMetadata);

  useEffect(() => {
    setLocalReviews(reviewMetadata);
  }, [reviewMetadata]);

  const goalRatingError =
    localReviews?.goalRating &&
    (localReviews?.rating > localReviews.goalRating ||
      localReviews.goalRating > 5);

  const expectedRatingError =
    localReviews?.expectedRating &&
    (localReviews.expectedRating < localReviews.goalRating ||
      localReviews.expectedRating > 5);

  const incompleteForm =
    goalRatingError ||
    expectedRatingError ||
    !localReviews?.goalRating ||
    !localReviews?.expectedRating;

  return (
    <AnimatedDialog open={open}>
      <div className="w-full bg-white p-4 flex flex-col gap-4">
        <div className="flex items-center justify-between border-b border-gray-300 w-full">
          <h4 className="pb-3 text-xl font-semibold">Update Rating Goals</h4>
          <AiOutlineClose
            className="w-7 h-7 cursor-pointer text-gray-600"
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>

        <div className="flex gap-2 items-center">
          Current rating:
          <StarRatingChip rating={reviewMetadata?.rating} />
        </div>

        <TextField
          label="Goal Rating"
          value={localReviews?.goalRating ?? ''}
          onChange={(e) => {
            setLocalReviews({
              ...localReviews,
              goalRating: parseFloat(e.target.value),
            });
          }}
          variant="outlined"
          error={goalRatingError}
          helperText={
            goalRatingError
              ? localReviews.goalRating < localReviews.rating
                ? 'Goal rating should be higher than current rating'
                : 'Goal rating should be between 1 and 5'
              : ''
          }
          type="number"
          className="w-full"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Rating
                  value={localReviews?.goalRating ?? 0}
                  precision={0.1}
                  onChange={(_, newValue) =>
                    setLocalReviews({
                      ...localReviews,
                      goalRating: newValue,
                    })
                  }
                />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          label="Expected Rating for new reviews"
          value={localReviews?.expectedRating ?? ''}
          onChange={(e) => {
            setLocalReviews({
              ...localReviews,
              expectedRating: parseFloat(e.target.value),
            });
          }}
          variant="outlined"
          error={expectedRatingError}
          helperText={
            expectedRatingError
              ? localReviews.expectedRating < localReviews.goalRating
                ? 'Expected rating should be higher than goal rating'
                : 'Expected rating should be between 1 and 5'
              : ''
          }
          type="number"
          className="w-full"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Rating
                  value={localReviews?.expectedRating ?? 0}
                  onChange={(_, newValue) =>
                    setLocalReviews({
                      ...localReviews,
                      expectedRating: newValue,
                    })
                  }
                />
              </InputAdornment>
            ),
          }}
        />

        {!incompleteForm && (
          <p className="text-sm">
            Reviews needed: To get to {localReviews?.goalRating} stars, you will
            need{' '}
            {Math.ceil(
              calcNeededReviewsForGoal(
                localReviews?.reviews,
                localReviews?.rating,
                localReviews?.goalRating,
                localReviews?.expectedRating
              )
            )}{' '}
            new {displayStars(localReviews?.expectedRating)} reviews <br />
          </p>
        )}
        <div className="w-full grid grid-cols-2 gap-2 rounded">
          <button
            className=" border w-full py-2"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </button>
          <button
            className={`bg-pr w-full py-2 text-white font-light rounded ${
              incompleteForm && 'opacity-30'
            }`}
            disabled={incompleteForm}
            onClick={() => {
              setReviewMetadata(localReviews);
              saveReviewMetadata(localReviews);
              setOpen(false);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </AnimatedDialog>
  );
}
