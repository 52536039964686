export function writeToCache(payload, key) {
  if (!key || typeof key !== 'string')
    throw new Error('[writeToCache] Key must be defined, and of type string !');
  try {
    if (payload) localStorage.setItem(key, JSON.stringify(payload));
  } catch (error) {
    console.log(
      `[WriteToCache] Operation Write Failed on key ${key} Because of Exceeding Quota: `,
      payload
    );
  }
}

export function readFromCache(key) {
  if (!key || typeof key !== 'string')
    throw new Error(
      '[readFromCache] Key must be defined, and of type string !'
    );

  const localData = localStorage.getItem(key);
  if (localData) return JSON.parse(localData);

  return;
}

export function clearCache() {
  localStorage.clear();
}
