import { useState } from 'react';
import PeopleIcon from '@material-ui/icons/People';
import LinkIcon from '@material-ui/icons/Link';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveIcon from '@material-ui/icons/Save';
import { getDurationString } from '../../utils/moment';
import moment from 'moment';

export function RoomCard({
  room,
  setTitle,
  link,
  visitorLink,
  onEdit,
  onSave,
  onDelete,
  setNotification,
  buttonsDisabled,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [unsavedTitle, setUnsavedTitle] = useState('');
  const [copyTimeoutId, setCopyTimeoutId] = useState();
  const [copyInstruction, setCopyInstruction] = useState('Copy Visitor Link');

  const validateTitle = (newTitle) => {
    return /^[A-Z.\-: a-z0-9]+$/.test(newTitle);
  };

  const startEditing = () => {
    onEdit();
    setUnsavedTitle(room.name);
    setIsEditing(true);
  };

  const endEditing = () => {
    if (!validateTitle(unsavedTitle)) {
      setNotification(
        'Title can only contain alphanumeric characters or any of -.:',
        'error'
      );
      return;
    }
    setTitle(unsavedTitle); // update rooms object as whole
    setIsEditing(false);
    onSave();
  };

  return (
    <div
      className="text-white rounded-xl relative overflow-hidden"
      style={{ background: '#47546E', width: '400px' }}
    >
      <div
        className="w-full p-3"
        style={{
          background: '#65768E',
        }}
      >
        {isEditing ? (
          <>
            <form onSubmit={endEditing}>
              <input
                type="type"
                className="truncate font-bold h-5 block"
                style={{ width: '328px' }}
                value={unsavedTitle}
                onChange={(e) => {
                  setUnsavedTitle(e.target.value);
                }}
                onFocus={(e) => {
                  e.target.select();
                }}
                autoFocus
              />
            </form>
            <SaveIcon
              className="pointer absolute top-3 right-3"
              onClick={endEditing}
            />
          </>
        ) : (
          <>
            <h3 type="text" className="truncate h-5" style={{ width: '328px' }}>
              {room.name}
            </h3>
            <EditIcon
              className={`pointer absolute top-3 right-3`}
              onClick={() => {
                startEditing();
              }}
            />
          </>
        )}
      </div>

      <div className="p-3 relative">
        {room.active ? (
          <>
            <p className="truncate " style={{ width: '328px' }}>
              {room.peers.filter((elem) => elem.name !== 'Beam')?.join(', ')}
            </p>
            <PeopleIcon className="absolute top-3 right-3" />

            <p className="font-normal mt-1">{`${moment(room.started)?.format(
              'LT'
            )} － ${getDurationString(moment(room.started), moment())}`}</p>
          </>
        ) : (
          <>
            <p
              className="truncate italic text-gray-400 font-normal"
              style={{ width: '328px' }}
            >
              {buttonsDisabled ? 'Loading...' : 'Available'}
            </p>
            <PeopleIcon className="absolute top-3 right-3" />

            <p className="font-normal mt-1 h-6"></p>
          </>
        )}

        <div className="flex justify-between mt-3 items-center">
          <div className="flex gap-2 items-center">
            <a href={link} target="_blank">
              <button className="font-bold py-1 px-3 bg-blue-500 rounded-md text-white">
                Join
              </button>
            </a>
            <button
              className="flex items:center text-gray-400 text-sm font-bold hover:underline"
              onClick={() => {
                clearTimeout(copyTimeoutId);
                navigator.clipboard.writeText(visitorLink);
                setCopyInstruction('Copied!');
                setCopyTimeoutId(
                  setTimeout(() => setCopyInstruction('Copy Visitor Link'), 800)
                );
              }}
            >
              <LinkIcon className="text-gray-400" />
              {copyInstruction}
            </button>
          </div>
          <DeleteForeverIcon
            className={`text-gray-400 ${
              buttonsDisabled
                ? 'opacity-25'
                : 'hover:text-red-400 duration-200 ease-in-out pointer'
            }`}
            onClick={() => {
              if (!buttonsDisabled) onDelete();
            }}
          />
        </div>
      </div>
    </div>
  );
}
