import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AppSidebar, Header } from '../../components';
import { listCommunities } from '../../store/actions/communitiesActions';
import firebase from '../../utils/base';
import { get } from '../../utils/request';
import { clearCache, readFromCache, writeToCache } from './utils';
import { FaSpinner } from 'react-icons/fa';
import { supabase } from '../../services/supabase';

const AnalyticsList = () => {
  const [itemPerpage, setItemPerpage] = useState(25); 
  const navigate = useHistory();
  let componentMountedId = useRef(true);
  let fetchcounter = useRef(0);

  const { data, communityLoading, callError } = useSelector(
    (state) => state.listCommunities
  );
  const { communities, error, status } = data;
  const [mod_communities, setmod_comminities] = useState(''); 
  const [org_communities, setorg_communities] = useState(''); 
  const [componentloaded, setcomponentloaded] = useState(false); // Track if component is fully loaded
  const [dataloading, setdataloading] = useState(false);

  useEffect(() => {
    if (communities) {
      componentMountedId.current = Math.random();
      let the_communities = [];
      communities.map((item) => {
        the_communities.push({ loading: true, item: item, queryinfo: {} });
      });
      setmod_comminities([...the_communities]);
      setorg_communities([...the_communities]);
      startfetching([...the_communities]); // Start fetching analytics for communities
      setcomponentloaded(true);

      return () => {
        componentMountedId.current = Math.random(); // Reset component mount ID when unmounted
        fetchcounter.current = 0;
      };
    }
  }, [communities]);


  useEffect(() => {
    if (org_communities) {
      handlepagination({ selected: 0 });
    }
  }, [org_communities]);

  const _dispatch = useDispatch();

  useEffect(() => {
    _dispatch(listCommunities());
  }, []);

  const [userDataLoading, setUserDataLoading] = useState(false); // Track user data loading status
  const [user, setUser] = useState(); 
  
  // Fetch user data from Firebase
  useEffect(() => {
    setUserDataLoading(true);
    firebase.auth.onAuthStateChanged((user) => {
      firebase.db
        .collection('users')
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setUser(doc.data());
            setUserDataLoading(false);
          }
        })
        .catch((err) => {
          console.log('Error getting document:', err);
          setUserDataLoading(false);
        });
    });
  }, []);

  // Function to get analytics data from BigQuery
  const getanalyicsbigquery = async (query) => {
    try {
      const { data } = await axios.get(
        'https://api.leasemagnets.com/run_sqlquery_inbigquery?querystring=' +
          query
      );
      return data;
    } catch (error) {
      console.log('error', error);
    }
  };


  const send_grouping_analytics_mail = async () => {
    try {
      let communities_arr = [];
      if (communities.length < 0) {
        return toast('Please wait and try again!');
      }
      communities.map((item) => {
        communities_arr.push(item?.id);
      });
      toast('Weekly Reporting email request sent successfully');
      const { data } = await axios.post(
        'https://groupreportingemail-tyg.onrender.com/send_grouping_analytics_mail',
        {
          communities_arr,
          email: user?.email,
          specificemail: true,
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  // Start fetching analytics data for each community
  const startfetching = (the_communities, enableCache = true) => {
    const random = componentMountedId.current;
    const fetchanalytics = async (communityid, random) => {
      // Cache keys
      const communityDataCacheKey = `analyticslist_${communityid}_data`;
      const communityQueryInfoCacheKey = `analyticslist_${communityid}_queryinfo`;
      const communityDataCacheRes = readFromCache(communityDataCacheKey);
      const communityQueryInfoCacheRes = readFromCache(communityQueryInfoCacheKey);

      let res;
      if (enableCache && communityDataCacheRes) {
        res = communityDataCacheRes;
      } else {
        res = await get(`/magnets?queryByCommunity=${communityid}`, {
          auth: false,
        });
        writeToCache(res, communityDataCacheKey);
      }

      if (res?.magnets) {
        let res1;
        if (enableCache && communityQueryInfoCacheRes) {
          res1 = communityQueryInfoCacheRes;
        } else {
          res1 = await getanalyicsbigquery(
            "SELECT SUM(CASE WHEN `default.events`.`event_type` LIKE '%button_click%' THEN 1 END) AS click_count, COUNT(DISTINCT user_anonymous_id) AS visitor_count," +
              "SUM(CASE WHEN `default.events`.`event_type` LIKE '%form_submission%' THEN 1 END) AS lead_count,max(_timestamp) AS recent_click FROM `default.events`" +
              'WHERE `default.events`.`magnet_uuid` = ' +
              `'${res?.magnets[0]?.uuid}'`
          );
          const { count: Leadcount } = await supabase
            .from('Lead')
            .select('*', { count: 'exact' })
            .eq('magnet_uuid', res?.magnets[0]?.uuid);

          res1['res'][0][2] = Leadcount;
          writeToCache(res1, communityQueryInfoCacheKey);
        }

        the_communities[fetchcounter.current]['queryinfo'] = {
          totalleads: res1.res[0][2],
          totaltours: res1.res[0][0],
          totalvisitors: res1.res[0][1],
          last_event: res1.res[0][3],
        };
      }

      the_communities[fetchcounter.current]['loading'] = false;
      setmod_comminities([...the_communities]);
      fetchcounter.current += 1;
      if (fetchcounter.current < the_communities.length && componentMountedId.current === random) {
        fetchanalytics(the_communities[fetchcounter.current]?.item?.id, random);
      }
    };
    fetchanalytics(the_communities[fetchcounter.current]?.item?.id, random);
  };

  const goRoute = (internal_route = '/community/analyticspage') => {
    navigate.push(internal_route);
  };

  const [search, setsearch] = useState(''); // Search input state
  let [paginationdata, setpagination] = useState({}); // Pagination data state

  // Handle pagination event
  const handlepagination = (event) => {
    let end = (event.selected + 1) * itemPerpage;
    let start = end - itemPerpage;
    setpagination({ start, end });
  };


  useEffect(() => {
    handlepagination({ selected: 0 });
  }, [itemPerpage]);

  const TableRow = ({ item }) => {
    return (
      <tr
        onClick={() => goRoute(`/dashboard/${item?.item?.id}/analytics/`)}
        className="hover:bg-blue-50 cursor-pointer w-full"
      >
        <td className="px-4 py-3 text-gray-700 font-semibold whitespace-nowrap">
          <p>{item?.item?.name}</p>
          <p style={{ fontSize: '8px', color: 'grey' }}>{item?.item?.id}</p>
        </td>
        <td className="px-4 py-3 text-gray-700 font-semibold whitespace-nowrap">
          <div className="flex gap-1">
            <p className="text-lg">
              {item.loading ? (
                <FaSpinner className="animate-spin text-blue-500" />
              ) : item?.queryinfo?.totaltours || 0}
            </p>
          </div>
        </td>
        <td className="px-4 py-3 text-gray-700 font-semibold whitespace-nowrap">
          <div className="flex gap-1">
            <p className="text-lg">
              {item.loading ? (
                <FaSpinner className="animate-spin text-blue-500" />
              ) : item?.queryinfo?.totalleads || 0}
            </p>
          </div>
        </td>
        <td className="px-4 py-3 text-gray-700 font-semibold whitespace-nowrap">
          <div className="flex gap-1">
            <p className="text-lg">
              {item.loading ? (
                <FaSpinner className="animate-spin text-blue-500" />
              ) : item?.queryinfo?.totalvisitors || 0}
            </p>
          </div>
        </td>
        <td className="px-4 py-3 text-gray-700 font-semibold whitespace-nowrap">
          <p>
            {item.loading ? (
              <FaSpinner className="animate-spin text-blue-500" />
            ) : item?.queryinfo?.last_event || 'No events'}
          </p>
        </td>
      </tr>
    );
  };

  return (
    <div className="w-full">
      <AppSidebar />
      <div className="manage-community sidebar-space">
        <Header />

        <div className="w-full mt-3 px-4">
          <h1 className="text-2xl pb-4">Analytics</h1>

          <div className="w-full flex items-center gap-3 justify-between pt-3">
            <div className="flex gap-2">
              <button
                onClick={() => send_grouping_analytics_mail()}
                className="bg-gray-100 border rounded-sm p-2 flex items-center gap-2"
              >
                <p className="text-sm font-bold">Send group reporting email</p>
              </button>
              <button
                disabled={dataloading}
                onClick={() => {
                  if (communities) {
                    clearCache(); // Clear cache before fetching
                    let formattedCommunities = communities.map((item) => ({
                      loading: true,
                      item: item,
                      queryinfo: {},
                    }));
                    setmod_comminities([...formattedCommunities]);
                    setorg_communities([...formattedCommunities]);
                    setTimeout(() => {
                      fetchcounter.current = 0;
                      startfetching([...formattedCommunities]); // Start fetching again
                      setdataloading(true);
                    }, 100);
                  }
                }}
                className="bg-blue-400 hover:bg-blue-500 active:bg-blue-600 rounded-sm p-2 flex items-center gap-2"
              >
                <p className="text-sm font-bold text-white">Force Refresh Communities</p>
              </button>
            </div>

            <div className="flex items-center relative">
              <input
                onChange={(e) => setsearch(e.target.value)}
                style={{ padding: '6px' }}
                type="text"
                placeholder="Search by community"
                className="w-full border text-xs bg-transparent rounded-sm font-semibold"
              />
              <AiOutlineSearch className="text-gray-500 z-10 absolute top-2 right-1 cursor-pointer" />
            </div>
          </div>

          <div className="w-full mt-4 overflow-x-scroll lg:overflow-hidden border rounded-md">
            <table className="min-w-full">
              <thead className="bg-gray-50 border-b">
                <tr>
                  <th scope="col" className="px-6 py-2 text-left tracking-wider">
                    Community Name
                  </th>
                  <th scope="col" className="px-6 py-2 text-left tracking-wider">
                    Tours
                  </th>
                  <th scope="col" className="px-6 py-2 text-left tracking-wider">
                    Leads & Appointments
                  </th>
                  <th scope="col" className="px-6 py-2 text-left tracking-wider">
                    Visitors
                  </th>
                  <th scope="col" className="px-6 py-2 text-left tracking-wider">
                    Last tracked event
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {mod_communities &&
                  mod_communities.map((item, index) => {
                    if (search) {
                      if (
                        item?.item?.name
                          ?.toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return <TableRow item={item} />;
                      }
                    } else if (
                      index >= paginationdata.start &&
                      index < paginationdata.end
                    ) {
                      return <TableRow item={item} />;
                    }
                  })}
              </tbody>
            </table>
          </div>
          <div className="flex items-center justify-between paginate_react mt-3">
            <div className="flex items-center gap-2">
              <select
                value={itemPerpage}
                onChange={(e) => {
                  setItemPerpage(e.target.value);
                }}
                className="border p-1 text-sm bg-transparent rounded-md"
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={org_communities.length}>All</option>
              </select>
              <p className="text-gray-700">communities per page</p>
            </div>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlepagination}
              pageRangeDisplayed={4}
              pageCount={Math.ceil(org_communities.length / itemPerpage)}
              previousLabel="<"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsList;
