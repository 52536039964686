import React from 'react';
import VideosBanner from './VideosBanner';

export default function AllVideos({ query, currRoute }) {
  return (
    <div class="all-videos-section">
      <div class="share-video-container">
        <div class="title-wrap">
          <div class="title-line"></div>
          <h2>All Handpicked Videos</h2>
        </div>
        <VideosBanner query={query} currRoute={currRoute} />
      </div>
    </div>
  );
}
