import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/tour-video-NEW-TYG-logo.png';
import firebase from '../utils/base';

const Navbar = (props) => {
  const { showMenu = true, customHomepage = '/dashboard' } = props;

  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [dropAvataOption, setDropAvataOption] = useState(false);
  const avatarOptions = [
    { icon: 'icon-settings', value: 'Settings', to: '/settings' },
    { icon: 'icon-mail', value: 'Inbox', badge: '6' },
    { icon: 'icon-send', value: 'Messages', to: '/messages' },
    { icon: 'icon-help-circle', value: 'Need help?' },
    { icon: 'icon-log-out', value: 'Sign out', to: '/signout' },
  ];

  const loadName = async (uid) => {
    try {
      const doc = await firebase.db.collection('users').doc(uid).get();

      if (doc.exists) {
        setName(doc.data().name);
        setImage(doc.data().image);
      }
    } catch (err) {
      console.log('Error getting document:', err);
    }
  };

  useEffect(() => {
    loadName(firebase.uid());
  }, [firebase.uid()]);

  useEffect(() => {
    document.body.addEventListener('click', () => setDropAvataOption(false));
  }, []);

  const items = (
    <div className="sub-header">
      <div className="wrapWidth flex aic">
        <Link to={customHomepage} className="item flex aic rel active">
          <div className="ico icon-home s22 c000" />
          <div className="lbl font s15 c333">My Properties</div>
        </Link>
        <Link to="/signup/apartments" className="item flex aic rel">
          <div className="icon-plus s18 c000" />
          <div className="font s15 c333">Add New Apartments</div>
        </Link>
      </div>
    </div>
  );

  return (
    <div className="navbar-menu">
      <div className="main-hdr">
        <div className="wrapWidth flex aic">
          <div className="lit flex aic">
            <Link to={customHomepage}>
              <img src={logo} className="hdr-logo" />
            </Link>
          </div>

          <div className="rit flex aic" onClick={(e) => e.stopPropagation()}>
            <button
              className="cleanbtn user-bl flex aic"
              onClick={(e) => {
                e.stopPropagation();
                setDropAvataOption(!dropAvataOption);
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${
                    image ? image : './demo/faces/female/25.jpg'
                  })`,
                }}
                className="user-dp"
              />
              <div className="meta flex flex-col">
                <div className="nam font s15 b5 c333">{name}</div>

                <div className="menu rel">
                  <div className="font s14 c999 flex aic">Administrator ▼</div>
                  <div
                    className={`options flex flex-col abs ${
                      dropAvataOption ? 'drop' : ''
                    }`}
                  >
                    {avatarOptions.map((item) => (
                      <Link
                        to={item.to}
                        className="lin flex aic anim"
                        //onClick={() => setDropAvataOption(false)}
                      >
                        <div className="raw flex aic">
                          <div className="item flex aic">
                            <div className={`ico ${item.icon} s16 c333`} />
                            <div className="lbl font s14 c333">
                              {item.value}
                            </div>
                          </div>
                          {item.badge && (
                            <div className="tag font s12 cfff b5">
                              {item.badge}
                            </div>
                          )}
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      {/* {showMenu && items} */}
    </div>
  );
};

export default Navbar;
