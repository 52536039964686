import React from 'react';
import { Link, useParams } from 'react-router-dom';
import SendRecording from '../manage-magnets/SendRecording';

const UploadVideoImportRecord = () => {
  const { magnet_id_param, screen_id_param } = useParams();
  return (
    <div className="upload-video-import-record rel">
      <Link
        to={`/upload-video-import/${magnet_id_param}/${screen_id_param}`}
        className="cleanbtn back-btn"
      >
        <img
          src={require('../../assets/upload-video-import/arrow.svg').default}
          className="ico"
        />
      </Link>
      <div className="wrapper flex flex-col aic">
        <SendRecording
          fromPage="upload-video-import"
          magnet_id_param={magnet_id_param}
          screen_id_param={screen_id_param}
        />
      </div>
      {/* Upload file button */}
      <input
        type="file"
        id="upload_file_btn"
        accept="video/mp4,video/x-m4v,video/*"
        className="hide-file-upload-button"
      />
      <button
        className={`cleanbtn add-file-btn flex aic`}
        onClick={() => document.getElementById('upload_file_btn').click()}
      >
        <div className="ico icon-plus1" />
      </button>
    </div>
  );
};
export default UploadVideoImportRecord;
