/**
 * Determine whether the given `input` is iterable.
 * returns empty object if not iterable otherwise returns input
 * @returns {Object}
 */
export function getIterable(input, returnArr) {
  const emptyObj = returnArr ? [] : {};

  if (input === null || input === undefined) return emptyObj;

  if (typeof input[Symbol.iterator] === 'function') return emptyObj;

  return input;
}

export function shortenStr(inputStr, maxLength, showEllipsis) {
  if (maxLength > 0 && inputStr?.length <= maxLength) return inputStr;
  return inputStr.substring(0, maxLength) + showEllipsis ? '...' : '';
}

export const getISOdate = (subtracteddays) => {
  const currDate = new Date();
  currDate.setDate(currDate.getDate() - subtracteddays);
  let thedate = currDate.toISOString();
  return thedate;
};

export const generatechartoptions = (data, name, setoptionmethod) => {
  let series = [];
  let labels = [];
  data?.res?.map((item) => {
    series.push(item[1]);
    labels.push(item[0]);
  });
  //settotal(total);
  console.log('converted', { labels }, { series });
  if (data?.res) {
    setoptionmethod({
      series: [
        {
          name: name,
          data: series,
        },
      ],
      height: '100%',
      type: 'area',
      options: {
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        xaxis: {
          type: 'datetime',
        },
        labels,
        //colors: [colors.blue],
        fill: {
          opacity: 0.16,
        },
        sparkline: {
          enabled: true,
        },
      },
    });
  }
};

export function arrayReplaceAt(arr, index, value) {
  const ret = arr.slice(0);
  ret[index] = value;
  return ret;
}

export function JStringify(item) {
  if (typeof item === 'object') return JSON.stringify(item);
  return item;
}

export function JParse(item) {
  if (typeof item === 'string' && item.length > 0 && item !== 'undefined')
    return JSON.parse(item);
  return item;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 *
 * @param {array} dataArr Array that needs to be filtered
 * @param {string} propertyName name of the property to be filtered by
 * @param {any} propertyValue value of the propery to compare to
 * @param {function} formatter (optional) function to modify the value of property before comparing
 * @returns
 */
export function filterArrOfObjs(
  dataArr,
  propertyName,
  propertyValue = null,
  formatter
) {
  if (!propertyValue) return dataArr;

  return dataArr
    ? dataArr.filter((dataItem, idx) => {
        let itemVal = dataItem[propertyName];
        if (formatter) itemVal = formatter(itemVal);
        if (
          typeof dataItem[propertyName] === 'string' &&
          typeof propertyValue === 'string'
        ) {
          itemVal = itemVal.toLowerCase();
          return itemVal.includes(propertyValue.toLowerCase());
        }
        return dataItem[propertyName] === propertyValue;
      })
    : [];
}
