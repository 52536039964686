import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

const SimpleAccordion = ({
  children,
  title = 'Summary',
  startOpen = true,
  titleCoded = React.Fragment,
}) => {
  const [expanded, setExpanded] = React.useState({
    panel1: startOpen,
  });

  const handleChange = (panel) => (event, newExpanded) => {
    const newState = {
      ...expanded,
    };
    newState[panel] = !newState[panel];
    setExpanded(newState);

    console.log('newxpanded', newExpanded, panel, event);
  };
  return (
    <Accordion
      square
      expanded={expanded.panel1}
      onChange={handleChange('panel1')}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="summarystats-header"
        expandIcon={
          <>
            <ExpandMoreIcon />{' '}
          </>
        }
      >
        <b> {title} </b>
        <>{titleCoded}</>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default SimpleAccordion;
