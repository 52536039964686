import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AccessLevelFunction } from '../../../components/Reusable/AccessLevelWrapper';
import SimpleAccordion from '../../../components/SimpleAccordion';
import { ACCESS_LEVEL } from '../../../utils/constants';
import { HOST, post } from '../../../utils/request';
import './Settings.css';

const AdvancedSetting = ({ title, enabled, children }) => (
  <SimpleAccordion
    title={''}
    titleCoded={
      <>
        <div className="font s15 b7 c000">Advanced: {title}</div>
        {enabled && (
          <div
            className="font s11 b5"
            style={{
              color: 'white',
              backgroundColor: '#4caf50',
              marginLeft: '10px',
              padding: '2px 5px',
              borderRadius: '5px',
              lineHeight: '1.8',
            }}
          >
            Enabled
          </div>
        )}
      </>
    }
    startOpen={false}
  >
    {children}
  </SimpleAccordion>
);

const Settings = ({
  screenObj = {},

  screenKey,
  categoryKey,
}) => {
  const { community_id, userId } = useParams();
  const [tempScreenObj, setTempScreenObj] = useState(screenObj);
  const [response, setResponse] = useState();
  const [accessLevel, setAccessLevel] = useState();
  const [value, setValue] = useState(0); // integer state
  const jsonEditorRef = useRef();
  const { magnetId } = useSelector((state) => state.magnetStates);
  const { template } = useSelector((state) => state.getMagnet);
  // const screenObj = template?.categories[categoryKey]?.screens[screenKey];

  console.log('templateAct', template);

  useEffect(() => {
    console.log('json', template?.categories[categoryKey]?.screens[screenKey]);
    setTempScreenObj(template?.categories[categoryKey]?.screens[screenKey]);
    const editor =
      jsonEditorRef &&
      jsonEditorRef.current &&
      jsonEditorRef.current.jsonEditor;
    if (editor) {
      editor.update(template?.categories[categoryKey]?.screens[screenKey]);
    }
  }, [template]);
  useEffect(() => {
    const effect = async () => {
      //setTempScreenObj(screenObj);
      const res = await AccessLevelFunction(0); // we dont care what community we are looking at we just want to know if they are global admin
      setAccessLevel(res);
    };
    effect();
  }, [screenObj]);
  const [encodingloading, setencodingloading] = useState(false);

  async function encodevideos() {
    console.log('pressed');
    setencodingloading(true);
    try {
      const res = await axios.post(
        //`https://api.directual.com/encode_a_video_in_acommunity?community_id=${community_id}&category=${categoryKey}&screen=${screenKey}`
        `${HOST}/encode_a_video_in_acommunity?community_id=${community_id}&category=${categoryKey}&screen=${screenKey}`
      );
    } catch (error) {
      console.log('errorm', error);
      console.log('errorm', error.response);
    }
    setencodingloading(false);
  }

  return (
    <div className="advanced-settings-container">
      {accessLevel === ACCESS_LEVEL['global'] && tempScreenObj && (
        <>
          <br />
          <AdvancedSetting title={`admin screen json`} enabled={true}>
            <Grid container>
              <Grid item xs={12}>
                {tempScreenObj && (
                  <Editor
                    ref={jsonEditorRef}
                    value={tempScreenObj}
                    onChange={(e) => setTempScreenObj(e)}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={async () => {
                    console.log('/magnets/template/screen/update', {
                      magnet_uuid: magnetId,
                      magnet_type: 'video',
                      screen: tempScreenObj,
                      screen_key: screenKey,
                      category_key: categoryKey,
                    });
                    const res = await post('/magnets/template/screen/update', {
                      magnet_uuid: magnetId,
                      magnet_type: 'video',
                      screen: tempScreenObj,
                      screen_key: screenKey,
                      category_key: categoryKey,
                    });
                    if (!res.error) {
                      setResponse('success -- see logs for details');
                    } else {
                      setResponse('fail -- see log for details');
                    }
                    console.log('response = ', res);
                  }}
                >
                  Save
                </Button>
                <Button
                  style={{ marginTop: '10px' }}
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    encodevideos();
                  }}
                >
                  {encodingloading
                    ? 'Encoding in progress'
                    : 'Encode Videos again'}
                </Button>
              </Grid>
              <Grid item xs={12}>
                {response}
              </Grid>
            </Grid>
          </AdvancedSetting>
        </>
      )}
    </div>
  );
};

export default memo(Settings);
