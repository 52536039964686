// This file is used to set the branding of the app
// It is used in the following places:

export const whitelabelName = () => {
  //   let url = window.location.href;
  if (window.location.hostname.includes('empower')) {
    return 'Empower';
  }
  // if (url.includes('tour')) return 'Tour';
  else return 'Tour';
};

export const whitelabelVideo = () => {
  if (window.location.hostname.includes('empower')) {
    return 'https://customer-qqdk2u3dbwgfurzm.cloudflarestream.com/1e9a063b0c5d21d1c0046e7450b0be53/downloads/default.mp4';
  } else {
    return 'https://firebasestorage.googleapis.com/v0/b/leasemagnets---dummy-db.appspot.com/o/community%2F31%2Fmagnet%2F0d8aa956-2d8d-4254-b018-15c1672c5d9a%2Fintro_Main_Intro_2%2FSimple_tour_video_ad__masked_shirt_2_mp4.mp4?alt=media&token=cc19a462-6e80-49cd-ba2c-e68b1c2f2967';
  }
};

export const whitelabelLogoOrText = () => {
  if (window.location.hostname.includes('feedbackjoy.com')) {
    return (
      <img src={require('../assets/feedback3-TYG.png').default} width={250} />
    );
  } else if (window.location.hostname.includes('tour.video')) {
    return (
      <img
        src={require('../assets/tour-video-NEW-TYG-logo.png').default}
        width={250}
      />
    );
  } else if (window.location.hostname.includes('leasemagnets.com')) {
    return (
      <img
        src={require('../assets/leasemagnets-TYG.png').default}
        width={250}
      />
    );
  } else if (window.location.hostname.includes('empower')) {
    return (
      <div className="logo-txt font_uber font s28 b6 c000">
        Empower Virtual Leasing
      </div>
    );
  } else return <div className="logo-txt font_uber font s28 b6 c000">Tour</div>;
};
