import React, { useEffect, useState } from 'react';
import firebase from '../../utils/base';
import { ACCESS_LEVEL } from '../../utils/constants.js';
import { getUserCommunities } from '../../utils/routes';

export default function AccessLevel(props) {
  const {
    access_level_clearance,
    setUAccessLevel,
    community_id,
    strict,
    reroute,
    children,
  } = props;

  /*
      Props:
        access_level_clearance: minimum access_level required for user to see children
        community_id: community uuid
        strict: ONLY a specific access_level_clearance is allowed to see children
        reroute: if user doesn't have access, user will be rerouted to home
      
      This function determines if a user has access to the children props for the given community_id
    */

  const [userAccessLevel, setUserAccessLevel] = useState('user');

  const loadAcessLevel = async (uid) => {
    // figure out access level
    try {
      const doc = await firebase.db.collection('users').doc(uid).get();
      setUAccessLevel(doc.data().shared_access[community_id]);
      if (doc.exists) {
        if (doc.data().global_admin) {
          setUserAccessLevel(ACCESS_LEVEL['global']);
          setUAccessLevel('global');
        } else if (doc.data().shared_access[community_id]) {
          // reroute if community is hidden
          console.log('Users_access', doc.data());
          const res = await getUserCommunities();
          const currCommunity = res.communities.filter(
            (curr) => curr.id === parseInt(community_id)
          );
          if (!currCommunity.length) {
            setUserAccessLevel(null);
          } else {
            console.log('I am here', doc.data().shared_access[community_id]);
            setUserAccessLevel(
              ACCESS_LEVEL[doc.data().shared_access[community_id]]
            );
          }
        } else {
          setUserAccessLevel(null);
        }
      }
    } catch (err) {
      console.log('Error getting document:', err);
    }
  };

  useEffect(() => {
    loadAcessLevel(firebase.uid());
  }, [firebase.uid()]);

  if (userAccessLevel === null && reroute) {
    window.location.replace('/');
    return <></>;
  }

  if (userAccessLevel === null) {
    console.log('I am coming 65');
    return <></>;
  }

  if (strict) {
    if (userAccessLevel === ACCESS_LEVEL[access_level_clearance]) {
      return <>{children}</>;
    }
    console.log('I am coming 72');
    return <></>;
  }

  if (userAccessLevel >= ACCESS_LEVEL[access_level_clearance]) {
    console.log('I am coming 76');
    return <>{children}</>;
  }

  return <></>;
}

export async function AccessLevelFunction(community_id) {
  /* 
    Props:
      community_id: community uuid 

    This function returns the ACCESS_LEVEL for the given community_id
    Used in a component to hide / show sub-components depending on user's ACCESS_LEVEL
  */

  const doc = await firebase.db.collection('users').doc(firebase.uid()).get();

  if (doc.exists) {
    if (doc.data().global_admin) {
      return ACCESS_LEVEL['global'];
    } else if (doc.data().shared_access[community_id]) {
      return ACCESS_LEVEL[doc.data().shared_access[community_id]];
    }
    return ACCESS_LEVEL['none'];
  }
  return ACCESS_LEVEL['none'];
}

export const AccessLevelRequest = () => {
  /* 
      Props:
      defaults allow you to set default loading state

  */

  const [loading, setLoading] = useState(false);

  const accessLevelCheckCommunityFunc = async (community_id) => {
    /* 
      Props:
        community_id: community uuid 
  
      This function returns the ACCESS_LEVEL for the given community_id
      Used in a component to hide / show sub-components depending on user's ACCESS_LEVEL
    */
    setLoading(true);
    const doc = await firebase.db.collection('users').doc(firebase.uid()).get();
    setLoading(false);

    if (doc.exists) {
      if (doc.data().global_admin) {
        return ACCESS_LEVEL['global'];
      } else if (doc.data().shared_access[community_id]) {
        return ACCESS_LEVEL[doc.data().shared_access[community_id]];
      }
      return ACCESS_LEVEL['none'];
    }
    return ACCESS_LEVEL['none'];
  };

  return [loading, accessLevelCheckCommunityFunc];
};
