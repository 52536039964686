import React from 'react';

const MultiButtonSelector = ({
  selectionArray,
  selectedoptions = [],
  setselectedoptions,
}) => {
  const handleSelected = (label, value, id) => {
    const objIndex = selectedoptions?.findIndex((obj) => obj.label == label);
    if (objIndex > -1) {
      selectedoptions?.splice(objIndex, 1);
    } else {
      selectedoptions.push({ label, value });
    }
    setselectedoptions([...selectedoptions]);
  };
  return (
    <div>
      {selectionArray?.map(({ options, title }, id) => (
        <div className="block" key={id}>
          <div className="label font s14 black">{title}</div>
          <div className="options flex aic">
            {options &&
              options.map(({ label, value }, index) => (
                <div
                  key={index}
                  onClick={() => handleSelected(label, value, id)}
                  className={`item font ${
                    selectedoptions?.findIndex((obj) => obj.label == label) > -1
                      ? 'active'
                      : ''
                  }`}
                >
                  {label}
                </div>
              ))}
            {/* <div className="item font active">Any</div> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MultiButtonSelector;
