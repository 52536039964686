import axios from 'axios';
import { toast } from 'react-toastify';

export const createReviewCommunity = (_data) => async (dispatch) => {
  try {
    dispatch({ type: 'CREATE_REVIEWS_COMMUNITY_REQUEST' });

    const res = await axios.post(
      `https://api.directual.com/good/api/v5/data/community/newCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
      _data
    );

    dispatch({
      type: 'CREATE_REVIEWS_COMMUNITY_SUCCESS',
      payload: res.data.status,
    });
    if (res.data.status === 'OK') {
      toast('Setting updated successfully', {
        position: 'top-center',
        type: 'success',
        autoClose: 5000,
      });
    }
  } catch (error) {
    dispatch({
      type: 'CREATE_REVIEWS_COMMUNITY_FAIL',
      payload: error.message,
    });
    console.log('error', error);
  }
};

export const getReviewCommunity = (magnetId) => async (dispatch) => {
  try {
    dispatch({ type: 'GET_REVIEWS_COMMUNITY_REQUEST' });

    const res = await axios.get(
      `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${magnetId}`
    );

    // console.log('get community res....', res.data);

    dispatch({
      type: 'GET_REVIEWS_COMMUNITY_SUCCESS',
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: 'GET_REVIEWS_COMMUNITY_FAIL',
      payload: error.message,
    });
    console.log('e  ', error);
  }
};

export const getPendingReviewRequests =
  (userId, communityId) => async (dispatch) => {
    try {
      dispatch({ type: 'GET_PENDING_REVIEWS_REQUEST' });

      const res = await axios.get(
        `https://api.directual.com/good/api/v5/data/request_history/getPendingReviewsByCommunityID?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&userId=${userId}&communityId=${communityId}`
      );

      console.log('pendingReview res....', res.data);

      dispatch({
        type: 'GET_PENDING_REVIEWS_SUCCESS',
        payload: res?.data?.payload,
      });
      console.log('pendingReview success....', res.data);
    } catch (error) {
      dispatch({
        type: 'GET_PENDING_REVIEWS_FAIL',
        payload: error.message,
      });
      console.log('pendingReview e  ', error);
    }
  };

export const getCommunityReviews = (magnetId) => async (dispatch) => {
  try {
    dispatch({ type: 'GET_COMMUNITY_REVIEWS_REQUEST' });

    const res = await axios.get(
      `https://api.directual.com/good/api/v5/data/reviews/reviewsPerCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=&communityId=${magnetId}`
    );

    dispatch({
      type: 'GET_COMMUNITY_REVIEWS_SUCCESS',
      payload: res.data?.payload,
    });
  } catch (error) {
    dispatch({
      type: 'GET_COMMUNITY_REVIEWS_FAIL',
      payload: error.message,
    });
    console.log('error  ', error);
  }
};

export const getCommunityUsers =
  (userType, community_id) => async (dispatch) => {
    try {
      dispatch({ type: 'GET_COMMUNITY_USERS_REQUEST' });

      const res = await axios.get(
        `https://api.directual.com/good/api/v5/data/users/usersPerCommunityByType?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}&userType=${userType}`
      );

      // console.log('community users res....', res.data);

      dispatch({
        type: 'GET_COMMUNITY_USERS_SUCCESS',
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: 'GET_COMMUNITY_USERS_FAIL',
        payload: error.message,
      });
      console.log('error:', error);
    }
  };

export const getCommunityUserByID = (staffName) => async (dispatch) => {
  console.log('staffName....', staffName);
  try {
    dispatch({ type: 'GET_COMMUNITY_USER_BY_ID_REQUEST' });

    const res = await axios.get(
      `https://api.directual.com/good/api/v5/data/users/getUserById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&userId=${staffName}`
    );

    console.log('get community user by id....', res.data);

    dispatch({
      type: 'GET_COMMUNITY_USER_BY_ID_SUCCESS',
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: 'GET_COMMUNITY_USER_BY_ID_FAIL',
      payload: error.message,
    });
    console.log('error:', error);
  }
};

export const getCommunityUserReviews =
  (communityId, userId) => async (dispatch) => {
    try {
      dispatch({ type: 'GET_COMMUNITY_USER_REVIEWS_REQUEST' });

      // const res = await axios.get(
      //   `https://api.directual.com/good/api/v5/data/reviews/reviewsByUserIdAndCommunityId?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=thegeorge&userId=amulya`
      // );

      const res = await axios.get(
        `https://api.directual.com/good/api/v5/data/reviews/reviewsByUserIdAndCommunityId?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${communityId}&userId=${userId}`
      );

      // console.log('community users reviews....', res.data);

      dispatch({
        type: 'GET_COMMUNITY_USER_REVIEWS_SUCCESS',
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: 'GET_COMMUNITY_USER_REVIEWS_FAIL',
        payload: error.message,
      });
      console.log('getCommunityUserReviews error....', error);
    }
  };

export const getCommunityAllUserReviews =
  (community_id) => async (dispatch) => {
    try {
      dispatch({ type: 'GET_COMMUNITY_All_USER_REVIEWS_REQUEST' });

      const res = await axios.get(
        `https://api.directual.com/good/api/v5/data/reviews/reviewsPerCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
      );

      console.log('res....', res.data);

      dispatch({
        type: 'GET_COMMUNITY_All_USER_REVIEWS_SUCCESS',
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: 'GET_COMMUNITY_All_USER_REVIEWS_FAIL',
        payload: error.message,
      });
      console.log('getCommunityUserReviews error....', error);
    }
  };

export const createRequestHistory =
  (requestHistoryData) => async (dispatch) => {
    try {
      dispatch({ type: 'CREATE_REQUEST_HISTORY_REQUEST' });

      let res = await axios.post(
        `https://api.directual.com/good/api/v5/data/request_history/newRequestHistory?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
        requestHistoryData
      );

      console.log('create requestHistory response...', res.data);

      dispatch({
        type: 'CREATE_REQUEST_HISTORY_SUCCESS',
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: 'CREATE_REQUEST_HISTORY_FAIL',
        payload: error.message,
      });
      console.log('requestHistory error...', error);
    }
  };

export const getRequestHistory = (requestID) => async (dispatch) => {
  try {
    dispatch({ type: 'GET_REQUEST_HISTORY_REQUEST' });

    let res = await axios.get(
      `https://api.directual.com/good/api/v5/data/request_history/getRequestHistoryById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&requestId=${requestID}`
    );

    // console.log('get requestHistory response...', res.data.payload[0]);

    dispatch({
      type: 'GET_REQUEST_HISTORY_SUCCESS',
      payload: res.data.payload[0],
    });
  } catch (error) {
    dispatch({
      type: 'GET_REQUEST_HISTORY_FAIL',
      payload: error.message,
    });
    console.log('requestHistory error...', error);
  }
};
