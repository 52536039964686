import axios from 'axios';
import momenttz from 'moment-timezone';
import moment from 'moment';
import { supabase } from '../../../services/supabase';

export const get_corpus_by_communityid = async (community_id) => {
  const { data, error } = await supabase
    .from('Community')
    .select('ai')
    .eq('id', community_id)
    .limit(1);

  if (error) {
    console.error('Error fetching from supabase for roomIds', error);
    return;
  }
  if (!data.length) {
    console.error(`Community with id ${community_id} not found in supabase`);
    return;
  }

  // console.log("corpus", data)
  return data?.[0]?.ai;
};

export const convert_date_by_tz = async (date, tz) => {
  if (tz == 'MST') {
    return momenttz(date).tz('America/Denver'); // MST
  } else if (tz == 'PST') {
    return momenttz(date).tz('America/Los_Angeles'); // PST
  }
};

export const bookAppointment = async (
  uuid,
  obj,
  userInfo,
  tourType,
  onError
) => {
  try {
    const query = new URLSearchParams(window.location.search);
    const entratauser = query.get('entratauser');
    ///const uuid = query.get('uuid');
    const entratapass = query.get('entratapass');
    const entratapropertyId = query.get('entratapropertyId');
    let data = {};
    if (entratauser && entratapass && entratapropertyId && uuid) {
      data = {
        username: entratauser,
        password: entratapass,
        property_id: entratapropertyId,
      };
    } else {
      //data = await getEntrataData(integrationId);
    }
    let form_data = {
      source: 'cta_tour_mini_app',
      lead_name: `${userInfo.name}`,
      lead_phone: `+1${userInfo.phone}`,
      lead_email: userInfo.email,
      first_name: userInfo.firstname,
      last_name: userInfo.lastname,
      email: userInfo.email,
      phone: `+1${userInfo.phone}`,
      ...obj,
    };
    console.log('bookAppointment_obj', form_data);
    // Apr 15.  4:00 PM EST  for 30 minutes
    handleGoal(
      'schedule_tour',
      `${
        localStorage.getItem('tour_type') === 'virtual'
          ? '🖥 Virtual Tour:'
          : '🚶 In-Person Tour:'
      } ` +
        moment(new Date(obj?.appointmentDate)).format('MMM Do') +
        ', ' +
        obj?.timeFrom +
        ' Est for 30 minutes',
      {
        tour_time:
          moment(new Date(obj?.appointmentDate)).format('MMM Do') +
          ', ' +
          obj?.timeFrom +
          ' Est for 30 minutes',
      }
    );
    saveLeadinDB(uuid, {
      form_data,
      form_route: null,
      video_time: 0,
    });
    return true;
  } catch (err) {
    console.log(err);
  }
};

export const saveLeadinDB = async (magnet_uuid, details) => {
  await axios.post('https://api.leasemagnets.com/event', {
    magnet_uuid,
    url: window.location.href,
    event_type: 'form_submission',
    details,
  });
};

export const data = {
  intro: {
    text: [
      'Hello👋 My name is Gabrielle at Cayce Cove, and I am an 🤖 automated Leasing Agent here at Cayce Cove in SC.',
      // "You are entering to scan to freebie. What's your name?"
      'So we can reach back out to you, with any rewards. Please provide reachable name, email and number',
      'What is your name?',
    ],
    action: {
      type: 'text-input',
      route: 'lead_email',
      localStorageKey: 'lead_name',
    },
  },
  intro_name: {
    text: [
      // "You are entering to scan to freebie. What's your name?"
      'Excellent, I think that I can help with that. But first, I am asking for your info, in case I get something wrong.',
      'What is your name?',
    ],
    action: {
      type: 'text-input',
      route: 'lead_email',
      localStorageKey: 'lead_name',
    },
  },
  callback: {
    text: [
      'We will call you back tomorrow, What time of day work best for you when it comes to a callback',
    ],
    action: {
      type: 'multiple-choice',
      buttons: [
        { title: 'Morning - 9 AM to 10:30 AM', route: 'callback_scheduled' },
        { title: 'Noonish - 10:30 AM to noon', route: 'callback_scheduled' },
        { title: 'Afternoon - noon to 2 PM', route: 'callback_scheduled' },
        {
          title: 'Afternoonish - 2 PM to 5 PM',
          route: 'callback_scheduled',
        },
      ],
    },
  },
  scheduler: {
    text: ['Schedule A tour'],
    action: {
      type: 'scheduler',
      route: 'callback_scheduled',
    },
  },
  tour_scheduled: {
    text: [
      '📞 Your callback has been scheduled, and you will receive a text about it shortly!',
      'What is next?',
    ],
    action: {
      type: 'multiple-choice',
      buttons: [
        { title: 'Info about properties', route: 'info' },
        { title: 'Pricing/Availability', route: 'pricing' },
        { title: 'Ask us a Question', route: 'question' },
      ],
    },
  },
  callback_scheduled: {
    text: [
      '📞 Your callback has been scheduled, and you will receive a text about it shortly!',
      'What is next?',
    ],
    action: {
      type: 'multiple-choice',
      buttons: [
        { title: 'Info about properties', route: 'info' },
        { title: 'Pricing/Availability', route: 'pricing' },
        { title: 'Ask us a Question', route: 'question' },
      ],
    },
  },
  info: {
    text: [
      'Welcome to Cayce Cove Apartments! Our apartments in Cayce, SC offer students all the comforts of home, including full-size washers and dryers, cable TV, and Wi-Fi. Community amenities like our fitness center with cardio room, tanning bed, resort-style pool with hot tub, and computer/conference center with free printing, help you stay fit, relaxed, and social.',
      'Our apartments near the University of South Carolina campus are also fully furnished, have all-inclusive rent, and provide a private state-of-the-art campus shuttle. Explore why Cayce Cove is the best in student living!',
      'What would you like to learn more about? PS: If you book an in-person tour and show up, we will give you $10',
    ],
    action: {
      type: 'multiple-choice',
      buttons: [
        { title: 'Schedule a Tour', route: 'scheduler' },
        { title: 'Pricing/Availability', route: 'pricing' },
        { title: 'Ask us a Question', route: 'question' },
      ],
    },
  },
  pricing: {
    text: [
      'Here at Cayce Cove, we combine comfortable living and affordable prices. :',
      '2 Bed: $755',
      '4 Bed: $610',
      'All units are fully furnished and roommate matching is available.',
      'Internet, Basic Cable w/HBO, Water, Sewer, Trash, and Electricity are included in rent',
      'Current promotions:',
      '$25 off per month on all 4x2s',
      'What else can I help you with?',
    ],
    action: {
      type: 'multiple-choice',
      buttons: [
        { title: 'Info about properties', route: 'info' },
        { title: 'Pricing/Availability', route: 'pricing' },
        { title: 'Ask a question', route: 'question' },
      ],
    },
  },
  question: {
    text: ["Sure, what's your question?"],
    action: {
      type: 'text-input',
      goal: 'question',
      route: 'question_followup',
      localStorageKey: 'question',
    },
  },
  question_followup: {
    text: [
      // "Here at Bankier, we combine comfortable living and affordable prices. Some of our most popular apartments start at the following:",
      // " \n\n- 202 E Green, Park Place Tower: $825/mo \n- 410 E Green, Skylight Court: $775/mo \n- 519 E Green, Skyline Tower: $1125/mo \n- 1107 S Second - Round Balconies: $750/mo \n",
      'What else can I help you with?',
    ],
    action: {
      type: 'multiple-choice',
      buttons: [
        { title: 'Schedule a Tour', route: 'scheduler' },
        { title: 'Ask another question', route: 'question' },
        { title: 'Request a callback', route: 'callback' },
      ],
    },
  },
  question2: {
    text: ['What property is this regarding?'],
    action: {
      type: 'multiple-select',
      buttons: [
        { title: 'Park Place Tower' },
        { title: 'Skyline Tower' },
        { title: 'Skyline West' },
      ],
      route: 'question3', //onSelecting and confirming, go to this route
    },
  },
  question3: {
    text: ['What type of housing is this regarding?'],
    action: {
      type: 'multiple-select',
      buttons: [
        { title: '2 Bedroom' },
        { title: '4 Bedroom' },
        { title: 'Roommate Matching' },
      ],
      route: 'lead_name', //onSelecting and confirming, go to this route
    },
  },
  lead_name: {
    text: [
      'Hello👋 My name is Marianna at The One, and I am an 🤖 automated Leasing Agent here at The One in Ann Arbor.',
      'Virtually, I can try to answer any of the questions you may have, or may ask one of my team to personally reach out',
      'What is your name?',
    ],
    action: {
      type: 'text-input',
      route: 'lead_email',
      localStorageKey: 'lead_name',
    },
  },
  referral_name: {
    text: ['What name of the person you would like to refer?'],
    action: {
      type: 'text-input',
      route: 'referral_phone',
      localStorageKey: 'lead_name',
    },
  },
  referral_phone: {
    text: ['What is the phone number of the person you would like to refer?'],
    action: {
      type: 'text-input',
      regex: 'phone',
      route: 'referree_name',
      localStorageKey: 'lead_phone',
    },
  },
  referree_name: {
    text: [
      'Fantastic, now if the lead signs a lease, we would like to send you a thank you 🎁',
      'For that, we will need your contact information',
      'What is your name?',
    ],
    action: {
      type: 'text-input',
      route: 'referree_phone',
      localStorageKey: 'referree_name',
    },
  },
  referree_phone: {
    text: ['What is your phone number?'],
    action: {
      type: 'text-input',
      regex: 'phone',
      route: 'referree_email',
      localStorageKey: 'referree_phone',
    },
  },
  referree_email: {
    text: ['What is your email?'],
    action: {
      type: 'text-input',
      regex: 'email',
      route: 'thank_you_refer_complete',
      localStorageKey: 'referree_email',
    },
  },
  lead_email: {
    text: ['2️⃣ What email should we use to contact you?'],
    action: {
      type: 'text-input',
      regex: 'email',
      route: 'lead_phone',
      localStorageKey: 'lead_email',
    },
  },
  lead_phone: {
    text: ['3️⃣ What phone number should we use to contact you?'],
    action: {
      type: 'text-input',
      regex: 'phone',
      route: 'thank_you',
      localStorageKey: 'lead_phone',
    },
  },
  lead_graduation: {
    text: ['What year will you be graduating'],
    action: {
      type: 'multiple-choice',
      buttons: [
        // { title: "Info about properties", route: "info" },
        // { title: "Pricing/Availability", route: "pricing" },
        { title: '2023', route: 'lead_budget' },
        { title: '2024', route: 'lead_budget' },
        { title: '2025', route: 'lead_budget' },
        { title: '2026', route: 'lead_budget' },
        { title: '2027+', route: 'lead_budget' },
      ],
      localStorageKey: 'lead_graduation',
    },
  },
  lead_budget: {
    text: ['What is your rent budget?'],
    action: {
      type: 'multiple-choice',
      buttons: [
        // { title: "Info about properties", route: "info" },
        // { title: "Pricing/Availability", route: "pricing" },
        { title: '$600 and below', route: 'lead_lease_signed' },
        { title: '$600-800', route: 'lead_lease_signed' },
        { title: '$800-1000', route: 'lead_lease_signed' },
        { title: '$1000-1200', route: 'lead_lease_signed' },
        { title: '$1200+', route: 'lead_lease_signed' },
      ],
      localStorageKey: 'lead_budget',
    },
  },
  lead_lease_signed: {
    text: ['Are you still looking to sign a lease for next year?'],
    action: {
      type: 'multiple-choice',
      buttons: [
        // { title: "Info about properties", route: "info" },
        // { title: "Pricing/Availability", route: "pricing" },
        { title: "🔍 Yes, I'm still looking", route: 'lead_name' },
        { title: 'No, but I can refer someone', route: 'referral_name' },
      ],
      localStorageKey: 'lead_lease_signed',
    },
  },
  virtual_tour: {
    text: ['Here are some relevant pictures and videos'],
    action: {
      type: 'virtual-tour',
      // route: "thank_you",
      buttons: [
        // { title: "Info about properties", route: "info" },
        // { title: "Pricing/Availability", route: "pricing" },
        { title: 'Schedule A Tour', route: 'scheduler' },
        { title: 'Ask a question', route: 'question' },
        { title: 'Request a Callback', route: 'callback' },
      ],
    },
  },
  thank_you: {
    text: [
      '🙌 Welcome, we  are here to answer any questions you may have.',
      'In the meantime, What would you want to learn about in our property?',
    ],
    action: {
      type: 'multiple-choice',
      buttons: [
        // { title: "Info about properties", route: "info" },
        // { title: "Pricing/Availability", route: "pricing" },
        { title: 'Ask a question', route: 'question' },
        { title: 'View Virtual Tour', route: 'virtual_tour' },
        // { title: "Request a Callback", route: "callback" },
        { title: 'Schedule A Tour', route: 'scheduler' },
        { title: 'Refer someone', route: 'referral_name' },
      ],
    },
  },
  thank_you_tour: {
    text: [
      '🙌 Thank you so much for providing that information! I am going to send you text message to confirm that.',
      'In the meantime, What would you want to learn about in our property?',
    ],
    action: {
      type: 'multiple-choice',
      buttons: [
        // { title: "Info about properties", route: "info" },
        // { title: "Pricing/Availability", route: "pricing" },
        { title: 'Ask a question', route: 'question' },
        { title: 'View Virtual Tour', route: 'virtual_tour' },
        { title: 'Request a Callback', route: 'callback' },
        { title: 'Schedule A Tour', route: 'scheduler' },
      ],
    },
  },
  welcome_tour: {
    text: [
      '🙌 Welcome! I am here to answer any questions you may have.',
      'In the meantime, What would you want to learn about in our property?',
    ],
    action: {
      type: 'multiple-choice',
      buttons: [
        // { title: "Info about properties", route: "info" },
        // { title: "Pricing/Availability", route: "pricing" },
        { title: 'Ask a question', route: 'question' },
        { title: 'View Virtual Tour', route: 'virtual_tour' },
        { title: 'Request a Callback', route: 'callback' },
        { title: 'Schedule A Tour', route: 'scheduler' },
      ],
    },
  },

  thank_you_refer: {
    text: [
      'Thank you for being open to refer someone!',
      'Click below to refer someone, but in the meantime. What would you want to learn about our property?',
    ],
    action: {
      type: 'multiple-choice',
      buttons: [
        // { title: "Info about properties", route: "info" },
        // { title: "Pricing/Availability", route: "pricing" },
        { title: 'Ask a question', route: 'question' },
        { title: 'View Virtual Tour', route: 'virtual_tour' },
        { title: 'Request a Callback', route: 'callback' },
        { title: 'Refer someone else', route: 'referral_name' },
      ],
    },
  },
  thank_you_refer_complete: {
    text: [
      'Thank you for referring someone!',
      'That number will be verified as well!',
      'In the meantime. What would you want to learn about our property?',
    ],
    action: {
      type: 'multiple-choice',
      buttons: [
        // { title: "Info about properties", route: "info" },
        // { title: "Pricing/Availability", route: "pricing" },
        { title: 'Ask a question', route: 'question' },
        { title: 'View Virtual Tour', route: 'virtual_tour' },
        { title: 'Request a Callback', route: 'callback' },
        { title: 'Refer someone', route: 'refer_name' },
      ],
    },
  },
};

const insert_data_in_sheet = (data) => {
  console.log('lead_event goal');
  fetch('https://sheet.best/api/sheets/a0c616bb-b856-4a41-90d3-d54637669f15', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify([
      {
        Date: moment().format('MMMM Do YYYY, h:mm:ss a'),
        //Action: "lead_event",
        Name: localStorage.getItem('lead_name'),
        Email: localStorage.getItem('lead_email'),
        Phone: localStorage.getItem('lead_phone'),
        ...data,
      },
    ]),
  })
    .then((r) => r.json())
    .then((data) => {
      // The response comes here
      console.log(data);
    })
    .catch((error) => {
      // Errors are reported there
      console.log(error);
    });
};

export const send_email = async (route, data) => {
  console.log('letss see', { route, data });
  try {
    await axios.post(
      `https://transactionalandcronjobs-tyg.onrender.com/api/${route}`,
      data
    );
  } catch (error) {
    console.log(error.response);
    console.log(error);
  }
};

export const send_sms = async (data) => {
  console.log('send_sms letss see', data);
  try {
    await axios.post(
      `https://transactionalandcronjobs-tyg.onrender.com/api/send_sms`,
      {
        to: data.to,
        message: data.message,
      }
    );
  } catch (error) {
    console.log(error.response);
    console.log(error);
  }
};

export const handleGoal = (goal, info = '', data) => {
  if (goal === 'lead_event') {
    insert_data_in_sheet({
      Action: 'lead_event',
    });
  }

  if (goal === 'question') {
    insert_data_in_sheet({
      Action: 'lead_question',
      Info: info,
    });
  }
  if (goal === 'callback') {
    // - text message notifying lead about the callback requested
    send_sms({
      to: `+${localStorage.getItem('lead_phone')}`,
      message: `Your callback request has been recieved, we will head back to you on ${data.callback_time}`,
    });
    // - and email notifying the team that a callback has been requested
    insert_data_in_sheet({
      Action: 'lead_callback',
      Info: info,
    });
  }
  if (goal === 'schedule_tour') {
    // - send an email and text message, confirming the tour booking
    send_sms({
      to: `+${localStorage.getItem('lead_phone')}`,
      message: `Hi ${localStorage.getItem('lead_name')}, Your ${
        localStorage.getItem('tour_type') || 'In-person'
      } tour has been booked at ${
        localStorage.getItem('community_name') || 'cayce Cove'
      } on ${data?.tour_time}`,
    });
    send_email('send_schedule_tour_email', {
      user_name: localStorage.getItem('lead_name'),
      email: localStorage.getItem('lead_email'),
      community_name: localStorage.getItem('community_name') || 'cayce Cove',
      tour_type: localStorage.getItem('tour_type') || 'In-person',
      tour_time: data?.tour_time,
      communityLocation:
        localStorage.getItem('communityLocation') ||
        '215 Spencer Pl, Cayce, SC 29033, United States',
    });
    insert_data_in_sheet({
      Action: 'schedule_tour',
      Info: info,
    });
  }
  if (goal === 'lead_referral') {
    // - text message to referral, notifying that someone referred them,
    // send_sms(localStorage.getItem("lead_phone"))  referree_phone
    send_sms({
      to: `+${localStorage.getItem('referree_phone')}`,
      message: `Hi ${localStorage.getItem(
        'referree_name'
      )},you have been referred by your friend
      ${localStorage.getItem('lead_name')}`,
    });
    // - email to referree, to let the know the reward and - text message to referee to let them know reward
    send_email('send_referral_email', {
      referral_name: localStorage.getItem('lead_name'),
      referee_name: localStorage.getItem('referree_name'),
      email: localStorage.getItem('referree_email'),
    });
    insert_data_in_sheet({
      Action: 'lead_referral',
      Info: info,
    });
  }
  if (goal === 'lead_event') {
    insert_data_in_sheet({
      Action: 'lead_event',
      Info: info,
    });
  }
};
