import React from 'react';
import Slider from 'react-slick';
import { Player } from 'video-react';

const Solution = () => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className=" w-full flex items-center flex-col gap-3 py-20">
      <h1 className=" text-2xl font-bold">
        A video solution for all your needs.
      </h1>
      <p className=" text-sm text-center">
        We deliver top quality videos for <br /> products in all industries
      </p>
      <button className=" px-4 text-sm hover:bg-nhvr bg-nvr font-medium py-2 rounded-md text-white">
        View Pricing
      </button>
      <div className=" w-full mt-10">
        <Slider {...settings}>
          <div>
            <Player
              playsInline
              src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
            />
          </div>
          <div>
            <Player
              playsInline
              src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
            />
          </div>
          <div>
            <Player
              playsInline
              src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
            />
          </div>
          <div>
            <Player
              playsInline
              src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
            />
          </div>
          <div>
            <Player
              playsInline
              src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
            />
          </div>
          <div>
            <Player
              playsInline
              src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
            />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Solution;
