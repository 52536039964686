import { makeStyles } from '@material-ui/core/styles';
import Autocomplete, { usePlacesWidget } from 'react-google-autocomplete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Header } from '../../../components';
import ManageTeamMembers from '../../../components/Reviews/ManageTeamMembers';
import { fakeReviewData } from '../../../fakedata/review';
import { RESIDENT_DASHBOARD, useRequest } from '../../../utils/request.js';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import { Box, TextField, Tooltip } from '@material-ui/core';
import { RefreshRounded, StarRate } from '@material-ui/icons';
import GoalSummary from '../dashboard/GoalSummary';
import { supabase } from '../../../services/supabase';
import StarRatingChip from '../dashboard/StarRatingChip';
import ReviewAnalytics from '../dashboard/ReviewAnalytics';
import CollectReviews from '../dashboard/CollectReviews';
import FeaturedReviewSelection from './FeaturedReviewSelection';
import { isEmpty } from 'lodash';
import CreateReview from '../dashboard/CreateReview';
import { Skeleton } from '@material-ui/lab';
import { API_HOST } from '../../../utils/request.js';

export const CHART_DURATION = moment.duration(1, 'year');
const REVIEWS_PER_FETCH = 100;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '5px',
  },
}));

// as part of the move from directual -> supabase I have removed the teams logic
// if you are adding it back, the *rough* equivalent of directualCommunity
// is reviewMetadata - it would somewhat make sense to store teams there
// but probably also good to find a good place to put it
// reviewMetadata gets stored into supabase under the Community table in the reviews column

const Review = ({ name, url, community_id, title }) => {
  const { screenToEdit } = useSelector((state) => state.magnetStates);
  const _magnetData = useSelector((state) => state.getMagnet);
  const { template } = _magnetData;

  const [category] = useMemo(() => screenToEdit.split('.'), [screenToEdit]);
  var categoryTitle = useMemo(
    () => (category ? template?.categories[category]?.title : ''),
    [category, template]
  );
  const classes = useStyles();
  const [loading, setloading] = useState(true);

  const [addopen, setAddopen] = useState(false);
  const [editLocation, setEditLocation] = useState(false);

  const [wallfeedback, setWallfeedback] = useState([]);
  useEffect(() => {
    setWallfeedback(fakeReviewData.feedbacktype);
  }, []);

  const params = useParams();
  console.log('_magnetData', _magnetData);

  const _dispatch = useDispatch();
  const [error, data, makeRequest] = useRequest({ loading: false });

  const [locationError, setLocationError] = useState(false);

  const toastNotification = (message, type) => {
    toast(message, {
      position: 'top-right',
      type: type,
      autoClose: 5000,
    });
  };

  // const { ref: googlePlacesRef } = usePlacesWidget({
  //   apiKey: 'AIzaSyCrD2NbwBUEj-jqtU303rfETOYChYehO2A',
  //   onPlaceSelected: (place) => {
  //     console.log('GMBOBJ: ', place);

  //     setReviewMetadata((prev) => {
  //       const newMetadata = {
  //         ...prev,
  //         place_id: place?.place_id,
  //       };
  //       saveReviewMetadata(newMetadata);
  //       return newMetadata;
  //     });
  //     setLocationError(false);
  //   },
  //   options: {
  //     types: ['establishment'],
  //   },
  // });

  const [reviewMetadata, setReviewMetadata] = useState();
  const [reviews, setReviews] = useState();
  const [currentReviewSortingMethod, setCurrentReviewSortingMethod] =
    useState('timeDescending');
  const reviewIds = useRef(new Set());
  const reviewSortingMethodCounts = useRef({
    totalReviews: Number.MAX_SAFE_INTEGER,
    timeAscending: 0,
    timeDescending: 0,
    ratingAscending: 0,
    ratingDescending: 0,
  });
  const [disableRefreshButton, setDisableRefreshButton] = useState(false);

  const allReviewsFetched = () => {
    return reviews.length >= reviewSortingMethodCounts.current.totalReviews;
  };

  async function saveReviewMetadata(providedReviewMetadata) {
    if (!providedReviewMetadata) {
      providedReviewMetadata = reviewMetadata;
    }
    const { error } = await supabase
      .from('Community')
      .update({ reviews: providedReviewMetadata })
      .eq('id', community_id);
    if (error) {
      console.error('Error updating review metadata in supabase:', error);
    }
  }

  async function getReviewMetadata() {
    const { data, error } = await supabase
      .from('Community')
      .select('reviews')
      .eq('id', community_id);
    if (error) {
      console.error('Error fetching review metadata from supabase:', error);
      return;
    }
    setReviewMetadata(data[0].reviews);
  }

  useEffect(() => {
    console.log('reviews', reviews);
    console.log('reviewSortingMethodCounts', reviewSortingMethodCounts);
  }, [reviews]);

  async function getReviews() {
    console.log('getReviews run!');
    const { data, error } = await supabase
      .from('Reviews')
      .select('created_at, review_rating, review_text, author_title, id')
      .eq('community_id', community_id)
      .gt(
        'created_at',
        moment().subtract(CHART_DURATION).format('YYYY-MM-DD HH:mm:ss')
      );
    if (error) {
      console.error('Error fetching reviews from supabase:', error);
      return;
    }
    console.log('supabase reviews data reviewanalytics', data);
    setReviews(data);
    reviewIds.current = new Set(data.map((datum) => datum.id));
    reviewSortingMethodCounts.current.timeDescending = data.length;
    const { count, error: countError } = await supabase
      .from('Reviews')
      .select('*', { count: 'exact', head: true })
      .eq('community_id', community_id);
    if (countError) {
      console.error('Error counting supabase review count:', countError);
      return;
    }
    reviewSortingMethodCounts.current.totalReviews = count;
  }

  async function fetchMoreReviews(sortingMethod) {
    console.log('fetchMoreReviews ru');
    console.log('reviewSortingMethodCounts', reviewSortingMethodCounts);
    if (allReviewsFetched()) {
      console.log('early return because of all reviews fetched');
      return;
    }
    const reviewsToSkip = reviewSortingMethodCounts.current[sortingMethod];
    const { data, error } = await supabase
      .from('Reviews')
      .select('created_at, review_rating, review_text, author_title, id')
      .eq('community_id', community_id)
      .range(reviewsToSkip, reviewsToSkip + REVIEWS_PER_FETCH);

    if (error) {
      console.error('Error fetching next page of reviews from supabase');
    }

    // trim array down to reviews we dont have, and update the reviews set
    const unseenReviews = data.filter((review) => {
      if (!reviewIds.current.has(review.id)) {
        reviewIds.current.add(review.id);
        return true;
      }
    });

    reviewSortingMethodCounts.current[sortingMethod] += unseenReviews.length;

    setReviews(
      sortReviews(currentReviewSortingMethod, [...reviews, ...unseenReviews])
    );
  }

  useEffect(() => {
    getReviewMetadata();
    getReviews();
  }, []);

  const sortReviews = (sortingMethod, reviews) => {
    const sortingFunction =
      sortingMethod === 'timeAscending'
        ? (a, b) => new Date(a).getTime() - new Date(b).getTime()
        : sortingMethod === 'timeDescending'
        ? (a, b) => new Date(b).getTime() - new Date(a).getTime()
        : sortingMethod === 'ratingAscending'
        ? (a, b) => {
            const ratingDiff = a.review_rating - b.review_rating;
            return ratingDiff || new Date(b).getTime() - new Date(a).getTime();
          }
        : (a, b) => {
            const ratingDiff = b.review_rating - a.review_rating;
            return ratingDiff || new Date(b).getTime() - new Date(a).getTime();
          };
    return reviews.toSorted(sortingFunction);
  };

  const changeSortingMethod = (newSortingMethod) => {
    if (
      (newSortingMethod === 'timeAscending' &&
        currentReviewSortingMethod === 'timeDescending') ||
      (newSortingMethod === 'timeDescending' &&
        currentReviewSortingMethod === 'timeAscending') ||
      (newSortingMethod === 'ratingAscending' &&
        currentReviewSortingMethod === 'ratingDescending') ||
      (newSortingMethod === 'ratingDescending' &&
        currentReviewSortingMethod === 'ratingAscending')
    ) {
      setReviews(reviews.toReversed());
    } else {
      setReviews(sortReviews(newSortingMethod, reviews));
    }
    setCurrentReviewSortingMethod(newSortingMethod);
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="w-full min-h-screen">
        {/*Manage team members / Add team member(s)*/}
        {!loading && (
          <ManageTeamMembers
            saveCommunityTeam={(team) => {
              console.log('team', team);
              setReviewMetadata({ ...reviewMetadata, team: team });
              updatecommunity({ ...reviewMetadata, team: team });
            }}
            setAddopen={setAddopen}
            addopen={addopen}
            initialTeamState={reviewMetadata?.team}
            community_id={community_id}
          />
        )}
        <Header>
          <div className="dashboard-hdr flex aic w-full">
            <div className="left flex aic">
              <a href={url} target="_blank">
                <OpenInNewIcon />
              </a>
              &nbsp;&nbsp;
              <Link to="/" className="item flex aic">
                <div className="txt font s14 b3 anim">{name}</div>
                {name ? <span className="slash">&nbsp;/&nbsp;</span> : null}
              </Link>
              <div className="item flex aic">
                <div
                  onClick={() => {
                    _dispatch({
                      type: 'SCREEN_TO_EDIT',
                      payload: '',
                    });
                    window.history.pushState({}, `View ${community_id}`, `#`);
                  }}
                  className="txt font s14 b5 black"
                >
                  Reviews
                </div>
                {categoryTitle && <span className="slash">&nbsp;/&nbsp;</span>}
              </div>
              {categoryTitle && (
                <div className="item flex aic">
                  <div className="txt font s14 b5 black">{categoryTitle}</div>
                </div>
              )}
            </div>
          </div>
        </Header>
        <div className="w-full mt-3 px-4">
          <h1 className="text-sm font-semibold pt-1 block">Currently at: </h1>

          <div className="flex items-center gap-4 flex-wrap">
            <h1 className="text-3xl font-semibold ">
              {reviewMetadata?.reviews ?? 'no'}

              <span className="text-3xl font-light"> reviews</span>
            </h1>
          </div>

          <div className="flex items-center flex-wrap gap-2 w-full">
            {reviewMetadata ? (
              <>
                <StarRatingChip rating={reviewMetadata?.rating} />

                {/* <TextField
                  inputRef={googlePlacesRef}
                  label="Location"
                  error={locationError}
                  defaultValue={reviewMetadata.place_id ? name : ''}
                  variant="outlined"
                /> */}

                {reviewMetadata.place_id && !editLocation ? (
                  <div
                    className="hover:text-pr"
                    onClick={() => setEditLocation(true)}
                  >
                    {name}
                  </div>
                ) : (
                  <Autocomplete
                    apiKey={'AIzaSyCrD2NbwBUEj-jqtU303rfETOYChYehO2A'}
                    onPlaceSelected={(place) => {
                      console.log('GMBOBJ: ', place);

                      setReviewMetadata((prev) => {
                        const newMetadata = {
                          ...prev,
                          place_id: place?.place_id,
                        };
                        saveReviewMetadata(newMetadata);
                        return newMetadata;
                      });
                      setLocationError(false);
                    }}
                    options={{ types: ['establishment'] }}
                  />
                )}

                <Tooltip title="Refresh Reviews">
                  <IconButton
                    disabled={disableRefreshButton}
                    onClick={() => {
                      if (!reviewMetadata.place_id) {
                        setLocationError(true);
                      } else {
                        axios.post(`${API_HOST}/reviews/request_update`, {
                          community_id,
                        });
                        toastNotification(
                          'Request to update reviews sent! Data will be updated in 5-10 minutes, check back then',
                          'success'
                        );
                        setDisableRefreshButton(true);
                      }
                    }}
                  >
                    <RefreshRounded />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                <Skeleton width={230} height={40} variant="rect" />
                <Skeleton width={210} height={40} variant="rect" />
                <Skeleton width={40} height={40} variant="circle" />
              </>
            )}
          </div>
        </div>

        <div className="flex flex-wrap p-6 gap-10">
          {reviewMetadata?.reviews != null && (
            <>
              <GoalSummary
                reviewMetadata={reviewMetadata}
                setReviewMetadata={setReviewMetadata}
                saveReviewMetadata={saveReviewMetadata}
              />
              {!isEmpty(reviews) && <ReviewAnalytics reviews={reviews} />}
              <CollectReviews
                url={url}
                name={name}
                reviewsMetadata={reviewMetadata}
                toastNotification={toastNotification}
                community_id={community_id}
              />
            </>
          )}
          <CreateReview setReviews={setReviews} community_id={community_id} />
        </div>

        <FeaturedReviewSelection
          reviews={reviews}
          reviewMetadata={reviewMetadata}
          setReviewMetadata={setReviewMetadata}
          saveReviewMetadata={saveReviewMetadata}
          fetchMoreReviews={() => fetchMoreReviews(currentReviewSortingMethod)}
          allReviewsFetched={allReviewsFetched}
          sortingMethod={currentReviewSortingMethod}
          setSortingMethod={changeSortingMethod}
        />

        {/* {showStepForm && <MultiStepForm community_id={community_id} />} */}
      </div>
    </>
  );
};

export default Review;
