import Dexie from 'dexie';

export const idb = new Dexie('Tour');
idb.version(1).stores({
  analytics: 'id',
});

(async function () {
  const recordsLimit = 3;

  const allRecords = await idb.analytics.toArray();
  // If total records exceed recordsLimit, then delete previous records
  if (allRecords.length > recordsLimit) {
    for (let i = recordsLimit; i < allRecords.length; i++) {
      await idb.analytics.delete(allRecords[i].id);
    }
  }
})();
