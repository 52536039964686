import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import WidgetWrapper from '../WidgetWrapper';
import { getSupabaseRow } from '../../manage-magnets/utils';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import SwiperCore, { Pagination, A11y, Thumbs, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Skeleton } from '@material-ui/lab';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/a11y/a11y.min.css';
// import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/thumbs/thumbs.min.css';
import './PhotoGallery.css';

// install Swiper modules
SwiperCore.use([Pagination, Thumbs, A11y, Navigation]);

const PhotoGallery = () => {
  const { magnetId } = useParams();
  const [photos, setPhotos] = useState([
    {
      link: 'https://images.unsplash.com/photo-1502672260266-1c1ef2d93688?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1980&q=80',
      caption: 'Sample photo 1',
    },
    {
      link: 'https://images.unsplash.com/photo-1497238932193-904bf1749495?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80',
      caption: 'Sample photo 2',
    },
    {
      link: 'https://images.unsplash.com/flagged/photo-1556438758-8be0c4afe990?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80',
      caption: 'Sample photo 3',
    },
  ]);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useEffect(() => {
    const fetchPhotos = async () => {
      const res = await getSupabaseRow('Community', 'photos', {
        column_name: 'community_magnets',
        column_value: magnetId,
      });
      if (res.error || !res.data.length) {
        console.error(
          'PhotoGallery - Error in fetching photos or no photos found:',
          res.error
        );
        return;
      }
      if (res.data[0].photos?.length) {
        setPhotos(res.data[0].photos);
      }
    };
    fetchPhotos();
  }, []);

  return (
    <WidgetWrapper onBack={() => history.back()}>
      <div className="mx-7 mt-9">
        <h1 className="font-bold text-2xl mb-4">Photo Gallery</h1>
        <div className="relative">
          <div className="image-swiper-button-next swiper-button">
            <ArrowForwardIosIcon className="text-white" />
          </div>
          <div className="image-swiper-button-prev swiper-button">
            <ArrowBackIosIcon className="text-white" />
          </div>
          <Swiper
            className="full-image-swiper"
            spaceBetween={28}
            pagination={{ dynamicBullets: true }}
            thumbs={{ swiper: thumbsSwiper }}
            centeredSlides={true}
            a11y={{
              containerMessage: 'A photo gallery for Arba San Marcos', //todo: replace with actual data
              containerRoleDescriptionMessage:
                'Displays one photo at a time, with a title and description beneath',
            }}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
              disabledClass: 'swiper-button-disabled',
            }}
          >
            {photos ? (
              photos.map(({ link, caption }) => (
                <SwiperSlide key={link}>
                  <div className="flex flex-col align-items-start w-full">
                    <img src={link} alt="image w-full h-auto object-cover" />
                    <p className="text-lg mt-2">{caption}</p>
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <>
                <SwiperSlide>
                  <Skeleton variant="rect" height={400} width={711} />
                </SwiperSlide>
                <SwiperSlide>
                  <Skeleton variant="rect" height={400} width={711} />
                </SwiperSlide>
                <SwiperSlide>
                  <Skeleton variant="rect" height={400} width={711} />
                </SwiperSlide>
                <SwiperSlide>
                  <Skeleton variant="rect" height={400} width={711} />
                </SwiperSlide>
              </>
            )}
          </Swiper>
        </div>

        {/* This swiper holds the thumbnails */}
        <Swiper
          spaceBetween={10}
          slidesPerView={'auto'}
          freeMode={true}
          onSwiper={setThumbsSwiper}
          watchSlidesVisibility={true}
          watchSlidesProgress={true}
          className="thumbnail-swiper"
          a11y={{
            containerMessage: 'A photo gallery for Arba San Marcos',
            containerRoleDescriptionMessage:
              'This holds the thumbnails for the images before. There is no new content here.',
          }}
        >
          {photos ? (
            photos.map(({ link, caption }) => (
              <SwiperSlide key={link}>
                <img
                  src={link}
                  alt="image thumbnail"
                  className="h-16 rounded pointer"
                />
              </SwiperSlide>
            ))
          ) : (
            <>
              <SwiperSlide>
                <Skeleton variant="rect" height={60} width={107} />
              </SwiperSlide>
              <SwiperSlide>
                <Skeleton variant="rect" height={60} width={107} />
              </SwiperSlide>
              <SwiperSlide>
                <Skeleton variant="rect" height={60} width={107} />
              </SwiperSlide>
              <SwiperSlide>
                <Skeleton variant="rect" height={60} width={107} />
              </SwiperSlide>
            </>
          )}
        </Swiper>
      </div>
    </WidgetWrapper>
  );
};

export default PhotoGallery;
