import { Tab, Tabs } from '@material-ui/core';
import OpenRooms from '../../components/meetings/OpenRooms';
import PastRecordings from '../../components/meetings/PastRecordings';
import Notification from '../../components/Notification';
import { useState } from 'react';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import { MdOutlineClose } from 'react-icons/md';

const VirtualRooms = ({
  community_id,
  meetingModal,
  setMeetingModal,
  externalRooms,
  setExternalRooms,
  communityName,
  externalInitialLoading,
  setExternalInitialLoading,
}) => {
  const [NotificationBar, setNotification] = Notification();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  return (
    <AnimatedDialog
      open={meetingModal}
      onClose={() => {
        setMeetingModal(false);
      }}
      maxWidth="lg"
    >
      {meetingModal && (
        <>
          <div className="flex justify-between mb-4 pt-4 px-4">
            <h1 className="font-bold text-2xl">Manage Virtual Tour Rooms</h1>

            <MdOutlineClose
              onClick={() => {
                setMeetingModal(false);
              }}
              className="w-8 h-8 cursor-pointer"
            />
          </div>
          <NotificationBar />
          <Tabs
            value={currentTabIndex}
            variant="fullWidth"
            onChange={(_, newValue) => setCurrentTabIndex(newValue)}
            indicatorColor="primary"
          >
            <Tab
              label="Rooms"
              style={{ fontWeight: currentTabIndex === 0 ? 'bold' : 'normal' }}
            />
            <Tab
              label="Recordings"
              style={{ fontWeight: currentTabIndex === 1 ? 'bold' : 'normal' }}
            />
          </Tabs>
          <div
            hidden={currentTabIndex !== 0}
            style={{ maxHeight: '90vh' }}
            className="overflow-y-scroll"
          >
            <OpenRooms
              communityId={community_id}
              communityName={communityName}
              setNotification={setNotification}
              externalRooms={externalRooms}
              setExternalRooms={setExternalRooms}
              externalInitialLoading={externalInitialLoading}
              setExternalInitialLoading={setExternalInitialLoading}
            />
          </div>
          <div
            hidden={currentTabIndex !== 1}
            style={{ maxHeight: '90vh' }}
            className="overflow-y-scroll"
          >
            <PastRecordings
              communityId={community_id}
              className="bg-gray-200"
            />
          </div>
        </>
      )}
    </AnimatedDialog>
  );
};

export default VirtualRooms;
