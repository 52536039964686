import React, { useEffect, useRef, useState } from 'react';
import { buttonClick } from '../../utils/api';
import { get } from '../../utils/request';

export default function VideosBanner({ query, currRoute }) {
  // Magnet uuid
  const magnet_id = query.get('magnet_uuid');
  // Template params
  const videos = query.get('share').split(',');

  // Video template
  const [template, setTemplate] = useState();

  // Get video template from backend via GET request
  function getVideoTemplate() {
    get(`/magnets/template?magnet_uuid=${magnet_id}`, { auth: false })
      .then((data) => {
        setTemplate(data.magnet.magnet_details.template);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  useEffect(() => {
    getVideoTemplate();
  }, []);

  return (
    <div className="property-section">
      <div className="share-video-container video-list">
        <div
          role="list"
          className="property-list animated animatedFadeInUp fadeInUp"
        >
          {template &&
            videos.map((video) => {
              return (
                <Video
                  key={video}
                  query={query}
                  template={template}
                  route={video}
                  currRoute={currRoute}
                />
              );
            })}
          {videos.length === 0 && (
            <div className="no-videos-found">
              <h2>No videos found.</h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function Video({ query, template, route, currRoute }) {
  const videoRef = useRef();
  const routes = route.split('.');
  const video_template = template.categories[routes[0]]['screens'][routes[1]];

  // Allow video to continue playing when user hovers over play button
  function playVideo() {
    // Play video
    videoRef.current.play();
  }

  return (
    <div role="listitem" className="collection-item">
      <a
        href={`/properties/${route}?${query}`}
        onClick={() => {
          buttonClick(currRoute, route);
        }}
        className="property-card"
      >
        <div className="property-image-wrap">
          <video
            ref={videoRef}
            className="property-image"
            onMouseOver={(event) => event.target.play()}
            onMouseOut={(event) => event.target.pause()}
            loop
            muted
          >
            <source src={video_template.video} />
            <p className="vjs-no-js">
              To view this video please enable JavaScript, and consider
              upgrading to a web browser that
              <a
                href="https://videojs.com/html5-video-support/"
                target="_blank"
              >
                supports HTML5 video
              </a>
            </p>
          </video>
        </div>
        <div onMouseOver={playVideo} id="pause-video" className="html-embed">
          <svg fill="none" height="96" width="96">
            <path
              clip-rule="evenodd"
              d="M48 96c26.51 0 48-21.49 48-48S74.51 0 48 0 0 21.49 0 48s21.49 48 48 48z"
              fill="#fff"
              fill-rule="evenodd"
            ></path>
            <path
              clip-rule="evenodd"
              d="M37.326 33.822c0-2.408 2.695-3.835 4.687-2.481l20.862 14.178c1.752 1.19 1.752 3.772 0 4.963L42.013 64.66c-1.992 1.354-4.687-.072-4.687-2.48V33.821z"
              fill="#000"
              fill-rule="evenodd"
            ></path>
          </svg>
        </div>
        <div className="property-content">
          <h5>{video_template.title}</h5>
          <div>
            {video_template?.additionalInfo?.shareCaption
              ? `${video_template.additionalInfo.shareCaption}`
              : ''}
          </div>
        </div>
        <div className="room-feature-grid">
          <div className="feature">
            <div>{'▶️'} Take Video Tour</div>
          </div>
        </div>
      </a>
    </div>
  );
}
