import React from 'react';

function VideoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="16"
      fill="none"
      viewBox="0 0 25 16"
    >
      <path
        fill="#3898EC"
        d="M14.303 0c2.01 0 3.127 1.102 3.167 3.016l.002.107v9.764c0 1.935-1.18 3.072-3.162 3.112l-.109.001H3.271C1.258 16 .043 14.898 0 12.992L0 12.886V3.123C0 1.178 1.268.041 3.166.001L3.27 0h11.033zm9.383 1.507c.769 0 1.28.526 1.312 1.292l.002.078v10.246c0 .808-.52 1.37-1.314 1.37-.34 0-.716-.162-1.046-.421l-.075-.062-3.708-3.104V5.084l3.708-3.094c.347-.295.754-.483 1.12-.483z"
      ></path>
    </svg>
  );
}

export default VideoIcon;
