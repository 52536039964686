import React, { useContext } from 'react';
import './ContactCard.css';
import { EmailIcon, LinkedinIcon, PhoneIcon } from './Icons';
import MagnetContext from './MagnetContext';
import { HOST } from './util';

export const ContactCard = ({ contactInfo }) => {
  const { uuid, currentCategory, currentScreen } = useContext(MagnetContext);

  const downloadContact = () => {
    fetch(
      `${HOST}/contact/${uuid}.vcf?category_key=${currentCategory}&screen_key=${currentScreen}`
    ).then((response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'contact.vcf';
        a.click();
      });
    });
  };

  return (
    <div className="contact-card-container">
      <div className="contact-info-main">
        <img
          alt="profile picture"
          className="contact-card-profile-picture"
          src={contactInfo.profile_picture}
        />
        {contactInfo.name && (
          <div className="contact-card-name">{contactInfo.name}</div>
        )}
        {contactInfo.org && (
          <div className="contact-card-company">
            {contactInfo.org}
            {contactInfo.website && `, ${contactInfo.website}`}
          </div>
        )}
        <a className="contact-card-download-button" onClick={downloadContact}>
          +<span>Add Contact</span>
        </a>
        <hr className="contact-card-divider" />
        <div className="contact-details-container">
          {contactInfo.email && (
            <a
              className="contact-detail"
              href={`mailto:${contactInfo.email}`}
              target="_blank"
              rel="noreferrer"
            >
              <EmailIcon />
              <span>{contactInfo.email}</span>
            </a>
          )}
          {contactInfo.phone && (
            <a
              className="contact-detail"
              href={`tel:${contactInfo.phone}`}
              target="_blank"
              rel="noreferrer"
            >
              <PhoneIcon />
              <span>{contactInfo.phone}</span>
            </a>
          )}
          {contactInfo.linkedin && (
            <a
              className="contact-detail"
              href={contactInfo.linkedin}
              target="_blank"
              rel="noreferrer"
            >
              <LinkedinIcon />
              <span>{contactInfo.linkedin}</span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
