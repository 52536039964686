import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Header } from '../../components';
import ContentLoader from '../../components/ContentLoader';
import firebase from '../../utils/base';
import { useRequest } from '../../utils/request';
import Analytics from './Analytics';
import Embed from './Embed';
import Integrations from './Integrations';
import Leads from './Leads';
import Reporting from './Reporting';
import Template from './Template';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Videomagnet = (props) => {
  const [loading, error, data, makeRequest] = useRequest({ loading: true });
  const { key_value, name } = props;

  var { form_id } = useParams();
  const [value, setValue] = React.useState(0);

  if (key_value) {
    form_id = key_value;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [form, setForm] = React.useState({});
  const [metrics, setMetrics] = React.useState({
    interval: 'daily',
    since: '2020-09-15',
    until: '2020-11-11',
    platforms: ['desktop', 'unknown', 'other', 'mobile', 'tablet'],
    totals: {
      landed: 786,
      unique_landed: 395,
      interacted: 631,
      answered: 611,
      completed: 60,
    },
    data: [
      {
        id: '2020-09-15',
        landed: 10,
        unique_landed: 6,
        interacted: 9,
        answered: 9,
        completed: 3,
      },
    ],
  });

  // Firebase cheatsheet https://medium.com/@aaron_lu1/firebase-cloud-firestore-add-set-update-delete-get-data-6da566513b1b
  const loadFormIdFirebase = async (uid) => {
    try {
      const doc = await firebase.db.collection('videoembed').doc(form_id).get();
      console.log('lets see if form id exists');
      if (doc.exists) {
        // form id exists
        console.log(
          'user exists!!! now lets do something with it firebaseStuff'
        );
      } else {
        console.log('User does not exist, lets create it firebaseStuff');
        firebase.db.collection('videoembed').doc(form_id).set({});
      }
    } catch (err) {
      console.log('Failure getting document, check firebase key :', err);
    }
  };

  async function getFormApi() {
    const res = await makeRequest(
      `/getforms?limit=100&form_id=${form_id}`,
      'GET',
      null,
      {
        auth: false,
      }
    );
    setForm(res);
    console.log('form id results: ', res);
  }

  const [days, setDays] = useState(365);
  async function getMetrics(daysPrev = days) {
    // '&since=' + since + '&to=' + to
    const res = await makeRequest(
      `${'/getmetrics' + '?form_id='}${form_id}&days=${daysPrev}`,
      'GET',
      null,
      { auth: false }
    );
    setMetrics(res);
    console.log('metrics results: ', res);
  }

  useEffect(() => {
    loadFormIdFirebase(form_id);
    getFormApi();
    getMetrics();
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const tabParam = urlParams.get('tab');
  const backParam = urlParams.get('back');

  useEffect(() => {
    if ([0, 1, 2, 3, 4].includes(parseInt(tabParam))) {
      setValue(parseInt(tabParam));
    }
  }, [tabParam]);

  // let form = require('./forms.json');
  // top

  const { screenToEdit } = useSelector((state) => state.magnetStates);
  const _magnetData = useSelector((state) => state.getMagnet);
  const { template } = _magnetData;

  const [category, screen] = useMemo(
    () => screenToEdit.split('.'),
    [screenToEdit]
  );
  var categoryTitle = useMemo(
    () => (category ? template?.categories[category]?.title : ''),
    [category, template]
  );

  return (
    <div>
      {/* <Navbar customHomepage={backParam} /> */}
      <Header>
        <div className="dashboard-hdr flex aic w-full">
          <div className="left flex aic">
            <a href="#" target="_blank">
              <OpenInNewIcon />
            </a>
            &nbsp;&nbsp;
            <Link to="/" className="item flex aic">
              <div className="txt font s14 b3 anim">{name}</div>
              {name ? <span className="slash">&nbsp;/&nbsp;</span> : null}
            </Link>
            <div className="item flex aic">
              <div
                onClick={() => {
                  _dispatch({
                    type: 'SCREEN_TO_EDIT',
                    payload: '',
                  });
                  window.history.pushState({}, `View ${community_id}`, `#`);
                }}
                className="txt font s14 b5 black"
              >
                Analytics
              </div>
              {categoryTitle && <span className="slash">&nbsp;/&nbsp;</span>}
            </div>
            {categoryTitle && (
              <div className="item flex aic">
                <div className="txt font s14 b5 black">{categoryTitle}</div>
              </div>
            )}
          </div>
          <div className="right flex aic">
            <Link
              to={`./reviews/settings`}
              className="create-btn cleanbtn font anim s15"
            >
              <button className="cleanbtn add-category font s14 b5 cfff flex aic">
                <span className="ico icon-plus s16 cfff" />
                Survey Settings
              </button>
            </Link>
          </div>
        </div>
      </Header>
      <ContentLoader loading={loading} error={error}>
        <Paper square>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="disabled tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Analytics" {...a11yProps(0)} />
            <Tab label="Leads" {...a11yProps(1)} />
            {/* <Tab label="Integrations" {...a11yProps(2)} /> */}
            {/* <Tab label="Reporting" {...a11yProps(3)} /> */}
            {/* <Tab label="Embed" {...a11yProps(4)} /> */}
            {/* <Tab label="Template" {...a11yProps(5)} /> */}
          </Tabs>
        </Paper>

        <TabPanel value={value} index={0}>
          Analytics
          <Analytics
            form_id={form_id}
            form={form}
            metrics={metrics}
            getMetrics={getMetrics}
            days={days}
            setDays={setDays}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          Leads
          <Leads form_id={form_id} form={form} status="completed" />
        </TabPanel>

        <TabPanel value={value} index={2}>
          Integrations
          <Integrations form_id={form_id} form={form} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          Reporting
          <Reporting form_id={form_id} form={form} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          Embed
          <Embed form_id={form_id} form={form} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          Template
          <Template form_id={form_id} form={form} />
        </TabPanel>
      </ContentLoader>
    </div>
  );
};

export default Videomagnet;
