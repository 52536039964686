import React from 'react';

function SidebarTeamIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 22 19"
    >
      <path
        fill="#446888"
        d="M11.422.393a3.002 3.002 0 100 6.003 3.002 3.002 0 000-6.003zM18.182 1.892a2.253 2.253 0 100 4.507 2.253 2.253 0 000-4.507zM4.662 1.892a2.253 2.253 0 100 4.507 2.253 2.253 0 000-4.507zM6.915 9.393a1.502 1.502 0 011.503-1.492h6.009a1.502 1.502 0 011.502 1.503v4.506a4.508 4.508 0 01-9.014 0V9.393zM5.413 9.404c0-.549.146-1.061.402-1.503H2.409A1.502 1.502 0 00.906 9.404v3.755a3.755 3.755 0 005.162 3.484 5.983 5.983 0 01-.655-2.734V9.404zM17.431 9.404v4.506c0 .984-.236 1.913-.655 2.733a3.756 3.756 0 005.162-3.484V9.404A1.502 1.502 0 0020.436 7.9h-3.407c.255.442.402.954.402 1.503z"
      ></path>
    </svg>
  );
}

export default SidebarTeamIcon;
