import axios from 'axios';
import { API_HOST } from '../../utils/request';

export const getanalyicsbigquery = async (query) => {
  try {
    console.log('query', query);
    const { data } = await axios.post(
      `${API_HOST}/run_sqlquery_inbigquery`,
      {
        querystring: query,
      }
      //query
    );
    //console.log('goot data', data.res);
    return data;
  } catch (error) {
    console.log('errror', error);
  }
};

export const getmetabaseiframe = async (magnetuuid, setiframeurl) => {
  try {
    const { data } = await axios.post(
      `${API_HOST}/get_metabase_iframeurl_byuuid`,
      {
        dashboard_id: 10,
        dashboard_params: { magnet_uuid: [magnetuuid] },
      }
    );
    console.log('got data', data);
    setiframeurl(data.res);
  } catch (error) {
    console.log('errror', error);
  }
};

export const getentratadata = async (setfloorPlansJson) => {
  const res = await axios.get(
    `https://api.directual.com/good/api/v5/data/message/getAndCreateMessage?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&messageId=10`
  );
  if (res.data.payload[0].data) {
    getfloorplans(JSON.parse(res.data.payload[0].data), setfloorPlansJson);
  }
};

export const get_magnet_uuid_integrations = async (magnetuuid) => {
  try {
    const { data } = await axios.get(
      `https://api.leasemagnets.com/integration/details?magnet_uuid=${magnetuuid}`
    );
    console.log('got data', data);
    return data;
  } catch (error) {
    console.log('errror', error);
  }
};

export const fetch_events_analytics = async (filterdate,magnetuuid) => {
  console.log("filterdate",filterdate)
  try {
    const { data } = await axios.post(
      `${API_HOST}/bigquery_events_analytics`,
      {
        "from_date" : new Date(filterdate?.date).toISOString().split('T')[0],
        "to_date" : new Date().toISOString().split('T')[0],
        "magnet_uuid" : magnetuuid,
        "hour_start" : "0",
        "hour_end" : "24",
        "city" : "Washington"
      }
    );
    console.log("final_data",data)
    return data?.res;
  } catch (error) {
    console.log('errror', error);
  }
}

export const generate_data_frombigquery = async (
  filterdate,
  magnetuuid,
  settotaltours,
  settotalleads,
  setuniquevisitor,
  generatechartoptions,
  setchartOptions,
  generatechartoptionsmultipleseries,
  setformviews,
  setblackChartOptions,
  launchdate
) => {
  //// blackChart Start ///

  let ranges = {};
  let series = {};
  //let launchdate = '2023-06-29'

  let total_tour_query_res = await getanalyicsbigquery(
    'SELECT count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` > ' +
      `'${filterdate.date}' ` +
      (launchdate ? `AND \`default.events\`.\`_timestamp\` > TIMESTAMP('${launchdate}') ` : '') +
      "AND `default.events`.`event_type` = 'open_tour' AND `default.events`.`magnet_uuid` = " +
      `'${magnetuuid}'`
  );
  console.log("total_tour_query_res",total_tour_query_res)
  settotaltours(total_tour_query_res?.res[0]);

  // get leads
  let total_lead_query_res = await getanalyicsbigquery(
    'SELECT count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` > ' +
      `'${filterdate.date}' ` +
      (launchdate ? `AND \`default.events\`.\`_timestamp\` > TIMESTAMP('${launchdate}') ` : '') +
      "AND `default.events`.`event_type` LIKE '%form_submission%' AND `default.events`.`magnet_uuid` = " +
      `'${magnetuuid}'`
  );
  
  console.log("total_lead_query_res",total_lead_query_res)
  settotalleads(total_lead_query_res?.res[0]);

  // get uniquer visitors
  let uniquer_visitor_query_res = await getanalyicsbigquery(
    'SELECT COUNT(DISTINCT user_anonymous_id) as `count` FROM `default.events` WHERE `default.events`.`_timestamp` > ' +
      `'${filterdate.date}' ` +
      (launchdate ? `AND \`default.events\`.\`_timestamp\` > TIMESTAMP('${launchdate}') ` : '') +
      'AND `default.events`.`magnet_uuid` = ' +
      `'${magnetuuid}'`
  );
  
  setuniquevisitor(uniquer_visitor_query_res?.res[0]);
  // visitors data timeline
  // let unique_visitor_timeline = await getanalyicsbigquery(
  //   'SELECT timestamp_trunc(`default.events`.`_timestamp`, ' +
  //     `${
  //       filterdate.days < 30 ? 'DAY' : filterdate.days < 180 ? 'WEEK' : 'MONTH'
  //     }` +
  //     ') AS `_timestamp`, COUNT (DISTINCT user_anonymous_id) as `count` FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL -' +
  //     ` ${filterdate.days} ` +
  //     'day), day) AND magnet_uuid=' +
  //     `'${magnetuuid}'` +
  //     ' GROUP BY `_timestamp` ORDER BY `_timestamp` ASC'
  //     // add where default.events`.`_timestamp` less than launchdate
  // );
  let unique_visitor_timeline = await getanalyicsbigquery(
    'SELECT timestamp_trunc(`default.events`.`_timestamp`, ' +
      `${
        filterdate.days < 30 ? 'DAY' : filterdate.days < 180 ? 'WEEK' : 'MONTH'
      }` +
      ') AS `_timestamp`, COUNT(DISTINCT user_anonymous_id) as `count` FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL -' +
      ` ${filterdate.days} ` +
      'day), day) ' +
      (launchdate ? `AND \`default.events\`.\`_timestamp\` > timestamp_trunc('${launchdate}', day) ` : '') +
      'AND magnet_uuid=' +
      `'${magnetuuid}'` +
      ' GROUP BY `_timestamp` ORDER BY `_timestamp` ASC'
  );
  
  console.log("unique_%visitor_timeline",unique_visitor_timeline)
  ranges['visitors'] = 'Visitors';
  let visitor_chart_data = generate_black_chartoptions(
    unique_visitor_timeline
  );
  series['visitors'] = [{ name: 'Visitors', data: visitor_chart_data }];

  // tour data timeline
  let open_tour_timeline = await getanalyicsbigquery(
    'SELECT timestamp_trunc(`default.events`.`_timestamp`, ' +
      `${
        filterdate.days < 30 ? 'DAY' : filterdate.days < 180 ? 'WEEK' : 'MONTH'
      }` +
      ') AS `_timestamp`, count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL -' +
      ` ${filterdate.days} ` +
      'day), day) ' +
      (launchdate ? `AND \`default.events\`.\`_timestamp\` > timestamp_trunc(TIMESTAMP('${launchdate}'), day) ` : '') +
      'AND magnet_uuid=' +
      `'${magnetuuid}'` +
      ' AND `default.events`.`event_type` = "open_tour" GROUP BY `_timestamp` ORDER BY `_timestamp` ASC'
  );
  console.log("unique_%tour_timeline",open_tour_timeline)
  generatechartoptions(open_tour_timeline, setchartOptions);
  ranges['tours'] = 'Tours';
  let tour_chart_data = generate_black_chartoptions(open_tour_timeline);
  series['tours'] = [{ name: 'Tours', data: tour_chart_data }];

  // leads data timeline
  let lead_data_timeline_query_res = await getanalyicsbigquery(
    'SELECT `default.events`.`event_type` AS `event_type`, timestamp_trunc(`default.events`.`_timestamp`, ' +
      `${
        filterdate.days < 30 ? 'DAY' : filterdate.days < 180 ? 'WEEK' : 'MONTH'
      }` +
      ') AS `_timestamp`, count(*) AS `count`' +
      "FROM `default.events` WHERE (`default.events`.`event_type` = 'form_submission'" +
      'AND `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL -' +
      `${filterdate.days}` +
      ' day), day) AND `default.events`.`_timestamp` < timestamp_trunc(current_timestamp(), day) AND `default.events`.`magnet_uuid` = ' +
      `'${magnetuuid}'` +
      ')' +
      'GROUP BY `event_type`, `_timestamp` ORDER BY `event_type` ASC, `_timestamp` ASC'
  );
  console.log('lead_data_timeline_query_res', lead_data_timeline_query_res);
  ranges['leads'] = 'Leads';
  let lead_chart_data = generate_black_chartoptions(
    lead_data_timeline_query_res,
    1,
    2
  );
  series['leads'] = [{ name: 'Leads', data: lead_chart_data }];

  setblackChartOptions({ ranges, series });
  console.log('ADB, setBlackChartOptions ===> ', { ranges, series });
  //// blackChart end ///

  // Sleep for 10ms, Required in order to cache blackChartOptions properly without this, setting chartoptions overrides the blackchartoptions data
  // USE this only when caching chartoptions
  // await new Promise((r) => setTimeout(r, 10));

  generatechartoptionsmultipleseries(
    [
      {
        name: 'Tours',
        raw_data: open_tour_timeline,
        date_index: 0,
        value_index: 1,
      },
      {
        name: 'Leads',
        raw_data: lead_data_timeline_query_res,
        date_index: 1,
        value_index: 2,
        // settotalmethod: (item) => settotalleadsbounded(item),
      },
    ],
    setchartOptions
  );

  // Form path table
  let form_submission_timeline_query = await getanalyicsbigquery(
    'SELECT details_form_route FROM `default.events` WHERE  `default.events`.`_timestamp` > ' +
      `'${filterdate.date}'` +
      " AND `event_type` = 'form_submission' AND `magnet_uuid` = " +
      `'${magnetuuid}'`
  );

  let allroutes = [];
  let mod_routes = [];
  form_submission_timeline_query?.res?.map((item) => {
    if (item != null) {
      let p1 = item[0]?.split(',')[0]?.split('[')[1];
      let p2 = item[0]?.split(',')[1]?.split(']')[0];
      //allroutes.push(`${p1}.${p2}`)
      let path = `${p1}.${p2}`;
      if (allroutes.includes(path)) {
        mod_routes[path]['total'] = mod_routes[path]['total'] + 1;
      } else {
        mod_routes[path] = {
          route: path,
          total: 1,
        };
        allroutes.push(path);
      }
    }
  });
  let the_routes = [];
  allroutes.map((item) => {
    if (item != 'undefined.undefined') {
      the_routes.push(mod_routes[item]);
    }
  });
  setformviews(the_routes);
};

export const utmSourceFunc = async (
  selectedrow,
  setpopupchartloader,
  setScreenOpen,
  setthetitle,
  generatechartoptions,
  setchartOptionspopup,
  magnetUuid
) => {
  setpopupchartloader(true);
  setScreenOpen(true);
  setthetitle(selectedrow?.title);
  let resss = await getanalyicsbigquery(
    "SELECT timestamp_trunc(`default.events`.`_timestamp`, day) AS `_timestamp`, count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL - 90 day), day) AND (`default.events`.`utm_source` IS NOT NULL AND (`default.events`.`utm_source` <> '' OR `default.events`.`utm_source` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`utm_source` =' +
      `'${selectedrow?.title}'` +
      'GROUP BY `_timestamp` ORDER BY `_timestamp` ASC'
  );
  console.log('resss', resss);
  generatechartoptions(resss, setchartOptionspopup);
  let day_count_query_res = await getanalyicsbigquery(
    'SELECT  count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >' +
      `'${getISOdate(1)}'` +
      "AND (`default.events`.`utm_source` IS NOT NULL AND (`default.events`.`utm_source` <> '' OR `default.events`.`utm_source` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`utm_source` =' +
      `'${selectedrow?.title}'`
  );
  console.log('day_count_query_res', day_count_query_res);
  let one_weeK_count_query_res = await getanalyicsbigquery(
    'SELECT  count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >' +
      `'${getISOdate(7)}'` +
      "AND (`default.events`.`utm_source` IS NOT NULL AND (`default.events`.`utm_source` <> '' OR `default.events`.`utm_source` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`utm_source` =' +
      `'${selectedrow?.title}'`
  );
  console.log('one_weeK_count_query_res', one_weeK_count_query_res);
  let one_month_count_query_res = await getanalyicsbigquery(
    'SELECT  count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >' +
      `'${getISOdate(28)}'` +
      "AND (`default.events`.`utm_source` IS NOT NULL AND (`default.events`.`utm_source` <> '' OR `default.events`.`utm_source` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`utm_source` =' +
      `'${selectedrow?.title}'`
  );
  console.log('one_month_count_query_res', one_month_count_query_res);

  let total_count_query_res = await getanalyicsbigquery(
    "SELECT  count(*) AS `count` FROM `default.events` WHERE (`default.events`.`utm_source` IS NOT NULL AND (`default.events`.`utm_source` <> '' OR `default.events`.`utm_source` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`utm_source` =' +
      `'${selectedrow?.title}'`
  );
  console.log('total_count_query_res', total_count_query_res);
  return {
    daily: day_count_query_res.res[0],
    weekly: one_weeK_count_query_res.res[0],
    monthly: one_month_count_query_res.res[0],
    all: total_count_query_res.res[0],
  };
};

export const generatechartoptions = (
  data,
  setoptionmethod,
  settotalmethod,
  seriesLabel = 'Tours'
) => {
  let series = [];
  let labels = [];
  let total = 0;
  data?.res?.map((item) => {
    series.push(item[1]);
    labels.push(item[0]);
    total = total + item[1];
  });
  //settotal(total);
  console.log('converted', { labels }, { series });
  if (data?.res) {
    setoptionmethod({
      series: [
        {
          name: seriesLabel,
          data: series,
        },
      ],
      height: '100%',
      type: 'area',
      options: {
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        xaxis: {
          type: 'datetime',
        },
        labels,
        //colors: [colors.blue],
        fill: {
          opacity: 0.16,
        },
        sparkline: {
          enabled: true,
        },
      },
    });
    if (settotalmethod) {
      settotalmethod(total);
    }
  }
};

export const generate_black_chartoptions = (
  data,
  dateindex = 0,
  valueindex = 1
) => {
  let series = [];
  data?.res?.map((item) => {
    series.push({ x: item[dateindex], y: item[valueindex] });
  });
  return series;
};

const getfloorplans = async (data, setfloorPlansJson) => {
  try {
    const res = await axios.post(
      `https://api.leasemagnets.com/integrations/entrata/getMitsPropertyUnits`,
      {
        creds: {
          username: data.username,
          password: data.password,
        },
        propertyId: data.property_id,
      }
    );
    let floorPlans = [];
    if (res?.data?.resp?.result?.PhysicalProperty?.Property[0]?.Floorplan) {
      floorPlans =
        res.data.resp?.result?.PhysicalProperty?.Property[0]?.Floorplan;
    }
    console.log('floorPlans', floorPlans);
    const ilsUnits =
      res.data.resp?.result?.PhysicalProperty.Property[0].ILS_Unit;
    const floorPlanObj = floorPlans?.map((item, idx) => {
      const pic = item?.File?.map((file, fidx) => {
        if (file?.Src.includes('.jpg') || file?.Src.includes('.png')) {
          return file?.Src;
        }
        return null;
      }).filter((c) => c !== null);

      let roomTypeJson = {};
      item?.Room?.map((room, ridx) => {
        roomTypeJson[room?.['@attributes'].RoomType] = room?.Count;
      });

      let dynamicItems = ilsUnits
        ?.map((unit, uidx) => {
          if (item?.Name && item?.Name == unit?.Units.Unit.FloorplanName) {
            if (unit?.Availability?.VacancyClass == 'Unoccupied') {
              return unit?.Availability?.VacateDate['@attributes'];
            }
          }
          return null;
        })
        .filter((c) => c !== null);

      let itemObj = {
        name: item?.Name,
        rents: item?.MarketRent['@attributes'],
        unitsAvailable: item?.UnitsAvailable,
        sqFt: item?.SquareFeet['@attributes'],
        pic: pic?.[0],
        info: item?.FloorplanAvailabilityURL,
        rooms: roomTypeJson,
        available: dynamicItems?.[0],
      };
      //console.log('items: ', itemObj);
      return itemObj;
    });
    console.log('floorPlanObj', floorPlanObj);
    setfloorPlansJson(floorPlanObj);
  } catch (err) {
    console.log(err);
  }
};

function printDocument(inDiv) {
  // https://stackoverflow.com/questions/44989119/generating-a-pdf-file-from-react-components
  // may be needed later https://stackoverflow.com/questions/27045704/how-do-i-get-a-multi-page-pdf-from-a-website-using-jspdf-and-html2canvas
  setDownloadInProgress(true);
  const input = document.getElementById(inDiv);
  html2canvas(input)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'pt', 'a4', false);
      pdf.addImage(imgData, 'PNG', 20, 20, 560, 0, undefined, false);
      // pdf.output('dataurlnewwindow');
      pdf.save(`${name}_tour_video_Analytics.pdf`);
    })
    .then(() => setDownloadInProgress(false));
}

export const generatechartoptionsmultipleseries = (
  dataList,
  setoptionmethod
) => {
  //dataList={[{name:"Tours", raw_data:[], settotalmethod: totalMethod }]}
  //the labels need to be the same across series
  let seriesList = [];
  let labels = [];
  let labelsLookup = new Map();
  dataList.map((data, list_idx) => {
    let series = [];
    let total = 0;
    if (list_idx > 0) {
      series = new Array(labels.length || 0).fill(0);
    }
    data?.raw_data?.res?.map((item, item_idx) => {
      if (list_idx == 0) {
        series.push(item[data?.value_index]);
        labels.push(item[data?.date_index]);
        labelsLookup.set(item[data?.date_index], labels.length);
      } else {
        if (labelsLookup.has(item[data?.date_index])) {
          series[labelsLookup.get(item[data?.date_index])] =
            item[data?.value_index];
          console.log(
            'special logic: ',
            item[data?.date_index],
            item[data?.value_index],
            labelsLookup.get(item[data?.date_index]),
            labelsLookup,
            series
          );
        }
        total = total + data?.value_index;
      }
    });

    seriesList.push({
      name: data?.name || `Series ${idx}`,
      data: series,
    });
    if (data?.settotalmethod) {
      data?.settotalmethod(total);
    }
  });

  console.log('converted multiple', seriesList, labels);
  if (seriesList) {
    setoptionmethod({
      series: seriesList,
      height: '100%',
      type: 'area',
      options: {
        chart: {
          // sparkline: {
          //   enabled: true,
          // },
          height: 350,
          type: 'area',
          animations: { enabled: false },
          toolbar: { show: false },
          zoom: { enabled: false },
        },
        yaxis: {
          enabled: false,
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          type: 'datetime',
        },
        labels,
        //colors: [colors.blue],
        fill: {
          opacity: 0.16,
        },
        // sparkline: {
        //   enabled: true,
        // },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          x: {
            format: 'ddd MM/dd/yy',
          },
        },
      },
    });
  }
};

export const count_details_from_rows = async (
  magnetuuid,
  template,
  setBarChartData,
  setCategoryData
) => {
  try {
    const { data } = await axios.post(
      `https://api.leasemagnets.com/count_details_from_rows`,
      {
        magnet_uuid: magnetuuid,
      }
    );
    console.log('got count_details_from_rows', data);
    let categoryNames = [];
    let categoryTotals = [];
    let screenobj = {};
    let categoryScreenNames = [];
    let categoryScreenTotals = [];
    let allScreenNames = [];
    let allScreenTotals = [];
    if (data?.res) {
      data?.res?.map((item) => {
        if (
          template?.categories[item[0]?.split('.')[0]]?.screens[
            item[0]?.split('.')[1]
          ]?.title &&
          template?.categories[item[0]?.split('.')[0]]?.title
        ) {
          categoryScreenNames.push([
            template?.categories[item[0].split('.')[0]]?.screens[
              item[0].split('.')[1]
            ]?.title,
          ]);
          categoryScreenTotals.push([item[1]]);
          allScreenNames.push(
            `${
              template?.categories[item[0].split('.')[0]]?.screens[
                item[0].split('.')[1]
              ]?.title
            } -  ${template?.categories[item[0].split('.')[0]]?.title}`
          );
          allScreenTotals.push(item[1]);
          if (screenobj[item[0].split('.')[0]]) {
            screenobj[item[0].split('.')[0]] =
              screenobj[item[0].split('.')[0]] + item[1];
          } else {
            screenobj[item[0].split('.')[0]] = item[1];
          }
        }
      });
      Object.keys(screenobj).map((item) => {
        categoryNames.push(template?.categories[item]?.title);
        categoryTotals.push(screenobj[item]);
      });
    }
    let templatecategorynames = [];
    categoryNames.map((item) => {
      templatecategorynames.push(template?.categories[item]?.title);
    });
    let tempcategory = [];
    let tempcategoryScreenNames = [];
    let tempcategoryScreenTotals = [];
    allScreenNames.map((item, index) => {
      if (tempcategory.includes(item?.split(' - ')[1])) {
        let theindex = tempcategory.findIndex(
          (item2) => item2 == item?.split(' - ')[1]
        );
        tempcategoryScreenNames[theindex]?.push(item?.split(' - ')[0]);
        tempcategoryScreenTotals[theindex]?.push(allScreenTotals[index]);
      } else {
        tempcategory.push(item?.split(' - ')[1]);
        tempcategoryScreenNames.push([item?.split(' - ')[0]]);
        tempcategoryScreenTotals.push([allScreenTotals[index]]);
      }
    });
    setBarChartData({
      title: 'All Screens',
      titles: allScreenNames,
      data: allScreenTotals,
    });
    setCategoryData({
      categoryNames,
      categoryTotals,
      allScreenNames,
      allScreenTotals,
      categoryScreenNames: tempcategoryScreenNames,
      categoryScreenTotals: tempcategoryScreenTotals,
    });
  } catch (error) {
    console.log('errror', error);
  }
};

export const chartOptions_init = {
  series: [
    {
      name: 'Tours',
      data: [91, 70, 70, 70, 70, 70, 70, 70],
    },
  ],
  height: '100%',
  type: 'area',
  options: {
    chart: {
      height: 350,
      type: 'area',
      animations: { enabled: false },
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    yaxis: {
      axisTicks: {
        enabled: false,
      },
      enabled: false,
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
    },
    tooltip: {
      x: {
        format: 'ddd MM/dd/yy',
      },
    },
  },
};

const getISOdate = (subtracteddays) => {
  const currDate = new Date();
  currDate.setDate(currDate.getDate() - subtracteddays);
  let thedate = currDate.toISOString();
  return thedate;
};

export const referalDomainsFunc = async (
  selectedrow,
  setpopupchartloader,
  setScreenOpen,
  setthetitle,
  generatechartoptions,
  setchartOptionspopup,
  magnetUuid
) => {
  setpopupchartloader(true);
  setScreenOpen(true);
  setthetitle(selectedrow?.title);
  let resss = await getanalyicsbigquery(
    'SELECT timestamp_trunc(`default.events`.`_timestamp`, day) AS `_timestamp`,count(url) as Total_Count FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL - 90 day), day) AND `default.events`.`magnet_uuid` =' +
      `'${magnetUuid}'` +
      'AND `default.events`.`url` LIKE ' +
      `"%${selectedrow?.title}%"` +
      'GROUP BY `_timestamp` ORDER BY `_timestamp` ASC'
  );
  console.log('resss', resss);
  generatechartoptions(resss, setchartOptionspopup);
  // last 24 hours
  let day_count_query_res = await getanalyicsbigquery(
    'SELECT  count(url) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >' +
      `'${getISOdate(1)}'` +
      'AND `default.events`.`magnet_uuid` =' +
      `'${magnetUuid}'` +
      'AND `default.events`.`url` LIKE ' +
      `"%${selectedrow?.title}%"`
  );
  console.log('day_count_query_res', day_count_query_res);
  let one_weeK_count_query_res = await getanalyicsbigquery(
    'SELECT  count(url) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >' +
      `'${getISOdate(7)}'` +
      'AND `default.events`.`magnet_uuid` =' +
      `'${magnetUuid}'` +
      'AND `default.events`.`url` LIKE ' +
      `"%${selectedrow?.title}%"`
  );
  console.log('one_weeK_count_query_res', one_weeK_count_query_res);
  let one_month_count_query_res = await getanalyicsbigquery(
    'SELECT  count(url) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >' +
      `'${getISOdate(28)}'` +
      'AND `default.events`.`magnet_uuid` =' +
      `'${magnetUuid}'` +
      'AND `default.events`.`url` LIKE ' +
      `"%${selectedrow?.title}%"`
  );
  console.log('one_month_count_query_res', one_month_count_query_res);

  let total_count_query_res = await getanalyicsbigquery(
    'SELECT  count(url) AS `count` FROM `default.events` WHERE ' +
      '`default.events`.`magnet_uuid` =' +
      `'${magnetUuid}'` +
      'AND `default.events`.`url` LIKE ' +
      `"%${selectedrow?.title}%"`
  );
  console.log('total_count_query_res', total_count_query_res);
  return {
    daily: day_count_query_res.res[0],
    weekly: one_weeK_count_query_res.res[0],
    monthly: one_month_count_query_res.res[0],
    all: total_count_query_res.res[0],
  };
};
export const popularPagesFunc = async (
  selectedrow,
  setpopupchartloader,
  setScreenOpen,
  setthetitle,
  generatechartoptions,
  setchartOptionspopup,
  magnetUuid
) => {
  setpopupchartloader(true);
  setScreenOpen(true);
  setthetitle(selectedrow?.title);
  let resss = await getanalyicsbigquery(
    "SELECT timestamp_trunc(`default.events`.`_timestamp`, day) AS `_timestamp`, count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL - 90 day), day) AND (`default.events`.`page_title` IS NOT NULL AND (`default.events`.`page_title` <> '' OR `default.events`.`page_title` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`page_title` =' +
      `'${selectedrow?.title}'` +
      'GROUP BY `_timestamp` ORDER BY `_timestamp` ASC'
  );
  console.log('resss', resss);
  generatechartoptions(resss, setchartOptionspopup);
  // last 24 hours
  let day_count_query_res = await getanalyicsbigquery(
    'SELECT  count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >' +
      `'${getISOdate(1)}'` +
      "AND (`default.events`.`page_title` IS NOT NULL AND (`default.events`.`page_title` <> '' OR `default.events`.`page_title` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`page_title` =' +
      `'${selectedrow?.title}'`
  );
  console.log('day_count_query_res', day_count_query_res);
  let one_weeK_count_query_res = await getanalyicsbigquery(
    'SELECT  count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >' +
      `'${getISOdate(7)}'` +
      "AND (`default.events`.`page_title` IS NOT NULL AND (`default.events`.`page_title` <> '' OR `default.events`.`page_title` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`page_title` =' +
      `'${selectedrow?.title}'`
  );
  console.log('one_weeK_count_query_res', one_weeK_count_query_res);
  let one_month_count_query_res = await getanalyicsbigquery(
    'SELECT  count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >' +
      `'${getISOdate(28)}'` +
      "AND (`default.events`.`page_title` IS NOT NULL AND (`default.events`.`page_title` <> '' OR `default.events`.`page_title` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`page_title` =' +
      `'${selectedrow?.title}'`
  );
  console.log('one_month_count_query_res', one_month_count_query_res);

  let total_count_query_res = await getanalyicsbigquery(
    "SELECT  count(*) AS `count` FROM `default.events` WHERE (`default.events`.`page_title` IS NOT NULL AND (`default.events`.`page_title` <> '' OR `default.events`.`page_title` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`page_title` =' +
      `'${selectedrow?.title}'`
  );
  console.log('day_count_query_res', total_count_query_res);
  return {
    daily: day_count_query_res.res[0],
    weekly: one_weeK_count_query_res.res[0],
    monthly: one_month_count_query_res.res[0],
    all: total_count_query_res.res[0],
  };
};
export const utmCampainFunc = async (
  selectedrow,
  setpopupchartloader,
  setScreenOpen,
  setthetitle,
  generatechartoptions,
  setchartOptionspopup,
  magnetUuid
) => {
  //console.log("itemmm",item)
  setpopupchartloader(true);
  setScreenOpen(true);
  setthetitle(selectedrow?.title);
  //`default.events`.`_timestamp` > '2022-07-27T10:54:27.802Z'
  let resss = await getanalyicsbigquery(
    "SELECT timestamp_trunc(`default.events`.`_timestamp`, day) AS `_timestamp`, count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL - 90 day), day) AND (`default.events`.`utm_campaign` IS NOT NULL AND (`default.events`.`utm_campaign` <> '' OR `default.events`.`utm_campaign` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`utm_campaign` =' +
      `'${selectedrow?.title}'` +
      'GROUP BY `_timestamp` ORDER BY `_timestamp` ASC'
  );
  console.log('resss', resss);
  generatechartoptions(resss, setchartOptionspopup);
  // last 24 hours
  let day_count_query_res = await getanalyicsbigquery(
    'SELECT  count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >' +
      `'${getISOdate(1)}'` +
      "AND (`default.events`.`utm_campaign` IS NOT NULL AND (`default.events`.`utm_campaign` <> '' OR `default.events`.`utm_campaign` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`utm_campaign` =' +
      `'${selectedrow?.title}'`
  );
  console.log('day_count_query_res', day_count_query_res);
  let one_weeK_count_query_res = await getanalyicsbigquery(
    'SELECT  count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >' +
      `'${getISOdate(7)}'` +
      "AND (`default.events`.`utm_campaign` IS NOT NULL AND (`default.events`.`utm_campaign` <> '' OR `default.events`.`utm_campaign` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`utm_campaign` =' +
      `'${selectedrow?.title}'`
  );
  console.log('one_weeK_count_query_res', one_weeK_count_query_res);
  let one_month_count_query_res = await getanalyicsbigquery(
    'SELECT  count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >' +
      `'${getISOdate(28)}'` +
      "AND (`default.events`.`utm_campaign` IS NOT NULL AND (`default.events`.`utm_campaign` <> '' OR `default.events`.`utm_campaign` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`utm_campaign` =' +
      `'${selectedrow?.title}'`
  );
  console.log('one_month_count_query_res', one_month_count_query_res);

  let total_count_query_res = await getanalyicsbigquery(
    "SELECT  count(*) AS `count` FROM `default.events` WHERE (`default.events`.`utm_campaign` IS NOT NULL AND (`default.events`.`utm_campaign` <> '' OR `default.events`.`utm_campaign` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`utm_campaign` =' +
      `'${selectedrow?.title}'`
  );
  console.log('day_count_query_res', total_count_query_res);
  return {
    daily: day_count_query_res.res[0],
    weekly: one_weeK_count_query_res.res[0],
    monthly: one_month_count_query_res.res[0],
    all: total_count_query_res.res[0],
  };
};

export const utmMediumFunc = async (
  selectedrow,
  setpopupchartloader,
  setScreenOpen,
  setthetitle,
  generatechartoptions,
  setchartOptionspopup,
  magnetUuid
) => {
  setpopupchartloader(true);
  setScreenOpen(true);
  setthetitle(selectedrow?.title);
  let resss = await getanalyicsbigquery(
    "SELECT timestamp_trunc(`default.events`.`_timestamp`, day) AS `_timestamp`, count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL - 90 day), day) AND (`default.events`.`utm_medium` IS NOT NULL AND (`default.events`.`utm_medium` <> '' OR `default.events`.`utm_medium` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`utm_medium` =' +
      `'${selectedrow?.title}'` +
      'GROUP BY `_timestamp` ORDER BY `_timestamp` ASC'
  );
  console.log('resss', resss);
  generatechartoptions(resss, setchartOptionspopup);
  let day_count_query_res = await getanalyicsbigquery(
    'SELECT  count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >' +
      `'${getISOdate(1)}'` +
      "AND (`default.events`.`utm_medium` IS NOT NULL AND (`default.events`.`utm_medium` <> '' OR `default.events`.`utm_medium` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`utm_medium` =' +
      `'${selectedrow?.title}'`
  );
  console.log('day_count_query_res', day_count_query_res);
  let one_weeK_count_query_res = await getanalyicsbigquery(
    'SELECT  count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >' +
      `'${getISOdate(7)}'` +
      "AND (`default.events`.`utm_medium` IS NOT NULL AND (`default.events`.`utm_medium` <> '' OR `default.events`.`utm_medium` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`utm_medium` =' +
      `'${selectedrow?.title}'`
  );
  console.log('one_weeK_count_query_res', one_weeK_count_query_res);
  let one_month_count_query_res = await getanalyicsbigquery(
    'SELECT  count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >' +
      `'${getISOdate(28)}'` +
      "AND (`default.events`.`utm_medium` IS NOT NULL AND (`default.events`.`utm_medium` <> '' OR `default.events`.`utm_medium` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`utm_medium` =' +
      `'${selectedrow?.title}'`
  );
  console.log('one_month_count_query_res', one_month_count_query_res);

  let total_count_query_res = await getanalyicsbigquery(
    "SELECT  count(*) AS `count` FROM `default.events` WHERE (`default.events`.`utm_medium` IS NOT NULL AND (`default.events`.`utm_medium` <> '' OR `default.events`.`utm_medium` IS NULL)) AND `default.events`.`magnet_uuid` =" +
      `'${magnetUuid}'` +
      'AND `default.events`.`utm_medium` =' +
      `'${selectedrow?.title}'`
  );
  console.log('day_count_query_res', total_count_query_res);
  return {
    daily: day_count_query_res.res[0],
    weekly: one_weeK_count_query_res.res[0],
    monthly: one_month_count_query_res.res[0],
    all: total_count_query_res.res[0],
  };
};

export const get_typewriter_custom_stats = async (
  days,
  event_type,
  magnetuuid
) => {
  try {
    let typewriter_query_res = await getanalyicsbigquery(
      'SELECT  count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` > ' +
        `'${getISOdate(days)}'` +
        ' AND `default.events`.`event_type` = ' +
        `'${event_type}'` +
        ' AND `default.events`.`magnet_uuid` =' +
        `'${magnetuuid}'`
    );
    return typewriter_query_res?.res[0];
  } catch (error) {
    console.log('errror', error);
  }
};
