export const getAllReferralsReducer = (
  state = { referralsList: {} },
  action
) => {
  switch (action.type) {
    case 'GET_ALL_REFERRALS_REQUEST':
      return {
        referralsListLoading: true,
        referralsList: {},
      };
    case 'GET_ALL_REFERRALS_SUCCESS':
      return {
        referralsListLoading: false,
        referralsList: action.payload,
      };
    case 'GET_ALL_REFERRALS_FAIL':
      return {
        referralsListLoading: false,
        referralsListError: action.payload,
      };
    default:
      return state;
  }
};

export const getReferralUsersReducer = (
  state = { referralUsers: {} },
  action
) => {
  switch (action.type) {
    case 'GET_REFERRAL_USERS_REQUEST':
      return {
        referralUserLoading: true,
        referralUsers: {},
      };
    case 'GET_REFERRAL_USERS_SUCCESS':
      return {
        referralUserLoading: false,
        referralUsers: action.payload,
      };
    case 'GET_REFERRAL_USERS_FAIL':
      return {
        referralUserLoading: false,
        referralUserError: action.payload,
      };
    default:
      return state;
  }
};
