import { Grid } from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Alert from '@material-ui/lab/Alert';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import ButtonDialog from '../../components/Reusable/ButtonDialog';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import SimpleAccordion from '../../components/SimpleAccordion';
// get entrata alerts
import { get, post, useRequest } from '../../utils/request';
//import reusable component
import EmailLeads from './EmailLeads';
import EmailListIntegration from './EmailListIntegration';
import EntrataIntegration from './EntrataIntegration';

const tableIcons = {
  Add: forwardRef((props, ref) => (
    <AddBox {...props} ref={ref} keyboard-shortcut="add-icon-handler" />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Integrations = (props) => {
  const { form_id, community_id, name } = props;
  const [loading, error, data, makeRequest] = useRequest({ loading: false });
  const [leadError, setLeadError] = useState();
  const [emailError, setEmailError] = useState();
  const [currEmail, setCurrEmail] = useState();
  const [facebookPixel, setFacebookPixel] = useState('');
  const [googleAnalyticsVal, setGoogleAnalyticsVal] = useState('');

  const [uuid, setUuid] = useState();

  const [plainEmail, setPlainEmail] = useState({
    team_email: '',
    live: false,
  });

  const [weeklyReportingEmails, setWeeklyReportingEmails] = useState({
    team_email: '',
    live: false,
  });

  const [customizableEmail, setCustomizableEmail] = useState({
    emailType: 'promo',
    subject: '',
    template: '',
    cmpEmail: '',
    live: false,
  });

  const [newLeadEmail, setNewLeadEmail] = useState({
    team_email: '',
    live: false,
  });

  useEffect(() => {
    if (!form_id) {
      makeRequest(`/magnets?queryByCommunity=${community_id}`).then((res) => {
        setUuid(res.magnets[0].uuid);
        console.log('the restulstss are ', res);
        getAllIntegrations(res.magnets[0].uuid);
      });
    } else {
      getAllIntegrations(form_id);
    }
  }, [community_id]);

  async function getAllIntegrations(form_id) {
    const res = await get(`/integration/details?magnet_uuid=${form_id}`);
    if (res.status !== 'fail') {
      console.log('integrations json = ', res);
      if (res['email-team']) {
        setNewLeadEmail(res['email-team']);
      }
      if (res['email-lead-promo']) {
        setCustomizableEmail(res['email-lead-promo']);
      }
      if (res['facebook-pixel']) {
        setFacebookPixel(res['facebook-pixel']);
      }
      if (res['google-analytics']) {
        setGoogleAnalyticsVal(res['google-analytics']);
      }
      if (res['email-plain']) {
        setPlainEmail(res['email-plain']);
      }
      if (res['weekly-reporting']) {
        setWeeklyReportingEmails(res['weekly-reporting']);
      }
    }
  }

  const emailImage =
    require('../../assets/integrationImages/email.png').default;
  const emailImage1 =
    require('../../assets/integrationImages/email11.png').default;
  const facebookLogo =
    require('../../assets/integrationImages/facebook-logo.png').default;
  const googleAnalytics =
    require('../../assets/integrationImages/googleAnalytic.png').default;
  const entrataLogo =
    require('../../assets/integrationImages/entrata.png').default;

  const plainEmailRef = useRef();
  function handleSubmitPlainEmail(email = currEmail) {
    const re = /^[^\s@]+@[^\s@]+$/;
    if (plainEmail.team_email?.includes(email.toLowerCase())) {
      setEmailError('Email already entered');
    } else if (re.test(email)) {
      setEmailError();
      let temp = plainEmail.team_email.split(',').filter((o) => o);
      temp.push(email.toLowerCase());
      setPlainEmail({ ...plainEmail, team_email: temp.join(',') });
      setCurrEmail('');
      plainEmailRef.current.value = '';
    } else {
      setEmailError('Please enter a valid email address');
    }
  }

  return (
    <div className="integrat-page">
      <div className="integrat-title font s18 b6 c000">
        Setup your integration
      </div>
      {loading ? (
        <div className="integrat-wrapper">
          {[{}, {}, {}, {}, {}, {}, {}].map((ev, idx) => (
            <div className="wrap-block holder" />
          ))}
        </div>
      ) : error ? (
        <div className="font t-c s16 b5 red">{error}</div>
      ) : (
        <Grid container spacing={3}>
          <ButtonDialog
            title="Entrata API"
            width={'150px'}
            height={'150px'}
            color={'#e2e2e2'}
            image={entrataLogo}
          >
            <SimpleAccordion title="Entrata">
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <EntrataIntegration form_id={form_id ? form_id : uuid} />
                </Grid>
              </Grid>
            </SimpleAccordion>
          </ButtonDialog>

          <EmailListIntegration
            uuid={form_id ? form_id : uuid}
            integrationKey="weekly-reporting"
            emails={weeklyReportingEmails}
            setEmails={setWeeklyReportingEmails}
            title="Weekly reporting emails"
            note="Enter emails for weekly reporting below."
            icon={<MailOutlineIcon fontSize={'large'} />}
            color={'#ea4335'}
            image={emailImage}
            includeStartDateTime={true}
            sendTestEmail={(emails) =>
              post(
                `/email/analytics`,
                { uuid: uuid, name: name, cid: community_id, target: emails },
                { auth: false }
              )
            }
          />

          <EmailListIntegration
            uuid={form_id ? form_id : uuid}
            integrationKey="email-plain"
            emails={plainEmail}
            setEmails={setPlainEmail}
            title="Plain text lead email"
            note="Add emails to receive a plain text email when a lead is submitted."
            icon={<MailOutlineIcon fontSize={'large'} />}
            color={'#32a852'}
            image={emailImage}
          />

          <ButtonDialog
            title={'Email to Leads'}
            icon={<AllInboxIcon fontSize={'large'} />}
            width={'150px'}
            height={'150px'}
            color={'#8F0EB6'}
            image={emailImage1}
            customButtonText="Save"
            customButtonFunction={async () => {
              setLeadError();
              const res = await post('/integration/details', {
                magnet_uuid: form_id ? form_id : uuid,
                data: {
                  'email-lead-promo': customizableEmail,
                },
              });
              if (res.error) {
                setLeadError('Unable to update integrations please try again');
                return true;
              } else {
                return false;
              }
            }}
          >
            <EmailLeads
              customizableEmail={customizableEmail}
              setCustomizableEmail={setCustomizableEmail}
              form_id={form_id}
              uuid={uuid}
            />
          </ButtonDialog>

          <ButtonDialog
            title={'Facebook Pixel'}
            width={'150px'}
            height={'150px'}
            color={'#1778f2'}
            image={facebookLogo}
            customButtonText="Save"
            customButtonFunction={async () => {
              setLeadError();
              const res = await post('/integration/details', {
                magnet_uuid: form_id ? form_id : uuid,
                data: {
                  'facebook-pixel': facebookPixel,
                },
              });
              if (res.error) {
                setLeadError('Unable to update integrations please try again');
                return true;
              } else {
                return false;
              }
            }}
          >
            <DebouncedTextField
              fullWidth
              value={facebookPixel}
              onChange={setFacebookPixel}
            />
            {emailError && <Alert severity="error">{leadError}</Alert>}
          </ButtonDialog>

          <ButtonDialog
            title={'Google Analytics'}
            width={'150px'}
            height={'150px'}
            color={'#fbbc05'}
            image={googleAnalytics}
            customButtonText="Save"
            customButtonFunction={async () => {
              setLeadError();
              const res = await post('/integration/details', {
                magnet_uuid: form_id ? form_id : uuid,
                data: {
                  'google-analytics': googleAnalyticsVal,
                },
              });
              if (res.error) {
                setLeadError('Unable to update integrations please try again');
                return true;
              } else {
                return false;
              }
            }}
          >
            <DebouncedTextField
              fullWidth
              value={googleAnalyticsVal}
              onChange={setGoogleAnalyticsVal}
            />
            {emailError && <Alert severity="error">{leadError}</Alert>}
          </ButtonDialog>

          {/* <ButtonDialog
            title={'Google Analytics'}
            width={'150px'}
            height={'150px'}
            color={'#fbbc05'}
            image={googleAnalytics}
          >
            <SimpleAccordion
              title="Google Analytics / POST Request"
              startOpen={true}
            >
              Coming soon
              {form_id}
            </SimpleAccordion>
          </ButtonDialog> */}
        </Grid>
      )}
      {/* <div>
        <ContentLoader loading={loading} error={error} />
      </div> */}
    </div>
  );
};

export default Integrations;
