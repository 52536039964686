import axios from 'axios';
import { useEffect, useState } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import { validFileName } from '../../utils/files';
import { API_HOST } from '../../utils/request';

const SCOPE = ['https://www.googleapis.com/auth/driveconst'];

export default function GPickerButton({
  cloudLibrary,
  setCloudLibrary,
  currentCategory,
  currentScreen,
  communityId,
  magnetId,
}) {
  const [videos, setVideos] = useState([]);
  const [percent, setPercent] = useState(0);
  const [openPicker, authResponse] = useDrivePicker();
  const [show, setShow] = useState(false);
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  // const temp1 = gpick();
  const handleOpenPicker = () => {
    openPicker({
      clientId:
        '615679282659-rja4q91f2mr68h3bka1i4aqiaijjnoe0.apps.googleusercontent.com',
      developerKey: 'AIzaSyAnTU1wWsR6WuXCFriwLCKuz_xxHZbDsaU',
      viewId: 'PDFs',
      // token: token, // pass oauth token in case you already have one
      //showUploadView: true,
      // showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      setIncludeFolders: false,
      // disableDefaultView: true,
      // viewMimeTypes: [
      //   'application/vnd.google-apps.video',
      //   'application/vnd.google-apps.folder',
      //   'application/vnd.google-apps.photo',
      // ],
      callbackFunction: (data) => {
        if (data.action === 'cancel') {
          console.log('User clicked cancel/close button');
        }
        data?.docs?.map((video) => {
          console.log(video);
          setVideos((prevVideos) => [...prevVideos, video]);
        });
        console.log(data);
      },
      // customViews: customViewsArray, // custom view
    });
  };
  // https://stackoverflow.com/questions/59294233/google-drive-picker-api-show-folders-and-certain-mime-types-files

  //console.log('gdrive_data = ', data);
  console.log('videos', videos);
  console.log('authResponse', authResponse);
  async function uploadtoDrive(video) {
    // console.log('gdrive_data___',  {
    //   fileID: video.id,
    //   mimeType: video.mimeType,
    //   filename: video.name,
    //   access_token: authResponse.access_token,
    // },);
    setShow(true);
    setPercent(30);
    try {
      const response = await axios({
        method: 'POST',
        url: API_HOST + '/upload-drive',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          fileID: video.id,
          mimeType: video.mimeType,
          filename: video.name,
          //  a '/' is inserted before the ${prefix}
          prefix: `community/${communityId}/magnet/${magnetId}/category/${currentCategory}/screen/${currentScreen}`,
          access_token: authResponse.access_token,
        },
      });
      setPercent(50);
      console.log(response);
      //add it to videos
      setTimeout(() => {
        setPercent(100);
      }, 1000);
      setTimeout(() => {
        setShow(false);
      }, 2000);
      setCloudLibrary((prevList) => [
        ...prevList,
        {
          name: video.name,
          type: video.mimeType,
          origName: video.name,
          prefix: ``,
          dataURL: response.data + '?',
          formData: { video: response.data + '?' },
          saveToCloud: true,
        },
      ]);
    } catch (e) {
      console.log('error = ', e);
    }
  }
  // useEffect(() => {
  //   // do anything with the selected/uploaded files
  //   if (data) {
  //     setVideos([]);
  //     data?.docs?.map((video) => {
  //       console.log(video);
  //       setVideos((prevVideos) => [...prevVideos, video]);
  //     });
  //   }
  //   console.log('gdata', data);
  // }, [data]);

  useEffect(() => {
    if (videos) {
      videos.map((video) => {
        uploadtoDrive(video);
      });
    }
    console.log(videos, 'videos');
  }, [videos]);
  return (
    <div className=" relative">
      {show && (
        <div className={`upload-toast fixed flex aic sho`}>
          <div className="lef-blk flex flex-col aic">
            <div className="no font s26 b6 cfff">
              {/* {getVideosToUploadCount()} */}
            </div>
            <span className="lbl font s13 cfff">videos to upload</span>
          </div>
        </div>
      )}
      {show && (
        <div className="upload-toast fixed flex aic">
          <div className="lef-blk flex flex-col aic">
            <div className="no font s26 b6 cfff">{videos?.length}</div>
            <p className=" text-base text-white font-medium">Videos</p>
          </div>
          <div className="rit-blk flex flex-col">
            <div className="meta flex aic flex-col w-full">
              <div className="nam font s14 b6 color">
                {videos.map((item) => (
                  <span>{item?.name},</span>
                ))}
              </div>
            </div>
            <div className="prograss-bar rel">
              <div
                className="prograss abs anim"
                style={{
                  width: `${percent}%`,
                }}
              />
            </div>
            <div className="size font s14 b6 mt-4 text-gray-400 flex items-center gap-1">
              Uploading from google Drive{' '}
              <img
                className="img w-4 h-4"
                src={require('../../assets/google-drive.png').default}
              />
            </div>
          </div>
        </div>
      )}
      <button
        className="cleanbtn google-sheet-btn flex aic"
        onClick={() => handleOpenPicker()}
      >
        <img
          className="img"
          src={require('../../assets/google-drive.png').default}
        />
        <div className="font s14 b5 c333">Upload From Google Drive</div>
      </button>
    </div>
  );
}
