import React, { useEffect } from 'react';
import { RESIDENT_HOST, useRequest } from '../../../utils/request.js';
import './question.css';

export default function Exit({
  getSlug,
  who,
  apt_template,
  question_template,
  rating,
  feedback,
  selectedStaffBadges,
  selectedAptBadges,
}) {
  // After all data is collected, send data to backend via json
  const [loading, error, data, makeRequest] = useRequest({ loading: false });

  useEffect(() => {
    // TODO: Handle badges...

    // const data = {
    //   apartment_id: apt_template.id,
    //   apartment_name: apt_template.aptName,
    //   interacted_with: who,
    //   rating: parseInt(rating),
    //   optional_feedback: feedback,
    //   staff_badges: selectedStaffBadges,
    //   apt_badges: selectedAptBadges
    // };
    // IDEA: manually put default/not sure/other as user_id = 0, so gen apt reviews go here
    const user_id = who ? who.user_id : 0;

    const data = {
      apartment_id: apt_template.id,
      user_id: parseInt(user_id),
      rating: parseInt(rating),
      review: who && feedback.length <= 41 + who.name.length ? '' : feedback,
      badges: {
        apt: selectedAptBadges,
        staff: selectedStaffBadges,
      },
    };
    // POST review to backend
    makeRequest(`/reviews/${getSlug()}/${user_id}`, 'POST', data, {
      auth: false,
      host: RESIDENT_HOST,
    })
      .then((response) => {
        return response;
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <div>
      <h1 className="exit-text">
        Thank you for your response. <br /> We look forward to making{' '}
        {apt_template.aptName} a better place!
      </h1>
    </div>
  );
}
