import React from 'react';
import { Link } from 'react-router-dom';
import newlogo from '../../assets/newlogo.png';
const NewHeader = () => {
  return (
    <div className=" px-10 flex items-center justify-between w-full py-3">
      <img src={newlogo} alt="" />
      <div className=" flex items-center gap-5">
        <Link to="/solutions">Solutions</Link>
        <Link to="/pricing">Pricing</Link>
        <Link to="/video-gallery">Video Gallery</Link>
        <button className=" px-4 hover:bg-nhvr bg-nvr py-2 rounded-md text-white">
          Schedule A Call
        </button>
      </div>
    </div>
  );
};

export default NewHeader;
