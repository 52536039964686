const parse = (data) => {
  return JSON.parse(data);
};

const keyCodes = {
  ENTER: 13,
};

const keyupListener = (e, keyCode, callback) => {
  var key = e.which || e.keyCode;
  key === keyCode && callback();
};

const isValidEmail = (e) => {
  let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return e.match(reg);
};

function isValidURL(string) {
  var regexp =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return regexp.test(string);
}

const focus = (id) => {
  try {
    document.querySelector(id).focus();
  } catch (e) {}
};

const generateID = (len, k) => {
  function s(k) {
    var text = '';
    var possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < k; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
  var id = s(k);
  if (len > 1) {
    for (var n = 1; n < len; n++) {
      id += '-' + s(k);
    }
  }
  return id;
};

const currentDateTime = () => {
  var currentdate = new Date();
  var datetime =
    currentdate.getDate() +
    '/' +
    (currentdate.getMonth() + 1) +
    '/' +
    currentdate.getFullYear() +
    ' , ' +
    currentdate.getHours() +
    ':' +
    currentdate.getMinutes() +
    ':' +
    currentdate.getSeconds();

  return datetime;
};

function parseJsonHorizontally(dataArr = null) {
  let tempArr = [];
  !dataArr === null &&
    dataArr.forEach((data) => {
      let tempObj = {};
      Object.keys(data).map((item, idx) => {
        try {
          let t1 = JSON.parse(data[item]);
          tempObj[item] = t1;
          return t1;
        } catch (e) {
          console.log(e);
        }
        tempObj[item] = data[item];
        return data[item];
      });
      tempArr.push(tempObj);
    });

  return tempArr;
}

export {
  parse,
  keyCodes,
  keyupListener,
  isValidEmail,
  focus,
  generateID,
  currentDateTime,
  parseJsonHorizontally,
  isValidURL,
};
