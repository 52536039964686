import React, { useRef, useState } from 'react';
import './animations.css';
import './common.css';
import { LinkIcon, PlayIcon2 } from './Icons';
import './VideoMagnet.css';

const ScrollableContainer = ({
  extraPadding,
  bottomLinks = [],
  onClickLink,
  fadeTransition,
}) => {
  const scollContainerRef = useRef();
  // const [isDown, setIsDown] = useState(false)
  const scroll = (scrollOffset) => {
    scollContainerRef.current.scrollLeft += scrollOffset;
  };

  //   useEffect(() => {
  //     function updateScrollPosition() {
  //         // update the scroll position
  //     }

  //     if (scollContainerRef && scollContainerRef.current) {

  //     let isDown = false;
  //     let startX;
  //     let scrollLeft;

  //     scollContainerRef.current.addEventListener('mousedown', (e) => {
  //       isDown = true;
  //       scollContainerRef.current.classList.add('active');
  //       startX = e.pageX - scollContainerRef.current.offsetLeft;
  //       scrollLeft = scollContainerRef.current.scrollLeft;
  //     });
  //     scollContainerRef.current.addEventListener('mouseleave', () => {
  //       isDown = false;
  //       scollContainerRef.current.classList.remove('active');
  //     });
  //     scollContainerRef.current.addEventListener('mouseup', () => {
  //       isDown = false;
  //       scollContainerRef.current.classList.remove('active');
  //     });
  //     scollContainerRef.current.addEventListener('mousemove', (e) => {
  //       if(!isDown) return;
  //       e.preventDefault();
  //       const x = e.pageX - scollContainerRef.current.offsetLeft;
  //       const walk = (x - startX) * 3; //scroll-fast
  //       scollContainerRef.current.scrollLeft = scrollLeft - walk;
  //       console.log(walk);
  //     });

  //       scollContainerRef.current.addEventListener("scroll", updateScrollPosition, false);
  //         return function cleanup() {
  //           scollContainerRef.current.removeEventListener("scroll", updateScrollPosition, false);
  //         };
  //     }
  // }, []);

  function CardBtn({ keys, name, img, link }) {
    const [isHover, setIsHover] = useState(false);

    return (
      <div
        key={keys}
        className="card"
        style={{ backgroundImage: `url(${img})` }}
        onClick={(e) => {
          e.stopPropagation();
          onClickLink(link);
        }}
      >
        <button className="play-btn">
          {link?.href ? <LinkIcon /> : <PlayIcon2 />}
        </button>
        <div className="card-content" />
        <div className="card-ftr">
          <div className="name">{name}</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        style={extraPadding ? { paddingTop: 10 } : undefined}
        className={`container-xscroll ${fadeTransition}`}
      >
        <div className="scroll-content" ref={scollContainerRef}>
          {bottomLinks?.links?.map((link, idx) => (
            <CardBtn
              key={idx}
              keys={idx}
              name={link?.name}
              img={link?.img}
              link={link}
            />
          ))}
        </div>
        {bottomLinks?.links && bottomLinks?.links?.length !== 0 && (
          <>
            {' '}
            <button
              className="scroll-btn prev-btn"
              onClick={(e) => {
                scroll(-300);
                e.stopPropagation();
              }}
            >
              &#8592;
            </button>
            <button
              className="scroll-btn next-btn"
              onClick={(e) => {
                scroll(300);
                e.stopPropagation();
              }}
            >
              {' '}
              &#8594;{' '}
            </button>{' '}
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(ScrollableContainer);
