import React, { useEffect, useState } from 'react';
import { startTour, startVisit } from '../../utils/api';
import { get } from '../../utils/request';
import Banner from './Banner';
// import Headline from './Headline';
import Navbar from './Navbar';
import './share-video.css';
import VideosBanner from './VideosBanner';

export default function CustomTour({ customHomepage = '/tour' }) {
  // Get query parameters
  const query = new URLSearchParams(window.location.search);
  // Get name query
  const token = query.get('name');
  // Magnet id
  const magnet_id = query.get('magnet_uuid');
  const intro_video = query.get('intro_video');

  /*
      QUERY FORMAT:
      /tour?magnet_uuid=9b9d1e9e-b3ad-4815-bef3-726cb2f8a945&share=intro.main,floor_plans.main&name=Amulya
      magnet uuid -> share -> name
    */

  // Apartment info
  const [apt, setApt] = useState();

  // Get all of the apartment's info for navbar
  function getCommunityDetails() {
    get(`/magnets/template?magnet_uuid=${magnet_id}`, { auth: false })
      .then((data) => {
        setApt(data.magnet.magnet_details.template.magnetSettings);
        startVisit(data.magnet.magnet_details.template);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  console.log('apt', apt);

  useEffect(() => {
    document.title = 'Tour built just for you | Tour.video';
    getCommunityDetails();
    startTour(token);
  }, []);

  return (
    <div>
      {' '}
      {/*(apt) &&
                    <Headline
                      site={apt.url}
                      phone={"(248) 123-4567"}
                      headline={"LEASING NOW!"}
                    />
                  */}{' '}
      <div className="tour-container">
        {' '}
        {apt && (
          <>
            <Navbar
              query={query}
              name={apt.communityName}
              location={apt.location}
              site={apt.website}
              phone={apt.phone}
              email={apt.email}
              scheduleLink={apt.scheduleLink}
              customHomepage={customHomepage}
            />{' '}
            <Banner
              intro_video={intro_video}
              name={token}
              fullTour={'https://video.tourmagnets.com/f31n1gcg7'}
              phone={apt.phone}
              email={apt.email}
              backgroundImg={apt.backgroundImg}
            />{' '}
          </>
        )}{' '}
        <VideosBanner query={query} currRoute={'CustomTour.Home'} />{' '}
      </div>{' '}
    </div>
  );
}
