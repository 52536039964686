const BillingAmountSummaryBox = ({ communitiesDetailsList }) => {
  // TODO use useCallback or useMemo to avoid unnecessary re-renders
  const calculateTotalAmountRemainingForOne = (thisCommunityDetails) => {
    let amount_remaining = 0;
    thisCommunityDetails?.unpaid_invoices?.forEach((thisInvoice, idx) => {
      amount_remaining += thisInvoice.amount_remaining / 100;
    });
    return amount_remaining;
  };

  // TODO use useCallback or useMemo to avoid unnecessary re-renders
  const calculateTotalAmountRemainingForAll = (thisCommunitiesDetails) => {
    let amount_remaining = 0;
    thisCommunitiesDetails?.forEach((thisCommunityDetail, idx) => {
      amount_remaining +=
        calculateTotalAmountRemainingForOne(thisCommunityDetail);
    });
    return amount_remaining;
  };

  return (
    <div className="shadow-md bg-white border">
      <div className="p-4">
        <h3 className="pb-3 border-b font-medium text-black">
          Total upcoming Billing Amount{' '}
        </h3>
        {communitiesDetailsList?.map((thisCommunityDetails, index) => {
          if (!thisCommunityDetails) return null;
          const thisAmountRemaining =
            calculateTotalAmountRemainingForOne(thisCommunityDetails);
          return (
            <BillEntry
              key={thisCommunityDetails?.community_id + '_' + index}
              id={thisCommunityDetails?.community_id}
              name={thisCommunityDetails?.name}
              amount={thisAmountRemaining}
            />
          );
        })}
        <div className=" mt-3 w-full flex items-center justify-between text-xl pb-2">
          <p className=" text-gray-500 font-light text-lg">Total</p>
          <p className=" font-medium text-black text-3xl">
            ${calculateTotalAmountRemainingForAll(communitiesDetailsList)}
          </p>
        </div>
      </div>
      <div className=" w-full bg-pr text-white flex items-center text-xl cursor-pointer hover:bg-blue-500 justify-center py-3">
        Pay now
      </div>
    </div>
  );
};

const BillEntry = ({ id, name, amount }) => {
  return (
    <div className=" mt-3 w-full flex items-center justify-between text-xl pb-3 border-b">
      <a
        href={`#community_${id}`}
        className=" text-gray-500 hover:text-gray-700 active:text-gray-800 cursor-pointer font-light text-lg mr-3"
      >
        {name}
      </a>
      <p className=" font-medium text-black">${amount ? amount : 0}</p>
    </div>
  );
};

export default BillingAmountSummaryBox;
