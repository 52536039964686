import { CardElement } from '@stripe/react-stripe-js';
import React, { useMemo } from 'react';
import './Stripe.css';

//STRIPE CREDIT CARD HANDLER

const useOptions = () => {
  const fontSize = '16px';
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: "'PT Sans', Arial, sans-serif",
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const CardForm = ({ processpayment, handleSubmit }) => {
  const options = useOptions();
  return (
    <form onSubmit={handleSubmit}>
      <div style={{ width: 'calc(100%)' }} className="label-text font s15 c000">
        Enter your card information
        <CardElement
          options={options}
          onReady={() => {
            console.log('CardElement [ready]');
          }}
          onChange={(event) => {
            console.log('CardElement [change]', event);
          }}
          onBlur={() => {
            console.log('CardElement [blur]');
          }}
          onFocus={() => {
            console.log('CardElement [focus]');
          }}
        />
      </div>
      {/*<button type="submit" disabled={!stripe} className="stripe-button">
        Pay
      </button>*/}
    </form>
  );
};

export default CardForm;
