// Material UI imports
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EditUser from '../../../components/Reusable/EditUser';
import notification from '../../../components/Reusable/Notification';
import { generateID } from '../../../core';
import {
  createRequestHistory,
  getCommunityUserByID,
  getCommunityUserReviews,
  getPendingReviewRequests,
  getReviewCommunity,
} from '../../../store/actions/reviewsActions';
import {
  RESIDENT_DASHBOARD,
  RESIDENT_HOST,
  useRequest,
} from '../../../utils/request.js';
import { slugToString } from '../../../utils/slug';
import Badge from './Badge';

export default function Employee({ setCustomHomepage, name, url }) {
  const { community_id, staffName } = useParams();
  const _dispatch = useDispatch();
  const staffId = staffName;

  const {
    reviewscommunityLoading,
    reviewscommunityData,
    reviewscommunityError,
  } = useSelector((state) => state.getReviewCommunityReducer);

  const {
    communityUserReviews,
    communityUserReviewsLoading,
    communityUserReviewsError,
  } = useSelector((state) => state.communityUserReviewReducer);

  const { communityUser, communityUserLoading, communityUserError } =
    useSelector((state) => state.communityUserByIDReducer);

  const { pendingReviews, pendingReviewsLoading, pendingReviewsError } =
    useSelector((state) => state.getPendingReviewReducer);

  let requestID = generateID(3, 4);

  const [user, setUser] = useState();
  // Number of reviews & rating
  const [amount, setAmount] = useState(0);
  const [rating, setRating] = useState(0);
  const [achieveRating, setAchieveRating] = useState(0);
  // Reviews state
  const [reviews, setReviews] = useState();
  // Which page of reviews we are showing
  const [page, setPage] = useState(1);
  const [loading, error, data, makeRequest] = useRequest({ loading: false });
  setCustomHomepage(`/portal/${getAptSlug()}`);
  // Get apt name slug

  const [msg, setmsg] = useState(
    'I was hoping you could do me a favor and leave my team and me a review? Thank you in advance.'
  );
  const messagelist = [
    {
      msg: 'I was hoping you could do me a favor and leave my team and me a review? Thank you in advance.',
    },
    {
      msg: 'I wanted to just say sincerely thank you for leaving us a review. It really meant alot to us.',
    },
    {
      msg: 'I wanted to just say sincerely thank you for leaving us a review. Just wanted to check in to see if you were ever were able to leave us us a Google Review?\n It really means alot to us.',
    },
    {
      msg: 'Just wanted to share a special token as a thank you for kind words in the review and being an awesome resident here. \n It really means alot to us.',
    },
  ];

  useEffect(() => {
    _dispatch(getReviewCommunity(community_id));
    _dispatch(getCommunityUserReviews(community_id, staffId));
    _dispatch(getPendingReviewRequests(staffId, community_id));
  }, [community_id]);

  useEffect(() => {
    _dispatch(getCommunityUserByID(staffId));
  }, [staffId]);

  function getAptSlug() {
    // Split URL by '/'
    const url = window.location.href.split('/');
    return url[url.length - 2];
  }

  // Get user
  function getUser() {
    // fetch(`${RESIDENT_HOST}/users/${getSlug()}`)
    makeRequest(`/users/3`, 'GET', null, {
      auth: false,
      host: RESIDENT_HOST,
    })
      .then((response) => {
        // console.log('users res...', response);
        return response;
      })
      .then((data) => {
        setUser(data.user.name);
      })
      .catch((error) => {
        console.log('get user error:', error.message);
      });
  }

  // Get reviews for user
  function getReviews() {
    // fetch(`${RESIDENT_HOST}/reviews/${getAptSlug()}/${getSlug()}`)
    makeRequest(`/reviews/2`, 'GET', null, {
      auth: false,
      host: RESIDENT_HOST,
    })
      .then((response) => {
        return response;
      })
      .then((data) => {
        setReviews(data.reviews);
      })
      .catch((error) => {
        console.log('get reviews error...', error.message);
      });
  }

  useEffect(() => {
    if (!user) getUser();
    if (!reviews) getReviews();
  }, []);

  useEffect(() => {
    getNumReviews();
  }, [communityUserReviewsLoading]);

  let total = 0.0;
  // Get amount of reviews
  const getNumReviews = () => {
    setAmount(communityUserReviews?.payload?.length);

    // // Get average rating
    communityUserReviews?.payload?.length &&
      communityUserReviews?.payload?.map((item) => {
        if (item?.rating) {
          total += parseInt(item.rating);
        }
      });

    if (communityUserReviews.payload?.length) {
      let _rating = parseFloat(
        (total / communityUserReviews?.payload?.length).toFixed(1)
      );
      setRating(_rating);
    }
  };

  const [openEditUser, setOpenEditUser] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);

  // topbar

  const { screenToEdit } = useSelector((state) => state.magnetStates);
  const _magnetData = useSelector((state) => state.getMagnet);
  const { template } = _magnetData;

  const [category, screen] = useMemo(
    () => screenToEdit.split('.'),
    [screenToEdit]
  );
  var categoryTitle = useMemo(
    () => (category ? template?.categories[category]?.title : ''),
    [category, template]
  );

  return (
    <>
      <div className="review-dashboard-employee-p relative w-full">
        <Header>
          <div className="dashboard-hdr flex aic w-full">
            <div className="left flex aic">
              <a href={url} target="_blank">
                <OpenInNewIcon />
              </a>
              &nbsp;&nbsp;
              <Link to="/" className="item flex aic">
                <div className="txt font s14 b3 anim">{name}</div>
                {name ? <span className="slash">&nbsp;/&nbsp;</span> : null}
              </Link>
              <div className="item flex aic">
                <div
                  onClick={() => {
                    _dispatch({
                      type: 'SCREEN_TO_EDIT',
                      payload: '',
                    });
                    window.history.pushState({}, `View ${community_id}`, `#`);
                  }}
                  className="txt font s14 b5 black"
                >
                  Referral
                </div>
                {categoryTitle && <span className="slash">&nbsp;/&nbsp;</span>}
              </div>
              {categoryTitle && (
                <div className="item flex aic">
                  <div className="txt font s14 b5 black">{categoryTitle}</div>
                </div>
              )}
            </div>
            <div className="right flex aic">
              <Link
                to={`./reviews/settings`}
                className="create-btn cleanbtn font anim s15"
              >
                <button className="cleanbtn add-category font s14 b5 cfff flex aic">
                  <span className="ico icon-plus s16 cfff" />
                  Survey Settings
                </button>
              </Link>
            </div>
          </div>
        </Header>
        {reviewscommunityLoading ||
        communityUserReviewsLoading ||
        communityUserLoading ? (
          <div className="review-page-loading flex aic">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="employee-hdr flex aic">
              <Link to={`/dashboard/${community_id}/reviews`}>
                <button className="back-btn cleanbtn font s15 c222 flex aic">
                  <div className="ico icon-arrow-left s16" />
                  GO Back
                </button>
              </Link>
            </div>
            <div className="employe-nam flex aic">
              <div className="title font b6 s22 black">
                {`${
                  communityUser?.payload?.length &&
                  communityUser?.payload[0]?.name
                }'s Review Portal  | ${
                  reviewscommunityData?.payload?.length &&
                  reviewscommunityData?.payload[0]?.name
                }`}
              </div>
              <div className="star-blk flex flex-col aic jtc">
                <div className="flex aic">
                  <div className="icons flex aic">
                    <Rating
                      name="rating-component"
                      value={rating ? rating : 0}
                      precision={0.5}
                      size="large"
                      onChange={(event) => {
                        setAchieveRating(event.target.value);
                      }}
                    />
                  </div>
                  <div className="num font s13 c222">{`${rating} out of 5`}</div>
                </div>
                <div className="total-generated font s13 c222">{`${communityUserReviews?.payload?.length} Reviews generated`}</div>
              </div>
            </div>

            {communityUser?.payload ? (
              <>
                <SMS
                  makeRequest={makeRequest}
                  apt_slug={getAptSlug()}
                  apt={slugToString(getAptSlug())}
                  community_id={community_id}
                  user={communityUser?.payload[0]?.name}
                  requestID={requestID}
                  setmsg={setmsg}
                  msg={msg}
                />
              </>
            ) : null}

            {communityUserReviews?.payload && (
              <div class="feedback-table">
                <div class="title font s18 b6 black">
                  Feedback for {communityUser?.payload[0]?.name}
                </div>
                <CompletedFeedbackTable
                  setSelectedReview={setSelectedReview}
                  setOpenEditUser={setOpenEditUser}
                  reviews={communityUserReviews?.payload}
                  page={page}
                  setmsg={setmsg}
                  messagelist={messagelist}
                />

                <Navigation
                  page={page}
                  setPage={setPage}
                  pages={
                    Math.floor(communityUserReviews?.payload?.length / 10) + 1
                  }
                />
              </div>
            )}

            {pendingReviews && communityUser?.payload && (
              <div class="feedback-table pending-table">
                <div class="title font s18 b6 black">
                  Pending Review Requests Sent by{' '}
                  {communityUser?.payload[0]?.name}
                </div>
                <PendingFeedbackTable reviews={pendingReviews} page={page} />

                <Navigation
                  page={page}
                  setPage={setPage}
                  pages={Math.floor(pendingReviews.length / 10) + 1}
                />
              </div>
            )}
          </>
        )}
      </div>
      <EditUser
        community_id={community_id}
        selectedReview={selectedReview}
        openEditUser={openEditUser}
        setOpenEditUser={setOpenEditUser}
      />
    </>
  );
}

function SMS({
  makeRequest,
  community_id,
  apt_slug,
  apt,
  user,
  requestID,
  msg,
  setmsg,
}) {
  const _dispatch = useDispatch();
  const { staffName } = useParams();
  const staffId = staffName;
  // Determine SMS or email

  const [errors, setErrors] = useState('');
  const [type, setType] = useState('SMS');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [requestSending, setRequestSending] = useState(false);

  const msgRef = useRef();
  const formRef = useRef();
  const [NotificationBar, setNotification] = notification();
  const { communityUser, communityUserLoading, communityUserError } =
    useSelector((state) => state.communityUserByIDReducer);

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + match[2] + match[3];
    }
    return false;
  }

  console.log('phoneNumber', phone, formatPhoneNumber(phone));

  // Handle send message
  function handleSubmit(e) {
    setRequestSending(true);
    e.preventDefault();
    alert(`${type} sent to ${name}`);
    // Create data object
    let data = {};
    if (type === 'SMS') {
      const phoneNumber = formatPhoneNumber(phone);
      // if phone number is not valid do not continue
      if (phoneNumber === false) {
        setErrors('Phone number is invalid');
        setRequestSending(false);
        return null;
      }

      data = {
        to: phoneNumber,
        message: msgRef.current.innerText,
      };
    } else {
      // type === email
      data = {
        to: email,
        aptName: apt,
        leadName: name,
        reviewLink: `${RESIDENT_DASHBOARD}/survey/${apt_slug}?request=${requestID}`,
        message: msgRef.current.innerText,
      };
    }
    const route = type === 'SMS' ? '/send_sms' : '/send_email';

    let requestHistory = {
      id: requestID,
      type: 'review_request',
      src_community_id: community_id,
      src_user_id: communityUser?.payload[0]?.id,
      reviewId: '',
      data: {
        requesterName: communityUser?.payload[0]?.name,
        requesterEmail: communityUser?.payload[0]?.email,
        requesterPhone: communityUser?.payload[0]?.phone,
        requesterId: communityUser?.payload[0]?.id,
        leadName: name, // requster name
        leadPhone: phone, // requster phone
        leadEmail: email, // requster email
        leadUserId: generateID(3, 4), // requster user
        defaultReviewStars: 4,
        contextId: '',
        completedBonusReview: false,
        specialOffer: '',
        dateCreated: moment().format('MMMM Do YYYY, h:mm:ss a'),
        message: msgRef.current.innerText,
      },
    };

    // Send SMS to phone number
    makeRequest(route, 'POST', data, { auth: false, host: RESIDENT_HOST })
      .then((data) => {
        // Notify user message was successful
        console.log(data);
        if (name) {
          toast(`Message sent to ${name}`, {
            position: 'top-center',
            type: 'success',
            autoClose: 5000,
          });
        } else {
          toast(`Message sent`, {
            position: 'top-center',
            type: 'success',
            autoClose: 5000,
          });
        }
        // Clear inputs
        formRef.current.reset();
        setPhone('');
        setEmail('');
        setErrors('');
        setName('');
        setRequestSending(false);
      })
      .catch((error) => {
        console.log(error.message);
        setRequestSending(false);
      });

    _dispatch(createRequestHistory(requestHistory));
    setTimeout(() => {
      _dispatch(getPendingReviewRequests(staffId, community_id));
    }, 2000);
  }

  const handleTypeSwitcher = (type) => {
    if (type === 'SMS') {
      setType('SMS');
      setEmail('');
    } else {
      setType('email');
      setPhone('');
    }
  };

  return (
    <>
      {requestSending && (
        <div className="loading-overlay flex aic">
          <CircularProgress />
        </div>
      )}
      <div className="sms-container flex aic">
        <div className="left flex flex-col">
          <div className="label font s16 b6 black">Request resident review</div>
          <div
            className={`font s15 msg ${type === 'SMS' ? 'sms' : 'email'}`}
            ref={msgRef}
            contentEditable
          >
            Hi{name ? ` ${name}` : ''}, it's {user} from {apt}! {msg}
            <br />
            <br />
            {`${RESIDENT_DASHBOARD}/survey/${community_id}?request=${requestID}`}
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              setmsg(
                'I was hoping you could do me a favor and leave my team and me a review? Thank you in advance.'
              )
            }
            style={{
              marginTop: '10px',
              width: '40px',
            }}
          >
            Reset
          </Button>
        </div>
        <div className="right flex flex-col">
          <form
            onSubmit={handleSubmit}
            ref={formRef}
            className="form flex flex-col"
          >
            <div className="field flex aic">
              <div
                className="item cleanbtn flex aic"
                onClick={() => handleTypeSwitcher('SMS')}
              >
                <div
                  className={`ico ${type === 'SMS' ? 'on icon-check1' : ''}`}
                />
                <div className="lbl font">Send SMS</div>
              </div>
              <button
                className="item cleanbtn flex aic"
                onClick={() => handleTypeSwitcher('email')}
              >
                <div
                  className={`ico ${type === 'email' ? 'on icon-check1' : ''}`}
                />
                <div className="lbl font">Send Email</div>
              </button>
            </div>
            <input
              type="text"
              className="font iput"
              placeholder="Name (optional)"
              onChange={(e) => setName(e.target.value)}
            />
            {type === 'SMS' && (
              <div>
                <p style={{ color: 'red' }}>{errors}</p>
                <input
                  type="tel"
                  className="font iput"
                  placeholder="Phone number"
                  required
                  style={{ width: '100%' }}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            )}
            {type === 'email' && (
              <input
                type="email"
                className="font iput"
                placeholder="Email address"
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                onChange={(e) => setEmail(e.target.value)}
              />
            )}
            <div className="actions flex aic">
              <input
                type="submit"
                value="Request review"
                className="request-btn font"
              />
            </div>
          </form>
          <NotificationBar />
        </div>
      </div>
    </>
  );
}

// CompletedFeedbackTable Table
function CompletedFeedbackTable({
  reviews,
  page,
  setmsg,
  messagelist,
  setSelectedReview,
  setOpenEditUser,
}) {
  // TODO: sort by date, rating

  // Only display the current page of reviews (10 per page)
  function displayTen() {
    const ten = reviews.filter(
      (review, idx) => idx >= 10 * (page - 1) && idx < 10 * page
    );
    return ten;
  }

  return (
    <div className="dashboard-employee-table">
      <div className="table">
        <div className="table-hdr flex aic">
          <div className="column">
            <div className="label font">Date</div>
          </div>
          <div className="column">
            <div className="label font">Name</div>
          </div>
          <div className="column">
            <div className="label font">Rating</div>
          </div>
          <div className="column">
            <div className="label font">Comments</div>
          </div>
        </div>
        {displayTen().map((review, idx) => {
          let _badges = review.badges ? JSON.parse(review.badges) : [];
          return (
            <CompletedRow
              key={idx}
              idx={idx}
              // date={review.created_date.substring(
              //   0,
              //   review.created_date.indexOf(':') - 2
              // )}
              setSelectedReview={setSelectedReview}
              setOpenEditUser={setOpenEditUser}
              review={review}
              date={review.date_created}
              fromUserName={review.from_user_name}
              rating={review.rating}
              comments={review.message}
              badges={_badges}
              setmsg={setmsg}
              messagelist={messagelist}
            />
          );
        })}
        {reviews.length === 0 && <NoRows />}
      </div>
    </div>
  );
}

// Row for each table entry
function CompletedRow({
  idx,
  date,
  fromUserName,
  review,
  rating,
  comments,
  badges,
  setmsg,
  messagelist,
  setSelectedReview,
  setOpenEditUser,
}) {
  // Expand or not
  const [expand, setExpand] = useState(false);

  // Constrain comments to 200 characters
  const openUserModal = () => {
    setOpenEditUser(true);
    setSelectedReview(review);
  };
  function constrainComments() {
    if (comments) {
      if (comments.length < 90) return comments;
      else return comments.slice(0, 90) + ' ... ';
    }
    return 'N/A';
  }

  const emojis = [
    { label: 'Well', icon: '🙌' },
    { label: 'Noticed', icon: '🔔' },
    { label: 'Heart', icon: '❤️' },
    { label: 'Gift', icon: '🎁' },
  ];

  return (
    <>
      <div style={{ display: 'flex' }} className="table-row aic">
        <div className="column">
          <div className="txt font">{date}</div>
        </div>
        <div className="column user-name">
          <div className="name" onClick={openUserModal}>
            {fromUserName ? fromUserName : '🧍'}
          </div>
          <div className="reactions">
            {emojis.map((item, index) => (
              <div
                key={index}
                onClick={() => setmsg(messagelist[0].msg)}
                className="sms-reaction-icon"
              >
                {item.icon}
                <div className="copied">{item.label}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="column">
          <div style={{ display: 'inline-block', fontSize: '34px' }}>
            <Rating
              name="rating-component"
              defaultValue={rating}
              precision={0.5}
              readOnly
              size="medium"
            />{' '}
            {JSON.parse(review?.metadata)?.bonusReviewCompleted ? (
              <span style={{ color: '#ffb400' }}>☑</span>
            ) : (
              <span style={{ color: 'lightgrey' }}>☑</span>
            )}
          </div>
        </div>
        <div className="comments column">
          <div className="item flex aic">
            <div className="meta flex flex-col">
              <div className="badges flex aic">
                {Object.keys(badges).map((item) => {
                  return badges[item].map((badge) => {
                    return <Badge number={''} text={badge.title} />;
                  });
                })}
              </div>
              <div className="cmt font s15 black wordwrap">{comments}</div>
            </div>
            {comments?.length > 0 && (
              <button
                className="cleanbtn drop-btn font"
                onClick={() => setExpand(!expand)}
              >
                {!expand ? '▼' : '▲'}
              </button>
            )}
          </div>
        </div>
      </div>
      {expand && (
        <>
          <ExpandedComments idx={idx} comments={comments} />
        </>
      )}
    </>
  );
}

// CompletedFeedbackTable Table
function PendingFeedbackTable({ reviews, page }) {
  // TODO: sort by date, rating

  // Only display the current page of reviews (10 per page)
  function displayTen() {
    const ten = reviews.filter(
      (review, idx) => idx >= 10 * (page - 1) && idx < 10 * page
    );
    return ten;
  }

  return (
    <div className="dashboard-employee-table">
      <div className="table">
        <div className="table-hdr flex aic">
          <div className="column">
            <div className="label font">Date</div>
          </div>
          <div className="column">
            <div className="label font">Name</div>
          </div>
          <div className="column">
            <div className="label font">Message</div>
          </div>
        </div>
        {displayTen().map((review, idx) => {
          let _badges = review.badges ? JSON.parse(review.badges) : [];
          const revDataJson = JSON.parse(review?.data);
          return (
            <PendingRow
              key={idx}
              idx={idx}
              // date={review.created_date.substring(
              //   0,
              //   review.created_date.indexOf(':') - 2
              // )}
              review={review}
              date={revDataJson?.dateCreated}
              requestId={revDataJson?.id}
              leadName={revDataJson?.leadName}
              leadEmail={revDataJson?.leadEmail}
              leadPhone={revDataJson?.leadPhone}
              message={revDataJson?.message}
            />
          );
        })}
        {reviews.length === 0 && <NoRows />}
      </div>
    </div>
  );
}

// Row for each table entry
function PendingRow({
  idx,
  review,
  date,
  leadName,
  leadEmail,
  leadPhone,
  message,
}) {
  return (
    <>
      <div style={{ display: 'flex' }} className="table-row  aic">
        <div className="column">
          <div className="txt font">
            <a
              href={`${RESIDENT_DASHBOARD}/survey/${review?.src_community_id}?request=${review?.id}`}
              target="_blank"
            >
              {date}
            </a>{' '}
          </div>
        </div>
        <div className="column">
          {leadName}
          <br />
          <div className="badges dark flex aic">
            {leadPhone && <Badge color={'#D3D3D3'} text={leadPhone} />}
            {leadEmail && <Badge color={'#D3D3D3'} text={leadEmail} />}
          </div>
        </div>
        <div className="column">
          {message && message.length > 30
            ? message.substring(0, 30) + '...'
            : message}
        </div>
      </div>
    </>
  );
}

// Expanded comments section
function ExpandedComments({ idx, comments }) {
  return (
    <div className="expanded-blk flex aic">
      <div className="txt font">{comments}</div>
    </div>
  );
}

// Navigation bar for table
// page = current page
// setPage = set current page
// pages = total number of pages
function Navigation({ page, setPage, pages }) {
  // Handle clicks
  function handleFirst() {
    setPage(1);
  }

  function handlePrev() {
    if (page !== 1) setPage(page - 1);
  }

  function handleNext() {
    if (page !== pages) setPage(page + 1);
  }

  function handleLast() {
    setPage(pages);
  }

  return (
    <div className="table-navigation flex aic">
      <div className="left flex aic">
        <div className="prev-btn font s16 b5 black" onClick={handleFirst}>
          {'<< First'}
        </div>
        <div className="prev-btn font s16 b5 black" onClick={handlePrev}>
          {' < Prev '}
        </div>
      </div>
      <div className="center">
        <div className="font s18 b6">
          {page} / {pages}
        </div>
      </div>
      <div className="left flex aic">
        <div className="next-btn font s16 b5 black" onClick={handleNext}>
          {' Next > '}
        </div>
        <div className="prev-btn font s16 b5 black" onClick={handleLast}>
          {' Last >>'}
        </div>
      </div>
    </div>
  );
}

function NoRows() {
  return <div className="no-employees flex aic">No reviews found.</div>;
}
