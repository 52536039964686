import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from 'react-google-autocomplete';
import IconButton from '@material-ui/core/IconButton';
import QRCode from 'react-qr-code';
import { createScreenKey } from '../../manage-magnets/CreateKeys';
import TextField from '@material-ui/core/TextField';
import LaunchIcon from '@material-ui/icons/Launch';
import InputAdornment from '@material-ui/core/InputAdornment';
import { slugToString } from '../../../utils/slug';
import { Autocomplete as MaterialAutocomplete } from '@material-ui/lab';
import EditReview from '../../../components/createReview/EditReviewSettings';
import { IoQrCodeSharp } from 'react-icons/io5';
import { FaWpforms } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import axios from 'axios';
import { API_HOST } from '../../../utils/request';
import { RESIDENT_DASHBOARD } from '../../../utils/request';
import { generateID, isValidEmail } from '../../../core';

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + match[2] + match[3];
  }
  return false;
}

// this function is not guaranteed to work - i separated this from `Review.jsx` on 2023-07-12
// and most of the logic is untouched and just patched up by moving imports
export default function CollectReviews({
  url,
  name,
  toastNotification,
  reviewsMetadata,
  community_id,
}) {
  const msgRef = useRef();
  const [teamMemberName, setTeamMemberName] = useState('');
  const [currentPage, setCurrentPage] = useState('qrcode');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [leadName, setLeadName] = useState('');
  const [requestSending, setRequestSending] = useState(false);
  const [type, setType] = useState('SMS');
  const [
    selectedTeamMemberReviewTrackingId,
    setSelectedTeamMemberReviewTrackingId,
  ] = useState('');
  let requestID = generateID(3, 4);

  const handleTypeSwitcher = (type) => {
    if (type === 'SMS') {
      setType('SMS');
      setEmail('');
    } else {
      setType('email');
      setPhone('');
    }
  };
  function getAptSlug() {
    // Split URL by '/'
    const url = window.location.href.split('/');
    return url[url.length - 2];
  }
  function handleSubmit(e) {
    setRequestSending(true);
    e.preventDefault();
    // Create data object

    let data = {};

    if (type === 'SMS') {
      const phoneNumber = formatPhoneNumber(phone);
      if (phoneNumber === false) {
        toastNotification('Phone number is invalid', 'error');
        setRequestSending(false);
        return null;
      }

      data = {
        message: {
          to: {
            phone_number: '+1' + phoneNumber,
          },
          template: 'ZHWWQ62XKN4NRCJWZHD3GBP1Z5KZ',
          data: {
            name: leadName,
            message: msgRef.current.innerText,
          },
        },
      };
      console.log('requestSend SMS', data);

      var config = {
        method: 'post',
        url: `${API_HOST}/send_courier`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);

          toastNotification('Text sent successfully :)', 'success');
          setRequestSending(false);
        })
        .catch(function (error) {
          console.log(error.message);

          toastNotification('An error occurred', 'error');
          setRequestSending(false);
        });
    } else {
      // type === email
      if (!email || !isValidEmail(email)) {
        toastNotification('Please enter a valid email', 'error');
        setRequestSending(false);
        return null;
      }
      data = {
        to: email,
        aptName: slugToString(getAptSlug()),
        leadName,
        reviewLink: `${RESIDENT_DASHBOARD}/survey/${getAptSlug()}?request=${requestID}`,
        message: msgRef.current.innerText,
      };

      data = {
        message: {
          to: {
            email: email,
          },
          template: 'BBYS20X8VX4674JN7JCFJM5V5YWZ',
          data: {
            subjectLine: teamMemberName + ' has requested a review',
            fromName:
              `Hey 👋 ${leadName}` +
              (teamMemberName ? '' : `,  ${teamMemberName} left you a message`),
            fromPic:
              'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/153/magnet/category/b1_2_bed_2_bath/4_bed___4_bath_mp4.gif',
            message: msgRef.current.innerText,
          },
        },
      };

      console.log('requestSend SMS', data);

      const config = {
        method: 'post',
        url: `${API_HOST}/send_courier`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);

          toastNotification('Email sent successfully :)', 'success');
          setRequestSending(false);
        })
        .catch(function (error) {
          console.log(error.message);

          toastNotification('An error occurred', 'error');
          setRequestSending(false);
        });
    }
  }
  return (
    <div>
      <div className="flex ">
        <h1 className="font-bold text-2xl mb-2 mr-5">Collect Reviews</h1>
      </div>
      <div className="w-full px-0 py-0 lg:py-14 lg:px-8 flex flex-col-reverse items-start justify-start lg:flex-row lg:justify-start lg:items-start">
        <div className="flex-1">
          <div className=" w-full flex items-start gap-6 justify-start pb-3">
            <h6
              onClick={() => setCurrentPage('qrcode')}
              className={
                currentPage === 'qrcode'
                  ? ' font-medium cursor-pointer text-pr border-b flex items-center gap-1 border-pr'
                  : ' font-medium cursor-pointer text-gray-600 flex items-center gap-1 '
              }
            >
              <IoQrCodeSharp />
              Link & QR code
            </h6>
            <h6
              onClick={() => setCurrentPage('texting')}
              className={
                currentPage === 'texting'
                  ? ' font-medium cursor-pointer text-pr border-b flex items-center gap-1 border-pr'
                  : ' font-medium cursor-pointer text-gray-600  flex items-center gap-1'
              }
            >
              <FaWpforms /> Texting
            </h6>
            <h6
              onClick={() => setCurrentPage('settings')}
              className={
                currentPage === 'settings'
                  ? ' font-medium cursor-pointer text-pr border-b flex items-center gap-1 border-pr'
                  : ' font-medium cursor-pointer text-gray-600  flex items-center gap-1'
              }
            >
              <FiSettings /> Settings
            </h6>
          </div>
          {currentPage === 'qrcode' && (
            <QrCodeGMB
              website={url}
              name={name}
              logo={reviewsMetadata?.logo}
              place_id={reviewsMetadata?.place_id}
              selectedTeamMemberReviewTrackingId={
                selectedTeamMemberReviewTrackingId
              }
              setSelectedTeamMemberReviewTrackingId={
                setSelectedTeamMemberReviewTrackingId
              }
              community_id={community_id}
              teamMemberName={teamMemberName}
              setTeamMemberName={setTeamMemberName}
            />
          )}
          {currentPage === 'settings' && <EditReview />}
          {currentPage === 'texting' && (
            <>
              <div className=" my-2 font-medium text-sm mb-2">
                Request Review via Personalized Message (Recommended)
              </div>
              <div
                className=" rounded-xl bg-pr p-3.5 text-white text-sm mb-4"
                ref={msgRef}
              >
                <div contentEditable>
                  Hi {leadName || 'XXXX'}, it's {teamMemberName} from {name}! I
                  was hoping you could do me a favor and leave my team and me a
                  review? Thank you in advance.
                </div>
                <p className=" text-white text-sm mt-3">
                  {RESIDENT_DASHBOARD}/writereview/{community_id}/
                  {reviewsMetadata?.place_id}
                  {teamMemberName
                    ? '?u=' + createScreenKey(teamMemberName)
                    : ''}
                </p>
              </div>
              <div className="flex items-center">
                <label className="inline-flex items-center mr-4">
                  <input
                    type="checkbox"
                    className="w-5 h-5 border-gray-300 rounded-full focus:ring-indigo-500 text-indigo-600"
                    value="SMS"
                    onChange={(e) => handleTypeSwitcher(e.target.value)}
                    checked={type === 'SMS'}
                  />
                  <span className="ml-2 text-sm">Send SMS</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="w-5 h-5 border-gray-300 rounded-full focus:ring-indigo-500 text-indigo-600"
                    value="email"
                    onChange={(e) => handleTypeSwitcher(e.target.value)}
                    checked={type === 'email'}
                  />
                  <span className="ml-2 text-sm">Send Email</span>
                </label>
              </div>
              <div className=" w-full flex flex-col gap-1 mt-3">
                <div className="grid grid-cols-2 gap-1">
                  <div>
                    <TextField
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      placeholder=""
                      defaultValue={leadName}
                      onChange={(e) => setLeadName(e.target.value)}
                      fullWidth
                    />
                  </div>
                  <div>
                    {type === 'SMS' && (
                      <TextField
                        type="number"
                        id="outlined-basic"
                        label="Phone"
                        variant="outlined"
                        defaultValue={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        fullWidth
                      />
                    )}
                    {type === 'email' && (
                      <TextField
                        type="email"
                        id="outlined-basic"
                        label="Email address"
                        variant="outlined"
                        defaultValue={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                      />
                    )}
                  </div>
                </div>

                <div>
                  <button
                    className=" bg-pr px-3 mt-4 py-2.5 rounded-md flex items-center justify-center text-white font-light"
                    onClick={handleSubmit}
                  >
                    {requestSending ? 'Request Sending....' : 'Request Review'}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function QrCodeGMB({
  website,
  place_id,
  community_id,
  teamMemberName,
  setTeamMemberName,
  name,
  logo,
  selectedTeamMemberReviewTrackingId,
  setSelectedTeamMemberReviewTrackingId,
}) {
  return (
    <div className={` w-full flex items-start justify-start`}>
      <div className=" w-full lg:w-96  border-2 border-gray-500 rounded-md p-3 shadow-lg bg-white">
        <div className="flex items-center mb-4 space-x-4">
          <img className="w-20 h-20 rounded-full" src={logo} alt="" />
          <div className="space-y-1 font-medium dark:text-white">
            <p>
              {name}{' '}
              <a
                href={website}
                target="_blank"
                className="block text-sm text-gray-500 dark:text-gray-400"
              >
                {website}
              </a>
            </p>
          </div>
        </div>

        <hr className="my-3 h-px bg-gray-200 border-0 dark:bg-gray-700" />
        <div className="grid place-items-center center justify-center items-center">
          <h1 className="text-lg mt-2 font-bold mb-2">Scan to review ⬇️</h1>
          <QRCode
            value={`${RESIDENT_DASHBOARD}/writereview/${community_id}/${place_id}${
              selectedTeamMemberReviewTrackingId
                ? '?u=' + selectedTeamMemberReviewTrackingId
                : ''
            }`}
            size={220}
          />
        </div>

        {teamMemberName && (
          <p className="block text-center center text-md text-gray-500 w-full mt-4 dark:text-gray-400 ">
            Thank you,
            <br />
            👋 @{createScreenKey(teamMemberName)}
          </p>
        )}

        <hr className="my-3 mt-5 h-px bg-gray-200 border-0 dark:bg-gray-700" />

        {place_id && (
          <div>
            <TextField
              id="input-with-icon-textfield"
              label="Link with Tracking"
              value={`${RESIDENT_DASHBOARD}/writereview/${community_id}/${place_id}${
                selectedTeamMemberReviewTrackingId
                  ? '?u=' + selectedTeamMemberReviewTrackingId
                  : ''
              }`}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() =>
                        window.open(
                          `${RESIDENT_DASHBOARD}/writereview/${community_id}/${place_id}${
                            selectedTeamMemberReviewTrackingId
                              ? '?u=' + selectedTeamMemberReviewTrackingId
                              : ''
                          }`,
                          '_blank'
                        )
                      }
                    >
                      <LaunchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              id="input-with-icon-textfield"
              label="No Tracking"
              value={`https://search.google.com/local/writereview?placeid=${place_id}`}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() =>
                        window.open(
                          `https://search.google.com/local/writereview?placeid=${place_id}`,
                          '_blank'
                        )
                      }
                    >
                      <LaunchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <span className="text-sm font-gray-200">GMB: {place_id}</span>
          </div>
        )}
      </div>
    </div>
  );
}
