import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import RouteInput from '../../components/Reusable/RouteInput';
import VideoMagnet from '../../components/VideoMagnet/VideoMagnet';
import PreviewVideoMagnet from '../manage-magnets/PreviewVideoMagnet';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export function ButtonPreview({ button, deleteButton }) {
  // const { magnetId, magnetType } = useSelector((state) => state.magnetStates);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: button.id });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      style={style}
      key={button.id}
      ref={setNodeRef}
      className="flex flex-row w-full items-center justify-center h-16 hover:bg-gray-200 relative group"
    >
      <div
        onClick={() => deleteButton(button.id)}
        className="opacity-0 group-hover:opacity-100 w-4 h-4 bg-red-300 rounded-full absolute -left-2 -top-2 grid place-items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      <svg
        {...attributes}
        {...listeners}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
      </svg>
      <p {...attributes} {...listeners}>
        {button.text}
      </p>
    </div>
  );
}
