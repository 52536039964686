import React, { useEffect, useState } from 'react';
import {  useParams } from 'react-router-dom';

export  function V2TourFullTYG({ alias='@vue32', initialSearchParams }) {
    const [iframeSrc, setIframeSrc] = useState("");
  
    useEffect(() => {
      const baseUrl = "https://new-videomagnet.onrender.com";
      console.log("aliasTYG: ", alias);
      const queryString = new URLSearchParams({
        uuid: alias.replace("%40", "@"), // for some reason the url stringifies '@' to '%40' so I am just replacing it to pass in the data TYG
        inline: "true",
        isTwoColumn: "false",
        captureLead : 'false',
        captureLeadAfter : undefined,
        ...Object.fromEntries(searchParams.entries()),
      }).toString();
      setIframeSrc(`${baseUrl}?${queryString}`);
    }, [alias, searchParams]);
  
    
    const fullScreenStyle = {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      border: "none",
    };
  
    return (
      <iframe
        src={iframeSrc}
        style={fullScreenStyle}
        allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *; clipboard-write"
        allowFullScreen
      />
    );
  }

const V2PreviewTourTYG = ({  }) => {
  const [iframeSrc, setIframeSrc] = useState('');
  const { alias } = useParams()

  useEffect(() => {
    const baseUrl = 'https://new-videomagnet.onrender.com';
    console.log('aliasTYG: ', alias);

    const urlParams = new URLSearchParams(window.location.search);
    
    // Create an object with default values and URL parameters
    const paramsObject = {
      uuid: "@" + alias.replace('@', ''), // Ensure @ is at the start and not duplicated
      inline: 'true',
      isTwoColumn: 'true',
      captureLead : 'false',
      captureLeadAfter : undefined,
      ...Object.fromEntries(urlParams)
    };

    // Create a new URLSearchParams object from the merged object
    const queryParams = new URLSearchParams(paramsObject);

    const newIframeSrc = `${baseUrl}?${queryParams.toString()}`;
    setIframeSrc(newIframeSrc);
    console.log('iframeSrc: TYG ', newIframeSrc);
  }, [alias]);

  const fullScreenStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
  };

  return (
    <iframe
      src={iframeSrc}
      style={fullScreenStyle}
      allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *; clipboard-write"
      allowFullScreen
    />
  );
};


export const V1PreviewTourTYG = ({  }) => {
    const [iframeSrc, setIframeSrc] = useState('');
    const { alias } = useParams()
  
    useEffect(() => {
      const baseUrl = 'https://embed.tour.video';
      console.log('aliasTYG: ', alias);
  
      const urlParams = new URLSearchParams(window.location.search);
      
      // Create an object with default values and URL parameters
      const paramsObject = {
        uuid: "@" + alias.replace('@', ''), // Ensure @ is at the start and not duplicated
        inline: 'true',
        isTwoColumn: 'true',
        ...Object.fromEntries(urlParams)
      };
  
      // Create a new URLSearchParams object from the merged object
      const queryParams = new URLSearchParams(paramsObject);
  
      const newIframeSrc = `${baseUrl}?${queryParams.toString()}`;
      setIframeSrc(newIframeSrc);
      console.log('iframeSrc: TYG ', newIframeSrc);
    }, [alias]);
  
    const fullScreenStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: 'none',
    };
  
    return (
      <iframe
        src={iframeSrc}
        style={fullScreenStyle}
        allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *; clipboard-write"
        allowFullScreen
      />
    );
  };
  
export default V2PreviewTourTYG;