import CircularProgress from '@material-ui/core/CircularProgress';
import Rating from '@material-ui/lab/Rating';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import AnimatedDialog from '../../../components/Reusable/AnimatedDialog';
import { generateID } from '../../../core';
import { mergeNestedArray } from '../../../utils/eventUtils';
import { uploadFile } from '../../../utils/files';

const Thanks = ({
  updateReview,
  reviewData,
  updateRequestHistory,
  apt_template,
  setRoute,
  requestHistory,
  rating,
}) => {
  const tagInputRef = useRef(null);

  console.log('data', {
    updateReview,
    reviewData,
    updateRequestHistory,
    apt_template,
    setRoute,
    requestHistory,
    rating,
  });

  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [openReferralDialog, setOpenReferralDialog] = useState(false);
  const [loadingReviewLink, setLoadingReviewLink] = useState(false);
  const [leftGoogleReview, setLeftGoogleReview] = useState(false);
  const [leadUser, setLeadUser] = useState({
    id: requestHistory?.data?.leadUserId
      ? requestHistory.leadUserId
      : generateID(3, 4),
    name: requestHistory?.data?.leadName,
    phone: requestHistory?.data?.leadPhone,
    email: requestHistory?.data?.leadEmail,
    aboutMe: '',
    rating,
    notes: '',
  });

  const [checkedReferral, setCheckoutReferral] = useState(false);
  const [tagValue, setTagValue] = useState('');
  const [metadata, setMetadata] = useState('');
  const [skills, setSkills] = useState([]);
  const [photoUrl, setPhotoUrl] = useState('');
  const [downloadUrl, setDownloadUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingOnLoad, setLoadingOnLoad] = useState(false);
  const [progress, setProgress] = useState(null);
  const [tagList, setTagList] = useState([]);
  const [suggestion, setSuggestion] = useState([]);
  const [file, setFile] = useState(null);
  const [tags, setTags] = useState([]);
  const [openTagSelector, setOpenTagSelector] = useState(false);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    let badgesArray = JSON.parse(apt_template?.settings)?.referrals?.badges.map(
      (item) => item.type
    );

    badgesArray && setTagList(mergeNestedArray(badgesArray));
  }, [apt_template]);

  // console.log('req history passed in ', requestHistory);
  // console.log('reviewData', reviewData);
  let review_links = JSON.parse(apt_template.review_json);

  useEffect(() => {
    setLoadingReviewLink(true);

    if (parseInt(rating) >= 4) {
      setTimeout(() => {
        window.open(review_links.google_reviews_link);
        setLoadingReviewLink(false);
      }, 1500);
    } else {
      setTimeout(() => {
        window.open(review_links.google_reviews_link);
        setLoadingReviewLink(false);
      }, 12000);
    }
  }, [rating]);

  const handleClose = () => {
    setOpenReviewDialog(false);
  };

  const handleCloseReferralDialog = () => {
    setOpenReferralDialog(false);
  };

  const handleSubmit = () => {
    const googleReviewLink = JSON.parse(apt_template.review_json);
    console.log('googleReviewLink', googleReviewLink);
    console.log('updatedUserReviewData', {
      ...reviewData,
      from_user_name: leadUser.name,
      from_user: leadUser.id,
      metadata: {
        ...reviewData?.metadata,
        bonusReviewCompleted: true,
        notes: leadUser.notes,
        phone: leadUser.phone,
        email: leadUser.email,
        google_reviews_link: googleReviewLink.google_reviews_link,
      },
    });
    updateReview({
      ...reviewData,
      from_user_name: leadUser.name,
      from_user: leadUser.id,
      metadata: {
        ...reviewData?.metadata,
        bonusReviewCompleted: true,
        notes: leadUser.notes,
        phone: leadUser.phone,
        email: leadUser.email,
      },
    });
    handleClose();
    setRoute(leadUser);
  };

  useEffect(() => {
    setMetadata({
      ...metadata,
      photoUrl: downloadUrl,
      aboutMe: leadUser.aboutMe,
      skills: tags,
    });
  }, [downloadUrl, leadUser.aboutMe, tags, checkedReferral]);
  // console.log(downloadUrl);
  const handleChange = (event) => {
    setLeadUser({ ...leadUser, [event.target.name]: event.target.value });
  };

  async function onSelectImage(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPhotoUrl(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);

      setFile(e.target.files[0]);
    }
  }

  const handleUpload = async () => {
    try {
      const url = await uploadFile(
        file,
        setProgress,
        'users',
        'profile/' + leadUser.id
      );

      console.log('urlUpload', url);

      setPhotoUrl(url);
      setDownloadUrl(url);

      setLoading(false);
      return url;
    } catch (err) {
      console.log(err);
    }
  };

  const addTags = (value) => {
    if (tags?.some((tag) => tag === value)) {
      alert('Skill is already added');
      return;
    }
    setTags([...tags, value.replace(/\s+/g, '')]);
    setTagValue('');
  };

  const removeTags = (value) => {
    const temp = tags.filter((tag) => tag !== value);
    if (temp.length === 0) {
      setTags([]);
    }
    setTags(temp);
  };

  function handleSuggestion(value) {
    const filter = tagList.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestion(filter);
  }

  const handleChangeTagValue = (value) => {
    setTagValue(value);
    handleSuggestion(value);
  };

  useEffect(() => {
    if (file === null) {
      setFile(null);
    }
  }, [file]);

  const submitReferral = async () => {
    if (!leadUser.email || !leadUser.phone || !leadUser.name) {
      alert('Please fill all the fields');

      return;
    }
    let url = '';
    if (file) {
      url = await handleUpload();
    }

    try {
      // to avoiding to upload same file again

      let postData = {
        email: leadUser.email,
        name: leadUser.name,
        phone: leadUser.phone,
        metadata: {
          ...metadata,
          photoUrl: url,
        },
        community: apt_template?.id,
        id: leadUser.id,
      };

      const user = await axios.post(
        `https://api.directual.com/good/api/v5/data/users/newUser?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83`,
        postData
      );
      console.log('userUpdated after review TYG: ', user);
      toast(
        '🥳congratulations on joining the review program. A staff member will be reviewing your submission to award you those rewards points :)',
        {
          position: 'top-right',
          type: 'success',
          autoClose: 5000,
        }
      );
      setLoading(false);
      handleCloseReferralDialog();
    } catch (err) {
      console.log('err', err);
      setLoading(false);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (tagInputRef.current && !tagInputRef.current.contains(event.target)) {
        setFocused(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tagInputRef]);

  return (
    <>
      <div className="survey-thanks-page rel">
        <div className="wrapper flex flex-col aic">
          <div className="meta">
            <div className="title font s24 b7 c333">
              Thanks for your response
            </div>
            <div className="txt font s18 b4 c333">
              We look forward to making your {apt_template.name} a better place!
            </div>
          </div>
          {parseInt(rating) >= 2.5 ? (
            <div className="block">
              {loadingReviewLink && (
                <div className="loading">
                  <CircularProgress />
                </div>
              )}
              <div className="msg font s16 b6 c333">
                Forwarding you to google reviews. Confirm you have completed it
                and win 100 ResidentBux
              </div>
              <div className="actions-btns flex aic">
                <div className="action">
                  <button
                    onClick={() => setOpenReviewDialog(true)}
                    className="confirm-btn actions-btn font"
                  >
                    Confirmed, I Left a Review
                  </button>
                  <div className="txt font">
                    + to get 10 dollars towards your rewards balance
                  </div>
                </div>
                <div className="action">
                  <button
                    className=" actions-btn font"
                    onClick={() => setOpenReferralDialog(true)}
                  >
                    Join the referral program
                  </button>
                  <div className="txt font">
                    + to get 500 dollars when you refer someone
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {/* <div className="block">
              {loadingReviewLink && (
                <div className="loading">
                  <CircularProgress />
                </div>
              )}
              <div className="msg font s16 b6 c333">
                Sorry your experience wasn’t great. We’d love to help. Would you
                like a staff to reach back out to you?
              </div>
              <div className="actions-btns flex aic">
                <button
                  onClick={() => openReviewDialog(true)}
                  className="confirm-btn actions-btn font"
                >
                  Yes!
                </button>
                <button className=" actions-btn font">No!</button>
              </div>
            </div> */}
              <div className="block">
                {loadingReviewLink && (
                  <div className="loading">
                    <CircularProgress />
                  </div>
                )}
                <div className="actions-btns flex aic">
                  <div className="action">
                    <button
                      onClick={() => setOpenReviewDialog(true)}
                      className="confirm-btn actions-btn font"
                    >
                      Confirmed, I Left a Review
                    </button>
                  </div>
                  <div className="action">
                    <button
                      className=" actions-btn font"
                      onClick={() => setOpenReferralDialog(true)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
              <div className="confirm-msg font">
                Confirm your review to qualify for any current resident rewards
              </div>
            </>
          )}
        </div>
      </div>

      {/* Review Program Dialog */}
      <AnimatedDialog
        open={openReviewDialog}
        onClose={handleClose}
        backdrop={handleClose}
        maxWidth={'md'}
      >
        <div className="survey-thanks-bux-dialog flex">
          <button className="cleanbtn close-btn fontr" onClick={handleClose}>
            &times;
          </button>
          <div
            className="left"
            style={{
              backgroundImage: `url(${
                require('../../../assets/thanks-dialog-image.png').default
              })`,
            }}
          >
            <div className="left-content">
              <div className="title font">Welcome to our review program!</div>
              <div className="txt font">fsd</div>
              <div className="note">
                For those join the <span className="b6">review program:</span>{' '}
                $100 rewards & bonus to each new review
              </div>
            </div>
          </div>
          <div className="right flex flex-col">
            <div className="form">
              <div className="user-blk flex">
                <div className="image-blk">
                  <div
                    className="dp"
                    style={{
                      backgroundImage: `url(${
                        photoUrl || 'https://placeimg.com/640/480/people?1'
                      } )`,
                    }}
                  />
                  <button
                    className="font change-img s12 b5 color"
                    onClick={() =>
                      document.getElementById('_upload-file_').click()
                    }
                  >
                    Upload Photo
                  </button>
                  <input
                    onClick={(e) => (e.target.value = '')}
                    onChange={onSelectImage}
                    type="file"
                    accept="image/*"
                    className="hide-element "
                    id="_upload-file_"
                  />
                </div>
                <div className="meta flex flex-col">
                  <input
                    type="text"
                    className="iput font"
                    placeholder="Name"
                    value={leadUser.name}
                    name="name"
                    onChange={(e) => handleChange(e)}
                  />
                  <Rating
                    name="rating"
                    precision={0.5}
                    size="large"
                    value={leadUser.rating}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="tag-blk">
                <div className="tag-blk-title font">Review Badges</div>
                <div className="selected-tags">
                  {tags &&
                    tags?.map((tag, index) => (
                      <div key={index} className="tag">
                        {tag} +
                      </div>
                    ))}
                </div>
              </div>
              <textarea
                type="text"
                className="iput area font"
                placeholder="Additional notes"
                value={leadUser.notes}
                name="notes"
                onChange={(e) => handleChange(e)}
              />

              <div className="flex-center-vt mb-3">
                <input
                  type="checkbox"
                  checked={leftGoogleReview}
                  onChange={(e) => setLeftGoogleReview(e.target.checked)}
                />
                <div className="checkbox-lbl font ml-3">
                  I also left a google review?
                </div>
              </div>

              <input
                type="text"
                className="iput font"
                placeholder="Phone"
                value={leadUser.phone}
                name="phone"
                onChange={(e) => handleChange(e)}
              />
              <input
                type="text"
                className="iput font"
                placeholder="Email"
                value={leadUser.email}
                name="email"
                onChange={(e) => handleChange(e)}
              />
              <button
                onClick={handleSubmit}
                className="cleanbtn submit-btn font"
              >
                {loading ? 'Loading...' : 'Submit'}
              </button>
            </div>
            <div className="notic font s14 black flex aic">
              *will be reviewed by a human
            </div>
          </div>
        </div>
      </AnimatedDialog>

      {/* Referral Program Dialog */}
      <AnimatedDialog
        open={openReferralDialog}
        onClose={handleCloseReferralDialog}
        backdrop={handleCloseReferralDialog}
        maxWidth={'md'}
      >
        <div className="survey-thanks-bux-dialog referral-blk flex">
          <button
            className="cleanbtn close-btn fontr"
            onClick={handleCloseReferralDialog}
          >
            &times;
          </button>
          <div
            className="left"
            style={{
              backgroundImage: `url(${
                require('../../../assets/thanks-dialog-image.png').default
              })`,
            }}
          >
            <div className="left-content">
              <div className="title font">Welcome to our referral program!</div>
              <div className="txt font">
                Customize your profile so we can put you into the referral
              </div>
              <div className="note">
                For those join the <span className="b6">referral program:</span>{' '}
                $100 rewards & bonus to each new referral
              </div>
            </div>
          </div>
          <div className="right flex flex-col">
            <div className="form">
              <div className="user-blk flex">
                <div className="image-blk">
                  <div
                    className="dp"
                    style={{
                      backgroundImage: `url(${
                        photoUrl || 'https://placeimg.com/640/480/people?1'
                      } )`,
                    }}
                  />
                  <button
                    className="font change-img s12 b5 color"
                    onClick={() =>
                      document.getElementById('_upload-file_').click()
                    }
                  >
                    Upload Photo
                  </button>
                  <input
                    onClick={(e) => (e.target.value = '')}
                    onChange={onSelectImage}
                    type="file"
                    accept="image/*"
                    className="hide-element "
                    id="_upload-file_"
                  />
                </div>
                <div className="meta flex flex-col">
                  <input
                    type="text"
                    className="iput font"
                    placeholder="Name"
                    value={leadUser.name}
                    name="name"
                    onChange={(e) => handleChange(e)}
                  />
                  <textarea
                    type="text"
                    className="iput area font"
                    placeholder="About me"
                    value={leadUser.aboutMe}
                    name="aboutMe"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div
                className="dp"
                style={{
                  backgroundImage: `url(https://placeimg.com/640/480/people?1)`,
                }}
              />
              <div className="tag-blk">
                <div className="selected-tags">
                  {tags &&
                    tags?.map((tag, index) => (
                      <div key={index} className="tag">
                        {tag}
                        <span
                          onClick={() => removeTags(tag)}
                          style={{ cursor: 'pointer', fontWeight: 'bold' }}
                        >
                          {' '}
                          -
                        </span>
                      </div>
                    ))}
                </div>
                <div className="tag-selector" ref={tagInputRef}>
                  <div className="tag-blk-title font">About me</div>
                  <div className="iput flex aic">
                    <input
                      type="text"
                      onFocus={(e) => {
                        setFocused(true);
                        e.target.value.length === 0 && setSuggestion(tagList);
                      }}
                      placeholder="Add a Tag"
                      className="input-text font"
                      value={tagValue}
                      onChange={(e) => handleChangeTagValue(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          addTags(e.target.value);
                        }
                      }}
                    />
                    <div
                      className="add-btn icon-plus"
                      onClick={() => setOpenTagSelector(!openTagSelector)}
                    />
                  </div>
                  <div className={`tag-list ${focused ? 'active' : ''}`}>
                    {suggestion &&
                      suggestion?.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => addTags(item)}
                          className="item"
                        >
                          {item} +
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <input
                type="text"
                className="iput font"
                placeholder="Name"
                name="name"
                value={leadUser.name}
                onChange={(e) => handleChange(e)}
              />
              <input
                type="text"
                className="iput font"
                placeholder="Phone"
                name="phone"
                value={leadUser.phone}
                onChange={(e) => handleChange(e)}
              />
              <input
                type="text"
                className="iput font"
                placeholder="Email"
                name="email"
                value={leadUser.email}
                onChange={(e) => handleChange(e)}
              />
              <button
                className="cleanbtn submit-btn font"
                onClick={submitReferral}
              >
                {loading ? 'Loading...' : 'Submit'}
              </button>
            </div>
            <div className="notic font s14 black flex aic">
              *will be referral by a human
            </div>
          </div>
        </div>
      </AnimatedDialog>
    </>
  );
};

export default Thanks;
