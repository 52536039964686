import React from 'react';
import cross from '../../assets/cross.png';
import tik from '../../assets/tik.png';
const Switchto = () => {
  return (
    <div className=" my-20 w-full">
      <h1 className=" text-center text-2xl font-bold w-full text-gray-700">
        Switch to video.pro. See the impact.
      </h1>
      <div className=" flex items-center justify-center w-full gap-5 mt-10">
        <div className=" lg:w-80" style={{ background: '#FFFBFB' }}>
          <div
            style={{ background: '#E54C4C' }}
            className=" w-full flex items-center justify-center text-white text-lg py-2"
          >
            Before
          </div>
          <div className=" p-6">
            <div className=" flex items-center gap-3">
              <img src={cross} alt="" />
              <p className=" text-xs">
                Struggling with unreliable video editors, sub-standard videos
              </p>
            </div>
            <div className=" flex items-center gap-3 mt-4">
              <img src={cross} alt="" />
              <p className=" text-xs">Long, complicated feedback threads</p>
            </div>
            <div className=" flex items-center gap-3 mt-4">
              <img src={cross} alt="" />
              <p className=" text-xs">Expensive, pay per hour rates</p>
            </div>
            <div className=" flex items-center gap-3 mt-4">
              <img src={cross} alt="" />
              <p className=" text-xs">
                Limited revisions, disappointing final results
              </p>
            </div>
            <div className=" flex items-center gap-3 mt-4">
              <img src={cross} alt="" />
              <p className=" text-xs">
                Limited revisions, disappointing final results
              </p>
            </div>
          </div>
        </div>
        <div className=" lg:w-80" style={{ background: '#EAFFF1' }}>
          <div
            style={{ background: '#11A041' }}
            className=" w-full flex items-center justify-center text-white text-lg py-2"
          >
            After
          </div>
          <div className=" p-6">
            <div className=" flex items-center gap-3">
              <img src={tik} alt="" />
              <p className=" text-xs">
                Work with top 1% video talent, get unique videos that match your
                vision
              </p>
            </div>
            <div className=" flex items-center gap-3 mt-4">
              <img src={tik} alt="" />
              <p className=" text-xs">
                Simple, effective communication on your preferred platform
              </p>
            </div>
            <div className=" flex items-center gap-3 mt-4">
              <img src={tik} alt="" />
              <p className=" text-xs">
                Flat rate pricing. No surprise fees. No contracts.
              </p>
            </div>
            <div className=" flex items-center gap-3 mt-4">
              <img src={tik} alt="" />
              <p className=" text-xs">
                Unlimited revisions and design requests
              </p>
            </div>
            <div className=" flex items-center gap-3 mt-4">
              <img src={tik} alt="" />
              <p className=" text-xs">1 day turnaround, prompt communication</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Switchto;
