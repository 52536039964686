import React, { useRef, useState } from 'react';
import notification from '../../components/Reusable/Notification';
import { analyticsMetadata, formSubmission } from '../../utils/api';

export default function PropertyDetails({ details, apt, agent_idx }) {
  return (
    <div className="property-detail">
      <div className="share-video-container">
        <div className="grid-3">
          <div>
            <Video source={details.video} />
            {details.additionalInfo && (
              <>
                {details.additionalInfo.detailedDescription && (
                  <Details
                    title={'Details'}
                    child={<p>{details.additionalInfo.detailedDescription}</p>}
                  />
                )}
                {details.additionalInfo.features.length > 0 && (
                  <Details
                    title={'Features'}
                    child={
                      <div role="list" className="collection-list-3">
                        {details.additionalInfo.features.map((feature) => {
                          return <ListItem text={feature} />;
                        })}
                      </div>
                    }
                  />
                )}
              </>
            )}
          </div>
          <Form apt={apt} agent_idx={agent_idx} details={details} />
        </div>
      </div>
    </div>
  );
}

function Video({ source }) {
  const videoRef = useRef(null);
  const video = videoRef.current;

  const handleTimeUpdate = () => {
    const { currentTime, duration } = video || {};
    analyticsMetadata.videoTime = currentTime;
  };

  return (
    <div className="gallery-wrap">
      <div className="property-video">
        <div>
          <video
            id="my-video"
            ref={videoRef}
            controls
            onTimeUpdate={handleTimeUpdate}
            preload="auto"
            poster=""
            data-setup="{}"
            autoplay
            style={{ minWidth: '100%' }}
          >
            <source src={source} />
            {/*"https://storage.googleapis.com/leasemagnets-cloud-storage/Gameroom__Bowling_5.mp4"*/}
            <p className="vjs-no-js">
              To view this video please enable JavaScript, and consider
              upgrading to a web browser that
              <a
                href="https://videojs.com/html5-video-support/"
                target="_blank"
              >
                supports HTML5 video
              </a>
            </p>
          </video>
        </div>
      </div>
      {/* IMAGES COMMENTED OUT FOR MVP
        <div className="gallery-wrapper">
        <div className="collection-list-wrapper">
          <div role="list" className="collection-list w-dyn-items w-row">
            <PreviewImage />
            <PreviewImage />
            <PreviewImage />
            <PreviewImage />
          </div>
        </div>
      </div>*/}
    </div>
  );
}

function Details({ title, child }) {
  return (
    <div className="white-block">
      <div className="title-block">
        <h3>{title}</h3>
      </div>
      <div className="content-wrapper">{child}</div>
    </div>
  );
}

function YellowCheck() {
  return (
    <>
      <img
        src="https://uploads-ssl.webflow.com/600fa4ae7493ac0a6a8a88b2/600fa4b14cd50580acb98719_Check%20Yellow%20(1).svg"
        alt="checkmark"
        className="filter-icon"
      />
    </>
  );
}

function ListItem({ text }) {
  return (
    <div role="listitem" className="details-listitem">
      <YellowCheck />
      <div>{text}</div>
    </div>
  );
}

function PreviewImage() {
  return (
    <div role="listitem" className="collection-item-2">
      <a href="#" className="gallery-item">
        <img
          src="https://uploads-ssl.webflow.com/600fa4b14cd505108fb986e0/601072c4b5441a6e1f66e713_Screen%20Shot%202021-01-26%20at%202.51.02%20PM.png"
          alt="cover"
          className="image-38"
        />
        <div className="image-cover">
          <div className="view">
            <img
              src="https://static.thenounproject.com/png/421-200.png"
              alt=""
              className="image-37"
            />
          </div>
        </div>
      </a>
    </div>
  );
}

function Form({ apt, agent_idx, details }) {
  let agentInfo = {};
  // Get agent info

  if (apt.agents && agent_idx) {
    agentInfo = apt.agents[agent_idx];
    console.log('heres my current agent: ', agentInfo, agent_idx, apt);
  } else {
    agentInfo = {
      image: apt.backgroundImg,
      name: apt.communityName,
      email: apt.email,
    };
  }

  const [NotificationBar, setNotification] = notification();

  // Form input states
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      to: agentInfo.email,
      subject: `New Inquiry from ${name} | ${apt.communityName}`,
      leadName: name,
      replyTo: email,
      replyToName: name,
      message: `From: <strong>${name}</strong><br />Phone: <strong>${phone}</strong><br />Email: <strong>${email}</strong><br /><strong>Context: ${details.title} from <a href="${apt.website}">${apt.communityName}</a>, ${apt.location} </strong><br /> <br />Message: <br /><strong>${msg}</strong><br /><br /><em>Reply to this email to respond to ${name}</em> <br /> <br /> Powered by LeaseMagnets </br>`,
    };

    formSubmission({
      lead_name: name,
      lead_phone: phone,
      lead_email: email,
      lead_message: msg,
    });

    // post(`/email/plain`, data, { auth: false })
    //   .then(data => {
    //     console.log(data);
    //     setNotification('Message successfully sent!', 'success');
    //   })
    //   .catch(error => {
    //     console.log(error.message);
    //   });
  }

  return (
    <div>
      <div className="staff-card">
        <div className="avatar-base">
          <div className="avatar-wrap">
            <img
              src={
                agentInfo?.image
                  ? agentInfo.image
                  : 'https://ssu.ca/wp-content/uploads/2020/08/default-profile.png'
              }
              alt="leasing agent"
              className="avatar-image"
            />
          </div>
          <div>
            {(agentInfo?.name || apt?.communityName) && (
              <div className="agent-name-2-0">
                {agentInfo?.name ? agentInfo?.name : apt?.communityName}
              </div>
            )}
            {(agentInfo?.email || apt?.email) && (
              <a
                href={
                  agentInfo?.name
                    ? `mailto:${agentInfo?.name}`
                    : `mailto:${apt?.email}`
                }
                className="agent-link"
              >
                Email me
              </a>
            )}
          </div>
        </div>
        <div className="w-form">
          <form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            onSubmit={handleSubmit}
          >
            <input
              type="text"
              className="text-field w-input"
              maxlength="256"
              name="name"
              data-name="Name"
              placeholder="Name"
              id="name"
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="tel"
              className="text-field w-input"
              maxlength="256"
              name="Phone"
              data-name="Phone"
              placeholder="Phone"
              id="Phone"
              required=""
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              type="email"
              className="text-field w-input"
              maxlength="256"
              name="Email"
              data-name="Email"
              placeholder="Email"
              id="Email"
              required=""
              onChange={(e) => setEmail(e.target.value)}
            />
            <textarea
              placeholder="Hello, I am interested in…"
              maxlength="5000"
              id="Message"
              name="Message"
              data-name="Message"
              className="text-field-large w-input"
              onChange={(e) => setMsg(e.target.value)}
            ></textarea>
            <input
              type="submit"
              value="Submit"
              data-wait="Please wait..."
              className="button-submit w-button"
            />
          </form>
          <NotificationBar />
        </div>
      </div>
    </div>
  );
}
