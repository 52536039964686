import { get } from '../../utils/request';

export const listCommunities = () => async (dispatch) => {
  try {
    dispatch({ type: 'COMMUNITIES_LIST_REQUEST' });

    const data = await get('/communities');

    dispatch({
      type: 'COMMUNITIES_LIST_SUCCESS',
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: 'COMMUNITIES_LIST_FAIL',
      payload: error,
    });
  }
};

export const listCommunityDetial = (community_id) => async (dispatch) => {
  try {
    dispatch({ type: 'COMMUNITY_DETAIL_REQUEST' });

    const res = await get('/communities');
    let data = res.communities.filter((apt) => apt.id == community_id);

    if (data) {
      dispatch({
        type: 'COMMUNITY_DETAIL_SUCCESS',
        payload: data,
      });
    }

    if (res.error) {
      dispatch({
        type: 'COMMUNITY_DETAIL_FAIL',
        payload: res.error,
      });
    }
  } catch (error) {
    dispatch({
      type: 'COMMUNITY_DETAIL_FAIL',
      payload: error,
    });
  }
};
