import { useSelector, useDispatch } from 'react-redux';
import { arrayMove, useSortable } from '@dnd-kit/sortable';
import { SortableContext } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { v4 as uuidv4 } from 'uuid';
import { useState, useEffect } from 'react';
import { MdEdit, MdUpdate } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import AddBubbleSection from './AddBubbleSection';
import { generateRoutes } from '../manage-magnets/ManageMagnet';
import { getMagnetData } from '../../store/actions/magnetActions';
import ChatbotBubble from './ChatbotBubble';
import TriggersModal from './TriggersModal';
import { isEmpty } from 'lodash';
import { FaMagic } from 'react-icons/fa';
import { TriggerHeadline } from './ConditionRow';
import BehaviorModal from './BehaviorModal';

function ChatbotDialog({
  defaultConfig,
  setDefaultConfig,
  filter = () => true,
  updateData,
}) {
  const { template } = useSelector((state) => state.getMagnet);
  const { magnetId: magnetUuid } = useSelector((state) => state.magnetStates);
  const { data: communityDetails } = useSelector(
    (state) => state.listCommunityDetial
  );
  const { community_id } = useParams();
  const [selectedRow, setSelectedRow] = useState(null);
  const [conditionModal, setconditionModal] = useState(false);
  const [behaviorModal, setBehaviorModal] = useState(false);
  const [idx, setIdx] = useState(0);
  const [buttonIndex, setButtonIndex] = useState(0);
  const [progress, setProgress] = useState(false);
  const [routes, setRoutes] = useState(null);
  const _dispatch = useDispatch();

  // allows the x to still be clickable inside the button
  // https://github.com/clauderic/dnd-kit/issues/591
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );
  useEffect(() => {
    if (!defaultConfig?.length) {
      defaultConfig = template?.default_config;
    }
    console.log('template', template);
    // console.log('BCDA Template: ', template);
    if (
      !isEmpty(template) &&
      Object.keys(template).length > 0 &&
      template.constructor === Object
    ) {
      setRoutes(generateRoutes(template));
      console.log('BCDA Routes: ', generateRoutes(template));
    }
  }, [template]);

  const handleCloseSignOutDialog = () => {
    setconditionModal(false);
  };

  const handleRowSelection = (newSelection) => {
    console.log('rowSelected: ', newSelection);
    setSelectedRow(newSelection);
  };

  const deselectRow = () => {
    setSelectedRow(null);
  };

  const onClickLink = (link) => {
    if (link.route) {
      const newRoute = link.route.split('.');
      // onClick(newRoute);
    } else if (link.href) {
      // This creates an inconsistency in the "linked list" of Events, which
      // each have a "to" and a "from" route. We could fire another Event here
      // from `link.href` to `currentRoute`, or when processing Events, use the
      // previous `from` if previous `to` is not equal to current `from`
      window.open(link.href, '_blank').focus();
    } else if (link.href_direct) {
      // This creates an inconsistency in the "linked list" of Events, which
      // each have a "to" and a "from" route. We could fire another Event here
      // from `link.href` to `currentRoute`, or when processing Events, use the
      // previous `from` if previous `to` is not equal to current `from`
      window.open(link.href_direct, '_self').focus();
    }
  };

  function getChatbot() {
    return defaultConfig?.chatbot || [];
  }

  function setChatbot(chatbotPayload) {
    setDefaultConfig((prevDefaultConfig) => {
      const newDefaultConfig = { ...prevDefaultConfig };
      newDefaultConfig.chatbot =
        typeof chatbotPayload === 'function'
          ? chatbotPayload(prevDefaultConfig.chatbot)
          : chatbotPayload;
      return newDefaultConfig;
    });
  }

  function addNewChatBubble() {
    setChatbot([
      ...getChatbot(),
      {
        type: 'Basic',
        text: '',
        id: uuidv4(),
        condition: [],
        buttons: [{ label: '', route: '', href: '' }],
        sender: template?.magnetSettings?.communityName,
        ...updateData,
      },
    ]);
    handleRowSelection(getChatbot()?.length);
  }

  const isValidRowSelected = selectedRow !== null;

  const saveChatbot = async (props) => {
    console.log('saving_default_configuuu', {
      ...defaultConfig,
      chatbot: getChatbot(),
      specialOffer : defaultConfig?.chatbot?.[0]?.['text']
    });
    post(
      '/magnets/template/updateMagnet/dynamic/defaultEmbedupdated',
      {
        magnet_uuid: magnetUuid,
        default_config: {
          ...defaultConfig,
          chatbot: getChatbot(),
          specialOffer : defaultConfig?.chatbot?.[0]?.['text']
        },
      },
      {
        auth: false,
        //, host: "http://localhost:8080"
      }
    )
      .then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        _dispatch(getMagnetData(community_id));

        toast(
          props?.toastMessage
            ? props?.toastMessage
            : `Successfully Saved CTAs & Special Offers`,
          {
            position: 'top-center',
            type: 'success',
            autoClose: 5000,
          }
        );
      })
      .catch(() => {
        toast('No internet connection found. App is running in offline mode.', {
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
      });
  };

  const deleteBubble = (index) => {
    const newChatbot = getChatbot();
    newChatbot.splice(index, 1);
    setChatbot(newChatbot);
  };

  const handleUpload = async (file) => {
    try {
      const url = await uploadFile(
        file,
        setProgress,
        community_id,
        'magnetuid/' + guidGenerator()
      );
      setProgress(false);
      return url;
    } catch (err) {
      console.log(err);
    }
  };

  async function onSelectImage(e) {
    if (e.target.files && e.target.files.length > 0) {
      let imageUrl = await handleUpload(e.target.files[0]);

      let copytemporaryChatbot = getChatbot();
      if (copytemporaryChatbot[buttonIndex].buttons[idx]?.picArray) {
        copytemporaryChatbot[buttonIndex].buttons[idx].picArray.push({
          img: imageUrl,
          alt: '',
        });
      } else {
        copytemporaryChatbot[buttonIndex].buttons[idx].picArray = [
          { img: imageUrl, alt: '' },
        ];
      }

      setChatbot([...copytemporaryChatbot]);
    }
  }

  const deleteSelectedImage = (item, index, idx, pic_idx) => {
    const filterdImageArrray = getchatbot()[index].buttons[idx].picArray.filter(
      (pic, l_index) => pic_idx != l_index
    );
    let copyAgainChatbot = [...getChatbot()];
    copyAgainChatbot[index].buttons[idx].picArray = filterdImageArrray;
    console.log(
      (copyAgainChatbot[index].buttons[idx].picArray = filterdImageArrray)
    );
    setChatbot([...copyAgainChatbot]);
    // setchatbot(chatbot[index].buttons[idx].picArray = filterdImageArrray);
  };

  function addSubtitles(index, e, idx) {
    let copytemporaryChatbot = [...getChatbot()];

    copytemporaryChatbot[index].buttons[idx].subTitle = e.target.value;

    setChatbot([...copytemporaryChatbot]);
  }

  const autoUpdateData = (item, index) => {
    const newChatbot = getChatbot();
    console.log('getChatbot()', getChatbot());
    newChatbot[index] = { ...item, ...updateData };
    console.log('newChatbot', newChatbot);
    setChatbot(newChatbot);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;
    if (active.id !== over.id) {
      const oldIndex = getChatbot().findIndex(({ id }) => id === active.id);
      const newIndex = getChatbot().findIndex(({ id }) => id === over.id);
      setChatbot(arrayMove(getChatbot(), oldIndex, newIndex));
    }
  };

  return (
    <div>
      {/* Modals */}
      <AnimatedDialog
        open={isValidRowSelected}
        onClose={() => {
          deselectRow();
        }}
        fullWidth
        maxWidth="md"
      >
        <AddBubbleSection
          defaultConfig={defaultConfig}
          setDefaultConfig={setDefaultConfig}
          addNewChatBubble={addNewChatBubble}
          handleRowSelection={handleRowSelection}
          saveChatbot={saveChatbot}
          setconditionModal={setconditionModal}
          setBehaviorModal={setBehaviorModal}
          setIdx={setIdx}
          setButtonIndex={setButtonIndex}
          onSelectImage={onSelectImage}
          deleteSelectedImage={deleteSelectedImage}
          addSubtitles={addSubtitles}
          progress={progress}
          community_id={community_id}
          routes={routes}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          isValidRowSelected={isValidRowSelected}
          deselectRow={deselectRow}
          communityName={communityDetails?.[0]?.name}
        />
      </AnimatedDialog>
      <TriggersModal
        open={conditionModal}
        setOpen={setconditionModal}
        chatbot={getChatbot()}
        setChatbot={setChatbot}
        routes={routes}
        selectedRow={selectedRow}
        routeOpts={routes}
      />
      <BehaviorModal
        open={behaviorModal}
        setOpen={setBehaviorModal}
        chatbot={getChatbot()}
        setChatbot={setChatbot}
        selectedRow={selectedRow}
      />
      {/* Chatbot Editor */}
      <div className="rs">
        <div
          className={`chatbot-preview ${
            defaultConfig?.layout === 'right' && 'items-end'
          }`}
        >
          <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
            <div
              className={`chatbot-bubbles floatggerh-right ${
                defaultConfig?.layout === 'right' && 'items-end'
              }`}
            >
              <SortableContext
                items={defaultConfig?.chatbot?.map(({ id }) => id) ?? []}
              >
                {defaultConfig?.chatbot?.length > 0 &&
                  defaultConfig?.chatbot?.map((item, index) => {
                    if (!filter(item)) {
                      return <></>;
                    }
                    return (
                      <DraggableBubble
                        item={item}
                        index={index}
                        magnetUuid={magnetUuid}
                        defaultConfig={defaultConfig}
                        onUpdate={
                          !isEmpty(updateData)
                            ? () => autoUpdateData(item, index)
                            : null
                        }
                        onDelete={() => deleteBubble(index)}
                        onEdit={() => handleRowSelection(index)}
                        onTrigger={() => {
                          handleRowSelection(index);
                          setconditionModal(true);
                        }}
                        key={item.id}
                      />
                    );
                  })}
              </SortableContext>
              <button
                className=" border px-3 py-2 text-sm font-semibold hover:bg-gray-100 hover:text-gray-800 rounded-md text-gray-500 mb-2"
                style={{ maxWidth: '240px' }}
                onClick={addNewChatBubble}
              >
                <span className="ico icon-plus s15 pr-1"></span>
                <span>Add CTA Bubble</span>
              </button>
            </div>
          </DndContext>
        </div>
      </div>
    </div>
  );
}

const DraggableBubble = ({
  item,
  index,
  magnetUuid,
  defaultConfig,
  onUpdate,
  onDelete,
  onEdit,
  onTrigger,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: item.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 50 - index : 49 - index,
    cursor: isDragging ? 'grabbing' : 'grab',
  };

  return (
    <div
      className="flex items-center"
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      {/* drag handle */}
      <div
        {...listeners}
        className={defaultConfig?.layout === 'right' && 'order-2'}
      >
        <ChatbotBubble
          item={{ ...item }} // copy the item to rerender it when it changes...
          onDelete={onDelete}
          primaryColor={
            defaultConfig?.secondaryColor || defaultConfig?.primaryColor
          }
          magnet_uuid={magnetUuid}
        />
      </div>

      <div
        className={`justify-center flex flex-col gap-2 mx-2 ${
          defaultConfig?.layout === 'right' && 'order-1'
        }`}
      >
        {onUpdate && (
          <span
            className={`flex items-center gap-1 cursor-pointer transition-all hover:text-gray-500 transform hover:-translate-y-1
                          ${defaultConfig?.layout === 'right' && 'justify-end'}
                          `}
            onClick={onUpdate}
          >
            {' '}
            <MdUpdate /> Update <span className="italic"> ({index + 1}) </span>
          </span>
        )}
        <span
          className={`flex items-center gap-1 cursor-pointer transition-all hover:text-gray-500 transform hover:-translate-y-1
                          ${defaultConfig?.layout === 'right' && 'justify-end'}
                        `}
          onClick={onEdit}
        >
          {' '}
          <MdEdit /> Edit <span className="italic"> ({index + 1}) </span>
        </span>
        {!isEmpty(defaultConfig?.chatbot?.[index]?.condition) &&
        defaultConfig.chatbot[index].condition[0].type ? (
          <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className="relative"
          >
            <span
              className={`flex items-center gap-1 cursor-pointer transition-all hover:text-gray-500 transform hover:-translate-y-1 border-b-2 border-gray-300 border-dotted
                          ${defaultConfig?.layout === 'right' && 'justify-end'}
                        `}
              onClick={onTrigger}
            >
              {' '}
              <FaMagic /> Triggers{' '}
              <span className="italic">
                {' '}
                ({defaultConfig.chatbot[index].condition.length}){' '}
              </span>
            </span>
            <div
              className={`absolute top-6 rounded-xl bg-white border border-gray-300 p-2 z-50 ${
                !isHovered && 'hidden'
              }`}
            >
              {defaultConfig.chatbot[index].condition.map((trigger) => (
                <div className="whitespace-nowrap">
                  <TriggerHeadline
                    key={index}
                    trigger={trigger}
                    index={index}
                    chatbot={defaultConfig?.chatbot}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ChatbotDialog;
