import axios from 'axios';
import { saveLeadinDB } from '../utilities';
import { supabase } from '../../../services/supabase';
// import { get } from '../../utils/request';
import { get } from '../../../utils/request';

export const getPropertySupabase = async (magnetuuid) => {
  try {
    console.log('community_magnets', magnetuuid);
    let { data: Community, error } = await supabase
      .from('Community')
      .select('*')
      .eq('community_magnets', magnetuuid);
    console.log('supabase_response', Community);
    return Community?.[0];
  } catch (err) {
    console.log(err);
    console.log('getPropertyinfo.error', err);
  }
};

export const getMagnetTemplate = async (
  magnetId,
  setMagnetSettings,
  entratauser,
  seentratauser,
  entratapass,
  setentratapass,
  entratapropertyId,
  setentratapropertyId
) => {
  get(`/magnets/template?magnet_uuid=${magnetId}`, { auth: false })
    .then((data) => {
      const extractedTemplateData = data.magnet.magnet_details.template;
      if (!entratauser || !entratapass || !entratapropertyId) {
        console.log('rendere_entrata_part');
        if (data.magnet?.integration_details['api-entrata']) {
          seentratauser(
            data.magnet?.integration_details['api-entrata']?.username
          );
          setentratapass(
            data.magnet?.integration_details['api-entrata']?.password
          );
          setentratapropertyId(
            data.magnet?.integration_details['api-entrata']?.property_id
          );
        }
      }
      console.log('whole?.data', data.magnet);
      console.log(
        'extractedTemplateData?.magnetSettings',
        extractedTemplateData?.magnetSettings
      );
      setMagnetSettings(extractedTemplateData?.magnetSettings || {});
    })
    .catch((error) => {
      console.log('error@getVideoTemplate: ', error.message);
    });
};

export const getProperty = async (integrationId) => {
  try {
    // const data = await getEntrataData(integrationId);
    // console.log("data",data)
    const query = new URLSearchParams(window.location.search);
    const entratauser = query.get('entratauser');
    const entratapass = query.get('entratapass');
    const entratapropertyId = query.get('entratapropertyId');
    let entratadomain = query.get('entratadomain');
    let creds = {
      username: entratauser,
      password: entratapass,
    };
    if (entratadomain) {
      creds['entratadomain'] = entratadomain;
    }
    const res = await axios.post(
      `https://api.leasemagnets.com/integrations/entrata/getPropertyinfo`,
      {
        creds: creds,
        propertyId: entratapropertyId,
      }
    );
    console.log(res);
    return res?.data?.resp?.result?.PhysicalProperty?.Property[0];
  } catch (err) {
    console.log(err);
    console.log('getPropertyinfo.error', err);
  }
};

export const getAvailablePropertyHours = (selectedDate, propertyInfo) => {
  let allHours = [
    '12:00 AM',
    '01:00 AM',
    '02:00 AM',
    '03:00 AM',
    '04:00 AM',
    '05:00 AM',
    '06:00 AM',
    '07:00 AM',
    '08:00 AM',
    '09:00 AM',
    '10:00 AM',
    '11:00 AM',
    '12:00 PM',
    '01:00 PM',
    '02:00 PM',
    '03:00 PM',
    '04:00 PM',
    '05:00 PM',
    '06:00 PM',
    '07:00 PM',
    '08:00 PM',
    '09:00 PM',
    '10:00 PM',
    '11:00 PM',
  ];
  let allhours_new = [
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
  ];
  const weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  let theDay = '';
  if (selectedDate) {
    theDay = weekDays[new Date(selectedDate).getDay()];
    let selectedDayObj = {};
    propertyInfo?.PropertyHours?.OfficeHours?.OfficeHour?.map((item) => {
      // get office hours from entrata
      if (item.Day == theDay) {
        selectedDayObj = item;
      }
    });
    console.log('selectedDayObj_available_hours_entrata', selectedDayObj);
    let availHours = [];
    const query = new URLSearchParams(window.location.search);
    const start = query.get('start');
    const end = query.get('end');
    const saturdaystatus = query.get('saturdaystatus');
    const sundaystatus = query.get('sundaystatus');
    const saturdaystart = query.get('saturdaystart');
    const saturdayend = query.get('saturdayend');
    const sundaystart = query.get('sundaystart');
    const sundayend = query.get('sundayend');
    const hours_updated = query.get('hours_updated');
    console.log({ hours_updated });
    if (hours_updated) {
      allHours = allhours_new;
    }
    console.log({
      start,
      end,
      saturdaystart,
      saturdayend,
      sundaystart,
      sundayend,
    });

    // if we have start and end hours in params, then we overwrite with entrata open and close hour
    if (saturdaystatus == 'enabled' && new Date(selectedDate).getDay() == 6) {
      selectedDayObj['OpenTime'] = allHours[saturdaystart];
      selectedDayObj['CloseTime'] = allHours[saturdayend];
    } else if (
      sundaystatus == 'enabled' &&
      new Date(selectedDate).getDay() == 0
    ) {
      selectedDayObj['OpenTime'] = allHours[sundaystart];
      selectedDayObj['CloseTime'] = allHours[sundayend];
    } else if (start && end) {
      selectedDayObj['OpenTime'] = allHours[start];
      selectedDayObj['CloseTime'] = allHours[end];
    }
    console.log('selectedDayObj', selectedDayObj);

    const startindex = allHours.findIndex((item) => {
      return (
        `${Number(selectedDayObj?.OpenTime?.split(':')[0])}:${
          selectedDayObj?.OpenTime?.split(':')[1]
        }` === `${Number(item?.split(':')[0])}:${item?.split(':')[1]}`
      );
    });

    const endindex = allHours.findIndex((item) => {
      return (
        `${Number(item?.split(':')[0])}:${item?.split(':')[1]}` ===
        `${Number(selectedDayObj?.CloseTime?.split(':')[0])}:${
          selectedDayObj?.CloseTime?.split(':')[1]
        }`
      );
    });

    allHours.map((item, index) => {
      if (index >= startindex && index <= endindex) {
        availHours.push(item);
      }
    });
    return availHours;
  }
};

export const bookAppointment = async (
  uuid,
  obj,
  userInfo,
  tourType,
  onError
) => {
  try {
    console.log('obj**', obj);
    const query = new URLSearchParams(window.location.search);
    const entratauser = query.get('entratauser');
    ///const uuid = query.get('uuid');
    const entratapass = query.get('entratapass');
    const entratapropertyId = query.get('entratapropertyId');
    let data = {};
    if (entratauser && entratapass && entratapropertyId && uuid) {
      data = {
        username: entratauser,
        password: entratapass,
        property_id: entratapropertyId,
      };
    } else {
      //data = await getEntrataData(integrationId);
    }
    let form_data = {
      source: 'cta_tour_mini_app',
      lead_name: `${userInfo.firstname} ${userInfo.lastname}`,
      lead_phone: `+1${userInfo.phone}`,
      lead_email: userInfo.email,
      first_name: userInfo.firstname,
      last_name: userInfo.lastname,
      email: userInfo.email,
      phone: `+1${userInfo.phone}`,
      ...obj,
    };
    console.log('bookAppointment_obj', form_data);
    saveLeadinDB(uuid, {
      form_data,
      form_route: null,
      video_time: 0,
    });
    return true;
  } catch (err) {
    console.log(err);
  }
};
