import React from 'react';
import h1 from '../../assets/h1.png';
import n1 from '../../assets/n1.png';
const NewHero = () => {
  return (
    <div className=" grid grid-cols-1 lg:grid-cols-2 w-full mt-20 pb-5">
      <div className=" flex w-full flex-col items-center justify-center h-96  gap-3">
        <div className=" relative w-72">
          <img src={n1} className=" absolute top-0 left-44 z-10" alt="" />
          <h1 className=" text-4xl text-gray-700 font-bold">Unlimited</h1>
          <h1 className=" text-4xl text-gray-700 font-bold">Video Editing</h1>
          <p className=" text-gray-700 text-sm py-2">
            Get your videos edited professionally for a flat monthly rate. Take
            your business to the next level.
          </p>
        </div>
        <div className=" flex items-center gap-2 -ml-3">
          <button className=" px-4 text-sm hover:bg-nhvr bg-nvr font-medium py-2 rounded-md text-white">
            View Pricing
          </button>
          <button
            style={{ background: '#B9D5C2' }}
            className=" px-4 text-sm font-medium py-2 rounded-md "
          >
            See Our Work
          </button>
        </div>
      </div>
      <div>
        <img src={h1} alt="" />
      </div>
    </div>
  );
};

export default NewHero;
