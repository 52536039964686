import React, { useState } from 'react';
import './animations.css';
import './common.css';
import { ArrowIcon, LinkIcon2 } from './Icons';
import './NavigationLinks.css';

// Settings for nav link wrapping. Units in %
const NUM_COLS = 2;
const GAP = 2;
const FLEX_BASIS = Math.floor((100 - (NUM_COLS - 1) * GAP) / NUM_COLS);
const FLEX_GROW = true; // Requires >= 2 columns to see the difference

const NavigationLinks = ({
  history,
  links,
  onClickLink,
  title,
  fadeDirection,
  isIframe = false,
  usesAltNavLinks,
  setAltNavLinks,
}) => {
  // 2 animations are occuring here:
  // * Fading in/out of the entire nav links container on VideoMagnet open/close (with fadeDirection)
  // * Collapsing nav links to only show 1 using the buttonCollapsed/Collapsing states
  const collapsible = links?.length > 0;
  const [buttonsCollapsed, setButtonsCollapsed] = useState(false);
  const [buttonsCollapsing, setButtonsCollapsing] = useState(false);

  const collapseButtons = () => {
    if (!buttonsCollapsed) {
      setButtonsCollapsing(true);
      // Wait for collapse animation to finish
      setTimeout(() => {
        setButtonsCollapsing(false);
        setButtonsCollapsed(true);
      }, 500);
    }
  };

  // const linkClickHandler = (e, link) => {
  //   e.stopPropagation();

  //   onClickLink(link);
  //   console.log('Link', link);
  // };

  return (
    // The presence of navigation-links-collapsed/open signals to the video shade
    // to change the background gradient size
    <div
      style={{
        marginBottom: 8,
        zIndex: 3,
      }}
      className={`navigation-links-container${
        isIframe ? '-iframe' : ''
      } fade-${fadeDirection} navigation-links-${
        buttonsCollapsed || buttonsCollapsing ? 'collapsed' : 'open'
      }`}
    >
      {title ? (
        <div
          // className={`collapse-button title `}
          style={{
            color: 'white',
            marginRight: 'auto',
            marginBottom: 0,
            marginTop: 20,
          }}
          // ${buttonsCollapsed ? '' : 'fade-in-up'} ${buttonsCollapsing ? 'fade-out-down' : ''}
          // onClick={(e) => {
          //     e.stopPropagation();
          //     if (collapsible) {
          //         if (!buttonsCollapsed) {
          //             collapseButtons();
          //         } else {
          //             setButtonsCollapsed(false);
          //         }
          //     }
          // }}
        >
          {/* {collapsible && (
          <ArrowIcon
            style={{
              transform:
                buttonsCollapsed || buttonsCollapsing ? 'rotate(180deg)' : '',
            }}
          />
        )} */}
          {/* <svg style={
          {
            width: 20,
            height: 20
          }
        } xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5" />
        </svg> */}

          <span
            style={{
              marginRight: 10,
              fontSize: '20px',
            }}
          >
            👋
          </span>

          <span
            style={{
              fontWeight: 400,
              fontSize: 'large',
            }}
          >
            {title}
          </span>
        </div>
      ) : null}

      {title !== false && links?.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'between',
            alignItems: 'center',
            width: '100%',
            marginTop: 10,
            marginBottom: 15,
          }}
        >
          <div
            style={{
              color: 'white',
              fontSize: 'small',
              marginRight: 'auto',
              cursor: 'pointer',
              opacity: 0.7,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            onClick={() => {
              setAltNavLinks((prev) => {
                if (prev === 1) {
                  return 2;
                }
                if (prev === 2) {
                  return 1;
                }
              });
            }}
          >
            <ArrowIcon
              style={{
                transform: usesAltNavLinks === 2 ? 'rotate(180deg)' : '',
                marginRight: 10,
                width: '15px',
                height: '15px',
                fill: 'white',
                color: 'white',
              }}
            />
            <p>Where to next?</p>
          </div>

          {/* <p className="location-chip" style={{

                    fontSize: "small",
                }}>{location}</p> */}
        </div>
      )}

      <div
        // className={`nav-inside-container ${buttonsCollapsing ? 'without-scrollbar' : 'with-scrollbar'
        //   }`}
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          width: '100%',
          gap: 10,
          height: 'min-content',
        }}
      >
        {(buttonsCollapsed ? [] : links).map((link, i) => (
          <button
            type="button"
            key={link.name + link.route}
            className={`navigation-link ${
              buttonsCollapsed ? '' : 'fade-in-up'
            } ${buttonsCollapsing ? 'fade-out-down' : ''}`}
            style={{
              gridColumn:
                i === links.length - 1 && links.length % 2 === 1 ? '1/-1' : '',
            }}
            onClick={(e) => {
              e.stopPropagation();
              onClickLink(link);
            }}
          >
            {link?.href || link?.href_direct ? (
              <span className="letter">
                <LinkIcon2 />
              </span>
            ) : (
              <span className="letter">
                {String.fromCharCode((i % 26) + 65)}
              </span>
            )}
            <span className="content">{link.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default NavigationLinks;
