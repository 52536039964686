import React, { useState } from 'react';
import './AltNavigationLinks.css';
import './animations.css';
import './common.css';
import { ArrowIcon } from './Icons';

// Settings for nav link wrapping. Units in %
const NUM_COLS = 2;
const GAP = 2;
const FLEX_BASIS = Math.floor((100 - (NUM_COLS - 1) * GAP) / NUM_COLS);
const FLEX_GROW = true; // Requires >= 2 columns to see the difference

const AltNavigationLinks = ({
  links,
  onClickLink,
  title,
  fadeDirection,
  isIframe = false,
  location,
  usesAltNavLinks,
  setAltNavLinks,
}) => {
  const IS_DESKTOP = !window.matchMedia('only screen and (max-width: 600px)')
    .matches;
  // 2 animations are occuring here:
  // * Fading in/out of the entire nav links container on VideoMagnet open/close (with fadeDirection)
  // * Collapsing nav links to only show 1 using the buttonCollapsed/Collapsing states
  const collapsible = links?.length > 0;
  const [buttonsCollapsed, setButtonsCollapsed] = useState(false);
  const [buttonsCollapsing, setButtonsCollapsing] = useState(false);

  const collapseButtons = () => {
    if (!buttonsCollapsed) {
      setButtonsCollapsing(true);
      // Wait for collapse animation to finish
      setTimeout(() => {
        setButtonsCollapsing(false);
        setButtonsCollapsed(true);
      }, 500);
    }
  };

  // const linkClickHandler = (e, link) => {
  //   e.stopPropagation();

  //   onClickLink(link);
  //   console.log('Link', link);
  // };

  return (
    // The presence of navigation-links-collapsed/open signals to the video shade
    // to change the background gradient size
    <div
      className={`navigation-links-container${
        isIframe ? '-iframe' : ''
      }  navigation-links-${
        buttonsCollapsed || buttonsCollapsing ? 'collapsed' : 'open'
      }`}
      style={{
        marginBottom: 'auto',
        maringTop: 'auto',
      }}
      // fade-${fadeDirection}
    >
      <div
        className={`nav-inside-container ${
          buttonsCollapsing ? 'without-scrollbar' : 'with-scrollbar'
        }`}
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          width: '100%',
          gap: 10,
        }}
      >
        {(buttonsCollapsed ? [] : links).map((link, i) => (
          <button
            type="button"
            key={link.name + link.route}
            className="navigation-link-alt"
            //  ${buttonsCollapsed ? '' : 'fade-in-up'} ${buttonsCollapsing ? 'fade-out-down' : ''}
            style={{
              borderColor: '#cbd5e1',
              height: '100%',
              gridColumn:
                i === links.length - 1 && links.length % 2 === 1 ? '1/-1' : '',
            }}
            onClick={(e) => {
              e.stopPropagation();
              onClickLink(link);
            }}
          >
            <span
              style={{
                textAlign: 'center',
              }}
              className="content"
            >
              {link.name}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default AltNavigationLinks;
