// deterministically generate 2 word name based on uuid
// takes in a string that must consist of letters or numbers in the first 2 indicies, and uses these indices to return a 2 word (adjective and noun) username

const adjectives = [
  'Lucky',
  'Mighty',
  'Noble',
  'Royal',
  'Adventurous',
  'Brilliant',
  'Creative',
  'Delightful',
  'Energetic',
  'Friendly',
  'Generous',
  'Hopeful',
  'Idealistic',
  'Joyful',
  'Kind',
  'Optimistic',
  'Polite',
  'Quirky',
  'Clever',
  'Thoughtful',
  'Useful',
  'Vibrant',
  'Wonderful',
  'Curious',
  'Youthful',
  'Zesty',
  'Astute',
  'Ambitious',
  'Charming',
  'Loyal',
  'Wise',
  'Valiant',
  'Sussy', // this is the 33rd adjective and will therefore never be used :(
];

const nouns = [
  'Blobfish',
  'Ant',
  'Baboon',
  'Cougar',
  'Dolphin',
  'Elephant',
  'Fox',
  'Giraffe',
  'Horse',
  'Iguana',
  'Jaguar',
  'Kangaroo',
  'Lion',
  'Squirrel',
  'Crab',
  'Owl',
  'Penguin',
  'Quail',
  'Racoon',
  'Swan',
  'Tiger',
  'Wolf',
  'Whale',
  'Yak',
  'Zebra',
  'Jellyfish',
  'Gazelle',
  'Narwhal',
  'Dragon',
  'Panda',
  'Tortoise',
  'Unicorn',
  'Baka', // this is the 33rd noun and will therefore never be used :(
];

export default function generateUsername(seed) {
  console.log("seed",seed)
  if(!seed) return
  seed = seed?.toUpperCase()?.slice(0, 4);
  const indices = [0, 0];
  // normalize to number 0-31
  for (const index of [0, 1, 2, 3]) {
    indices[Math.floor(index / 2)] += parseInt(seed[index], 16);
  }

  return `${adjectives?.[indices?.[0]] ?? 'Code'} ${nouns?.[indices?.[1]] ?? 'Monkey'}`;
}
