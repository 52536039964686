import { BsArrowLeftShort } from 'react-icons/bs';

const WidgetWrapper = ({ children, onBack, step, showGoBack }) => {
  return (
    <>
      <div className="booking-scheduler">
        <div className={'scheduler-container w-full lg:w-2/5  flex flex-col'}>
          <div className="container-hdr">
            {showGoBack === true ? (
              <div className="left">
                {onBack && (
                  <button
                    className="back-btn flex items-center ml-2"
                    onClick={onBack}
                  >
                    <BsArrowLeftShort size={28} /> <span>Go Back</span>
                  </button>
                )}
              </div>
            ) : (
              <div className="left">
                {onBack && step !== 1 && (
                  <button
                    className="back-btn flex items-center ml-2"
                    onClick={onBack}
                  >
                    <BsArrowLeftShort size={28} /> <span>Go Back</span>
                  </button>
                )}
              </div>
            )}
            <div className="right">
              {/* <button className=" flex items-center justify-center bg-gray-100 px-2  rounded-full text-2xl">
                &times;
              </button> */}
            </div>
          </div>
          <div className="scheduler-content">{children}</div>
        </div>
      </div>
    </>
  );
};

export default WidgetWrapper;
