import SubjectIcon from '@material-ui/icons/Subject';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CodeIcon from '@material-ui/icons/Code';
import {
  IconButton,
  Tab,
  Tabs,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { v4 } from 'uuid';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import ConditionRow from '../talk-and-triggers/ConditionRow';
import { ActionRow } from './ActionRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DeleteForever } from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export default function LeadGoalConfiguration({
  routeOpts,
  chatbot,
  defaultConfig,
  setDefaultConfig,
}) {
  const [goalIndex, setGoalIndex] = useState(0);
  const goals = defaultConfig?.goals || [];
  const setGoals = (newGoals) =>
    setDefaultConfig((prev) => {
      const newDefaultConfig = { ...prev };
      if (typeof newGoals === 'function') {
        newDefaultConfig.goals = newGoals(prev.goals);
      } else {
        newDefaultConfig.goals = newGoals;
      }
      return newDefaultConfig;
    });

  const handleTabChange = (_, newTabIndex) => {
    if (newTabIndex > goals?.length) {
      console.error('newTab greater than goals.length', newTabIndex, goals);
      return;
    }
    if (newTabIndex === goals?.length) {
      addNewGoal();
    }
    console.log('newTabIndex', newTabIndex);
    setGoalIndex(newTabIndex);
  };

  const addNewGoal = () => {
    setGoals([
      ...goals,
      {
        name: 'Untitled Goal',
        id: v4(),
        actions: [],
        conditions: [],
      },
    ]);
  };

  const removeGoal = (index) => {
    setGoals(goals.filter((_, ind) => ind !== index));
    if (goalIndex >= index) {
      setGoalIndex(goalIndex - 1);
    }
  };

  const handleNameChange = (newValue) => {
    const newGoals = [...goals];
    newGoals[goalIndex]['name'] = newValue;
    setGoals(newGoals);
  };

  const addAction = () => {
    const newGoals = [...goals];
    newGoals[goalIndex].actions = [
      ...goals[goalIndex].actions,
      {
        type: '',
        target: '',
        id: v4(),
      },
    ];
    setGoals(newGoals);
  };

  const setAction = (index, newValue) => {
    const newGoals = [...goals];
    newGoals[goalIndex].actions[index] = newValue;
    setGoals(newGoals);
  };

  const removeAction = (index) => {
    const newGoals = [...goals];
    newGoals[goalIndex].actions = goals[goalIndex].actions.filter(
      (_, ind) => index !== ind
    );
    setGoals(newGoals);
  };

  const addCondition = () => {
    const newGoals = [...goals];
    newGoals[goalIndex].conditions = [
      ...goals[goalIndex]?.conditions,
      {
        type: '',
        route: '',
        operator: '',
        value: [],
        id: v4(),
      },
    ];
    setGoals(newGoals);
  };

  const setCondition = (index, newValue) => {
    setGoals((prevGoals) => {
      const newGoals = [...prevGoals];
      newGoals[goalIndex].conditions[index] =
        typeof newValue === 'function'
          ? newValue(prevGoals[goalIndex].conditions[index])
          : newValue;
      return newGoals;
    });
  };

  const removeCondition = (index) => {
    const newGoals = [...goals];
    newGoals[goalIndex].conditions = goals[goalIndex].conditions.filter(
      (_, ind) => index !== ind
    );
    setGoals(newGoals);
  };

  return (
    <>
      <p className="p-2 mt-2">
        Choose what conditions leads need to meet to notify your team and how
        you'll be notified:
      </p>
      <div className="flex items-center">
        <IconButton
          className="flex-none"
          onClick={() => setGoalIndex(goalIndex - 1)}
          disabled={goalIndex === 0}
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          className="flex-none"
          onClick={() => {
            console.log('goalIndex', goalIndex);
            console.log('goals?.length', goals?.length);
            if (goalIndex >= (goals?.length || 1) - 1) {
              addNewGoal();
            }
            setGoalIndex(goals?.length ? goalIndex + 1 : 0);
          }}
        >
          {goalIndex >= (goals?.length || 1) - 1 ? (
            <AddIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
        <Tabs value={goalIndex} onChange={handleTabChange}>
          {goals?.map(({ name }) => (
            <Tab label={name} />
          ))}
          <Tab icon={<AddIcon />} iconPosition="start" label="Add Goal" />
        </Tabs>
      </div>
      {isEmpty(goals) ? (
        <>No goals yet. Add one.</>
      ) : (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className="flex items-center gap-2">
                <SubjectIcon />
                <h1 className="font-bold text-lg">Config</h1>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <DebouncedTextField
                value={goals[goalIndex]?.name}
                onChange={handleNameChange}
                variant="outlined"
                label="Name"
              />
              <Tooltip title="Delete Goal">
                <IconButton
                  onClick={() => removeGoal(goalIndex)}
                  className="hover:text-red-500 duration-200 ease-in-out flex-none"
                >
                  <DeleteForever />
                </IconButton>
              </Tooltip>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className="flex items-center gap-2">
                <CodeIcon />
                <h1 className="font-bold text-lg">
                  Triggers ({goals[goalIndex]?.conditions?.length ?? 0})
                </h1>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex flex-col gap-4 p-2 border rounded-md border-dashed w-full">
                {goals[goalIndex]?.conditions?.map((item, ind) => {
                  return (
                    <ConditionRow
                      key={item.id}
                      condition={item}
                      setCondition={(newValue) => setCondition(ind, newValue)}
                      removeCondition={() => removeCondition(ind)}
                      routeOpts={routeOpts}
                      chatbot={chatbot}
                      selectedRow={-1}
                    />
                  );
                }) ?? 'No triggers'}
                <Button onClick={addCondition} startIcon={<AddIcon />}>
                  Add New
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className="flex items-center gap-2">
                <NotificationsIcon />
                <h1 className="font-bold text-lg">
                  Actions ({goals[goalIndex]?.actions?.length ?? 0})
                </h1>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex flex-col w-full">
                {goals[goalIndex]?.actions?.map((item, ind) => {
                  return (
                    <>
                      <ActionRow
                        key={item.id}
                        action={item}
                        setAction={(newValue) => setAction(ind, newValue)}
                        removeAction={() => removeAction(ind)}
                      />
                      <div className="w-1/2 h-6 border-r-2" />
                    </>
                  );
                }) ?? 'No actions'}
                <Button
                  className="border rounded-xl p-4 flex gap-2 self-center"
                  onClick={addAction}
                  startIcon={<AddIcon />}
                >
                  Add New
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </>
  );
}
