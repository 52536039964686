import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import {
  listCommunities,
  listCommunityDetial,
} from './reducers/communitiesReducers';
import generalReducers from './reducers/generalReducers';
import { getMagnet, magnetStates } from './reducers/magnetReducers';
import {
  getAllReferralsReducer,
  getReferralUsersReducer,
} from './reducers/referralReducers';
import {
  communityAllUserReviewsReducer,
  communityUserByIDReducer,
  communityUserReviewReducer,
  communityUsersReducer,
  createRequestHistoryReducer,
  createReviewCommunityReducer,
  getCommunityReviewsReducer,
  getPendingReviewReducer,
  getRequestHistoryReducer,
  getReviewCommunityReducer,
} from './reducers/reviewsReducers';

const reducer = combineReducers({
  listCommunities,
  listCommunityDetial,
  generalReducers,
  getMagnet,
  magnetStates,
  getReviewCommunityReducer,
  createReviewCommunityReducer,
  communityUsersReducer,
  getCommunityReviewsReducer,
  communityUserReviewReducer,
  communityUserByIDReducer,
  createRequestHistoryReducer,
  getRequestHistoryReducer,
  getPendingReviewReducer,
  communityAllUserReviewsReducer,
  getAllReferralsReducer,
  getReferralUsersReducer,
});

let initialState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
