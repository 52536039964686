import React from 'react';
import Stories from 'react-insta-stories';
// import './share-video.css';

export default function InstaTour({ customHomepage = '/tour' }) {
  // Get query parameters
  const query = new URLSearchParams(window.location.search);
  // Get name query
  const token = query.get('name');
  // Magnet id
  const magnet_id = query.get('magnet_uuid');
  const intro_video = query.get('intro_video');

  /*
    QUERY FORMAT:
    /tour?magnet_uuid=9b9d1e9e-b3ad-4815-bef3-726cb2f8a945&share=intro.main,floor_plans.main&name=Amulya
    magnet uuid -> share -> name
  */

  // Template params
  // const videos = query.get('share').split(',');
  // const routes = route.split('.');
  // const video_template = template.categories[routes[0]]['screens'][routes[1]];

  // Video template
  // const [template, setTemplate] = useState();

  // // Get video template from backend via GET request
  // function getVideoTemplate() {
  //   get(`/magnets/template?magnet_uuid=${magnet_id}`, { auth: false })
  //     .then((data) => {
  //       setTemplate(data.magnet.magnet_details.template);
  //     })
  //     .catch((error) => {
  //       console.log(error.message);
  //     });
  // }

  // useEffect(() => {
  //   getVideoTemplate();
  // }, []);

  // // Apartment info
  // const [apt, setApt] = useState();

  // // Get all of the apartment's info for navbar
  // function getCommunityDetails() {
  //   get(`/magnets/template?magnet_uuid=${magnet_id}`, { auth: false })
  //     .then((data) => {
  //       setApt(data.magnet.magnet_details.template.magnetSettings);
  //       startVisit(data.magnet.magnet_details.template);
  //     })
  //     .catch((error) => {
  //       console.log(error.message);
  //     });
  // }

  // useEffect(() => {
  //   document.title = 'Tour built just for you | Tour.video';
  //   getCommunityDetails();
  //   startTour(token);
  // }, []);

  return (
    <div>
      {/*(apt) &&
        <Headline
          site={apt.url}
          phone={"(248) 123-4567"}
          headline={"LEASING NOW!"}
        />
      */}
      <Stories
        stories={[
          {
            type: 'video',
            header: {
              profileImage: 'https://ui-avatars.com/api/?name=Amulya%20Parmar',
              heading:
                'Amulya Parmar - Tell us a bit about yourself? Why did you get into video? How are you long you are doing?',
              subheading: 'Videographer Pro, Video Editor',
            },
            url: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/109/magnet/bf2b9180-e688-4b0c-8202-f7c428e9d137/stories_Pinocchio_AI/Y2Mate_is_-_Tavtech_Hackathon_-_Pinocchio_AI_-_True_News-DF2ghSM_jNM-720p-1647657705633_mp4_1.mp4',
          },
          {
            type: 'video',
            header: {
              profileImage: 'https://ui-avatars.com/api/?name=Amulya%20Parmar',
              heading:
                'Amulya Parmar - Tell us a bit about yourself? Why did you get into video? How are you long you are doing?',
              subheading: 'Videographer Pro, Video Editor',
            },
            url: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/31/magnet/0d8aa956-2d8d-4254-b018-15c1672c5d9a/amenities_Main_Page/video.mp4',
          },
        ]}
        defaultInterval={1500}
        width={'100%'}
        height={'100%'}
      />
    </div>
  );
}
