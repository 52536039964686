import React from 'react';
import { HiArrowRight } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import ss from '../assets/home/ss.png';
import tt from '../assets/home/tt.png';

const GlobNavbar = () => {
  const openScheduleTour = () => {
    window.tempLeaseMagnets({
      uuid: '0d8aa956-2d8d-4254-b018-15c1672c5d9a',
      startScreen: 'thank_you.customer_support',
      startOpen: true,
    });
  };

  return (
    <>
      <div className=" w-full bg-white shadow-2xl h-full flex py-4 gap-10">
        <div className=" lg:pl-20">
          <h6 className=" font_cwb text-sm font-semibold ">INDUSTRIES</h6>
          <h6 className=" font_cwb text-sm text-black mt-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
            <Link to="/industry/real-estate"> Real Estate</Link>
          </h6>
          <h6 className=" font_cwb text-sm text-black mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
            <Link to="/industry/university"> Universities</Link>
          </h6>
          <h6 className=" font_cwb text-sm text-black mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
            <Link to="/industry/healthcare"> Healthcare</Link>
          </h6>

          <h6 className=" font_cwb text-sm font-semibold mt-3  ">LEARN</h6>
          <h6 className=" font_cwb text-sm text-black mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
            <Link to="/learn"> Tourversity</Link>
          </h6>

          {/* <h6 className=" font_cwb text-sm text-black mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
            <Link to="/"> Senior Living</Link>
          </h6>
          <h6 className=" font_cwb text-sm text-black mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
            <Link to="/"> Conferences</Link>
          </h6>
          <h6 className=" font_cwb text-sm text-black mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
            <Link to="/"> Accelerators</Link>
          </h6> */}
        </div>

        {/* <div className=" lg:pl-20">
          <h6 className=" font_cwb text-sm font-semibold ">LEARN</h6>
          <h6 className=" font_cwb text-sm text-black mt-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
            <Link to="/"> Welcome Videos</Link>
          </h6>
          <h6 className=" font_cwb text-sm text-black mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
            <Link to="/"> Tour Videos</Link>
          </h6>
          <h6 className=" font_cwb text-sm text-black mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
            <Link to="/"> Analytics</Link>
          </h6>
          <h6 className=" font_cwb text-sm text-black mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
            <Link to="/"> Retargeting</Link>
          </h6>
          <h6 className=" font_cwb text-sm text-black mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
            <Link to="/"> CTA’s and Triggers</Link>
          </h6>
          <h6 className=" font_cwb text-sm text-black mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
            <Link to="/"> Integrations</Link>
          </h6>
        </div> */}
        <div className=" lg:pl-20">
          <h6 className=" font_cwb text-sm font-semibold ">USE CASES</h6>
          <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
            <img src={tt} className=" w-7 h-7 object-cover" alt="" />
            <div
              onClick={() => {
                window.customLmChat([
                  {
                    type: 'Basic',
                    text: '',
                    condition: [[]],
                    buttons: [
                      {
                        label: '250 off 2 bedroom',
                        route: '_promotions_.latest',
                      },
                    ],
                  },
                ]);
              }}
            >
              <h6 className=" font_cwb text-sm text-black">Tour & Triggers </h6>
              <p className="text-xs text-gray-400">
                Make it easy to build an embeddable & shareable tour
              </p>
            </div>
          </div>
          <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
            <img
              src="https://www.intercom.com/_next/static/images/solutions--engagement-bc48a36991ab0b915c148bd61c6c1d06.svg"
              className=" w-7 h-7 object-cover"
              alt=""
            />
            <div>
              <h6
                className=" font_cwb text-sm text-black"
                onClick={() => {
                  window.tempLeaseMagnets({
                    uuid: '7c54522f-2ee7-451d-a2c6-56edee14c1d9',
                    startScreen: 'amenities.testimonial',
                    startOpen: true,
                  });
                }}
              >
                Trust & Testimonials
              </h6>
              <p className="text-xs text-gray-400">
                Build more trust through testimonials
              </p>
            </div>
          </div>
          <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
            <img
              src="https://www.intercom.com/_next/static/images/solutions--marketing-0fc6ec0aeab50485489ad1f6da1a1e5d.svg"
              className=" w-7 h-7 object-cover"
              alt=""
            />
            <div>
              <h6 className=" font_cwb text-sm text-black">
                Targeting & Retargeting
              </h6>
              <p className="text-xs text-gray-400">
                Create targeted specials and online campaigns
              </p>
            </div>
          </div>
        </div>
        <div className=" lg:pl-20">
          <Link to="#" onClick={() => window.toggleLeaseMagnetsOpen(true)}>
            <h6 className=" font_cwb text-sm font-semibold ">WATCH A DEMO</h6>
            <div className=" mt-3">
              <img src={ss} className=" h-20 object-cover " alt="" />
            </div>
            <p className=" text-gray-400 text-sm py-2">
              Learn from a professional what makes <br />
              Tour work behind the scenes
            </p>
          </Link>
          <h6 className=" font_cwb text-sm text-black mt-2 transform font-medium flex items-center gap-24 transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
            <Link to="#" onClick={() => openScheduleTour()}>
              {' '}
              BOOK A DEMO
            </Link>
            <HiArrowRight className=" w-5 h-5" />
          </h6>
        </div>
      </div>
    </>
  );
};

export default GlobNavbar;
