import React from 'react';

function FormIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="22"
      fill="none"
      viewBox="0 0 17 22"
    >
      <path
        fill="#3898EC"
        d="M1.946 17.46V2.664H1.05c-.59 0-1.05.46-1.05 1.05v16.36c0 .588.46 1.049 1.05 1.049h12.212c.589 0 1.05-.46 1.05-1.05v-.845h-10.6a1.77 1.77 0 01-1.766-1.766zM13.364 4.48H17L12.52 0V3.636c0 .46.384.844.844.844z"
      ></path>
      <path
        fill="#3898EC"
        d="M11.803 3.636V.026h-8.09c-.59 0-1.05.46-1.05 1.05V17.46c0 .589.46 1.05 1.05 1.05h12.212c.588 0 1.05-.461 1.05-1.05V5.197h-3.61c-.871 0-1.562-.691-1.562-1.561zM6.477 5.12H9.73a.35.35 0 01.358.358.35.35 0 01-.358.358H6.477a.35.35 0 01-.358-.358c-.026-.205.154-.358.358-.358zm6.734 10.113H6.477a.35.35 0 01-.358-.359.35.35 0 01.358-.358h6.734a.35.35 0 01.358.358c0 .18-.179.358-.358.358zm0-3.124H6.477a.35.35 0 01-.358-.358.35.35 0 01.358-.359h6.734a.35.35 0 01.358.358c0 .205-.179.359-.358.359zm.358-3.508a.35.35 0 01-.358.359H6.477a.35.35 0 01-.358-.359.35.35 0 01.358-.358h6.734c.179 0 .358.154.358.358z"
      ></path>
    </svg>
  );
}

export default FormIcon;
