import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { supabase } from '../../services/supabase';
import { toast } from 'react-toastify';

const Corpus = ({ community_id, magnetUuid }) => {
  const aiCorpusSample = `The One is Ann Arbor's only off-campus student living that offers houses and townhomes with porches, well-manicured yards and resident + guest parking in front of your residence. Living at this pet-friendly community means you have the privacy of no neighbors above or below you and a yard for your furry friend. The One features over 12,000 square-feet of customized amenities and includes one- to five-bedroom houses and townhomes, each bedroom with its own bathroom with an additional 1/2 guest bath in select floor plans. The property is a short private shuttle ride to shops, restaurants, entertainment or campus, complete with tracking to get to you and from with ease from 7 AM to 10 PM Monday to Friday, Saturday 10 AM - 2 PM, perfect for early risers or late night study sessions, as well as, on Saturdays we drop you directly and pick you up directly from the grocery store.

Our 24x7 access gym as well as regular friends who meet in our ping pong table, pool table or clubhouse, we are confident you will find a home away from home.

We lease by the bedroom and currently have the following selection of homes available. Here is our availability for the August 12, 2023 to July 28, 2024 term:
1 Bedroom Townhomes (C1): Approximately $1499 | Available
2 Bedroom Townhomes (D1): Approximately $1400 | Available
3 Bedroom Townhomes (D1): Approximately $1201 | Unavailable
4 Bedroom Townhomes (D1): Approximately $1140 | Available

We have a $60 dollar pet policy, per pet.
Our typical electricity/utility prices for rent are approximately $100/mo.

You can match roommates within a townhome, but we do not allow for double occupancy in a room.

Our best current special is $750 gift card for anyone who leases before March 21st.

Resident parking is about $85 per month.

We primarily offer only 12 month lease terms. However, if you are potentially looking to move-in sooner, we sometimes can sign for immediate move-ins till the end of the term. I recommend scheduling a call back to have a team member talk about the specifics for you.

Amenities include:
- Houses + townhomes living
- Patios and balconies available in select floor plans
- Pet-friendly
- Spacious, neighborhood living
- Open green space
- Ample resident parking
- Resort-style pool with sundeck + hot tub
- Outdoor basketball and sand volleyball courts
- Outdoor grilling stations + fire pit
- Private shuttles to campus and grocery w/ late night runs
- 24 hour indoor fitness center
- Yoga and spin area featuring virtual fitness classes
- Walking and running trails
- 4 private study lounges
- Computer lounge w/ Macs and PCs + free printing
- 24 hour clubhouse with gaming and social areas
- Free tanning
- Free coffee bar
- Bike storage
- Market's only FitLife health + wellness program
- Community Rewards loyalty program
- On-site courtesy patrol
- 24 hour emergency maintenance
- Roommate matching available
- U Life Resident Life Program

1-5 Bedroom Student Apartments Amenities include:
- 1 - 5 Private bedroom suites with bathrooms
- Free high-speed Wi-Fi
- Free water, sewage and trash included
- Additional 1/2 bath in 2 - 5 bedrooms
- 50" Flat screen TV in living room
- Fully furnished with contemporary finishes
- Granite countertops + stainless steel appliances
- Hardwood-style floors
- Secureelectronic access
- Spacious closets
- Full size washer and dryer
- Patios and balconies in select units

We don't have an elevator because all our units are townhomes, and several of our units are available at the ground level of the Townhome and ADA compliant.

Your address is: 2327 Erie Dr, Ann Arbor Charter Twp, MI 48105

Some great nearby restaurants are: No Thai, Joe's Pizza, Buffalo Wild Wings. We are right next door to the bus stop, so we are literally a couple minutes to North Campus, the nearby Kroger, about 8 minutes away from Central Campus.`;

  const [corpusText, setCorpusText] = useState('');
  const getCorpusAi = async () => {
    const { data, error } = await supabase
      .from('Community')
      .select('ai')
      .eq('id', community_id)
      .limit(1);

    if (error) {
      console.error('Error fetching from supabase for roomIds', error);
      return;
    }
    if (!data.length) {
      console.error(`Community with id ${community_id} not found in supabase`);
      return;
    }

    // console.log("corpus", data)
    return data?.[0]?.ai;
  };
  const getCorpusData = async () => {
    const aiObject = await getCorpusAi();
    setCorpusText(aiObject?.corpus);
    console.log('aiObject', aiObject);
  };
  useEffect(() => {
    getCorpusData();
  }, []);

  const handleDataSubmit = async () => {
    try {
      const data = {
        ai: {
          corpus: corpusText,
        },
      };

      const { data: savedData, error } = await supabase
        .from('Community')
        .update(data)
        .eq('id', community_id);

      if (error) {
        toast.error(` Error deleting Corpus: ${error}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      } else {
        toast.success('Corpus Saved Successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (error) {
      toast.error(` Error deleting Corpus: ${error}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  return (
    <div>
      <Accordion defaultExpanded={true} className="my-4">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="flex flex-col">
            <p className="text-2xl font-bold">AI FAQ Corpus</p>
            <p className="text-base text-gray-600">
              Build your AI Data model for corpus and create a shareable chatbot
              that can answer questions about your property
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <textarea
            value={corpusText}
            onChange={(e) => setCorpusText(e.target.value)}
            className=" bg-gray-50 border rounded-xl p-4 w-full"
            name=""
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </AccordionDetails>
      </Accordion>
      <div className=" flex items-center gap-4">
        <button
          onClick={() => handleDataSubmit()}
          className="bg-pr px-3 py-2 text-white rounded-md"
        >
          Save
        </button>
        <button
          onClick={() => setCorpusText(aiCorpusSample)}
          className="bg-pr px-3 py-2 text-white rounded-md"
        >
          Generate Sample
        </button>
        <button
          onClick={() => {
            window.open(
              `${window.location.origin}/cta/chatbot/integration/${community_id}`,
              '_blank'
            );
          }}
          className="bg-pr px-3 py-2 text-white rounded-md"
        >
          Preview
        </button>
      </div>
    </div>
  );
};

export default Corpus;
