import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import { Button, ButtonGroup, Dialog } from '@material-ui/core';
import WidgetColorPicker from './WidgetColorPicker';
import RouteInput from '../../components/Reusable/RouteInput';
import PreviewVideoMagnet from '../manage-magnets/PreviewVideoMagnet';
import { useSelector } from 'react-redux';
import TourWidgetPreview from './TourWidgetPreview';

const DEFAULT_PRIMARY_COLOR = '#224493';

const TourBubbleConfig = ({
  routeOpts,
  magnetUuid,
  videoStartChoice,
  setLayoutDirection,
  PreviewWidgetEmbed,
  setDefaultConfig,
  defaultConfig,
}) => {
  // start screen route state
  const [tourPopupScreenRoute, setTourPopupScreenRoute] = useState([]);

  const handleClose = () => {
    setTourPopupScreenRoute(null);
  };

  const { magnetId } = useSelector((state) => state.magnetStates);
  const { template } = useSelector((state) => state.getMagnet);

  var latestSpecial = defaultConfig?.specialOffer || 'Latest special Offer';

  let latestSpecialForm = {
    title: latestSpecial
      ? `Redeem your special offer: ` + latestSpecial
      : 'Register to qualify for our latest specials',
    form: {
      contact: {
        enabled: false,
      },
      enabled: true,
      inputs: [
        {
          id: 'lead_name',
          label: 'Name',
          required: true,
          type: 'lead_name',
        },
        {
          id: 'lead_phone',
          label: 'Phone Number',
          required: true,
          type: 'lead_phone',
        },
        {
          id: 'lead_email',
          label: 'Email',
          required: true,
          type: 'lead_email',
        },
      ],
      opacity: 1,
      text_color: 'black',
      text_data: {
        title: latestSpecial
          ? `Redeem your special offer: ` + latestSpecial
          : 'Register to qualify for our latest specials',
        body_text:
          'Fill out your Details Below to claim the special and become eligible for other specials at this time',
        submit_body:
          'You can share this tour with a friend using the buttons below.',
        submit_fail:
          'Oops! Something went wrong while submitting the form. Please try again in a moment.',
        submit_success: 'Your submission has been received!',
      },
    },
    links: [],
  };

  const generateLatestSpecialForm = () => {
    console.log('tourPopupScreenRoute: ', tourPopupScreenRoute);
    if (
      // !defaultConfig?.specialOffer ||
      tourPopupScreenRoute?.[0] === '_promotions_'
    ) {
      return {
        category: '_preview_',
        screen: 'new',
        previewTemplateScreen: latestSpecialForm,
      };
    }
    return {
      screen: tourPopupScreenRoute?.[1],
      category: tourPopupScreenRoute?.[0],
    };
  };

  return (
    <>
      <div className="w-full grid grid-cols-1 gap-6 pt-4 items-center p-4 z-50">
        <Dialog
          open={tourPopupScreenRoute && tourPopupScreenRoute?.length === 2}
          // TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          maxWidth={'lg'}
          PaperProps={{
            style: {
              height: '95vh',
              width: '80vw',
              borderRadius: 13,
              overflow: 'hidden',
            },
          }}
        >
          {/* If we render when not open, then the video starts playing in the background */}
          {tourPopupScreenRoute}

          {tourPopupScreenRoute && tourPopupScreenRoute?.length === 2 && (
            <PreviewVideoMagnet
              uuid={magnetId}
              template={template}
              {...generateLatestSpecialForm()}
              inline={false}
              singleScreen={true}
              onClose={handleClose}
            />
          )}
        </Dialog>

        <div className=" ml-4">
          <div className="rs">
            <span className="font-bold pb-2">
              What your website visitors see:{' '}
            </span>
            <TourWidgetPreview
              defaultConfig={defaultConfig}
              setDefaultConfig={setDefaultConfig}
              videoSrc={videoStartChoice.toString()}
            />
          </div>
        </div>
        <div>
          <div>
            <FormControl
              variant="filled"
              style={{ width: '80%', maxWidth: '375px' }}
            >
              <RouteInput
                placeholder="Default Screen"
                routes={routeOpts}
                value={defaultConfig?.startScreen || ''}
                disableHrefGeneration={true}
                // value={condition[ind].route}
                onChange={(value) => {
                  console.log('value: ', value);
                  setDefaultConfig({
                    ...defaultConfig,
                    startScreen: value?.route,
                  });
                }}
              />
            </FormControl>
          </div>

          <br />
          <div>
            <DebouncedTextField
              style={{ minWidth: '350px' }}
              label="Call to Action"
              value={defaultConfig?.buttonLabel}
              onChange={(value) => {
                setDefaultConfig((prev) => ({ ...prev, buttonLabel: value }));
              }}
            />
          </div>
          <br />
          <div className="flex w-full gap-4">
            <div>
              <InputLabel shrink={true}>Widget Position</InputLabel>
              <ButtonGroup>
                <Button
                  style={
                    defaultConfig?.layout === 'left'
                      ? {
                          backgroundColor: 'rgb(59 130 246)',
                          color: 'white',
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                        }
                      : {
                          textTransform: 'capitalize',
                        }
                  }
                  onClick={() => setLayoutDirection('left')}
                >
                  Left
                </Button>
                <Button
                  onClick={() => setLayoutDirection('right')}
                  style={
                    defaultConfig?.layout === 'right'
                      ? {
                          backgroundColor: 'rgb(59 130 246)',
                          color: 'white',
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                        }
                      : {
                          textTransform: 'capitalize',
                        }
                  }
                >
                  Right
                </Button>
              </ButtonGroup>
            </div>
            <div>
              <InputLabel shrink={true}>Primary Color</InputLabel>
              <WidgetColorPicker
                widgetColorPrimary={
                  defaultConfig?.primaryColor || DEFAULT_PRIMARY_COLOR
                }
                setWidgetColorPrimary={(e) => {
                  setDefaultConfig((prev) => ({ ...prev, primaryColor: e }));
                }}
              />
            </div>
            <div>
              <InputLabel shrink={true}>Secondary Color</InputLabel>
              <WidgetColorPicker
                widgetColorPrimary={
                  defaultConfig?.secondaryColor ||
                  defaultConfig?.primaryColor ||
                  DEFAULT_PRIMARY_COLOR
                }
                setWidgetColorPrimary={(e) => {
                  setDefaultConfig((prev) => ({ ...prev, secondaryColor: e }));
                }}
              />
            </div>
          </div>
          {PreviewWidgetEmbed({
            uuid: magnetUuid,
            buttonLabel: defaultConfig?.buttonLabel,
          })}
        </div>
      </div>

      {/* <MagnetSettings
            // community_id={community_id}
            magnet_uuid={magnetId}
            title="Settings"
          /> */}
    </>
  );
};

export default TourBubbleConfig;
