import {
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';

export default function EmailLeads({
  customizableEmail,
  setCustomizableEmail,
  magnetSettings,
  form_id,
  uuid,
}) {
  useEffect(() => {
    if (
      !customizableEmail?.cmpEmail ||
      !customizableEmail?.cmpName ||
      !customizableEmail.website ||
      !customizableEmail.phone
    ) {
      setCustomizableEmail({
        ...customizableEmail,
        cmpEmail: customizableEmail?.cmpEmail || magnetSettings?.email,
        cmpName: customizableEmail?.cmpName || magnetSettings?.communityName,
        website: customizableEmail?.website || magnetSettings?.website,
        phone: customizableEmail?.phone || magnetSettings?.phone,
      });
    }
  }, []);

  return (
    <div className=" grid grid-cols-1 lg:grid-cols-5 gap-3 overflow-hidden">
      <div className="  lg:col-span-2 overflow-y-scroll">
        <Grid item xs={12}>
          <Select
            value={customizableEmail.emailType}
            onChange={(event) =>
              setCustomizableEmail({
                ...customizableEmail,
                emailType: event.target.value,
              })
            }
            fullWidth
          >
            <MenuItem value={'message'}>Message</MenuItem>
            <MenuItem value={'promo'}>Promo</MenuItem>
          </Select>
        </Grid>
        {customizableEmail.emailType && (
          <>
            <Grid item xs={12}>
              <DebouncedTextField
                label="Email Subject"
                fullWidth
                onChange={(newValue) =>
                  setCustomizableEmail({
                    ...customizableEmail,
                    subject: newValue,
                  })
                }
                value={customizableEmail.subject}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                label="Reply to Email"
                fullWidth
                onChange={(newValue) =>
                  setCustomizableEmail({
                    ...customizableEmail,
                    cmpEmail: newValue,
                  })
                }
                value={customizableEmail.cmpEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                label="Text Color"
                fullWidth
                onChange={(newValue) => {
                  let mod_value = newValue.replace('#', '');
                  console.log('colr', newValue, mod_value);
                  setCustomizableEmail({
                    ...customizableEmail,
                    textcolor: mod_value,
                  });
                }}
                value={customizableEmail?.textcolor}
              />
            </Grid>
            {/* TODO: make this a gradiant  */}
            <Grid item xs={12}>
              <DebouncedTextField
                label="Color"
                fullWidth
                onChange={(newValue) => {
                  let mod_value = newValue.replace('#', '');
                  console.log('colr', newValue, mod_value);
                  setCustomizableEmail({
                    ...customizableEmail,
                    gradient: {
                      l_color: mod_value,
                      r_color: mod_value,
                    },
                  });
                }}
                value={customizableEmail?.gradient?.l_color}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                label="Company Name"
                fullWidth
                onChange={(newValue) =>
                  setCustomizableEmail({
                    ...customizableEmail,
                    cmpName: newValue,
                  })
                }
                value={customizableEmail.cmpName}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                label="Rep Name"
                fullWidth
                onChange={(newValue) =>
                  setCustomizableEmail({
                    ...customizableEmail,
                    repName: newValue,
                  })
                }
                value={customizableEmail.repName}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                label="Company Website"
                fullWidth
                onChange={(newValue) =>
                  setCustomizableEmail({
                    ...customizableEmail,
                    website: newValue,
                  })
                }
                value={customizableEmail.website}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                label="Company Phone"
                fullWidth
                onChange={(newValue) =>
                  setCustomizableEmail({
                    ...customizableEmail,
                    phone: newValue,
                  })
                }
                value={customizableEmail.phone}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                label="Company Address"
                fullWidth
                onChange={(newValue) =>
                  setCustomizableEmail({
                    ...customizableEmail,
                    address: newValue,
                  })
                }
                value={customizableEmail.address}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                label="Action Button Text"
                fullWidth
                onChange={(newValue) =>
                  setCustomizableEmail({
                    ...customizableEmail,
                    actionText: newValue,
                  })
                }
                value={customizableEmail.actionText}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                label="Action Button Link"
                fullWidth
                onChange={(newValue) =>
                  setCustomizableEmail({
                    ...customizableEmail,
                    actionLink: newValue,
                  })
                }
                value={customizableEmail.actionLink}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                label="Facebook Link"
                fullWidth
                onChange={(newValue) =>
                  setCustomizableEmail({
                    ...customizableEmail,
                    fb: newValue,
                  })
                }
                value={customizableEmail.fb}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                label="Instagram Link"
                fullWidth
                onChange={(newValue) =>
                  setCustomizableEmail({
                    ...customizableEmail,
                    insta: newValue,
                  })
                }
                value={customizableEmail.insta}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                label="Twitter Link"
                fullWidth
                onChange={(newValue) =>
                  setCustomizableEmail({
                    ...customizableEmail,
                    twitter: newValue,
                  })
                }
                value={customizableEmail.twitter}
              />
            </Grid>
            {customizableEmail.emailType === 'promo' && (
              <>
                <Grid item xs={12}>
                  <DebouncedTextField
                    label="Promo Code"
                    fullWidth
                    onChange={(newValue) =>
                      setCustomizableEmail({
                        ...customizableEmail,
                        code: newValue,
                      })
                    }
                    value={customizableEmail.code}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DebouncedTextField
                    label="Fee Title"
                    fullWidth
                    onChange={(newValue) =>
                      setCustomizableEmail({
                        ...customizableEmail,
                        fee: newValue,
                      })
                    }
                    value={customizableEmail.fee}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DebouncedTextField
                    label="Fee Amount"
                    fullWidth
                    onChange={(newValue) =>
                      setCustomizableEmail({
                        ...customizableEmail,
                        feeAmount: newValue,
                      })
                    }
                    value={customizableEmail.feeAmount}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DebouncedTextField
                    label="Logo"
                    fullWidth
                    onChange={(newValue) =>
                      setCustomizableEmail({
                        ...customizableEmail,
                        logo: newValue,
                      })
                    }
                    value={customizableEmail.logo}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DebouncedTextField
                    label="Rep Picture"
                    fullWidth
                    onChange={(newValue) =>
                      setCustomizableEmail({
                        ...customizableEmail,
                        selfie: newValue,
                      })
                    }
                    value={customizableEmail.selfie}
                  />
                </Grid>
              </>
            )}
            {customizableEmail.emailType === 'message' && (
              <Grid item xs={12}>
                <DebouncedTextField
                  label="Message"
                  fullWidth
                  multiline
                  onChange={(newValue) =>
                    setCustomizableEmail({
                      ...customizableEmail,
                      message: newValue,
                    })
                  }
                  value={customizableEmail.message}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                fullWidth
                target="_blank"
                href={`https://api.leasemagnets.com/email/promo?leadName=John Doe&preview=true&emailType=${customizableEmail.emailType}&message=${customizableEmail.message}&fee=${customizableEmail.fee}&code=${customizableEmail.code}&insta=${customizableEmail.insta}&fb=${customizableEmail.fb}&twitter=${customizableEmail.twitter}&actionLink=${customizableEmail.actionLink}&actionText=${customizableEmail.actionText}&amount=${customizableEmail.feeAmount}&company=${customizableEmail.cmpName}&cmpRep=${customizableEmail.repName}&cmpEmails=${customizableEmail.cmpEmail}&cmpSite=${customizableEmail.website}&cmpAddy=${customizableEmail.address}&lColor=${customizableEmail?.gradient?.l_color}&rColor=${customizableEmail?.gradient?.r_color}&cmpNum=${customizableEmail.phone}&selfie=${customizableEmail.selfie}&logo=${customizableEmail.logo}&textcolor=${customizableEmail.textcolor}`}
              >
                Generate Preview
              </Button>
            </Grid>
            <FormControlLabel
              control={
                <Switch
                  checked={customizableEmail.live}
                  onChange={(event) =>
                    setCustomizableEmail({
                      ...customizableEmail,
                      live: event.target.checked,
                    })
                  }
                  name="checkedLive"
                  inputProps={{ 'aria-label': 'live checkbox' }}
                />
              }
              label="Go Live"
            />
          </>
        )}
      </div>

      <div className=" lg:col-span-3">
        <iframe
          src={`https://api.leasemagnets.com/email/promo?leadName=John Doe&preview=true&emailType=${customizableEmail.emailType}&message=${customizableEmail.message}&fee=${customizableEmail.fee}&code=${customizableEmail.code}&insta=${customizableEmail.insta}&fb=${customizableEmail.fb}&twitter=${customizableEmail.twitter}&actionLink=${customizableEmail.actionLink}&actionText=${customizableEmail.actionText}&amount=${customizableEmail.feeAmount}&company=${customizableEmail.cmpName}&cmpRep=${customizableEmail.repName}&cmpEmails=${customizableEmail.cmpEmail}&cmpSite=${customizableEmail.website}&cmpAddy=${customizableEmail.address}&lColor=${customizableEmail?.gradient?.l_color}&rColor=${customizableEmail?.gradient?.r_color}&cmpNum=${customizableEmail.phone}&selfie=${customizableEmail.selfie}&logo=${customizableEmail.logo}&textcolor=${customizableEmail.textcolor}`}
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
}
