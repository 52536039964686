import { Divider, Typography } from '@material-ui/core';
import MatGrid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import aset from '../../assets/authenticaiton/aset.png';
import cardinal from '../../assets/authenticaiton/cardinal.png';
import desai from '../../assets/authenticaiton/desai.png';
import deso from '../../assets/authenticaiton/deso.png';
import independance from '../../assets/authenticaiton/independance.png';
import lakers from '../../assets/authenticaiton/lakers.png';
import m from '../../assets/authenticaiton/m.png';
import peak from '../../assets/authenticaiton/peak.png';
import pie from '../../assets/authenticaiton/pie.png';
import pnoe from '../../assets/authenticaiton/pnoe.png';
import saas from '../../assets/authenticaiton/saas.png';
import social from '../../assets/authenticaiton/social.png';
import google from '../../assets/home/google.png';
import Header from '../../layout/Header';
import { IoMdEyeOff, IoMdEye } from 'react-icons/io';
import firebase, { auth, google_provider } from '../../utils/base';
import { send_Request_data_to_discord } from '../../utils/api';
import { font } from '../../utils/constants';
import './signin.css';
import moment from 'moment';
import posthog from 'posthog-js'

export const SignIn = () => {
  posthog.capture('LoginEvent', { property: 'signin' })
  // User Input States
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordvisible, setpasswordvisible] = useState(false);

  // Validate Inputs
  const isValid = !email || !password;

  const onSignIn = (e) => {
    e.preventDefault();

    if (isValid) {
      console.log("Input fields couldn't be empty");
      return;
    }

    firebase.auth
      .signInWithEmailAndPassword(email.trim(), password)
      .then((user) => {
        console.log(user);
        if (email != 'team@leasemagnets.com') {
          send_Request_data_to_discord(
            {
              content: `**Login:** 
            User: ${email} 
            Date: ${new Date()}`,
            },
            true
          );
          posthog.capture('LoginSuccessEvent', { property: 'signinSuccessful' })

        }
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const signInWithGoogle = () => {
    auth
      .signInWithPopup(google_provider)
      .then((result) => {
        var token = result.credential.accessToken;
        var user = result.user;
        if (result?.user?.email != 'team@leasemagnets.com') {
          send_Request_data_to_discord(
            {
              content: `**Login with google:** 
              User: ${result?.user?.email} 
              Date: ${new Date()}`,
            },
            true
          );
          posthog.capture('LoginSuccessEvent', { property: 'signinSuccessful' })
        }
      }, true)
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        var credential = error.credential;
        setError(errorMessage);
      });
  };

  const emailPattern = /^\S+@\S+\.\S+/;

  const [showSignin, setShowSignin] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setShowSignin(false);
      setIsMobile(true);
    }
  }, []);

  const history = useHistory();
  return (
    <>
      <Header />
      <div className="SignIn  w-full">
        <div
          className=" flex items-center mt-40 justify-center flex-col-reverse w-full  lg:grid grid-cols-1 lg:grid-cols-2"
          style={{ width: '90vw' }}
          classes={{ root: 'center_container' }}
        >
          <div
            className=" flex items-center justify-center flex-col gap-2"
            style={{ display: showSignin && isMobile ? 'none' : 'flex' }}
          >
            {/* <img
          alt="phone showing demo tour video"
          style={{ width: '75%' }}
          src={signuppic}
        /> */}
            <Typography
              variant={isMobile ? 'h6' : 'h5'}
              align="center"
              style={{
                fontWeight: 900,
                fontFamily: font,
                marginTop: '20px',
                lineHeight: '38px',
              }}
            >
              Join the <span className="blue-highlight">400+</span> <br />{' '}
              businesses that
              {/* <span className="blue-highlight">
            {' '}
           
          </span> */}
            </Typography>
            {/* <p className=" font-medium text-lg pt-4 pb-1">
          Join the 400+ businesses that trust us with their tour.
        </p> */}
            <div className=" w-full flex items-center justify-center ">
              <div className=" grid grid-cols-4 items-center gap-x-6 gap-y-4">
                {/* <a
                  href="http://gogle.com"
                  target="_blank"
                  className=" cursor-pointer"
                >
                  <img src={lakers} style={{ objectFit: 'cover' }} alt="" />
                </a> */}
                <a
                  href="http://gogle.com"
                  target="_blank"
                  className=" cursor-pointer"
                >
                  <img src={saas} style={{ objectFit: 'cover' }} alt="" />
                </a>
                <a
                  href="http://gogle.com"
                  target="_blank"
                  className=" cursor-pointer"
                >
                  <img src={social} style={{ objectFit: 'cover' }} alt="" />
                </a>
                <a
                  href="http://gogle.com"
                  target="_blank"
                  className=" cursor-pointer"
                >
                  <img src={deso} style={{ objectFit: 'cover' }} alt="" />
                </a>
                <a
                  href="http://gogle.com"
                  target="_blank"
                  className=" cursor-pointer"
                >
                  <img src={desai} style={{ objectFit: 'cover' }} alt="" />
                </a>
                <a
                  href="http://gogle.com"
                  target="_blank"
                  className=" cursor-pointer"
                >
                  <img
                    src={independance}
                    style={{ objectFit: 'cover' }}
                    alt=""
                  />
                </a>
                <a
                  href="http://gogle.com"
                  target="_blank"
                  className=" cursor-pointer"
                >
                  <img
                    src={m}
                    className=" p-3"
                    style={{ objectFit: 'cover' }}
                    alt=""
                  />
                </a>
                <a
                  href="http://gogle.com"
                  target="_blank"
                  className=" cursor-pointer"
                >
                  <img src={peak} style={{ objectFit: 'cover' }} alt="" />
                </a>
                <a
                  href="http://gogle.com"
                  target="_blank"
                  className=" cursor-pointer"
                >
                  <img src={pie} style={{ objectFit: 'cover' }} alt="" />
                </a>
                <a
                  href="http://gogle.com"
                  target="_blank"
                  className=" cursor-pointer"
                >
                  <img src={pnoe} style={{ objectFit: 'cover' }} alt="" />
                </a>
                <a
                  href="http://gogle.com"
                  target="_blank"
                  className=" cursor-pointer"
                >
                  <img src={cardinal} style={{ objectFit: 'cover' }} alt="" />
                </a>
                <a
                  href="http://gogle.com"
                  target="_blank"
                  className=" cursor-pointer"
                >
                  <img src={aset} style={{ objectFit: 'cover' }} alt="" />
                </a>
              </div>
            </div>
            <Typography
              variant={isMobile ? 'h6' : 'h5'}
              align="center"
              style={{
                fontWeight: 900,
                fontFamily: font,
                marginTop: '15px',
                lineHeight: '35px',
              }}
            >
              trust us with their tour.
            </Typography>
          </div>

          <div className=" flex items-center mt-32 lg:mt-0 justify-center flex-col gap-2">
            <form className="w-full" onSubmit={onSignIn}>
              <MatGrid container item xs={12} direction="column">
                <Typography
                  variant={isMobile ? 'h5' : 'h4'}
                  style={{
                    color: '#262E37',
                    fontWeight: 900,
                    fontFamily: font,
                  }}
                >
                  Welcome Back!{' '}
                </Typography>
                <Typography
                  variant={isMobile ? 'subtitle2' : 'subtitle1'}
                  style={{
                    color: '#262E37',
                    fontFamily: font,
                    marginTop: '5px',
                  }}
                >
                  Please log in to your account.{' '}
                </Typography>{' '}
                <br />
                <MatGrid container item xs={12} justify="center">
                  <button
                    type="button"
                    className="button google flex items-center justify-center mb-10"
                    onClick={signInWithGoogle}
                    style={{ display: 'flex' }}
                  >
                    {' '}
                    <img
                      alt="Google logo"
                      style={{
                        height: '20px',
                        marginRight: '15px',
                        marginBottom: '2px',
                        width: '20px',
                        objectFit: 'contain',
                      }}
                      src={google}
                    />{' '}
                    Sign In with Google
                  </button>
                  <Divider style={{ width: '100%' }} />
                  <Typography
                    variant="caption"
                    style={{
                      marginTop: '-10px',
                      background: 'white',
                      padding: '0 5px',
                    }}
                  >
                    or
                  </Typography>
                  <div className="mb-7"></div>
                </MatGrid>
                <input
                  type="text"
                  name="login"
                  id="login"
                  tabIndex="1"
                  className="text-input "
                  autoCorrect="off"
                  autoCapitalize="off"
                  placeholder="johndoe@gmail.com"
                  style={{
                    backgroundColor:
                      email && emailPattern.test(email)
                        ? 'rgb(226, 244, 253)'
                        : '#F3F3F4',
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'relative',
                  }}
                >
                  <input
                    type={passwordvisible ? 'text' : 'password'}
                    name="login"
                    id="login"
                    tabIndex="1"
                    className="text-input"
                    autoCorrect="off"
                    autoCapitalize="off"
                    placeholder="**********"
                    style={{
                      backgroundColor: password
                        ? 'rgb(226, 244, 253)'
                        : '#F3F3F4',
                    }}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <div
                    onClick={() => {
                      setpasswordvisible(!passwordvisible);
                    }}
                    style={{
                      alignSelf: 'flex-end',
                      right: '20px',
                      top: '27px',
                      position: 'absolute',
                    }}
                  >
                    {passwordvisible ? (
                      <IoMdEye className=" w-7 h-7" />
                    ) : (
                      <IoMdEyeOff className=" w-7 h-7" />
                    )}
                  </div>
                </div>
              </MatGrid>
              <MatGrid
                container
                item
                xs={12}
                direction="row"
                justify="flex-end"
                style={{ marginTop: '10px' }}
              >
                <Typography variant="caption">
                  <a href="/resetpassword" className="special-link-red text-sm">
                    Forgot password?
                  </a>
                </Typography>
              </MatGrid>
              <MatGrid
                container
                item
                xs={12}
                direction="row"
                justify="flex-start"
                style={{ display: error ? 'flex' : 'none' }}
              >
                <Typography variant="caption" style={{ color: 'red' }}>
                  {error}
                </Typography>
              </MatGrid>

              <MatGrid container item xs={12} justify="space-between">
                <button type="submit" className="button signin w-button">
                  Log in
                </button>
                {/* <button
              type="button"
              className="button create w-button"
           
            >
              Sign up for free!
            </button> */}
                <p
                  className="text-sm text-gray-700 text-center mt-8 w-full"
                  onClick={() => history.push('/signup')}
                >
                  Don't have an account?{' '}
                  <span className=" cursor-pointer text-pr">
                    {' '}
                    Sign up for free
                  </span>
                </p>
              </MatGrid>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
