import React from 'react';

function SidebarDashboardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 30 22"
    >
      <path
        fill="#446888"
        fillRule="evenodd"
        d="M8.5 19.125a1.063 1.063 0 011.063-1.063h19.124a1.062 1.062 0 110 2.125H9.563A1.062 1.062 0 018.5 19.125zm0-8.5a1.063 1.063 0 011.063-1.063h19.124a1.063 1.063 0 010 2.126H9.563A1.062 1.062 0 018.5 10.624zm0-8.5a1.062 1.062 0 011.063-1.063h19.124a1.063 1.063 0 010 2.125H9.563A1.062 1.062 0 018.5 2.125zM2.125 4.25a2.125 2.125 0 100-4.25 2.125 2.125 0 000 4.25zm0 8.5a2.125 2.125 0 100-4.25 2.125 2.125 0 000 4.25zm0 8.5a2.125 2.125 0 100-4.25 2.125 2.125 0 000 4.25z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SidebarDashboardIcon;
