import React, { useRef, useState } from 'react';
import { AiOutlinePause } from 'react-icons/ai';
import { FiPlay } from 'react-icons/fi';

const ReviewVideoPlayer = ({ item, addopen, setAddopen, ind }) => {
  const vidRef = useRef(null);
  const isplaying = useRef(false);
  const handlePlayVideo = () => {
    console.log(vidRef);
    if (isplaying.current == false) {
      //isplaying.current = true
      let playPromise = vidRef.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
            isplaying.current = true;
            vidRef.current.play();
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
            console.log(error);
          });
      }
    }
  };

  const handlePauseVideo = () => {
    if (isplaying.current == true) {
      isplaying.current = false;
      vidRef.current.pause();
    }
  };
  const [play, setPlay] = useState({
    id: -1,
    status: false,
    ref: false,
  });

  return (
    <>
      <div className="relative w-80 overflow-hidden rounded-xl">
        <video
          ref={vidRef}
          className="item-video"
          style={{ height: '100%' }}
          loop
        >
          <source
            // src={video}
            src={item.video}
            type="video/mp4"
          />
          Your browser does not support HTML5 Video.
        </video>
        <div className=" absolute bottom-3 left-3 z-20">
          {play.id === ind && play.status === true && play.ref === true ? (
            <AiOutlinePause
              onClick={() => {
                handlePauseVideo();
                setPlay({
                  id: ind,
                  status: false,
                  ref: false,
                });
              }}
              className=" w-7 h-7 text-white cursor-pointer"
            />
          ) : (
            <FiPlay
              onClick={() => {
                handlePlayVideo();
                setPlay({
                  id: ind,
                  status: true,
                  ref: true,
                });
              }}
              className=" w-7 h-7 text-white cursor-pointer"
            />
          )}
        </div>
        <div
          onClick={() => setAddopen(true)}
          className=" absolute top-0 left-0 z-10 h-full w-full"
          style={{
            background: 'rgba(0,0,0,0.4)',
            cursor: 'pointer',
          }}
        ></div>
      </div>
      <a href={item.video} target="_blank">
        download url{' '}
      </a>
    </>
  );
};

export default ReviewVideoPlayer;
