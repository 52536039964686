import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { get } from '../../utils/request';
import AllVideos from './AllVideos';
import Navbar from './Navbar';
import PropertyBanner from './PropertyBanner';
import PropertyDetails from './PropertyDetails';
import './share-video.css';

function Property(props) {
  // Get query parameters
  const query = new URLSearchParams(window.location.search);

  const magnet_id = query.get('magnet_uuid');

  const agent_idx = query.get('agent');

  // Path to getting video details via template
  const route = props.match.params.route.split('.');

  const [details, setDetails] = useState();
  const [apt, setApt] = useState();

  // Get all of the apartment's info for navbar
  function getCommunityDetails() {
    get(`/magnets/template?magnet_uuid=${magnet_id}`, { auth: false })
      .then((data) => {
        setApt(data.magnet.magnet_details.template.magnetSettings);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  // Get video template from backend via GET request
  function getVideoTemplate() {
    get(`/magnets/template?magnet_uuid=${magnet_id}`, { auth: false })
      .then((data) => {
        setDetails(
          data.magnet.magnet_details.template.categories[route[0]]['screens'][
            route[1]
          ]
        );
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  useEffect(() => {
    document.title = 'Tour built just for you | Tour.video';
    getCommunityDetails();
    getVideoTemplate();
  }, []);

  return (
    <div>
      {apt && (
        <Navbar
          query={query}
          name={apt.communityName}
          location={apt.location}
          site={apt.website}
          phone={apt.phone}
          email={apt.email}
          scheduleLink={apt.scheduleLink}
        />
      )}
      {details && apt && (
        <>
          <PropertyBanner
            property={details.title}
            summary={
              details?.additionalInfo?.shareCaption
                ? `${details.additionalInfo.shareCaption}`
                : ''
            }
            price={
              details.additionalInfo ? `${details.additionalInfo.price}` : ''
            }
            size={details.size}
          />
          <PropertyDetails details={details} apt={apt} agent_idx={agent_idx} />
        </>
      )}
      <AllVideos query={query} currRoute={route[0] + '.' + route[1]} />
    </div>
  );
}

export default withRouter(Property);
