import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { MdOutlineRefresh } from 'react-icons/md';
import eparty from '../../assets/emojiParty.png';
import AnimatedDialog from '../Reusable/AnimatedDialog';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
}));

const ThankYouDialog = ({ open, setOpen, askGoogleReview }) => {
  console.log('gogle', askGoogleReview);
  const [openDesc, setOpenDesc] = useState(false);

  return (
    <AnimatedDialog
      title="Edit Review Settings"
      open={open}
      onClose={() => setOpen(false)}
    >
      <button
        onClick={() => setOpen(false)}
        type="button"
        class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
      >
        <span class="sr-only">Close menu</span>
        <svg
          class="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>

      <div>
        <div className="">
          {/* <p className=" text-sm font-semibold">Logo</p> */}
          <div className=" flex items-center justify-center p-4">
            <img src={eparty} className=" w-16 h-16" alt="" />
            <p className="ml-2  font-semibold">
              Thank you so much for your feedback, we really appreciate you{' '}
              <span
                onClick={() => setOpenDesc(true)}
                className=" text-pr cursor-pointer border-b border-pr hover:text-blue-600"
              >
                Don't believe me?
              </span>
            </p>
          </div>
          {openDesc && (
            <div className="border border-gray-500 rounded-md p-4  mx-4 my-2 h-96 overflow-scroll	">
              <h5>
                After you pressed the submit button and sent us your testimonial
                a little buzzer went off in our office. Our entire team can hear
                it, and when it went off everyone smiled. Our office manager
                gave a golf clap and our operations guy did a pushup (I don't
                know why, but for some reason he does a pushup each time the
                buzzer goes off). Greg, our head of marketing, ran outside and
                hugged some old lady walking by the office. I'm pretty sure he
                just tried to kiss her. She didn't seem to mind it, thankfully.
                I'm also fairly certain I just saw Kera, our director of
                content, take a shot of tequila. I have no idea why she's
                drinking at work, but hey, the kid is excited you just signed
                up. Is she pouring another one? Ah crap, I gotta go and stop
                her. Wait, shoot... I'm getting off topic. Look, I didn't write
                this to tell you about our team's awful impulse control, but to
                thank you for signing up. Every pixel and character of copy on
                our site and in this newsletter is here to make your time here
                as memorable as possible. We hope every amenity, floor plan we
                create will inspire you to live a more epic life. If it doesn't,
                just hit reply to any email you receive from The us/team and
                tell us how you feel. Thanks again for joining our tribe. We'll
                holler in a few days with some awesome stuff. - Sam PS: Wanna
                make our head of operations do another pushup? Hit "share on
                Facebook" or "share on Twitter" at the bottom of this email.
                Then, once we see it, we'll send you a video of Greg doing a
                pushup. I'm not joking...try me. To make sure we see it, tweet
                at us or email us proof.
              </h5>
            </div>
          )}
          <div
            onClick={() => location.reload()}
            className=" flex items-center text-sm text-pr cursor-pointer justify-end font-medium gap-1 mt-2 mb-2 mx-4"
          >
            <MdOutlineRefresh className=" w-5 h-5" /> Leave another review
          </div>
          {askGoogleReview == 1 && (
            <div className=" mx-4 border-t pt-4 pb-2">
              <p className=" w-full text-center">
                <span className=" text-xl text-pr font-semibold">
                  Thank you
                </span>{' '}
                for being willing to leave us a google review
              </p>
              <div class="flex justify-center items-center pt-2">
                <div class=" flex items-center gap-2">
                  <div className=" p-3 animate-pulse rounded-full bg-pr"></div>
                  <span class="visually-hidden font-medium animate-pulse">
                    Transporting you to google.com shortly...
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </AnimatedDialog>
  );
};

export default ThankYouDialog;
