import Rating from '@material-ui/lab/Rating';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import firebase from '../../utils/base';

function ReferralsDialog({ customId = '' }) {
  const { id } = useParams();
  const [communityId, setCommunityid] = useState(customId || id || '');

  const [openFilter, setOpenFilter] = useState('');
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [appliedFilter, setAppliedFilter] = useState(false);
  const [settings, setSettings] = useState(null);
  const [filtersList, setFiltersList] = useState([]);
  const [usersList, setUsersList] = useState([]);

  const [searchResult, setSearchResult] = useState(usersList);
  const [agentDetail, setAgentDetail] = useState('');
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [step, setStep] = useState(1);
  const [verificationDetail, setVerificationDetail] = useState(null);
  const [community, setCommunity] = useState(null);
  const [emailCodeVal, setEmailCodeVal] = useState('');
  const [phoneCodeVal, setPhoneCodeVal] = useState('');

  const [currentReferral, setCurrentReferral] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loginConnect, setLoginConnect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [connectData, setConnectData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
  });
  const [loginConnectData, setLoginConnectData] = useState({
    emailLogin: '',
    passwordLogin: '',
  });

  const handleChangeConnectData = (e) => {
    setConnectData({ ...connectData, [e.target.name]: e.target.value });
  };
  const handleChangeLoginConnectData = (e) => {
    setLoginConnectData({
      ...loginConnectData,
      [e.target.name]: e.target.value,
    });
  };

  const [credits, setCredits] = useState(null);

  function parseJsonPayloadHorizontally(dataArr = null) {
    return dataArr.map((data, i) => {
      let tempObj = {};
      Object.keys(data).map((k, idx) => {
        console.log('response jsonp: ', k, data[k]);
        try {
          let t1 = JSON.parse(data[k]);
          tempObj[k] = t1;
          return t1;
        } catch (e) {
          console.log(e);
        }
        tempObj[k] = data[k];
        return data[k];
      });
      console.log('response jsonfull: ', tempObj);
      return tempObj || {};
    });
  }

  const toastNotification = (message, type) => {
    toast(message, {
      position: 'top-right',
      type: type,
      autoClose: 5000,
    });
  };
  useEffect(() => {
    localStorage.getItem('letsConnect') &&
      setCurrentUser(JSON.parse(localStorage.getItem('letsConnect')));
  }, []);
  useEffect(() => {
    localStorage.getItem('credits') &&
      setCredits(localStorage.getItem('credits'));

    let time = localStorage.getItem('time');
    console.log('hours', time);
    if (time) {
      let today = Date.now();
      let localStorageTime = new Date(Number(time)).getTime();
      console.log('hours localStorageTime', { localStorageTime, today });
      console.log('today >  localStorageTime', today > localStorageTime);
      if (today > localStorageTime) {
        let timeMiliSeconds = today - localStorageTime;

        // 24*60*60*1000 = 24 hours
        if (timeMiliSeconds > 24 * 60 * 60 * 1000) {
          localStorage.setItem('time', Date.now());
          localStorage.setItem('credits', 5);
          toastNotification('🎉 Congrats you received 5 credits now');
        }
      }
    }
  }, []);

  const getcommunity = async (community_id) => {
    const res = await axios.get(
      `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
    );
    console.log('response', res, res.data.payload);
    let temp = parseJsonPayloadHorizontally(res.data.payload);
    console.log('response2', temp);
    if (res.data.payload.length > 0) {
      try {
        let thejson = {
          ...temp[0],
          champions: temp?.[0]?.champions?.champions || [],
          communitybadges: temp?.[0]?.communitybadges?.communitybadges || [],
          reviews: temp?.[0]?.reviews?.reviews || [],
          settings: temp?.[0]?.settings || {},
          team: temp?.[0]?.team?.team || [],
        };
        //console.log('response',JSON.parse(res.data?.payload[0].champions))
        console.log('thejson', thejson);
        setDirectualCommunity({ ...thejson });
        console.log('after', directualCommunity);
        setcreatecommunityenable(true);
        setloading(false);
      } catch (error) {
        console.log('thejson errr', error);
      }
    } else {
      console.log('thejson response_empty');
    }
  };
  useEffect(() => {
    getcommunity();
  }, []);

  useEffect(() => {
    if (communityId) {
      getCommunityData(communityId);
      getReferralsUsersInCommunity(communityId);
    }
  }, []);

  useEffect(() => {
    if (usersList) {
      setSearchResult(usersList);
    }
  }, [usersList]);

  const getCommunityData = async (id) => {
    var config = {
      method: 'get',
      url:
        'https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=' +
        id,
    };

    try {
      const response = await axios(config);
      // setCommunity_data(response.data?.payload[0]);

      console.log('community data', response.data?.payload[0]);
      setCommunity(response.data?.payload[0]);

      let JSONSettings = JSON.parse(response.data?.payload[0].settings);
      setSettings(JSONSettings);

      setFiltersList(
        JSONSettings?.referrals?.badges.map((item) => {
          return {
            title: item.name,
            list: item.type.map((item) => {
              return { label: item, value: item.toLowerCase() };
            }),
          };
        })
      );

      setSelectedFilter([]);
    } catch (error) {
      console.log(error);
    }
  };
  const getReferralsUsersInCommunity = async (communityId) => {
    var config = {
      method: 'get',
      url:
        'https://api.directual.com/good/api/v5/data/users/getReferralUsersInCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=' +
        communityId,
    };

    try {
      const response = await axios(config);

      console.log('response', response?.data?.payload);

      if (response?.data?.payload) {
        setUsersList(
          response?.data?.payload.map((item) => {
            let metadata = JSON.parse(item?.metadata);
            console.log('metadata', metadata);
            return {
              id: item.id,
              name: item.name,
              email: item.email,
              image: metadata?.photoUrl,
              values: metadata?.skills?.map((value) => {
                return { label: value, value };
              }),
            };
          })
        );
      }

      // setReferralUsers(response.data?.payload);
    } catch (error) {
      console.log(error);
    }
  };

  const handleApplyFilters = () => {
    setAppliedFilter(true);

    console.log('run the handleApplyFilters function here');
    let newResults = [];
    selectedFilter.map((filter) => {
      console.log('filter', filter);
      usersList.map((user) => {
        console.log('user', user);
        let result = user?.values?.some(
          (value) => value.label === filter.value
        );
        if (result) {
          newResults.push(user);
        }
      });
    });

    const ids = newResults.map((user) => user.id);
    const removedDuplicateItems = newResults.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );

    setSearchResult(removedDuplicateItems);
    setOpenFilter('');
  };

  const handleCloseAppliedFilters = () => {
    console.log('run the handleCloseAppliedFilters function here');
    setAppliedFilter(false);
    setSearchResult(usersList);
    setSelectedFilter([]);
    setOpenFilter('');
  };

  const handleCloseLoginDialog = () => {
    setOpenLoginDialog(false);
    setCurrentReferral(null);
  };

  const createNewUser = async () => {
    let postData = {
      email: connectData.email.replace(/\s+/g, ''),
      name: connectData.name.replace(/\s+/g, ''),
      phone: connectData.phone.replace(/\s+/g, ''),
      password: connectData.password.replace(/\s+/g, ''),
      community: communityId, // communityId
    };

    if (!validation(postData)) {
      return;
    }
    registerInFirebase(postData);
  };

  const loginConnectUser = async () => {
    console.log('login');
    let postData = {
      email: loginConnectData?.emailLogin.replace(/\s+/g, ''),
      password: loginConnectData?.passwordLogin.replace(/\s+/g, ''),
    };

    if (!validation(postData, true)) {
      return;
    }
    loginWithFirebase(postData);
  };

  const registerInFirebase = async (data) => {
    await firebase.letsConnectNewUser(
      data,
      toastNotification,
      handleCloseLoginDialog,
      setCurrentUser,
      setLoading,
      setCredits
    );
  };
  const loginWithFirebase = async (data) => {
    await firebase.letsConnectLogin(
      data,
      toastNotification,
      handleCloseLoginDialog,
      setCurrentUser,
      setLoading,
      setCredits
    );
  };

  const validation = (data, login) => {
    if (!data.name && !login) {
      toast('Name is required', {
        position: 'top-right',
        type: 'error',
        autoClose: 5000,
      });
      return false;
    }
    if (!data.email) {
      toast('Email is required', {
        position: 'top-right',
        type: 'error',
        autoClose: 5000,
      });
      return false;
    }
    if (!data.phone && !login) {
      toast('Phone is required', {
        position: 'top-right',
        type: 'error',
        autoClose: 5000,
      });
      return false;
    }
    if (!data.password) {
      toast('Password is required', {
        position: 'top-right',
        type: 'error',
        autoClose: 5000,
      });
      return false;
    }
    return true;
  };
  const handleVerification = async () => {
    let postData = {
      email: connectData.email,
      name: connectData.name,
      phone: connectData.phone,
      community: id, // communityId
    };

    const doc = await firebase.emailPhoneVerify(postData.email, postData.phone);
    if (doc.exists) {
      console.log('user exist', doc.data());
      setVerificationDetail(doc.data());
      if (doc.data().emailVerify === true && doc.data().phoneVerify === true) {
        const userExistRes = await axios.get(
          `https://api.directual.com/good/api/v5/data/users/findUserbyEmailOrPhone?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=asdf&email=${postData.email}&phone=${postData.phone}`
        );
        const user = userExistRes?.data?.payload[0];
        setCurrentUser(user);
        if (user) {
          setOpenLoginDialog(false);
          toast('🥳You are connected, Now you can send the messages :)', {
            position: 'top-right',
            type: 'success',
            autoClose: 5000,
          });
          setOpenLoginDialog(false);
          return;
        } else {
          const userRes = await axios.post(
            `https://api.directual.com/good/api/v5/data/users/newUser?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83`,
            postData
          );
          console.log('userUpdated after review TYG: ', userRes?.data?.result);
          setCurrentUser(userRes?.data?.result);
          toast('🥳You are connected, Now you can send the messages :)', {
            position: 'top-right',
            type: 'success',
            autoClose: 5000,
          });
          setOpenLoginDialog(false);
          return;
        }
      }
      if (typeof doc.data().emailVerify === 'string') {
        setStep(2);

        return;
      }
      if (typeof doc.data().phoneVerify === 'string') {
        setStep(3);
        return;
      }
    } else {
      toast(
        'This user is not register in our temporary verification process. we are working on that. Please enter the registered email and phone for testing purpose',
        {
          position: 'top-right',
          type: 'error',
          autoClose: 5000,
        }
      );
    }
  };
  console.log('verificationDetail', verificationDetail);

  const handleVerificationCode = async () => {
    if (step === 2) {
      if (emailCodeVal === verificationDetail?.emailVerify) {
        firebase
          .verify(connectData.email, connectData?.phone, 'emailVerify')
          .then(() => {
            setStep(3);
          })
          .catch((error) => console.log(error.message));
      } else {
        toast('Please enter the correct code', {
          position: 'top-right',
          type: 'error',
          autoClose: 5000,
        });
      }
    }
    if (step === 3) {
      if (phoneCodeVal === verificationDetail?.phoneVerify) {
        firebase
          .verify(connectData?.email, connectData?.phone, 'phoneVerify')
          .then(() => handleVerification())
          .catch((error) => console.log(error.message));
      } else {
        toast('Please enter the correct code', {
          position: 'top-right',
          type: 'error',
          autoClose: 5000,
        });
      }
    }
  };

  return (
    <>
      <div className="referral-dialog">
        <div id="recaptcha-container"></div>
        <div className="referrals-container flex flex-col">
          {agentDetail ? (
            <div className="agent-detail">
              <div className="back-btn font" onClick={() => setAgentDetail('')}>
                &times;
              </div>
              <div className="user flex aic">
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(${agentDetail.image})`,
                  }}
                />
                <div className="meta flex flex-col">
                  <div className="name">{agentDetail.name}</div>
                  <div className="values">
                    {agentDetail?.values?.map((value, idx) => (
                      <div key={idx} className="txt">
                        <span className="dot">•</span>
                        <span>{value.label}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="about font">
                I'm a mountain climber, working hard USC 21 Lets connect you two
                !
              </div>
              <div className="form flex flex-col">
                <div className="field-row">
                  <div className="field">
                    <input
                      type="text"
                      placeholder="Name"
                      className="iput font"
                    />
                  </div>
                </div>
                <div className="field-row">
                  <div className="field">
                    <input
                      type="text"
                      placeholder="Email"
                      className="iput font"
                    />
                  </div>
                  <div className="field">
                    <input
                      type="text"
                      placeholder="Phone"
                      className="iput font"
                    />
                  </div>
                </div>
                <div className="field-row">
                  <div className="field">
                    <textarea
                      type="text"
                      placeholder={`Write your message to ${agentDetail.name}`}
                      className="iput font"
                    />
                  </div>
                </div>
                <div className="field-row">
                  <div className="field">
                    <input
                      type="text"
                      placeholder="move in date"
                      className="iput area font"
                    />
                  </div>
                </div>
                <div className="field-row">
                  <div className="field">
                    <button className="cleanbtn connect-btn font">
                      Lets's Connect
                    </button>
                  </div>
                </div>
              </div>
              <div className="extra-detail">
                <div className="reviews">
                  <div className="lbl font s14 black">
                    What Magdalena has to say about the Latitude
                  </div>
                  <Rating
                    name="rating-component"
                    defaultValue={0}
                    precision={0.5}
                    readOnly
                    size="medium"
                  />
                </div>
                <div className="user-badges">
                  <div className="lbl font s14 black">
                    Absolutely loved my time here:”
                  </div>
                  <div className="badges-list">
                    {[{}, {}, {}, {}].map((itm, idx) => (
                      <div key={idx} className="itm font">
                        My badge
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="hdr">
                <div className="title font flex justify-between">
                  <div>We live here! Ask us questions!</div>
                  {credits && <div>Credits : {credits}</div>}
                </div>
                <div className="txt font">
                  Ask a current resident about their experience by messaging
                  them below. You'll receive an extra $50 move-in bonus if you
                  lease here.
                </div>
              </div>
              <div className="latitude-blk">
                <div className="label font">Community at {community?.name}</div>
                <div className="tabs-bar rel">
                  {filtersList.length > 0 &&
                    filtersList.map((filter, index) => (
                      <div key={index}>
                        {filter?.title && (
                          <div
                            className={`tab ${
                              selectedFilter.find((x) => {
                                return filter.list.find(
                                  (itm) => itm.label == x.label
                                );
                              }) && appliedFilter
                                ? 'active'
                                : ''
                            }`}
                            onClick={() => {
                              if (filter.title === openFilter?.title) {
                                setOpenFilter('');
                              } else {
                                setOpenFilter(filter);
                              }
                            }}
                          >
                            <div className="lbl font">{filter.title}</div>
                            <div
                              className={`ico ${
                                filter.title === openFilter?.title
                                  ? 'icon-arrow_drop_up'
                                  : 'icon-arrow_drop_down'
                              }`}
                            />
                          </div>
                        )}

                        {openFilter && openFilter?.list?.length > 0 && (
                          <div className="filters-block">
                            <div className="filters-wrap">
                              {openFilter.list.map((filter, idx) => (
                                <div
                                  key={idx}
                                  className="filter"
                                  onClick={() => {
                                    let filterFound = selectedFilter.find(
                                      (x) => x.label == filter.label
                                    );
                                    if (filterFound) {
                                      let currentSelectedFilter =
                                        selectedFilter.filter(
                                          (x) => x.label !== filter.label
                                        );
                                      setSelectedFilter([
                                        ...currentSelectedFilter,
                                      ]);
                                    } else {
                                      setSelectedFilter([
                                        ...selectedFilter,
                                        filter,
                                      ]);
                                    }
                                  }}
                                >
                                  <div
                                    className={`checkbox ${
                                      selectedFilter.find(
                                        (x) => x.label == filter.label
                                      )
                                        ? 'active'
                                        : ''
                                    }`}
                                  />
                                  <div className="lbl">{filter.label}</div>
                                </div>
                              ))}
                            </div>
                            <div className="filter-actions">
                              <button
                                className="action-btn font"
                                onClick={() => setOpenFilter('')}
                              >
                                Cancel
                              </button>
                              <button
                                className="action-btn filled font"
                                onClick={handleApplyFilters}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  {appliedFilter && (
                    <div
                      className="cross-btn font"
                      onClick={handleCloseAppliedFilters}
                    >
                      &times;
                    </div>
                  )}
                </div>
                <div className="users">
                  {searchResult.length ? (
                    <>
                      {searchResult.map((item, index) => (
                        <UserBlock
                          setOpenLoginDialog={setOpenLoginDialog}
                          key={index}
                          secondUser={item}
                          firstUser={currentUser}
                          community={community}
                          setAgentDetail={setAgentDetail}
                          toastNotification={toastNotification}
                          setCredits={setCredits}
                        />
                      ))}
                    </>
                  ) : (
                    <div className="empty-user-list">
                      <div className="txt font">Result Not Found.</div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <AnimatedDialog
        open={openLoginDialog}
        onClose={handleCloseLoginDialog}
        fullWidth
        maxWidth={'xs'}
      >
        <div className="referral-login-dialog">
          <div className="hdr">
            <div className="title font">
              Connect with {currentReferral?.name}
            </div>
            <div className="cross-btn font" onClick={handleCloseLoginDialog}>
              &times;
            </div>
          </div>
          {step === 1 && (
            <div className="content">
              {loginConnect ? (
                <div>
                  <div className="field">
                    <div className="lbl font">Email</div>
                    <input
                      name="emailLogin"
                      onChange={(e) => handleChangeLoginConnectData(e)}
                      type="text"
                      className="iput font"
                      placeholder="Enter your Email"
                      value={connectData.emailLogin}
                    />
                  </div>
                  <div className="field">
                    <div className="lbl font">Password</div>
                    <input
                      name="passwordLogin"
                      onChange={(e) => handleChangeLoginConnectData(e)}
                      type="password"
                      className="iput font"
                      placeholder="Enter your Password"
                      value={connectData.passwordLogin}
                    />
                  </div>
                  <div>
                    <p
                      onClick={() => setLoginConnect(false)}
                      className="no-underline hover:underline cursor-pointer"
                    >
                      Make a new account
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="field">
                    <div className="lbl font">Name</div>
                    <input
                      name="name"
                      onChange={(e) => handleChangeConnectData(e)}
                      type="text"
                      className="iput font"
                      placeholder="Enter your name"
                      value={connectData.name}
                    />
                  </div>
                  <div className="field">
                    <div className="lbl font">Email</div>
                    <input
                      name="email"
                      onChange={(e) => handleChangeConnectData(e)}
                      type="text"
                      className="iput font"
                      placeholder="Enter your Email"
                      value={connectData.email}
                    />
                  </div>
                  <div className="field">
                    <div className="lbl font">Phone Number</div>
                    <input
                      name="phone"
                      onChange={(e) => handleChangeConnectData(e)}
                      type="text"
                      className="iput font"
                      placeholder="Enter your Phone Number"
                      value={connectData.phone}
                    />
                  </div>
                  <div className="field">
                    <div className="lbl font">Password</div>
                    <input
                      name="password"
                      onChange={(e) => handleChangeConnectData(e)}
                      type="password"
                      className="iput font"
                      placeholder="Enter your Password"
                      value={connectData.password}
                    />
                  </div>
                  <div>
                    <p
                      onClick={() => setLoginConnect(true)}
                      className="no-underline hover:underline cursor-pointer"
                    >
                      Already have an account
                    </p>
                  </div>
                </div>
              )}

              <div className="actions flex aic">
                <button
                  className="action transparent font"
                  onClick={handleCloseLoginDialog}
                >
                  Cancel
                </button>
                <button
                  className="action font"
                  onClick={() => {
                    loginConnect ? loginConnectUser() : createNewUser();
                  }}
                >
                  {loading ? 'Loading' : 'Lets Connect'}
                </button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="content">
              <div className="field">
                <div className="lbl font">Verify your Email</div>
                <input
                  onChange={(e) => setEmailCodeVal(e.target.value)}
                  value={emailCodeVal}
                  type="text"
                  className="iput font"
                  placeholder="Enter the code that sent to your Email"
                />
              </div>

              <div className="actions flex aic">
                <button
                  className="action transparent font"
                  onClick={handleCloseLoginDialog}
                >
                  Cancel
                </button>
                <button
                  className="action font"
                  onClick={handleVerificationCode}
                >
                  Verify Email
                </button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="content">
              <div className="field">
                <div className="lbl font">Verify your Phone</div>
                <input
                  name="email"
                  onChange={(e) => setPhoneCodeVal(e.target.value)}
                  value={phoneCodeVal}
                  type="text"
                  className="iput font"
                  placeholder="Enter the code that sent to your Phone"
                />
              </div>

              <div className="actions flex aic">
                <button
                  className="action transparent font"
                  onClick={handleCloseLoginDialog}
                >
                  Cancel
                </button>
                <button
                  className="action font"
                  onClick={handleVerificationCode}
                >
                  Verify Phone
                </button>
              </div>
            </div>
          )}
        </div>
      </AnimatedDialog>
    </>
  );
}

export default ReferralsDialog;
const UserBlock = ({
  secondUser,
  setOpenLoginDialog,
  firstUser,
  community,
  setAgentDetail,
  toastNotification,
  setCredits,
}) => {
  const [message, setMessage] = useState('');

  const sendMessagesHandler = async () => {
    if (!firstUser) {
      setOpenLoginDialog(true);
    } else {
      if (!secondUser.email) {
        toastNotification('Email is not found', 'error');
        return;
      }
      // let postData = {
      //   userOneId: firstUser?.id,
      //   userTwoId: secondUser?.id,
      //   message: message,
      //   community,
      //   requesterMessage: firstUser?.name,
      // };
      if (Number(localStorage.getItem('credits') < 1)) {
        toastNotification(
          "You don't have enough credits for messages. Please wait for 24 hours, after that it will automatically be added by 5",
          'error'
        );
        return;
      }
      var data = {
        message: {
          to: {
            email: secondUser?.email,
          },
          data: {
            recipientName: secondUser.name,
            referralRequestorEmail: firstUser.email,
            referralRequestorName: firstUser.name,
            referralRequestorMessage: message,
            communityName: community?.name,
            currentReferralSpecial: 'ghghf',
          },
          template: 'VMYH3GN47H4KEEGMA813PQ1DJQ2E',
        },
      };

      console.log('data', data);

      var config = {
        method: 'post',
        url: 'https://api.leasemagnets.com/send_courier',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);

          toastNotification('Email sent successfully :)', 'success');
          if (localStorage.getItem('credits')) {
            let credits = parseInt(localStorage.getItem('credits'));
            localStorage.setItem('credits', `${--credits}`);
            setCredits(localStorage.getItem('credits'));
          }
        })
        .catch(function (error) {
          console.log(error.message);

          toastNotification('An error occurred :)', 'error');
        });
    }
  };

  return (
    <div className="user-blk">
      <div className="user flex aic">
        <div
          className="img"
          style={{
            backgroundImage: `url(${
              secondUser?.image ||
              `https://ui-avatars.com/api/?name=${encodeURI(secondUser?.name)}`
            } )`,
          }}
        />
        <div className="meta flex flex-col">
          <div className="name" onClick={() => setAgentDetail(secondUser)}>
            {secondUser?.name}
          </div>
          <div className="values">
            {secondUser?.values &&
              secondUser?.values.map((value, idx) => (
                <div key={idx} className="txt">
                  <span className="dot">•</span>
                  <span>{value.label}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="field-row">
        <div className="field">
          <textarea
            type="text"
            placeholder={`Write your message to ${secondUser?.name}.`}
            className="iput area font"
            defaultValue={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <button
          disabled={!message}
          className="cleanbtn send-btn font"
          onClick={() => sendMessagesHandler(secondUser)}
        >
          Send
        </button>
      </div>
      {message && (
        <div className="field-row">
          <div className="field">
            <div className="lbl font">Expected Move in Date*</div>
            <input type="date" placeholder="Name" className="iput font" />
          </div>
        </div>
      )}
    </div>
  );
};
