// Get apt name slug
export function getSlug() {
  // Split URL by '/'
  return 'the-george-ann-arbor';
  const url = window.location.href.split('/');
  return url[url.length - 1];
}

// Convert given string into a url slug
export function stringToSlug(string) {
  return string.trim().toLowerCase().replace(/ /g, '-');
}

// Translate slug into a string with each first letter capitalized
export function slugToString(slug) {
  return slug
    .split('-')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
}
