import React, { useRef, useState } from 'react';
import './IframeContextVideo.css';

const IframeContextVideo = ({
  shouldVideoAutoplay,
  togglePlay,
  contextVideoData,
}) => {
  const videoRef = useRef();
  const [minimized, setMinimized] = useState(!shouldVideoAutoplay);
  // let [playing, setPlaying] = useState(false)
  const blackGlass = useRef();

  const IS_DESKTOP = !window.matchMedia('only screen and (max-width: 600px)')
    .matches;

  const sizeRecommendation = () => {
    if (!shouldVideoAutoplay) {
      return IS_DESKTOP ? 150 : 130;
    }

    return IS_DESKTOP ? (minimized ? 100 : 150) : minimized ? 80 : 130;
  };

  return (
    <>
      <div
        style={{
          gridColumn: 1,
          gridRow: 3,
          width: '100%',
          zIndex: 100,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'start',
        }}
      >
        <div
          style={{
            borderRadius: '15%',
            marginLeft: 'auto',
            height: sizeRecommendation(),
            width: sizeRecommendation(),
            transition: 'all 0.5s ease-in-out',
            overflow: 'hidden',
            display: 'grid',
            placeItems: 'center',
            marginRight: '6%',
            position: 'relative',
            border: 'black',
            borderWidth: IS_DESKTOP ? 3 : 2,
            borderStyle: 'solid',
            overflow: 'hidden',
            transform: 'translateZ(0)',
          }}
          onClick={() => {
            setMinimized((minimized) => {
              if (minimized) {
                if (!shouldVideoAutoplay) {
                  togglePlay();
                }
                videoRef.current.play();
              } else {
                videoRef.current.pause();
              }

              return !minimized;
            });
          }}
        >
          <div
            onMouseEnter={() => blackGlass.cu}
            ref={blackGlass}
            style={{
              width: '100%',
              height: '100%',
              transform: 'translate(-50%, -50%)',
              top: '50%',
              left: '50%',
              position: 'absolute',
              zIndex: 101,
              backgroundColor: 'black',
              opacity: minimized ? 0.3 : 0,
              pointerEvents: 'none',
              transition: 'transition all 1s ease-in-out',
            }}
          />

          <svg
            style={{
              width: 32,
              height: 32,
              transform: 'translate(-50%, -50%)',
              top: '50%',
              left: '50%',
              position: 'absolute',
              zIndex: 103,
              opacity: minimized ? 1 : 0,
            }}
            xmlns="https://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="white"
            className=""
          >
            <path
              fillRule="evenodd"
              d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
              clipRule="evenodd"
            />
          </svg>

          <video
            playsInline
            preload="auto"
            type="video/mp4"
            autoPlay={shouldVideoAutoplay}
            ref={videoRef}
            style={{
              transition: 'all 0.5s ease-in-out',
              height: sizeRecommendation(),
              width: sizeRecommendation(),
              zIndex: 100,
              transform: 'translateZ(0) scale(2)',
              position: 'relative',
              webkitTransform: 'translateZ(0) scale(2)',
              borderRadius: IS_DESKTOP ? '100%' : '15%',
              WebkitMaskImage:
                'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);',
              overflow: 'clip',
            }}
            src={`${contextVideoData.videoUrl}${
              contextVideoData.startTime
                ? `#t=${parseInt(contextVideoData.startTime)}`
                : ''
            }`}
          />

          {/* https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/11/magnet/5bf261ca-3103-4e13-8646-af8856b8ab26/floor_plans_2_Bed___4_1_2_Bath/A17th_model_unit_mp4_1.mp4?isButtonSeprated=false */}
        </div>
      </div>
    </>
  );
};

export default IframeContextVideo;
