import { Button, Grid, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { AiOutlineClose } from 'react-icons/ai';
import ContentLoader from '../../components/ContentLoader';
import AccessLevelWrapper from '../../components/Reusable/AccessLevelWrapper';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import CustomHeader from '../../components/Reusable/CustomHeader';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import notification from '../../components/Reusable/Notification';
import firebase from '../../utils/base';
import { post, useRequest } from '../../utils/request';
import TeamPagination from './TeamPagination';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const Marketplace = ({ name, community_id, url, title }) => {
  const [NotificationBar, setNotification] = notification();
  const classes = useStyles();
  const history = useHistory();

  const [magnetTemplate, setMagnetTemplate] = useState({});
  const [magnetUuid, setMagnetUuid] = useState('');
  const [magnetType, setMagnetType] = useState('');

  const [loading, error, data, makeRequest] = useRequest({ loading: true });

  const [magSettings, setMagSettings] = useState({
    location: '',
    features: [],
    communityName: '',
    website: '',
    phone: '',
    email: '',
    customBranding: {
      img: '',
      url: '',
    },
    backgroundImg: '',
    agents: [], // each agent object in array contains name, email, image, and access_level (access_level is one of "user", "property", "company")
    preferredSettings: '',
  });

  const getMagnet = async () => {
    const res = await makeRequest(
      `/magnets?queryByCommunity=${community_id}`,
      'GET',
      null,
      { auth: false }
    );
    console.log(res.magnets);

    let magnetObj = res?.magnets[0]?.magnet_details.template;
    setMagnetUuid(res?.magnets[0]?.uuid);
    setMagnetType(res?.magnets[0]?.magnet_type);
    setMagnetTemplate(res?.magnets[0]?.magnet_details.template);

    let tempDict = {};

    if (magnetObj && 'magnetSettings' in magnetObj) {
      let magSettingKeys = Object.keys(magSettings);

      for (let k of magSettingKeys) {
        if (
          magnetObj.magnetSettings &&
          Object.keys(magnetObj.magnetSettings).includes(k)
        ) {
          tempDict[k] = magnetObj.magnetSettings[k];
        } else {
          tempDict[k] = magSettings[k];
        }
      }
      setMagSettings(tempDict);
    }
  };
  useEffect(() => {
    getMagnet();
  }, [community_id]);

  const [addUserAgent, setAddUserAgent] = useState(false);

  const [newUserAgents, setNewUserAgents] = useState([]);
  const [deleteUserAgents, setDeleteUserAgents] = useState(null);

  const [addPropertyManager, setAddPropertyManager] = useState(false);
  const [propertyManagerName, setPropertyManagerName] = useState('');
  const [propertyManagerEmail, setPropertyManagerEmail] = useState('');
  const [propertyManagerImage, setPropertyManagerImage] = useState('');
  const [newPropertyManagers, setNewPropertyManagers] = useState([]);
  const [deletePropertyManagers, setDeletePropertyManagers] = useState([]);

  const [editPropertyManager, setEditPropertyManager] = useState(false);
  const [editPropertyManagerIndex, setEditPropertyManagerIndex] = useState(-1);
  const [editPropertyManagerName, setEditPropertyManagerName] = useState('');
  const [editPropertyManagerEmail, setEditPropertyManagerEmail] = useState('');
  const [editPropertyManagerImage, setEditPropertyManagerImage] = useState('');
  const [valueEdit, setValueEdit] = useState('');
  const [value, setValue] = React.useState('property');
  const [newUser, setNewUser] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const handleChange = () => {
    setEditModal(false);
  };

  function handleUserAgentDelete(index) {
    const curr_agent = magSettings.agents[index];
    let exists = false;
    newUserAgents.map((agent_, index_) => {
      if (agent_ === curr_agent) {
        let currentNewUserAgents = newUserAgents;
        currentNewUserAgents.splice(index_, 1);
        setNewUserAgents(currentNewUserAgents);
        let currentAgents = magSettings.agents;
        currentAgents.splice(index, 1);
        setMagSettings({ ...magSettings, agents: currentAgents });
        exists = true;
      }
    });

    if (exists) {
      setAddUserAgent(false);
      return;
    }

    let currentAgents = magSettings.agents;

    // currentDeleteUserAgents.push(currentAgents[index]);
    updateSettings(currentAgents[index], 'remove');
    currentAgents.splice(index, 1);
    setMagSettings({ ...magSettings, agents: currentAgents });
  }

  function handlePropertyManagerAdd(override, name, email, image) {
    const currentAgents = magSettings.agents;
    const agent = {
      name: propertyManagerName,
      email: propertyManagerEmail,
      image: propertyManagerImage,
      access_level: value,
    };
    if (override) {
      agent.name = name;
      agent.email = email;
      agent.image = image;
    }

    if (!agent.name || !agent.email) {
      alert('field can not be blank');
      return;
    }
    if (!ValidateEmail(agent.email)) {
      return;
    }

    // error testing: check for repeats
    let exists = false;
    for (var i = 0; i < currentAgents.length; i++) {
      if (currentAgents[i].email === agent.email) {
        if (currentAgents[i].access_level === agent.access_level) {
          exists = true;
          alert('A user with the same email address has already been added.');
          break;
        } else if (currentAgents[i].access_level === 'user') {
          const response_ = window.confirm(
            'A user with this email address exists in the system as a Leasing Agent. Promote user to Property Manager?'
          );
          if (response_) {
            handleUserAgentDelete(i);
          } else {
            exists = true;
            break;
          }
        } else {
          // access_level === "company"
          alert(
            'A user with this email address exists as an admin in the system.'
          );
          exists = true;
          break;
        }
      }
    }

    if (exists) {
      setPropertyManagerName('');
      setPropertyManagerEmail('');
      setPropertyManagerImage('');
      setAddPropertyManager(false);
      return;
    }
    console.log('agent', agent);
    updateSettings(agent, 'add');
    currentAgents.push(agent);
    setMagSettings({ ...magSettings, agents: currentAgents });
    setAddPropertyManager(false);
    setPropertyManagerName('');
    setPropertyManagerEmail('');
    setPropertyManagerImage('');
    setEditModal(false);
  }
  function handlePropertyManagerEdit() {
    const old_agent = magSettings.agents[editPropertyManagerIndex];
    const new_agent = {
      name: editPropertyManagerName,
      email: editPropertyManagerEmail,
      image: editPropertyManagerImage,
      access_level: 'property',
    };
    if (old_agent['email'] !== new_agent['email']) {
      handleUserAgentDelete(editPropertyManagerIndex);
      handlePropertyManagerAdd(
        true,
        editPropertyManagerName,
        editPropertyManagerEmail,
        editPropertyManagerImage
      );
    } else {
      magSettings.agents[editPropertyManagerIndex]['name'] = new_agent['name'];
      magSettings.agents[editPropertyManagerIndex]['image'] =
        new_agent['image'];
      setEditPropertyManager(false);
    }
    setEditPropertyManagerName('');
    setEditPropertyManagerEmail('');
    setEditPropertyManagerImage('');
    setEditModal(false);
  }

  function updateSettings(user, todo) {
    // const tempAdd = [...newPropertyManagers];
    // const tempRemove = [...deleteUserAgents];
    // console.log("tempAdd", tempAdd)
    // tempAdd.forEach((newUser) =>
    //
    // );
    console.log('newUser', user);
    if (todo === 'add' && user) {
      firebase.createUser(user, community_id, name);
    }
    if (todo === 'remove' && user) {
      firebase.removeAccess(user, community_id);
    }
    // tempRemove.forEach((deleteUser) =>

    // );

    post('/magnets/template/settings', {
      magnet_uuid: magnetUuid,
      magnetSettings: magSettings,
      magnet_type: magnetType,
    }).then((data) => {
      setNotification(`Settings updated`, 'success');
      setNewPropertyManagers([]);
      setDeletePropertyManagers([]);
      setNewUserAgents([]);
      setDeleteUserAgents([]);
      // setSubmit(true);
    });

    console.log('just posted the magnet', {
      magnet_uuid: magnetUuid,
      template: {
        ...magnetTemplate,
        magnetSettings: magSettings,
      },
      magnet_type: magnetType,
    });
  }

  const [currentItems, setCurrentItems] = useState([]);

  const [itemOffset, setItemOffset] = useState(0);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.

  const [deleteModal, setDeleteModal] = useState(false);
  const handleClose = () => {
    setDeleteModal(false);
  };
  const [deleteUserIndex, setdeleteUserIndex] = useState();

  const [uAccessLevel, setUAccessLevel] = useState('user');
  function ValidateEmail(email) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    }
    alert('You have entered an invalid email address!');
    return false;
  }
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CustomHeader
        url={url}
        name={name}
        community_id={community_id}
        title="Marketplace"
      />
      {loading ? (
        <div className="magnent-team-page flex flex-col">
          <div className="hdr">
            <div className="tit holder" />
            <div className="lbl holder" />
          </div>
          <div className="team-wrap flex aic">
            <div className="item holder" />
            <div className="item holder" />
            <div className="item holder" />
          </div>
          <div className="hdr">
            <div className="tit holder" />
            <div className="lbl holder" />
          </div>
          <div className="bt holder" />
        </div>
      ) : (
        <>
          {error ? (
            <div className="font s15 b5 red t-c flex aic">{error}</div>
          ) : (
            <div className="magnent-team-page flex flex-col">
              <ContentLoader>
                <Grid container spacing={1}>
                  <AccessLevelWrapper
                    access_level_clearance="user"
                    community_id={community_id}
                    setUAccessLevel={setUAccessLevel}
                  >
                    <div className=" flex flex-col gap-2 w-full">
                      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        View Tour Plugin Marketplace
                      </Typography>

                      <Typography variant="caption">
                        Build more tours effectively with our plugin marketplace
                      </Typography>

                      <section className="py-10 bg-gray-100">
                        <div className="relative mx-auto grid max-w-6xl  grid-cols-1 gap-6 p-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pt-10">
                          <span className="text-xl font-bold	absolute left-10 top-[-25px]">
                            Reviews
                          </span>
                          <article className="rounded-xl bg-white p-3 shadow-lg hover:shadow-xl hover:transform hover:scale-105 duration-300 ">
                            <a
                              href="javascript:void(0);"
                              onClick={() => history.push('./reviews')}
                            >
                              <div className="relative flex items-end overflow-hidden rounded-xl">
                                <img
                                  src="https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/df72512e-6290-4a11-7398-8694e20a4500/public"
                                  alt="Hotel Photo"
                                />
                                <div className="absolute bottom-3 left-3 inline-flex items-center rounded-lg bg-white p-2 shadow-md">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 text-yellow-400"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                  </svg>
                                  <span className="ml-1 text-sm text-slate-400">
                                    4.9
                                  </span>
                                </div>
                              </div>

                              <div className="mt-1 p-2">
                                <h2 className="text-slate-700">
                                  Feedbackjoy: #1 More reviews
                                </h2>
                                <p className="mt-1 text-sm text-slate-400">
                                  Reputation
                                </p>

                                <div className="mt-3 flex items-end justify-between">
                                  <p className="text-lg font-bold text-blue-500">
                                    FREE
                                  </p>

                                  <div className="flex items-center space-x-1.5 rounded-lg bg-blue-500 px-4 py-1.5 text-white duration-100 hover:bg-blue-600">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="h-4 w-4"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                                      />
                                    </svg>

                                    <button className="text-sm">
                                      Get Started
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </article>
                        </div>
                      </section>
                    </div>
                  </AccessLevelWrapper>
                </Grid>
              </ContentLoader>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Marketplace;
