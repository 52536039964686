// Material UI imports
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useEffect, useState } from 'react';

export default function Navbar({
  query,
  name,
  location,
  site,
  phone,
  email,
  scheduleLink,
}) {
  useEffect(() => {
    document.title = `${name} | Tour built just for you | Tour.video`;
  }, []);

  return (
    <div
      data-collapse="medium"
      data-animation="default"
      data-duration="400"
      role="banner"
      class="tour-nav"
    >
      <div className="tour-nav-container">
        <a href={`/tour?${query}`} aria-current="page" className="brand">
          <h1 className="display-heading nav-header">
            {name}
            <br />
            <span className="display-heading-span">{location}</span>
          </h1>
        </a>
        <Desktop
          site={site}
          phone={phone}
          email={email}
          scheduleLink={scheduleLink}
        />
        <Mobile
          query={query}
          site={site}
          phone={phone}
          email={email}
          scheduleLink={scheduleLink}
        />
      </div>
    </div>
  );
}

function Desktop({ site, phone, email, scheduleLink }) {
  return (
    <nav role="navigation" className="nav-menu">
      {site && (
        <>
          <a href={site} target="_blank" className="nav-link-plain">
            Website
          </a>
          <div className="line-break"></div>
        </>
      )}
      {phone && (
        <>
          <a href={`tel:${phone}`} target="_blank" className="nav-link-plain">
            Call
          </a>
          <div className="line-break"></div>
        </>
      )}
      {email && (
        <>
          <a
            href={`mailto:${email}`}
            target="_blank"
            className="nav-link-plain"
          >
            Email
          </a>
          <div className="line-break"></div>
        </>
      )}
      {/*<a href={(scheduleLink) ? scheduleLink : site} target="_blank" className="button">
        Schedule a Tour →
      </a>*/}
      {(phone || email) && (
        <a href={phone ? `tel:${phone}` : `mailto:${email}`} target="_blank">
          <div className="button">Schedule a Tour →</div>
        </a>
      )}
    </nav>
  );
}

function Mobile({ query, site, phone, email, scheduleLink }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        className="menu-button-green m-nav-button"
        aria-label="menu"
        role="button"
        tabindex="0"
        aria-controls="w-nav-overlay-0"
        aria-haspopup="menu"
        aria-expanded="false"
        onClick={handleClick}
      >
        {'☰'}
      </div>
      <Menu
        id="mobile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <a href={`/tour?${query}`} className="nav-link-plain">
            Home
          </a>
        </MenuItem>
        <hr />
        <MenuItem onClick={handleClose}>
          <a href={site} className="nav-link-plain">
            Website
          </a>
        </MenuItem>
        <hr />
        {phone && (
          <>
            <MenuItem onClick={handleClose}>
              <a href={`tel:${phone}`} className="nav-link-plain">
                Call
              </a>
            </MenuItem>
            <hr />
          </>
        )}
        {email && (
          <>
            <MenuItem onClick={handleClose}>
              <a href={`mailto:${email}`} className="nav-link-plain">
                Email
              </a>
            </MenuItem>
            <hr />
          </>
        )}
        <MenuItem onClick={handleClose}>
          <a
            href={scheduleLink ? scheduleLink : site}
            target="_blank"
            className="button"
          >
            Schedule a Tour →
          </a>
        </MenuItem>
      </Menu>
    </>
  );
}
