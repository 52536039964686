import React from 'react';
import './question.css';

export default function Badges({ badges, selected, setSelected }) {
  // Add or delete badge from selected prop
  function modifySelected(event, title) {
    console.log('selected', selected);
    let temp = [...selected];
    // If badge becomes selected, add it to selected state
    if (event.target.checked) {
      console.log('event', event.target.checked);
      temp.push({ id: event.target.id, title: title });
    }
    // Badge becomes unselected, remove from selected state
    else {
      temp = selected.filter((item) => item.id !== event.target.id);
    }

    // Set new state of selected prop
    setSelected(temp);
  }

  return (
    <div className="badges flex aic">
      <form>
        {badges.map((badge) => {
          return (
            <>
              <input
                type="checkbox"
                id={badge.id}
                onClick={(event) => modifySelected(event, badge.title)}
              />
              <label htmlFor={badge.id}>
                <div className="badge-item flex aic">
                  <span></span> {badge.title}
                </div>
              </label>
            </>
          );
        })}
      </form>
    </div>
  );
}
