import React, { useEffect, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import avatar from '../../assets/testimonials.jpeg';
import {
  AddDeleteCommunities as AddCommunities,
  BillingSignup,
  PersonalInfoSignup,
} from '../../components/SignUpForm';
import Header from '../../layout/Header';
import { getUserCommunities } from '../../utils/routes';
import '../signin/signin.css';
import './infiniteCarousel.css'; // Custom CSS for the infinite scroll
import { AiFillPlayCircle } from 'react-icons/ai'; // Play button icon
const LeasemagnetsLogo = require('../../assets/tour-video-NEW-TYG-logo.png');

// Mock data for videos
const videoData = [
  {
    id: 1,
    src: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com//testmonials/essentials_t.mp4',
    title: 'The Essential',
    img: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com//testmonials/essentials_testimonials.png'
  },
  {
    id: 2,
    src: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com//testmonials/peak_made.mp4',
    title: 'PeakMade',
    img: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com//testmonials/peakmade_testimonials.png'
  },
  {
    id: 3,
    src: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com//testmonials/Quad_real_estate.mp4',
    title: 'Quad Real Estate',
    img: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com//testmonials/Quad_realestate_v2.png'
  },
  {
    id: 4,
    src: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com//testmonials/CLS.mp4',
    title: 'Campus Life & Style',
    img: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com//testmonials/cls_v2.png'
  },
  {
    id: 5,
    src: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com//testmonials/caliber_living.mp4',
    title: 'Caliber Living',
    img: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com//testmonials/calibar_living_v2.png'
  },
  {
    id: 6,
    src: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com//testmonials/GMH.mp4',
    title: 'GMH',
    img: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com//testmonials/GMH_v2.png'
  },
 
  // Add more video objects here
];

const VideoCard = ({ video, onPlay }) => (
  <div className="video-card" onClick={() => onPlay(video.src)}>
    <img 
    style={{width : '450px', height : '250px'}}
    width={"550px"} height={'450px'} src={video.img} alt={video.title} className="video-thumbnail" />
    <div className="play-button">
      <AiFillPlayCircle size={64} color="white" />
    </div>
    <h4>{video.title}</h4>
  </div>
);

const VideoPopup = ({ videoSrc, onClose }) => (
  <div className="video-popup">
    <div className="video-popup-content">
      <span className="close-button" onClick={onClose}>
        &times;
      </span>
      <video src={videoSrc} controls autoPlay className="popup-video" />
    </div>
  </div>
);

export const SignUp = (props) => {
  const [signupStep, setSignupStep] = useState(1);
  const [communities, setCommunities] = useState([]);
  const [hasCommunities, setHasCommunities] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState('');

  const loadCommunities = async () => {
    const res = await getUserCommunities();
    if (!res.error && res.communities.length) {
      setHasCommunities(true);
    }
  };

  const openPopup = (videoSrc) => {
    setCurrentVideo(videoSrc);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setCurrentVideo('');
  };

  useEffect(() => {
    if (props.signupStep) {
      setSignupStep(props.signupStep);
      loadCommunities();
    }
  }, []);

  return (
    <>
      <Header />
      <div className="SignUp">
        <div
          className="grid grid-cols-1 lg:grid-cols-2 gap-5 items-center h-full mt-20"
          // style={{ width: '70vw' }}
        >
          <div className="flex items-center justify-center flex-col">
            <h6 className="pt-10 w-full text-center text-lg font-medium text-gray-800">
              Customers Stories
            </h6>
            <h1 className="text-4xl font-bold text-gray-800 w-full text-center pt-2">
              What our partners are saying
            </h1>
            {/* Infinite scrolling cards */}
            <div className="infinite-carousel">
              <div className="infinite-carousel-wrapper">
                {videoData.map((video, index) => (
                  <VideoCard key={index} video={video} onPlay={openPopup} />
                ))}
              </div>
            </div>
          </div>

          <div
            className="flex flex-col justify-center"
            style={{ margin: '50px 0', paddingLeft: '20px',width : '450px',zIndex : 100,backgroundColor : 'white' }}
          >
            {signupStep === 1 && (
              <PersonalInfoSignup
                setSignupStep={setSignupStep}
                communities={communities}
                setCommunities={setCommunities}
              />
            )}
            {signupStep === 2 && (
              <AddCommunities
                setSignupStep={setSignupStep}
                communities={communities}
                setCommunities={setCommunities}
                hasCommunities={hasCommunities}
              />
            )}
            {signupStep === 3 && (
              <BillingSignup
                setSignupStep={setSignupStep}
                communities={communities}
                setCommunities={setCommunities}
              />
            )}
          </div>
        </div>
        {isPopupOpen && <VideoPopup videoSrc={currentVideo} onClose={closePopup} />}
      </div>
    </>
  );
};
