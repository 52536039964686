import { useEffect, useRef, useState } from 'react';
import { BiUser } from 'react-icons/bi';
import { BsGlobe2 } from 'react-icons/bs';
import {
  HiArrowRight,
  HiOutlineArrowLeft,
  HiOutlineArrowRight,
} from 'react-icons/hi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdClose, MdKeyboardArrowRight } from 'react-icons/md';
import { VscThreeBars } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import MagnetContext from '../../components/VideoMagnet/MagnetContext';
import VideoMagnetTheme from '../../components/VideoMagnet/theme';
import VideoMagnet from '../../components/VideoMagnet/VideoMagnet';
import { whitelabelName } from '../../utils/branding';
import { get } from '../../utils/request';
import GlobNavbar from '../GlobNavbar';
import VideoSidebar from './VideoSidebar';
const EMPTY_FUNC = () => {};

const TheCustomTour = () => {
  // Sidebar should not be open on mobile devices by default
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    /*() =>
    window?.innerHeight < 768 ? false : true*/
    true
  );

  // Get query parameters
  const query = new URLSearchParams(window.location.search);

  // Magnet uuid
  const magnet_id = query.get('magnet_uuid');
  // Template params
  const videosQueryParams = query.get('share')?.split(',');

  // Video template
  const [template, setTemplate] = useState();
  const [screens, setScreens] = useState();
  const [videosLength, setVideosLength] = useState([]);
  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
  const videoRef = useRef();
  const _links = screens ? screens.links : [];
  const links = _links
    ? _links?.map((link) => ({
        ...link,
        name: replaceUserInfo(link.name),
      }))
    : [];

  const [categoryKey, setCategoryKey] = useState('intro');
  const [screenKey, setScreenKey] = useState('main');
  useEffect(() => {
   setCategoryKey(screens?.[currentScreenIndex]?.categoryName)
   setScreenKey(screens?.[currentScreenIndex]?.screenName)
  },[currentScreenIndex])
  // useEffect(() => {
  //   if (screens) {
  //     setCategoryKey();
  //   }
  // }, [screens]);

  // useEffect(() => {
  //   setScreenKey(_screenKey);
  // }, [_screenKey]);
  const getNextVideo = ([category, screen]) => {
    getScreenByRoute(category, screen)?.video;
    setCategoryKey(category);
    setScreenKey(screen);
  };
  // Get video template from backend via GET request
  function getVideoTemplate() {
    get(`/magnets/template?magnet_uuid=${magnet_id}`, { auth: false })
      .then((data) => {
        const extractedTemplateData = data.magnet.magnet_details.template;
        setTemplate(extractedTemplateData);
        setScreens(() => {
          let tempScreens = videosQueryParams.map((videoParam) => {
            const videoParamSeparated = videoParam.split('.');
            const videoParamCategoryName = videoParamSeparated[0];
            const videoParamScreenName = videoParamSeparated[1];
            const extractedScreenData =
              extractedTemplateData?.categories[videoParamCategoryName]
                ?.screens[videoParamScreenName];
            if (extractedScreenData) return {...extractedScreenData,categoryName : videoParamCategoryName,screenName : videoParamScreenName};
          });
          return tempScreens;
        });
        setVideosLength(() => {
          let tempScreens = videosQueryParams.map((videoParam) => {
            const videoParamSeparated = videoParam.split('.');
            const videoParamCategoryName = videoParamSeparated[0];
            const videoParamScreenName = videoParamSeparated[1];
            const extractedScreenData =
              extractedTemplateData?.categories[videoParamCategoryName]
                ?.screens[videoParamScreenName];
            if (extractedScreenData) return extractedScreenData;
          });
          return tempScreens;
        });
      })
      .catch((error) => {
        console.log('error@getVideoTemplate: ', error.message);
      });
  }

  console.log('screens', screens);

  useEffect(() => {
    getVideoTemplate();
  }, []);

  //videoRef?.current?.play();

  document.onkeydown = checkKey;

  function checkKey(e) {
    e = e || window.event;

    if (e.keyCode == '38') {
      if (currentScreenIndex !== 0) {
        setCurrentScreenIndex(currentScreenIndex - 1);
      }
    } else if (e.keyCode == '40') {
      if (currentScreenIndex + 1 < videosLength?.length) {
        setCurrentScreenIndex(currentScreenIndex + 1);
      }
    } else if (e.keyCode == '37') {
      if (currentScreenIndex !== 0) {
        setCurrentScreenIndex(currentScreenIndex - 1);
      }
    } else if (e.keyCode == '39') {
      // right arrow
      if (currentScreenIndex + 1 < videosLength?.length) {
        setCurrentScreenIndex(currentScreenIndex + 1);
      }
    }
  }
  const [toggle, setToggle] = useState(false);
  const [tactive, setTactive] = useState(false);

  return (
    <div className=" h-full lg:h-screen overflow-scroll lg:overflow-hidden">
      <div className=" w-full bg-black  relative z-30">
        <div className="container relative">
          <div className=" items-center justify-between hidden lg:flex">
            <div className=" flex items-center">
              <Link to="/">
                <div>
                  <h1 className="font_uber py-2 text-2xl inline items-center cursor-pointer text-white">
                    {/* leasemag
              <img src={noto_magnet} className="w-4 mt-1 h-4" alt="" />
              ets */}
                    {whitelabelName()}
                  </h1>
                </div>
              </Link>
            </div>
            <div className=" flex items-center py-2 gap-4">
              <Link
                to="/"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BsGlobe2 /> EN
              </Link>
              <Link
                to="/signin"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BiUser /> Sign In
              </Link>
              <Link
                to="/signup"
                className=" flex items-center hover:bg-white font-semibold hover:text-black text-sm justify-center gap-1 bg-white font-light text-black py-2  px-2"
              >
                Sign Up
              </Link>
            </div>
          </div>
          {/* <div id="showheader" className=" absolute w-full  top-16 left-0">
            <GlobNavbar />
          </div> */}
          <div className=" items-center justify-between flex lg:hidden px-2 py-3">
            <div className=" flex items-center">
              <h1 className="font_uber text-2xl cursor-pointer text-white">
                {whitelabelName()}
              </h1>
            </div>
            <div className=" flex items-center gap-2">
              {screens && (
                <div
                  onClick={() => setIsSidebarOpen((prev) => !prev)}
                  className=" hover:opacity-70 cursor-pointer flex items-center rounded-3xl hover:bg-white hover:text-black text-xs justify-center gap-1 bg-white font-light text-black py-2  px-2"
                >
                  {screens[currentScreenIndex]?.title}
                </div>
              )}
              {!isSidebarOpen ? (
                <VscThreeBars
                  onClick={() => setIsSidebarOpen(true)}
                  className=" cursor-pointer text-white w-5 h-5"
                />
              ) : (
                <MdClose
                  onClick={() => setIsSidebarOpen(false)}
                  className=" cursor-pointer text-white w-5 h-5"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full  relative flex">
        {/* <input type="text" className=' hidden' onKeyDown={ handleKeyDown } /> */}
        {/* Button to open sidebar */}
        {/* <button
          className="md:hidden block shadow-sm pointer-cursor absolute bottom-5 left-5 w-10 h-10 rounded-full border"
        
        >
          <MdKeyboardArrowRight className="w-10 h-10" />
        </button> */}
        {/* VideoSidebar */}
        {isSidebarOpen && (
          <div className="absolute z-10 md:static md:w-52 w-full h-full">
            <VideoSidebar
              currentVideoIndex={currentScreenIndex}
              screensCollection={screens}
              onClose={() => setIsSidebarOpen(false)}
              onVideoChange={(currentVideoIndex) =>
                setCurrentScreenIndex(currentVideoIndex)
              }
            />
          </div>
        )}
        {/* The Actual Video Being Played */}
        <div className="flex-1 p-4 flex flex-col gap-2 items-center justify-center bg-white h-full">
          <p className="text-lg font-bold hidden sm:block">
            {screens?.[currentScreenIndex]?.title}
          </p>
          {screens && (
            <div
              style={{
                position: 'relative',
                height: '75vh',
                width: '95%',
              }}
            >
              <MagnetContext.Provider
                value={{
                  uuid: magnet_id,
                  currentCategory: categoryKey,
                  currentScreen: screenKey,
                  startCategory: categoryKey,
                  startScreen: screenKey,
                }}
              >
                <div style={VideoMagnetTheme}>
                  <VideoMagnet
                    show={true}
                    inline={true}
                    inlineCTA={undefined}
                    showLinksInline={false}
                    screen={{
                      ...screens[currentScreenIndex],
                      links: [],
                      screenId: `${categoryKey}.${screenKey}`,
                    }}
                    magnetSettings={template?.magnetSettings || {}}
                    getNextVideo={true ? EMPTY_FUNC : getNextVideo}
                    getNewPage={true ? EMPTY_FUNC : getNewPage}
                    startTour={EMPTY_FUNC}
                    restartTour={EMPTY_FUNC}
                    onClose={() => {}}
                    primaryColor={'black'}
                    links={[]}
                    title={''}
                    //locationCard={locationCard}
                  />
                </div>
              </MagnetContext.Provider>
            </div>
          )}
          <div className=" mt-4 w-full flex flex-wrap items-center gap-6 lg:justify-between lg:pr-6 pl-4">
            <div className="flex items-center gap-2 ">
              <div
                style={{
                  gridTemplateColumns: `repeat(${videosLength?.length}, 1fr)`,
                  height: '6px',
                }}
                className=" grid lg:w-96 w-60   bg-gray-200"
              >
                {videosLength.slice(0, currentScreenIndex + 1).map((item) => (
                  <div
                    style={{ height: '6px' }}
                    className=" w-full  bg-black"
                  ></div>
                ))}
              </div>
              <p className=" text-gray-700 text-sm">
                {currentScreenIndex + 1 + '/' + videosLength?.length}
              </p>
            </div>
            <div className="flex items-center gap-4">
              {currentScreenIndex !== 0 ? (
                <div
                  onClick={() => setCurrentScreenIndex(currentScreenIndex - 1)}
                  className=" rounded-2xl cursor-pointer  "
                >
                  <HiOutlineArrowLeft className=" w-6 h-6 cursor-pointer text-black " />
                </div>
              ) : (
                <div className="  rounded-2xl">
                  <HiOutlineArrowLeft className=" w-6 h-6  text-gray-500  " />
                </div>
              )}
              {currentScreenIndex + 1 < videosLength?.length ? (
                <div
                  onClick={() => setCurrentScreenIndex(currentScreenIndex + 1)}
                  className=" rounded-2xl cursor-pointer  "
                >
                  <HiOutlineArrowRight className=" w-6 h-6 cursor-pointer text-black " />
                </div>
              ) : (
                <div className="  rounded-2xl   ">
                  <HiOutlineArrowRight className=" w-6 h-6  text-gray-500 " />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheCustomTour;
