import React from 'react';

function CopyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 30 37"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.641"
        d="M9.863 4.91v18.87a3.145 3.145 0 003.145 3.144h12.58a3.144 3.144 0 003.144-3.145V10.01a3.145 3.145 0 00-.947-2.25l-5.212-5.097a3.145 3.145 0 00-2.198-.896h-7.367A3.145 3.145 0 009.863 4.91v0z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.641"
        d="M19.869 29.296v3.145a3.145 3.145 0 01-3.145 3.145H4.144A3.145 3.145 0 011 32.441V15.145A3.145 3.145 0 014.145 12H7.29"
      ></path>
    </svg>
  );
}

export default CopyIcon;
