import React from 'react';

function TargetIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 68 68"
    >
      <path
        fill="currentColor"
        fillOpacity="0.85"
        d="M34.02.225c4.025 0 7.883.712 11.457 2.012L41.482 6.23a28.423 28.423 0 1019.971 19.978l3.992-3.992a33.38 33.38 0 012.013 11.447c0 18.467-14.97 33.437-33.438 33.437C15.553 67.1.583 52.13.583 33.662S15.553.225 34.02.225zm0 13.379c1.694-.001 3.381.212 5.021.635v5.238a15.043 15.043 0 109.167 9.17h5.239a20.058 20.058 0 11-19.42-15.043h-.007zm6.686 20.058a6.686 6.686 0 11-4.068-6.155l5.75-5.75-.003-10.66c0-.665.265-1.303.735-1.773L51.478.967a2.507 2.507 0 014.279 1.772v9.193h9.193a2.507 2.507 0 011.772 4.28l-8.358 8.357c-.47.47-1.107.735-1.771.735H45.928l-5.75 5.75c.341.803.528 1.682.528 2.608zM55.553 20.29l3.343-3.343h-5.65a2.507 2.507 0 01-2.507-2.508V8.79l-3.34 3.344v7.96c.07.062.136.13.197.2h7.957v-.003z"
      ></path>
    </svg>
  );
}

export default TargetIcon;
