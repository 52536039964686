import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import './reusable.css';

export default function EditButton({
  label,
  placeholder,
  initialValue = '',
  setState,
  onCheck,
  onCancel,
  startEditable = false,
  setEditableState,
}) {
  const [addState, setAddState] = useState(startEditable);
  const [tempState, setTempState] = useState(initialValue);

  // React.useEffect(() => {
  //   console.log(initialValue, "XXXXXXXXX");
  //   console.log(sampleVal, "yyyyyyyyy");
  // }, [sampleVal])

  function handleCheckClick(e) {
    if (onCheck) onCheck();
    if (setState) setState(tempState);
    setAddState(false);
    if (setEditableState) setEditableState(false);
    e.stopPropagation();
  }

  function handleCancelClick(e) {
    if (onCancel) onCancel();
    if (setState) setState(initialValue);
    setAddState(false);
    if (setEditableState) setEditableState(false);
    e.stopPropagation();
  }

  return (
    <div key={initialValue} className="add-button">
      {!addState ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            setAddState(true);
            if (setEditableState) setEditableState(true);
          }}
        >
          {label ? label : '+ Add'}
        </button>
      ) : (
        <Input
          id="add-button-input"
          variant="outlined"
          placeholder={placeholder}
          value={tempState}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleCheckClick(e);
            }
          }}
          onChange={(e) => {
            e.stopPropagation();
            console.log('initVal: ', initialValue);
            setTempState(e.target.value);
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="checkmark button"
                onClick={handleCheckClick}
              >
                <CheckIcon style={{ color: '#2196f3' }} />
              </IconButton>
              <IconButton
                aria-label="checkmark button"
                onClick={handleCancelClick}
              >
                <CloseIcon style={{ color: '#f44336' }} />
              </IconButton>
            </InputAdornment>
          }
        />
      )}
    </div>
  );
}
