import { Button, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import notification from '../../components/Notification';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import SimpleAccordion from '../../components/SimpleAccordion';
import firebase from '../../utils/base';

const Reporting = (props) => {
  const { form_id } = props;
  const forms = require('./forms.json');
  const [email, setEmail] = useState('');
  const [reportingDetails, setReportingDetails] = useState({
    email: '',
    url: '',
    company: '',
    community: '',
    name: '',
  });
  const [primaryColor, setPrimaryColor] = useState('#302d2d');

  const [NotificationBar, setNotification] = notification();

  async function updateReporting() {
    // Firebase cheatsheet https://medium.com/@aaron_lu1/firebase-cloud-firestore-add-set-update-delete-get-data-6da566513b1b

    if (!reportingDetails.email || !reportingDetails.url || !reportingDetails) {
      console.log('ending ealry');
      setNotification("Warning you don't have an email ", 'warning');
      return false;
    }

    try {
      const reportingRef = firebase.db.collection('videoembed').doc(form_id);
      const doc = await firebase.db.collection('videoembed').doc(form_id).get();
      console.log('docdata', doc.data);
      if (doc) {
        console.log('leads field exists');
        // https://docs.microsoft.com/en-us/dotnet/api/system.datetime.tostring?view=netcore-3.1
        reportingRef.set(
          {
            reporting: reportingDetails,
          },
          { merge: true }
        );
      } else {
        console.log('leads field does not exists');
        reportingRef.set(
          {
            reporting: reportingDetails,
          },
          { merge: true }
        );
      }
      return true;
    } catch (err) {
      console.log('Failure getting leads document, check firebase key :', err);
      return false;
    }
  }

  const getReporting = async () => {
    console.log('fetching entrata integration');
    try {
      const doc = await firebase.db.collection('videoembed').doc(form_id).get();
      console.log('docdata', doc.data);
      if (doc.data().reporting) {
        setReportingDetails(doc.data().reporting);
      } else {
        console.log('reporting field does not exists');
      }
      return true;
    } catch (err) {
      console.log('Failure getting leads document, check firebase key :', err);
      return false;
    }
  };

  useEffect(() => {
    getReporting();
  }, []);

  return (
    <>
      <SimpleAccordion title="Team Reporting">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DebouncedTextField
              name="videoCTA"
              label="Lead Email"
              onChange={(newValue) =>
                setReportingDetails({
                  ...reportingDetails,
                  email: newValue,
                })
              }
              value={reportingDetails.email}
              required
            />
            <DebouncedTextField
              name="url"
              label="Website URL"
              onChange={(newValue) =>
                setReportingDetails({
                  ...reportingDetails,
                  url: newValue,
                })
              }
              value={reportingDetails.url}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <DebouncedTextField
              name="url"
              label="Property Manager"
              onChange={(newValue) =>
                setReportingDetails({
                  ...reportingDetails,
                  name: newValue,
                })
              }
              value={reportingDetails.name}
              required
            />
            <DebouncedTextField
              name="url"
              label="Company"
              onChange={(newValue) =>
                setReportingDetails({
                  ...reportingDetails,
                  company: newValue,
                })
              }
              value={reportingDetails.company}
              required
            />
            <DebouncedTextField
              name="url"
              label="Community"
              onChange={(newValue) =>
                setReportingDetails({
                  ...reportingDetails,
                  community: newValue,
                })
              }
              value={reportingDetails.community}
              required
            />
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                updateReporting().then((e) => {
                  console.log('bool vals', e);
                  if (e) {
                    setNotification(
                      'Updated Reporting email information successfully'
                    );
                  }
                });
              }}
            >
              {' '}
              Save Settings{' '}
            </Button>
            <NotificationBar />
          </Grid>
        </Grid>
      </SimpleAccordion>
    </>
  );
};

export default Reporting;
