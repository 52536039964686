import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import { jitsuClient } from '@jitsu/sdk-js';
import axios from 'axios';

function WriteGoogleReviewForward({}) {
  const aptBg =
    'https://thevicvillage.com/sites/thevicvillage.com/files/styles/width_1024/public/R02%20PERSPECTIVE_SUN%20TCN_LR_08.08.18_1.jpg?itok=faEgEWi-';
  const { community_id, place_id } = useParams();

  // const jitsu = jitsuClient({
  //   key: 'js.825kb6tm58yb9z4zv6exeg.y1p8defgphltc856xtn6bc',
  //   tracking_host: 'https://t.jitsu.com',
  //   randomize_url: true,
  // });

  const urlParams = new URLSearchParams(window.location.search);
  let u = urlParams.get('u', '');

  var data = JSON.stringify({
    community_id: community_id,
    details: {
      form_title: 'google_review_forward',
      team_member: u,
    },
    doc_encoding: 'UTF-8',
    event_type: 'form_view',
    place_id: place_id,
    url: window.location.href,
    page_title: 'Feedbackjoy.com TYG',
  });

  var config = {
    method: 'post',
    url: 'https://xc9dp0.deta.dev/jitsu_qr_code',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  useEffect(() => {
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        window.location.replace(
          `https://search.google.com/local/writereview?placeid=${place_id}`
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return <div></div>;
}

export default WriteGoogleReviewForward;
