import fetchRetry from 'fetch-retry';
import originalFetch from 'isomorphic-fetch';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import { API_HOST } from './request';

const fetch = fetchRetry(originalFetch, {
  retries: 3,
  retryDelay: 100,
});

const USER_ID_COOKIE_KEY = '_lm_user_id';
const USER_INFO_COOKIE_KEY = '_lm_user_info';
const VISIT_ID_COOKIE_KEY = '_lm_visit_id_tour_page';
const VISIT_ID_EXPIRATION_MS = 1 * 60 * 60 * 1000; // 1 hour

const query = new URLSearchParams(window.location.search);

const cookies = new Cookies();
const metadata = {};

export const setMagnetId = (magnetId) => {
  metadata.magnetId = magnetId;
  return magnetId;
};

export const getMagnetId = () => query.get('magnet_uuid');

export const setUserId = (userId) => {
  cookies.set(USER_ID_COOKIE_KEY, userId);
};

// Note that this is async and you need to use `await`!
export const getUserId = async () => {
  let userId = cookies.get(USER_ID_COOKIE_KEY);
  if (!userId) {
    // Get new visitor UUID from backend
    console.log('Creating new visitor');
    const res = await post('/visitor', {});
    if (res.visitor_uuid && !res.error) {
      console.log('Received new visitor UUID');
      userId = res.visitor_uuid; // New UUID generated on the backend
      setUserId(userId);
    }
    // Note that we shouldn't reset the visit ID here if we didn't have a visitor ID,
    // since if we have a valid visit ID we can get the visitor ID from that
  }
  return userId;
};

export const replaceUserInfo = (string) => {
  if (!string) return string;
  // Some text elements in template may contain elements to fill with user
  // data, e.g. Welcome{{, {name}|}}! Glad you could join us, {{{name}|friend}}
  // Left side of | is key to use, right is alternative if key isn't available
  // Just allowing one replacement per {{}} block right now
  let startReplacement;
  while ((startReplacement = string.indexOf('{{')) !== -1) {
    const separator = string.indexOf('|', startReplacement + 2);
    const stringWithKey = string.slice(startReplacement + 2, separator);

    const endReplacement = string.indexOf('}}', separator + 1);
    const alternative = string.slice(separator + 1, endReplacement);

    // Find key to replace
    const keyToReplaceStart = string.indexOf('{', startReplacement + 2);
    const keyToReplaceEnd = string.indexOf('}', keyToReplaceStart + 1);
    const keyToReplace = string.slice(keyToReplaceStart + 1, keyToReplaceEnd);

    const replacement =
      metadata?.userInfo[keyToReplace] && keyToReplaceEnd < separator
        ? stringWithKey.replace(
            '{' + keyToReplace + '}',
            metadata.userInfo[keyToReplace]
          )
        : alternative;

    string =
      string.slice(0, startReplacement) +
      replacement +
      string.slice(endReplacement + 2);
  }
  return string;
};

// Remove special characters (except -)
const replace = (str) =>
  str?.replace(/[`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi, '');

export const setUserInfo = (userInfo) => {
  userInfo.name = replace(userInfo.name);
  userInfo.phone = replace(userInfo.phone);

  metadata.userInfo = userInfo;
  if (userInfo.name) {
    [metadata.userInfo.firstName, metadata.userInfo.lastName] =
      metadata.userInfo.name.split(/ (.+)/);
  }
  cookies.set(USER_INFO_COOKIE_KEY, metadata.userInfo);
};

export const getUserInfo = () => metadata.userInfo;

// Initialize metadata.userInfo
const userInfo = cookies.get(USER_INFO_COOKIE_KEY);
setUserInfo(userInfo && userInfo !== 'undefined' ? userInfo : {});

const getVisitIdExpirationDate = () => {
  let expiry = new Date();
  // Add time until expiration (in milliseconds)
  expiry.setTime(expiry.getTime() + VISIT_ID_EXPIRATION_MS);
  return expiry;
};

export const setVisitId = (visitId) => {
  cookies.set(VISIT_ID_COOKIE_KEY, visitId, {
    expires: getVisitIdExpirationDate(),
  });
};

// Note that this is async and you need to use `await`!
export const getVisitId = async () => {
  let visitId = cookies.get(VISIT_ID_COOKIE_KEY);
  if (!visitId) {
    // Send new visit info to backend and receive new visit UUID
    console.log('Beginning new visit');
    const res = await post('/visit', {
      magnet_uuid: getMagnetId(),
      visitor_uuid: await getUserId(),
    });
    if (res.visit_uuid && !res.error) {
      visitId = res.visit_uuid; // New UUID generated on the backend
      console.log('Received new visit UUID');
    } else {
      console.log('Visit UUID failed', res);
    }
  }
  // Only update expiration time if we found UUID in cookies or successfully
  // fetched one from the backend
  if (visitId) {
    setVisitId(visitId); // Update visit ID cookie expiration time
  }
  return visitId;
};

const request = async (route, data, method = 'GET') => {
  const headers = new Headers();
  let json = null;

  if (method === 'POST') {
    headers.append('Content-Type', 'application/json');
  }

  try {
    let queryString = '';
    if (method === 'GET') {
      // Build query string for GET
      queryString +=
        '?' +
        Object.keys(data)
          .map((key) => key + '=' + data[key])
          .join('&');
    }

    const url = API_HOST + route + queryString;
    const res = await fetch(url, {
      method: method,
      headers: headers,
      // Only pass body for POST
      ...(method === 'POST' && { body: JSON.stringify(data) }),
      credentials: 'include',
    });

    try {
      const text = await res.text();
      json = JSON.parse(text);
    } catch (err) {
      json = { status: 'fail', error: `Request returned invalid JSON: ${err}` };
    }
  } catch (err) {
    json = { status: 'fail', error: `Failed to make request: ${err}` };
  }

  console.log(`Got JSON from ${route}: ${json.status}`);
  if (json.status == 'fail' || json.error) {
    console.log(json.error);
  }
  return json;
};

export const get = request;
export const post = (route, data) => request(route, data, 'POST');

export const formatTimestamp = (time) => {
  // time in seconds => "hours:minutes:seconds"
  // Output like "1:01" or "4:03:59" or "123:03:59"
  const hrs = ~~(time / 3600);
  const mins = ~~((time % 3600) / 60);
  const secs = ~~time % 60;

  let ret = '';
  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '');
  ret += '' + secs;
  return ret;
};

export function mergeNestedArray(arr) {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? mergeNestedArray(toFlatten) : toFlatten
    );
  }, []);
}
export const toastNotification = (message, type) => {
  toast(message, {
    position: 'top-right',
    type: type,
    autoClose: 5000,
  });
};
export function parseJsonPayloadHorizontally(dataArr = null) {
  return dataArr.map((data, i) => {
    let tempObj = {};
    Object.keys(data).map((k, idx) => {
      console.log('response jsonp: ', k, data[k]);
      try {
        let t1 = JSON.parse(data[k]);
        tempObj[k] = t1;
        return t1;
      } catch (e) {
        console.log(e);
      }
      tempObj[k] = data[k];
      return data[k];
    });
    console.log('response jsonfull: ', tempObj);
    return tempObj || {};
  });
}
