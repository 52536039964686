import { TextField, Button } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import React, { useState } from 'react';
import { v4 } from 'uuid';
import SelectSenderPhotoModal from '../../talk-and-triggers/SelectSenderPhotoModal';
import { FiUserPlus } from 'react-icons/fi';
import { supabase } from '../../../services/supabase';

const DEFAULT_REVIEW = {
  id: `db-${v4()}`,
  author_title: '',
  review_rating: 5,
  review_text: '',
  author_image: '',
};

export default function CreateReview({ setReviews, community_id }) {
  const [review, setReview] = useState(DEFAULT_REVIEW);
  const [showPhotoModal, setShowPhotoModal] = useState(false);

  // the new review inserted wont be sorted until sorting method changes but i think that's fine
  async function saveReview() {
    // add to local state
    review.community_id = community_id;
    setReviews((oldReviews) => [...oldReviews, review]);
    //save to supabase
    const { error } = await supabase.from('Reviews').insert(review);
    if (error) {
      console.error('Error adding review to supabase:', error);
    }
    // reset
    DEFAULT_REVIEW.id = `db-${v4()}`;
    setReview(DEFAULT_REVIEW);
  }

  return (
    <div className="flex flex-col">
      <h1 className="font-bold text-2xl mb-2 mr-5">Create Review</h1>
      <div className="rounded-xl border border-gray-300 flex flex-col gap-2 p-4 items-center">
        <div className="flex gap-2 items-center">
          <button
            onClick={() => {
              setShowPhotoModal(true);
            }}
            className={
              'p-2 cursor-pointer rounded-full bg-gray-200 text-gray-400 flex-shrink-0 flex-grow-0'
            }
            style={{
              width: '35px',
              height: '35px',
              ...(review.author_image
                ? {
                    backgroundImage: `url(${review.author_image})`,
                    backgroundSize: 'cover',
                  }
                : {}),
            }}
          >
            {!review.author_image && <FiUserPlus className="w-6 h-6" />}
          </button>
          <TextField
            value={review.author_title}
            onChange={(e) =>
              setReview({ ...review, author_title: e.target.value })
            }
            variant="outlined"
            label="Reviewer Name"
          />
        </div>
        <Rating
          value={review.review_rating}
          onChange={(_, newValue) =>
            setReview({ ...review, review_rating: newValue })
          }
        />
        <TextField
          value={review.review_text}
          onChange={(e) =>
            setReview({ ...review, review_text: e.target.value })
          }
          variant="outlined"
          label="Description"
          multiline
          minRows={4}
          className="w-full"
        />
        <Button variant="contained" onClick={saveReview}>
          Add Review
        </Button>
      </div>
      <SelectSenderPhotoModal
        open={showPhotoModal}
        setOpen={setShowPhotoModal}
        communityId={community_id}
        photoUrl={review.author_image}
        setPhotoUrl={(newUrl) => setReview({ ...review, author_image: newUrl })}
        senderName={review.author_title}
      />
    </div>
  );
}
