import ApexCharts from 'apexcharts';
import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';

export const SplineAreaChart = ({
  dataAndLabels,
  xAxisData,
  height,
  title,
  id = 'SplineAreaChart',
  toursOnly,
}) => {
  // https://apexcharts.com/javascript-chart-demos/area-charts/spline/

  useEffect(() => {
    if (toursOnly) {
      try {
        ApexCharts.exec(id, 'hideSeries', ['Views']);
        ApexCharts.exec(id, 'hideSeries', ['Leads']);
        ApexCharts.exec(id, 'hideSeries', ['Leases']);
      } catch (e) {}
    }
  }, []);

  return (
    <Chart
      options={{
        grid: {
          show: false,
        },
        chart: {
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          id: id,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: toursOnly ? 'category' : 'datetime',
          categories: xAxisData,
        },
        title: {
          text: title,
          align: 'left',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: '#263238',
          },
        },
      }}
      series={dataAndLabels}
      type="area"
      height={height || '100%'}
      width="100%"
    />
  );
};

export const BarChart = ({
  dataAndLabels,
  xAxisData,
  title,
  id = 'BarChart',
  toursOnly,
  height,
}) => {
  // https://apexcharts.com/javascript-chart-demos/column-charts/bar/
  useEffect(() => {
    if (toursOnly) {
      try {
        ApexCharts.exec(id, 'hideSeries', ['Views']);
        ApexCharts.exec(id, 'hideSeries', ['Leads']);
        ApexCharts.exec(id, 'hideSeries', ['Leases']);
      } catch (e) {}
    }
  }, []);

  return (
    <Chart
      options={{
        grid: {
          show: false,
        },
        chart: {
          type: 'bar',
          height: 350,
          stacked: false,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          id: id,
        },
        dataLabels: {
          enabled: false,
        },

        xaxis: {
          type: 'category',
          categories: xAxisData,
        },
        title: {
          text: title,
          align: 'left',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: '#263238',
          },
        },
      }}
      series={dataAndLabels}
      type="bar"
      height={height || '100%'}
      width="100%"
    />
  );
};

export const DonutChart = ({ labels, data, selected, title }) => {
  // https://apexcharts.com/javascript-chart-demos/
  return (
    <Chart
      options={{
        chart: {
          events: {
            dataPointSelection: function (event, chartContext, config) {
              selected(config?.selectedDataPoints);
            },
          },
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'gradient',
        },
        legend: {
          show: true,
          formatter: function (val, opts) {
            return val + ': ' + opts.w.globals.series[opts.seriesIndex];
          },
        },
        labels: labels,
        title: {
          text: title,
          align: 'left',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: '#263238',
          },
        },
      }}
      series={data}
      type="donut"
      height="312"
      width="100%"
    />
  );
};

export const ReverseBarChart = ({
  categories,
  data,
  size,
  title,
  customIcons,
}) => {
  return (
    <Chart
      options={{
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: customIcons,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: categories,
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        yaxis: {
          reversed: false,
          axisTicks: {
            show: false,
          },
        },
        title: {
          text: title,
          align: 'left',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: '#263238',
          },
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: false,
          },
          y: {
            show: false,
          },
        },
      }}
      series={[
        {
          name: '',
          data: data,
        },
      ]}
      type="bar"
      height={30 * size > 300 ? 300 + 15 * size : '300'}
      width="100%"
    />
  );
};
