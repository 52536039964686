import { Dialog } from '@material-ui/core';
import { useState } from 'react';

function RecordedVideoTitleDialog({ onClose, open }) {
  const [title, setTitle] = useState('');

  function handleClose() {
    onClose(title);
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: {
          width: '350px',
          height: '170px',
        },
      }}
    >
      <p className="text-base border-b p-2 text-center bg-gray-50">
        Add Title for New Video
      </p>
      <div className="flex flex-col gap-4 p-3">
        <input
          placeholder="Video Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="border border-2 p-2 rounded min-w-[200px]"
        />
        <button
          onClick={handleClose}
          className="px-3 py-1 rounded bg-blue-500 hover:bg-blue-600 active:bg-blue-700 text-white"
        >
          Save
        </button>
      </div>
    </Dialog>
  );
}

export default RecordedVideoTitleDialog;
