import { Button, FormControlLabel, Grid, Switch } from '@material-ui/core';
import axios from 'axios';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import calendly from '../../../assets/calendly.png';
import metaport from '../../../assets/metaport.png';
import youtube from '../../../assets/youtube.png';
import LoadingIndicator from '../../../components/LoadingIndicator';
import AnimatedDialog from '../../../components/Reusable/AnimatedDialog';
import DebouncedTextField from '../../../components/Reusable/DebouncedTextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { HOST, API_HOST, IFRAME_HOST } from '../../../utils/request';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: `100%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
import './Settings.css';
var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const iframeData = [
  {
    name: 'Promotions',
    src: `${IFRAME_HOST}/cta/floorplans/integration/0baf7485-4dde-4772-a14e-1d0fe0108561?type=inperson&timezone=EST&start=1&end=24&entratauser=leasemagnets@peakmade&entratapass=L3as3Magn3ts&entratapropertyId=639744`,
    desc: 'Easily generate a free contact us form',
  },
  {
    name: 'Scheduler',
    src: `${IFRAME_HOST}/cta/scheduler/integration`,
    desc: 'Easily generate a free scheduler via Tour',
  },
  {
    name: 'Contact Us',
    src: `${IFRAME_HOST}/cta/contactus/integration`,
    desc: 'Easily generate a free contact us form',
  },
  {
    name: 'Floor Plan Special & Scheduler',
    src: `${IFRAME_HOST}/cta/floorplans/integration`,
    desc: 'Easily generate a free floor plans scheduler and special offer',
  },
  {
    name: 'Photo Gallery',
    src: `${IFRAME_HOST}/cta/gallery/integration`,
    desc: 'Easily generate a photo gallery for prospects to view',
  },

  // {
  //   name: 'Nearby Businesses (Maps)',
  //   src: ' https://calendly.com/amulyaparmar/15min',
  //   desc: 'Showcase some businesses, transportation, restaurants etc that we can showcase.',
  // },
];

const start_timeframe = [
  { value: '2', title: '7:00 AM' },
  { value: '3', title: '7:30 AM' },
  { value: '4', title: '8:00 AM' },
  { value: '5', title: '8:30 AM' },
  { value: '6', title: '9:00 AM' },
  { value: '7', title: '9:30 AM' },
  { value: '8', title: '10:00 AM' },
  { value: '9', title: '10:30 AM' },
  { value: '10', title: '11:00 AM' },
  { value: '11', title: '11:30 AM' },
  { value: '12', title: '12:00 PM' },
  { value: '13', title: '12:30 PM' },
  { value: '14', title: '1:00 PM' },
  { value: '15', title: '1:30 PM' },
  { value: '16', title: '2:00 PM' },
];

const end_timeframe = [
  { value: '13', title: '12:30 PM' },
  { value: '14', title: '1:00 PM' },
  { value: '15', title: '1:30 PM' },
  { value: '16', title: '2:00 PM' },
  { value: '17', title: '2:30 PM' },
  { value: '18', title: '3:00 PM' },
  { value: '19', title: '3:30 PM' },
  { value: '20', title: '4:00 PM' },
  { value: '21', title: '4:30 PM' },
  { value: '22', title: '5:00 PM' },
  { value: '23', title: '5:30 PM' },
  { value: '24', title: '6:00 PM' },
  { value: '25', title: '6:30 PM' },
  { value: '26', title: '7:00 PM' },
  { value: '27', title: '7:30 PM' },
];

const IframeSettings = ({ iframeOptions, setIframeOptions }) => {
  const [open, setOpen] = useState(false);
  const [modalDetails, setModalDetails] = useState({});
  return (
    <Grid container spacing={1}>
      <AnimatedDialog
        title="Embed Matterport Videos"
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
      >
        <ModalForPreview
          modalDetails={modalDetails}
          iframeOptions={iframeOptions}
          setIframeOptions={setIframeOptions}
          setOpen={setOpen}
        />
      </AnimatedDialog>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Switch name="isIframe" />}
          style={{ fontSize: '12px' }}
          className="  text-gray-500"
          label="Enables users to add/insert various website pages/tools/applications within the virtual tour."
          checked={iframeOptions.enabled}
          onChange={(e) =>
            setIframeOptions({ ...iframeOptions, enabled: e.target.checked })
          }
        />
      </Grid>
      <div className=" w-full my-4">
        <Slider {...settings}>
          {iframeData.map((item) => (
            <div className=" w-full border p-3 rounded-xl">
              <div className=" flex items-center gap-2">
                {item.name === 'Youtube Video' && (
                  <img
                    src={youtube}
                    className=" w-8 h-8 object-contain"
                    alt=""
                  />
                )}
                {item.name === 'Matterport' && (
                  <img
                    src={metaport}
                    className=" w-8 h-8 object-contain"
                    alt=""
                  />
                )}
                {item.name === 'Calendly' && (
                  <img
                    src={calendly}
                    className=" w-8 h-8 object-contain"
                    alt=""
                  />
                )}
                <p>{item.name}</p>
              </div>
              <p className=" py-3 text-sm text-gray-500">{item.desc}</p>
              <div className=" flex items-center justify-end gap-3">
                {/* <button
                  onClick={() => {
                    setIframeOptions({
                      ...iframeOptions,
                      enabled: true,
                      src: item.src,
                    });
                  }}
                  className=" border px-2 py-2 text-sm text-gray-400 rounded-md"
                >
                  See Demo
                </button> */}
                <button
                  onClick={() => {
                    setModalDetails(item);
                    setOpen(true);
                  }}
                  className=" bg-pr px-2 py-2 text-sm text-white rounded-md"
                >
                  Setup
                </button>
              </div>
            </div>
          ))}
          <div className=" w-full border p-3 rounded-xl ">
            <div className=" flex items-center gap-2">
              <img src={metaport} className=" w-8 h-8 object-contain" alt="" />
              <p>Matterport</p>
            </div>
            <p className=" py-3 text-sm text-gray-500">
              Lorem its ipsum dolor sit amet, consectetur adipiscing elit, sed
            </p>
            <div className=" flex items-center justify-end gap-3">
              <button
                onClick={() => {
                  setIframeOptions({
                    ...iframeOptions,
                    enabled: true,
                    src: 'https://my.matterport.com/show/?m=FaevZd7HepL&play=1',
                  });
                }}
                className=" border px-2 py-2 text-sm text-gray-400 rounded-md"
              >
                See Demo
              </button>
              {/* <button
                onClick={() => setOpen(true)}
                className=" bg-pr px-2 py-2 text-sm text-white rounded-md"
              >
                Setup
              </button> */}
            </div>
          </div>
          <div className=" w-full border p-3 rounded-xl ">
            <div className=" flex items-center gap-2">
              <img src={calendly} className=" w-8 h-8 object-contain" alt="" />
              <p>Calendly</p>
            </div>
            <p className=" py-3 text-sm text-gray-500">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            </p>
            <div className=" flex items-center justify-end gap-3">
              <button
                onClick={() => {
                  setIframeOptions({
                    ...iframeOptions,
                    enabled: true,
                    src: 'https://calendly.com/amulyaparmar/15min',
                  });
                }}
                className=" border px-2 py-2 text-sm text-gray-400 rounded-md"
              >
                See Demo
              </button>
              {/* <button
                onClick={() => setOpen(true)}
                className=" bg-pr px-2 py-2 text-sm text-white rounded-md"
              >
                Setup
              </button> */}
            </div>
          </div>
        </Slider>
      </div>

      {iframeOptions && (
        <Grid item xs={12}>
          <div className="form-group flex flex-col">
            <div className="feild-title font s15 b6 c000">iFrame Src</div>
            <DebouncedTextField
              id="form-title"
              placeholder="e.g. https://www.youtube.com/embed/YPulKPClHWI"
              variant="outlined"
              className="text-gray-400"
              value={iframeOptions.src || ''}
              size="small"
              onChange={(newValue) =>
                setIframeOptions({ ...iframeOptions, src: newValue })
              }
              fullWidth
            />
          </div>
          <div className=" grid grid-cols-2 gap-5 w-full ">
            <div className="form-group flex flex-col">
              <div className="feild-title font s15 b6 c000">
                Background Color
              </div>

              {/* <input id="bg_color" name='bg_color' className=' hidden' type="color"  value={iframeOptions.background_color}   onChange={(newValue) =>
           setIframeOptions({
             ...iframeOptions,
             background_color: newValue,
           })
         } /> */}
              <DebouncedTextField
                id="form-text-color"
                name="form-text-color"
                type="color"
                variant="outlined"
                style={{ opacity: '0', width: '0', height: '0' }}
                value={iframeOptions.background_color}
                size="small"
                onChange={(newValue) =>
                  setIframeOptions({
                    ...iframeOptions,
                    background_color: newValue,
                  })
                }
                fullWidth
              />
              <label
                htmlFor="form-text-color"
                className=" w-full cursor-pointer p-2 border rounded-md flex items-center justify-between"
              >
                <div
                  style={{ background: iframeOptions.background_color }}
                  className=" w-5 h-5 rounded-full"
                ></div>
                <p className=" text-pr text-sm">Change color</p>
              </label>
            </div>

            <div className="form-group flex flex-col">
              <div className="feild-title font s15 b6 c000">Icon Color</div>
              <DebouncedTextField
                id="form-text-color-2"
                name="form-text-color-2"
                type="color"
                style={{ opacity: '0', width: '0', height: '0' }}
                variant="outlined"
                value={iframeOptions.icon_color}
                size="small"
                onChange={(newValue) =>
                  setIframeOptions({
                    ...iframeOptions,
                    icon_color: newValue,
                  })
                }
                fullWidth
              />
              <label
                htmlFor="form-text-color-2"
                className=" w-full cursor-pointer p-2 border rounded-md flex items-center justify-between"
              >
                <div
                  style={{ background: iframeOptions.icon_color }}
                  className=" w-5 h-5 rounded-full"
                ></div>
                <p className=" text-pr text-sm">Change color</p>
              </label>
            </div>
          </div>
          {/* <Button
            variant="outlined"
            onClick={() =>
              setIframeOptions({
                ...iframeOptions,
                src: 'https://www.youtube.com/embed/YPulKPClHWI',
              })
            }
          >
            YouTube
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              setIframeOptions({
                ...iframeOptions,
                src: 'https://my.matterport.com/show/?m=FaevZd7HepL&play=1',
              })
            }
          >
            Matterport
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              setIframeOptions({
                ...iframeOptions,
                src: 'https://calendly.com/amulyaparmar/15min',
              })
            }
          >
            Calendly
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              setIframeOptions({
                ...iframeOptions,
                src: 'https://calendly.com/amulyaparmar/15min',
              })
            }
          >
            External Video
          </Button> */}
        </Grid>
      )}
    </Grid>
  );
};

const ModalForPreview = ({
  modalDetails,
  iframeOptions,
  setIframeOptions,
  setOpen,
}) => {
  const [details, setDetails] = useState(true);
  const [startB, setStartB] = useState('2');
  const [endB, setEndB] = useState('13');
  const [saturdaystart, setsaturdaystart] = useState('2');
  const [saturdayend, setsaturdayend] = useState('13');
  const [sundaystart, setsundaystart] = useState('2');
  const [sundayend, setsundayend] = useState('13');
  const [timeZone, setTimeZone] = useState('');
  const { community_id } = useParams();
  const { magnetId } = useSelector((state) => state.magnetStates);
  const { magnetData } = useSelector((state) => state.getMagnet);
  const [iframeSrc, setIframeSrc] = useState('');

  console.log('loadEntrataFromMagnet', magnetData);

  const [values, setValues] = useState([]);
  console.log('values***', values);

  const [entrataCheck, setEntrataCheck] = useState(false);
  const [saturdaystatus, setsaturdaystatus] = useState(false);
  const [sundaystatus, setsundaystatus] = useState(false);
  const [apiUsername, setApiUsername] = useState('');
  const [apiPassword, setApiPassword] = useState('');
  const [propertyId, setPropertyId] = useState('');
  const [entratachecloading, setentratachecloading] = useState(false);
  const [isenratatavalid, setisenratatavalid] = useState('');
  const [contactUsMsgParam, setContactUsMsgParam] = useState('');

  async function loadEntrataFromMagnet() {
    const api_entrata =
      magnetData?.['magnets']?.[0]?.['integration_details']?.['api-entrata'];
    if (api_entrata) {
      setApiUsername(api_entrata?.username);
      setApiPassword(api_entrata?.password);
      setPropertyId(api_entrata?.property_id);
      setEntrataCheck(true);
    }
  }

  useEffect(() => {
    setIframeSrc(`${modalDetails?.src}/${magnetId}`);
    // setApiUsername('');
    // setApiPassword('');
    // setPropertyId('');
    // setEntrataCheck(false);
    const urlParams = new URLSearchParams(iframeOptions?.src);
    const entratauser = urlParams.get('entratauser');
    const entratapass = urlParams.get('entratapass');
    const entratapropertyId = urlParams.get('entratapropertyId');

    if (entratauser || entratapass || entratapropertyId) {
      setApiUsername(entratauser);
      setApiPassword(entratapass);
      setPropertyId(entratapropertyId);
      setEntrataCheck(true);
    } else {
      setEntrataCheck(false);
    }
    if (!entratauser || !entratapass || !entratapropertyId) {
      loadEntrataFromMagnet();
    }
  }, [modalDetails]);

  const check_entrata_validation = async ({
    entratauser,
    entratapass,
    entratapropertyId,
  }) => {
    setentratachecloading(true);
    setisenratatavalid('');
    try {
      const res = await axios.post(
        `${API_HOST}/integrations/entrata/getPropertyinfo`,
        {
          creds: {
            username: entratauser,
            password: entratapass,
          },
          propertyId: entratapropertyId,
        }
      );
      console.log('special_res', res);
      if (res?.data?.resp?.code == 200) {
        setisenratatavalid('valid');
        setentratachecloading(false);
      } else {
        setisenratatavalid('invalid');
        setentratachecloading(false);
      }
    } catch (error) {
      setisenratatavalid('invalid');
      setentratachecloading(false);
      console.log('invaliddd', error);
    }
  };

  const add_disabled_dates = () => {
    let str = '';
    if (values.length > 0) {
      str = '&disableddates=';
      values.map((item) => {
        str = `${str}` + `${item?.format()},`;
      });
    }
    return str;
  };

  const weekendcheck = () => {
    let str = '';
    if (sundaystatus) {
      str =
        str +
        `&sundaystatus=enabled&sundaystart=${sundaystart}&sundayend=${sundayend}`;
    } else {
      str = str + `&sundaystatus=disabled`;
    }
    if (saturdaystatus) {
      str =
        str +
        `&saturdaystatus=enabled&saturdaystart=${saturdaystart}&saturdayend=${saturdayend}`;
    } else {
      str = str + `&saturdaystatus=disabled`;
    }
    return str;
  };

  const scedulerFormSubmit = () => {
    console.log('strrr**', add_disabled_dates());
    if (entrataCheck) {
      if (apiUsername !== '' && apiPassword !== '' && propertyId !== '') {
        setIframeOptions({
          ...iframeOptions,
          src: `${IFRAME_HOST}/cta/scheduler/integration/${magnetId}?timezone=${timeZone}&start=${startB}&end=${endB}&entratauser=${apiUsername}&entratapass=${apiPassword}&entratapropertyId=${propertyId}&hours_updated=true${weekendcheck()}${add_disabled_dates()}`,
          enabled: true,
        });
        setOpen(false);
      }
    } else {
      setIframeOptions({
        ...iframeOptions,
        src: `${IFRAME_HOST}/cta/scheduler/integration/${magnetId}?timezone=${timeZone}&start=${startB}&end=${endB}&hours_updated=true${weekendcheck()}${add_disabled_dates()}`,
        enabled: true,
      });
      setOpen(false);
    }
  };

  const floorPlanPickerFormSubmit = () => {
    if (entrataCheck) {
      if (apiUsername !== '' && apiPassword !== '' && propertyId !== '') {
        setIframeOptions({
          ...iframeOptions,
          src: `${IFRAME_HOST}/cta/floorplans/integration/${magnetId}?timezone=${timeZone}&start=${startB}&end=${endB}&entratauser=${apiUsername}&entratapass=${apiPassword}&entratapropertyId=${propertyId}&bedrooms=${bedrooms.join(
            ','
          )}
          &bathrooms=${bathrooms.join(',')}&bedrooms=${bedrooms.join(
            ','
          )}&InterestedIn=${InterestedIn.join(
            ','
          )}&desiredMoveInSelector=${desiredMoveInSelector.join(',')}
          `,
          enabled: true,
        });
        setOpen(false);
      }
    } else {
      setIframeOptions({
        ...iframeOptions,
        src: `${IFRAME_HOST}/cta/floorplans/integration/${magnetId}?timezone=${timeZone}&start=${startB}&end=${endB}&bedrooms=${bedrooms.join(
          ','
        )}
        &bathrooms=${bathrooms.join(',')}&bedrooms=${bedrooms.join(
          ','
        )}&InterestedIn=${InterestedIn.join(
          ','
        )}&desiredMoveInSelector=${desiredMoveInSelector.join(',')}
        `,
        enabled: true,
      });
      setOpen(false);
    }
  };

  const contactFormSubmit = () => {
    setIframeOptions({
      ...iframeOptions,
      src: `${IFRAME_HOST}/cta/contactus/integration/${magnetId}?msg=${contactUsMsgParam}`,
      enabled: true,
    });
    setOpen(false);
  };
  const [pullSpecial, setPullSpecial] = useState(false);
  const promotionFormSubmit = () => {
    if (entrataCheck) {
      if (apiUsername !== '' && apiPassword !== '' && propertyId !== '') {
        setIframeOptions({
          ...iframeOptions,
          src: `${IFRAME_HOST}/cta/promotions/integration/${magnetId}?pullSpecialFromTriggers=${pullSpecial}&entratauser=${apiUsername}&entratapass=${apiPassword}&entratapropertyId=${propertyId}`,
          enabled: true,
        });
        setOpen(false);
      }
    } else {
      console.log('hiiiiiiiiiiii');
      setIframeOptions({
        ...iframeOptions,
        src: `${IFRAME_HOST}/cta/promotions/integration/${magnetId}?pullSpecialFromTriggers=${pullSpecial}`,
        enabled: true,
      });

      setOpen(false);
    }
  };

  const photoGallerySubmit = () => {
    setIframeOptions({
      ...iframeOptions,
      src: `${IFRAME_HOST}/cta/gallery/integration/${magnetId}`,
      enabled: true,
    });
    setOpen(false);
  };

  const [bedrooms, setbedrooms] = useState(['Any', '1 Bed']);
  const [bathrooms, setbathrooms] = useState(['Any', '1 Bath']);
  const [InterestedIn, setInterestedIn] = useState([
    'Roommate Matching',
    'Fitness Room',
    'Student Events',
    'life Style',
  ]);
  const [selectedDates, setselectedDates] = useState({
    dateFrom: '',
    dateTo: '',
  });
  const [desiredMoveInSelector, setdesiredMoveInSelector] = useState([]);
  console.log('iframeOptions', iframeOptions);
  console.log('selectedDates', selectedDates);
  console.log('desiredMoveInSelector', desiredMoveInSelector);
  const classes = useStyles();
  const [selectedoption, setSelectedoption] = useState(1);

  function CustomMultipleInput({ onFocus, value }) {
    return (
      <input
        onFocus={onFocus}
        value={value}
        className=" text-sm mt-2 w-full py-2 px-2 border rounded-md outline-none bg-transparent"
        readOnly
        style={{ width: '300px' }}
      />
    );
  }

  return (
    <div className=" w-full  bg-white p-3">
      <h1 className=" text-md text-gray-700">{modalDetails?.name}</h1>
      <div className=" mb-2 mt-4 bg-blue-100 w-full  flex items-center gap-4 px-4">
        <p
          onClick={() => setDetails(true)}
          className={
            details
              ? 'text-black text-sm py-2 border-b-2 border-black cursor-pointer'
              : 'text-gray-500 cursor-pointer text-sm py-2'
          }
        >
          Details
        </p>
        <p
          onClick={() => setDetails(false)}
          className={
            !details
              ? 'text-black text-sm py-2 border-b-2 border-black cursor-pointer'
              : 'text-gray-500 cursor-pointer text-sm py-2'
          }
        >
          Tutorials
        </p>
      </div>
      <div className=" pt-2 grid gap-8 grid-cols-1 lg:grid-cols-11 w-full">
        <div className=" lg:col-span-5">
          <iframe
            width="100%"
            height="455"
            src={iframeSrc}
            title="YouTube video player"
            frameborder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen={true}
          />
        </div>
        {modalDetails?.name === 'Scheduler' && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              scedulerFormSubmit();
            }}
            className=" lg:col-span-6"
          >
            <h1 className=" text-3xl font-semibold">Tour</h1>
            <p className=" text-sm font-medium">Basic Settings</p>

            <select
              className=" mt-1 w-full p-2 rounded-md border text-gray-500 text-sm"
              name=""
              id=""
              required
              onChange={(e) => setTimeZone(e.target.value)}
            >
              <option>SELECT TIME ZONE</option>
              <option className="EST">EST</option>
              <option className="CST">CST</option>
              <option className="PST">PST</option>
              <option className="PST">MST</option>
            </select>

            <div className=" grid grid-cols-2 gap-4 mt-2">
              <div>
                <p className=" text-sm">Office Start</p>
                <select
                  onChange={(e) => setStartB(e.target.value)}
                  name=""
                  id=""
                  className=" border p-2 rounded-md w-full"
                  value={startB}
                >
                  {start_timeframe?.map((item, index) => {
                    return <option value={item?.value}>{item?.title}</option>;
                  })}
                </select>
              </div>
              <div>
                <p className=" text-sm">Office End</p>
                <select
                  onChange={(e) => setEndB(e.target.value)}
                  name=""
                  id=""
                  value={endB}
                  className=" border p-2 rounded-md w-full"
                >
                  {end_timeframe?.map((item, index) => {
                    return <option value={item?.value}>{item?.title}</option>;
                  })}
                </select>
              </div>
            </div>

            <div
              className="flex items-center gap-2 py-3"
              style={{ alignItems: 'end' }}
            >
              <Switch
                checked={saturdaystatus}
                name="isFormTemplateEnabled"
                onChange={(e) => {
                  setsaturdaystatus(e.target.checked);
                }}
              />
              <div
                className=" grid grid-cols-2 gap-4 mt-2"
                style={{ width: '-webkit-fill-available' }}
              >
                <div>
                  <p className=" text-sm">Saturday Start</p>
                  <select
                    onChange={(e) => setsaturdaystart(e.target.value)}
                    name=""
                    id=""
                    className=" border p-2 rounded-md w-full"
                  >
                    {start_timeframe?.map((item, index) => {
                      return <option value={item?.value}>{item?.title}</option>;
                    })}
                  </select>
                </div>
                <div>
                  <p className=" text-sm">Saturday End</p>
                  <select
                    onChange={(e) => setsaturdayend(e.target.value)}
                    name=""
                    id=""
                    className=" border p-2 rounded-md w-full"
                  >
                    {end_timeframe?.map((item, index) => {
                      return <option value={item?.value}>{item?.title}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div
              className="flex items-center gap-2 py-3"
              style={{ alignItems: 'end' }}
            >
              <Switch
                checked={sundaystatus}
                name="isFormTemplateEnabled"
                onChange={(e) => {
                  setsundaystatus(e.target.checked);
                }}
              />
              <div
                className=" grid grid-cols-2 gap-4 mt-2"
                style={{ width: '-webkit-fill-available' }}
              >
                <div>
                  <p className=" text-sm">Sunday Start</p>
                  <select
                    onChange={(e) => setsundaystart(e.target.value)}
                    name=""
                    id=""
                    className=" border p-2 rounded-md w-full"
                  >
                    {start_timeframe?.map((item, index) => {
                      return <option value={item?.value}>{item?.title}</option>;
                    })}
                  </select>
                </div>
                <div>
                  <p className=" text-sm">Sunday End</p>
                  <select
                    onChange={(e) => setsundayend(e.target.value)}
                    name=""
                    id=""
                    className=" border p-2 rounded-md w-full"
                  >
                    {end_timeframe?.map((item, index) => {
                      return <option value={item?.value}>{item?.title}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className=" flex">
              <div>
                <p className="text-sm font-medium">
                  Add Dates to be disabled in scheduler :{' '}
                </p>
                <DatePicker
                  multiple
                  value={values}
                  onChange={setValues}
                  render={<CustomMultipleInput />}
                  plugins={[<DatePanel />]}
                />
              </div>
            </div>

            <img src="/entrata.png" className=" mt-4" alt="" />
            <div className=" w-full flex items-center gap-2 py-3">
              <input
                type="checkbox"
                onChange={(e) => setEntrataCheck(e.target.checked)}
                value={entrataCheck}
                className=" w-4 h-4"
                name="entrata_checkbox"
                id="entrata_checkbox"
              />
              <label htmlFor="entrata_checkbox" className="text-sm">
                Pull scheduling availability from Entrata as well
              </label>
            </div>
            {entrataCheck && (
              <>
                <input
                  type="text"
                  required
                  value={apiUsername}
                  onChange={(e) => setApiUsername(e.target.value)}
                  placeholder="Entrata API Username"
                  className=" text-sm w-full py-2 px-2 border rounded-md outline-none bg-transparent"
                />
                <input
                  type="text"
                  required
                  value={apiPassword}
                  onChange={(e) => setApiPassword(e.target.value)}
                  placeholder="Entrata API Password"
                  className=" text-sm mt-2 w-full py-2 px-2 border rounded-md outline-none bg-transparent"
                />
                <input
                  type="text"
                  required
                  value={propertyId}
                  onChange={(e) => setPropertyId(e.target.value)}
                  placeholder="Property ID"
                  className=" text-sm mt-2 w-full py-2 px-2 border rounded-md outline-none bg-transparent"
                />
                <Button
                  onClick={() =>
                    check_entrata_validation({
                      entratapropertyId: propertyId,
                      entratauser: apiUsername,
                      entratapass: apiPassword,
                    })
                  }
                >
                  {entratachecloading
                    ? 'Checking...  '
                    : 'Test Entrata Integration'}
                  {entratachecloading && (
                    <div className=" w-full">
                      <LoadingIndicator />
                    </div>
                  )}
                </Button>
              </>
            )}
            {isenratatavalid == 'valid' && 'Valid Entrata Credentials'}
            {isenratatavalid == 'invalid' && 'InValid Entrata Credentials '}
            <p className=" py-2 text-sm italic">
              Note: getCalendarAvailability endpoint needs to be enabled for API
              user & all leads will be delivered to any of the lead followup
              settings set
            </p>
            <button
              type="submit"
              className=" bg-pr px-3 py-2 mt-4 rounded-md text-white text-sm"
            >
              Save
            </button>
          </form>
        )}
        {modalDetails?.name === 'Contact Us' && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              contactFormSubmit();
            }}
            className=" lg:col-span-6"
          >
            <div className=" w-full flex items-center gap-2 py-3">
              <div>
                <p className=" py-2 text-sm italic">
                  This is a traditional contact us form that will send lead data
                  and questions to your lead tab. <br /> We However, you can
                  also preface any such lead question with a default message
                  here as well.
                </p>
              </div>
              <input
                type="text"
                required
                value={contactUsMsgParam}
                onChange={(e) => setContactUsMsgParam(e.target.value)}
                placeholder="Default contact us question text"
                className=" text-sm w-full py-2 px-2 border rounded-md outline-none bg-transparent"
              />
            </div>

            <button
              type="submit"
              className=" bg-pr px-3 py-2 mt-4 rounded-md text-white text-sm"
            >
              Save
            </button>
          </form>
        )}
        {modalDetails?.name === 'Promotions' && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              promotionFormSubmit();
            }}
            className=" lg:col-span-6"
          >
            <h1 className=" text-3xl font-semibold">Tour</h1>
            <p className=" text-sm font-medium">Basic Settings</p>
            <div className=" w-full flex items-center gap-2 py-3">
              <input
                type="checkbox"
                onChange={(e) => setPullSpecial(e.target.checked)}
                className=" w-4 h-4"
                name="specials_checkbox"
                id="specials_checkbox"
              />
              <label htmlFor="specials_checkbox" className="text-sm">
                Pull special offer data from triggers/specials
              </label>
            </div>
            {pullSpecial ? (
              <div className=" w-full bg-black rounded-md p-2">
                <p className=" text-white">
                  🔥 $250 off 2 Bedroom Application waiver
                </p>
                <p className=" text-sm text-white">expires Sept 23</p>
              </div>
            ) : (
              <div className=" w-full border rounded-md p-2">
                <p className=" text-gray-400">
                  🔥 $250 off 2 Bedroom Application waiver
                </p>
                <p className=" text-sm text-gray-400">expires Sept 23</p>
              </div>
            )}

            <img src="/entrata.png" className=" mt-4" alt="" />
            <div className=" w-full flex items-center gap-2 py-3">
              <input
                type="checkbox"
                onChange={(e) => setEntrataCheck(e.target.checked)}
                value={entrataCheck}
                className=" w-4 h-4"
                name="entrata_checkbox"
                id="entrata_checkbox"
              />
              <label htmlFor="entrata_checkbox" className="text-sm">
                Connect specials from Entrata as well
              </label>
            </div>
            {entrataCheck && (
              <>
                <input
                  type="text"
                  required
                  value={apiUsername}
                  onChange={(e) => setApiUsername(e.target.value)}
                  placeholder="Entrata API Username"
                  className=" text-sm w-full py-2 px-2 border rounded-md outline-none bg-transparent"
                />
                <input
                  type="text"
                  required
                  value={apiPassword}
                  onChange={(e) => setApiPassword(e.target.value)}
                  placeholder="Entrata API Password"
                  className=" text-sm mt-2 w-full py-2 px-2 border rounded-md outline-none bg-transparent"
                />
                <input
                  type="text"
                  required
                  value={propertyId}
                  onChange={(e) => setPropertyId(e.target.value)}
                  placeholder="Property ID"
                  className=" text-sm mt-2 w-full py-2 px-2 border rounded-md outline-none bg-transparent"
                />
              </>
            )}
            <p className=" py-2 text-sm italic">
              Note: getSpecials endpoint needs to be enabled for API user
            </p>
            <button
              type="submit"
              className=" bg-pr px-3 py-2 mt-4 rounded-md text-white text-sm"
            >
              Save
            </button>
          </form>
        )}
        {modalDetails?.name === 'Floor Plan Special & Scheduler' && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              floorPlanPickerFormSubmit();
            }}
            className=" lg:col-span-6"
          >
            <p className=" text-sm pb-1">
              How many bedrooms are available in your floor plans?
            </p>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={bedrooms.map((option) => option)}
              defaultValue={bedrooms}
              freeSolo
              onChange={(event, newValue) => {
                console.log('e', newValue);
                setbedrooms(newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Add a bedroom option"
                />
              )}
            />
            <p className=" text-sm pb-1 pt-3">
              How many bathroom are available in your floor plans?
            </p>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={bathrooms.map((option) => option)}
              defaultValue={bathrooms}
              freeSolo
              onChange={(event, newValue) => {
                console.log('e', newValue);
                setbathrooms(newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Add a bedroom option"
                />
              )}
            />
            <p className=" text-sm pb-1 pt-3">Are you interested in?</p>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={InterestedIn.map((option) => option)}
              defaultValue={InterestedIn}
              freeSolo
              onChange={(event, newValue) => {
                console.log('e', newValue);
                setInterestedIn(newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Add a bedroom option"
                />
              )}
            />{' '}
            <p className=" text-sm pb-1 pt-3">Select Move-in date method:</p>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedoption}
                onChange={(e) => setSelectedoption(e.target.value)}
              >
                <MenuItem value={1}>Calendar</MenuItem>
                <MenuItem value={2}>Availability dates</MenuItem>
              </Select>
            </FormControl>
            {selectedoption === 2 && (
              <>
                <div className=" mt-3 flex items-center gap-4">
                  <div>
                    <p className=" text-xs">Date from</p>
                    <input
                      className=" px-2 py-2 border rounded-md"
                      type="date"
                      name=""
                      id=""
                      onChange={(newValue) =>
                        setselectedDates({
                          ...selectedDates,
                          dateFrom: new Date(newValue.target.value).getTime(),
                        })
                      }
                    />
                  </div>

                  <div>
                    <p className=" text-xs">Date to</p>
                    <input
                      className=" px-2 py-2 border rounded-md"
                      type="date"
                      name=""
                      id=""
                      onChange={(newValue) =>
                        setselectedDates({
                          ...selectedDates,
                          dateTo: new Date(newValue.target.value).getTime(),
                        })
                      }
                    />
                  </div>
                  <div
                    //type="submit"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setdesiredMoveInSelector([
                        ...desiredMoveInSelector,
                        `${moment(selectedDates?.dateFrom).format(
                          'L'
                        )}-${moment(selectedDates?.dateTo).format('L')}`,
                      ]);
                    }}
                    className=" bg-pr px-3 py-2 mt-4 rounded-md text-white text-sm"
                  >
                    Add
                  </div>
                </div>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={desiredMoveInSelector.map((option) => option)}
                  //defaultValue={desiredMoveInSelector}
                  freeSolo
                  onChange={(event, newValue) => {
                    console.log('e', newValue);
                    setdesiredMoveInSelector(newValue);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Add a bedroom option"
                    />
                  )}
                />
              </>
            )}
            <img src="/entrata.png" className=" mt-4" alt="" />
            <div className=" w-full flex items-center gap-2 py-3">
              <input
                type="checkbox"
                value={entrataCheck}
                onChange={(e) => setEntrataCheck(e.target.checked)}
                className=" w-4 h-4"
                name="entrata_checkbox"
                id="entrata_checkbox"
              />
              <label htmlFor="entrata_checkbox" className="text-sm">
                Pull floor plan data from entrata
              </label>
            </div>
            {entrataCheck && (
              <>
                <input
                  type="text"
                  required
                  value={apiUsername}
                  onChange={(e) => setApiUsername(e.target.value)}
                  placeholder="Entrata API Username"
                  className=" text-sm w-full py-2 px-2 border rounded-md outline-none bg-transparent"
                />
                <input
                  type="text"
                  required
                  value={apiPassword}
                  onChange={(e) => setApiPassword(e.target.value)}
                  placeholder="Entrata API Password"
                  className=" text-sm mt-2 w-full py-2 px-2 border rounded-md outline-none bg-transparent"
                />
                <input
                  type="text"
                  required
                  value={propertyId}
                  onChange={(e) => setPropertyId(e.target.value)}
                  placeholder="Property ID"
                  className=" text-sm mt-2 w-full py-2 px-2 border rounded-md outline-none bg-transparent"
                />
              </>
            )}
            <p className=" py-2 text-sm italic">
              Note: getFloorPlans endpoint must be enabled for API user
            </p>
            <button
              type="submit"
              className=" bg-pr px-3 py-2 mt-4 rounded-md text-white text-sm"
            >
              Save
            </button>
          </form>
        )}
        {modalDetails?.name === 'Photo Gallery' && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              photoGallerySubmit();
            }}
            className=" lg:col-span-6"
          >
            <div className=" w-full flex items-center gap-2 py-3">
              <div>
                <p className=" py-2 text-sm italic">
                  This is a photo gallery that allows prospects to view photos
                  you've uploaded in the Customize tab of your Tour.
                </p>
              </div>
            </div>

            <button
              type="submit"
              className=" bg-pr px-3 py-2 mt-4 rounded-md text-white text-sm"
            >
              Save
            </button>
          </form>
        )}
      </div>
    </div>
  );
};
export default memo(IframeSettings);
