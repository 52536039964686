import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from 'moment';
import React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
// RewardsTable Table
function RewardsTable({
  pendingcheck,
  page,
  rewardList,
  fundingrequestlist,
  fundedlist,
}) {
  console.log('fundingrequestlist', fundingrequestlist);
  // Only display the current page of reviews (10 per page)
  function displayTen() {
    const ten = rewardList.filter(
      (review, idx) => idx >= 10 * (page - 1) && idx < 10 * page
    );
    console.log(ten);
    return ten;
  }

  return (
    <div className="reward-table">
      <div className="table">
        <div className={`table-hdr flex aic ${pendingcheck}`}>
          <div className="column">
            <div className="label font">Amount</div>
          </div>
          <div className="column">
            <div className="label font">Date</div>
          </div>
          <div className="column">
            <div className="label font">Receipt</div>
          </div>
          {/* <div className="column">
                <div className="label font">Comments</div>
              </div> */}
        </div>
        {pendingcheck == 'Pending' &&
          fundingrequestlist?.map((item) => (
            <div style={{ display: 'flex' }} className="table-row aic">
              <div className="column">
                <div className="txt font">{JSON.parse(item.data).amount}</div>
              </div>
              <div className="column">
                <div className="txt font">
                  {moment(JSON.parse(item.data).funding_request_date).format(
                    'MMMM Do YYYY, h:mm:ss a'
                  )}
                </div>
              </div>
              <div className="column">
                <a href={JSON.parse(item.data).receipt_url} target="_blank">
                  {' '}
                  <OpenInNewIcon />{' '}
                </a>
              </div>
            </div>
          ))}
        {pendingcheck == 'Completed' &&
          fundedlist?.map((item) => (
            <div style={{ display: 'flex' }} className="table-row aic">
              <div className="column">
                <div className="txt font">{JSON.parse(item.data).amount}</div>
              </div>
              <div className="column">
                <div className="txt font">
                  {moment(JSON.parse(item.data).funding_request_date).format(
                    'MMMM Do YYYY, h:mm:ss a'
                  )}
                </div>
              </div>
              <div className="column">
                <a href={JSON.parse(item.data).receipt_url} target="_blank">
                  {' '}
                  <OpenInNewIcon />{' '}
                </a>
              </div>
            </div>
          ))}

        {/* {reviews.length === 0 && <NoRows />} */}
      </div>
    </div>
  );
}

export default RewardsTable;
