import { useEffect, useState } from 'react';
import { JStringify, JParse } from '../utils/helpers';

/**
 *
 * @param {string} key the key that will be associated with data in local storage
 * @param {any} initialValue this initialValue will be used if there is no key or data associated with the key is undefined in local storage
 * @param {boolean} isJson set to true if data is of type object
 * @returns
 */
const useLocalStorage = (key, initialValue, isJson = true) => {
  function getInitialValue() {
    const localVal = localStorage.getItem(key);

    if (isJson) {
      if (
        typeof localVal === 'string' &&
        localVal.length > 0 &&
        localVal !== 'undefined'
      ) {
        return JParse(localVal);
      } else {
        return initialValue;
      }
    }

    return localVal ? localVal : initialValue;
  }

  const [storage, setStorage] = useState(getInitialValue());

  useEffect(() => {
    const val = isJson ? JStringify(storage) : storage;
    localStorage.setItem(key, val);
  }, [storage]);

  return [storage, setStorage];
};

export default useLocalStorage;
