import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from '../../../components/LoadingIndicator';
import ContactUs from '../ContactUs/ContactUs';
import Scheduler from '../Scheduler/Scheduler';
import useLoadingMessages from '../useLoadingMessages';
import WidgetWrapper from '../WidgetWrapper';
import FloorPlanCard from './FloorPlanCard';
import {
  filterFloorPlans,
  floorPlan,
  formatFloorPlans,
  getFloorplans,
  generateOptionsFromString,
  getFloorplansFromsupabase,
  getEntrataCredsByMagnetuuid,
} from './utils';
import MultiButtonSelector from './MultiButtonSelector';
import MoveInDateRangeSelector from './MoveInDateRangeSelector';
import { FiSettings } from 'react-icons/fi';
import { Slider } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CobrowseIO from 'cobrowse-sdk-js';

CobrowseIO.license = 'quGBhRTXlDPITg';
CobrowseIO.trustedOrigins = [
  'https://embed.tour.video', // parent origin to trust
  'https://tour.video', // parent origin to trust
  'https://hi.tour.video', // parent origin to trust
  'https://app.usetour.com', // parent origin to trust
  'https://usetour.com', // parent origin to trust
  'https://my.leasemagnets.com', // another parent origin to trust
  'https://leasemagnets.com',
  'https://tour.video',
  'https://usetour.com',
  'https://theoneannarbor.com',
  '*',
];
CobrowseIO.start();

function BookingSchedule(props) {
  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState();
  const [step, setStep] = useState(2);
  const [viewSpecial, setViewSpecial] = useState(false);
  const [selectedBedroom, setSelectedBedroom] = useState(0);
  const [selectedBathroom, setSelectedBathroom] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [floorPlansJson, setfloorPlansJson] = useState({});
  const [propertyInfo, setPropertyInfo] = useState('');
  const [userinfo, setuserinfo] = useState({});
  const [filtereditems, setfiltereditems] = useState([]);
  const [filterednonavailableitems, setfilterednonavailableitems] = useState(
    []
  );
  const { magnetId } = useParams();
  console.log('magnetId', magnetId);
  const { integrationId } = useParams();
  const [specials, setSpecials] = useState([]);
  const [dataloaded, setdataloaded] = useState(false);
  const ilsUnits =
    floorPlansJson?.result?.PhysicalProperty?.Property[0]?.ILS_Unit;
  const floorPlanObj = useRef([]);
  const [message, setMessage] = useState(
    `I am interested in the Floor plans and looking to begin renting on ${selectedPlan?.available?.Month}-${selectedPlan?.available?.Day}-${selectedPlan?.available?.Year}`
  );
  const arrayOfMessages = [
    'Generating floor plans for you',
    'Our leasing agents be checking it twice',
    'Done, floor plans incoming',
  ];
  const loadMessage = useLoadingMessages(arrayOfMessages);

  const [choices, setchoices] = useState(floorPlan);

  function handleSelected(value, id) {
    console.log('handle', value, id);
    if (id === 1) {
      setSelectedBedroom(value);
    } else if (id === 2) {
      setSelectedBathroom(value);
    }
  }

  const [desiredMoveInSelector, setdesiredMoveInSelector] = useState('');
  const [selectedmoveDate, setselectedmoveDate] = useState({});

  const query = new URLSearchParams(window.location.search);
  const bedrooms = query.get('bedrooms');
  const bathrooms = query.get('bathrooms');
  const callForPricing = query.get('callForPricing');
  const InterestedIn = query.get('Plus Jakarta SansestedIn');

  const [credentials, setcredentials] = useState({
    entratauser: query.get('entratauser'),
    entratapass: query.get('entratapass'),
    entratapropertyId: query.get('entratapropertyId'),
    entratadomain: query.get('entratadomain'),
  });

  console.log('credentials', credentials);

  const isEntrataCredentialsAvailable = () => {
    console.log('lets see', {entratauser : credentials?.entratauser ,
      entratapass : credentials?.entratapass ,
      entratapropertyId :credentials?.entratapropertyId}, credentials?.entratauser && credentials?.entratapass && credentials?.entratapropertyId)
    return (
      credentials?.entratauser &&
      credentials?.entratapass &&
      credentials?.entratapropertyId
    );
  };

  useEffect(() => {
    if (!isEntrataCredentialsAvailable()) {
      console.log("credentials_getting",credentials)
      (async () => {
        await getEntrataCredsByMagnetuuid(magnetId, setcredentials);
      })();
    }
  }, []);

  console.log('selceted_move date', { selectedmoveDate });
  useEffect(() => {
    if (isEntrataCredentialsAvailable()) {
      (async () => {
        if (
          selectedmoveDate?.moveInStartDate &&
          selectedmoveDate?.moveInEndDate
        ) {
          setStep(2);
          const floorsRes = await getFloorplans(
            credentials,
            selectedmoveDate
            // 'getUnitAvailabilityAndPricing'
          );
          setfloorPlansJson(floorsRes);
          setPropertyInfo(
            floorsRes?.result?.PhysicalProperty?.Property[0]?.PropertyID
          );
          setSpecials(
            floorsRes?.result?.PhysicalProperty?.Property[0]?.Concession || []
          );
          // setdataloaded(true);
        }
      })();
    }
  }, [selectedmoveDate, credentials]);

  useEffect(() => {
    if (credentials.entratapropertyId) {
      const desiredMoveInSelector = query.get('desiredMoveInSelector');
      console.log('desiredMoveInSelector', desiredMoveInSelector);
      setdesiredMoveInSelector(desiredMoveInSelector);
      if (!desiredMoveInSelector) {
        (async () => {
          let floorsRes = await getFloorplansFromsupabase(
            credentials.entratapropertyId
          );
          if (floorsRes) {
            console.log('floorsRes', floorsRes);
            setfloorPlansJson(floorsRes);
            setPropertyInfo(
              floorsRes?.result?.PhysicalProperty?.Property[0]?.PropertyID
            );
            setSpecials(
              floorsRes?.result?.PhysicalProperty?.Property[0]?.Concession || []
            );
          } else {
            const floorsRes = await getFloorplans(
              credentials,
              selectedmoveDate
              // 'getUnitAvailabilityAndPricing'
            );
            console.log('floorsRes', floorsRes);
            setfloorPlansJson(floorsRes);
            setPropertyInfo(
              floorsRes?.result?.PhysicalProperty?.Property[0]?.PropertyID
            );
            setSpecials(
              floorsRes?.result?.PhysicalProperty?.Property[0]?.Concession || []
            );
          }
        })();
      }
    }
  }, [credentials]);

  useEffect(() => {
    // get bed and rooms from params
    if (bedrooms) {
      console.log('choices', bedrooms);
      choices[0] = generateOptionsFromString(
        bedrooms,
        0,
        'How many bedrooms do you need?'
      );
    }
    if (bathrooms) {
      console.log('choices', bathrooms);
      choices[1] = generateOptionsFromString(
        bathrooms,
        1,
        'How many bathrooms do you need?'
      );
    }
    if (InterestedIn) {
      console.log('choices', InterestedIn);
      choices[2] = generateOptionsFromString(
        InterestedIn,
        3,
        'Are You Intered In?'
      );
    }
    console.log('choices', choices);
    setchoices([...choices]);
  }, []);
  console.log('specials', specials);
  useEffect(() => {
    if (floorPlansJson?.result?.PhysicalProperty?.Property[0]?.Floorplan) {
      // Get Floorplans
      const floorPlans =
        floorPlansJson?.result?.PhysicalProperty?.Property[0]?.Floorplan;

      // Format Floorplans
      floorPlanObj.current = formatFloorPlans(floorPlans, ilsUnits);

      console.log('floorPlanObjjjj', floorPlanObj.current, {
        floorPlans,
        ilsUnits,
      });
      let min = floorPlans[0]['MarketRent']['@attributes']['Min'];
      let max = floorPlans[0]['MarketRent']['@attributes']['Max'];
      let bedroomslist = ['Any'];
      let bathroomslist = ['Any'];
      floorPlans?.map((item) => {
        if (item['MarketRent']?.['@attributes']?.['Min'] < min) {
          min = item?.['MarketRent']?.['@attributes']?.['Min'];
        }
        if (item['MarketRent']?.['@attributes']?.['Max'] > max) {
          max = item?.['MarketRent']?.['@attributes']?.['Max'];
        }
        item['Room']?.map((val) => {
          console.log('val', val);
          if (val?.['@attributes']?.RoomType == 'Bedroom') {
            if (!bedroomslist.includes(`${val?.Count}Bed`)) {
              bedroomslist.push(`${val?.Count}Bed`);
            }
          }
          if (val?.['@attributes']?.RoomType == 'Bathroom') {
            if (!bathroomslist.includes(`${val?.Count}Bath`)) {
              bathroomslist.push(`${val?.Count}Bath`);
            }
          }
        });
      });
      if (!bedrooms) {
        console.log('choices', bedrooms);
        choices[0] = generateOptionsFromString(
          bedroomslist.join(','),
          0,
          'How many bedrooms do you need?'
        );
      }
      if (!bathrooms) {
        console.log('choices', bathrooms);
        choices[1] = generateOptionsFromString(
          bathroomslist.join(','),
          1,
          'How many bathrooms do you need?'
        );
      }
      console.log('choices', choices);
      setchoices([...choices]);
      console.log('min_max', { min, max, bedrooms, bathrooms });
      setrentRange([min, max]);
      setorginalrentRange([min, max]);
      setdataloaded(true);
    }
  }, [floorPlansJson]);

  useEffect(() => {
    setMessage(
      `I am interested in the ${selectedPlan?.name} and looking to begin renting on ${selectedPlan?.available?.Month}-${selectedPlan?.available?.Day}-${selectedPlan?.available?.Year}`
    );
    setuserinfo({
      ...userinfo,
      message: `I am interested in the ${selectedPlan?.name} and looking to begin renting on ${selectedPlan?.available?.Month}-${selectedPlan?.available?.Day}-${selectedPlan?.available?.Year}`,
    });
  }, [selectedPlan]);

  const [selectedoptions, setselectedoptions] = useState([]);
  const [rentRange, setrentRange] = React.useState([0, 8000]);
  const [orginalrentRange, setorginalrentRange] = React.useState([0, 8000]);
  const handleChange = (event, newValue) => {
    setrentRange(newValue);
  };

  useEffect(() => {
    getFilterfloorPlans();
  }, [
    selectedDate,
    selectedBedroom,
    selectedBathroom,
    rentRange,
    floorPlansJson,
  ]);

  const getFilterfloorPlans = () => {
    let filtered = filterFloorPlans(
      floorPlanObj.current,
      selectedDate,
      selectedBedroom,
      selectedBathroom,
      rentRange
    );
    console.log('filtereditems', filtered);
    setfiltereditems(() => {
      return filtered?.matchedfloorplans;
    });
    setfilterednonavailableitems(filtered?.unmatchedfloorplans);
  };

  const handleOnGoBack = () => {
    setViewSpecial(false);
    if (step > 1) setStep((prev) => prev - 1);
    else if (onBack) onBack();
    else history.back();
  };

  console.log('selectedoptions', selectedoptions);
  console.log('step_step', step);

  // 4 is the number of steps internal to this widget

  if (step < 4) {
    return (
      <WidgetWrapper step={step == 2 ? 1 : step} onBack={handleOnGoBack}>
        {/* Actual Widget Content */}
        <div className="scheduler-content ">
          {step === 1 && (
            <>
              <div className="step-1 flex flex-col  w-full items-center">
                <div className="meta">
                  <div className="title font">
                    {filtereditems ? filtereditems.length : 0} Floor Plans
                    Available
                  </div>
                  <div className="txt font">Pick out a floor plan</div>
                </div>
                <div>
                  {choices &&
                    choices.map(({ title, id, options }) => (
                      <>
                        {title == 'Are You Intered In?' ? (
                          <MultiButtonSelector
                            selectionArray={[{ title, id, options }]}
                            selectedoptions={selectedoptions}
                            setselectedoptions={setselectedoptions}
                          />
                        ) : (
                          <div className="block" key={id}>
                            <div className="label font s14 black">{title}</div>
                            <div className="options flex aic gap-2">
                              {options &&
                                options.map(({ label, value }, index) => (
                                  <div
                                    key={index}
                                    onClick={() => handleSelected(value, id)}
                                    className={`item font ${
                                      id === 1
                                        ? selectedBedroom === value
                                          ? 'active'
                                          : ''
                                        : selectedBathroom === value
                                        ? 'active'
                                        : ''
                                    }`}
                                  >
                                    {label}
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                </div>
                {/* <div style={{maxWidth: '384px'}}> */}
                <div className="w-full lg:w-96 px-6 lg:px-0">
                  <p
                    className="text-left"
                    style={{
                      marginTop: '30px',
                      fontSize: '15px',
                    }}
                  >
                    What is your target rent?
                  </p>
                </div>
                <div className="w-full lg:w-96 px-8 lg:px-0">
                  <Slider
                    value={rentRange}
                    onChange={handleChange}
                    // defaultValue={[1210,2100]}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    min={orginalrentRange[0]}
                    max={orginalrentRange[1]}
                    // step={50}
                    // fullWidth
                    // getAriaValueText={valuetext}
                    // marks={[
                    //   {
                    //     value: 0,
                    //     label: 'Min Price',
                    //   },
                    //   {
                    //     value: 9000,
                    //     label: 'Max Price',
                    //   },
                    // ]}
                    style={{ color: '#4D8AE5' }}
                    className=""
                  />
                </div>

                <div className=" flex w-full lg:w-96 px-6 lg:px-0 items-center justify-between mb-2">
                  <h6 className="text-lg font-semibold">${rentRange[0]}</h6>
                  <h6 className="text-lg font-semibold">${rentRange[1]}</h6>
                </div>
                <div className="w-full lg:w-96 px-6 lg:px-0">
                  <div
                    style={{
                      textAlign: 'left',
                      marginTop: '10px',
                      fontSize: '15px',
                      left: '0',
                    }}
                  >
                    When is your desired move in date (optional)
                  </div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      // id="outlined-basic"
                      variant="outlined"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-outline"
                      label="Select a move in date (optional)"
                      style={{ width: '100%' }}
                      value={selectedDate}
                      onChange={(value) => {
                        if (new Date(value).getTime() > new Date().getTime()) {
                          setSelectedDate(value);
                          //setStep(2);
                        }
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                {/* </div> */}
                {/* {desiredMoveInSelector ? (
                  <MoveInDateRangeSelector
                    dateRanges={desiredMoveInSelector}
                    setselectedmoveDate={setselectedmoveDate}
                    selectedDate={selectedDate}
                  />
                ) : (
                  <div className="block">
                    <div className="label font s14 black">
                      When is your desired move-in date?
                    </div>
                    <div className="calender w-96	" style={{ width: '384px' }}>
                      <Calendar
                        minDate={
                          new Date(new Date().getTime() + 60 * 60 * 24 * 1000)
                        }
                        onChange={(value) => {
                          if (
                            new Date(value).getTime() > new Date().getTime()
                          ) {
                            setSelectedDate(value);
                            setStep(2);
                          }
                        }}
                        value={selectedDate}
                      />
                    </div>
                  </div>
                )} */}
                <div
                  className="w-full px-6 lg:px-0 flex items-center justify-center
              "
                >
                  <button
                    onClick={() => {
                      getFilterfloorPlans();
                      setStep(2);
                    }}
                    style={{ background: '#4D8AE5' }}
                    className="py-3 text-center w-full lg:w-96  text-white font-semibold rounded-md mt-10"
                  >
                    Filter
                  </button>
                </div>
              </div>
            </>
          )}
          {step === 2 && (
            <div className="step-2 flex flex-col">
              {dataloaded ? (
                <>
                  <div className="meta top-0 sticky bg-white p-2 border-b">
                    <div className="title font">Floor Plans</div>
                    <div className="txt font">Browse the matching</div>
                    <div className="txt-2 font">
                      <span className="b6"></span> floor plans below.
                    </div>
                    {/* <-- Previous selected information --> */}
                    <div className="flex items-center flex-wrap justify-center gap-4 mt-2">
                      <div
                        style={{ minWidth: 100 }}
                        className="border bg-gray-50 rounded py-1 px-2  cursor-pointer"
                        onClick={() => {
                          setStep(1);
                        }}
                      >
                        <p className="text-xs text-gray-400"># of beds</p>
                        <p className="text-gray-600">
                          {selectedBedroom ? selectedBedroom : 'Any'}
                        </p>
                      </div>
                      <div
                        style={{ minWidth: 100 }}
                        className="border bg-gray-50 rounded py-1 px-2 cursor-pointer"
                        onClick={() => {
                          setStep(1);
                        }}
                      >
                        <p className="text-xs text-gray-400"># of baths</p>
                        <p className="text-gray-600">
                          {selectedBathroom ? selectedBathroom : 'Any'}
                        </p>
                      </div>
                      <div
                        style={{ minWidth: 100 }}
                        className="border bg-gray-50 rounded py-1 px-2  cursor-pointer"
                        onClick={() => {
                          setStep(1);
                        }}
                      >
                        <p className="text-xs text-gray-400 whitespace-nowrap">
                          Preferred Move-in Date
                        </p>
                        <p className="text-gray-700 whitespace-nowrap">
                          {selectedDate
                            ? moment(selectedDate).format('LL')
                            : 'Any'}
                        </p>
                      </div>
                      <div
                        className="flex cursor-pointer items-center gap-2 hover:color-blue-500"
                        onClick={() => setStep(1)}
                      >
                        <FiSettings />

                        <p>Filter</p>
                      </div>
                    </div>
                  </div>
                  <div className="plans-list">
                    {
                      filtereditems?.length > 0 &&
                        filtereditems?.map((item, index) => (
                          <FloorPlanCard
                            planItem={item}
                            key={index}
                            onClick={() => {
                              setStep(3);
                              setSelectedPlan(item);
                            }}
                            callForPricing={callForPricing}
                          />
                        ))
                      // : (
                      //   <LoadingIndicator />
                      // )
                    }
                  </div>
                  {/* Non - available. Show text for things you can waitlist*/}
                  <div className="mt-8">
                    <h1 className="text-2xl font-semibold">
                      Not seeing what you're looking for?
                    </h1>
                    <p className="text-gray-500">
                      We have a few more floor plans that we can waitlist you
                      for:
                    </p>
                  </div>
                  <div className="plans-list">
                    {
                      filterednonavailableitems?.length > 0 &&
                        filterednonavailableitems?.map((item, index) => (
                          <FloorPlanCard
                            planItem={item}
                            key={index}
                            onClick={() => {
                              setStep(3);
                              setSelectedPlan(item);
                            }}
                            dec_opacity={true}
                            callForPricing={callForPricing}
                          />
                        ))
                      // : (
                      //   <LoadingIndicator />
                      // )
                    }
                  </div>
                </>
              ) : (
                <div className=" w-full">
                  <div className=" mt-8 w-full flex items-center justify-center gap-3">
                    <LoadingIndicator />
                    <h1 className=" text-2xl text-gray-400">{loadMessage}</h1>
                  </div>
                </div>
              )}
            </div>
          )}
          {step === 3 && selectedPlan && (
            <div className="step-3 flex flex-col">
              <img
                src={selectedPlan?.pic}
                style={{ height: '300px', objectFit: 'contain' }}
                alt=""
              />

              <div className="actions flex aic">
                {viewSpecial ? (
                  <button
                    className="action-btn font"
                    onClick={() => setViewSpecial(false)}
                  >
                    View Details
                  </button>
                ) : (
                  <button
                    className="action-btn font"
                    onClick={() => setViewSpecial(true)}
                  >
                    View Specials
                  </button>
                )}
              </div>
              <div className="content flex flex-col">
                {viewSpecial ? (
                  <>
                    {/* <div className="name">{special?.name}</div> */}
                    <div className="txt font">
                      Sign a lease today to get this special deal.
                    </div>
                    <button
                      onClick={() => {
                        setStep(6);
                        // history.push(
                        //   `/cta/contactus/integration/${integrationId}`
                        // );
                        setMessage(
                          `I would like to be updated on any specials for '${selectedPlan?.name}'. Can you please add me to your special offers update list?'`
                        );
                      }}
                      className="apply-btn"
                    >
                      Contact Me with Updated Specials
                    </button>
                    {selectedPlan?.Concession?.map((special, idx) => {
                      return (
                        <div
                          className=" hover:opacity-50 cursor-pointer hover:underline "
                          onClick={() => {
                            setStep(6);
                            setMessage(
                              `I would like to learn more about how I can claim '${special?.DescriptionHeader}'`
                            );
                          }}
                        >
                          <div className=" text-lg font-semibold mt-4">
                            {special?.DescriptionHeader}
                          </div>
                          <div className=" text-sm font-medium">
                            {special?.DescriptionBody}
                          </div>
                        </div>
                      );
                    })}
                    {specials.length > 0
                      ? 'We might not be running specials right now, But we can notify when we update speicals'
                      : ''}
                    {specials?.map((special, idx) => {
                      return (
                        <div
                          className=" hover:opacity-50 cursor-pointer hover:underline "
                          onClick={() => {
                            setStep(6);
                            setMessage(
                              `I would like to learn more about how I can claim '${special?.name}'`
                            );
                          }}
                        >
                          <div className=" text-lg font-semibold mt-4">
                            {special?.DescriptionHeader}
                          </div>
                          <div className=" text-sm font-medium">
                            {special?.DescriptionBody}
                          </div>
                        </div>
                      );
                    })}
                    <div className="stamp">Hurry, offer ends soon</div>
                  </>
                ) : (
                  <>
                    <div className="name">{selectedPlan?.name}</div>
                    <div className="txt font">
                      {selectedPlan?.rooms?.Bedroom} Bed /{' '}
                      {selectedPlan?.rooms?.Bedroom} Bath
                    </div>
                     <div className="txt font">
                     {callForPricing ? "Call for Pricing" : `$${selectedPlan?.rents?.['Min']} - $${selectedPlan?.rents?.['Max']}`}
                    </div> 
                    {selectedPlan?.sqFt?.['Min'] && selectedPlan?.sqFt?.['Min'] != "0" ? <div className="txt font">
                      {selectedPlan?.sqFt?.['Min']} -{' '}
                      {selectedPlan?.sqFt?.['Max']} Sq ft.
                    </div> : ""}
                    <div className="act-btns">
                      <div
                        className="act-btn"
                        onClick={() => {
                          setStep(6);
                          setMessage(
                            `I want to apply for ${selectedPlan?.name} floor plans.`
                          );
                        }}
                      >
                        Apply
                      </div>
                      <div
                        className="act-btn transparent"
                        onClick={() => {
                          setStep(4);
                        }}
                      >
                        Schedule A Tour
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        setStep(6);
                        setMessage(
                          `I have a question about ${selectedPlan?.name} floor plans.`
                        );
                      }}
                      className="send-msg-btn font mt-2 text-slate-500"
                    >
                      Ask a question
                    </button>
                  </>
                )}
              </div>
            </div>
          )}

          {/*step === 5 && (
            <ThankYouScreen
              text={`${userinfo.firstname}, thanks for scheduling a tour at \n ${propertyInfo?.MarketingName}`}
              details={{
                selectedDate: selectedDate,
              }}
              onGoBack={() => setStep((prev) => prev - 1)}
            />
          )*/}
        </div>
      </WidgetWrapper>
    );
  } else if (step === 4 || step === 5) {
    return (
      <Scheduler
        onBack={() => setStep(3)}
        showGoBack={true}
        scheduleType={step === 4 ? 'in-person' : 'virtual'}
      />
    );
  } else if (step === 6) {
    // TODO add custom message from floorplans
    return (
      <ContactUs
        showBack={true}
        the_message={message}
        onBack={() => setStep(3)}
      />
    );
  }
}

export default BookingSchedule;
