import React from 'react';

export default function Badge({ number, text, color = '#3898ec' }) {
  return (
    <div className="itm flex flex-col aic" style={{ background: color }}>
      <div className="num font c000">{number}</div>
      <div className="lbl font c000">{text}</div>
    </div>
  );
}
