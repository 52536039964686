import CircularProgress from '@material-ui/core/CircularProgress';
import Rating from '@material-ui/lab/Rating';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseJsonHorizontally } from '../../core';
import { getAllReferrals } from '../../store/actions/referralActions';

const AllReferralList = ({ community_id }) => {
  const [openEditUser, setOpenEditUser] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [page, setPage] = useState(1);

  const [msg, setmsg] = useState(
    'I was hoping you could do me a favor and leave my team and me a review? Thank you in advance.'
  );
  const messagelist = [
    {
      msg: 'I was hoping you could do me a favor and leave my team and me a review? Thank you in advance.',
    },
    {
      msg: 'I wanted to just say sincerely thank you for leaving us a review. It really meant alot to us.',
    },
    {
      msg: 'I wanted to just say sincerely thank you for leaving us a review. Just wanted to check in to see if you were ever were able to leave us us a Google Review?\n It really means alot to us.',
    },
    {
      msg: 'Just wanted to share a special token as a thank you for kind words in the review and being an awesome resident here. \n It really means alot to us.',
    },
  ];

  const { referralsList, referralsListLoading, referralsListError } =
    useSelector((state) => state.getAllReferralsReducer);

  const _dispatch = useDispatch();

  useEffect(() => {
    _dispatch(getAllReferrals(community_id));
  }, []);

  return (
    <div className="all-user-reviews">
      <div className="hdr flex aic">
        <div className="ls flex aic">
          <div className="tit font s18">Referral Conversations</div>
        </div>
        <div className="rs flex aic"></div>
      </div>
      <>
        {referralsListLoading && referralsList?.payload?.length ? (
          <div className="loading">
            <CircularProgress />
          </div>
        ) : (
          <div className="reviews-list">
            <CompletedFeedbackTable
              selectedReview={selectedReview}
              setSelectedReview={setSelectedReview}
              openEditUser={openEditUser}
              setOpenEditUser={setOpenEditUser}
              reviews={referralsList?.payload}
              page={page}
              setmsg={setmsg}
              messagelist={messagelist}
              community_id={community_id}
            />
            <Navigation
              page={page}
              setPage={setPage}
              pages={Math.floor(referralsList?.payload?.length / 5) + 1}
            />
          </div>
        )}
      </>
    </div>
  );
};

export default AllReferralList;

function CompletedFeedbackTable({
  reviews,
  page,
  setmsg,
  messagelist,
  setSelectedReview,
  setOpenEditUser,
}) {
  // Only display the current page of reviews (10 per page)
  function displayTen() {
    if (reviews?.length) {
      const ten = reviews.filter(
        (review, idx) => idx >= 5 * (page - 1) && idx < 5 * page
      );
      return ten;
    } else {
      return [];
    }
  }

  return (
    <>
      <div className="dashboard-employee-table">
        <div className="table">
          <div className="table-hdr flex aic">
            <div className="column">
              <div className="label font">Date</div>
            </div>
            <div className="column">
              <div className="label font">Name</div>
            </div>
            <div className="column">
              <div className="label font">Sentiment</div>
            </div>
            <div className="column">
              <div className="label font">Comments</div>
            </div>
          </div>
          {displayTen().map((review, idx) => {
            let _badges = review.badges ? JSON.parse(review.badges) : [];
            return (
              <CompletedRow
                key={idx}
                idx={idx}
                setSelectedReview={setSelectedReview}
                review={review}
                date={review.date_created}
                fromUserName={review?.user1?.name}
                toUserName={review?.user2?.name}
                rating={review.rating}
                comments={review.message}
                badges={_badges}
                setmsg={setmsg}
                messagelist={messagelist}
              />
            );
          })}
          {reviews?.length === 0 && <NoRows />}
        </div>
      </div>
    </>
  );
}

// Row for each table entry
function CompletedRow({
  idx,
  date,
  fromUserName,
  toUserName,
  review,
  rating,
  comments,
  setmsg,
  messagelist,
}) {
  // Expand or not
  const [expand, setExpand] = useState(false);

  const emojis = [
    { label: 'Well', icon: '🙌' },
    { label: 'Noticed', icon: '🔔' },
    { label: 'Heart', icon: '❤️' },
    { label: 'Gift', icon: '🎁' },
  ];

  return (
    <>
      <div className="table-row flex aic">
        <div className="column">
          <div className="txt font">{date}</div>
        </div>
        <div className="column user-name">
          <div className="font name">
            {fromUserName ? <span>{fromUserName}</span> : '🧍'}
            {toUserName && <span>&nbsp;↔&nbsp;{toUserName}</span>}
          </div>
          <div className="reactions">
            {emojis.map((item, index) => (
              <div
                key={index}
                onClick={() => setmsg(messagelist[0].msg)}
                className="sms-reaction-icon"
              >
                {item.icon}
                <div className="copied">{item.label}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="column">
          <div style={{ display: 'inline-block', fontSize: '34px' }}>
            <Rating
              name="rating-component"
              defaultValue={rating}
              precision={0.5}
              readOnly
              size="medium"
            />{' '}
            {parseJsonHorizontally(review?.metadata)?.bonusReviewCompleted ? (
              <span style={{ color: '#ffb400' }}>☑</span>
            ) : (
              <span style={{ color: 'lightgrey' }}>☑</span>
            )}
          </div>
        </div>
        <div className="comments column">
          <div className="item flex aic">
            <div className="meta flex flex-col">
              <div className="cmt font s15 black wordwrap">{comments}</div>
            </div>
            {comments?.length > 0 && (
              <button
                className="cleanbtn drop-btn font"
                onClick={() => setExpand(!expand)}
              >
                {!expand ? '▼' : '▲'}
              </button>
            )}
          </div>
        </div>
      </div>
      {expand && (
        <>
          <ExpandedComments idx={idx} comments={comments} />
        </>
      )}
    </>
  );
}

function Navigation({ page, setPage, pages }) {
  // Handle clicks
  function handleFirst() {
    setPage(1);
  }

  function handlePrev() {
    if (page !== 1) setPage(page - 1);
  }

  function handleNext() {
    if (page !== pages) setPage(page + 1);
  }

  function handleLast() {
    setPage(pages);
  }

  return (
    <div className="table-navigation flex aic">
      <div className="left flex aic">
        <div className="prev-btn font s16 b5 black" onClick={handleFirst}>
          {'<< First'}
        </div>
        <div className="prev-btn font s16 b5 black" onClick={handlePrev}>
          {' < Prev '}
        </div>
      </div>
      <div className="center">
        <div className="font s18 b6">
          {page} / {pages}
        </div>
      </div>
      <div className="left flex aic">
        <div className="next-btn font s16 b5 black" onClick={handleNext}>
          {' Next > '}
        </div>
        <div className="prev-btn font s16 b5 black" onClick={handleLast}>
          {' Last >>'}
        </div>
      </div>
    </div>
  );
}

function NoRows() {
  return <div className="no-employees flex aic">No Referral Conversations</div>;
}

// Expanded comments section
function ExpandedComments({ idx, comments }) {
  return (
    <div style={{ display: 'flex' }} className="expanded-blk aic">
      <div className="txt font">{comments}</div>
    </div>
  );
}
