import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';
import { SketchPicker } from 'react-color';
import notification from '../../components/Notification';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import Switch from '../../components/Switch';
import firebase from '../../utils/base';

const Embed = (props) => {
  const { form_id, form } = props;
  const forms = require('./forms.json');

  const [embedDetails, setEmbedDetails] = useState({
    videoCTA: '',
    website: '',
    primaryColor: '#302d2d',
    blacklist: '',
  });

  const [NotificationBar, setNotification] = notification();

  async function updateEmbedSettings() {
    // Firebase cheatsheet https://medium.com/@aaron_lu1/firebase-cloud-firestore-add-set-update-delete-get-data-6da566513b1b

    try {
      const embedSettingsRef = firebase.db
        .collection('videoembed')
        .doc(form_id);
      const doc = await firebase.db.collection('videoembed').doc(form_id).get();
      console.log('docdata', doc.data);
      if (doc) {
        console.log('leads field exists');
        // https://docs.microsoft.com/en-us/dotnet/api/system.datetime.tostring?view=netcore-3.1
        embedSettingsRef.set(
          {
            embed_settings: embedDetails,
          },
          { merge: true }
        );
      } else {
        console.log('leads field does not exists');
        embedSettingsRef.set(
          {
            embed_settings: embedDetails,
          },
          { merge: true }
        );
      }
      return true;
    } catch (err) {
      console.log('Failure getting leads document, check firebase key :', err);
      return false;
    }
  }

  const getEmbedSettings = async () => {
    console.log('fetching entrata integration');
    try {
      const doc = await firebase.db.collection('videoembed').doc(form_id).get();
      console.log('docdata', doc.data);
      if (doc.data().embed_settings) {
        setEmbedDetails(doc.data().embed_settings);
      } else {
        console.log('reporting field does not exists');
      }
      return true;
    } catch (err) {
      console.log('Failure getting leads document, check firebase key :', err);
      return false;
    }
  };

  useEffect(() => {
    getEmbedSettings();
  }, []);

  function openInNewTab(url) {
    const win = window.open(url, '_blank');
    win.focus();
  }

  return (
    <div className="video-magnet-embed-page flex flex-col rel">
      <div className="emded-page-body">
        <NotificationBar />
        <div className="embed-title font s20 c333">General Settings</div>
        <div className="embed-container flex">
          <div className="col flex flex-col">
            <div className="blk">
              <DebouncedTextField
                name="videoCTA"
                label="Video CTA"
                onChange={(newValue) =>
                  setEmbedDetails({ ...embedDetails, videoCTA: newValue })
                }
                value={embedDetails.videoCTA}
                required
              />
              <DebouncedTextField
                name="Website"
                label="Website"
                onChange={(newValue) =>
                  setEmbedDetails({ ...embedDetails, website: newValue })
                }
                value={embedDetails.website}
                required
              />

              <DebouncedTextField
                name="page_blacklist"
                label="Hide Magnet on Pages"
                onChange={(newValue) =>
                  setEmbedDetails({ ...embedDetails, blacklist: newValue })
                }
                value={embedDetails.blacklist}
                required
              />

              <DebouncedTextField
                name="Website"
                label="Website"
                onChange={(newValue) =>
                  setEmbedDetails({ ...embedDetails, website: newValue })
                }
                value={embedDetails.website}
                required
              />

              <div className="font s15 b5">Choose Side of Page for Widget</div>
              <Switch type="checkbox" leftLabel="Left" rightLabel="Right" />

              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={() => {
                  updateEmbedSettings().then((e) => {
                    console.log('bool vals', e);
                    if (e) {
                      setNotification(
                        'Updated Reporting email information successfully'
                      );
                    }
                  });
                }}
              >
                {' '}
                Save Embed Settings{' '}
              </Button>
            </div>
          </div>

          <div className="col flex flex-col">
            <div className="blk">
              <DebouncedTextField
                name="color"
                label="Color"
                onChange={(newValue) =>
                  setEmbedDetails({
                    ...embedDetails,
                    primaryColor: newValue,
                  })
                }
                value={embedDetails.primaryColor}
                required
              />
              <SketchPicker
                color={embedDetails.primaryColor}
                onChangeComplete={(c) =>
                  setEmbedDetails({ ...embedDetails, primaryColor: c.hex })
                }
              />

              <Button
                fullWidth
                variant="outlined"
                onClick={() => {
                  openInNewTab(
                    `https://leasemagnets.com/preview?code=${form.share_id}&color=${embedDetails.primaryColor}&cta=${embedDetails.videoCTA}`
                  );
                }}
              >
                {' '}
                Preview{' '}
              </Button>
            </div>
          </div>
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexGrow: 0.3,
            }}
          >
            <div
              style={{
                height: 120,
                width: 120,
                border: `5px solid ${embedDetails.primaryColor}`,
                borderRadius: 60,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span style={{ textAlign: 'center' }}>
                Take a Virtual Tour & $250 Off
              </span>
            </div>
          </div>
        </div>
      </div>
      <CopyBlock
        text={`
                <script>
                window.VIDEOASK_EMBED_CONFIG = {
                  "kind": "widget",
                  "url": "https://video.tourmagnets.com/${form.share_id}",
                  "options": {
                    "widgetType": "VideoThumbnailWindow",
                    "text": "${embedDetails.videoCTA}",
                    "backgroundColor": "${embedDetails.primaryColor}",
                    "position": "bottom-right"
                  }
                }
                </script>
                <script src="https://video.tourmagnets.com/embed/embed.js"></script>
                `}
        language="python"
        showLineNumbers={false}
        theme={dracula}
        fullWidth
        wrapLines
        codeBlock
      />
    </div>
  );
};

export default Embed;
