import { supabase } from '../../services/supabase';
import axios from 'axios';
import {
  getRandomId,
  uploadBlobToStorage,
  validFileName,
} from '../../utils/files';
import io from 'socket.io-client';
import Ably from 'ably';

export function generateInitialVideoMetadata(
  videoUrl,
  videoTitle,
  magnetUuid,
  communityId
) {
  return {
    title: videoTitle,
    video: videoUrl,
    //'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/299/magnet/f39eb022-5c26-4cfa-b8e0-6fb3d3d1cb84/floor_plans_4x4_Boulder_V2/4x420FPV2.mp4.mp4',
    caption: '',
    cloudLibrary: [],
    form: {},
    gif: '',
    iframe: {},
    img: '',
    links: [],
    location_card: '',
    share_info: {},
    encoded: false,
    encoded: false,
    magnet_uuid: magnetUuid,
    community_id: communityId,
  };
}

// Use coconut.co to encode video and return back the data of encoded video.
export async function encodeVideo(videoData, supabaseResponse) {
  console.log('videoData', { videoData }, { supabaseResponse });
  axios({
    method: 'POST',
    url: `https://api.leasemagnets.com/upload-url?prefix=${`recordings/${getRandomId()}`}`,
    data: {
      video: videoData?.video,
      customvideo_id: supabaseResponse?.data[0]?.id,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return videoData;
}

export async function generateAndSaveVideoData({
  videoUrl,
  title,
  magnetId,
  communityId,
  onVideoSaved,
}) {
  const initialVideoObj = generateInitialVideoMetadata(
    videoUrl,
    title,
    magnetId,
    communityId
  );
  const supabaseResponse = await saveVideoToSupabase(initialVideoObj);
  encodeVideo(initialVideoObj, supabaseResponse);
  onVideoSaved(supabaseResponse);
}

export async function getSupabaseTable(table_name, column_name, filter) {
  let supabaseResponse = {};
  if (filter) {
    supabaseResponse = await supabase
      .from(table_name)
      .select(column_name ? column_name : '*')
      .eq(filter?.column_name, filter?.column_value)
      .order('time', { ascending: false }); // If column_name then select that column otherwise select all columns
    console.log('supabaseResponse', supabaseResponse);
  } else {
    supabaseResponse = await supabase
      .from(table_name)
      .select(column_name ? column_name : '*')
      .order('time', { ascending: false });
  }
  return supabaseResponse;
}

export async function getSupabaseRow(table_name, column_name, filter) {
  let supabaseResponse = {};
  if (filter) {
    supabaseResponse = await supabase
      .from(table_name)
      .select(column_name ? column_name : '*')
      .eq(filter?.column_name, filter?.column_value);
    console.log('supabaseResponse', supabaseResponse);
  } else {
    supabaseResponse = await supabase
      .from(table_name)
      .select(column_name ? column_name : '*');
  }
  return supabaseResponse;
}

export async function supaUpdaterow(table_name, updateJson, filter) {
  // Returns only error
  const supaResponse = await supabase
    .from(table_name)
    .update(updateJson)
    .eq(filter?.column_name, filter?.column_value);

  return supaResponse;
}

export async function subscribeRealtimeUpdates(setCustomVideosData) {
  const CustomVideos = supabase
    .channel('custom-update-channel')
    .on(
      'postgres_changes',
      { event: 'UPDATE', schema: 'public', table: 'CustomVideos' },
      async (payload) => {
        console.log('Change received!', payload);
        const supabaseResponse = await supabase
          .from('CustomVideos')
          .select('*')
          .order('id', { ascending: false }); // If column_name then select that column otherwise select all columns
        setCustomVideosData(supabaseResponse?.data);
      }
    )
    .subscribe();
}

export async function unsubscribeRealtimeUpdates() {
  supabase
    .channel('custom-update-channel')
    .on(
      'postgres_changes',
      { event: 'UPDATE', schema: 'public', table: 'CustomVideos' },
      (payload) => {
        console.log('Change received!', payload);
      }
    )
    .unsubscribe();
}

export async function saveVideoToSupabase(videoObj) {
  console.log('ABCD Supabase insert videoObj', videoObj);
  const { data, error } = await supabase
    .from('CustomVideos')
    .insert([videoObj])
    .select('id');

  console.log('data_data', data);

  return { data, error };
}

export async function supaUpdateVideoScreen(screenData, videoId) {
  // Returns only error
  const supaResponse = await supabase
    .from('CustomVideos')
    .update({ screen: screenData })
    .eq('id', videoId);

  return supaResponse;
}

export async function supaGetRowById(videoId) {
  // Returns only error
  const supaResponse = await supabase
    .from('CustomVideos')
    .select('*')
    .eq('id', videoId);

  return supaResponse;
}

export async function deleteVideoFromCloud(url) {
  console.log('url to delete the file', url);
  await axios({
    method: 'POST',
    url: 'https://api.leasemagnets.com' + `/delete_file?file_path=` + url,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export async function supaDeleteVideo(videoId) {
  const supaResponse = await supabase
    .from('CustomVideos')
    .delete()
    .eq('id', videoId);

  return supaResponse;
}

const formScreenPayload = {
  payload: {
    screen: {
      title: 'Thank You video 8',
      location_card: '',
      caption: '',
      video:
        'https://storage.googleapis.com/leasemagnets-video-tyg/black-TYG.mp4?isButtonSeprated=undefined',
      cloudLibrary: [],
      links: [],
      centerLinks: {
        pageEnabled: false,
        buttonEnabled: false,
        backgroundWhite: false,
        links: [],
      },
      bottomLinks: {
        invisibleBackground: false,
        roundedVideoPlayer: false,
        extraPadding: false,
        links: [],
      },
      form: {
        text_data: {
          title: 'Your interests',
          body_text: 'Please let us know about your interest.',
        },
        inputs: [
          {
            label: 'Your Name',
            type: 'lead_name',
            id: 'lead_name',
            required: true,
          },
          {
            label: 'Your Email',
            type: 'lead_email',
            id: 'lead_email',
            required: true,
          },
          {
            label: 'Your Phone',
            type: 'lead_phone',
            id: 'lead_phone',
            required: true,
          },
        ],
        opacity: 1,
        text_color: '#000',
        enabled: true,
      },
      iframe: {
        enabled: false,
        src: '',
        icon_color: '#ffffff',
        background_color: '#000000',
      },
      additionalInfo: {
        price: 0,
        features: [],
        //    "moveInDate": "2023-02-27",
        detailedDescription: '',
        shareCaption: '',
        images: [],
        //    "projectID": 492863
      },
      share_info: {},
      addOns: {},
    },
  },
};

const iframeScreenPayload = {
  payload: {
    screen: {
      title: 'Iframe Screen 8',
      location_card: '',
      caption: '',
      video: '',
      cloudLibrary: [],
      links: [],
      centerLinks: {
        pageEnabled: false,
        buttonEnabled: false,
        backgroundWhite: false,
        links: [],
      },
      bottomLinks: {
        invisibleBackground: false,
        roundedVideoPlayer: false,
        extraPadding: false,
        links: [],
      },
      form: {
        text_data: {},
        inputs: [],
        opacity: 1,
        text_color: '#000',
        enabled: false,
      },
      iframe: {
        enabled: true,
        src: '',
        icon_color: '#ffffff',
        background_color: '#000000',
      },
      additionalInfo: {
        price: 0,
        features: [],
        //    "moveInDate": "2023-02-27",
        detailedDescription: '',
        shareCaption: '',
        images: [],
        //    "projectID": 492863
      },
      share_info: {},
      addOns: {},
    },
  },
};

export async function createFormScreen(
  _dispatch,
  screen_key,
  category_key,
  magnet_id,
  tempNewVideoTitle,
  formTemplate
) {
  _dispatch({
    type: 'updateScreen',
    payload: {
      screen_key,
      category_key,
      magnet_id,
      magnet_type: 'video',
      screen: {
        ...formScreenPayload.payload.screen,
        form: { ...formScreenPayload.payload.screen.form, ...formTemplate },
        title: tempNewVideoTitle,
      },
    },
  });
}

export async function createIframeScreen(
  _dispatch,
  screen_key,
  category_key,
  magnet_id,
  tempNewVideoTitle,
  iframeObj
) {
  _dispatch({
    type: 'updateScreen',
    payload: {
      screen_key,
      category_key,
      magnet_id,
      magnet_type: 'video',
      screen: {
        ...iframeScreenPayload.payload.screen,
        iframe: { ...iframeScreenPayload.payload.screen.iframe, ...iframeObj },
        title: tempNewVideoTitle,
      },
    },
  });
}

export const connectwithsocket = async () => {
  //const visitorId = await getUserId()
  const visitorId = 'gfs43243w65edc';
  console.log('visitorId_caleld', visitorId);
  const socket = io.connect(
    `https://videomagnet-websockets.onrender.com?visitorid=${visitorId}&uuid=0d8aa956-2d8d-4254-b018-15c1672c5d9a&admin=True`,
    {
      reconnection: true,
      reconnectionDelay: 200,
    }
  );
  socket.on('message', function (thedata) {
    console.log('sockett-meessage', thedata);
    if (thedata?.action == 'visitor_list') {
      setactivevisitors(thedata?.visitors);
    }
  });
};

export const sendsocketmessage = async (visitorid) => {
  const res = await axios.post(
    `https://videomagnet-websockets.onrender.com/sendMessage`,
    {
      uuid: '0d8aa956-2d8d-4254-b018-15c1672c5d9a',
      visitorid,
    }
  );
};

export const initializeSupabaseRealtime = (
  setchannelref,
  activevisitors,
  setactivevisitors,
  magnet_uuid,
  temp_activevisitors,
  flag = ''
) => {
  const channel = supabase.channel(magnet_uuid);
  temp_activevisitors = [];
  setchannelref(channel);

  channel.on('presence', { event: 'sync' }, () => {
    let members = channel.presenceState();
    console.log('presence_event: Online users: ', members);
    var curr_members = [];
    Object.keys(members).map((key) => {
      curr_members.push({ data: { ...members[key][0], unreadCount: 0 } });
    });

    curr_members.sort((a, b) => b?.data?.started - a?.data?.started);

    setactivevisitors((prev) => {
      console.log('activevisitors current initializeSupabaseRealtime', prev);
      let updatedMembers = curr_members.map((item) => {
        const existingMember = prev?.find((member) => member?.data?.visitorId === item.data.visitorId);
        const existingUnreadCount = existingMember?.data?.unreadCount;
        const existingUnreadMessage = existingMember?.data?.unreadMessage;
        return {
          data: {
            ...item.data,
            unreadCount: existingUnreadCount ?? 0,
            unreadMessage : existingUnreadMessage
          },
        };
      });

      let membersWithUnread = updatedMembers.filter(item => item.data.unreadCount > 0);
      let membersWithoutUnread = updatedMembers.filter(item => item.data.unreadCount === 0);

      //keep members with unread counts at the beginning
      return [...membersWithUnread, ...membersWithoutUnread];
    });

    console.log(flag || '', 'curr_members', curr_members);
  });

  // these listener are necessary to add
  channel.on('presence', { event: 'join' }, ({ newPresences }) => {
    console.log('presence_event:New users have joined: ', newPresences);
  });

  channel.on('presence', { event: 'leave' }, ({ leftPresences }) => {
    console.log('presence_event: Users have left: ', leftPresences);
  });

  channel
    .on('broadcast', { event: 'chat' }, (payload) =>
      console.log('presence_event:', payload)
    )
    .subscribe();
};

export const sendrealtimemessage = async (eventname, customFields) => {
  const channel = supabase.channel(eventname);
  channel.subscribe((status) => {
    console.log('sent_message');
    if (status === 'SUBSCRIBED') {
      channel.send({
        type: 'broadcast',
        event: 'supa',
        payload: { visitorId: eventname, ...customFields },
      });
    }
  });
  // channelref.publish(eventname, { name: 'ahad', id: 'asd' }, function (err) {
  //   if (err) {
  //     console.log('Unable to publish message; err = ' + err.message);
  //   } else {
  //     console.log('Message successfully sent');
  //   }
  // });
};


export const generateLocationStr = (itemObj) => {
  if (
    itemObj?.location?.city &&
    itemObj?.location?.state &&
    itemObj?.location?.country &&
    itemObj?.location?.country === 'United States'
  ) {
    return `${itemObj?.location?.city}, ${itemObj?.location?.state}`;
  } else if (
    itemObj?.location?.city &&
    itemObj?.location?.state &&
    itemObj?.location?.country &&
    itemObj?.location?.country !== 'United States'
  ) {
    return `${itemObj?.location?.city}, ${itemObj?.location?.state} ${itemObj?.location?.country}`;
  } else if (
    itemObj?.location?.country &&
    (itemObj?.location?.city || itemObj?.location?.state)
  ) {
    return `${itemObj?.location?.city}${itemObj?.location?.state}, ${itemObj?.location?.country}`;
  } else if (itemObj?.location?.country) {
    return `${itemObj?.location?.country}`;
  } else {
    return '';
  }
}