import React from 'react';

function SidebarAnalyticsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        stroke="#446888"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.852 7.915l-3.117 4.765a1.052 1.052 0 01-1.824-.113l-.438-.891a1.052 1.052 0 00-1.824-.113l-3.117 4.765"
      ></path>
      <rect
        width="18.577"
        height="18.929"
        x="1.404"
        y="1.605"
        stroke="#446888"
        strokeWidth="2"
        rx="2"
      ></rect>
    </svg>
  );
}

export default SidebarAnalyticsIcon;
