import React, { useEffect, useState } from 'react';
import { LocationIcon } from './Icons';
import './common.css';
import {
  BackIcon,
  CloseIcon,
  FullscreenIcon,
  SettingsIcon,
  ShareIcon,
  VolumeFullIcon,
  VolumeMuteIcon,
} from './Icons';
import { formatTimestamp } from './util';
import './VideoMagnetControls.css';

const ExpandedVideoSettings = ({
  isMuted,
  toggleIsMuted,
  playbackRate,
  togglePlaybackRate,
}) => (
  <div
    className="settings-expanded video-control"
    onClick={(e) => e.stopPropagation()}
  >
    <div
      className="settings-expanded-label"
      onClick={(e) => {
        e.stopPropagation();
        toggleIsMuted();
      }}
    >
      <span>Volume</span>
      <span className="accent-text icon-text" style={{ color: 'white' }}>
        {isMuted ? (
          <>
            <VolumeMuteIcon />
            Off
          </>
        ) : (
          <>
            <VolumeFullIcon />
            On
          </>
        )}
      </span>
    </div>
    <div
      className="settings-expanded-label"
      onClick={(e) => {
        e.stopPropagation();
        togglePlaybackRate();
      }}
    >
      <span>Speed</span>
      <span className="accent-text" style={{ color: 'white' }}>
        {playbackRate}x
      </span>
    </div>
  </div>
);

const VideoMagnetControls = ({
  progress,
  duration,
  isMuted,
  toggleIsMuted,
  isFullScreen,
  toggleFullscreen,
  onBackClick,
  showBackButton,
  onClose,
  showShare,
  toggleShowShare,
  playbackRate,
  togglePlaybackRate,
  showDuration = true,
  showSettings = true,
  showChapterButton,
  iframeVideoUnderlayToggle,
  setIframeVideoUnderlayToggle,
  location = '',
}) => {
  const [expandSettings, setExpandSettings] = useState(false);
  useEffect(() => setExpandSettings(false), [showSettings]);
  useEffect(() => {
    window.closevideomethod = onClose;
  }, [onClose]);
  return (
    <div className="video-controls">
      <div
        className=" top-row-controls"
        // If not on the main video screen (e.g. form) don't propagate clicks that play/pause the background video
        onClick={(e) => (!showDuration || !showSettings) && e.stopPropagation()}
      >
        {showBackButton && (
          <button
            type="button"
            className="video-control icon-scale-transform hover-outline transition-all"
            onClick={onBackClick}
          >
            <BackIcon />
          </button>
        )}

        {showDuration && (
          <div className="video-timestamp">
            <span
              style={{
                marginBottom: 0,
              }}
            >
              {formatTimestamp((progress / 100) * duration)} /{' '}
              {formatTimestamp(duration)}
            </span>
          </div>
        )}
        {/* Pretty hacky, but also pretty convenient since controls are often added and removed */}
        <div className="controls-spacer" />
        {showChapterButton && (
          <button
            type="button"
            className="video-control icon-scale-transform hover-outline transition-all"
            // data-selected={showShare}
            // onClick={(e) => {
            //   e.stopPropagation();
            //   toggleShowShare();
            // }}
          >
            <ShareIcon />
          </button>
        )}
        {toggleShowShare && (
          <button
            type="button"
            className="video-control icon-scale-transform hover-outline transition-all"
            data-selected={showShare}
            onClick={(e) => {
              e.stopPropagation();
              toggleShowShare();
            }}
          >
            <ShareIcon />
          </button>
        )}
        {showSettings && (
          <button
            type="button"
            className="video-control icon-scale-transform hover-outline transition-all"
            data-selected={expandSettings}
            onClick={(e) => {
              e.stopPropagation();
              setExpandSettings((expand) => !expand);
            }}
          >
            <SettingsIcon />
          </button>
        )}
        <button
          type="button"
          className="video-control icon-scale-transform hover-outline transition-all"
          data-selected={isFullScreen}
          onClick={toggleFullscreen}
        >
          <FullscreenIcon />
        </button>
        {onClose && (
          <button
            type="button"
            className="video-control icon-scale-transform hover-outline transition-all"
            onClick={onClose}
          >
            <CloseIcon />
          </button>
        )}
        {/* Extra wrapper <div> for absolutely positioning ExpandedVideoSettings */}
        <div>
          {expandSettings && (
            <ExpandedVideoSettings
              isMuted={isMuted}
              toggleIsMuted={toggleIsMuted}
              playbackRate={playbackRate}
              togglePlaybackRate={togglePlaybackRate}
            />
          )}
        </div>
      </div>
      {location && (
        <p
          className="location-chip"
          style={{
            fontSize: 'small',
          }}
        >
          <LocationIcon
            style={{
              marginRight: 5,
              width: 15,
              height: 15,
            }}
          />
          {location}
        </p>
      )}
    </div>
  );
};

export default VideoMagnetControls;
