//material ui dialog box
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AnimatedDialog = ({ children, ...rest }) => {
  return (
    <>
      <Dialog
        BackdropProps={{
          style: { backgroundColor: 'rgba(17, 24, 39, 0.9)' },
        }}
        TransitionComponent={Transition}
        keepMounted
        disableBackdropClick={false}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth="true"
        {...rest}
      >
        {children}
      </Dialog>
    </>
  );
};

export default AnimatedDialog;
