import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ThankYouScreen from '../ThankYouScreen';
import WidgetWrapper from '../WidgetWrapper';
import ContactForm from './ReferralForm';
import { sendMessage } from './utils';
import moment from 'moment';

const Referral = ({ onBack, the_message, showBack = false }) => {
  const [step, setStep] = useState(1);
  const [userInfo, setUserInfo] = useState({
    message: the_message ? the_message : '',
    //: `[Contact Us : I am interested in the Floor plans and looking to begin renting on ${moment().format('L')}]`,
  });
  const [isContactUsLoading, setIsContactUsLoading] = useState(false);
  const { magnetId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  let msg = urlParams.get('msg', '')
    ? urlParams.get('msg', '')
    : userInfo?.message;
  let referralName = urlParams.get('ref', '');

  const isUserDataValid = (data) => {
    if (
      !data.message ||
      !data.firstname ||
      !data.lastname ||
      !data.email ||
      !data.phone
    ) {
      return 'Please fill all fields';
    } else if (data?.phone?.length != 10) {
      return 'Phone number length should be 10';
    }
  };

  const formSubmitHandler = async () => {
    console.log('data TYG:', userInfo);

    const validationError = isUserDataValid(userInfo);
    if (validationError) {
      return toast.error(validationError);
    }

    setIsContactUsLoading(true);
    const isMessageSent = await sendMessage(
      magnetId,
      {
        metadata: [
          {
            lead_type: 'Referral',
            message: userInfo.message,
            referral_source: referralName,
          },
        ],
      },
      userInfo,
      {
        setLoading: setIsContactUsLoading,
        onError: (msg) => toast.error(msg),
      }
    );

    setIsContactUsLoading(false);
    if (isMessageSent) setStep(2);
  };

  const handleOnGoBack = () => {
    if (step > 1) setStep((prev) => prev - 1);
    else if (onBack) onBack();
    else history.back();
  };

  useEffect(() => {
    const s = document.createElement('script');
    s.setAttribute('src', `https://embed.tour.video/leasemagnets.js`);
    s.setAttribute('async', 'async');
    s.setAttribute('defer', 'defer');
    console.log('attempt to render lease magnets TYG');
    s.addEventListener(
      'load',
      () =>
        window.LeaseMagnets &&
        window.LeaseMagnets({
          uuid: magnetId,
          layout: 'right',
          // primaryColor: '#4285f4',
        })
    );
    s.addEventListener('error', (e) => console.error(e));

    document.body.appendChild(s);

    return () => {
      window.destroyLeaseMagnets && window.destroyLeaseMagnets();
      document.body.removeChild(s); // Clean up
    };
  }, [magnetId]);

  return (
    <WidgetWrapper step={showBack === false ? 1 : 0} onBack={handleOnGoBack}>
      {step === 1 && (
        <div className="step-4 flex flex-col">
          <div className="meta">
            <div className="title font">Refer-A-Friend to The One</div>
            <div className="txt font">
              Fill out the form below and to refer a friend to this property.
            </div>
            <ContactForm
              info={userInfo}
              setInfo={setUserInfo}
              onSubmit={formSubmitHandler}
              isLoading={isContactUsLoading}
              defaultMessage={msg}
              defaultReferralName={referralName}
            />
          </div>
        </div>
      )}
      {step === 2 && (
        <ThankYouScreen
          text={`${userInfo.firstname}, thanks for contacting us.`}
          secText={
            'We have received your request and will get back to you soon !'
          }
          onGoBack={() => setStep((prev) => prev - 1)}
        />
      )}
    </WidgetWrapper>
  );
};

export default Referral;
