import React from 'react';
import Rating from '@material-ui/lab/Rating';

export default function StarRatingChip({ rating }) {
  return (
    <div className="bg-blue-50 rounded-full mt-2 p-2 flex gap-1 items-center">
      <img src="https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/9bc34e95-2527-4e62-a052-857b6ecc2200/w=25,sharpen=1" />
      {rating ? (
        <>
          <Rating value={rating} readOnly />
          <p className="text-xs text-gray-700">{rating} out of 5</p>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
