import React, { useState } from 'react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Tooltip from '@material-ui/core/Tooltip';
import { useEffect } from 'react';

// fake data generator
const faker = [
  { id: 1, content: '/r1.png' },
  { id: 2, content: '/r2.png' },
  { id: 3, content: '/r1.png' },
  { id: 4, content: '/r2.png' },
  { id: 5, content: '/r1.png' },
  { id: 6, content: '/r2.png' },
];
const getItems = (count) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k}`,
    content: faker[k].content,
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver, itemsLength) => ({
  display: 'flex',
  padding: grid,
  //   width: itemsLength * 68.44 + 16
});

const DragItem = ({ selectedVideos, setSelectedVideos, getScreen }) => {
  console.log(getItems(5));
  const [stateMy, setStateMy] = useState([]);
  useEffect(() => {
    let selectedScreenObjects = [];
    for (let i = 0; i < selectedVideos.length; i++) {
      const [category, screen] = selectedVideos[i]?.split('.');
      console.log(
        'TYG Screen: ',
        category,
        screen,
        getScreen(category, screen)
      );
      let screenObject = getScreen(category, screen);
      let img =
        screenObject?.img ||
        screenObject?.gif ||
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/d19257cc-8cb2-4ccd-b750-dbe52fbaa600/public';
      let title = screenObject?.title || `Screen ${i}`;
      selectedScreenObjects.push({
        id: `item-${i}`,
        content: img,
        title: title,
        route: `${category}.${screen}`,
      });
    }
    setStateMy(selectedScreenObjects);
  }, [selectedVideos]);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      stateMy,
      result.source.index,
      result.destination.index
    );

    setSelectedVideos([...items.map((item) => item.route)]);
    setStateMy([...items]);
  };

  return (
    <div className=" mt-6" style={{ overflow: 'scroll', minHeight: 100 }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver, stateMy.length)}
              {...provided.droppableProps}
            >
              {stateMy.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      // style={getItemStyle(
                      //   snapshot.isDragging,
                      //   provided.draggableProps.style
                      // )}
                    >
                      <Tooltip title={item?.title} placement="top">
                        <div className="border border-gray-400 w-12 rounded-sm">
                          <img
                            className=" w-full object-cover"
                            src={item?.content}
                            alt=""
                          />
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className=" w-full flex items-center justify-center">
        <div className=" bg-gray-200 px-2 text-sm rounded-2xl">
          {stateMy.length} videos/screens
        </div>
      </div>
    </div>
  );
};

export default DragItem;
