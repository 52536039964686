import { TextareaAutosize } from '@material-ui/core';
import axios from 'axios';
import React, { useState } from 'react';
import styles from '../views/manage-magnets/MagnetSummary.module.css';
import AnimatedDialog from './Reusable/AnimatedDialog';

const DynamicIframdialogue = ({
  open,
  setOpen,
  updateIframe,
  hyperIframeId,
}) => {
  const [openDesc, setOpenDesc] = useState(false);
  const [hyperIframeData, setHyperIframeData] = useState({
    first_name: 'Hi Name',
    custom_text_1: `Hi name,

    Thank you for asking for your question,
    we will get back to you as soon as possible.
    Yours truly, Amulya`,
    employees_range: 'Amulya Parmar',
    url: 'https://img.hyperise.io/v/408997c8f',
  });
  const submitiframe = async () => {
    try {
      try {
        let sampleId = hyperIframeId || new Date().getTime();
        await axios.post(
          `https://api.directual.com/good/api/v5/data/message/getAndCreateMessage?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83`,
          {
            id: sampleId,
            type: 'hyperise_message',
            data: {
              ...hyperIframeData,
            },
          }
        );

        updateIframe(sampleId);
        setOpen(false);
      } catch (err) {
        console.log('error while in directual', err);
        return false;
      }
      // const community = await axios.post( `https://api.directual.com/good/api/v5/data/community/newCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`)
    } catch (error) {
      console.log('error', error);
      console.log('error', error.response);
    }
  };

  return (
    <AnimatedDialog
      title="Edit Review Settings"
      open={open}
      onClose={() => setOpen(false)}
    >
      <div className="p-4">
        <button
          onClick={() => setOpen(false)}
          type="button"
          class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
        >
          <span class="sr-only">Close menu</span>
          <svg
            class="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className={styles.share_popup_title}>
          <span style={{ color: '#347DEF' }}>Create</span> Video with text
        </div>
        <input
          id="outlined-basic"
          label=""
          placeholder="Name"
          style={{ width: '80%', border: '1px solid rgb(202 202 202)' }}
          onBlur={(e) =>
            setHyperIframeData({
              ...hyperIframeData,
              first_name: e.target.value,
            })
          }
          defaultValue={hyperIframeData.first_name}
          //ref={textAreaRef}
          //disabled
        />
        <TextareaAutosize
          //id="outlined-basic"

          placeholder="write your message"
          style={{ width: '80%', border: '1px solid rgb(202 202 202)' }}
          className="mt-3"
          onBlur={(e) =>
            setHyperIframeData({
              ...hyperIframeData,
              custom_text_1: e.target.value,
            })
          }
          defaultValue={hyperIframeData.custom_text_1}
          //ref={textAreaRef}
          //disabled
        />
        <input
          id="outlined-basic"
          label=""
          style={{ width: '80%', border: '1px solid rgb(202 202 202)' }}
          placeholder="employees"
          variant="outlined"
          className="mt-3"
          onBlur={(e) =>
            setHyperIframeData({
              ...hyperIframeData,
              employees_range: e.target.value,
            })
          }
          defaultValue={hyperIframeData.employees_range}
        />
        {/* <TextField
          id="outlined-basic"
          label=""
          placeholder="url"
          variant="outlined"
          value={``}
          style={{ width: '80%' }}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          className="mt-3"
          onChange={(e) =>
            setHyperIframeData({ ...hyperIframeData, url: e.target.value })
          }
          value={hyperIframeData.url}
        /> */}
        {open && (
          <iframe
            src={`${hyperIframeData?.url}?first_name=${hyperIframeData?.first_name}&employees_range=${hyperIframeData?.employees_range}&custom_text_1=${hyperIframeData?.custom_text_1}&autoplay=false`}
            width="500px"
            height="500px"
          />
        )}

        <div
          className={styles.share_popup_next}
          style={{
            marginTop: '0px',
          }}
          onClick={submitiframe}
        >
          Save iframe
        </div>
      </div>
    </AnimatedDialog>
  );
};

export default DynamicIframdialogue;
