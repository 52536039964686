import React from 'react';
import ChatbotDialog from './ChatbotDialog';
import TourBubble from './TourBubble';

export default function TourWidgetPreview({
  defaultConfig,
  setDefaultConfig,
  updateData = {},
  filter = () => true,
  videoSrc,
  defaultBubbleType = 'Basic',
}) {
  return (
    <div
      className={`flex flex-col ${
        defaultConfig?.layout === 'right' && 'items-end'
      }`}
    >
      <ChatbotDialog
        defaultConfig={defaultConfig}
        setDefaultConfig={setDefaultConfig}
        updateData={updateData}
        filter={filter}
        defaultBubbleType={defaultBubbleType}
      />
      <TourBubble src={videoSrc} defaultConfig={defaultConfig} />
    </div>
  );
}
