import React from 'react';

function SidebarAdminIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 17 19"
    >
      <path
        fill="#446888"
        d="M7.22 11.773v7.211H.01a7.211 7.211 0 017.21-7.211zm0-.901a5.407 5.407 0 01-5.408-5.409A5.407 5.407 0 017.22.055a5.407 5.407 0 015.408 5.408 5.407 5.407 0 01-5.408 5.409zm8.113 3.605h.9v4.507h-7.21v-4.507h.901v-.901a2.704 2.704 0 115.409 0v.901zm-1.803 0v-.901a.902.902 0 00-1.803 0v.901h1.803z"
      ></path>
    </svg>
  );
}

export default SidebarAdminIcon;
