import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

const ContentLoader = (props) => {
  const { error, loading, children } = props;

  if (loading) {
    return (
      <div style={{ width: '100%', height: '100%', marginTop: '200px' }}>
        {' '}
        <center>
          {' '}
          <CircularProgress />{' '}
        </center>{' '}
      </div>
    );
  }

  if (error) {
    return <h2>Error: {error}</h2>;
  }

  return <>{children}</>;
};

export default ContentLoader;
