import React from 'react';

function IframeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="21"
      fill="none"
      viewBox="0 0 24 21"
    >
      <path
        fill="#3898EC"
        d="M21.429 0H2.57A2.571 2.571 0 000 2.571v4.286h24V2.571A2.571 2.571 0 0021.429 0zM4.286 6a1.714 1.714 0 110-3.429 1.714 1.714 0 010 3.429zM8.57 6a1.714 1.714 0 110-3.429 1.714 1.714 0 010 3.429zM0 8.571h24V18a2.571 2.571 0 01-2.571 2.571H2.57A2.572 2.572 0 010 18V8.571z"
      ></path>
      <path
        fill="#3898EC"
        d="M1.371 1.92H13.988000000000001V6.309H1.371z"
      ></path>
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.2"
        d="M5.484 14.147L8.45 11.7s0 0 0 0a.203.203 0 00.079-.157.203.203 0 00-.08-.158.258.258 0 00-.322 0h0l-3.154 2.601h0l-.01.01a.233.233 0 00-.063.141c-.003.06.02.118.071.162l3.145 2.877c.025.031.059.046.082.054.03.01.061.013.09.013a.246.246 0 00.082-.014.179.179 0 00.076-.051.222.222 0 00.075-.137.186.186 0 00-.069-.167l-2.968-2.727zM16.33 17.109l-.064.077a.212.212 0 01-.08-.151.208.208 0 01.056-.153l.006-.007h0l2.98-2.728-2.962-2.447s0 0 0 0h0l.063-.077c-.055-.046-.055-.114 0-.16a.156.156 0 01.195 0l-.195 5.646zm0 0l-.064.077c.049.04.114.057.16.057a.24.24 0 00.162-.057h0l.004-.003 3.146-2.884a.203.203 0 00.076-.156.203.203 0 00-.078-.157h0L16.33 17.11zM11.428 16.67v.001H11.502c.108 0 .22-.048.284-.125l.008-.01.006-.014 1.85-4.322a.162.162 0 00.018-.116.197.197 0 00-.054-.095.32.32 0 00-.185-.086.46.46 0 00-.2.02c-.051.018-.125.057-.153.132l-1.869 4.319a.162.162 0 00-.018.116c.01.04.031.071.054.095a.32.32 0 00.185.086z"
      ></path>
    </svg>
  );
}

export default IframeIcon;
