import React, { useEffect, useState } from 'react';
import { BiUser } from 'react-icons/bi';
import { BsCameraVideoFill, BsGlobe2 } from 'react-icons/bs';
import { CgMenuGridO } from 'react-icons/cg';
import { HiArrowRight, HiOutlineArrowRight } from 'react-icons/hi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdClose } from 'react-icons/md';
import { VscThreeBars } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import en from '../assets/home/en.png';
import google from '../assets/home/google.png';
import hy from '../assets/home/hy.png';
import inta from '../assets/home/inta.png';
import ss from '../assets/home/ss.png';
import t1 from '../assets/home/t1.png';
import t2 from '../assets/home/t2.jpeg';
import t3 from '../assets/home/t3.jpeg';
import t4 from '../assets/home/t4.jpeg';
import t5 from '../assets/home/t5.jpeg';
import t6 from '../assets/home/t6.png';
import tl1 from '../assets/home/tl1.png';
import tl2 from '../assets/home/tl2.png';
import tl3 from '../assets/home/tl3.png';
import tl4 from '../assets/home/tl4.png';
import tl5 from '../assets/home/tl5.svg';
import tl6 from '../assets/home/tl6.png';
import track from '../assets/home/track.png';
import tt from '../assets/home/tt.png';
import yar from '../assets/home/yar.png';
import GlobNavbar from '../components/GlobNavbar';

const Home1 = () => {
  useEffect(() => {
    const s = document.createElement('script');
    s.setAttribute('src', `https://embed.tour.video/leasemagnets.js`);
    s.setAttribute('async', 'async');
    s.setAttribute('defer', 'defer');

    s.addEventListener(
      'load',
      () =>
        window.LeaseMagnets &&
        window.LeaseMagnets({
          uuid: 'cedee600-ec79-4b1e-a159-892c4fd58761',
          layout: 'left',
          // primaryColor: '#ff2d55',
          widgetType: 'circle',
        })
    );
    s.addEventListener('error', (e) => console.error(e));

    document.body.appendChild(s);

    return () => {
      window.destroyLeaseMagnets && window.destroyLeaseMagnets();
      document.body.removeChild(s); // Clean up
    };
  }, []);

  const [active, setActive] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [tactive, setTactive] = useState(false);

  const communitySliderData = [
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/cc03b26d-c873-4d09-d8e1-517e0e062400/150pxProfileTYG',
      caption: '25k Tours',
      communityName: 'Marshall Louisville',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/bc2ef844-b351-4404-23c5-ca2352fcc000/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/6f8904fc-89d8-4375-2fc2-7c9d6dadf200/150pxProfileTYG',
      caption: '1.4k International Tours',
      communityName: 'Hawks Landing',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/adeba541-555e-44cf-b8f4-64d2158c7d00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/734d98e7-d07f-47a7-20ed-7b500f420b00/150pxProfileTYG',
      caption: '$70k+ Revenue Generated from Toru',
      communityName: 'Campus Walk',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/f579de30-ea39-4214-0612-8f80d34eaa00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/4909e798-3cd4-44ba-509f-f14a9ec01200/150pxProfileTYG',
      caption: '21k Tours',
      communityName: 'Latitude',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/106dc1ed-9f24-4b2a-eaf5-f7a4ab370000/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/e2fcd283-7b3d-4463-c327-fbc3c50f6100/150pxProfileTYG',
      caption: '$85k Revenue Generated',
      communityName: 'Noble 2500',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/1ba9d2e5-baf5-4417-a0d3-c23a48f57a00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/7167ce7e-3135-49a8-0ea4-2a614e967900/150pxProfileTYG',
      caption: '15k Tours + $300k Revenue Generated',
      communityName: 'CastleRock San Marcos',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/64756cb6-51b5-4b38-eda5-79740913ec00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/1d911fb6-23ba-4507-141a-0da95278c900/150pxProfileTYG',
      caption: '3k Tours +  $220k Revenue Generated',
      communityName: 'Paloma West Midtown',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/38bf0294-d7f5-4a2f-4a86-c309b2db9c00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/0f63db51-d9f6-420f-8eba-759fd0073100/150pxProfileTYG',
      caption: '7.4k Tours + $850k Revenue Generated',
      communityName: 'One Park',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/1e5b8871-d023-4b4c-bdc2-d7d8a0683400/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/3c550765-aae7-4a77-2507-7f66e635b000/150pxProfileTYG',
      caption: '18k Tours',
      communityName: 'Lodges at 777',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/ac58e77c-37da-42ff-8898-0cba57e6af00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/b01ef428-6a14-4661-4b0b-4d3c9f04e600/150pxProfileTYG',
      caption: '12k Tours + $550k Revenue Generated',
      communityName: 'HWH Luxury Living',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/1fa1af12-4aa5-4820-49ef-3a3bcbdebe00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/e1d7b510-f60a-4b43-c83d-2146ad4e3a00/150pxProfileTYG',
      caption: '125% increase in conversion',
      communityName: 'Douglas Heights',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/98a44ab2-8726-4204-c056-694f926b9600/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/41105640-d9a0-46e9-b7f4-287dba728300/150pxProfileTYG',
      caption: '150% increase in conversion',
      communityName: 'Arba',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/a4849728-6920-4ef4-c889-e29365a86500/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/ff27792c-3d5f-4ec8-f42d-7b91dd582f00/150pxProfileTYG',
      caption: '$129k in Revenue generated',
      communityName: 'The Rowan',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/b731c164-2f1f-475b-0d41-fce2f08b2a00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/fe586b4f-4e04-420c-7978-b9a9f361e700/150pxProfileTYG',
      caption: '120% Conversion Rate',
      communityName: 'The Rockland',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/b731c164-2f1f-475b-0d41-fce2f08b2a00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/25542c0e-6369-40eb-e7b6-53aaa48cd800/150pxProfileTYG',
      caption: '$40k Revenue Generated',
      communityName: 'The Hue',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/2322595b-7320-491c-239d-4248af567300/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/3f316b49-e66a-49bf-237e-61ec9ae9f900/150pxProfileTYG',
      caption: '5.5k Tours + $40k in Leadgen',
      communityName: 'The Academy on Charles',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/411c005f-4415-495f-659e-3dde79202900/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/ff99c810-45b3-4221-90f5-7f72749e3100/150pxProfileTYG',
      caption: '6.2k Tours + $241k in Leadgen',
      communityName: 'The Village at Chandler Crossings',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/c065befd-23d3-41aa-b45e-ad736ceb6700/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/3f36217d-64a7-4206-fe12-c8f321649c00/150pxProfileTYG',
      caption: '6.2k Tours + 340 5-Star Testimonials',
      communityName: 'The Edge on Euclid',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/f19125bc-b789-473d-236f-8c502895d900/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/6acb8d71-74f4-4a8a-ecbf-e3e083661e00/150pxProfileTYG',
      caption: '300+ Applicants',
      communityName: 'Desai Accelerator',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/67d332fd-c506-46fb-40e0-a161d24da800/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/3e1a95d3-e381-45e7-b62a-5bb064736700/150pxProfileTYG',
      caption: '$200k Revenue from Tour',
      communityName: 'HCC College',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/ba63783c-ba12-4505-2593-42feb2ce9700/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/26a7fd6e-0571-474e-85c5-8ab6dd4fcd00/150pxProfileTYG',
      caption: '$240k generated + 340 Reviews',
      communityName: 'Forward Medical',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/cee35afd-a1ce-45ed-d89a-60ad6ad7bd00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/baab8f17-bc67-42a7-520c-842d652afe00/150pxProfileTYG',
      caption: '210% Conversion Rate & Hired 2 Folks',
      communityName: 'Lambda School/ Bloomtech',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/53ea0757-f5f8-4c61-67f3-b7d64e53f300/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/b9d63c8a-cdf3-48b2-9540-055236e5ab00/150pxProfileTYG',
      caption: '150% increase in Conversion',
      communityName: 'PNOE',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/56cd2bef-a390-4a20-6228-d0b3454e2100/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/7a2cff03-6b58-4f53-80fe-c93743be6400/150pxProfileTYG',
      caption: '8k Onboarded',
      communityName: 'DESO',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/b831e49d-f1dd-4b10-4131-3984734e4300/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/b4f16062-a6ac-4a2f-b210-11b3a9611600/150pxProfileTYG',
      caption: '140%+ Onboarding increase + 215.7k Followers',
      communityName: 'Creator.app',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/df398489-f15f-415d-c69a-9b05a182b400/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/24662403-ae4b-4abb-6231-3a4244bb1100/150pxProfileTYG',
      caption: '210% increase in conversion',
      communityName: 'RAMP',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/9be7e2b4-42b0-40b7-b1eb-4f5893488f00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/1c9259ef-1877-4774-ec5e-707eb62f0e00/150pxProfileTYG',
      caption: 'Onboarded 15k new Engineers',
      communityName: 'WeWork',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/09a53245-fe76-4620-2f8e-c45aedced900/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/3a47b3a7-75a1-4142-7ddb-db9c28f43100/150pxProfileTYG',
      caption: '124%+ in Conversion',
      communityName: 'University of Michigan',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/67a6cb75-2399-4794-a589-4fba7e8db300/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/179e0c4a-3ab1-4f91-045d-fcbe4e26b500/150pxProfileTYG',
      caption: 'Onboarded 7k Community',
      communityName: 'SaaStr',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/ededba95-3e75-4d19-4f5a-ac9e128c1a00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/6ebcf654-666c-4462-81f8-88a65b3b6d00/150pxProfileTYG',
      caption: '150% in Conversion + $440k in Sales',
      communityName: 'X Communities',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/d7fde5fe-6428-42b8-b44b-912124a15a00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/b06d761a-2dba-4242-1b00-175602644100/150pxProfileTYG',
      caption: '12k Tours + $440k in Sales',
      communityName: 'Two East Oak',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/fe2cff69-52a8-4a5b-a891-a8f86e13a600/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/9e688e13-1762-45d9-ca59-836284857c00/150pxProfileTYG',
      caption: '150% in Onboarding Conversion',
      communityName: 'YC',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/055141a0-8e56-4a3a-20bd-dd089d3b2b00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/2ce0b833-5605-4d7c-cdc1-20f8fcb60500/150pxProfileTYG',
      caption: '$1.2m in Tour-driven Sales',
      communityName: 'Independence Village Oxford',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/de18508f-0142-4ef2-47d6-96d66ecd6f00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/7a0947d9-1dfe-4645-32a7-44cab66a7c00/150pxProfileTYG',
      caption: '4k Tours and $800k in Tour-driven Sales',
      communityName: 'The George',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/8b6b4e88-4b7f-4c3c-6915-c3a75d9d2600/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/e7361926-fa46-4963-42fe-bf99570cbe00/150pxProfileTYG',
      caption: '2k Tours and $750k in Tour-driven Sales',
      communityName: 'Park Place',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/a202f566-30a4-4b09-972c-1cb221772b00/400pxHeight',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/06828e5d-6e41-4981-824b-24d7cf61a600/150pxProfileTYG',
      caption: '89%+ in Conversion',
      communityName: 'Speechify',
      cover:
        'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/806e516a-3bd3-4dee-5385-1577afad3c00/400pxHeight',
      tourLink: '',
    },
  ];

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [activeBtn, setActiveBtn] = useState(0);
  React.useEffect(() => {
    let header = document.getElementById('showheader');
    document.getElementById('header_box').onmouseover = function callheader() {
      header.style.display = 'block';
    };

    document.getElementById('showheader').onmouseover = function callheader() {
      header.style.display = 'block';
    };
    document.getElementById('showheader').onmouseout = function callheader() {
      header.style.display = 'none';
    };

    document.getElementById('header_box').onmouseout = function callheader() {
      header.style.display = 'none';
    };
  }, []);

  let testimonials = [
    {
      name: 'Brian Nalezny',
      position: 'Regional Manager, PeakMade',
      review:
        'Tour is easy to work with and extremely receptive. They took my suggestion to add a Mandarin tour option and immediately came back with an updated product for us. We are in a beneficial partnership that has translated to an immediate increase in leads at properties where we use the tool',
      image: t1,
      cimg: tl1,
    },
    {
      name: 'Andrea Arbuckle',
      position: 'Regional Leasing/Marketing Manager, Landmark Properties',
      review:
        "Easy to use and effective. I feel better knowing that I'm in control of who can see my data.",
      image: t2,
      cimg: tl2,
    },
    {
      name: 'Amanda Kopko',
      position: 'McKinney Properties',
      review:
        '...This is what we use Tour for. It helps with the introduction to our website and allows someone browsing apartments at 1 am to feel like they are actually walking in the door. It gives them an in person experience before they even pick up the phone.',
      image: t3,
      cimg: tl3,
    },
    {
      name: 'KrisAnn Kizerr',
      position: 'Director, Pierce Education Properties',
      review:
        '“Tour is a great way to offer a creative solution to that problem. We now have properties where 30-40% of the people that sign have never toured in person. Their first time seeing the property will be move-in day.”',
      image: t4,
      cimg: tl4,
    },
    {
      name: 'David Ramos',
      position: 'DMG Investments',
      review:
        '“Huge fan of what you guys are building and think there’s a lot of fit outside of the property management space. I could see luxury realtors/brokers using the tool!”',
      image: t5,
      cimg: tl5,
    },
    {
      name: 'TjChambers',
      position: 'Chambers REA / EVP at Asset Living',
      review:
        '“These guys are building the tour video digital marketing platform of the future — I’ve recommended them to countless clients already!”',
      image: t6,
      cimg: tl6,
    },
  ];

  const [imgActive, setImgActive] = useState(0);
  return (
    <div className=" w-full min-h-screen relative overflow-x-hidden">
      <div className=" w-full bg-black  relative z-30">
        <div className="container relative">
          <div className=" items-center justify-between hidden lg:flex">
            <div className=" flex items-center">
              <Link to="/">
                <div>
                  <h1 className="font_uber py-4 text-2xl inline items-center cursor-pointer text-white">
                    {/* leasemag
                  <img src={noto_magnet} className="w-4 mt-1 h-4" alt="" />
                  ets */}
                    Tour
                  </h1>
                  <h3 className="font_uber text-white font-size-12 opacity-60">
                    for Universities
                  </h3>
                </div>
              </Link>
              <div className=" relative  pl-10 flex items-center justify-center gap-8">
                <Link
                  to="/"
                  id="header_box"
                  className=" flex items-center py-4  relative justify-center gap-1 text-white font-light "
                >
                  <CgMenuGridO /> Product
                </Link>
                <Link
                  to="/results"
                  className=" flex items-center py-4 justify-center gap-1 text-white font-light "
                >
                  Results
                </Link>
              </div>
            </div>
            <div className=" flex items-center py-4 gap-4">
              <Link
                to="/"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BsGlobe2 /> EN
              </Link>
              <Link
                to="/signin"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BiUser /> Sign In
              </Link>
              <Link
                to="/signup"
                className=" flex items-center hover:bg-white font-semibold hover:text-black text-sm justify-center gap-1 bg-white font-light text-black py-2  px-2"
              >
                Sign Up
              </Link>
            </div>
          </div>
          <div id="showheader" className=" absolute w-full  top-16 left-0">
            <GlobNavbar />
          </div>
          {toggle && (
            <div className=" items-center justify-between flex lg:hidden px-2 py-3">
              <div className=" flex items-center">
                <h1 className="font_uber text-2xl cursor-pointer text-white">
                  Tour
                </h1>
              </div>
              <div className=" flex items-center gap-2">
                <Link
                  to="/"
                  className=" flex items-center text-sm justify-center gap-1 text-white font-light "
                >
                  <BsGlobe2 className=" cursor-pointer text-white w-5 h-5" />
                </Link>
                <Link
                  to="/signin"
                  className=" flex items-center text-sm justify-center gap-1 text-white font-light "
                >
                  <BiUser className=" cursor-pointer text-white w-5 h-5" />
                </Link>
                <Link
                  to="/signup"
                  className=" flex items-center rounded-3xl hover:bg-white hover:text-black text-xs justify-center gap-1 bg-white font-light text-black py-2  px-2"
                >
                  Sign Up
                </Link>
                {toggle ? (
                  <VscThreeBars
                    onClick={() => setToggle(false)}
                    className=" cursor-pointer text-white w-5 h-5"
                  />
                ) : (
                  <MdClose
                    onClick={() => setToggle(true)}
                    className=" cursor-pointer text-white w-5 h-5"
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div
          style={
            !toggle
              ? {
                  height: '100vh',
                  borderTop: '1px solid rgba(255,255,255,0.3)',
                  background: '#000',
                  overflowY: 'scroll',
                  zIndex: '9999999999',
                }
              : { height: '0px', overflow: ' hidden' }
          }
          className="fixed top-0  bg-black left-0 transition-all  ease-linear   duration-300  w-full block lg:hidden"
        >
          {toggle === false && (
            <div className=" items-center justify-between flex lg:hidden px-2 py-3">
              <div className=" flex items-center">
                <h1 className="font_uber text-2xl cursor-pointer text-white">
                  Tour
                </h1>
              </div>
              <div className=" flex items-center gap-2">
                <Link
                  to="/"
                  className=" flex items-center text-sm justify-center gap-1 text-white font-light "
                >
                  <BsGlobe2 className=" cursor-pointer text-white w-5 h-5" />
                </Link>
                <Link
                  to="/signin"
                  className=" flex items-center text-sm justify-center gap-1 text-white font-light "
                >
                  <BiUser className=" cursor-pointer text-white w-5 h-5" />
                </Link>
                <Link
                  to="/signup"
                  className=" flex items-center rounded-3xl hover:bg-white hover:text-black text-xs justify-center gap-1 bg-white font-light text-black py-2  px-2"
                >
                  Sign Up
                </Link>
                {toggle ? (
                  <VscThreeBars
                    onClick={() => setToggle(false)}
                    className=" cursor-pointer text-white w-5 h-5"
                  />
                ) : (
                  <MdClose
                    onClick={() => setToggle(true)}
                    className=" cursor-pointer text-white w-5 h-5"
                  />
                )}
              </div>
            </div>
          )}
          <div className=" px-5">
            <div
              onClick={() => setTactive(!tactive)}
              className=" flex cursor-pointer items-center justify-between w-full pb-3 text-3xl     gap-1 text-white font_uber pt-10"
            >
              Product
              {tactive === false ? <IoIosArrowDown /> : <IoIosArrowUp />}
            </div>
            {tactive && (
              <div className=" w-full mt-3">
                <div className=" w-full  shadow-2xl h-full flex flex-col py-4 gap-10">
                  <div className=" lg:pl-20">
                    <h6 className=" font_cwb text-sm font-semibold text-white">
                      USE CASES
                    </h6>
                    <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                      <img src={tt} className=" w-7 h-7 object-cover" alt="" />
                      <div>
                        <h6 className=" font_cwb text-sm text-white">
                          Tour & Triggers{' '}
                        </h6>
                        <p className="text-xs text-gray-200">
                          Make it easy to build an embeddable & shareable tour
                        </p>
                      </div>
                    </div>
                    <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                      <img
                        src="https://www.intercom.com/_next/static/images/solutions--engagement-bc48a36991ab0b915c148bd61c6c1d06.svg"
                        className=" w-7 h-7 object-cover"
                        alt=""
                      />
                      <div>
                        <h6 className=" font_cwb text-sm text-white">
                          Trust & Testimonials
                        </h6>
                        <p className="text-xs text-gray-200">
                          Build more trust through testimonials
                        </p>
                      </div>
                    </div>
                    <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                      <img
                        src="https://www.intercom.com/_next/static/images/solutions--marketing-0fc6ec0aeab50485489ad1f6da1a1e5d.svg"
                        className=" w-7 h-7 object-cover"
                        alt=""
                      />
                      <div>
                        <h6 className=" font_cwb text-sm text-white">
                          Targeting & Retargeting
                        </h6>
                        <p className="text-xs text-gray-200">
                          Create targeted specials and online campaigns
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" lg:pl-20">
                    <h6 className=" font_cwb text-sm font-semibold text-white">
                      LEARN
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> Welcome Videos</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> Tour Videos</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> Analytics</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> Retargeting</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> CTA’s and Triggers</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> Integrations</Link>
                    </h6>
                  </div>
                  <div className=" lg:pl-20">
                    <h6 className=" font_cwb text-sm font-semibold text-white">
                      WATCH A DEMO
                    </h6>
                    <div className=" mt-3">
                      <img src={ss} className=" h-20 object-cover " alt="" />
                    </div>
                    <p className=" text-gray-200 text-sm py-2">
                      Learn from a professional what makes <br />
                      Tour work behind the scenes
                    </p>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform font-medium flex items-center gap-24 transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                      <Link to="/book-demo"> BOOK A DEMO</Link>
                      <HiArrowRight className=" w-5 h-5" />
                    </h6>
                  </div>
                </div>
              </div>
            )}
            <Link
              style={{ borderTop: '1px solid rgba(255,255,255,0.3)' }}
              to="/results"
              className=" flex items-center  text-3xl d justify-between pt-3  gap-1 text-white font_uber "
            >
              Results
              <p></p>
            </Link>
          </div>
        </div>
      </div>
      <div className=" container">
        <div className=" relative w-full h-full  rounded-2xl overflow-hidden">
          {/* <img src="https://res.cloudinary.com/kingdomkid/image/upload/v1655051910/build_tour_sample_TYG_2_uwvm0a.png"
        className=" relative "
        width="100%"
        style={{ objectFit: 'cover', height: '669.71px' }}
         /> */}

          <div style={{ background: '#F5F5F5' }}>
            <div className=" container">
              <div className="  pl-4 lg:pl-0 grid grid-cols-1 items-center gap-10 lg:grid-cols-12">
                <div className=" lg:col-span-7">
                  <h1 className=" text-4xl  font_uber text-left">
                    Transform your website
                    <br />
                    into your university tour guide
                  </h1>
                  <p className="  text-sm pt-2">
                    <h2 className="text-lg mt-[-15px] mb-2	">
                      Convert 85% more of your vistors{' '}
                      <span className=" underline">
                        into applications, signed up students, and in-person
                        visitors!{' '}
                      </span>
                    </h2>
                  </p>

                  <div className=" flex items-start lg:items-center lg:gap-3 text-xl font_uber flex-col lg:flex-row">
                    <Link to="/book-demo">
                      <button className=" mt-4 px-3 text-sm py-3 flex items-center gap-2 hover:bg-rr bg-rr rounded-sm text-white">
                        <BsCameraVideoFill /> Book personalized 1-1 demo
                      </button>
                    </Link>
                    <Link to="/signin">
                      <button className=" mt-4 px-3 font-light text-sm text-black py-3 flex items-center gap-2 hover:bg-gray-200 bg-gray-200 rounded-sm ">
                        <img
                          src={google}
                          className=" w-6 h-6 object-cover"
                          alt=""
                        />{' '}
                        Try Tour for 30 days
                      </button>
                    </Link>
                  </div>
                  <div className=" mt-16 flex items-center gap-4">
                    <div className=" flex items-center gap-0">
                      {communitySliderData.slice(0, 6).map((item, ind) => (
                        <div
                          className={
                            ind !== 0
                              ? ' w-7 h-7 rounded-full relative overflow-hidden  -ml-1'
                              : ' w-7 h-7 overflow-hidden relative rounded-full '
                          }
                        >
                          <img
                            className=" w-full h-full object-cover"
                            src={item?.agent}
                            alt=""
                          />
                          <div
                            className=" absolute top-0 left-0 w-full h-full rounded-full z-20"
                            style={{ background: 'rgba(0,0,0,0.3)' }}
                          ></div>
                        </div>
                      ))}
                    </div>
                    <p className=" ">
                      Loved by 500+ Communities & Property Managers
                    </p>
                  </div>
                </div>
                <div className=" lg:col-span-5">
                  <video
                    autoPlay
                    loop
                    muted
                    controls
                    src={
                      'https://customer-qqdk2u3dbwgfurzm.cloudflarestream.com/eb5f4df7dd5746801bed83464f8a6e00/downloads/default.mp4'
                    }
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      minHeight: '400px',
                    }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" grid grid-cols-2 gap-10 md:grid-cols-3 lg:grid-cols-6 py-10 ">
          <img
            src={
              'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/56f990c8-1438-420a-77b0-e6eb8b2a3700/400pxHeight'
            }
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src={
              'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/8373ae9c-bb66-4dc9-74a8-a2b66feee700/400pxHeight'
            }
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src={
              'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/af7a3600-fa0a-4c54-0519-17be75e5fc00/400pxHeight'
            }
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src={
              'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/18387297-6c3f-4b6c-5a2a-00dd35b57000/400pxHeight'
            }
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src={
              'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/92035f5f-f099-4014-ae55-52961a679200/400pxHeight'
            }
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src={
              'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/816e67cd-b1c3-4181-e5f1-950fdc112300/400pxHeight'
            }
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
        </div>
      </div>

      <div className=" bg-black mt-20 py-16">
        <h1 className=" text-center text-3xl text-white w-full font_uber">
          Don’t wait for your competitor <br />
          to build a better tour
        </h1>
        <p className=" text-sm pt-2 text-white text-center ">
          Use guided video, CTA’s and special offers, and <br />
          testimonials to increase conversion by 150% today
        </p>
        <div className=" flex  justify-center items-center lg:gap-3 text-white text-xl  font_uber w-full flex-col lg:flex-row">
          <button className=" mt-4 px-3 font-thin text-sm py-3 flex items-center gap-2  bg-black rounded-sm text-white">
            <BsCameraVideoFill /> Book personalized 1-1 demo
          </button>
          <button className=" mt-4 px-3 font-thin text-sm py-3 flex items-center gap-2 hover:bg-gray-800 bg-black rounded-sm text-white">
            <img src={google} className=" w-6 h-6 object-cover" alt="" /> Build
            your tour for free
          </button>
        </div>
        <div className=" container pt-16 pb-4">
          <div className=" grid grid-cols-1 gap-5 pl-5 lg:pl-0 lg:grid-cols-4">
            <div className=" lg:col-span-2">
              <h1 className=" text-2xl text-white">Tour</h1>
              <p className=" text-gray-400 text-sm flex mb-2">Backed by </p>
              <p className=" text-gray-400 text-sm flex">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_24_57)">
                    <rect width="24" height="24" rx="5.4" fill="#FF5100"></rect>
                    <rect
                      x="0.5"
                      y="0.5"
                      width="23"
                      height="23"
                      rx="4.9"
                      stroke="#FF844B"
                    ></rect>
                    <path
                      d="M7.54102 7.31818H9.28604L11.9458 11.9467H12.0552L14.715 7.31818H16.46L12.7662 13.5028V17.5H11.2349V13.5028L7.54102 7.31818Z"
                      fill="white"
                    ></path>
                  </g>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="4.9"
                    stroke="#FF5100"
                    stroke-opacity="0.1"
                  ></rect>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="4.9"
                    stroke="url(#paint0_radial_24_57)"
                  ></rect>
                  <defs>
                    <radialGradient
                      id="paint0_radial_24_57"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(7.35) rotate(58.475) scale(34.1384)"
                    >
                      <stop stop-color="white" stop-opacity="0.56"></stop>
                      <stop
                        offset="0.28125"
                        stop-color="white"
                        stop-opacity="0"
                      ></stop>
                    </radialGradient>
                    <clipPath id="clip0_24_57">
                      <rect width="24" height="24" rx="5.4" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
                <b className="text-white ml-1 mr-1">YCombinator</b> and{' '}
                <b className="text-white ml-1 mr-1">Zell Founder's Fund</b>
              </p>
            </div>
            <div className=" flex flex-col items-start">
              <h6 className=" text-white font-medium font_uber">🏢 Business</h6>
              <Link className=" text-gray-400 text-sm font_uber mt-2" to="/">
                Terms and conditions
              </Link>
              <Link className=" text-gray-400 text-sm font_uber" to="/">
                Reviews
              </Link>
              <Link className=" text-gray-400 text-sm font_uber" to="/">
                Contact
              </Link>
            </div>
            <div className=" flex flex-col items-start">
              <h6 className=" text-white font-medium font_uber">👋 Support</h6>
              <p className=" text-gray-400 text-sm pt-2">
                📞 +1 (586) 258-8588
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home1;
