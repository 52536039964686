import { CgSpinner } from 'react-icons/cg';

const LoadingIndicator = ({ size, containerOff }) => {
  const Loader = (
    <CgSpinner
      size={size ? size : 24}
      className="animate-spin text-center text-blue-500"
    />
  );

  if (containerOff) return Loader;

  return <div>{Loader}</div>;
};

export default LoadingIndicator;
