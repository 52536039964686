import React from 'react';
import { Link } from 'react-router-dom';
import { NewHeader } from '../views/Home';

const Header = ({ url = '/signin' }) => {
  return (
    <>
      {/* <Link to={url}>
        {window.location.hostname.includes('tour.video') ? (
          <img
            // src={require('./assets/tour-video-NEW-TYG-logo.png').default}
            width={250}
          />
        ) : window.location.hostname.includes('leasemagnets.com') ? (
          <img
            // src={require('./assets/leasemagnets-TYG.png').default}
            width={250}
          />
        ) : (
          <div className="logo-txt font_uber font s28 b6 c000">Tour</div>
        )}
      </Link> */}

      <NewHeader />
    </>
  );
};

export default Header;
