import {
  FormControl,
  Select,
  MenuItem,
  IconButton,
  TextField,
  InputLabel,
} from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';
import React from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

export function ActionRow({ action, setAction, removeAction }) {
  return (
    <div className="border rounded-xl p-4 flex gap-2 w-full">
      <FormControl variant="outlined" className="flex-grow">
        <InputLabel id="action-type-select">Type</InputLabel>
        <Select
          labelId="action-type-select"
          value={action?.type ?? ''}
          onChange={(e) => {
            setAction({ ...action, type: e.target.value });
          }}
        >
          <MenuItem value="sms">SMS Message</MenuItem>
          <MenuItem value="msteams">Microsoft Teams Webhook</MenuItem>
          <MenuItem value="slack">Slack Webhook</MenuItem>
        </Select>
      </FormControl>
      {action?.type === 'sms' ? (
        <PhoneInput
          placeholder="Enter phone number"
          value={action?.target}
          onChange={(newValue) => setAction({ ...action, target: newValue })}
          className="flex-grow"
        />
      ) : action?.type === 'msteams' ? (
        <TextField
          label="Webhook URL"
          value={action?.target}
          variant="outlined"
          onChange={(e) => setAction({ ...action, target: e.target.value })}
          className="flex-grow"
        />
      ) : action?.type === 'slack' ? (
        <TextField
          label="Webhook URL"
          value={action?.target}
          variant="outlined"
          onChange={(e) => setAction({ ...action, target: e.target.value })}
          className="flex-grow"
        />
      ) : (
        <></>
      )}
      <IconButton
        onClick={removeAction}
        className="hover:text-red-500 flex-none duration-200 ease-in-out"
      >
        <DeleteForever />
      </IconButton>
    </div>
  );
}
