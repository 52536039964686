import { useState } from 'react';

const AddStripeIdModal = ({ stripeId, handleClose, handleSave }) => {
  const [inputText, setInputText] = useState(stripeId);

  const handleOnClick = (e) => {
    e.stopPropagation();
  };

  const handleOnSave = () => {
    handleSave(inputText);
    handleClose();
  };

  return (
    <div
      onClick={handleClose}
      style={{ background: 'rgba(0, 0, 0, 0.1)' }}
      className="w-screen h-screen fixed top-0 left-0 z-20 flex items-center justify-center"
    >
      {/* <--- Centered Box ---> */}
      <div
        onClick={handleOnClick}
        className="bg-white flex flex-col gap-4 max-w-lg p-4 rounded"
      >
        <p className="text-lg">Adjust Stripe Customer Id</p>
        <input
          placeholder="Stripe Customer ID"
          value={inputText}
          onChange={(e) => setInputText(e.currentTarget.value)}
          className="p-2 border rounded my-2"
          style={{ minWidth: 350 }}
        />
        {/* <--- Buttons ---> */}
        <div className="text-sm flex justify-end gap-4">
          <button
            onClick={handleClose}
            className="px-3 py-2 cursor-pointer text-gray-500 border border-gray-500 rounded"
          >
            Back
          </button>
          <button
            onClick={handleOnSave}
            className="px-3 py-2 cursor-pointer text-white bg-blue-400 rounded"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddStripeIdModal;
