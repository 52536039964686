import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
//Material UI
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import React, { useState } from 'react';
import './Extend.css';

export default function Extend() {
  //Keep track of whether to display extend screen or not
  const [mode, setMode] = useState(false);
  const [placement, setPlacement] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setMode((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  return (
    <div>
      <Popper open={mode} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Popup />
          </Fade>
        )}
      </Popper>
      <button onClick={handleClick('right')}>Details</button>
    </div>
  );
}

const Popup = () => {
  return (
    <Paper elevation={3} square={false} className="paperSize">
      <center>
        <br />
        <Typography variant="h4" gutterBottom>
          <a href="#">Studio</a>
        </Typography>
        <Divider />
        <Typography variant="h6" gutterBottom>
          Gallery
        </Typography>
        <Grid container spacing={3} justify="center">
          <Grid item>
            <img
              src="https://www.thesprucepets.com/thmb/sfuyyLvyUx636_Oq3Fw5_mt-PIc=/3760x2820/smart/filters:no_upscale()/adorable-white-pomeranian-puppy-spitz-921029690-5c8be25d46e0fb000172effe.jpg"
              className="gallery-images"
              alt="dog"
            />
          </Grid>
          <Grid item>
            <img
              src="https://www.thesprucepets.com/thmb/sfuyyLvyUx636_Oq3Fw5_mt-PIc=/3760x2820/smart/filters:no_upscale()/adorable-white-pomeranian-puppy-spitz-921029690-5c8be25d46e0fb000172effe.jpg"
              className="gallery-images"
              alt="dog"
            />
          </Grid>
          <Grid item>
            <img
              src="https://www.thesprucepets.com/thmb/sfuyyLvyUx636_Oq3Fw5_mt-PIc=/3760x2820/smart/filters:no_upscale()/adorable-white-pomeranian-puppy-spitz-921029690-5c8be25d46e0fb000172effe.jpg"
              className="gallery-images"
              alt="dog"
            />
          </Grid>
          <Grid item>
            <img
              src="https://www.thesprucepets.com/thmb/sfuyyLvyUx636_Oq3Fw5_mt-PIc=/3760x2820/smart/filters:no_upscale()/adorable-white-pomeranian-puppy-spitz-921029690-5c8be25d46e0fb000172effe.jpg"
              className="gallery-images"
              alt="dog"
            />
          </Grid>
        </Grid>
        <br />
        <Divider />
        <Typography variant="h6" gutterBottom>
          Customer Reviews
        </Typography>
        <table>
          <thead>
            <th>Review</th>
            <th>Rating</th>
          </thead>
          <tbody>
            <tr>
              <td>
                <Typography variant="body2" component="span">
                  Incredible...free laundry every week & soundproof walls!
                </Typography>
                <Typography color="primary" gutterBottom>
                  - Amulya P.
                </Typography>
              </td>
              <td>
                <Rating name="read-only" value={2} readOnly />
              </td>
            </tr>
          </tbody>
        </table>
      </center>
    </Paper>
  );
};
