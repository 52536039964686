import React from 'react';

function SidebarHomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 30 26"
    >
      <path
        fill="#446888"
        d="M15.612.213a1.01 1.01 0 00-1.242 0L0 11.418l1.243 1.573L3 11.62V24a2.004 2.004 0 002 2h20a2.004 2.004 0 002-2V11.63L28.757 13 30 11.427 15.612.213zM17 24h-4v-8h4v8zm2 0v-8a2.002 2.002 0 00-2-2h-4a2.002 2.002 0 00-2 2v8H5V10.062l10-7.79 10 7.8V24h-6z"
      ></path>
    </svg>
  );
}

export default SidebarHomeIcon;
