import React from 'react';

function SidebarReviewsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 21 20"
    >
      <path
        fill="#446888"
        d="M10.5 3.75l1.374 2.662 2.938.426-2.156 1.943.719 2.875L10.5 10.04l-2.875 1.617.719-2.875-2.156-1.943 3.018-.426L10.5 3.75z"
      ></path>
      <path
        fill="#446888"
        d="M11.748 19.563l-1.248-.72 2.875-5.03h4.313a1.435 1.435 0 001.437-1.438V3.75a1.436 1.436 0 00-1.438-1.438H3.313A1.435 1.435 0 001.875 3.75v8.625a1.435 1.435 0 001.438 1.438H9.78v1.437H3.312a2.875 2.875 0 01-2.874-2.875V3.75A2.874 2.874 0 013.312.875h14.376a2.874 2.874 0 012.875 2.875v8.625a2.875 2.875 0 01-2.875 2.875h-3.476l-2.464 4.313z"
      ></path>
    </svg>
  );
}

export default SidebarReviewsIcon;
