import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Community from '../../components/CommunityListItem';
import ContentLoader from '../../components/ContentLoader';
import Navbar from '../../components/Navbar';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import { useRequest } from '../../utils/request';

const managejson = {
  results: [
    {
      form_id: '829ed96b-a564-4b1e-8449-11a12934efa3',
      title: 'Auden Albany',
    },
    {
      form_id: '4627c93e-462c-4b8c-9a14-66a314a7eb3f',
      title: 'Auden Houston',
    },
  ],
};

const ManagementCo = ({
  customHomepage = '',
  searchFilter,
  showCount = true,
}) => {
  const [loading, error, data, makeRequest] = useRequest({ loading: true });

  const [formList, setFormList] = useState({
    results: [
      {
        form_id: '829ed96b-a564-4b1e-8449-11a12934efa3',
        title: 'Auden Albany 12',
      },
      {
        form_id: '4627c93e-462c-4b8c-9a14-66a314a7eb3f',
        title: 'Auden Houston 12',
      },
    ],
  });

  async function getForms() {
    const res = await makeRequest('/getforms?limit=100', 'GET', null, {
      auth: false,
    });
    setFormList(res);
  }

  useEffect(() => {
    getForms();
  }, []);

  const { management_id } = useParams();

  // console.log("community count: ", formList['results'].length);

  const [searchCommunity, setSearchCommunity] = useState('');

  return (
    <div className="managementWrapper">
      <Navbar customHomepage={`${customHomepage}/${management_id}`} />

      <center>
        <ContentLoader loading={loading} error={error}>
          {(management_id === 'all' || showCount) && (
            <h2>
              {' '}
              {formList && formList.results.length} Community Website
              {formList && formList.results.length !== 1 && 's'} currently
              active. Edit or add them here.
            </h2>
          )}

          <div className="flex flex-col">
            <DebouncedTextField
              id="0"
              key="0"
              className="mb-3"
              icon="search"
              placeholder="Search for a specific community..."
              position="append"
              value={searchCommunity}
              onChange={(newValue) => setSearchCommunity(newValue)}
            />
          </div>

          <div className="communityListWrapper">
            {formList &&
              formList.results
                .filter((community) => {
                  const name = community.title || '';
                  if (management_id === 'all') {
                    return true;
                  }
                  return (
                    (!searchFilter ||
                      name
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase())) &&
                    (!management_id ||
                      name
                        .toLowerCase()
                        .includes(management_id.toLowerCase())) &&
                    (!name ||
                      name
                        .toLowerCase()
                        .includes(searchCommunity.toLowerCase()))
                  );
                })
                .map((community, index) => {
                  return (
                    <>
                      <Community
                        communityInfo={{
                          id: community.form_id,
                          name: community.title,
                          img_url: community.img_url,
                          url: '',
                        }}
                        id={index}
                        showManage
                        showAnalytics
                        redirectRoute="/videoembed/community/"
                        redirectRouteBack=""
                        back={`${customHomepage}/${management_id}`}
                      />
                    </>
                  );
                })}
          </div>
        </ContentLoader>
      </center>

      {/* <Button color="dark grey">⚙️ Edit Community List</Button> */}
      {/* <Button onClick={firebase.signOut}>Sign Out</Button> */}
    </div>
  );
};

export default ManagementCo;
