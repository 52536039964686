import Rating from '@material-ui/lab/Rating';
import axios from 'axios';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { mergeNestedArray } from '../../utils/eventUtils';
import { uploadFile } from '../../utils/files';
import AnimatedDialog from './AnimatedDialog';

const EditUser = ({
  openEditUser,
  setOpenEditUser,
  selectedReview,
  userId,
  community_id,
}) => {
  const [checkedReferral, setCheckoutReferral] = useState(false);
  const [skillValue, setSkillValue] = useState('');
  const [metadata, setMetadata] = useState('');
  const [skills, setSkills] = useState([]);
  const [photoUrl, setPhotoUrl] = useState('');
  const [downloadUrl, setDownloadUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingOnLoad, setLoadingOnLoad] = useState(false);
  const [progress, setProgress] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [uploadedUrl, setUploadedUrl] = useState('');
  const [skillList, setSkillList] = useState([]);
  const [suggestion, setSuggestion] = useState([]);
  const [focused, setFocused] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    aboutMe: '',
    rating: '',
  });

  useEffect(() => {
    setCurrentUserId(
      userId || selectedReview?.from_user?.id || selectedReview?.from_user
    );
  }, [openEditUser]);

  const rating = useMemo(() => {
    if (selectedReview?.rating) {
      return selectedReview?.rating;
    }
    return 0;
  }, [selectedReview?.rating]);
  const skillTagRef = useRef(null);

  useEffect(() => {
    // fetchCommunity function for referral badges
    const fetchCommunity = async () => {
      const res = await axios.get(
        `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
      );

      let badgesArray = JSON.parse(
        res.data?.payload[0]?.settings
      )?.referrals?.badges.map((item) => item.type);

      setSkillList(mergeNestedArray(badgesArray));
    };

    // call the function
    fetchCommunity()
      // make sure to catch any error
      .catch((err) => console.log(err));
  }, []);

  const getSelectedUserData = useCallback(
    async (userId) => {
      if (!userId) {
        setData({
          name: '',
          email: '',
          phone: '',
          aboutMe: '',
          rating: '',
        });

        setSkillList([]);
        setCheckoutReferral(false);
        setPhotoUrl('');
        setDownloadUrl('');
        setSkills([]);
        return;
      }
      console.log('getSelectedUserData', userId);
      setLoadingOnLoad(true);
      var config = {
        method: 'get',
        url:
          'https://api.directual.com/good/api/v5/data/users/getUserById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&userId=' +
          userId,
      };

      axios(config)
        .then(function (response) {
          let userData = response.data.payload[0];

          setLoadingOnLoad(false);

          if (userData) {
            userData?.metadata && setMetadata(JSON.parse(userData.metadata));

            setData({
              ...data,
              name: userData?.name,
              phone: userData?.phone,
              email: userData?.email,
              aboutMe: JSON.parse(userData?.metadata).aboutMe,
            });
            let horizontalJSON = JSON.parse(userData?.metadata);
            setPhotoUrl(horizontalJSON?.photoUrl);
            setDownloadUrl(horizontalJSON?.photoUrl);
            setSkills(horizontalJSON?.skills);
            console.log('horizontalJSON', userData);
            setCheckoutReferral(userData?.referral ? true : false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    [currentUserId]
  );

  console.log('refferal', checkedReferral);

  useEffect(() => {
    getSelectedUserData(currentUserId).catch((err) => {
      'referral user fetch: ', console.log(err.message);
    });

    // make sure to catch any error
  }, [currentUserId]);

  useEffect(() => {
    setData({
      ...data,
      name: selectedReview?.from_user_name,
      rating: selectedReview?.rating,
    });
  }, [selectedReview]);

  useEffect(() => {
    setMetadata({
      ...metadata,
      photoUrl: downloadUrl,
      aboutMe: data.aboutMe,
      skills,
      referral: checkedReferral ? selectedReview?.to_user : '',
    });
  }, [downloadUrl, data.aboutMe, skills, checkedReferral]);

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleCloseModal = () => {
    setOpenEditUser(false);

    setCurrentUserId(null);
  };

  async function onSelectImage(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPhotoUrl(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);

      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
  }
  const handleUpload = async () => {
    try {
      const url = await uploadFile(
        file,
        setProgress,
        'users',
        'profile/' + currentUserId
      );

      setPhotoUrl(url);
      setDownloadUrl(url);

      setLoading(false);
      return url;
    } catch (err) {
      console.log(err);
    }
  };

  const addSkills = (value) => {
    if (skills.some((skill) => skill === value)) {
      alert('Skill is already added');
      return;
    }
    setSkills([...skills, value.replace(/\s+/g, '')]);
  };

  const removeSkills = (value) => {
    const temp = skills.filter((skill) => skill !== value);
    if (temp.length === 0) {
      setSkills([]);
    }
    setSkills(temp);
  };

  useEffect(() => {
    if (file === null) {
      setFile(null);
    }
  }, [file]);

  const saveData = async () => {
    setLoading(true);

    try {
      // to avoiding to upload same file again

      let url = '';
      if (file) {
        url = await handleUpload();
      }
      let postData = {
        email: data.email,
        name: data.name,
        phone: data.phone,
        metadata: {
          ...metadata,
          photoUrl: url,
        },
        referral: checkedReferral ? selectedReview?.to_user : '',
        id: currentUserId,
        community: community_id || selectedReview.community_id,
      };

      setFile(null);
      console.log('currentUserId', currentUserId);

      const res = await axios.post(
        `https://api.directual.com/good/api/v5/data/users/newUser?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83`,
        postData
      );
      // if (rating && !userId) {
      //   await updateReview({
      //     ...selectedReview,
      //     from_user_name: data.name,
      //     metadata: {
      //       ...selectedReview?.metadata,
      //       bonusReviewCompleted: true,
      //       phone: data.phone,
      //       email: data.email,
      //     },
      //   });
      // }
      console.log('userUpdated after review TYG: ', res);

      setLoading(false);
    } catch (err) {
      console.log('err', err);
      setLoading(false);
    }
  };

  // const updateReview = async (updatedUserReviewData) => {
  //   console.log('updatedUserReviewData', updatedUserReviewData);
  //   try {
  //     await axios.post(
  //       `https://api.directual.com/good/api/v5/data/reviews/newReview?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
  //       updatedUserReviewData
  //     );
  //   } catch (err) {
  //     console.log('updateReview error', err);
  //   }
  // };

  function handleSuggestion(value) {
    const filter = skillList.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestion(filter);
  }

  const handleChangeSkillValue = (value) => {
    setSkillValue(value);
    handleSuggestion(value);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (skillTagRef.current && !skillTagRef.current.contains(event.target)) {
        setFocused(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [skillTagRef]);

  // console.log(skillList, suggestion);

  return (
    <AnimatedDialog
      onClose={handleCloseModal}
      open={openEditUser}
      maxWidth="xs"
    >
      <div className="edit-user-dialog">
        <div className="hdr flex aic">
          <div className="left flex aic">
            <div className="title font black">
              {data.name ? data.name : 'User name'}
              <span>
                {' '}
                (id:{' '}
                <a
                  href={`/update-user/${community_id}/${currentUserId}`}
                  target="_blank"
                >
                  {currentUserId}){' '}
                </a>{' '}
                {loadingOnLoad ? 'Loading.....' : ''}
              </span>
            </div>
          </div>
          <div className="right flex aic">
            <div className="cross-btn font" onClick={handleCloseModal}>
              &times;
            </div>
          </div>
        </div>
        <div className="user-content">
          {/* <div className="summary-blk">
            <div className="item">
              <div className="value font"> $30 </div>
              <div className="txt font"> Points </div>
            </div>
            <div className="item">
              <div className="value font"> 20 </div>
              <div className="txt font"> Referrals </div>
            </div>
            <div className="item">
              <div className="value font"> 13 </div>
              <div className="txt font"> Conversions </div>
            </div>
          </div> */}
          <div className="user-blk">
            <div className="image-blk">
              <div
                className="dp"
                style={{
                  backgroundImage: `url(${
                    photoUrl ||
                    `https://ui-avatars.com/api/?name=${encodeURI(data?.name)}`
                  } )`,
                }}
              />
              <button
                className="font change-img s12 b5 color"
                onClick={() => document.getElementById('_upload-file_').click()}
              >
                Edit Photo
              </button>
              <input
                onClick={(e) => (e.target.value = '')}
                onChange={onSelectImage}
                type="file"
                accept="image/*"
                className="hide-element "
                id="_upload-file_"
              />
            </div>
            <div className="meta flex flex-col">
              <div className="field-row">
                <div className="filed">
                  <input
                    onChange={(e) => handleChange(e)}
                    value={data.name}
                    name="name"
                    type="text"
                    className="iput font"
                    placeholder="Name"
                  />
                </div>
              </div>
              <div className="field-row">
                <div className="filed">
                  <input
                    onChange={(e) => handleChange(e)}
                    value={data.email}
                    name="email"
                    type="text"
                    className="iput font"
                    placeholder="Email"
                  />
                </div>
                <div className="filed">
                  <input
                    onChange={(e) => handleChange(e)}
                    value={data.phone}
                    name="phone"
                    type="text"
                    className="iput font"
                    placeholder="Phone"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field-row">
            <div className="filed">
              <input
                onChange={(e) => handleChange(e)}
                value={data.aboutMe}
                name="aboutMe"
                type="text"
                className="iput font"
                placeholder="About me"
              />
            </div>
          </div>

          <div className="field-row check-referral">
            <div
              className="filed"
              onClick={() => setCheckoutReferral(!checkedReferral)}
            >
              <div className={`checkbox-btn ${checkedReferral ? 'on' : ''}`}>
                <div className="icon icon-check1" />
              </div>
              <div className="checkbox-lbl font">
                Add to referral to program
              </div>
            </div>
          </div>

          <div className="tag-blk">
            <div className="tit font"> User Tags </div>
            <div className="selected-tags">
              {skills &&
                skills?.map((skill, index) => (
                  <div key={index} className="tag">
                    {skill}
                    <span
                      onClick={() => removeSkills(skill)}
                      style={{ cursor: 'pointer', fontWeight: 'bold' }}
                    >
                      {' '}
                      -
                    </span>
                  </div>
                ))}
            </div>
            <div className="tag-selector" ref={skillTagRef}>
              <div className="field">
                <input
                  type="text"
                  placeholder="Add a skill"
                  onFocus={(e) => {
                    setFocused(true);
                    e.target.value.length === 0 && setSuggestion(skillList);
                  }}
                  className="iput font"
                  value={skillValue}
                  onChange={(e) => handleChangeSkillValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addSkills(e.target.value);
                    }
                  }}
                />
                <div className="add-btn icon-plus" />
              </div>
              <div className={`tag-list ${focused ? 'active' : ''}`}>
                {suggestion &&
                  suggestion?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => addSkills(item)}
                      className="item"
                    >
                      {item} +
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="review-summary-blk">
            <div className="item">
              <div className="lbl font"> Review score </div>
              <div className="val">
                <Rating
                  name="rating-component"
                  value={rating}
                  precision={0.5}
                  size="small"
                  readOnly
                />
              </div>
            </div>
            <div className="item">
              <div className="lbl font"> Review score </div>
              <div className="val">
                {' '}
                {selectedReview?.message && selectedReview?.message}{' '}
              </div>
            </div>
          </div>
          <div className="actions flex aic">
            <div
              className="action-btn transparent font"
              onClick={handleCloseModal}
            >
              Cancel
            </div>
            <div
              onClick={() => (loading ? null : saveData())}
              className="action-btn font"
            >
              {loading ? 'Loading...' : 'Save'}
            </div>
          </div>
        </div>
      </div>
    </AnimatedDialog>
  );
};

export default EditUser;
