import React, { useEffect, useState } from 'react';
import AppSidebar from '../../components/AppSidebar';
import firebase from '../../utils/base';
import { getUserCommunities } from '../../utils/routes';
import './Settings.css';

export default function Settings() {
  return (
    <>
      <AppSidebar />
      <Profile />
    </>
  );
}

function Profile() {
  // design of the GET http request for querying communities given a UID
  const [communities, setCommunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [user, setUser] = useState();
  const [userDataLoading, setUserDataLoading] = useState(false);
  const [openMyProperties, setOpenMyProperties] = useState(false);
  //edit mode of my profile
  const [edit, setEdit] = useState(false);

  // User Input States for update
  const [fullName, setFullName] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [image, setImage] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [errorPhone, setErrorPhone] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');

  const loadCommunities = async () => {
    setLoading(true);
    const res = await getUserCommunities();
    if (res.error) {
      setError(res.error);
    } else {
      setCommunities(res.communities);
      setError('');
    }
    setLoading(false);
  };

  useEffect(() => {
    loadCommunities();
  }, [firebase.uid()]);

  useEffect(() => {
    setUserDataLoading(true);
    firebase.auth.onAuthStateChanged((user) => {
      firebase.db
        .collection('users')
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setUser(doc.data());
            console.log(doc.data());
            setUserDataLoading(false);
          }
        })
        .catch((err) => {
          console.log('Error getting document:', err);
          setUserDataLoading(false);
        });
    });
  }, []);

  useEffect(() => {
    // document.body.addEventListener('click', () => {
    //   setOpenMyProperties(false);
    // });
    if (openMyProperties) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [openMyProperties]);

  //Update my profile
  function onUpdate(e) {
    e.preventDefault();

    var userRef = firebase.db.collection('users').doc(firebase.uid()); //current user collection

    if (fullName) {
      userRef
        .update({
          name: fullName,
        })
        .then(function () {
          console.log('Updated name!');
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error('Error updating name: ', error);
        });
    }
    if (role) {
      userRef
        .update({
          role: role,
        })
        .then(function () {
          console.log('Updated role!');
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error('Error updating role: ', error);
        });
    }
    if (email) {
      const emailPattern = /^\S+@\S+\.\S+/;
      //Check email format is correct
      if (!emailPattern.test(email)) {
        setErrorEmail('Invalid email address');
      } else {
        userRef
          .update({
            email: email,
          })
          .then(function () {
            console.log('Updated email!');
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating email: ', error);
          });
      }
    }
    if (phone) {
      const phonePattern =
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*/;
      //Check phone format is correct
      if (!phonePattern.test(phone)) {
        setErrorPhone('Invalid phone number');
      } else {
        userRef
          .update({
            phone: phone,
          })
          .then(function () {
            console.log('Updated phone!');
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating phone: ', error);
          });
      }
    }
    if (image) {
      userRef
        .update({
          image: image,
        })
        .then(function () {
          console.log('Updated phone!');
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error('Error updating phone: ', error);
        });
    }
    if (password) {
      //Check passwords match
      if (password !== confirmPassword) {
        setErrorPassword("Passwords don't match");
      } else {
        user
          .updatePassword(password)
          .then(function () {
            console.log('Updated password!');
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating password: ', error);
          });
      }
    }

    //Set user so page re-renders correctly
    firebase.auth.onAuthStateChanged((user) => {
      firebase.db
        .collection('users')
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setUser(doc.data());
            console.log(doc.data());
          }
        })
        .catch((err) => {
          console.log('Error getting document:', err);
        });
    });

    //Switch state back to not edit mode
    setEdit(false);
  }

  return (
    <div className="profile-page sidebar-space">
      <div className="wrapper flex">
        <div className="left-side flex flex-col">
          <div className="page-hdr flex aic">
            <div className="page-title font s26 b7 black">My Profile</div>
            <button
              onClick={() => setOpenMyProperties(true)}
              className="cleanbtn properties-btn font s15 b5 color"
            >
              See Properties
            </button>
          </div>

          <div className="personal-info flex flex-col">
            <div className="title font s18 b7 black">Personal Info</div>
            <div className="user-dp rel">
              <div
                className="img"
                style={{
                  backgroundImage: `url(${
                    image ? image : require('../../assets/ava.png').default
                  })`,
                }}
              />
              {edit && (
                <>
                  <button
                    className="cleanbtn img-btn font"
                    onClick={() =>
                      document.getElementById('_file_input').click()
                    }
                  >
                    Change Image
                  </button>
                  <input
                    type="file"
                    accept="image/*"
                    id="_file_input"
                    className="hide-element"
                    onChange={(e) => {
                      let file = e.target.files[0];
                      file && setImage(URL.createObjectURL(file));
                    }}
                  />
                </>
              )}
            </div>
            <div className="form">
              {!userDataLoading && user ? (
                <>
                  <div className={`columns ${edit ? 'edit-mode' : ''}`}>
                    <div className="field flex flex-col">
                      <div className="lbl font">Name</div>
                      <input
                        type="text"
                        placeholder="Full Name"
                        className="iput font"
                        defaultValue={user.name}
                        onChange={(e) => setFullName(e.target.value)}
                        disabled={!edit}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl font">Email</div>
                      <input
                        type="email"
                        placeholder="Your Email"
                        className="iput font"
                        defaultValue={user.email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={!edit}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl font">Phone Number</div>
                      <input
                        type="tel"
                        placeholder="Phone Number"
                        className="iput font"
                        defaultValue={user.phone}
                        onChange={(e) => setPhone(e.target.value)}
                        disabled={!edit}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl font">Role</div>
                      <input
                        type="text"
                        placeholder="Your Role"
                        className="iput font"
                        defaultValue={user.role}
                        onChange={(e) => setRole(e.target.value)}
                        disabled={!edit}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl font">Password</div>
                      <input
                        type="password"
                        placeholder="New Password"
                        className="iput font"
                        defaultValue="********"
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={!edit}
                      />
                    </div>
                    {edit && (
                      <div className="field flex flex-col">
                        <div className="lbl font">Reenter Password</div>
                        <input
                          type="passwordConfirm"
                          placeholder="Confirm New Password"
                          className="iput font"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          disabled={!edit}
                        />
                      </div>
                    )}
                  </div>
                  <div className="ftr flex aic">
                    {!edit ? (
                      <button
                        className="form-btn font cleanbtn"
                        onClick={() => {
                          setEdit(true);
                          document.documentElement.scrollTop = 0;
                        }}
                      >
                        Edit Profile
                      </button>
                    ) : (
                      <>
                        <button
                          className="form-btn font cleanbtn"
                          onClick={onUpdate}
                        >
                          Save
                        </button>
                        <button
                          className="form-btn cancel font cleanbtn"
                          onClick={() => setEdit(false)}
                        >
                          Cancel
                        </button>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <div className="columns">
                  <div className="field flex flex-col holder" />
                  <div className="field flex flex-col holder" />
                  <div className="field flex flex-col holder" />
                  <div className="field flex flex-col holder" />
                  <div className="field flex flex-col holder" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={`right-side ${openMyProperties ? 'open' : 'closed'}`}>
          {/* My Properties Block */}
          <div
            className={`properties-block ${
              openMyProperties ? 'open' : 'closed'
            }`}
          >
            <div className="hdr flex aic">
              <div className="title font">My Properties</div>
              <div className="total font">{`Total: ${communities?.length}`}</div>
              <button
                onClick={(e) => {
                  setOpenMyProperties(false);
                  e.stopPropagation();
                }}
                className="close-btn cleanbtn font s21 icon-x lightBlack"
              />
            </div>
            <div className="properties-list flex flex-col">
              {loading
                ? [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].map(
                    (item, idx) => (
                      <div className="item flex flex-col">
                        <div className="number holder" />
                        <div className="name holder" />
                      </div>
                    )
                  )
                : communities.map((item, idx) => (
                    <div className="item flex aix">
                      <div className="number font s14 c222">{`${
                        idx + 1
                      })`}</div>
                      <div className="name font s14 c222 b4">{item.name}</div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
