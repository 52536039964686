// Icons
import IconButton from '@material-ui/core/IconButton';
// Material UI components
import TextField from '@material-ui/core/TextField';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { uploadFile } from '../../utils/files';
// Components
import MagnetPopup from './MagnetPopup';

/*
 * MagnetPathRow
 *
 * Each row consists of button name, video path
 * and user actions.
 *
 */
export default function MagnetPathRow({
  linksType,
  screen,
  community_id,
  button,
  button_idx,
  editPath,
  editPhoto,
  deletePath,
  routeOpts,
  dragItem,
  editButtonName,
  setEditButtonName,
  editVideoPath,
  setEditVideoPath,
  buttons,
  centerButtons
}) {
  // true = ViewMode, false = EditMode
  const [view, setView] = useState(true);
  const { template } = useSelector((state) => state.getMagnet);
  console.log('routeOpts: ', routeOpts);
  const itemOpts = React.useMemo(
    () => routeOpts.find((item) => item?.route === button?.route),
    [buttons,centerButtons]
  );
  console.log('itemOpts', itemOpts);

  return (
    <>
      {view ? (
        <ViewMode
          linksType={linksType}
          screen={screen}
          community_id={community_id}
          template={template}
          button={button}
          button_idx={button_idx}
          editPhoto={editPhoto}
          deletePath={deletePath}
          setView={setView}
          index={button_idx}
          dragItem={dragItem}
          editButtonName={editButtonName}
          editVideoPath={editVideoPath}
          itemOpts={itemOpts}
          routeOpts={routeOpts}
        />
      ) : (
        <EditMode
          template={template}
          button={button}
          button_idx={button_idx}
          editPath={editPath}
          deletePath={deletePath}
          routeOpts={routeOpts}
          setView={setView}
          editButtonName={editButtonName}
          setEditButtonName={setEditButtonName}
          editVideoPath={editVideoPath}
          setEditVideoPath={setEditVideoPath}
          routeOpts={routeOpts}
        />
      )}
    </>
  );
}

function ViewMode({
  linksType,
  screen,
  community_id,
  template,
  button,
  button_idx,
  deletePath,
  setView,
  index,
  editPhoto,
  dragItem,
  editButtonName,
  editVideoPath,
  itemOpts,
  routeOpts
}) {
  const [photoUrl, setPhotoUrl] = useState(button.img);
  const [progress, setProgress] = useState(null);
  const [imgInputId] = useState(uuidv4());
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'MagnetPathRow',
    item: () => {
      return { button, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'MagnetPathRow',
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      dragItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  }));

  const _dispatch = useDispatch();
  const ref = useRef(null);

  drag(drop(ref));

  useEffect(() => {
    _dispatch({ type: 'BUTTON_DRAGGING', payload: true });
  }, [isDragging]);

  useEffect(() => {
    _dispatch({ type: 'BUTTON_DRAGGING', payload: false });
  }, [isOver]);

  function onSelectImage(index, e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        // editPhoto(index, reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);

      handleUpload(e.target.files[0]);
    }
  }

  const handleUpload = async (file) => {
    try {
      const url = await uploadFile(
        file,
        setProgress,
        'community',
        `${community_id}/magnet/category/${screen}/img/${
          button.name + button.id
        }`
      );
      editPhoto(index, url);
    } catch (err) {
      console.log(err);
    }
  };

  console.log('button: ', button);

  return (
    <tr ref={ref} style={{ opacity: isOver ? 0 : 1 }}>
      {linksType !== 'links' && (
        <td>
          <div className="flex-center-vt ">
            <input
              onChange={(e) => onSelectImage(button_idx, e)}
              type="file"
              accept="image/*"
              className="hide-element"
              id={imgInputId}
            />
            <label key={uuidv4()} htmlFor={imgInputId}>
              <div key={uuidv4()}>
                {/* Drop or Choose File */}

                {button?.img ? (
                  <img
                    style={{
                      width: 120,
                      height: 80,
                      cursor: 'pointer',
                      objectFit: 'cover',
                      borderRadius: 10,
                    }}
                    src={button?.img}
                    alt="image"
                  />
                ) : (
                  <CameraAltIcon color="secondary" />
                )}
                <p> {progress && progress + '%'}</p>
              </div>
            </label>
            <div
              onClick={() => document.getElementById(uuidv4()).click()}
            ></div>
          </div>
        </td>
      )}

      <td
        style={{
          width: editButtonName ? 'calc(80%)' : 'calc(40%)',
          position: 'relative',
          display: editVideoPath ? 'none' : 'table-cell',
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: 0,
            zIndex: 1,
            cursor: isDragging ? 'grabbing' : 'grab',
          }}
        >
          <DragHandleIcon color="secondary" fontSize="small" />
        </div>
        {/* <div className="bottom-link-img" style={{ backgroundImage: `url()` }} /> */}
        <TextField
          id={button_idx}
          value={button.name}
          disabled
          fullWidth
          style={{ paddingLeft: '25px' }}
        />
      </td>
      <td
        style={{
          width: editVideoPath ? 'calc(80%)' : 'calc(40%)',
          display: editButtonName ? 'none' : 'table-cell',
        }}
      >
        {button.route ? (
          <MagnetPopup
            template={template}
            videoPath={button.route}
            buttonLabel={button?.name}
            videoImg={button?.img}
            screenTitle={routeOpts.find((item) => item?.route === button?.route)?.title}
          />
        ) : (
          <TextField id={button.href} value={button.href} disabled fullWidth />
        )}
      </td>
      <td style={{ width: 'calc(20%)' }}>
        <IconButton color="secondary" onClick={() => setView(false)}>
          <EditIcon />
        </IconButton>
        <IconButton color="secondary" onClick={() => deletePath(button_idx)}>
          <DeleteIcon />
        </IconButton>
      </td>
    </tr>
  );
}

function EditMode({
  template,
  button,
  button_idx,
  editPath,
  routeOpts,
  setView,
  editButtonName,
  setEditButtonName,
  editVideoPath,
  setEditVideoPath,
}) {
  const [name, setName] = useState(button.name);
  const [route, setRoute] = useState({
    id: button.id,
    route: button.route,
  });
  const [href, setHref] = useState(button.href);

  function updatePath() {
    // Edit path
    if (button.route) editPath(button_idx, name, route, null);
    if (button.href) editPath(button_idx, name, null, href);
    // Change back to view mode
    setView(true);
  }

  return (
    <tr>
      <td
        style={{
          width: editButtonName ? 'calc(80%)' : 'calc(40%)',
          display: editVideoPath ? 'none' : 'table-cell',
        }}
      >
        <TextField
          id={button_idx}
          defaultValue={name}
          onChange={(e) => setName(e.target.value)}
          onFocus={() => setEditButtonName(true)}
          onBlur={() => setEditButtonName(false)}
          fullWidth
        />
      </td>
      <td
        style={{
          width: editVideoPath ? 'calc(80%)' : 'calc(40%)',
          display: editButtonName ? 'none' : 'table-cell',
        }}
      >
        {button.route ? (
          <Autocomplete
            options={routeOpts}
            getOptionLabel={({ id }) => id}
            value={route}
            filterSelectedOptions
            onChange={(event, newValue) => {
              setRoute(newValue);
            }}
            onFocus={() => setEditVideoPath(true)}
            onBlur={() => setEditVideoPath(false)}
            renderInput={(params) => <TextField {...params} />}
          />
        ) : (
          <TextField
            id={button.href}
            defaultValue={href}
            onBlur={(e) => setHref(e.target.value)}
            fullWidth
          />
        )}
      </td>
      <td style={{ width: 'calc(20%)' }}>
        <IconButton color="secondary" onClick={updatePath}>
          <CheckIcon />
        </IconButton>
        <IconButton color="secondary" onClick={() => setView(true)}>
          <ClearIcon />
        </IconButton>
      </td>
    </tr>
  );
}

const RouteInput = ({ routeOpts }) => {
  return (
    <Autocomplete
      options={routeOpts}
      getOptionLabel={({ id }) => id}
      value={route}
      filterSelectedOptions
      onChange={(event, newValue) => {
        setRoute(newValue);
      }}
      onFocus={() => setEditVideoPath(true)}
      onBlur={() => setEditVideoPath(false)}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};
