import { useState, useEffect, useRef } from 'react';
import generateUsername from '../utils/usernameGeneration';
import { supabase } from '../services/supabase';
import moment from 'moment';
import { FaUserCircle } from 'react-icons/fa';

function MessageBox({messages,setMessages,currentVisitorUuidRef, activevisitors, setactivevisitors, magnet_uuid, visitorUuid, visitorData }) {
  const [newMessage, setNewMessage] = useState('');
  const activeVisitorsRef = useRef(activevisitors);
  const visitorName = visitorData.userInfo?.name ?? generateUsername(visitorData.visitorId);
  console.log("activevisitors", activevisitors);

  const handleSendMessage = async () => {
    const message = {
      sendby: magnet_uuid,
      message: newMessage,
      timestamp: new Date(),
    };
    setMessages([...messages, message]);
    setNewMessage('');

    const { error } = await supabase.from('chat').upsert({
      visitor_id: visitorUuid,
      magnet_uuid,
      messages: [...messages, message]
    });
    if (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    activeVisitorsRef.current = activevisitors;
  }, [activevisitors]);



  const fetchMessages = async (visitorId) => {
    // Fetch messages logic here
    const { data, error } = await supabase
      .from('chat')
      .select('*')
      .eq('visitor_id', visitorId);
    if (error) {
      console.error(error);
    } else {
      console.log("messages_data", data);
      setMessages(data?.[0]?.messages || []);
    }
  };

  useEffect(() => {
    currentVisitorUuidRef.current = visitorUuid;
    fetchMessages(visitorUuid);
  }, [visitorUuid]);
  
  const renderMessageContent = (message) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = message.split(urlRegex);
  
    return parts.map((part, index) =>
      urlRegex.test(part) ? (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center text-blue-500 bg-blue-100 rounded p-1 hover:bg-blue-200 transition-colors duration-300"
        >
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  return (
    <div className="p-4 border rounded-md bg-white">
      <div className="mb-4">
        <h2 className="font-bold text-lg"> {visitorName}</h2>
      </div>
      <div className="overflow-y-scroll mb-4" style={{ height: '400px' }}>
        {messages.map((msg, index) => (
          <div key={index} className={`mb-2 flex ${msg?.sendby === magnet_uuid ? "justify-end" : "justify-start"}`}>
            <div className={`text-sm p-2 rounded-md max-w-xs ${msg?.sendby === magnet_uuid ? "bg-blue-500 text-white self-end" : "bg-gray-200 text-black self-start"}`}>
              <div className="flex items-center mb-1">
                {msg?.sendby !== magnet_uuid ? (
                  <FaUserCircle className="text-xl mr-2" />
                ) : (
                  <span className="text-xs font-semibold mr-2">Me</span>
                )}
                <span className="text-xs">{moment(msg.timestamp).format('lll')}</span>
              </div>
              <div>{renderMessageContent(msg.message)}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center">
        <input
          type="text"
          className="border rounded-md p-2 flex-grow"
          value={newMessage}
          onKeyUp={(e) => {
            if(e.keyCode == 13) {
              handleSendMessage()
            }
          }}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message"
        />
        <button
          onClick={handleSendMessage}
          className="bg-blue-500 text-white rounded-md px-4 py-2 ml-2"
        >
          Send
        </button>
      </div>
    </div>
  );
}

export default MessageBox;
