import { useEffect, useState } from 'react';

const useLoadingMessages = (arrayOfMessages) => {
  const [loadingMessage, setLoadingMessage] = useState(arrayOfMessages[0]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      const msgIndex =
        currentMessageIndex + 1 < arrayOfMessages.length
          ? currentMessageIndex + 1
          : 0;

      setCurrentMessageIndex(msgIndex);
      setLoadingMessage(arrayOfMessages[msgIndex]);
    }, 1200);
  }, [currentMessageIndex]);

  return loadingMessage;
};

export default useLoadingMessages;
