import React from 'react';
import emo1 from '../../assets/emo1.png';
import emo2 from '../../assets/emo2.png';
import streamlinehq from '../../assets/streamlinehq.png';
const AnnualCost = () => {
  return (
    <div className=" grid grid-cols-1 lg:grid-cols-2 w-full mt-20 pb-5">
      <div className=" flex w-full flex-col items-center justify-center h-96  gap-3">
        <div className=" relative w-full lg:w-96 lg:pl-10">
          <h1 className=" text-2xl text-gray-700 font-bold">
            The annual cost of a <br />
            full-time, top quality video <br /> editor now exceeds $100,000
          </h1>

          <p className=" text-gray-700 text-sm py-3">
            But you need more videos that convert, look beautiful, and get
            delivered on time.
          </p>
          <p className=" font-medium flex items-center gap-2 text-sm">
            Sounds like a headache.{' '}
            <img src={emo1} className=" w-5 object-contain" alt="" />
          </p>
          <p className=" font-medium flex items-center gap-2 text-sm">
            That’s why we built video.pro.{' '}
            <img src={emo2} className=" w-5 object-contain" alt="" />
          </p>
        </div>
      </div>
      <div>
        <img src={streamlinehq} alt="" />
      </div>
    </div>
  );
};

export default AnnualCost;
