import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import CreateReview from '../components/createReview/CreateReview';
import TheCustomTour from '../components/CustomTour/TheCustomTour';
import PhotoUpload from '../views/talk-and-triggers/PhotoUpload.jsx';
// Views
import {
  CustomTour,
  InstaTour,
  ManagementCo,
  Property,
  VideomagnetDetails,
} from '../views';
import WriteGoogleReviewForward from '../views/reviews/dashboard/WriteGoogleReviewForward';
import { Survey } from '../views/reviews/Question';
import ChampionDialog from '../views/tour-magnet-tabs/ChampionDialog';
import ChampionDialogEdit from '../views/tour-magnet-tabs/ChampionDialogEdit';
import ReferralsDialog from '../views/tour-magnet-tabs/ReferralsDialog';
import ShareDashboard from '../views/tour-magnet-tabs/ShareDashboard';
import Testimonials from '../views/tour-magnet-tabs/Testimonials';
import Tourversity from '../views/TourversityTYG';
import UploadVideoImport from '../views/upload-video-import/UploadVideoImport';
import UploadVideoImportRecord from '../views/upload-video-import/UploadVideoImportRecord';
import ContactUs from '../views/widgets/ContactUs/ContactUs';
import PhotoGallery from '../views/widgets/PhotoGallery/PhotoGallery';
import Floorplans from '../views/widgets/Floorplans/Floorplans';
import Promotions from '../views/widgets/Promotions/Promotions';
import Referral from '../views/widgets/Referral/Referral';
import BookingScheduler from '../views/widgets/Scheduler/Scheduler';
import Chatbot from '../views/widgets/Chatbot/index';
import OpenRooms from '../components/meetings/OpenRooms';
import PastRecordings from '../components/meetings/PastRecordings';
import VirtualRooms from '../views/manage-magnets/VirtualRooms';
import ContrastVideoPlayer from '../views/widgets/GoContrastVideoPlayer/ContrastVideoPlayer';
import Analytics3 from '../views/tour-magnet-tabs/Analytics3.jsx';
import PreviewTourTYG, { V1PreviewTourTYG } from '../views/PreviewTourTYG.jsx';
import ApartmentView from '../views/tour-magnet-tabs/ApartmentView';

const UnprotectedRoutes = [
  { path: '/videoembed', component: () => 'missing management_id', exact: true },
  { path: '/videoembed/:management_id', component: ManagementCo, exact: true },
  { path: '/videoembed/community/:form_id', component: VideomagnetDetails, exact: true },
  { path: '/tour', component: () => <CustomTour customHomepage="/tour" />, exact: true },
  { path: '/share/analytics', component: ShareDashboard, exact: true },
  { path: '/share', component: () => <CustomTour customHomepage="/share" />, exact: true },
  { path: '/customtour', component: TheCustomTour, exact: true },
  { path: '/dashboard/:community_id/:magnet_tab', component: ApartmentView, exact: true },
  { path: '/cta/scheduler/integration/:magnetId', component: BookingScheduler, exact: true },
  { path: '/cta/floorplans/integration/:magnetId', component: Floorplans, exact: true },
  { path: '/cta/promotions/integration/:magnetId', component: Promotions, exact: true },
  { path: '/cta/contactus/integration/:magnetId', component: ContactUs, exact: true },
  { path: '/cta/gallery/integration/:magnetId', component: PhotoGallery, exact: true },
  { path: '/cta/chatbot/integration/:communityId', component: Chatbot, exact: true },
  { path: '/player/recording/:v', component: ContrastVideoPlayer, exact: true },
  { path: '/cta/referral/:magnetId', component: Referral, exact: true },
  { path: '/dashboard/:community_id/tour/analytics', component: Analytics3, exact: true },
  { path: '/instashare', component: InstaTour, exact: true },
  { path: '/dmg', component: () => <ManagementCo searchFilter="DMG" customHomepage="/dmg" showCount={false} />, exact: true },
  { path: '/survey/:community_id?', component: Survey, exact: true },
  { path: '/surveys/:community_id?/:community_name_url?', component: () => <CreateReview fullHeight={true} />, exact: true },
  { path: '/referrers/community/:id', component: ReferralsDialog, exact: true },
  { path: '/referrers/champions/community/:id', component: ChampionDialog, exact: true },
  { path: '/referrers/champions/community/:id/champion/:userId/edit', component: ChampionDialogEdit, exact: true },
  { path: '/partner/:management_id', component: () => <ManagementCo customHomepage="/partner" showCount={false} />, exact: true },
  { path: '/properties/:route', component: Property, exact: true },
  { path: '/testimonials/:form_id', component: Testimonials, exact: true },
  { path: '/writereview/:community_id/:place_id', component: WriteGoogleReviewForward, exact: true },
  { path: '/upload-video-import/:magnet_id_param/:screen_id_param', component: UploadVideoImport, exact: true },
  { path: '/upload-video-import/:magnet_id_param/:screen_id_param/record', component: UploadVideoImportRecord, exact: true },
  { path: '/esteem', component: () => <Redirect to="/survey/7" />, exact: true },
  { path: '/learn', component: Tourversity, exact: true },
  { path: '/v1/@:alias', component: V1PreviewTourTYG, exact: true },
  { path: '/@:alias', component: PreviewTourTYG, exact: true },
];


export default UnprotectedRoutes;
