import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Rating from '@material-ui/lab/Rating';
import { makeStyles } from '@material-ui/styles';
import React, { useRef, useState } from 'react';
import { BsReplyFill } from 'react-icons/bs';
import { MdOutlineArrowRightAlt } from 'react-icons/md';
import ReviewVideoPlayer from './ReviewVideoPlayer';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    borderRadius: '5px',
  },
}));
const Mcomponent = ({
  item,
  addopen,
  setAddopen,
  ind,
  deletereviewbyindex,
  embeded,
}) => {
  const vidRef = useRef(null);
  const isplaying = useRef(false);
  const handlePlayVideo = () => {
    console.log(vidRef);
    if (isplaying.current == false) {
      //isplaying.current = true
      let playPromise = vidRef.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
            isplaying.current = true;
            vidRef.current.play();
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
            console.log(error);
          });
      }
    }
  };

  const handlePauseVideo = () => {
    if (isplaying.current == true) {
      isplaying.current = false;
      vidRef.current.pause();
    }
  };
  const [play, setPlay] = useState({
    id: -1,
    status: false,
    ref: false,
  });
  const [loading, setloading] = useState(false);

  const classes = useStyles();
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className=" flex items-center justify-center"
        open={addopen}
        onClose={() => setAddopen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={addopen}>
          <div className=" w-full lg:w-2/4 bg-white overflow-y-scroll h-5/6 p-4 rounded-3xl">
            <div className=" w-full rounded-xl border px-3 py-3 cursor-pointer">
              {item?.email && (
                <p className=" text-gray-800 flex items-center gap-1 text-xs">
                  From: {item?.name} {item?.email} | date: {item?.date}
                </p>
              )}
              <div className=" w-full flex items-center  gap-2 pt-3 justify-between">
                <div>
                  <Rating
                    name="simple-controlled"
                    value={item?.rating}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                  />
                  {item?.selectcommunityBadges && (
                    <div className=" flex items-center gap-2 w-full mt-3 flex-wrap">
                      {item?.selectcommunityBadges.map((i, ind) => (
                        <button
                          className=" px-2 py-1 w-auto  text-xs text-white rounded-md bg-gray-700"
                          key={ind}
                        >
                          {i.name}
                        </button>
                      ))}
                    </div>
                  )}
                  {item?.to && (
                    <p className=" text-gray-500 flex items-center gap-1 text-xs">
                      <MdOutlineArrowRightAlt />
                      {item?.to}
                    </p>
                  )}
                </div>
                <div>
                  {item?.from && (
                    <p className=" text-gray-800 text-sm font-medium">
                      {item?.from}
                    </p>
                  )}
                  {item?.date && (
                    <p className=" text-gray-500 text-xs font-medium">
                      {item?.date}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* {item.type === 2 && ( */}
            <div>
              {item?.question?.map((item, index) => (
                <>
                  <p className=" text-gray-800 text-sm font-medium mt-2 mb-1">
                    {index + 1}. {item?.question}
                  </p>
                  <ReviewVideoPlayer item={item} />
                  {item?.useranswer && (
                    <p className=" text-gray-800 text-sm font-medium mt-2">
                      Feedback: {item?.useranswer}
                    </p>
                  )}
                </>
              ))}
              {item?.tags && (
                <div className=" flex items-center gap-2 w-full mt-3 flex-wrap">
                  {item?.tags.map((i, ind) => (
                    <button
                      className=" px-2  text-xs py-1 text-white rounded-md bg-gray-700"
                      key={ind}
                    >
                      {i}
                    </button>
                  ))}
                </div>
              )}
            </div>

            {/* <h6 className=" text-xl font-semibold pt-3">Respond</h6> */}
            <>
              {embeded === undefined && (
                <>
                  <textarea
                    className=" w-full  mt-3 border rounded-md p-2 text-sm"
                    placeholder="Write a response to gretchen@gmail.com"
                    name=""
                    id=""
                    cols="18"
                    rows="8"
                  ></textarea>
                  <div className=" flex items-center gap-2">
                    <button className=" px-2 py-2 text-white text-xs flex items-center gap-1 bg-pr rounded-md">
                      <BsReplyFill className=" w-4 h-4" /> Reply
                    </button>
                    <p className=" flex items-center gap-1">
                      <a
                        href="http://"
                        target="_blank"
                        rel="noopener noreferrer"
                        className=" text-sm text-pr"
                      >
                        {item?.to}
                      </a>
                    </p>
                    <button
                      onClick={() => {
                        setloading(true);
                        deletereviewbyindex(ind, setAddopen, setloading);
                      }}
                      className=" px-2 py-2 text-white text-xs flex items-center flex-end gap-1 bg-red-600 rounded-md"
                    >
                      {loading ? 'Loading...' : 'Delete'}
                    </button>
                  </div>
                </>
              )}
            </>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default Mcomponent;
