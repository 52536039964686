import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditUser from '../../components/Reusable/EditUser';
import Pagination from '../../components/Reusable/Pagination';
import { getReferralUsers } from '../../store/actions/referralActions';
import Badge from '../reviews/dashboard/Badge';

const ReferralUsers = ({ community_id }) => {
  const [page, setPage] = useState(1);

  const { referralUsers, referralUserLoading, referralUserError } = useSelector(
    (state) => state.getReferralUsersReducer
  );

  const _dispatch = useDispatch();

  useEffect(() => {
    _dispatch(getReferralUsers(community_id));
  }, []);

  return (
    <div className="all-user-reviews">
      <div className="hdr flex aic">
        <div className="ls x aic">
          <div className="tit font s18">Referral User</div>
        </div>
        <div className="rs flex aic"></div>
      </div>
      <>
        {referralUserLoading && referralUsers?.payload?.length == 0 ? (
          <div className="loading">
            <CircularProgress />
          </div>
        ) : (
          <div className="reviews-list">
            <CompletedFeedbackTable
              referralUsers={referralUsers?.payload}
              page={page}
              community_id={community_id}
            />
            <Pagination
              page={page}
              setPage={setPage}
              perPage={5}
              count={parseInt(referralUsers?.payload?.length)}
            />
          </div>
        )}
      </>
    </div>
  );
};

export default ReferralUsers;

function CompletedFeedbackTable({ community_id, referralUsers, page }) {
  // Only display the current page of reviews (10 per page)
  const [display, setDisplay] = useState([]);
  function displayTen() {
    if (referralUsers?.length) {
      const ten = referralUsers.filter(
        (user, idx) => idx >= 5 * (page - 1) && idx < 5 * page
      );
      setDisplay(ten);
    } else {
      setDisplay([]);
    }
  }

  useEffect(() => {
    displayTen();
  }, [referralUsers]);

  function deleteRowByIndex(index) {
    console.log(index);
    setDisplay(display.filter((user, idx) => idx !== index));
  }

  return (
    <>
      <div className="dashboard-employee-table">
        <div className="table">
          <div className="table-hdr flex aic">
            <div className="column">
              <div className="label font">Name</div>
            </div>
            <div className="column">
              <div className="label font">Email</div>
            </div>
            <div className="column">
              <div className="label font">Skills</div>
            </div>
            <div className="column">
              <div className="label font">Comments</div>
            </div>
          </div>
          {display.map((item, idx) => {
            // let _badges = item.badges ? JSON.parse(item.badges) : [];
            return (
              <CompletedRow
                community_id={community_id}
                key={idx}
                idx={idx}
                user={item}
                deleteRowByIndex={deleteRowByIndex}
              />
            );
          })}
          {referralUsers?.length === 0 && (
            <NoRows message="No referral users found." />
          )}
        </div>
      </div>
    </>
  );
}

// Row for each table entry
function CompletedRow({ community_id, idx, user, deleteRowByIndex }) {
  // Expand or not
  const [expand, setExpand] = useState(false);

  const { aboutMe, photoUrl, referral, skills, review } = JSON.parse(
    user?.metadata
  );
  console.log('user', user);

  const badges = review?.badges ? review.badges : null;

  const [openEditUser, setOpenEditUser] = useState(false);

  const deleteReferral = async () => {
    await axios.post(
      `https://api.directual.com/good/api/v5/data/users/newUser?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83`,
      {
        referral: '',
        id: user?.id,
      }
    );
    deleteRowByIndex(idx);
  };

  return (
    <>
      <div style={{ display: 'flex' }} className="table-row  aic">
        <div className="column">
          <div
            className="profile-img"
            style={{ backgroundImage: `url(${photoUrl})` }}
          />
          <div className="txt font" onClick={() => setOpenEditUser(true)}>
            {user?.name} <p style={{ color: 'grey' }}>( {user?.id} )</p>
          </div>
          <EditUser
            community_id={community_id}
            openEditUser={openEditUser}
            setOpenEditUser={setOpenEditUser}
            userId={user?.id}
          />
        </div>
        <div className="column">
          <div className="text font">{user?.email}</div>
        </div>
        <div className="column">
          {skills?.length ? (
            <div className="skills flex aic">
              {skills.map((skill) => (
                <div className="skill font">{`${skill}`}</div>
              ))}
            </div>
          ) : null}
        </div>

        <div className="comments column">
          <div className="item flex aic">
            <div className="meta flex flex-col">
              {badges ? (
                <div className="badges flex aic">
                  {Object.keys(badges).map((item) => {
                    return badges[item].map((badge) => {
                      return <Badge number={''} text={badge.title} />;
                    });
                  })}
                </div>
              ) : null}
              {aboutMe && (
                <div className="cmt font s15 black wordwrap">{aboutMe}</div>
              )}
            </div>
            {aboutMe && (
              <button
                className="cleanbtn drop-btn font"
                onClick={() => setExpand(!expand)}
              >
                {!expand ? '▼' : '▲'}
              </button>
            )}
          </div>
        </div>

        <div className="column">
          <IconButton onClick={deleteReferral}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
      {expand && (
        <>
          <ExpandedComments idx={idx} comments={aboutMe} />
        </>
      )}
    </>
  );
}

function NoRows({ message }) {
  return (
    <div className="no-employees flex aic">
      {message ? message : 'Record not found'}
    </div>
  );
}

// Expanded comments section
function ExpandedComments({ idx, comments }) {
  return (
    <div className="expanded-blk flex aic">
      <div className="txt font">{comments}</div>
    </div>
  );
}
