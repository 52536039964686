import axios from 'axios';

export const getSpecials = async (magnet_uuid = '', options) => {
  try {
    const showLogs = options?.showLogs;
    //const data = await getEntrataData(integrationId);

    const query = new URLSearchParams(window.location.search);
    let entratauser = query.get('entratauser');
    let entratadomain = query.get('entratadomain');
    let entratapass = query.get('entratapass');
    let entratapropertyId = query.get('entratapropertyId');

    // if (options?.entrataCreds && ( !entratauser || !entratapass || !entratapropertyId ) ) {
    //   let { _entratauser, _entratadomain, _entratapass, _entratapropertyId } = options.entrataCreds;
    //   entratauser = _entratauser;
    //   entratadomain = _entratadomain;
    //   entratapass = _entratapass;
    //   entratapropertyId = _entratapropertyId
    // }
    if (magnet_uuid && (!entratauser || !entratapass || !entratapropertyId)) {
      const res = await axios.get(
        `https://api.leasemagnets.com/integration/details?magnet_uuid=${magnet_uuid}`
      );
      if (showLogs) {
        console.log('integrations res', res);
      }
      if (res?.data?.['api-entrata']) {
        let api_entrata_obj = res?.data?.['api-entrata'];
        entratauser = api_entrata_obj?.username;
        // entratadomain = res?.data?.['api-entrata']?.domain;
        entratapass = api_entrata_obj?.password;
        entratapropertyId = api_entrata_obj?.property_id;
      }
    }

    if (!entratauser || !entratauser || !entratapropertyId) return null;
    let creds = {
      username: entratauser,
      password: entratapass,
    };
    if (entratadomain) {
      creds['entratadomain'] = entratadomain;
    }

    const special_res = await axios.post(
      `https://api.leasemagnets.com/integrations/entrata/getSpecials`,
      {
        creds: creds,
        propertyId: entratapropertyId,
      }
    );

    if (showLogs) {
      console.log('special_ressss', special_res);
      console.log('thedata', special_res.data.resp?.result?.specials);
    }

    let specials = special_res.data.resp?.result?.specials;
    let specialsArr = Object.keys(
      special_res.data.resp?.result?.specials?.propertySpecials?.special || []
    );

    if (showLogs) {
      console.log('SPECIALS1: ', specialsArr);
      console.log('SPECIALS2: ', specials);
    }

    return {
      propertySpecials: Object.values(
        special_res.data.resp?.result?.specials?.propertySpecials?.special || []
      ),
      floorplanSpecials: Object.values(
        special_res.data.resp?.result?.specials?.floorplanSpecials?.special ||
          []
      ),
      unitSpaceSpecials: Object.values(
        special_res.data.resp?.result?.specials?.unitSpaceSpecials?.special ||
          []
      ),
      unitTypeSpecials: Object.values(
        special_res.data.resp?.result?.specials?.unitTypeSpecials?.special || []
      ),
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};
