import React, { useEffect, useState } from 'react';
import { AppSidebar, Header } from '../../components';
import useGetCommunitiesList from '../../hooks/useGetCommunitiesList';
import { getCommunityData } from '../../utils/api';
import { arrayReplaceAt, sleep } from '../../utils/helpers';
import BillingAmountSummaryBox from './BillingAmountSummaryBox';
import CommunityBilling from './CommunityBilling';
import useLocalStorage from '../../hooks/useLocalStorage';

const communitiesLocalStorageKey = 'formatted_communities_list';

const Billing = () => {
  const { communitiesList, isCommunitiesListLoading } = useGetCommunitiesList(); // Basic Communities Data
  const [communitiesFormattedList, setCommunitiesFormatedList] =
    useLocalStorage(communitiesLocalStorageKey, []); // Communities Basic and Stripe Data Combined
  const [communityDataFetchCounter, setCommunityDataFetchCounter] =
    useState(-1); // Counts the index of the community fetched last
  const [forceFetchCommunityIndex, setForceFetchCommunityIndex] = useState(-1);
  const [refreshAllCommunitiesData, setRefreshAllCommunitiesData] =
    useState(false);

  // Fetches Stripe Data for Every Community in CommunityList and Stores in CommunityDetailsList, Also updates the Fetch Counter
  useEffect(() => {
    if (!isCommunitiesListLoading) {
      initFormattedCommunitiesList();
      (async () => {
        await fetchAllFormattedCommunities();
      })();
    }
  }, [isCommunitiesListLoading]);

  useEffect(() => {
    console.log('FORCE FETCH: ', forceFetchCommunityIndex);
    if (forceFetchCommunityIndex > -1) {
      initOneCommunity(forceFetchCommunityIndex);
      (async () => {
        await fetchAndFormatOneCommunity(forceFetchCommunityIndex);
        setForceFetchCommunityIndex(-1);
      })();
    }
  }, [forceFetchCommunityIndex]);

  useEffect(() => {
    if (refreshAllCommunitiesData) {
      console.log('Refreshing Data !');
      setRefreshAllCommunitiesData(false);
      setCommunitiesFormatedList(() => {
        const newList = communitiesList?.map((thisCommunity, idx) => {
          return { ...thisCommunity, loading: true, stripeInfo: null };
        });
        return newList;
      });
      fetchAllFormattedCommunities();
    }
  }, [refreshAllCommunitiesData]);

  // Initially Formats the communities with loading status etc.
  function initFormattedCommunitiesList() {
    setCommunitiesFormatedList(() => {
      const newList = communitiesList?.map((thisCommunity, idx) => {
        if (
          communitiesFormattedList[idx]?.loading === false &&
          communitiesFormattedList[idx]?.stripeInfo
        )
          return communitiesFormattedList[idx]; // If the local storage has preserved data on this index than use it instead of flushing it
        return { ...thisCommunity, loading: true, stripeInfo: null };
      });
      return newList;
    });
  }

  async function initOneCommunity(idx) {
    setCommunitiesFormatedList((prevData) => {
      const newArr = arrayReplaceAt(prevData, idx, {
        ...prevData[idx],
        loading: true,
        stripeInfo: undefined,
      });
      return newArr;
    });
  }

  // Fetches and Formats all communities
  async function fetchAllFormattedCommunities() {
    for (let i = 0; i < communitiesList.length; i++) {
      //console.log(`in the loop ${i}, ${refreshAllCommunitiesData}`);
      if (refreshAllCommunitiesData) {
        console.log('Stuck in Refreshing !');
        break;
      }

      // console.log(`${i} about to be fetched, ABCD`);
      if (forceFetchCommunityIndex > -1) {
        await fetchAndFormatOneCommunity();
        setForceFetchCommunityIndex(-1);
      }
      if (communitiesFormattedList[i]?.loading === false) continue;
      await fetchAndFormatOneCommunity(i);
      setCommunityDataFetchCounter(i);
      // console.log(`ABCD COMMUNITY FETCHED ${i}, ${communitiesFormattedList[i]?.name}`);
    }
  }

  // Fetches and Formats one Community on a given index
  async function fetchAndFormatOneCommunity(idx) {
    try {
      //if(communitiesFormattedList[idx]?.loading === false) continue;
      const communityRes = await getCommunityData(communitiesList[idx].id);
      if (communityRes) {
        const thisCommunityDetail = {
          name: communitiesList[idx].name,
          ...communityRes,
        };

        setCommunitiesFormatedList((prevData) => {
          const newArr = arrayReplaceAt(prevData, idx, {
            ...prevData[idx],
            loading: false,
            stripeInfo: thisCommunityDetail,
          });
          return newArr;
        });
      }
    } catch (err) {
      console.log(
        `Couldn't fetch details for community ${communitiesList[idx].id}`
      );
      setCommunitiesFormatedList((prevData) => {
        const newArr = arrayReplaceAt(prevData, idx, {
          ...prevData[idx],
          loading: false,
          stripeInfo: undefined,
        });
        return newArr;
      });
    }
  }

  return (
    <>
      <AppSidebar />
      <div className="manage-community sidebar-space">
        <Header>
          {/* <div className="community-hdr flex aic">
            <div className="left flex aic">
              {communities?.length ? (
                <div className="title font s18 b7 black">{`${
                  communities?.length !== 1 ? 'Communities' : 'Community'
                } (${communities?.length})`}</div>
              ) : (
                <div className="title holder" />
              )}
              <Link
                to="/signup/apartments"
                className="cleanbtn add-category font s14 b5 cfff flex aic"
              >
                <span className="ico icon-plus s16 cfff" />
                <span>Add Community</span>
              </Link>
            </div>
   
          </div> */}
        </Header>

        <div className=" w-full mt-3 px-4">
          <div className="flex w-full flex-wrap items-center justify-between">
            <h1
              className=" text-xl font-semibold text-gray-700"
              onClick={() =>
                console.log(
                  'FilteredFormattedCommunities: ',
                  filterLoadedCommunitiesDetails(communitiesFormattedList)
                )
              }
            >
              Billings
            </h1>
            <button
              style={{ color: 'white', maxWidth: '150px' }}
              className="p-2 bg-blue-500 color-gray-100 cursor-pointer rounded w-fit"
              onClick={() => {
                console.log('MUST Refresh now !');
                setRefreshAllCommunitiesData(true);
              }}
            >
              Refresh Data
            </button>
          </div>
          <div className=" mt-5 w-full grid-cols-1 grid lg:grid-cols-4 gap-10">
            <div className=" lg:col-span-3">
              <div>
                <CommunityBilling
                  communitiesFormatedList={communitiesFormattedList}
                  setForceFetchCommunityIndex={setForceFetchCommunityIndex}
                />
              </div>
            </div>
            <div>
              <BillingAmountSummaryBox
                communitiesDetailsList={filterLoadedCommunitiesDetails(
                  communitiesFormattedList
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Billing;

function filterLoadedCommunitiesDetails(allFormattedCommunitiesList) {
  if (typeof allFormattedCommunitiesList === 'object')
    return allFormattedCommunitiesList
      ?.filter((thisCommunity) => !thisCommunity.loading) // the community is not loading, as in it has finished loading
      ?.map((thisCommunity) => thisCommunity.stripeInfo); // get the stripe info

  return [];
}
