import { MdClose } from 'react-icons/md';
import tygImage from '../../assets/videos/website-icon-TYG.svg';
const VideoSidebar = ({
  screensCollection,
  currentVideoIndex,
  onVideoChange,
  onClose,
}) => {
  return (
    <div className=" h-screen overflow-y-scroll  w-full bg-gray-100 p-4">
      {/* <div className=" w-full  h-screen "> */}
      <p className=" text-sm pb-2 font-medium">
        👋{new URLSearchParams(window.location.search).get('name')} We built
        this tour for you
      </p>
      <div className="pb-5">
        {screensCollection?.map((screen, index) => {
          return (
            <>
              <div
                key={index}
                onClick={() => {
                  if (onVideoChange) onVideoChange(index);
                }}
                className={` cursor-pointer mt-2 hidden lg:flex  md:flex-col pb-2 pt-4 items-center  rounded overflow-hidden bg-white ${
                  index === currentVideoIndex ? 'border-2 border-black' : ''
                }`}
              >
                <img
                  className=" w-full h-16 object-contain"
                  src={!screen?.img ? tygImage : screen?.img}
                />
                <p className="p-2 text-sm">{screen?.title?.slice(0, 16)}</p>
              </div>
              <div
                key={index}
                onClick={() => {
                  if (onVideoChange) onVideoChange(index);
                  if (onClose) onClose();
                }}
                className={` cursor-pointer mt-2 flex lg:hidden  md:flex-col gap-2  items-center  rounded overflow-hidden bg-white ${
                  index === currentVideoIndex ? 'border-2 border-black' : ''
                }`}
              >
                <img
                  className="  h-20 w-32  object-cover"
                  src={!screen?.img ? tygImage : screen?.img}
                />
                <p className="p-2 text-sm">{screen?.title?.slice(0, 16)}</p>
              </div>
            </>
          );
        })}
      </div>
      {/* </div> */}
    </div>
  );
};

export default VideoSidebar;
