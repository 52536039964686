import axios from 'axios';
import momenttz from 'moment-timezone';
import { API_HOST } from '../../utils/request';

export const getEntrataData = async (integrationId) => {
  const res = await axios.get(
    `https://api.directual.com/good/api/v5/data/message/getAndCreateMessage?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&messageId=${integrationId}`
  );

  if (res.data.payload[0]?.data) {
    return JSON.parse(res.data.payload[0].data);
  }

  return {};
};

const getUrlParamsForEventData = () => {
  const query = new URLSearchParams(window.location.search);
  const visitor_uuid = query.get('visitor_uuid') || '';
  const visit_uuid =  ''; //visit_uuid 
  return { visitor_uuid, visit_uuid };
};

export const convert_date_by_tz = async (date, tz) => {
  if (tz == 'MST') {
    return momenttz(date).tz('America/Denver'); // MST
  } else if (tz == 'PST') {
    return momenttz(date).tz('America/Los_Angeles'); // PST
  }
};
window.addEventListener(
  'message',
  (event) => {
    if (event.data?.event_type == 'form_submission') {
      console.log('eventtttttttt', event);
    }
  },
  false
);

export const saveLeadinDB = async (magnet_uuid, details) => {
  window.parent.postMessage(
    {
      event_type: 'form_submission',
      app_name: 'mini-apps',
      // ...details,
      details: {
        form_data: {
          lead_name: details?.form_data?.lead_name,
          lead_email: details?.form_data?.lead_email,
          lead_phone: details?.form_data?.lead_phone,
          tourTime: details?.form_data?.tourTime,
        },
      },
    },
    '*'
  );
  window.postMessage(
    {
      event_type: 'form_submission',
      app_name: 'mini-apps',
      details: {
        form_data: {
          lead_name: details?.form_data?.lead_name,
          lead_email: details?.form_data?.lead_email,
          lead_phone: details?.form_data?.lead_phone,
          tourTime: details?.form_data?.tourTime,
        },
      },
    },
    '*'
  );
  
  const res = await axios.post(`${API_HOST}/event`, {
    ...getUrlParamsForEventData(),
    magnet_uuid,
    url: window.location.href,
    event_type: 'form_submission',
    details,
  });

  console.log('RESPONSE', res);

  // const jitsu = jitsuClient({
  //   key: 'js.oj1e1srp87oijohgjo9fyr.9b4uph2lmcwy2a9khx9o6o',
  //   tracking_host: 'https://t.jitsu.com',
  // });
  // jitsu.track('form_submission', {
  //   magnet_uuid,
  //   event_type: 'form_submission',
  //   details: {
  //     form_data: {
  //       lead_name: details?.form_data?.lead_name,
  //       lead_email: details?.form_data?.lead_email,
  //       lead_phone: details?.form_data?.lead_phone,
  //     },
  //   },
  // });
};
