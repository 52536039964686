import './styles.css';
import { useState, useEffect, useRef } from 'react';
import { Configuration, OpenAIApi } from 'openai';
import Calendar from 'react-calendar';
import moment from 'moment';
import React from 'react';
import {
  bookAppointment,
  data,
  convert_date_by_tz,
  handleGoal,
  get_corpus_by_communityid,
} from './utils';
import http from 'stream-http';
import { useHistory, useParams } from 'react-router-dom';
import Stories from 'react-insta-stories';
import { supabase } from '../../../services/supabase';

/*

URL Params - for now fake it and make it variaables
 magnet_uuid
 communityId
 name_property
 start_node text str
 entrata_property_id
 entrata_password
 entrata_username

Dynamic
 fetch corpus - leasing context below
 fetch additional node structures - 
    and combine them?
  
 Manage dynamicallY:
  - book appointment / scheduler
    we need call scheduler pipeline (and availability)

 we need to think about UI larger picture?  

 One easy win for today, is saving the routehistory and loading it from localStoarge
      - loading route history from localstorage
          -variables? should be saved from local storage
*/

const useGpt3 = (leasing_context_TYG) => {
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const configuration = new Configuration({
    apiKey: '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',
    // apiKey: "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"
  });
  const openai = new OpenAIApi(configuration);

  const askGpt3 = async (context, question, setText, nextRouteText) => {
    // const prompt = `Question: ${question}\nAnswer:`;
    setLoading(true);

    // const completion = await openai.createCompletion({
    //   model: "text-davinci-003",
    //   prompt: `${leasing_context_TYG}\n Question:${question}\nAnswer:`,
    //   max_tokens: 250,
    //   // n: 5,
    //   // stop: "\n",
    //   temperature: 0.7
    // });

    let res = await new Promise(async (resolve, reject) => {
      var completion2 = '';

      await http.get(
        {
          path: `https://tkweddqlriikqgylsuxz.supabase.co/functions/v1/openai2?prompt=${encodeURIComponent(
            leasing_context_TYG
          )}\n Question:${encodeURIComponent(question)}\nAnswer:}`,
          headers: {
            Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRrd2VkZHFscmlpa3FneWxzdXh6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzIwNzc0MjIsImV4cCI6MTk4NzY1MzQyMn0.IWdx6ELEUykw11jBQUW2ZT30jjX2-TyHT8INgLe07Ew`,
          },
          responseType: 'stream',
        },
        function (res) {
          setLoading(false);
          res.on('data', function (buf) {
            //preprocess
            const dataObjects = buf.toString().split('\n');

            // Extract the "content" property from each data object
            const contents = dataObjects.map((dataObject) => {
              try {
                const jsonData = JSON.parse(dataObject.replace('data: ', ''));
                return jsonData.choices[0].delta.content;
              } catch (error) {
                return null;
              }
            });
            console.log('contents', contents);
            contents.map((item) => {
              console.log('contents_contents', item);
              if (item) {
                completion2 = completion2 + item;
              }
            });
            console.log('completion2', completion2);
            setAnswer(completion2);
            if (setText) {
              setText([completion2]);
            }
          });

          res.on('end', async function () {
            console.log('end_reachedd');
            // setLoading(false);
            console.log('Completion TYG: ', completion2);
            if (resolve) {
              if (setText) {
                setText([completion2, ...nextRouteText]); // othertext - is the second line text, text from the next route
              }
              resolve(completion2);
            }

            return answer;
          });
        }
      );
    });

    // return answer;
    //setLoading(false);
    // setAnswer(completion.data.choices[0].text.trim());
    // console.log("callinggggg");
    return res;
    // -----
    // const completion = async (message) => {
    //   const response = await openai.createChatCompletion({
    //     model: "gpt-3.5-turbo",
    //     messages: [
    //       {
    //         role: "system",
    //         content: `You are virtual leasing assistant ${leasing_context_TYG}.`
    //       },
    //       { role: "user", content: question }
    //     ]
    //   });
    //   console.log("response: ", response?.data?.choices?.[0]?.message?.content);
    //   return response?.data?.choices?.[0]?.message?.content;
    // };
    // setLoading(false);
    // console.log(
    //   "completion TYG: ",
    //   completion?.data?.choices?.[0]?.message?.content
    // );
    // setAnswer(completion?.data?.choices?.[0]?.message?.content);
    // return completion?.data?.choices?.[0]?.message?.content;
    //  ---- END

    // const completions = await openai.completions.create({
    //   engine: "text-davinci-003",
    //   prompt: `${context}\n${prompt}`,
    // max_tokens: 4097,
    //   n: 1,
    //   stop: "\n",
    // temperature: 0.7;
    // });
    // setAnswer(completions.choices[0].text.trim());
  };

  return { answer, askGpt3, loading };
};

export default function App() {
  return (
    <div className="App">
      <Chatbot data={data} />
    </div>
  );
}

function isEmail(email) {
  var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  if (email !== '' && email.match(emailFormat)) {
    return true;
  }
  return false;
}

const initialFormJson = {};

const Chatbot = ({ data }) => {
  const [entrata_username, setEntrataApiUsername] = useState('');
  const [entrata_password, setEntrataApiPassword] = useState('');
  const [propertyId, setPropertyId] = useState('');

  async function loadEntrataFromMagnet() {
    let magnetData = supabase
      .table('Magnet')
      .select('*')
      .eq('community_id', 425)
      .single()
      .execute().data;
    const api_entrata = magnetData?.['integration_details']?.['api-entrata'];
    if (api_entrata) {
      setEntrataApiUsername(api_entrata?.username);
      setEntrataApiPassword(api_entrata?.password);
      setPropertyId(api_entrata?.property_id);
    }
  }

  const [currentRoute, setCurrentRoute] = useState('welcome_tour'); //"lead_graduation"); // lead_graduation // lead_name
  const [text, setText] = useState(data[currentRoute]?.text);
  const [action, setAction] = useState(data[currentRoute].action);
  const [routeHistory, setRouteHistory] = useState([]);
  console.log('routeHistoryTYG: ', routeHistory);
  const { communityId } = useParams();
  // given a communityId in the URL or in a variable, save the leasing_context as dynamic data,
  //let communityId = 428;
  const [leasing_context_TYG, setleasing_context_TYG] =
    useState(`Bellamy Carrollton is the newest off-campus housing located just steps away from UWG's east campus, Greek Row, local restaurants, shopping, and more.

Here at Bellamy, we believe in comfort and convenience. We offer 3, 4 and 5 Bedroom floor plans. Each of our homes comes fully furnished and equipped with Alexa-Integrated smart-home technology. With plenty of personal space to study, a 55” TV, stainless steel appliances and an in-unit washer and dryer, you’re sure to have everything you need and more.

And that’s not all, here at Bellamy, we strive to make your elevated lifestyle better. Whether you see yourself lounging by our resort-style pool during the day, catching a workout in our smart connected fitness center, catching a competitive game of pool, or just needing something printed for school or work, you’ll have easy access to all of these amenities at our beautiful leasing office.

We lease by the bedroom and currently have the following selection of homes available. Here is our availability for the August 6, 2023 to July 15, 2024 term:
3 Bedroom 3 Bathroom(3x3): Approximately $679 | Available
4 Bedroom 4 Bathroom(4x4): Approximately $629 | Available
5 Bedroom 5 Bathroom(5x5): Approximately $619 | Available

We have a $25 dollar pet policy, per pet.
Our typical electricity/utility prices for rent are approximately $80/mo

You can match roommates within a unit, but we do not allow for double occupancy in a room.

Our best current special is that we are waiving all application and administration fees.

Resident parking is included.

We primarily offer only 12 month lease terms. However, if you are potentially looking to move-in sooner, we sometimes can sign for immediate move-ins till the end of the term. I recommend scheduling a call back to have a team member talk about the specifics for you.

Amenities include:
- Spacious townhome living
- Patios and balconies available in select floor plans
- Pet-friendly with spacious bark park
- Spacious, neighborhood feel
- Open green space
- Ample resident parking
- Resort-style pool with covered patio
- Outdoor grilling stations + fire pit
- Private shuttles to campus and grocery w/ late night runs
- 24 hour indoor fitness center + Echelon eBikes
- Yoga and spin area featuring virtual fitness classes 
- Walking and running trails
- 4 private study lounges
- TV lounge  + free printing
-  Extended hours in clubhouse with gaming and social areas
- Free tanning
- Free coffee bar
- Bike storage
- Community Rewards loyalty program
- On-site courtesy patrol
- 24 hour emergency maintenance and courtesy officer 
- Roommate matching available
- U Life Resident Life Program

3,4,5-Bedroom Student Apartments Amenities include:
- Private bathroom for each bedroom
- Free high-speed Wi-Fi
- Free water, sewage and trash included
- 55" Flat screen TV in living room
- Fully furnished with contemporary finishes
- Granite countertops + stainless steel appliances
- Hardwood-style floors
- Secure electronic access
- Spacious closets
- Full size washer and dryer
- Patios and balconies in select units

We don't have an elevator because all our units are townhomes, and several of our units are available at the ground level of the Townhome and ADA compliant.

Your address is: 333 Foster St. Carrollton, GA 30117

Some great nearby restaurants are: Highland Deli, Corner Cafe, Chips Burger Bar, La Trattoria, and Samba Loca. We are right next door to the bus stop for the university, so we are literally a couple minutes from Front Campus, the University Recreation Center, and University Campus Center. We are approximately 8 minutes from Kroger and Publix. 

`);

  const get_corpus = async () => {
    let res = await get_corpus_by_communityid(communityId);
    console.log('corpusss', res);
    if (res?.corpus) {
      setleasing_context_TYG(res?.corpus);
    }
  };

  console.log('leasing_context_TYG', leasing_context_TYG);
  useEffect(() => {
    get_corpus();
    loadEntrataFromMagnet();

    // if (localStorage.getItem("routeHistory")) {
    //   let routeHistoryTemp = JSON.parse(localStorage.getItem("routeHistory"));
    //   setRouteHistory(JSON.parse(localStorage.getItem("routeHistory")) || []);
    //   setCurrentRoute(routeHistoryTemp[routeHistoryTemp.length - 1]?.route);
    // }
  }, []);
  useEffect(() => {
    if (routeHistory.length)
      localStorage.setItem('routeHistory', JSON.stringify(routeHistory));
  }, [routeHistory]);

  const cleanup = () => {
    setSelectedItems([]);
    setTextIndex(0); // Reset textIndex to 0
    setShowTyping(true);
  };

  const [floorPlanData, setFloorPlanData] = useState([]);
  const [floorPlanFilter, setFloorPlanFilter] = useState({
    showAvailabilityPricingInfo: false,
    specificInterestedBed: [],
  });

  function getFloorPlanFilter(query = '') {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };

    fetch(
      `https://api.leasemagnets.com/ai/check_query_for_availability?query=${query}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log('floorPlanFilter resp TYG: ', result);
        setFloorPlanFilter(result);
      })
      .catch((error) => console.log('error', error));
  }

  function filter_floorplans(
    filter = {
      showAvailabilityPricingInfo: true,
      specificInterestedBed: [],
    }
  ) {
    let filtered = [];

    if (!filter?.showAvailabilityPricingInfo) {
      return [];
    }

    if (
      filter?.specificInterestedBed?.length === 0 ||
      filter?.specificInterestedBed.includes('all')
    ) {
      return floorPlanData;
    }

    for (let i = 0; i < floorPlanData.length; i++) {
      let floor_plan = floorPlanData[i];

      let bed_ct = floor_plan.details?.beds ?? -1;
      console.log(
        'bed_ct: ',
        bed_ct,
        filter.specificInterestedBed.includes(bed_ct)
      );

      if (filter.specificInterestedBed.includes(bed_ct)) {
        filtered.push(floor_plan);
        continue;
      }

      for (let j = 0; j < filter.specificInterestedBed.length; j++) {
        let _filter = filter.specificInterestedBed[j];

        if (typeof _filter === 'string') {
          try {
            if (eval(`${bed_ct} ${_filter}`)) {
              filtered.push(floor_plan);
            }
          } catch {
            continue;
          }
        }
      }
    }

    return filtered;
  }

  // Get magnet data for floor plans
  useEffect(() => {
    if (
      !(entrata_username !== '' && entrata_password !== '' && propertyId !== '')
    ) {
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var raw = JSON.stringify({
      creds: {
        username: entrata_username,
        password: entrata_password,
      },
      propertyId: propertyId,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(
      'https://api.leasemagnets.com/integrations/entrata/getFloorPlansInJson',
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log('floorplandata TYG: ', result?.resp);
        setFloorPlanData(result?.resp);
      })
      .catch((error) => console.log('error', error));
  }, [entrata_username, entrata_password, propertyId]);

  // GPT 3 and GPT 4 TYG
  const { answer, askGpt3, loading } = useGpt3(leasing_context_TYG);

  // CLEANUP BEGIN
  const [textIndex, setTextIndex] = useState(0);
  const [showTyping, setShowTyping] = useState(true);

  useEffect(() => {
    console.log('loadingTYG: ', loading);
    if (loading) {
      setShowTyping(true);
    }
  }, [loading]);

  const scrollRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef?.current?.focus();
    console.log('Routehistory TYG:', routeHistory);
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollHeight + 50,
          behavior: 'smooth',
        });
      }
    }, 100);
  }, [routeHistory]);

  useEffect(() => {
    if (textIndex < text?.length) {
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollHeight + 50,
          behavior: 'smooth',
        });
      }

      const timeoutId = setTimeout(() => {
        setTextIndex((prevIndex) => prevIndex + 1);
      }, 500);

      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      setShowTyping(false);
    }
  }, [showTyping, textIndex, text]);

  // CLEANUP END

  const handleButtonClick = (route, title) => {
    const prevRoute = { route: currentRoute, text, answer: title };
    console.log('handleMultipleSelectSubmit: ', prevRoute);
    if (
      prevRoute.route === 'callback' &&
      prevRoute.answer !== 'Request a callback'
    ) {
      console.log('handleMultipleSelectSubmit: submitted', prevRoute);
      handleGoal(
        prevRoute?.route,
        '📞 Callback Reqested: ' + prevRoute?.answer,
        { callback_time: prevRoute?.answer }
      );
    }
    if (prevRoute.route === 'lead_lease_signed') {
      let text = '';
      routeHistory.map((item) => {
        if (item?.route === 'lead_graduation') {
          text += 'Graduating Year: ' + item?.answer;
          localStorage.setItem('lead_graduation', item?.answer);
        } else if (item?.route === 'lead_budget') {
          text += ', Budget: ' + item?.answer;
          localStorage.setItem('lead_budget', item?.answer);
        }
      });
      text += ', Lease Signed(: ' + prevRoute?.answer + ' )';
      localStorage.setItem('lead_lease_signed', prevRoute?.answer);
      handleGoal('lead_event', text);
    }
    if (data[currentRoute].action.type === 'multiple-choice') {
      prevRoute.options = data[currentRoute].action.buttons.map((button) => {
        if (title === button.title && route === button.route) {
          return { ...button, selected: true };
        } else {
          return { ...button, selected: false };
        }
      });
    }

    setCurrentRoute(route);
    setText(data[route].text);
    setAction(data[route].action);
    setRouteHistory([...routeHistory, prevRoute]);
    cleanup();
  };

  const handleTextInputSubmit = async (value) => {
    const prevRoute = { route: currentRoute, text, answer: value };
    const nextRoute = action.route;
    console.log('question: ', action?.goal, value);

    setText([]);
    setCurrentRoute(nextRoute);
    setAction(data[nextRoute].action);
    setRouteHistory([...routeHistory, prevRoute]);

    console.log('old text', text);
    let answerAI = '';
    if (currentRoute === 'question') {
      console.log("i'm here", value);
      // if (entrata_username !== "" && entrata_password !== "" && propertyId !== "") {
      //   getFloorPlanFilter(value);
      // }
      answerAI = await askGpt3(
        leasing_context_TYG,
        value,
        setText,
        data[nextRoute].text
      );
      console.log('answerAI: ', answerAI);
    }
    if (!answerAI) {
      setText(data[nextRoute].text);
    }
    // setText(
    //   answerAI ? [answerAI, ...data[nextRoute].text] : data[nextRoute].text
    // );
    // check if there is a goal
    if (action?.goal) {
      handleGoal(action?.goal, value);
    }
    if (action?.localStorageKey === 'referree_email') {
      handleGoal(
        'lead_referral',
        '🧲 Referral ' +
          `${localStorage.getItem(
            'referree_name'
          )} ${value} ${localStorage.getItem('referree_phone')}`,
        { referree_name: localStorage.getItem('referree_name') }
      );
    }

    // Clear the input field
    try {
      localStorage.setItem(action?.localStorageKey, value);
      document.querySelector('input[type="text"]').value = '';
    } catch {}

    //cleanup();
    setSelectedItems([]);
    //setTextIndex(0); // Reset textIndex to 0
    setShowTyping(true);
  };

  const handleMultipleChoiceClick = (button) => {
    const prevRoute = { route: currentRoute, text, answer: button.title };
    const nextRoute = button.route;
    setCurrentRoute(nextRoute);
    setText(data[nextRoute].text);
    setAction(data[nextRoute].action);
    setRouteHistory([...routeHistory, prevRoute]);
    cleanup();
  };

  const handleMultipleSelectSubmit = (selectedItems) => {
    const prevRoute = {
      route: currentRoute,
      text,
      answer: selectedItems.join(', '),
    };

    const nextRoute = action.route;
    setCurrentRoute(nextRoute);
    setText(data[nextRoute].text);
    setAction(data[nextRoute].action);
    setRouteHistory([...routeHistory, prevRoute]);
    cleanup();
  };

  // const renderText = () => {
  //   return (
  //     <div>
  //       {routeHistory.map((route, index) => (
  //         <div key={index} style={{ textAlign: "left" }}>
  //           {route.text.map((line, index) => (
  //             <p key={index}>{line}</p>
  //           ))}
  //           <div style={{ textAlign: "right" }}>{route.answer}</div>
  //         </div>
  //       ))}
  //       <div style={{ textAlign: "left" }}>
  //         {text.map((line, index) => (
  //           <p key={index}>{line}</p>
  //         ))}
  //       </div>
  //     </div>
  //   );
  // };

  const renderText = () => {
    return (
      <div
        ref={scrollRef}
        // className="scrollbarDiv"
        style={{
          // maxHeight: "92vh",
          // overflowY: "scroll",
          padding: '1rem',
          boxSizing: 'border-box',
          scrollBehavior: 'smooth',
          width: '98vw',
        }}
      >
        {routeHistory.map((route, index) => (
          <div key={index} style={{ textAlign: 'left', whiteSpace: 'pre' }}>
            {route.text.map((line, index) => (
              <div style={{ display: 'flex', margin: '18px 0' }}>
                <p className="message_block" key={index}>
                  {line}
                </p>
              </div>
            ))}
            {route.options && (
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                {route.options.map((option, index) => (
                  <button
                    key={index}
                    className="button_css"
                    onClick={() =>
                      handleButtonClick(option.route, option.title)
                    }
                  >
                    <div className="btn_border_rounded">
                      {option.selected ? (
                        <div className=" btn_rounded_center"></div>
                      ) : (
                        <div className=" btn_rounded_center_white"></div>
                      )}
                    </div>
                    {option.title}
                  </button>
                ))}
              </div>
            )}
            <div
              onClick={() => {
                console.log('routethrowback: ', route);
                handleButtonClick(route?.route, '🔁 ' + route?.answer);
              }}
              style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'right',
                marginTop: '16px',
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="edit_icon"
                style={{
                  width: '14px',
                  height: '14px',
                  marginTop: '12px',
                  marginRight: '5px',
                }}
              >
                <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
              </svg>

              <p className="answer_right"> {route.answer}</p>
            </div>
          </div>
        ))}
        <div style={{ textAlign: 'left' }}>
          {text?.slice(0, textIndex)?.map((line, index) => {
            return (
              <div style={{ display: 'flex', margin: '10px 0' }}>
                <p className="message_block" key={index}>
                  {line}
                </p>
              </div>
            );
          })}

          {(showTyping || loading) && (
            <div
              style={{
                marginLeft: '15px',
                marginTop: '15px',
                // backgroundColor: "lightgray"
              }}
            >
              <div className="dot-typing"></div>
              <br />
              <br />
            </div>
          )}
        </div>
      </div>
    );
  };

  // const renderText = () => {
  //   const [textIndex, setTextIndex] = useState(0);
  //   const [showTyping, setShowTyping] = useState(false);
  //   console.log("showTyping,", textIndex, showTyping);

  //   useEffect(() => {
  //     const timer = setTimeout(() => {
  //       if (textIndex < text?.length) {
  //         setTextIndex(textIndex + 1);
  //         // setShowTyping(true);
  //       } else {
  //         setShowTyping(false);
  //       }
  //     }, 500);
  //     return () => {
  //       clearTimeout(timer);
  //       setShowTyping(true);
  //     };
  //   }, [text, textIndex, action]);

  //   return (
  //     <div>
  //       {routeHistory.map((route, index) => (
  //         <div key={index} style={{ textAlign: "left" }}>
  //           {route.text.map((line, index) => (
  //             <p key={index}>{line}</p>
  //           ))}
  //           <div style={{ textAlign: "right" }}>{route.answer}</div>
  //         </div>
  //       ))}
  //       <div style={{ textAlign: "left" }}>
  //         {text.slice(0, textIndex).map((line, index) => {
  //           return <p key={index}>{line}</p>;
  //         })}

  //         {showTyping && text?.length > 0 && (
  //           <div style={{ marginLeft: "15px" }}>
  //             <div className="dot-typing"></div>
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //   );
  // };
  const [textInputError, setTextInputError] = useState(null);
  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const renderButtons = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '10px',
        }}
      >
        {action.buttons.map((button, index) => (
          <button
            key={index}
            className="button_css"
            onClick={() => handleButtonClick(button.route, button.title)}
          >
            <div className="btn_border_rounded">
              <div className=" btn_rounded_center_white"></div>
            </div>
            {button.title}
          </button>
        ))}

        {/* <form
          onSubmit={async (e) => {
            e.preventDefault();
            console.log("etargetTYG", e.target);
            setCurrentRoute("question");
            setText([
              "Sure what is your question?",
              "Do you have another question"
            ]);
            setAction({
              type: "text-input",
              goal: "question",
              route: "question_followup",
              localStorageKey: "question"
            });
            let value = e.target.elements[0].value;

            const prevRoute = {
              route: currentRoute,
              text,
              answer: "Question: " + value
            };
            setRouteHistory([...routeHistory, prevRoute]);
            cleanup();

            let nextRoute = "question_followup";
            // console.log("ask a question: ", e.target.elements[0].value);
            localStorage.setItem("question", value);
            getFloorPlanFilter(value);
            askGpt3(leasing_context_TYG, value, setText, data[nextRoute].text);

            // handleTextInputSubmit(e.target.elements[0].value);
            // setTextInputError(null);
          }}
        >
          <div
            class="input-container"
            style={{
              border: " 1px solid #0a1f35a9",
              padding: "10px 15px",
              borderRadius: "20px",
              background: loading ? "lightgray" : "none",
              width: "100%"
            }}
          >
            <input
              // defaultValue={
              //   action?.localStorageKey
              //     ? localStorage.getItem(action?.localStorageKey) || ""
              //     : ""
              // }
              type="text"
              style={{
                width: "80%",

                color: loading ? "gray" : "black",
                background: loading ? "lightgray" : "none"
              }}
              placeholder="Ask a question"
              // value={textInput}
              key={currentRoute}
              ref={inputRef}
              // onChange={(e) => setTextInput(e.target.value)}
              required
            />
            <button
              className="abs_btn"
              type="submit"
              style={{
                border: "none",
                outline: "none",
                background: "none"
              }}
            >
              <img src="/sub.png" alt="" />
            </button>
            <span style={{ fontSize: "10px", color: "red" }}>
              {textInputError}
            </span>
          </div>
        </form>
       */}
      </div>
    );
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateNumber = (number) => {
    return String(number)
      .toLowerCase()
      .match(/^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/);
  };

  const renderTextInput = () => {
    console.log(
      'fetching localstorage',
      action?.localStorageKey,
      localStorage.getItem(action?.localStorageKey)
    );

    const suggestedValues = [
      'Pet and Parking Policy',
      'Shuttle System',
      'Roommate Matching Policy',
      'Popular Amenities',
    ];

    const fillInputWithValue = (value) => {
      if (inputRef.current) {
        inputRef.current.value = value;
      }
      inputRef?.current?.focus();
    };

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (action?.regex) {
            if (action?.regex === 'email') {
              if (!validateEmail(e.target.elements[0].value)) {
                setTextInputError('invalid email');
                return;
              }
            }
            if (action?.regex === 'phone') {
              if (!validateNumber(e.target.elements[0].value)) {
                setTextInputError('invalid number');
                return;
              }
            }
          }
          handleTextInputSubmit(e.target.elements[0].value);
          setTextInputError(null);
          console.log(
            'newLocalStorageKey',
            action?.localStorageKey,
            localStorage.getItem(action?.localStorageKey)
          );
        }}
      >
        {currentRoute === 'question' && (
          <>
            <span style={{ fontSize: '10px' }}>
              Note: Will answer with AI, answers may vary. Please request a
              callback or live chat for confirmation on questions if we are
              unable to answer.
            </span>
            <div
              style={{ width: '105%', marginBottom: '5px', marginLeft: '-4px' }}
            >
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '2px' }}>
                {suggestedValues.map((value, index) => (
                  <div
                    style={{
                      border: '1px solid lightgray ',
                      margin: '4px',
                      padding: '4px',
                      borderRadius: '10px',
                      cursor: 'pointer',
                    }}
                    key={index}
                    onClick={() => fillInputWithValue(value)}
                  >
                    {value}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        <div
          class="input-container"
          style={{
            border: ' 1px solid #0a1f35a9',
            padding: '10px 15px',
            borderRadius: '20px',
            background: loading ? 'lightgray' : 'none',
          }}
        >
          <input
            defaultValue={
              action?.localStorageKey
                ? localStorage.getItem(action?.localStorageKey) || ''
                : ''
            }
            type="text"
            style={{
              width: '90%',

              color: loading ? 'gray' : 'white',
              background: loading ? 'lightgray' : 'none',
            }}
            placeholder="Type here..."
            // value={textInput}
            key={currentRoute}
            ref={inputRef}
            // onChange={(e) => setTextInput(e.target.value)}
            required
          />
          {/* {loading && <> loading... </>} */}
          <button
            className="button_css"
            className="abs_btn"
            type="submit"
            style={{
              border: 'none',
              outline: 'none',
              background: 'none',
            }}
          >
            <img src="/sub.png" alt="" />
          </button>
          <span style={{ fontSize: '10px', color: 'red' }}>
            {textInputError}
          </span>

          {/* <button
              classNa
              me="abs_btn"
              type="submit"
              style={{ border: "none", outline: "none", background: "none" }}
            >
              <img
                src="/loadingTYG.gif"
                alt=""
                style={{ background: "black", height: "15px" }}
              />
            </button> */}
        </div>
      </form>
    );
  };

  const [selectedItems, setSelectedItems] = useState([]);

  const handleMultipleSelectChange = (e) => {
    const itemValue = e.target.value;
    if (e.target.checked) {
      setSelectedItems([...selectedItems, itemValue]);
    } else {
      setSelectedItems(selectedItems.filter((value) => value !== itemValue));
    }
  };

  const renderMultipleSelect = () => {
    const selectItems = action.buttons.map((button, index) => ({
      label: button.title,
      value: index + 1,
    }));

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleMultipleSelectSubmit(selectedItems);
        }}
      >
        <div className="flex_div_checkbox">
          {selectItems.map((item) => (
            <div className="single_checkbox" key={item.value}>
              <input
                style={{ cursor: 'pointer', width: '15px', height: '15px' }}
                type="checkbox"
                value={item.label}
                id={item.label}
                checked={selectedItems.includes(item.label)}
                onChange={handleMultipleSelectChange}
              />
              <label style={{ cursor: 'pointer' }} htmlFor={item.label}>
                {item.label}
              </label>
            </div>
          ))}
        </div>
        {selectedItems.length ? (
          <button
            className="button_css"
            style={{ background: '#0a1f35a9', color: 'white' }}
            className="border_class"
            type="submit"
          >
            Confirm Selections ({selectedItems.length})
          </button>
        ) : (
          <button
            className="button_css"
            className="border_class"
            style={{ cursor: 'not-allowed' }}
            disabled
            type="submit"
          >
            Confirm Selections
          </button>
        )}
      </form>
    );
  };

  const virtualTour = () => {
    return (
      <>
        <iframe
          src={`https://embed.tour.video?uuid=008d45d0-ab7f-49fd-b082-77a48380010e&inline=true&inlineCTA=Take%20a%20tour&hideButtons=false&tourMsg=undefined`}
          allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
          width="100%"
          height="650px"
          style={{ border: null, borderRadius: '24px', marginBottom: '25px' }}
        />
        {virtualTour2()}
        {renderButtons()}
      </>
    );
  };
  const virtualTour2 = () => {
    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Stories
            stories={[
              {
                url: 'https://i.imgur.com/q2ef0eN.png',
                duration: 5000,
                header: {
                  heading: 'Welcome to Cayce Cove',
                  subheading:
                    'The best place to callhome this side of University of South Carolina',
                  profileImage: 'https://i.imgur.com/l5q2Eye.png',
                },
              },
              {
                url: 'https://i.imgur.com/9qZLeqk.jpg',
                duration: 5000,
                header: {
                  // subheading:
                  //   "Fresh, catered (non-veg, veg, and vegan friendly) food is brought in on time morning, breakfast, and dinner",
                  profileImage: 'https://i.imgur.com/l5q2Eye.png',
                },
              },
              {
                url: 'https://i.imgur.com/2KrgDG1.jpg',
                duration: 5000,
                header: {
                  // subheading:
                  //   "Fresh, catered (non-veg, veg, and vegan friendly) food is brought in on time morning, breakfast, and dinner",
                  profileImage: 'https://i.imgur.com/l5q2Eye.png',
                },
              },

              {
                url: 'https://i.imgur.com/d7eDxJc.jpg',
                duration: 5000,
                header: {
                  // subheading: "Did we mention, Snacks are available on tap",
                  profileImage: 'https://i.imgur.com/l5q2Eye.png',
                },
              },

              {
                url: 'https://i.imgur.com/SJdUKkI.jpg',
                duration: 5000,
                header: {
                  // subheading: "Every hacker gets their own bathroom",
                  profileImage: 'https://i.imgur.com/l5q2Eye.png',
                },
              },
              {
                url: 'https://i.imgur.com/pd4GpeQ.png',
                duration: 5000,
                header: {
                  // subheading: "And plenty of space to hack!",
                  profileImage: 'https://i.imgur.com/l5q2Eye.png',
                },
              },
              {
                url: 'https://i.imgur.com/qibFQle.png',
                duration: 5000,
                header: {
                  // subheading: "Every hacker gets their own bedroom",
                  profileImage: 'https://i.imgur.com/l5q2Eye.png',
                },
              },
              {
                url: 'https://i.imgur.com/YtD7ocG.jpg',
                duration: 5000,
                header: {
                  // subheading: "And plenty of space to hack!",
                  profileImage: 'https://i.imgur.com/l5q2Eye.png',
                },
              },
              {
                url: 'https://i.imgur.com/qMuVi9B.jpg',
                duration: 5000,
                header: {
                  // subheading: "And plenty of space to hack!",
                  profileImage: 'https://i.imgur.com/l5q2Eye.png',
                },
              },
              {
                url: 'https://i.imgur.com/ry62bVa.jpg',
                duration: 5000,
                header: {
                  // subheading: "And plenty of space to hack!",
                  profileImage: 'https://i.imgur.com/l5q2Eye.png',
                },
              },
              {
                url: 'https://i.imgur.com/chLbPLr.jpg',
                duration: 5000,
                header: {
                  // subheading:
                  //   "Fridays at 6 PM all hackers rushes downstairs over for demos of their applications’ progress to other hackers",
                  profileImage: 'https://i.imgur.com/l5q2Eye.png',
                },
              },
            ]}
            // defaultInterval={1500}
            width={'85vw'}
            loop={true}
            // height={200}
          />
        </div>
        {/* <img
          style={{ width: "400px", objectFit: "contain" }}
          src="https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/850cb6be-2ea0-42ca-e11c-2299d9609100/small"
        /> */}
        {/* {renderButtons()} */}
      </>
    );
  };

  const [step, setStep] = useState(1);
  const [selectedTourType, setSelectedTourType] = useState('in-person');
  const [selectedScheduleDate, setSelectedScheduleDate] = useState(new Date());
  const [selectedHours, setSelectedHour] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isAppointmentLoading, setIsAppointmentLoading] = useState(false);
  // backend send lead to Tour and let Tour forward the lead by itself
  async function sendRequest(url, options = null) {
    let reqBody = {
      creds: {
        username: entrata_username,
        password: entrata_password,
        domain: entrata_domain,
      },
    };
    console.log('Namee', localStorage.getItem('lead_name'));
    setIsAppointmentLoading(true);
    const query = new URLSearchParams(window.location.search);
    const timezone = query.get('timezone');
    let created_date = await convert_date_by_tz(
      new Date(),
      timezone ? timezone : 'MST'
    );
    const isBookingSuccessful = await bookAppointment(
      // cc455e16-1e6c-4a23-9ffc-6b663589ff08
      '0baf7485-4dde-4772-a14e-1d0fe0108561',
      {
        date: `${moment(created_date).format('L')}T${moment(
          created_date
        ).format('hh:mm:ss')}`,
        appointmentDate: moment(selectedDate).format('L'),
        timeFrom: `${selectedHours
          ?.split(' ')[0]
          .toLocaleLowerCase()}${selectedHours
          ?.split(' ')[1]
          .toLocaleLowerCase()}`,
        timeTo: `${selectedHours?.split(':')[0]}:30${selectedHours
          ?.split(' ')[1]
          .toLocaleLowerCase()}`,
        tourTime: `${moment(created_date).format('L')}T${moment(
          created_date
        ).format('hh:mm:ss')}`,
        tourLength: '30 Minutes',
        eventReasons: 'Tour Book Appointment, LeaseMagnets',
        entrata_event_type:
          selectedTourType === 'in-person' ? 'Appointment' : 'VirtualTour',
        //note : "Tour Type: In-Person Tour. Tour Scheduled from Leasemagnets, Please ignore this lead,its just for testing."
        note: `Lead is submitted, Tour Scheduler [${
          selectedTourType === 'in-person' ? 'Appointment' : 'VirtualTour'
        }
           : Scheduled 30 min appointment on ${moment(
             await convert_date_by_tz(selectedDate, timezone ? timezone : 'EST')
           ).format('YYYY-DD-MM')} at ${selectedHours
          ?.split(' ')[0]
          .toLocaleLowerCase()}${selectedHours
          ?.split(' ')[1]
          .toLocaleLowerCase()} 
          ]`,
      },
      //userInfo,
      {
        name: localStorage.getItem('lead_name'),
        lead_name: localStorage.getItem('lead_name'),
        lead_phone: localStorage.getItem('lead_phone'),
        lead_email: localStorage.getItem('lead_email'),
        first_name: localStorage.getItem('lead_name')?.split(' ')[0],
        last_name: localStorage.getItem('lead_name')?.split(' ')[1],
        email: localStorage.getItem('lead_email'),
        phone: localStorage.getItem('lead_phone'),
      },
      selectedTourType
      //(msg) => toast.error(msg)
    );
    if (isBookingSuccessful) {
      setIsAppointmentLoading(false);
      handleButtonClick(
        'welcome_tour',
        `Booked in-person tour for ${moment(
          new Date(selectedScheduleDate)
        ).format('LL')}, ${selectedHours} EST. `
      );
      //setStep(4);
    }
  }

  const bookingScheduler = () => {
    return (
      <>
        <div className="meta">
          <div className="txt font" style={{ marginBottom: '12px' }}>
            Select your tour type{' '}
          </div>
        </div>
        <div className="actions " style={{ marginBottom: '12px' }}>
          <button
            className="button_css"
            style={
              selectedTourType === 'in-person'
                ? {
                    backgroundColor: '#1673ca29',
                    color: '#0051a6',
                  }
                : {}
            }
            onClick={() => {
              setSelectedTourType('in-person');
              localStorage.setItem('tour_type', 'in-person');
            }}
          >
            In Person
          </button>
          <button
            // className={`action-btn font ${
            //   selectedTourType === 'virtual' ? 'active' : ''
            // }`}
            className="button_css"
            style={
              selectedTourType === 'virtual'
                ? {
                    backgroundColor: '#1673ca29',
                    color: '#0051a6',
                  }
                : {}
            }
            onClick={() => {
              setSelectedTourType('virtual');
              localStorage.setItem('tour_type', 'virtual');
            }}
          >
            Virtual
          </button>
        </div>
        <div className="calender">
          <Calendar
            minDate={new Date(new Date().getTime() + 60 * 60 * 24 * 1000)}
            onChange={(value) => {
              if (!(value < new Date())) {
                setSelectedScheduleDate(value);
                setSelectedDate(value);
                setStep(2);
              }
              setTimeout(scrollToBottom, 100);
            }}
            value={selectedScheduleDate}
            tileClassName={(dateObj) => {
              // console.log('calendardate: ', dateObj?.date.getMonth(), dateObj)
              if (
                dateObj?.activeStartDate?.getMonth() ===
                dateObj?.date?.getMonth()
              ) {
                console.log('calendardate: ', true);
                return '--different-month';
              } else {
                return undefined;
              }
            }}
          />
        </div>
        {step === 2 && (
          <div className="step-6 flex flex-col">
            <div className="time-table">
              <div
                className="tit font s14 black"
                style={{ marginBottom: '10px' }}
              >
                Select a tour time{' '}
                {selectedDate ? selectedDate.toDateString() : ''}
              </div>
              <div className="time-list">
                {[
                  '10:00 AM',
                  '11:00 AM',
                  '12:00 PM',
                  '01:00 PM',
                  '02:00 PM',
                  '03:00 PM',
                  '04:00 PM',
                  '05:00 PM',
                  '06:00 PM',
                  '07:00 PM',
                ]?.map((item, idx) => (
                  <div
                    onClick={() => {
                      setSelectedHour(item);
                      // setStep(3);
                      setTimeout(scrollToBottom, 100);
                    }}
                    key={idx}
                    style={{ border: '1px solid #3898ec' }}
                    className={selectedHours === item ? 'time_acitve' : 'time'}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {selectedHours !== '' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {' '}
            <div
              className="active_submit"
              style={{
                marginTop: '15px',
              }}
              onClick={() => {
                console.log('clickeddd');
                sendRequest();
              }}
            >
              {isAppointmentLoading
                ? 'Loading...'
                : 'Confirm to Schedule a Tour'}
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {step === '3' && (
              <div className="unactive_submit">Confirm to Schedule a Tour</div>
            )}
          </div>
        )}
      </>
    );
  };
  const renderAction = () => {
    switch (action.type) {
      case 'multiple-choice':
        return renderButtons();
      case 'text-input':
        return renderTextInput();
      case 'scheduler':
        return bookingScheduler();
      case 'virtual-tour':
        return virtualTour();
      case 'virtual-tour2':
        return virtualTour2();
      case 'multiple-select':
        return renderMultipleSelect();
      default:
        return null;
    }
  };

  const containerScrollRef = useRef(null);
  const scrollToBottom = () => {
    containerScrollRef.current.scrollTo({
      top: containerScrollRef.current.scrollHeight,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    scrollToBottom();
  }, [action, textIndex]);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          overflowY: 'scroll',
          overflowX: 'scroll',
          height: '100vh',
        }}
        ref={containerScrollRef}
      >
        <h1> </h1>
        {renderText()}
        {(currentRoute == 'question' ||
          routeHistory[routeHistory.length - 1]?.route == 'question') &&
          floorPlanFilter?.showAvailabilityPricingInfo && (
            <div
              style={{
                display: 'flex',
                overflowX: 'scroll',
                minHeight: '200px',
              }}
            >
              {filter_floorplans(floorPlanFilter).map((floor_plan, f_idx) => {
                return (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <img
                        src={floor_plan?.image}
                        style={{
                          height: '100px',
                        }}
                      />
                      <div style={{ display: 'flex' }}>
                        {/* <b style={{ marginRight: "10px" }}>4x4</b> */}
                        <span>
                          {floor_plan?.name} -{' '}
                          {floor_plan?.price_per_month?.Min}-
                          {floor_plan?.price_per_month?.Max}/mo
                          <br />
                          {floor_plan?.details?.beds || 'Studio'} x{' '}
                          {floor_plan?.details?.bathroom}
                        </span>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          )}
        {/* <iframe style={{border: "none", borderRadius: "15px" }} src='https://embed.tour.video?uuid=31950292-bede-435c-85b3-f1c0e28359cb&inline=true&screen=intro.main&inlineCTA=hey' width='700px' height='700px' ></iframe> */}
        {/* <div
          className="button-container"
          style={{
            // position: "fixed",
            // bottom: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%"
          }}
        > */}
        <div style={{ padding: '1rem' }}>{renderAction()}</div>
        {/* </div> */}

        {/* <div style={{ marginLeft: "15px" }}>
          <div class="dot-typing"></div>
        </div> */}
      </div>

      {/* <div
        className="button-container"
        style={{
          position: "fixed",
          bottom: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          paddingBottom: "30px"
        }}
      >
        {renderAction()}
      </div> */}
    </div>
  );
};
