import { TextField } from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import React from 'react';

export default function RouteInput({
  value = null,
  onChange,
  onFocus,
  routes,
  placeholder = 'Path',
  minWidth = '200px',
  disableHrefGeneration = false,
  enableHrefUnderlay = false,
}) {
  const filter = createFilterOptions();
  console.log(value);
  const defaultValue = () => {
    var urlRegexExp = /(^(http|https|www):\/\/)/gi;
    var urlReg = new RegExp(urlRegexExp);
    if (value) {
      if (String(value)?.match(urlReg)) {
        return { id: value };
      }
      return routes.filter((item) => item.route === value)[0];
    }
    return {};
  };

  if (routes) {
    return (
      <>
        {routes?.[0]?.route && (
          <Autocomplete
            filterSelectedOptions
            id="video-routes"
            value={defaultValue()}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (disableHrefGeneration) {
                return filtered;
              }
              if (
                params.inputValue &&
                (params.inputValue.startsWith('https://') ||
                  params.inputValue.startsWith('http://') ||
                  params.inputValue.startsWith('www.'))
              ) {
                filtered.push({
                  inputValue: params.inputValue,
                  id: `Open "${params.inputValue}" in new tab`,
                });
                if (enableHrefUnderlay)
                  filtered.push({
                    inputValue: { href_underlay: params.inputValue },
                    id: `Embed "${params.inputValue}"`,
                  });
              }
              return filtered;
            }}
            noOptionsText="No floor plans available. Add URL instead"
            options={routes}
            onChange={(event, newValue) => {
              if (newValue && newValue.id.startsWith('Add')) {
                onChange(newValue.inputValue);
              } else {
                onChange(newValue);
              }
            }}
            onFocus={() => onFocus && onFocus()}
            getOptionLabel={({ id }) => id || ''}
            style={{ width: '100%', minWidth: minWidth }}
            renderInput={(params) => (
              <TextField {...params} label={placeholder} variant="outlined" />
            )}
          />
        )}
      </>
    );
  } else return null;
}
