import { CardElement } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import firebase from '../../utils/base';
import { post, useRequest } from '../../utils/request';

const BillingSignup = (props) => {
  // firebase get all apartments & print out pricing post trial
  // const [communities, setCommunities] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState('');

  const history = useHistory();
  const { communities, setSignupStep } = props;

  const [loading, error, data, makeRequest] = useRequest({ loading: true });
  const [disableconfirmButton, setdisableconfirmButton] = useState(false);

  const onContinue = async () => {
    // withUser represents the communities that the user is attempting to add
    setdisableconfirmButton(true);
    const withUser = communities.map((x) => ({
      ...x,
      user_id: firebase.uid(),
    }));
    console.log(withUser);
    const res = await post('/communities', { communities: withUser });
    console.log('response ', res);
    let user_email = '';
    let user_name = '';
    const doc = await firebase.db.collection('users').doc(firebase.uid()).get();
    user_name = doc.data().name;
    user_email = doc.data().email;

    for (var i = 0; i < res?.community_ids?.length; i++) {
      const res_ = await makeRequest(
        `/magnets?queryByCommunity=${res.community_ids[i].toString()}`,
        'GET',
        null,
        { auth: false }
      );
      let magSettings = res_.magnets[0].magnet_details.template;

      magSettings['agents'] = [
        { name: user_name, email: user_email, access_level: 'company' },
      ];

      await post('/magnets/template/settings', {
        magnet_uuid: res_.magnets[0].uuid,
        magnetSettings: magSettings,
        magnet_type: res_.magnets[0].magnet_type,
      });
    }

    history?.push(
      '/dashboard?recently_added=' + res?.community_ids?.map((item) => item)
    );
    history?.go();
  };

  // const loadCommunities = async () => {
  //   setLoading(true);
  //   const res = await get('/communities');
  //   console.log('your communities:');
  //   console.log(res);
  //   if (res.error) {
  //     setError(res.error);
  //   } else {
  //     setCommunities(res.communities);
  //     setError('');
  //   }
  //   setLoading(false);
  // };

  useEffect(() => {
    console.log('firebase uid:', firebase.uid());
  }, [firebase.uid()]);

  // let content;

  // if (loading) {
  //   content = <Loader />;
  // } else if (error) {
  //   content = <h2>Error: {error}</h2>;
  // } else {
  //   content = (
  //     <>
  //       <div className="communityListWrappeqr">
  //         {communities.map((communityInfo) => (
  //           <>
  //             <a href={communityInfo.url}>{communityInfo.name}</a>
  //             &nbsp;&nbsp;&nbsp;
  //             {communityInfo.website_type === 'community' ? '$1' : '$2'}/mo
  //             <br />
  //           </>
  //         ))}
  //       </div>
  //     </>
  //   );
  // }

  return (
    <div className="signup-billing flex flex-col">
      <div className="billing-form">
        <div className="meta">
          <div className="slog font s20 c333">
            <Link to="/signup/apartments"> </Link> Get your billing for post
            trial
          </div>
          <div className="tit font s28 b7 c333">
            {communities.length} Community Website{' '}
            {communities.length !== 1 && 's'}
          </div>
          <div className="msg font s15 b6 c333">
            considered to be added to LeaseMagnets
          </div>
        </div>

        <div className="communityListWrappeqr">
          {communities.map((communityInfo) => (
            <>
              <a href={communityInfo.url} className="font">
                {communityInfo.name}
              </a>
              &nbsp;&nbsp;&nbsp;
              {communityInfo.website_type === 'community' ? '$1' : '$2'}/mo
              <br />
            </>
          ))}
        </div>

        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
        <button
          onClick={onContinue}
          disabled={disableconfirmButton}
          className="cleanbtn confirm-btn font s15 b5 cfff anim"
        >
          {disableconfirmButton ? 'Creating...' : 'Confirm and Continue'}
        </button>
        <div className="txt font s15 c333">
          Special Enterprise Requests (10+ Apartments)?&nbsp;
          <Link to="/book-demo" className="b6 color">
            Contact Support
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BillingSignup;
