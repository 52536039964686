import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppSidebar, Header } from '../../components';
import CommunityListItem from '../../components/CommunityListItem';
import {
  listCommunities,
  listCommunityDetial,
} from '../../store/actions/communitiesActions';

const ManageCommunities = () => {
  const { data, communityLoading, callError } = useSelector(
    (state) => state.listCommunities
  );
  const { communities, error, status } = data;

  const _dispatch = useDispatch();
  const [searchCommunity, setSearchCommunity] = useState('');

  useEffect(() => {
    _dispatch(listCommunities());
    _dispatch(listCommunityDetial(''));
    _dispatch({
      type: 'updateState',
      payload: null,
    });
  }, []);

  const urlParams = new URLSearchParams(window.location.search);

  let recently_added = urlParams.get('recently_added');
  let filter2 = [];
  if (recently_added && communities) {
    const recentAddedArray = recently_added.split(',');

    recentAddedArray.map((item) => {
      console.log(
        'filtering',
        communities.filter((i) => parseInt(item) == i.id)
      );
      let filter1 = communities.filter((i) => parseInt(item) == i.id);
      filter2 = [...filter2, filter1[0]];
    });

    console.log('filter2', filter2);
  }
  // console.log('recently_added', recently_added.split(','), communities);

  return (
    <>
      {/* <Navbar /> */}
      <AppSidebar />
      <div className="manage-community sidebar-space">
        <Header>
          <div className="community-hdr flex aic">
            <div className="left flex aic">
              {communities?.length ? (
                <div className="title font s18 b7 black">{`${
                  communities?.length !== 1 ? 'Communities' : 'Community'
                } (${communities?.length})`}</div>
              ) : (
                <div className="title holder" />
              )}
              <Link
                to="/signup/apartments"
                className="cleanbtn add-category font s14 b5 cfff flex aic"
              >
                <span className="ico icon-plus s16 cfff" />
                <span>Add Community</span>
              </Link>
            </div>
            <div className="right flex aic">
              <div className="srch-bar">
                <input
                  type="text"
                  className="cleanbtn iput font s15 c333"
                  placeholder="Search communities..."
                  value={searchCommunity}
                  onChange={(e) => setSearchCommunity(e.target.value)}
                />
              </div>
              <Link
                to="/signup/apartments"
                className="cleanbtn add-category font s14 b5 cfff flex aic"
              >
                <span className="ico icon-plus s16 cfff" />
                <span>Add New Community</span>
              </Link>
            </div>
          </div>
        </Header>
        <div className="communities-wrapper wrapWidth flex flex-col">
          {filter2.length !== 0 && recently_added && (
            <div className=" w-full pb-10">
              <h1 className=" text-2xl font-medium pb-2">Recently Added</h1>
              <div className="community-list flex">
                {filter2
                  ?.filter(
                    (communityInfo) =>
                      communityInfo?.name
                        .toLowerCase()
                        .includes(searchCommunity.toLowerCase()) ||
                      communityInfo?.url
                        .toLowerCase()
                        .includes(searchCommunity.toLowerCase())
                  )
                  .map((communityInfo, index) => (
                    <CommunityListItem
                      communityInfo={communityInfo}
                      key={communityInfo.name + index}
                      id={index}
                      showManage
                      showAnalytics
                    />
                  ))}
              </div>
            </div>
          )}

          {communityLoading ? (
            <>
              <div className="comunity-wrapper community-list">
                {[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].map(
                  (ev, i) => (
                    <div key={i} className="holder" />
                  )
                )}
              </div>
            </>
          ) : error ? (
            <div className="empty-comunity flex flex-col aic">
              <div className="msg font s18 b5 c000">
                <span className="b6 red">Error:&nbsp;</span>
                {error}
              </div>
            </div>
          ) : !communityLoading && communities?.length === 0 ? (
            <>
              <div className="empty-comunity flex flex-col aic">
                <div className="msg font s16 b6 c000">
                  Oops! You have no community at yet.
                </div>
                <Link
                  className="add-new-comunity font s15 cfff anim"
                  to="/signup/apartments"
                >
                  + Add New Apartment
                </Link>
              </div>
            </>
          ) : (
            <>
              {/* <Page.Content> 
              <Grid.Row cards>
                <Grid.Col sm={6} lg={4}>
                  <StampCard
                    color="black"
                    icon="eye" 
                    header={
                      <>
                        1,352 <small>Impressions</small>
                      </>
                    }
                    footer="112 registered today"
                  />
                </Grid.Col>
                <Grid.Col sm={6} lg={4}>
                  <StampCard
                    color="black"
                    icon="video"
                    header={
                      <>
                        132 <small>Virtual Tours</small>
                      </>
                    }
                    footer="12 waiting payments"
                  />
                </Grid.Col>
    
                <Grid.Col sm={6} lg={4}>
                  <StampCard
                    color="black"
                    icon="users"
                    header={
                      <>
                        78 <small>Leads</small>
                      </>
                    }
                    footer="32 shipped"
                  />
                </Grid.Col>
              </Grid.Row>
            </Page.Content> */}
              <h1 className=" text-2xl font-medium pb-2">All Communities</h1>
              <div className="community-list flex">
                {communities
                  ?.filter(
                    (communityInfo) =>
                      communityInfo.name
                        .toLowerCase()
                        .includes(searchCommunity.toLowerCase()) ||
                      communityInfo.url
                        .toLowerCase()
                        .includes(searchCommunity.toLowerCase())
                  )
                  .map((communityInfo, index) => (
                    <CommunityListItem
                      communityInfo={communityInfo}
                      key={communityInfo.name + index}
                      id={index}
                      showManage
                      showAnalytics
                    />
                  ))}
              </div>

              {/* <div className="communityListWrappeqr">
              {communities
                .filter(  
                  (communityInfo) =>
                    communityInfo.name
                      .toLowerCase()
                      .includes(searchCommunity.toLowerCase()) ||
                    communityInfo.url
                      .toLowerCase()
                      .includes(searchCommunity.toLowerCase())
                )
                .map((communityInfo, index) => (
                  <CommunityListItem
                    communityInfo={communityInfo}
                    key={communityInfo.name + index}
                    id={index}
                    showManage
                    showAnalytics
                  />
                ))}
            </div> */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ManageCommunities;
