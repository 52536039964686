import { IoMdClose } from 'react-icons/io';
import { v4 as uuidv4 } from 'uuid';
import { AiOutlineDelete } from 'react-icons/ai';
import AccessLevelWrapper from '../../components/Reusable/AccessLevelWrapper';
import RouteInput from '../../components/Reusable/RouteInput';
import { FiSettings, FiUserPlus } from 'react-icons/fi';
import { useState } from 'react';
import { getRandomId } from '../../utils/files';
import CloseIcon from '@material-ui/icons/Close';
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
  IconButton,
} from '@material-ui/core';
import SelectSenderPhotoModal from './SelectSenderPhotoModal';
import moment from 'moment';
import { Link } from 'react-router-dom';

const AddBubbleSection = ({
  community_id,
  routes,
  setconditionModal,
  setBehaviorModal,
  setIdx,
  setButtonIndex,
  onSelectImage,
  addSubtitles,
  deleteSelectedImage,
  progress,
  defaultConfig,
  setDefaultConfig,
  handleRowSelection,
  selectedRow,
  setSelectedRow,
  communityName,
}) => {
  const [activeSubtitle, setActiveSubtitle] = useState({});
  const [showjs, setShowjs] = useState({});
  const [showUploadModal, setShowUploadModal] = useState(false);

  function getChatbot() {
    return defaultConfig?.chatbot || [];
  }

  function setChatbot(chatbotPayload) {
    setDefaultConfig((prev) => ({ ...prev, chatbot: chatbotPayload }));
  }

  /* --- HELPER FUNCTIONS __ START --- */

  function deleteChatBubble(idxToDelete) {
    let currentchatbot = [...getChatbot()];
    currentchatbot.splice(idxToDelete, 1);
    setChatbot([...currentchatbot]);
    handleRowSelection(null);
  }

  const cloneChatBubble = (idxToClone) => {
    let localChatbotBubbles = [...getChatbot()];
    let chatbotBubbleToAdd = {
      ...getChatbot()[idxToClone],
      buttons: [...getChatbot()[idxToClone]?.buttons],
      condition: [],
      id: uuidv4(),
    };
    let Index = parseInt(idxToClone + 1);
    localChatbotBubbles.splice(Index, 0, chatbotBubbleToAdd);
    setChatbot([...localChatbotBubbles]);
    handleRowSelection(getChatbot()?.length);
  };

  const editBubbleRowValues = (bubbleIndex, buttonIndex, e, isPathInput) => {
    console.log({ bubbleIndex, buttonIndex, e, isPathInput });
    var urlRegexExp = /(^(http|https|www):\/\/)/gi;
    var urlReg = new RegExp(urlRegexExp);

    let currentBubble = getChatbot();

    if (isPathInput) {
      if (typeof e === 'string' && e?.match(urlReg)) {
        currentBubble[bubbleIndex].buttons[buttonIndex] = {
          ...currentBubble[bubbleIndex].buttons[buttonIndex],
          label: currentBubble[bubbleIndex].buttons[buttonIndex].label,
          href: e,
        };
        delete currentBubble[bubbleIndex].buttons[buttonIndex].route;
      } else {
        currentBubble[bubbleIndex].buttons[buttonIndex] = {
          ...currentBubble[bubbleIndex].buttons[buttonIndex],
          label: currentBubble[bubbleIndex].buttons[buttonIndex].label,
          route: e?.route,
        };
        delete currentBubble[bubbleIndex].buttons[buttonIndex].href;
      }
      //return;
    }
    if (e?.target?.name === 'button-name') {
      currentBubble[bubbleIndex].buttons[buttonIndex] = {
        ...currentBubble[bubbleIndex].buttons[buttonIndex],
        label: e.target.value,
        route: currentBubble[bubbleIndex].buttons[buttonIndex].route,
      };
    }
    // else {
    //   return null;
    // }
    setChatbot([...currentBubble]);
  };

  const getBubble = (bubbleIndex) => {
    return getChatbot()[bubbleIndex];
  };

  const setBubbleProperty = (bubbleIndex, propertyName, newPropertyValue) => {
    const currentBubble = getChatbot();
    currentBubble[bubbleIndex][propertyName] = newPropertyValue;
    setChatbot([...currentBubble]);
  };

  const handleBubbleTypeChange = (index, e) => {
    const newType = e.target.value;
    const oldType = getBubble(index).type;
    if (newType === oldType) {
      return;
    }
    setBubbleProperty(index, 'type', newType);
    const newChatbot = [...getChatbot()];
    if (oldType === 'Promotion') {
      // remove any triggers and buttons automatically created by promotion
      newChatbot[index].condition = newChatbot[index].condition.filter(
        (trigger) => !(trigger.createdBy === 'Promotion')
      );
      newChatbot[index].buttons = newChatbot[index].buttons.filter(
        (button) => !(button.createdBy === 'Promotion')
      );
    }
    if (newType === 'Promotion') {
      // if you edit this following logic you may want to check out StepThree in UpdateSpecialOfferSection.jsx
      newChatbot[index].text = defaultConfig.specialOffer;
      newChatbot[
        index
      ].subTitle = `Promotion expires on ${defaultConfig.specialOfferExpiryDate}`;
      newChatbot[index].condition.push({
        operator: '<=',
        type: 'date',
        value: moment(defaultConfig.specialOfferExpiryDate).format(
          'YYYY-MM-DDThh:mm'
        ),
        route: '',
        createdBy: 'Promotion',
      });
      newChatbot[index].buttons.unshift({
        label: 'Claim Offer',
        route: '_promotions_.latest',
        createdBy: 'Promotion',
      });
    } else if (newType === 'Urgency') {
      newChatbot[index].text =
        '[replace with specific floor plan] almost sold out';
      newChatbot[index].subTitle = "lock in your space before it's too late";
      newChatbot[index].buttons = [];
    } else if (newType === 'SocialProof') {
      newChatbot[index].trackedStatistic = 'Tours';
      newChatbot[index].trackedDuration = 7;
    }
    setChatbot(newChatbot);
  };

  // Add Bubble Row
  const addBubbleRow = (index) => {
    let currentBubble = [...getChatbot()];
    currentBubble?.[index]?.buttons.push({
      label: '',
      route: '',
      uuid: getRandomId(),
    });
    setChatbot([...currentBubble]);
  };

  // Delete Bubble Row
  const deleteBubbleRow = (bubbleIndex, buttonIndex) => {
    let currentBubble = [...getChatbot()];
    currentBubble[bubbleIndex].buttons.splice(buttonIndex, 1);
    setChatbot([...currentBubble]);
  };

  function addjs(index, idx, e) {
    let copytemporaryChatbot = [...getChatbot()];
    copytemporaryChatbot[index].buttons[idx].js = e.target.value;
    setChatbot([...copytemporaryChatbot]);
  }

  /* --- HELPER FUNCTIONS __ END --- */

  return (
    !(selectedRow == null) && (
      <>
        <div className="bubbles-list mt-4">
          <div className={`p-3`}>
            <div className="title font s15 b6 italic text-sm pb-2">{`Edit CTA Bubble: ${
              selectedRow + 1
            }`}</div>
            <div className="bubble flex flex-col w-full border p-3 rounded-md ">
              <div className="rit flex justify-end items-center gap-2 text-sm text-slate-400 ">
                <button
                  onClick={() => deleteChatBubble(selectedRow)}
                  className="flex items-cente gap-1  border rounded-md px-2 py-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                >
                  <span className="ico icon-delete1 b6 s16 text-slate-400	fill-slate-400"></span>
                  <span className=" text-slate-400">Delete</span>
                </button>
                <button
                  onClick={() => cloneChatBubble(selectedRow)}
                  className="flex items-center gap-1  border rounded-md px-2 py-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                >
                  <span className="ico icon-paste b6 s16 text-xs text-slate-400	"></span>
                  <span className=" text-slate-400">Clone</span>
                </button>
                <button
                  className="flex items-center gap-1  border rounded-md px-2 py-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                  onClick={() => {
                    setconditionModal(true);
                  }}
                >
                  <span className="ico icon-magic-wand b6 s16"></span>
                  <span>Triggers</span>
                </button>
                <button
                  className="flex items-center gap-1  border rounded-md px-2 py-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                  onClick={() => {
                    setBehaviorModal(true);
                  }}
                >
                  <FiSettings />
                  <span>Behavior</span>
                </button>
              </div>
              <div className="bubble-content flex flex-col gap-4 mt-4">
                <div className="flex gap-4 items-center">
                  {getBubble(selectedRow)?.senderImage && (
                    <IconButton
                      onClick={() =>
                        setBubbleProperty(selectedRow, 'senderImage', null)
                      }
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <button
                    onClick={() => {
                      setShowUploadModal(true);
                    }}
                    htmlFor="picture"
                    className={
                      'p-2 cursor-pointer rounded-full bg-gray-200 text-gray-400 flex-shrink-0 flex-grow-0'
                    }
                    style={{
                      width: '35px',
                      height: '35px',
                      ...(getBubble(selectedRow)?.senderImage
                        ? {
                            backgroundImage: `url(${
                              getBubble(selectedRow)?.senderImage
                            })`,
                            backgroundSize: 'cover',
                          }
                        : {}),
                    }}
                  >
                    {!getBubble(selectedRow)?.senderImage && (
                      <FiUserPlus className="w-6 h-6" />
                    )}
                  </button>
                  <TextField
                    variant="outlined"
                    className="flex-1"
                    label="Sender Name"
                    id={`chatbot-sender-bubble-${selectedRow}`}
                    value={getBubble(selectedRow).sender}
                    onChange={(e) => {
                      setBubbleProperty(selectedRow, 'sender', e.target.value);
                    }}
                  />
                  <FormControl variant="outlined" className="flex-1">
                    <InputLabel id="bubble-type-select-label">
                      Bubble Type
                    </InputLabel>
                    <Select
                      labelId="bubble-type-select-label"
                      id="bubble-type-select"
                      value={getBubble(selectedRow).type}
                      label="Bubble Type"
                      onChange={(e) => {
                        handleBubbleTypeChange(selectedRow, e);
                      }}
                    >
                      <MenuItem value="Basic">Basic</MenuItem>
                      <MenuItem value="SocialProof">Social Proof</MenuItem>
                      <MenuItem value="Urgency">Urgency</MenuItem>
                      <MenuItem value="Promotion">Promotion</MenuItem>
                      <MenuItem value="Review">Review</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {getBubble(selectedRow).type === 'Basic' ? (
                  <TextField
                    variant="outlined"
                    className="w-full"
                    label="Bubble Message (Optional)"
                    id={`chatbot-text-bubble-${selectedRow}`}
                    value={getBubble(selectedRow).text}
                    onChange={(e) => {
                      setBubbleProperty(selectedRow, 'text', e.target.value);
                    }}
                  />
                ) : getBubble(selectedRow).type === 'SocialProof' ? (
                  <div className="flex justify-center gap-4 w-full">
                    <div className="flex-1">
                      <FormControl variant="outlined" className="w-full">
                        <InputLabel id="bubble-stat-select-label">
                          Statistic to Track
                        </InputLabel>
                        <Select
                          value={getBubble(selectedRow)?.trackedStatistic}
                          labelId="bubble-stat-select-label"
                          label="Statistic to Track"
                          onChange={(e) => {
                            setBubbleProperty(
                              selectedRow,
                              'trackedStatistic',
                              e.target.value
                            );
                          }}
                          className="w-full"
                        >
                          <MenuItem value="Tours">Tours</MenuItem>
                          <MenuItem value="UniqueVisitors">
                            Unique Visitors
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="flex-1">
                      <FormControl variant="outlined" className="w-full">
                        <InputLabel id="bubble-duration-select-label">
                          Tracking Duration
                        </InputLabel>
                        <Select
                          value={getBubble(selectedRow)?.trackedDuration}
                          label="Tracking Duration"
                          labelId="bubble-duration-select-label"
                          onChange={(e) =>
                            setBubbleProperty(
                              selectedRow,
                              'trackedDuration',
                              e.target.value
                            )
                          }
                        >
                          <MenuItem value={1}>past day</MenuItem>
                          <MenuItem value={3}>past 3 days</MenuItem>
                          <MenuItem value={7}>past 7 days</MenuItem>
                          <MenuItem value={30}>past 30 days</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                ) : getBubble(selectedRow).type === 'Urgency' ||
                  getBubble(selectedRow).type === 'Promotion' ? (
                  <>
                    <TextField
                      variant="outlined"
                      label="Headline"
                      value={getBubble(selectedRow).text}
                      onChange={(e) =>
                        setBubbleProperty(selectedRow, 'text', e.target.value)
                      }
                    />
                    <TextField
                      variant="outlined"
                      label="Subtitle"
                      value={getBubble(selectedRow).subTitle}
                      onChange={(e) =>
                        setBubbleProperty(
                          selectedRow,
                          'subTitle',
                          e.target.value
                        )
                      }
                    />
                  </>
                ) : (
                  <div>
                    Edit which reviews to feature in the{' '}
                    <Link to="../reviews" className="text-pr hover:font-bold">
                      reviews page
                    </Link>
                    .
                  </div>
                )}
                {/* Buttons */}
                <p className="text-md text-gray-500 -mb-6">Buttons</p>
                <div className="variables-wrapper">
                  {getBubble(selectedRow).buttons.map((item, idx) => (
                    <div
                      key={idx}
                      id={`item-bubble-${selectedRow}-${idx}`}
                      className="flex items-start gap-2 my-3"
                    >
                      <div className="flex-col pt-1 pr-2 rounded-lg flex items-center justify-center">
                        <div className=" flex items-center gap-1">
                          {item?.picArray && (
                            <div className=" flex items-center">
                              {item?.picArray.map((item, ind) => (
                                <div
                                  key={ind}
                                  className={
                                    ind !== 0
                                      ? ' w-10 h-10 cursor-pointer img_hover_container rounded-full overflow-hidden relative -ml-3'
                                      : ' w-10 h-10 cursor-pointer img_hover_container rounded-full overflow-hidden relative'
                                  }
                                >
                                  <img
                                    className=" w-full h-full"
                                    style={{
                                      objectFit: 'cover',
                                    }}
                                    src={item.img}
                                    alt=""
                                  />
                                  <div
                                    onClick={() =>
                                      deleteSelectedImage(
                                        item,
                                        selectedRow,
                                        idx,
                                        ind
                                      )
                                    }
                                    className=" img_hover cursor-pointer absolute top-0 left-0 w-full h-full flex items-center justify-center"
                                  >
                                    <IoMdClose />
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                          {/* {console.log(index)} */}
                          <input
                            type="file"
                            onChange={(e) => {
                              onSelectImage(e);
                            }}
                            accept="image/*"
                            className=" hidden"
                            name="picture"
                            id="picture"
                          />
                          <label
                            onClick={() => {
                              setIdx(idx);
                              setButtonIndex(selectedRow);
                            }}
                            htmlFor="picture"
                            className={
                              progress === false
                                ? ' p-2 cursor-pointer rounded-full bg-gray-200 text-gray-400 '
                                : ' p-2 cursor-pointer rounded-full bg-gray-200 text-gray-400 animate-spin opacity-30'
                            }
                          >
                            <FiUserPlus className=" w-5 h-5" />
                          </label>
                        </div>
                      </div>

                      <div className="field flex flex-col w-full gap-2">
                        <input
                          type="text"
                          name="button-name"
                          placeholder="Button title"
                          className="border rounded-md px-2 py-2 w-full"
                          onFocus={() =>
                            setActiveSubtitle({ selectedRow, idx })
                          }
                          id={`chatbot-text-bubble-title-${selectedRow}-${idx}`}
                          value={getBubble(selectedRow).buttons[idx].label}
                          onChange={(e) => {
                            editBubbleRowValues(selectedRow, idx, e);
                          }}
                        />
                        {activeSubtitle?.idx === idx && (
                          <input
                            type="text"
                            name="subtitle"
                            onFocus={() =>
                              setActiveSubtitle({ selectedRow, idx })
                            }
                            placeholder="Add subtitle"
                            className=" w-full py-2 px-2 border rounded-md  bg-gray-50 text-sm"
                            id={`chatbot-text-bubble-subtitle-${selectedRow}-${idx}`}
                            value={getBubble(selectedRow).buttons[idx].subTitle}
                            onChange={(e) => {
                              addSubtitles(selectedRow, e, idx);
                            }}
                          />
                        )}
                      </div>

                      <div className="field flex flex-col w-full">
                        <RouteInput
                          routes={routes}
                          onFocus={() => {
                            setShowjs({ selectedRow, idx });
                          }}
                          id={`chatbot-text-bubble-path-${selectedRow}-${idx}`}
                          value={
                            getBubble(selectedRow).buttons[idx]?.route ||
                            getBubble(selectedRow).buttons[idx]?.href
                          }
                          onChange={(value) => {
                            editBubbleRowValues(selectedRow, idx, value, true);
                          }}
                          minWidth="50px"
                        />
                        <AccessLevelWrapper
                          access_level_clearance="global"
                          community_id={community_id}
                        >
                          {
                            <textarea
                              onChange={(e) => addjs(selectedRow, idx, e)}
                              className=" border p-1 rounded-md"
                              placeholder="Enter Js"
                              name=""
                              id=""
                              defaultValue={item?.js}
                              value={item?.js}
                              cols="10"
                              rows="5"
                            ></textarea>
                          }
                        </AccessLevelWrapper>
                      </div>

                      <button
                        onClick={() => deleteBubbleRow(selectedRow, idx)}
                        className="flex items-center gap-1  border rounded-md px-2 py-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                        style={{ height: '42px' }}
                      >
                        <AiOutlineDelete className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>
                <div className="mt-3 flex justify-between">
                  <button
                    onClick={() => addBubbleRow(selectedRow)}
                    className="mr-4 flex items-center gap-1 bg-gray-600 px-2 text-sm py-2 rounded-md text-white"
                  >
                    +<span>Add new button</span>
                  </button>
                  <button
                    onClick={() => setSelectedRow(null)}
                    className="flex items-center gap-1 bg-pr px-2 text-sm py-2 rounded-md text-white"
                  >
                    <span>Finish Editing</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SelectSenderPhotoModal
          open={showUploadModal}
          setOpen={setShowUploadModal}
          onClose={() => setShowUploadModal(false)}
          communityId={community_id}
          photoUrl={getBubble(selectedRow).senderImage}
          setPhotoUrl={(url) => {
            setBubbleProperty(selectedRow, 'senderImage', url);
          }}
          primaryColor={
            defaultConfig?.secondaryColor || defaultConfig?.primaryColor
          }
          senderName={getBubble(selectedRow).sender}
        />
      </>
    )
  );
};

export default AddBubbleSection;
