import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useState, useEffect } from 'react';
import { supabase } from '../../services/supabase';
import { getRecordings } from '../../utils/hms';
import styles from './meetings.css';
import Skeleton from '@material-ui/lab/Skeleton';
import Pagination from '@material-ui/lab/Pagination';
import { PastRecordingCard } from './PastRecordingCard';

const PastRecordings = ({ communityId }) => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);

  const asyncEffect = async () => {
    setLoading(true);
    const res = await getRecordings(communityId);
    console.log('getRecordings()', res);
    setSessions(res);
    setLoading(false);
  };

  useEffect(() => {
    asyncEffect();
  }, []);

  const getRooms = async () => {
    // const response = await fetch(
    //   'https://api.100ms.live/v2/rooms', {
    //     headers: {Authorization: `Bearer ${HMS_MANAGEMENT_TOKEN}`}
    //   });
    // const responseJSON = await response.json()
    // const rooms = responseJSON.data.filter(elem => elem.description ===)
    const { data: roomData, error: roomError } = await supabase
      .from('Community')
      .select('rooms')
      .eq('id', communityId);
    if (roomError) {
      console.error('Error fetching roomIds from supabase:', roomError);
    }

    // const foo = await fetch(
    //   `https://storage.googleapis.com/storage/v1/b/tour-rooms/?key=${GOOGLE_CLOUD_API_KEY}`
    // );

    // console.log(foo);

    // const storage = new Storage();
    // const storage = new Storage({ keyFilename: 'key.json' });

    // roomIds.forEach(async (roomId) => {
    //   const response = await fetch(
    //     'https://api.100ms.live/v2/recording-assets',
    //     {
    //       room_id: roomId,
    //       headers: {
    //         Authorization: `Bearer ${HMS_MANAGEMENT_TOKEN}`,
    //       },
    //     }
    //   );
    //   const responseJSON = await response.json();
    // });
  };

  useEffect(() => {
    getRooms();
  }, []);

  const [page, setPage] = useState(1);
  const ROWS_PER_PAGE = 10;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className="bg-gray-200 p-6">
      {/* <h2 className="text-2xl font-bold ml-3 mb-6">Recordings</h2> */}
      <div className="flex flex-col gap-3">
        {loading ? (
          <>
            {Array(5)
              .fill(null)
              .map((elem, index) => (
                <Skeleton
                  width="100%"
                  height={100}
                  style={{
                    borderRadius: '12px',
                    background: 'rgba(255, 255, 255, 0.5)',
                  }}
                  variant="rect"
                  key={index}
                />
              ))}
          </>
        ) : sessions.length ? (
          sessions
            .map((elem) => <PastRecordingCard session={elem} />)
            .slice((page - 1) * ROWS_PER_PAGE, page * ROWS_PER_PAGE)
        ) : (
          'No recordings.'
        )}
      </div>
      {Math.ceil(sessions.length / ROWS_PER_PAGE) > 1 && (
        <div className="mt-8 flex justify-center">
          <Pagination
            count={Math.ceil(sessions.length / ROWS_PER_PAGE)}
            page={page}
            onChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default PastRecordings;
