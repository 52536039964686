import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listCommunities } from '../store/actions/communitiesActions';

const useGetCommunitiesList = () => {
  const _dispatch = useDispatch();

  useEffect(() => {
    _dispatch(listCommunities());
  }, []);

  const { data, communityLoading, callError } = useSelector(
    (state) => state.listCommunities
  );
  const { communities, error, status } = data;

  const [isCommunitiesListLoading, setIsCommunitiesListLoading] =
    useState(true);
  const [communitiesList, setCommunitiesList] = useState(communities);

  useEffect(() => {
    if (communities) {
      setCommunitiesList(communities);
      setIsCommunitiesListLoading(false);
    }
  }, [communities]);

  return {
    communitiesList,
    isCommunitiesListLoading,
  };
};

export default useGetCommunitiesList;
