import { useEffect, useState } from 'react';
import { supabase } from '../services/supabase';

function useGetSupabaseTable(table_name, column_name, filter) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false);

  function reloadTable() {
    setReload(true);
  }

  async function insertRow(data_to_insert) {
    const { data, error } = await supabase
      .from(table_name)
      .insert(data_to_insert);
    return { data, error };
  }

  async function deleteRow(col_name, col_value) {
    const { data, error } = await supabase
      .from(table_name)
      .delete()
      .eq(col_name, col_value);
    return { data, error };
  }

  useEffect(() => {
    (async () => {
      let supabaseResponse = {};
      if (filter) {
        supabaseResponse = await supabase
          .from(table_name)
          .select(column_name ? column_name : '*')
          .eq(filter?.column_name, filter?.column_value)
          .order('id', { ascending: false }); // If column_name then select that column otherwise select all columns
        console.log('supabaseResponse', supabaseResponse);
      } else {
        supabaseResponse = await supabase
          .from(table_name)
          .select(column_name ? column_name : '*')
          .order('id', { ascending: false });
      }

      setData(supabaseResponse?.data);
      setError(supabaseResponse?.error);
      setIsLoading(false);
      console.log('ABCD Supabase Response: ', supabaseResponse);
    })();
    setReload(false);
  }, [reload]);

  return { data, isLoading, error, reloadTable, insertRow, deleteRow };
}

export default useGetSupabaseTable;
