import { useState, useEffect } from 'react';
import ChatbotButton, { hexToHSL } from './ChatbotButton';
import { CloseIcon, StarIcon, CheckIcon } from './Icons';
import './ChatbotBubble.css';
import { supabase } from '../../services/supabase';
import _ from 'lodash';
import { get_social_proof_data, getanalyicsbigquery } from '../../utils/api';

const DAYS_TO_STRING = {
  1: '24 hours',
  3: 'three days',
  7: 'week',
  28: 'month',
  29: 'month',
  30: 'month',
  31: 'month',
};
const REVIEW_CHARS_TO_SHOW = 100;

/**
 * Renders a CTA bubble from the given item. Nearly identical to the file of the same name in videomagnet. However, this contains different logic for SocialProof since values update more often.
 *
 * @param {Object} item chatbot object to render
 * @param {String} primaryColor hex code of primary color to use for buttons and profile (e.g. #00aeff)
 * @param {function} onClose callback to run when button is closed
 * @param {function} onClickLink
 * @param {boolean} userOnMobile if true, all x buttons are shown at all times rather than on hover
 * @param {function} onComponentMount callback to run on first render
 * @returns
 */
const ChatbotBubble = ({
  item,
  primaryColor = '#4399DF',
  onDelete,
  className,
  magnet_uuid,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [socialProofData, setSocialProofData] = useState();
  const [reviewIndex, setReviewIndex] = useState(0);
  const [reviews, setReviews] = useState();
  const [showFullReview, setShowFullReview] = useState(false);

  useEffect(() => {
    if (
      item.type === 'SocialProof' &&
      (socialProofData?.target !== item.trackedStatistic ||
        socialProofData?.duration !== item.trackedDuration ||
        !socialProofData?.data)
    ) {
      getSocialProofStats();
    }
  }, [item, socialProofData]);
  useEffect(() => {
    if (item.type === 'Review') {
      getReviews();
    }
  }, [item.type]);

  const selectReview = () => {
    let newReviewIndex = Math.floor(Math.random() * reviews?.length);
    setReviewIndex(newReviewIndex);
  };

  const getReviews = async () => {
    const { data, error } = await supabase
      .from('Community')
      .select('reviews')
      .eq('community_magnets', magnet_uuid);
    if (error) {
      console.error('Error fetching review from supabase:', error);
      return;
    }
    setReviews(data[0].reviews?.featured);
    setReviewIndex(Math.floor(Math.random() * data[0].reviews.featured.length));
  };

  const getSocialProofStatsFromCache = async () => {
    const { data, error } = await supabase
      .from('Magnet')
      .select('cache')
      .eq('uuid', magnet_uuid);
    if (error) {
      console.error('Error getting social proof data from supabase:', error);
      return;
    }
    const foundCacheEntry = data[0].cache.find((elem) => elem.uuid === item.id);
    setSocialProofData({
      data: foundCacheEntry?.data,
      target: item.trackedStatistic,
      duration: item.trackedDuration,
    });
    if (!foundCacheEntry) {
      console.log('Cache miss on social proof bubble:', item);
    }
  };

  const getSocialProofStats = async () => {
    console.log('getSocialProofStats run');
    // initial render
    if (!socialProofData) {
      await getSocialProofStatsFromCache();
    } else {
      const newData = await get_social_proof_data(
        item.trackedStatistic,
        item.trackedDuration,
        magnet_uuid
      );

      setSocialProofData({
        data: newData,
        target: item.trackedStatistic,
        duration: item.trackedDuration,
      });
    }
  };

  const isFullBubble =
    item.text ||
    (item?.buttons && item?.buttons?.length > 1) ||
    item.type === 'SocialProof' ||
    item.type === 'Review'; // these bubbles appear to be empty since their content is dynamically fetched
  const isOneButton = !item.text && item.buttons && item?.buttons?.length === 1;
  return (
    <>
      {isFullBubble ? (
        <div
          key={item.id}
          className={`initial-message-bubble ${className}`}
          style={{
            width: '280px',
            boxShadow: 'rgba(211, 211, 211, 0.326) 0px 4px 20px',
          }}
          onMouseOver={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isHovered && (
            <div className="chatbot-button-close" onClick={onDelete}>
              <CloseIcon
                height={12}
                width={12}
                style={{ position: 'static' }}
              />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              className="bubble-profile-pic"
              style={{
                backgroundImage: `url(${
                  item.senderImage ??
                  'https://tkweddqlriikqgylsuxz.supabase.co/storage/v1/object/public/PropertyPhotos/-1/waving%20hand%20centered.png?width=35&height=35&resize=cover'
                })`,
                backgroundSize: 'cover',
                backgroundColor: hexToHSL(primaryColor, 94),
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  backgroundColor: '#5CCD4D',
                  width: '11px',
                  height: '11px',
                  borderRadius: '100%',
                  bottom: '0px',
                  right: '0px',
                  border: '2px solid white',
                }}
              ></div>
            </div>
            <b className="bubble-team-name">{item.sender || 'Leasing Team'}</b>{' '}
            <span className="bubble-message-timestamp"> · Just Now</span> <br />
          </div>
          <div className="p-2 pb-1 flex flex-col gap-2">
            {item.type === 'Basic' ? (
              <p
                style={{
                  paddingBottom: '10px',
                  fontFamily: 'Manrope, sans-serif',
                  fontSize: '15px',
                }}
              >
                {item.text}
              </p>
            ) : item.type === 'Review' ? (
              <>
                <div style={{ marginLeft: '18px', fontSize: '15px' }}>
                  {/* Here's what an {'undergrad'} student studying{' '}
                  {'Computer Science'} has to say about us: */}
                  Here's what a previous resident has to say about us:
                </div>
                <div className="cta-bubble-content">
                  {reviews?.[reviewIndex] ? (
                    <>
                      <div
                        className="flex w-full justify-between items-center"
                        style={{
                          fontSize: '14px',
                        }}
                      >
                        <b>{reviews[reviewIndex].author_title}</b>
                        <div style={{ display: 'flex' }}>
                          <StarIcon />
                          <StarIcon
                            fillColor={
                              reviews[reviewIndex].review_rating >= 2
                                ? '#feb12c'
                                : '#ffffff'
                            }
                          />
                          <StarIcon
                            fillColor={
                              reviews[reviewIndex].review_rating >= 3
                                ? '#feb12c'
                                : '#ffffff'
                            }
                          />
                          <StarIcon
                            fillColor={
                              reviews[reviewIndex].review_rating >= 4
                                ? '#feb12c'
                                : '#ffffff'
                            }
                          />
                          <StarIcon
                            fillColor={
                              reviews[reviewIndex].review_rating === 5
                                ? '#feb12c'
                                : '#ffffff'
                            }
                          />
                        </div>
                      </div>
                      <p
                        style={{
                          fontSize: '14px',
                        }}
                      >
                        {showFullReview ||
                        reviews[reviewIndex].review_text.length <
                          REVIEW_CHARS_TO_SHOW ? (
                          reviews[reviewIndex].review_text
                        ) : (
                          <>
                            {`${reviews[reviewIndex].review_text.substr(
                              0,
                              REVIEW_CHARS_TO_SHOW
                            )}`}
                            <span
                              style={{ color: 'gray', cursor: 'pointer' }}
                              onClick={() => {
                                setShowFullReview(true);
                              }}
                            >
                              ...show more
                            </span>
                          </>
                        )}
                      </p>
                    </>
                  ) : (
                    <>Loading...</>
                  )}
                </div>
              </>
            ) : (
              <div className="cta-bubble-content">
                {item.type === 'SocialProof' ? (
                  <>
                    <b className="cta-bubble-headline">{`${
                      socialProofData?.data
                    } ${
                      item.trackedStatistic === 'Tours'
                        ? 'tours taken'
                        : 'other visitors toured'
                    }`}</b>
                    <div className="cta-bubble-subtitle">
                      over the past {DAYS_TO_STRING[item.trackedDuration]}
                    </div>
                  </>
                ) : (
                  <>
                    <b className="cta-bubble-headline">{item.text}</b>
                    <div className="cta-bubble-subtitle">{item.subTitle}</div>
                  </>
                )}
                <div
                  style={{
                    display: 'flex',
                    gap: '6px',
                    alignItems: 'center',
                    marginTop: '8px',
                  }}
                >
                  <CheckIcon height={24} width={24} />
                  <div style={{ fontSize: '12px' }}>
                    Verified by <b>Tour</b>
                  </div>
                </div>
              </div>
            )}
            {item.buttons.map((button, index) => (
              <>
                <ChatbotButton
                  key={index}
                  onClickFunction={() => onClickLink(button, 'button-array')}
                  primaryColor={primaryColor}
                  maxWidth={'100%'}
                  title={button?.label}
                  subtitle={button?.subTitle}
                />
              </>
            ))}
          </div>
        </div>
      ) : (
        isOneButton &&
        (item?.buttons?.[0]?.label ? (
          <div
            className={`relative ${className}`}
            onMouseOver={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <ChatbotButton
              onClickFunction={() => {
                onClickLink(item?.buttons[0], 'button-array');
              }}
              primaryColor={primaryColor}
              title={item?.buttons?.[0]?.label}
              subtitle={item?.buttons?.[0]?.subTitle}
              extraStyle={{
                boxShadow: 'rgba(211, 211, 211, 0.326) 1px 4px 20px',
                border: 'solid  .5px',
                backdropFilter: 'blur(4px)',
              }}
              className={className}
            >
              {isHovered && (
                <div
                  className="chatbot-button-close"
                  style={{ display: isHovered ? 'flex' : 'none', zIndex: '10' }}
                  onClick={onDelete}
                >
                  <CloseIcon
                    height={12}
                    width={12}
                    style={{ position: 'static' }}
                  />
                </div>
              )}
            </ChatbotButton>
          </div>
        ) : (
          <div
            className={`initial-message-bubble font-gray-500 flex items-center text-gray-500 text-sm ${className}`}
            style={{ width: '230px' }} // not sure why, but aligning to the left without this gives extra space
            // and i couldn't find an elegant way to deal with that
          >
            <div class="typingIndicatorBubble">
              <div class="typingIndicatorBubbleDot"></div>
              <div class="typingIndicatorBubbleDot"></div>
              <div class="typingIndicatorBubbleDot"></div>
            </div>
            Add to your CTA bubble
          </div>
        ))
      )}
    </>
  );
};

export default ChatbotBubble;
