import { Divider, Radio, Typography } from '@material-ui/core';
import MatGrid from '@material-ui/core/Grid';
import { Delete, Edit } from '@material-ui/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { font } from '../../utils/constants';
import CommunityListItem from '../CommunityListItem';
const AddCommunityForm = (props) => {
  // onboarding step
  const [onboarding, setOnboarding] = useState(1);

  const [communityName, setCommunityName] = useState('');
  const [communityURL, setCommunityURL] = useState('');
  const [communityImageURL, setCommunityImageURL] = useState('');
  const [communityAddress, setCommunityAddress] = useState('');

  const [communitySize, setCommunitySize] = useState('');
  const [propType, setPropType] = useState('');
  const [webType, setWebType] = useState('');

  const [displayError, setDisplayError] = useState(false);

  const urlPat = /^https:\/\/.*\.(com|org|net|co)/;
  const notNumPat = /\D/;

  const handleSubmit = () => {
    console.log('checking form3 submit');
    props.onFormSubmit({
      name: communityName,
      url: communityURL,
      img_url: communityImageURL,
      // address: communityAddress,
      units: communitySize,
      property_type: propType,
      website_type: webType,
    });

    setCommunityName('');
    setCommunityURL('');
    setCommunityAddress('');
    setCommunitySize('');
    setWebType('');
    setPropType('');
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  const [isvirtual, setIsvirtual] = useState(false);
  const [isboth, setIsboth] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getImagUrl() {
      setLoading(true);
      const iframelyObj = await axios.get(
        `https://iframe.ly/api/oembed?url=${communityURL}&api_key=6b1098ee260e0d21f9c7e3`
      );
      if (iframelyObj.data.thumbnail_url) {
        setLoading(false);
        setCommunityImageURL(iframelyObj.data.thumbnail_url);
      } else {
        setLoading(false);
      }
    }
    if (communityURL !== '') {
      getImagUrl();
    }
  }, [communityURL]);

  return (
    <>
      <MatGrid container item xs={12} direction="column">
        {onboarding === 1 && (
          <>
            <Typography
              variant={isMobile ? 'subtitle2' : 'subtitle2'}
              align="left"
              style={{ color: 'darkgrey', fontFamily: font }}
            >
              Step 1 of 3
            </Typography>
            <Typography
              variant={isMobile ? 'h5' : 'h5'}
              align="left"
              style={{
                color: '#262E37',
                fontWeight: 900,
                fontFamily: font,
                marginTop: '5px',
              }}
            >
              Add your Community
            </Typography>
            <Typography
              variant={isMobile ? 'subtitle2' : 'subtitle2'}
              align="left"
              style={{ color: '#262E37', fontFamily: font, marginTop: '5px' }}
            >
              Use LeaseMagnets to manage your Community
            </Typography>{' '}
            <br />
            <input
              type="text"
              name="login"
              id="login"
              tabindex="1"
              className="text-input"
              autocorrect="off"
              autocapitalize="off"
              placeholder="Community Name"
              value={communityName}
              style={{
                backgroundColor: communityName
                  ? 'rgb(226, 244, 253)'
                  : '#F3F3F4',
              }}
              onChange={(e) => setCommunityName(e.target.value)}
              required
            />
          </>
        )}
        {onboarding === 2 && (
          <>
            <Typography
              variant={isMobile ? 'subtitle2' : 'subtitle2'}
              align="left"
              style={{ color: 'darkgrey', fontFamily: font }}
            >
              Step 2 of 3
            </Typography>
            <Typography
              variant={isMobile ? 'h5' : 'h5'}
              align="left"
              style={{ color: '#262E37', fontWeight: 900, fontFamily: font }}
            >
              Where can we find {communityName}
            </Typography>
            <Typography
              variant={isMobile ? 'subtitle2' : 'subtitle2'}
              align="left"
              style={{ color: '#262E37', fontFamily: font, marginTop: '5px' }}
            >
              We really want to come visit
            </Typography>{' '}
            <br />
            <input
              type="url"
              name="login"
              id="login"
              tabindex="1"
              className="text-input"
              autocorrect="off"
              autocapitalize="off"
              placeholder="Community URL"
              defaultValue={communityURL}
              style={{
                backgroundColor: urlPat.test(communityURL)
                  ? 'rgb(226, 244, 253)'
                  : '#F3F3F4',
              }}
              onBlur={(e) => setCommunityURL(e.target.value)}
              required
            />
            {communityImageURL && (
              <>
                {loading ? (
                  'loading...'
                ) : (
                  <img
                    alt="new"
                    src={communityImageURL}
                    style={{
                      width: '200px',
                      height: '100px',
                      objectFit: 'cover',
                    }}
                  />
                )}
              </>
            )}
            {/* <img alt="new" src={communityImageURL} style={{ width: '250px' }} />
            <button
              onClick={() => {
                console.log('before opengraph call');
                fetch(
                  `https://api.opengraphr.com/v1/og?api_token=zOoUHUX4mLywRUgL3UocGf7cPkyjKbuM4BPZi3wr0AxrjcRcqcX6aaKKIlDB8ms5cXqL0soSsn70FhMm&url=${communityURL}`
                )
                  .then((response) => response.json())
                  .then((data) => {
                    console.log('opengraph: ', data, communityImageURL);
                    setCommunityImageURL(data.image);
                  });
              }}
            >
              Generate Image URL
            </button>
            <button
              onClick={() => {
                console.log('img_url: ', communityImageURL);
              }}
            >
              Print Image URL
            </button> */}
            {/* <input type="radio" name="" id="" /> */}
            <div className="my-2 space-y-4">
              <div className="flex items-center">
                <input
                  id="push-everything"
                  name="push-notifications"
                  type="radio"
                  defaultChecked
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  onChange={(e) => {
                    setIsvirtual(false);
                    setIsboth(false);
                    setCommunityAddress('');
                  }}
                />
                <label
                  htmlFor="push-everything"
                  className="ml-3 block text-sm font-medium text-gray-700"
                >
                  In-person
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="push-email"
                  name="push-notifications"
                  type="radio"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  onChange={(e) => {
                    setIsvirtual(true);
                    setCommunityAddress('');
                  }}
                />
                <label
                  htmlFor="push-email"
                  className="ml-3 block text-sm font-medium text-gray-700"
                >
                  Virtual
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="push-nothing"
                  name="push-notifications"
                  type="radio"
                  value={false}
                  onChange={(e) => {
                    setIsvirtual(false);
                    setIsboth(true);
                    //setCommunityAddress('');
                  }}
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                />
                <label
                  htmlFor="push-nothing"
                  className="ml-3 block text-sm font-medium text-gray-700"
                >
                  Both
                </label>
              </div>
            </div>
            {isvirtual === false && (
              <input
                type="text"
                name="login"
                id="login"
                tabindex="1"
                className="text-input"
                autocorrect="off"
                autocapitalize="off"
                placeholder="Community Address"
                value={communityAddress}
                style={{
                  backgroundColor: communityAddress
                    ? 'rgb(226, 244, 253)'
                    : '#F3F3F4',
                }}
                onChange={(e) => setCommunityAddress(e.target.value)}
                required
              />
            )}
            <input
              type="text"
              name="login"
              id="login"
              tabindex="1"
              className="text-input"
              autocorrect="off"
              autocapitalize="off"
              placeholder={
                isvirtual === true || isboth === true
                  ? '# of Customer served Monthly'
                  : '# of Units'
              }
              value={communitySize}
              style={{
                backgroundColor:
                  communitySize && !notNumPat.test(communitySize)
                    ? 'rgb(226, 244, 253)'
                    : '#F3F3F4',
              }}
              onChange={(e) => setCommunitySize(e.target.value)}
              required
            />
          </>
        )}
        {onboarding === 3 && (
          <>
            <Typography
              variant={isMobile ? 'subtitle2' : 'subtitle2'}
              align="left"
              style={{ color: 'darkgrey', fontFamily: font }}
            >
              Step 3 of 3
            </Typography>
            <Typography
              variant={isMobile ? 'h5' : 'h5'}
              align="left"
              style={{ color: '#262E37', fontWeight: 900, fontFamily: font }}
            >
              {' '}
              Tell us more about {communityName}
            </Typography>
            <Typography
              variant={isMobile ? 'subtitle2' : 'subtitle2'}
              align="left"
              style={{ color: '#262E37', fontFamily: font, marginTop: '10px' }}
            >
              Is your Website for a Single Community or Portfolio?
            </Typography>{' '}
            <br />
            <MatGrid
              container
              direction="row"
              justify="space-between"
              style={{ marginTop: '5px' }}
            >
              <MatGrid
                container
                item
                direction="column"
                alignItems="center"
                style={{ width: '45%', cursor: 'pointer' }}
                onClick={() => setWebType('community')}
              >
                <img
                  src="https://img.icons8.com/?size=48&id=zNU7gWqHtLYb&format=png"
                  style={{ height: '40px', width: '40px', marginBottom: '5px' }}
                />
                <Typography variant="caption">Single Community</Typography>
                <Radio
                  color="primary"
                  checked={webType === 'community'}
                  style={{ padding: 0 }}
                />
              </MatGrid>
              <MatGrid
                container
                item
                direction="column"
                alignItems="center"
                style={{ width: '45%', cursor: 'pointer' }}
                onClick={() => setWebType('portfolio')}
              >
                <img
                  src="https://img.icons8.com/?size=48&id=Su3sXbvz0W0m&format=png"
                  style={{ height: '40px', width: '40px', marginBottom: '5px' }}
                />
                <Typography variant="caption">Portfolio</Typography>
                <Radio
                  color="primary"
                  checked={webType === 'portfolio'}
                  style={{ padding: 0 }}
                />
              </MatGrid>
              <Divider style={{ width: '100%', margin: '15px 0' }} />
              <Typography
                variant={isMobile ? 'subtitle2' : 'subtitle2'}
                align="left"
                style={{ color: '#262E37', fontFamily: font, marginTop: '5px' }}
              >
                Does {communityName} provide Student Housing or Multi-Family
                Housing?
              </Typography>{' '}
              <br />
              <MatGrid
                container
                direction="row"
                justify="space-between"
                style={{ marginTop: '10px' }}
              >
                <MatGrid
                  container
                  item
                  direction="column"
                  alignItems="center"
                  style={{ width: '45%', cursor: 'pointer' }}
                  onClick={() => setPropType('student_housing')}
                >
                  <img
                    src="https://img.icons8.com/?size=48&id=4NzvNiDONXRU&format=png"
                    style={{
                      height: '40px',
                      width: '40px',
                      marginBottom: '5px',
                    }}
                  />
                  <Typography variant="caption">Student Housing</Typography>
                  <Radio
                    color="primary"
                    checked={propType === 'student_housing'}
                    style={{ padding: 0 }}
                  />
                </MatGrid>
                <MatGrid
                  container
                  item
                  direction="column"
                  alignItems="center"
                  style={{ width: '45%', cursor: 'pointer' }}
                  onClick={() => setPropType('multifamily')}
                >
                  <img
                    src="https://img.icons8.com/?size=48&id=8aPkSWuKIc0q&format=png"
                    style={{
                      height: '40px',
                      width: '40px',
                      marginBottom: '5px',
                    }}
                  />
                  <Typography variant="caption">Multi-Family</Typography>
                  <Radio
                    color="primary"
                    checked={propType === 'multifamily'}
                    style={{ padding: 0 }}
                  />
                </MatGrid>
              </MatGrid>
            </MatGrid>
          </>
        )}
        {onboarding === 4 && (
          <>
            <div className="communityListWrapper">
              <Typography
                variant={isMobile ? 'h5' : 'h5'}
                align="left"
                style={{ color: '#262E37', fontWeight: 900, fontFamily: font }}
              >
                Review your Communities
              </Typography>
              <Typography
                variant={isMobile ? 'subtitle2' : 'subtitle2'}
                align="left"
                style={{ color: '#262E37', fontFamily: font, marginTop: '5px' }}
              >
                {props.communities.length} Community Website
                {props.communities.length !== 1 && 's'} to be added to your
                account
              </Typography>{' '}
              <br />
              <div style={{ maxHeight: '45vh', overflowY: 'scroll' }}>
                {props.communities.map((communityInfo, index) => (
                  <MatGrid
                    container
                    justify="space-between"
                    key={communityInfo.name + index}
                  >
                    <MatGrid item xs={11}>
                      <CommunityListItem
                        communityInfo={communityInfo}
                        key={communityInfo.name + index}
                        id={index}
                        onDelete={props.handleDelete}
                        showDelete
                        allowRedirect={false}
                      />
                    </MatGrid>
                    <MatGrid container direction="column" item xs={1}>
                      <Edit
                        style={{
                          cursor: 'pointer',
                          color: 'rgb(56, 152, 236)',
                          width: '75%',
                          marginLeft: '25%',
                        }}
                        onClick={() => {
                          props.handleDelete(index);
                          setOnboarding(1);
                          setCommunityName(communityInfo.name);
                          setCommunityURL(communityInfo.url);
                          setCommunityAddress(communityInfo.address);
                          setCommunitySize(communityInfo.units);
                          setWebType(communityInfo.website_type);
                          setPropType(communityInfo.property_type);
                        }}
                      />
                      <Delete
                        style={{
                          marginTop: '15px',
                          cursor: 'pointer',
                          color: 'rgb(56, 152, 236)',
                          width: '75%',
                          marginLeft: '25%',
                        }}
                        onClick={() => {
                          props.handleDelete(index);
                        }}
                      />
                    </MatGrid>
                  </MatGrid>
                ))}
              </div>
            </div>
          </>
        )}
      </MatGrid>
      <MatGrid
        container
        item
        xs={12}
        justify="flex-start"
        style={{ marginTop: '10px', display: displayError ? 'block' : 'none' }}
      >
        {onboarding === 1 && communityName === '' && (
          <Typography className="error_text" variant="caption" align="left">
            →Please enter the community name
            <br />
          </Typography>
        )}
        {onboarding === 2 && !communityURL && (
          <Typography className="error_text" variant="caption">
            →Please enter a valid community URL
            <br />
          </Typography>
        )}
        {onboarding === 2 && communityAddress === '' && (
          <Typography className="error_text" variant="caption">
            →Please enter your community address
            <br />
          </Typography>
        )}
        {onboarding === 2 && (!communitySize || notNumPat.test(communitySize)) && (
          <Typography className="error_text" variant="caption">
            →Please enter a valid community size
            <br />
          </Typography>
        )}
        {onboarding === 3 && webType === '' && (
          <Typography className="error_text" variant="caption">
            →Please choose your community type
            <br />
          </Typography>
        )}
        {onboarding === 3 && propType === '' && (
          <Typography className="error_text" variant="caption">
            →Please choose your property type
            <br />
          </Typography>
        )}

        {/* {onboarding===4 && orgRole === "" && <Typography className="error_text" variant="caption">→Please type your organizational role<br/></Typography>} */}
      </MatGrid>
      <MatGrid container item xs={12} justify="flex-start">
        {onboarding === 1 && (
          <>
            <button
              className="button signin"
              style={{ cursor: communityName ? 'pointer' : 'not-allowed' }}
              onClick={() => {
                if (communityName) {
                  setOnboarding(2);
                  setDisplayError(false);
                } else {
                  setDisplayError(true);
                }
              }}
            >
              Continue →
            </button>
            <button
              className="button secondary"
              onClick={() => window.location.replace('/')}
            >
              {props.hasCommunities
                ? 'Back to dashboard'
                : 'I will add my communities later'}
            </button>
          </>
        )}
        {onboarding === 2 && (
          <>
            <MatGrid container item xs={12} justify="space-between">
              <button className="button back" onClick={() => setOnboarding(1)}>
                ←
              </button>
              <button
                className="button signin"
                style={{
                  cursor:
                    communityURL &&
                    communityAddress &&
                    communitySize &&
                    !notNumPat.test(communitySize)
                      ? 'pointer'
                      : 'not-allowed',
                  width: '80%',
                }}
                onClick={() => {
                  if (
                    communityURL &&
                    communityAddress &&
                    communitySize &&
                    !notNumPat.test(communitySize)
                  ) {
                    setOnboarding(3);
                    setDisplayError(false);
                  } else {
                    setDisplayError(true);
                  }
                }}
              >
                Continue →
              </button>
            </MatGrid>
            <button
              className="button secondary"
              onClick={() => window.location.replace('/')}
            >
              {props.hasCommunities
                ? 'Back to dashboard'
                : 'I will add my communities later'}
            </button>
          </>
        )}
        {onboarding === 3 && (
          <>
            <MatGrid container item xs={12} justify="space-between">
              <button className="button back" onClick={() => setOnboarding(2)}>
                ←
              </button>
              <button
                className="button signin"
                style={{
                  cursor: webType && propType ? 'pointer' : 'not-allowed',
                  width: '80%',
                }}
                onClick={() => {
                  if (webType && propType) {
                    handleSubmit();
                    setOnboarding(4);
                    setDisplayError(false);
                  } else {
                    setDisplayError(true);
                  }
                }}
              >
                Add {communityName} →
              </button>
            </MatGrid>
            <button
              className="button secondary"
              onClick={() => window.location.replace('/')}
            >
              {props.hasCommunities
                ? 'Back to dashboard'
                : 'I will add my communities later'}
            </button>
          </>
        )}
        {onboarding === 4 && (
          <>
            <button className="button create" onClick={() => setOnboarding(1)}>
              Add another Community
            </button>
            <button className="button signin" onClick={props.onContinue}>
              Finish →
            </button>
          </>
        )}
      </MatGrid>
    </>
  );
};

export default AddCommunityForm;
