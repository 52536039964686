import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const UploadVideoImport = ({ match }) => {
  const { magnet_id_param, screen_id_param } = useParams();

  const [recordedVideos, sedRecordedVideos] = useState([
    {
      video:
        'https://images.hypno.com/7/9_qwhnD2s15t/9_qwhnD2s15t_750.jpg?width=300',
      counter: 0,
      stemp: '2 days ago',
    },
    {
      video:
        'https://images.hypno.com/7/BMPIe4Q2qxdc/BMPIe4Q2qxdc_750.jpg?width=300',
      counter: 0,
      stemp: '8 days ago',
    },
    {
      video:
        'https://images.hypno.com/7/XFneHEs2qhso/XFneHEs2qhso_750.jpg?width=300',
      counter: 0,
      stemp: '14 days ago',
    },
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]);
  const [openSearchbar, setOpenSearchbar] = useState(false);

  return (
    <div className="upload-video-import rel">
      <div className="nav-blk flex flex-col fixed">
        <div className="meta">
          <div className="nam font s38 b6 color">Amulya</div>
          <div className="font sub-txt">blessed</div>
          <Link
            to={`/upload-video-import/${magnet_id_param}/${screen_id_param}/record`}
            className="record-btn font s16"
          >
            Record a video ➔
          </Link>
        </div>
        <div className="nav-ftr flex aic">
          <div className="copy-rit flex aic">
            <div className="lbl font c000 b6 s15">Made with</div>
            <div className="txt font s15 cfff">tour.video</div>
          </div>
        </div>
      </div>
      <div className="videos-collection rel">
        {recordedVideos.map((item, idx) => (
          <div
            key={idx}
            className={`item rel ${
              item.video && openSearchbar == false ? 'video' : 'empty'
            }`}
          >
            <img src={item.video} className="img" />
            <div className="upper-right abs">
              <button className="cleanbtn del-btn flex aic">
                <div className="ico icon-delete2 cfff" />
              </button>
            </div>
            <div className="lower-left abs">
              <div className="stemp font s12 b4 cfff">{item.stemp}</div>
            </div>
            {/* <button className="cleanbtn add-btn">
              <img
                src={
                  require('../../assets/upload-video-import/btn-add.svg')
                    .default
                }
                className="ico"
              />
            </button> */}
          </div>
        ))}
        {/* Search Button */}
        <div
          className={`cleanbtn search-bar flex aic ${
            openSearchbar ? 'active' : ''
          }`}
        >
          {openSearchbar ? (
            <button
              className="cleanbtn"
              onClick={() => setOpenSearchbar(!openSearchbar)}
            >
              <img
                src={
                  require('../../assets/upload-video-import/close.svg').default
                }
                className="ico"
              />
            </button>
          ) : (
            <button
              className="cleanbtn"
              onClick={() => setOpenSearchbar(!openSearchbar)}
            >
              <img
                src={
                  require('../../assets/upload-video-import/search.svg').default
                }
                className="ico"
              />
            </button>
          )}
          <input type="text" placeholder="Search" className="iput font" />
        </div>
      </div>
    </div>
  );
};
export default UploadVideoImport;
