import { useCallback, useEffect, useState } from 'react';

const AnalyticsBarComponent = ({
  title,
  data,
  pagination,
  primaryColor,
  onClickFunc,
}) => {
  console.log('DataMain', data);
  const [dataLeft, setDataLeft] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentPage = useCallback(() => {
    return Math.floor(currentIndex / pagination);
  }, [currentIndex, pagination]);
  const maxPage = useCallback(() => {
    return Math.floor(data.length / pagination);
  }, [data, pagination]);

  useEffect(() => {
    setDataLeft(data?.slice(currentIndex, currentIndex + pagination));
    setCurrentIndex(currentIndex + pagination);
  }, []);

  const handlePreviousData = () => {
    console.log(currentIndex - pagination, currentIndex);
    if (currentIndex - pagination > 0) {
      setDataLeft(
        data?.slice(
          currentIndex - pagination - pagination,
          currentIndex - pagination
        )
      );
      setCurrentIndex(currentIndex - pagination);
    }
  };
  const handleNextData = () => {
    console.log(currentIndex, currentIndex + pagination);

    if (currentIndex < data?.length) {
      setDataLeft(data?.slice(currentIndex, currentIndex + pagination));
      setCurrentIndex(currentIndex + pagination);
    }
  };
  const openLink = (url) => window.open(url, '_blank')?.focus();

  return (
    <div className="bg-white p-3 rounded-xl ">
      {!(data && data.length > 0) ? (
        <div className="animate-pulse">
          <div className="flex items-center justify-between pb-3 border-b w-full ">
            <div className=" w-20 h-6 bg-gray-300 rounded-md"></div>
            <div className=" flex items-center gap-3">
              <div className=" w-16 h-8 bg-gray-300 rounded-md"></div>
              <div className=" w-16 h-8 bg-gray-300 rounded-md"></div>
            </div>
          </div>
          <div className=" w-full pr-10">
            <div
              style={{ width: '100%' }}
              className="  bg-gray-300 rounded-md h-8 mb-2"
            ></div>
            <div
              style={{ width: '90%' }}
              className=" w-full bg-gray-300 rounded-md h-8 mb-2"
            ></div>
            <div
              style={{ width: '80%' }}
              className=" w-full bg-gray-300 rounded-md h-8 mb-2"
            ></div>
            <div
              style={{ width: '60%' }}
              className=" w-full bg-gray-300 rounded-md h-8 mb-2"
            ></div>
            <div
              style={{ width: '30%' }}
              className=" w-full bg-gray-300 rounded-md h-8  mb-2"
            ></div>
            <div
              style={{ width: '20%' }}
              className=" w-full bg-gray-300 rounded-md h-8 "
            ></div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between pb-3 border-b w-full">
            <h1 className=" text-xl font-semibold">
              {title} | {currentPage()}/ {maxPage()}
            </h1>
            {pagination !== 0 && (
              <div className=" flex items-center gap-3">
                {currentIndex - pagination > 0 ? (
                  <button
                    onClick={handlePreviousData}
                    className=" px-3 py-2 text-sm cursor-pointer border rounded-md hover:bg-blue-50"
                  >
                    Prev
                  </button>
                ) : (
                  <button className=" px-3 py-2 text-sm cursor-default border text-gray-400  rounded-md ">
                    Prev
                  </button>
                )}
                {currentIndex < data?.length ? (
                  <button
                    onClick={handleNextData}
                    className=" px-3 py-2 text-sm cursor-pointer border rounded-md hover:bg-blue-50"
                  >
                    Next
                  </button>
                ) : (
                  <button className=" px-3 py-2 text-sm cursor-default border text-gray-400  rounded-md ">
                    Next
                  </button>
                )}
              </div>
            )}
          </div>
          <div className=" w-full">
            {dataLeft?.map((item, ind) => (
              <div
                onClick={() => {
                  if (item?.link) {
                    openLink(item?.link);
                  } else {
                    onClickFunc(item);
                  }
                }}
                className=" flex items-center justify-between cursor-pointer w-full z-10 my-2"
              >
                <div className=" w-full pr-10">
                  <div className=" relative  gap-2 p-2 rounded-md">
                    <div className=" relative w-full h-full flex items-center gap-2  z-30">
                      {item?.image && (
                        <img src={item?.image} className=" w-3 h-3" />
                      )}
                      <div className="block">
                        <p className="">
                          {item?.title
                            ? `${item?.title?.slice(0, 50)}`
                            : '(None)'}
                        </p>
                        <p className="text-xs">
                          {item?.subtitle && item?.subtitle?.slice(0, 50)}
                        </p>
                      </div>
                    </div>
                    <div
                      style={
                        currentPage > 0
                          ? {
                              width: `${(item?.value / data[0]?.value) * 100}%`,
                              background: primaryColor,
                            }
                          : {
                              width: `${(item?.value / data[0]?.value) * 100}%`,
                              background: primaryColor,
                            }
                      }
                      className=" absolute top-0 left-0 z-10 w-full h-full flex justify-center"
                    ></div>
                  </div>
                </div>
                <p>{item?.value}</p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default AnalyticsBarComponent;
