import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import { AiOutlineClose, AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai';
import { useState, useEffect } from 'react';
import ConditionRow from './ConditionRow';
import { v4 } from 'uuid';

const TriggersModal = ({
  open,
  setOpen,
  routes,
  chatbot,
  setChatbot,
  routeOpts, // i get the feeling that this is the same as routes but idk for sure
  selectedRow,
}) => {
  // emulate conditions state - linked directly to chatbot
  const conditions = chatbot?.[selectedRow]?.condition;
  const setConditions = (newValue) => {
    setChatbot((prevChatbot) => {
      const newChatbot = structuredClone(prevChatbot);
      newChatbot[selectedRow].condition =
        typeof newValue === 'function'
          ? newValue(prevChatbot[selectedRow].condition)
          : newValue;
      return newChatbot;
    });
  };

  // emulate a [condition, setCondition] state
  const setCondition = (index, newValue) => {
    setConditions((oldConditions) => {
      const newConditions = [...oldConditions];
      if (typeof newValue === 'function') {
        newConditions[index] = newValue(oldConditions[index]);
      } else {
        newConditions[index] = newValue;
      }
      return newConditions;
    });
  };

  const addCondition = () => {
    setConditions((oldConditions) => [
      ...oldConditions,
      {
        type: oldConditions?.[oldConditions.length - 1]?.type || '',
        route: '',
        operator: '',
        value: [],
        id: v4(),
      },
    ]);
  };

  const removeCondition = (ind) => {
    setConditions((oldConditions) =>
      oldConditions.filter((item, index) => ind !== index)
    );
  };

  return (
    <AnimatedDialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
      maxWidth="md"
    >
      <div className="p-4 w-full">
        <div className="flex items-center justify-between border-b border-gray-300 w-full">
          <h4 className=" pb-3 text-xl font-semibold">Conditionally Trigger</h4>
          <AiOutlineClose
            className=" w-7 h-7 cursor-pointer text-gray-600"
            onClick={() => setOpen(false)}
          />
        </div>
        <h1 className="text-xl p-2 mt-2">
          Add conditions that must be met for this bubble to show:
        </h1>
        <div className="flex flex-col gap-4 p-2 border rounded-md bg-gray-50">
          {routes &&
            conditions &&
            conditions?.map((item, ind) => {
              return (
                <ConditionRow
                  key={item.id}
                  condition={item}
                  setCondition={(newValue) => setCondition(ind, newValue)}
                  removeCondition={() => removeCondition(ind)}
                  routeOpts={routeOpts}
                  chatbot={chatbot}
                  selectedRow={selectedRow}
                />
              );
            })}
          {!conditions?.length && 'No triggers.'}
        </div>
        <div className="flex items-center justify-start w-full mt-3">
          <button
            onClick={() => addCondition()}
            className=" flex items-center gap-1 bg-gray-700 text-sm px-3 py-1 rounded-md text-white  mr-2 hover:opacity-80"
          >
            <AiOutlinePlus className=" w-5 h-5" />
            Add
          </button>
        </div>
      </div>
    </AnimatedDialog>
  );
};
export default TriggersModal;
