import React from 'react';
import './question.css';

export default function Question({
  setRoute,
  apt_template,
  question_template,
  setWho,
  step,
}) {
  return (
    <div>
      {step === 2 ? (
        <>
          <div className="font s22 b4 question-text c000">
            {`Who did you tour with at ${apt_template?.name}`}?
          </div>
          {question_template.map((answer, idx) => {
            return (
              <Answer
                key={answer?.id}
                answer={answer}
                setWho={setWho}
                setRoute={setRoute}
                idx={idx}
              />
            );
          })}
        </>
      ) : (
        <>
          <div className="font s22 b4 question-text c000">
            {question_template?.question} {apt_template?.name}
          </div>
          {question_template.answers.map((answer, idx) => {
            return (
              <Answer
                key={answer?.id}
                answer={answer}
                setWho={setWho}
                setRoute={setRoute}
                idx={idx}
              />
            );
          })}
        </>
      )}
    </div>
  );
}

// Answer button
function Answer({ answer, setWho, setRoute, idx }) {
  // Handle button click
  function handleClick() {
    console.log('handling click', answer);
    setRoute(answer);
    // If this question is asking for who the user interacted with
    if (setWho) setWho({ name: answer.name, user_id: answer.id });
  }

  return (
    <button className="answer-choice" onClick={handleClick}>
      <div className="letter-container">
        <div className="letter font">{String.fromCharCode(idx + 65)}</div>
      </div>
      {answer.name}
    </button>
  );
}
