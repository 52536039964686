import Input from '@material-ui/core/Input';
import Popover from '@material-ui/core/Popover';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CopyIcon } from '../../assets/svg';
import { Header } from '../../components';
import AllReferralList from './AllReferralList';
import ReferralUsers from './ReferralUsers';
function Referral({ name, community_id, url }) {
  const [community_data, setCommunity_data] = useState(null);
  const [referralUsers, setReferralUsers] = useState([]);
  const [special, setSpecial] = useState(
    '50 dollars if you refer someone & they sign a lease.'
  );
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({});
  const [inputData, setInputData] = useState([
    { name: '', type: [] },
    { name: '', type: [] },
    { name: '', type: [] },
  ]);

  console.log('referrals community id', community_id);

  useEffect(() => {
    // fetchCommunity by Id
    const fetchCommunity = async () => {
      const response = await axios.get(
        `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
      );
      setCommunity_data(response.data?.payload[0]);
      let setting = JSON.parse(response.data?.payload[0].settings);
      setSettings(setting);

      if (setting.referrals?.badges) {
        setInputData(setting.referrals?.badges);
      }
      if (setting.referrals?.specials) {
        setSpecial(setting.referrals?.specials);
      }
    };

    // call the function
    fetchCommunity()
      // make sure to catch any error
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // fetchCommunity by Id
    const fetchReferralUsersInCommunity = async () => {
      const response = await axios.get(
        `https://api.directual.com/good/api/v5/data/users/getReferralUsersInCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
      );
      // console.log('ReferralUsersInCommunity', response.data);
      setReferralUsers(response.data?.payload);
    };

    // call the function
    fetchReferralUsersInCommunity()
      // make sure to catch any error
      .catch((err) => console.log(err));
  }, []);

  const saveData = async () => {
    setLoading(true);
    try {
      await axios.post(
        `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83`,
        {
          settings,
          id: community_id,
        }
      );
      setLoading(false);
      console.log('TVG community updated', community_id);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setSettings((prevState) => ({
      ...prevState,
      referrals: {
        goal: { target: 25, current: 10, population: '40' },
        badges: inputData,
        specials: special,
      },
    }));
  }, [inputData, special]);

  const [show, setShow] = useState(false);

  // Popup true or false
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'copy-btn' : undefined;

  function handleChangeName(value, index) {
    let newArr = [...inputData];
    newArr[index].name = value;
    setInputData(newArr);
  }
  function handleChangeType(newType, index) {
    let newArr = [...inputData];
    newArr[index].type = newType;
    setInputData(newArr);
  }
  // top
  const { screenToEdit } = useSelector((state) => state.magnetStates);
  const _magnetData = useSelector((state) => state.getMagnet);
  const { template } = _magnetData;

  const [category, screen] = useMemo(
    () => screenToEdit.split('.'),
    [screenToEdit]
  );
  var categoryTitle = useMemo(
    () => (category ? template?.categories[category]?.title : ''),
    [category, template]
  );
  return (
    <div className=" w-full min-h-screen relative">
      <Header>
        <div className="dashboard-hdr flex aic w-full">
          <div className="left flex aic">
            <a href={url} target="_blank">
              <OpenInNewIcon />
            </a>
            &nbsp;&nbsp;
            <Link to="/" className="item flex aic">
              <div className="txt font s14 b3 anim">{name}</div>
              {name ? <span className="slash">&nbsp;/&nbsp;</span> : null}
            </Link>
            <div className="item flex aic">
              <div
                onClick={() => {
                  _dispatch({
                    type: 'SCREEN_TO_EDIT',
                    payload: '',
                  });
                  window.history.pushState({}, `View ${community_id}`, `#`);
                }}
                className="txt font s14 b5 black"
              >
                Referral
              </div>
              {categoryTitle && <span className="slash">&nbsp;/&nbsp;</span>}
            </div>
            {categoryTitle && (
              <div className="item flex aic">
                <div className="txt font s14 b5 black">{categoryTitle}</div>
              </div>
            )}
          </div>
          <div className="right flex aic">
            <Link
              to={`./reviews/settings`}
              className="create-btn cleanbtn font anim s15"
            >
              <button className="cleanbtn add-category font s14 b5 cfff flex aic">
                <span className="ico icon-plus s16 cfff" />
                Survey Settings
              </button>
            </Link>
          </div>
        </div>
      </Header>
      <div className="referral-page">
        <div className="wrapper flex">
          <div className="left-side">
            <div className="hdr flex aic">
              <div className="left flex aic">
                <div className="page-title font s22 black b7">
                  {`${name ? name : ''} Referrals Portal`}
                </div>
              </div>
              <div className="right flex aic"></div>
            </div>
            <ReferralPictures referralUsers={referralUsers} />
            <ShareReferralLink community_id={community_id} open={open} />
          </div>
          <div className="right-side">
            <div className="demographic">
              <div className="note font s15 black flex-center-vt flex-gap-20px">
                <form class="w-full max-w-sm">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-first-name"
                  >
                    Current Special
                  </label>

                  <div class="flex items-center border-b border-teal-500 py-2">
                    <input
                      class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="text"
                      placeholder="Current Special"
                      aria-label="Full name"
                      onChange={(e) => setSpecial(e.target.value)}
                      value={special}
                    />
                    {/* <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" type="button">
                 Sign Up
               </button>
               <button class="flex-shrink-0 border-transparent border-4 text-teal-500 hover:text-teal-800 text-sm py-1 px-2 rounded" type="button">
                 Cancel
               </button> */}
                  </div>
                </form>
              </div>
              {!show && (
                <div className="mt-4">
                  <div className="tit font">Current Demographics:</div>
                  <div className="blk">
                    {inputData.map(({ name, type }, index) => (
                      <div key={index}>
                        <div className="lbl font">{name}</div>
                        <div className="items">
                          {type.map((value, index) => (
                            <div key={index} className="itm">
                              {value}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Add More Demographics */}
            {show && (
              <div>
                {inputData.map((item, index) => (
                  <DemographicRow
                    handleChangeName={handleChangeName}
                    handleChangeType={handleChangeType}
                    item={item}
                    index={index}
                    key={item.id}
                  />
                ))}
              </div>
            )}

            <div className="field flex-end">
              <div className="expand">
                <div className="actions flex aic">
                  <button
                    onClick={() => setShow(!show)}
                    className="cleanbtn form-btn font s15 b6 black"
                  >
                    {!show ? (
                      <span>&#43; Add More Demographics</span>
                    ) : (
                      <span>&#45; Leave Edit Mode</span>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="actions flex aic">
              <button
                onClick={saveData}
                className="action-btn share-btn font s15 b6 black"
              >
                {loading ? (
                  <button
                    disabled
                    type="button"
                    class="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-sky-500 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                  >
                    <svg
                      role="status"
                      class="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#1C64F2"
                      />
                    </svg>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="button"
                    class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                    Save Settings
                  </button>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* list of all referral*/}
        <AllReferralList community_id={community_id} />

        {/* Referral Users */}
        <ReferralUsers community_id={community_id} />
      </div>
    </div>
  );
}

export default Referral;

function ShareReferralLink({ community_id, open }) {
  console.log('referral link: ', community_id);
  return (
    <div className="actions flex flex-col">
      <div className="action-btn flex aic">
        <div className="txt font s14 black wordwrap">
          {`https://tour.video/referrers/community/${community_id}`}
        </div>
        <button className="cleanbtn copy-btn flex aic">
          <div className="copy-txt font">Share Referral</div>
          <CopyIcon />
        </button>
      </div>

      <div className="action-btn flex aic">
        <div className="txt font s14 black wordwrap">
          {`https://tour.video/referrers/community/${community_id}/add-new-referral`}
        </div>
        <button className="cleanbtn copy-btn flex aic">
          <div className="copy-txt font">Add + Referral</div>
          <CopyIcon />
        </button>
      </div>

      <Popover
        open={open}
        // anchorEl={anchorEl}
        // onClose={() => {
        // setAnchorEl(null);
        // } }
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className="Popover"
      >
        Copied to clipboard
      </Popover>
      {/* <button className="action-btn share-btn font s15">Share link</button> */}
    </div>
  );
}

function DemographicRow({ item, index, handleChangeName, handleChangeType }) {
  console.log('RowItem', item);
  const typeRef = useRef();
  const [types, setTypes] = useState(item?.type);
  const [newType, setNewType] = useState('');

  useEffect(() => {
    if (types)
      handleChangeType(types, index, [...types, newType.replace(/\s+/g, '')]);
  }, [types]);

  useEffect(() => {
    setTypes(item?.type);
  }, [item?.type]);

  // remove suggestion when click

  function addTypes(suggestion) {
    if (types.some((type) => type === suggestion)) {
      alert('Types already');
      return;
    }

    setTypes((types) => [...types, suggestion]);
    setNewType('');
  }

  function handleChangeTypeValue(value) {
    setNewType(value);
  }

  function handleTypeDelete(id, text) {
    const temp = types.filter((badge) => badge !== text);
    if (temp.length === 0) {
      setTypes([]);
    }
    setTypes(temp);
  }

  return (
    <div className="field flex flex-col">
      <Input
        onChange={(e) => handleChangeName(e.target.value, index)}
        value={item.name}
        placeholder="Type Name"
        inputProps={{ 'aria-label': 'description' }}
      />

      <div className="badge-block flex aic">
        {item?.type?.map((tempType, index) => {
          return (
            <BadgeRow text={tempType} key={index} handler={handleTypeDelete} />
          );
        })}
      </div>

      <div className="iput put-badge flex aic">
        <div className="flex-grow-1">
          <input
            className="font"
            type="text"
            ref={typeRef}
            value={newType}
            placeholder={`+ Add Types to ${item.name}`}
            onChange={(e) => handleChangeTypeValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                addTypes(e.target.value);
              }
            }}
            fullWidth
          />
        </div>
      </div>
    </div>
  );
}

function BadgeRow({ id, text, type, handler }) {
  return (
    <div className="badge-itm flex aic">
      <div className="tit font s13 cfff">{text}</div>
      <button
        className="cleanbtn badge-btn fontr cfff"
        onClick={() => handler(id, text, type)}
      >
        &times;
      </button>
    </div>
  );
}

function ReferralPictures({ referralUsers = [] }) {
  return (
    <div className="referral-users-blk">
      <div className="users">
        <div className="flex justify-end sm:justify-start lg:justify-end xl:justify-start -space-x-1.5">
          {referralUsers.slice(0, 5).map((item, index) => {
            const userMetadata = JSON.parse(item.metadata);
            const photoUrl = userMetadata?.photoUrl || '';
            const userName = item?.name;
            return (
              <img
                key={index}
                src={
                  photoUrl
                    ? 'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?auto=format&fit=facearea&facepad=2&w=48&h=48&q=80'
                    : `https://ui-avatars.com/api/?name=${encodeURI(userName)}`
                }
                alt="user.name"
                className="w-18 h-18 rounded-full bg-slate-100 ring-2 ring-white"
                loading="lazy"
              />
            );
          })}

          <div className="user add-btn rel">
            <div className="icon icon-plus" />
            <div className="btn-lbl">Add more referrals</div>
          </div>
        </div>

        <div className="meta flex flex-col aic">
          <div className="tag font s14 b6 black">
            {referralUsers && referralUsers.length > 5
              ? `+ ${referralUsers?.length - 5} more`
              : ''}
          </div>
          <div className="txt font black s15 b3 ml-4">
            {referralUsers?.length} referrals enabled
          </div>
        </div>
      </div>
    </div>
  );
}
