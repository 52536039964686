import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { post } from '../../utils/request';

function TemplatePreviewMessage({ onCancel }) {
  const { previewTemplate, magnetId } = useSelector(
    (state) => state.magnetStates
  );
  const { template, magnetData } = useSelector((state) => state.getMagnet);
  const { magnets } = magnetData;

  const _dispatch = useDispatch();

  const handleSaveTemplate = async () => {
    if (template && magnetId) {
      const res = await post('/magnets/template', {
        magnet_uuid: magnetId,
        magnet_type: 'video',
        template: {
          ...template,
          magnetSettings:
            window.oldmagnetDatajson?.magnet_details?.template?.magnetSettings,
        },
      });
      _dispatch({
        type: 'PREVIEW_TEMPLATE',
        payload: { ...previewTemplate, enablePreview: false },
      });
      onCancel && onCancel();
    }
  };

  return (
    <div
      className={`temp-prev-msg flex aic anim ${
        previewTemplate.enablePreview ? 'sho' : 'hid'
      }`}
    >
      <div className="txt font s15 c000">{`Currently previewing the ‘${previewTemplate.selectedTemplate.name} Template’`}</div>
      <div className="actions flex aic">
        <button className="cleanbtn save-btn btn" onClick={handleSaveTemplate}>
          Save
        </button>
        <button
          className="cleanbtn btn"
          onClick={() => {
            _dispatch({
              type: 'PREVIEW_TEMPLATE',
              payload: { ...previewTemplate, enablePreview: false },
            });
            onCancel && onCancel();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default TemplatePreviewMessage;
