import React, { useState, useEffect } from 'react';
import { idb } from '../services/indexedDB';

function useCacheState(initialValue = undefined, key, groupKey) {
  const [thisState, setThisState] = useState(initialValue);

  useEffect(() => {
    if (!key || !groupKey || typeof key !== 'string') {
      throw new Error(
        '[useCacheState] key and groupKey are required and key must be strings !'
      );
    }
    // Retrieve Existing Data with this key !
    (async function () {
      const allData = await idb.analytics.where('id').equals(groupKey);
      const dataObj = (await allData.toArray())[0];
      if (dataObj) {
        setThisState(dataObj[key]);
        console.log(`[useCacheState] IDB, ${key} ===> `, dataObj[key]);
      }
    })();
  }, []);

  function modifiedSetState(payload) {
    (async function () {
      // This can be improved by not fetching prevAllData at every setState
      let prevAllData = (
        await (await idb.analytics.where('id').equals(groupKey)).toArray()
      )[0];
      if (!prevAllData) prevAllData = {};
      const newData = { id: groupKey, ...prevAllData, [key]: payload };
      await idb.analytics.put(newData);
      console.log(`[useCacheState] Data Added to ${key}`, payload, newData);
      setThisState(payload);
    })();
  }

  return [thisState, modifiedSetState];
}

export default useCacheState;
