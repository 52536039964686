import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditButton from '../../components/Reusable/EditButton';
import './DragAndDrop.css';

export default function Gallery({
  setCurrVid,
  currVid,
  video,
  updateCloud,
  updateVidName,
  progress,
  deletefile,
  videoIndex,
  magnetType,
  magnetId,
}) {
  const { screenToEdit } = useSelector((state) => state.magnetStates);
  const _dispatch = useDispatch();

  const [category, screen] = screenToEdit.split('.');
  const { template } = useSelector((state) => state.getMagnet);
  const screenObj = template?.categories[category]?.screens[screen];
  // if you can edit the text field... and therefore not select button
  const [editable, setEditable] = useState(false);
  // Reference to selected video
  const vidRef = useRef(null);
  const [downloading, setDownloading] = useState(false);
  const handlePlayVideo = () => {
    vidRef.current.play();
  };

  const handlePauseVideo = () => {
    vidRef.current.pause();
  };

  // downloads the file (if it on our servers)
  function downloadFile() {
    // function copied from template.js
    setDownloading(true);
    fetch(video.dataURL, {
      method: 'GET',
      // headers: {
      //     'Content-Type': 'video/mp4',
      // },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${video.name}.mp4`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        setDownloading(false);
      })
      .catch((err) => {
        console.log(err);
        setDownloading(false);
      });
  }

  // updates cloudLibrary and also updated the icon
  function handleUpdateCloud(event) {
    event.stopPropagation();
    // check origName to see if it is in the cloudlibrary yet (we dont store orgName after upload successful)
    if (!video.origName) {
      if (
        window.confirm('Are you sure you want to remove ' + video.name + '?')
      ) {
        deletefile();
      }
    } else {
      updateCloud();
    }
  }

  async function handlePushCloudLibrary() {
    console.log('the_screenToUpdate', screenObj);
    const screenToUpdate = screenObj;
    const data = screenToUpdate?.cloudLibrary[videoIndex];
    if (screenToUpdate?.img && data?.img) {
      screenToUpdate['img'] = data?.img;
    }
    if (screenToUpdate?.video && data?.video) {
      screenToUpdate['video'] = data?.video;
    }
    if (screenToUpdate?.gif && data?.gif) {
      screenToUpdate['gif'] = data?.gif;
    }
    if (screenToUpdate?.dataURL && data?.dataURL) {
      screenToUpdate['dataURL'] = data?.dataURL;
    }
    console.log('the_screenToUpdate', screenToUpdate);
    _dispatch({
      type: 'updateScreen',
      payload: {
        screen_key: screen,
        category_key: category,
        screen: screenToUpdate,
        magnet_id: magnetId,
        magnet_type: magnetType,
      },
    });
  }

  // Set current video to clicked video
  function setDisplay() {
    if (editable) {
      return;
    }
    setCurrVid([
      {
        source: video.dataURL,
        type: video.type,
        formData: video.formData ? video.formData : '',
      },
    ]);
    handlePushCloudLibrary();
  }

  return (
    <Button
      fullWidth
      disableFocusRipple={true}
      disableRipple={true}
      onClick={setDisplay}
      variant={currVid[0]?.source === video.dataURL ? 'contained' : ''}
    >
      <Grid container justify="center" spacing={0}>
        <Grid item xs={3}>
          <video
            ref={vidRef}
            muted
            loop
            onMouseEnter={handlePlayVideo}
            onMouseLeave={handlePauseVideo}
            className="gallery"
          >
            <source src={video.dataURL} type={video.type} />
            Your browser does not support HTML5 video.
          </video>
          {!video.origName && (
            <Box position="absolute" top="60%" left="22%">
              <CloudIcon color="primary" />
            </Box>
          )}
        </Grid>
        <Grid item xs={6}>
          <br />
          <div className="left">
            <EditButton
              label={
                <div>
                  {video?.name?.substring(-1, 15)}
                  {video?.name?.length >= 15 ? '...' : ''}
                </div>
              }
              placeholder={'Video Name'}
              initialValue={video?.name || 'Video'}
              setState={updateVidName}
              setEditableState={setEditable}
            />
          </div>
        </Grid>
        <Grid item xs={1}>
          {downloading ? (
            <>
              <br />
              <CircularProgress
                size={25}
                style={{ top: '50%', color: '#212121' }}
              />
            </>
          ) : (
            !video.formData && (
              <IconButton
                style={{ top: '20%', color: '#212121' }}
                aria-label="download video"
                onClick={(e) => {
                  e.stopPropagation();
                  downloadFile();
                }}
              >
                <GetAppIcon />
              </IconButton>
            )
          )}
        </Grid>
        <Grid item xs={1}>
          <IconButton
            style={{ top: '20%', color: '#212121' }}
            aria-label="save to cloud"
            onClick={handleUpdateCloud}
          >
            {video.origName ? (
              video.saveToCloud ? (
                <CloudIcon />
              ) : (
                <CloudQueueIcon />
              )
            ) : (
              <DeleteIcon />
            )}
          </IconButton>
        </Grid>
        {progress ? (
          <Grid item xs={12}>
            <LinearProgress variant="determinate" value={progress} />
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </Button>
  );
}
