const ContactForm = ({
  info,
  setInfo,
  onSubmit,
  isLoading,
  messageOff,
  cta = 'Send Message',
  defaultMessage = '',
}) => {
  const fields = [
    {
      label: 'First Name',
      identifier: 'firstname',
    },
    {
      label: 'Last Name',
      identifier: 'lastname',
    },
    {
      label: 'Email',
      identifier: 'email',
    },
    {
      label: 'Phone',
      identifier: 'phone',
    },
  ];

  return (
    <div className="contact-form">
      {fields.map((fieldData, idx) => {
        return (
          <div className="field" key={idx}>
            <div className="lbl font">{fieldData.label}</div>
            <input
              type="text"
              placeholder={`Your ${fieldData.label}`}
              className="iput font"
              onChange={(e) => {
                setInfo({
                  ...info,
                  [fieldData.identifier]: e.target.value,
                });
              }}
              value={info ? info[fieldData.identifier] : ''}
            />
          </div>
        );
      })}
      {messageOff ? null : (
        <div className="field">
          <div className="lbl font">Message</div>
          <textarea
            type="text"
            placeholder="Your message"
            className="iput area font"
            onChange={(e) => {
              setInfo({ ...info, message: e.target.value });
            }}
            defaultValue={defaultMessage}
          />
        </div>
      )}
      <button onClick={onSubmit} className="send-msg-btn font">
        {isLoading ? 'Please wait...' : cta}
      </button>
    </div>
  );
};

export default ContactForm;
