import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AppSidebar from '../../components/AppSidebar';
import ChatBox from './ChatBox';

function Messenger(props) {
  const [nav, setNav] = useState([
    { label: 'All', slug: '/all' },
    { label: 'Affiliate', slug: '/affiliate' },
    { label: 'Communitys', slug: '/communitys' },
    { label: 'More', slug: '/more' },
  ]);
  const [friendsChat, SetFriendsChat] = useState([
    {
      name: 'Jhon king',
      lastMsg: 'Hey! how are you.',
      stamp: '5 min',
      pic: 'https://placeimg.com/350/350/people?1',
    },
    {
      name: 'Marina Bar',
      lastMsg: 'Hey! how are you.',
      stamp: '1 hour',
      pic: 'https://placeimg.com/350/350/people?2',
    },
    {
      name: 'Denny Sing',
      lastMsg: 'Hey! how are you.',
      stamp: '10/2/2021',
      pic: 'https://placeimg.com/350/350/people?3',
    },
    {
      name: 'Jhon king',
      lastMsg: 'Hey! how are you.',
      stamp: '5 min',
      pic: 'https://placeimg.com/350/350/people?1',
    },
    {
      name: 'Marina Bar',
      lastMsg: 'Hey! how are you.',
      stamp: '1 hour',
      pic: 'https://placeimg.com/350/350/people?2',
    },
    {
      name: 'Denny Sing',
      lastMsg: 'Hey! how are you.',
      stamp: '10/2/2021',
      pic: 'https://placeimg.com/350/350/people?3',
    },
    {
      name: 'Marina Bar',
      lastMsg: 'Hey! how are you.',
      stamp: '1 hour',
      pic: 'https://placeimg.com/350/350/people?2',
    },
    {
      name: 'Denny Sing',
      lastMsg: 'Hey! how are you.',
      stamp: '10/2/2021',
      pic: 'https://placeimg.com/350/350/people?5',
    },
    {
      name: 'Denny Sing',
      lastMsg: 'Hey! how are you.',
      stamp: '10/2/2021',
      pic: 'https://placeimg.com/350/350/people?6',
    },
    {
      name: 'Denny Sing',
      lastMsg: 'Hey! how are you.',
      stamp: '10/2/2021',
      pic: 'https://placeimg.com/350/350/people?7',
    },
    {
      name: 'Denny Sing',
      lastMsg: 'Hey! how are you.',
      stamp: '10/2/2021',
      pic: 'https://placeimg.com/350/350/people?8',
    },
    {
      name: 'Denny Sing',
      lastMsg: 'Hey! how are you.',
      stamp: '10/2/2021',
      pic: 'https://placeimg.com/350/350/people?9',
    },
  ]);
  const [selectChat, setSelectChat] = useState(false);

  const { chatbox } = useSelector((state) => state.generalReducers);
  const _dispatch = useDispatch();

  return (
    <>
      <AppSidebar />
      <div className="messenger-page sidebar-space rel">
        <div className="wrap flex abs">
          {/* Side bar */}
          <div
            className={`messenger-side-blk flex flex-col ${
              chatbox == true && 'hidden'
            }`}
          >
            <div className="head flex flex-col sticky">
              <div className="hdr flex">
                <div className="search-bar flex aic anim">
                  <div className="icon icon-search s18 c555" />
                  <input
                    type="text"
                    placeholder="Search Chat"
                    className="iput font s13 black"
                  />
                </div>
                <button className="cleanbtn notic icon-plus s18 black anim" />
              </div>
              <div className="nav flex aic">
                {nav.map((item) => (
                  <Link to={item.slug} className="lin font s13 black rel">
                    {item.label}
                  </Link>
                ))}
              </div>
            </div>
            <div className="frd-list flex flex-col">
              {friendsChat.map((item) => (
                <label
                  className="item flex"
                  onClick={() => {
                    setSelectChat(true);
                    _dispatch({ type: 'CHAT_BOX', payload: true });
                  }}
                >
                  <div className="lef flex aic">
                    <div className="pic">
                      <div
                        className="img"
                        style={{ backgroundImage: `url(${item.pic})` }}
                      />
                    </div>
                    <div className="meta flex flex-col">
                      <div className="txt font s14 b6 black">{item.name}</div>
                      <div className="txt font s12 gray">{item.lastMsg}</div>
                    </div>
                  </div>
                  <div className="rig flex">
                    <div className="stamp font s12 gray">{item.stamp}</div>
                  </div>
                </label>
              ))}
            </div>
          </div>

          {/* Center block / Chat box */}
          <div className={`center-blk ${chatbox && 'display'}`}>
            {selectChat == false ? (
              <div className="empty-section-chat flex flex-col aic">
                <img
                  src={require('../../assets/empty-chat.svg').default}
                  className="image"
                />
                <div className="lbl font s24 b7 c222">
                  Select a Conversation
                </div>
                <div className="txt font s15 b3 c222">
                  Try selecting a conversation or searching for someone
                  specific.
                </div>
              </div>
            ) : (
              <ChatBox />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Messenger;
