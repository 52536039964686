import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import {
  SidebarAnalyticsIcon,
  SidebarBillingIcon,
  // SidebarChatIcon,
  SidebarReviewsIcon,
  SidebarDashboardIcon,
  SidebarHomeIcon,
} from '../assets/svg';
import firebase from '../utils/base';
import AnimatedDialog from './Reusable/AnimatedDialog';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
function AppSidebar({ videosTab, subMenus, children }) {
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [role, setRole] = useState('');
  const [openMinSidebar, setOpenMinSidebar] = useState(false);
  const [openSignOutDialog, setOpenSignOutDialog] = useState(false);
  const history = useHistory();

  const nav = [
    {
      label: 'Home',
      slug: '/dashboard',
      icon: <SidebarDashboardIcon />,
    },

    {
      label: 'Community',
      slug: '/tour',
      icon: <SidebarHomeIcon />,
    },
    {
      label: 'Reviews',
      slug: '/reviews',
      icon: <SidebarReviewsIcon />,
    },
    {
      label: 'Portfolio Analytics',
      slug: '/analytics',
      icon: <SidebarAnalyticsIcon />,
    },
    // {
    //   label: 'Portfolio Billing',
    //   slug: '/billing',
    //   icon: <SidebarBillingIcon />,
    // },
    // {
    //   label: 'Messages',
    //   slug: '/messages',
    //   icon: <SidebarChatIcon />,
    // },
    // {
    //   label: 'Need Help',
    //   slug: '/help',
    //   icon: <span className="icon-support s19" />,
    // },
  ];

  const loadName = async (uid) => {
    try {
      const doc = await firebase.db.collection('users').doc(uid).get();

      if (doc.exists) {
        console.log('doc...', doc.data());
        setName(doc.data().name);
        setImage(doc.data().image);
        setRole(doc.data().role);
      }
    } catch (err) {
      console.log('Error getting document:', err);
    }
  };

  useEffect(() => {
    loadName(firebase.uid());
  }, [firebase.uid()]);

  useEffect(() => {
    if (openMinSidebar) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    document.body.addEventListener('click', () => {
      setOpenMinSidebar(false);
    });
  }, [openMinSidebar]);

  const handleCloseSignOutDialog = () => {
    setOpenSignOutDialog(false);
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const onContinue = async () => {
    await delay(1000);
    firebase.signOut();
    await delay(1000);

    history.push('/');
    history.go();
  };

  const location = useLocation();

  return (
    <>
      <div
        className={`sidebar-overlay anim ${openMinSidebar ? 'sho' : 'hid'}`}
      />
      <div
        className={`app-sidebar anim ${subMenus ? 'sub-menus-sidebar' : ''} ${
          openMinSidebar ? 'open' : ''
        }`}
      >
        <div className="wrap flex flex-col aic">
          <Link to="/dashboard">
            <img
              className="sidebar-logo"
              src={require('../assets/logo-without-text.svg').default}
            />
          </Link>
          <div className="nav flex flex-col aic">
            {nav.map((item, idx) => {
              if (item.slug === '/tour' || item.slug === '/reviews') {
                const isCurrentPage = location.pathname.includes(item.slug);
                const shouldDisplay =
                  location.pathname.includes('/tour') ||
                  location.pathname.includes('/reviews');

                const targetRoute = `${location.pathname
                  .split('/')
                  .slice(0, 3)
                  .join('/')}${item.slug}`;
                return (
                  <NavLink
                    key={idx}
                    to={targetRoute}
                    disabled={isCurrentPage}
                    className={`cleanbtn lin ${
                      shouldDisplay ? 'display' : 'hidden'
                    }`}
                  >
                    <div className={`ico rel ${item.label}`}>
                      {item.icon}
                      <div className="sidebar-tooltip font s14 b3">
                        {item.label}
                      </div>
                    </div>
                  </NavLink>
                );
              }
              return (
                <NavLink
                  key={idx}
                  to={item.slug}
                  className={`lin ${item.display}`}
                  exact
                >
                  <div className={`ico rel ${item.label}`}>
                    {item.icon}
                    <div className="sidebar-tooltip font s14 b3">
                      {item.label}
                    </div>
                  </div>
                </NavLink>
              );
            })}
            <button
              className={`cleanbtn lin`}
              onClick={() => setOpenSignOutDialog(true)}
            >
              <div className={`ico rel`}>
                <span className="icon-log-out s18" />
                <div className="sidebar-tooltip font s14 b3">Sign out</div>
              </div>
            </button>
          </div>
          <NavLink to="/settings" className="user rel">
            <div
              className="user-dp"
              style={{
                backgroundImage: `url(${
                  image ? image : `${require('../assets/ava.png').default}`
                })`,
              }}
            />
            {name && (
              <div className="sidebar-tooltip font s14 b3 flex flex-col">
                <span>{name}</span>
                <span>{role}</span>
              </div>
            )}
          </NavLink>
        </div>
      </div>

      {/* Sub Menu Sidebar */}
      <div
        className={`sub-menu-sidebar anim  ${
          openMinSidebar ? 'open' : 'close'
        } ${subMenus ? 'sho' : 'hid'}`}
      >
        <div className="wrap flex flex-col aic">{children}</div>
      </div>

      {/* Min Screen App Sidebar */}
      <div className="min-screen-sidebar flex aic anim lg:hidden">
        <div className="wrap flex aic">
          <div className="left flex aic">
            <Link to="/dashboard">
              <img
                className="sidebar-logo"
                src={require('../assets/logo-without-text.svg').default}
              />
            </Link>
          </div>
          <div className="right flex aic">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setOpenMinSidebar(!openMinSidebar);
              }}
              className="cleanbtn menu-btn icon-menu black s26"
            />
          </div>
        </div>
      </div>

      {/* Sign out  Dialogbox */}
      <AnimatedDialog
        open={openSignOutDialog}
        onClose={handleCloseSignOutDialog}
        fullWidth
        maxWidth="xs"
      >
        <div className="add-category-dialog">
          <div className="hdr flex aic">
            <div className="title font s18 b6 c000">Sign out</div>
            <button
              className="cross-btn cleanbtn fontr"
              onClick={handleCloseSignOutDialog}
            >
              &times;
            </button>
          </div>
          <div className="wrap flex flex-col">
            <div className="font s15 black">
              Are you sure you want to sign out?
            </div>
          </div>
          <div className="ftr flex aic">
            <button
              onClick={onContinue}
              className="cleanbtn add-cata font s14 b5 cfff"
            >
              Log Me Out
            </button>
            <button
              onClick={handleCloseSignOutDialog}
              className="cleanbtn add-cata cancel font s14 b5 c333"
            >
              Cancel
            </button>
          </div>
        </div>
      </AnimatedDialog>
    </>
  );
}

export default AppSidebar;
