import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import React from 'react';
import { useSelector } from 'react-redux';
import PreviewVideoMagnet from './PreviewVideoMagnet';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MagnetPopup({
  videoPath,
  screenTitle = '',
  videoImg = '',
}) {
  const { template } = useSelector((state) => state.getMagnet);

  const [category, screen] = videoPath.split('.');
  const screenObj = template?.categories[category]?.screens[screen];

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { magnetId } = useSelector((state) => state.magnetStates);

  return (
    <div>
      <Button variant="text" color="secondary" onClick={handleClickOpen}>
        <img
          src={videoImg}
          className="object-cover w-10 h-10 rounded-lg mr-2"
        />{' '}
        {screenTitle.length > 30
          ? screenTitle.substring(0, 30) + '...'
          : screenTitle}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth={'l'}
        PaperProps={{
          style: {
            height: '95vh',
            width: '80vw',
            borderRadius: 13,
            overflow: 'hidden',
          },
        }}
      >
        {/* If we render when not open, then the video starts playing in the background */}
        {open && (
          <PreviewVideoMagnet
            uuid={magnetId}
            template={template}
            screen={screen}
            category={category}
            inline={false}
            singleScreen={false}
            onClose={handleClose}
          />
        )}
      </Dialog>
    </div>
  );
}
