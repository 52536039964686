import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { IoIosArrowDown } from 'react-icons/io';
const Customchart = ({
  Heading,
  showDropdown,
  setDropdown,
  index,
  query,
  setdays,
}) => {
  const [cardChart1Data, setcardChart1Data] = useState('');

  const [daysfilter, setdaysfilter] = useState([
    {
      title: 'Last 7 days',
      value: 7,
    },
    {
      title: 'Last 30 Days',
      value: 30,
    },
    {
      title: 'Last 3 Month',
      value: 90,
    },
  ]);
  const [selectedfitler, setselectedfilter] = useState({
    title: 'Last 3 Month',
    value: 90,
  });
  const [total, settotal] = useState('');
  const get90daysanalyicsbigquery = async (magnetuuid) => {
    try {
      const { data } = await axios.get(
        'https://api.leasemagnets.com/run_sqlquery_inbigquery?querystring=' +
          query
      );
      console.log('goot data', data);
      let series = [];
      let labels = [];
      let total = 0;
      data.res.map((item) => {
        series.push(item[1]);
        labels.push(item[0]);
        total = total + item[1];
      });
      settotal(total);
      console.log('converted', { labels }, { series });
      setcardChart1Data({
        series: [
          {
            name: 'Tours',
            data: series,
          },
        ],
        height: '100%',
        type: 'area',
        options: {
          chart: {
            sparkline: {
              enabled: true,
            },
          },
          xaxis: {
            type: 'datetime',
          },
          labels,
          //colors: [colors.blue],
          fill: {
            opacity: 0.16,
          },
          sparkline: {
            enabled: true,
          },
        },
      });
    } catch (error) {
      console.log('errror', error);
    }
  };
  useEffect(() => {
    get90daysanalyicsbigquery();
  }, [selectedfitler]);

  // const [showDropdown, setShowDropdown] = useState(false);
  return (
    <>
      {cardChart1Data && (
        <div className=" bg-white border rounded-sm relative h-36">
          <div className=" w-full flex items-start justify-between p-2">
            <div>
              <h2 className="text-sm w-28">{Heading}</h2>
              <h1 className=" text-2xl font-semibold text-gray-700">{total}</h1>
            </div>
            <div className=" relative">
              <h2
                onClick={setDropdown}
                className=" text-sm cursor-pointer flex items-center text-pr"
              >
                {selectedfitler.title} <IoIosArrowDown />
              </h2>
              {showDropdown && (
                <div className=" absolute z-20 top-5 -left-16 border shadow-lg w-36 bg-white flex flex-col divide-y">
                  {daysfilter.map((item) => (
                    <div
                      onClick={() => {
                        console.log('butttonnclicked');
                        setselectedfilter(item);
                        setDropdown();
                        setdays(item.value);
                      }}
                      className=" w-full text-xs py-2 hover:bg-gray-50 cursor-pointer px-2"
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className=" h-16 absolute z-10 bottom-0 w-full">
            <Chart {...cardChart1Data} />
          </div>
        </div>
      )}
    </>
  );
};

export default Customchart;
