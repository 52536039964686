const initState = {
  showVideoDragging: true,
  buttonDragging: false,
  showScreenRecording: false,
  chatbox: false,
  minSidebar: false,
};

const generalReducers = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SHOW_VIDEO_DRAGGING':
      return { ...state, showVideoDragging: payload };
    case 'BUTTON_DRAGGING':
      return { ...state, buttonDragging: payload };
    case 'SHOW_SCREEN_RECORDING':
      return { ...state, showScreenRecording: payload };
    case 'CHAT_BOX':
      return { ...state, chatbox: action.payload };
    case 'MIN_SIDEBAR':
      return { ...state, minSidebar: action.payload };
    default:
      return state;
  }
};

export default generalReducers;
