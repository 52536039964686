import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ContrastVideoPlayer.css'; // Import the CSS file for styling

const ContrastVideoPlayer = () => {
  const { v } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const iframe = document.getElementById('videoIframe');
    if (iframe) {
      iframe.onload = () => {
        setIsLoading(false);
      };
    }
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        backgroundColor: 'white',
      }}
    >
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-circle"></div>
        </div>
      )}
      <iframe
        id="videoIframe"
        src={`https://player.getcontrast.io/watch?v=${v}`}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        title="Tour video recording player"
        frameBorder="0"
        allow="encrypted-media; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default ContrastVideoPlayer;
