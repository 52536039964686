export const widgets = {
  ranges: {
    visitors: 'Visitor',
    tours: 'Tours',
    leads: 'Leads',
  },
  series: {
    leads: [
      {
        name: 'Leads',
        data: [
          {
            x: '2021-02-22T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-02-25T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-02-28T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-03-03T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-03-06T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-03-31T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-04-03T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-04-06T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-04-25T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-04-28T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-05-01T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-05-04T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-05-29T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-06-01T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-06-04T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-07-03T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-07-25T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-07-28T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-07-31T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-08-03T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-08-28T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-08-31T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-09-03T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-09-29T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-10-02T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-10-05T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-10-30T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-11-02T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-11-28T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2022-01-13T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2022-01-26T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2022-01-29T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2022-02-01T09:21:32.664Z',
            y: 0,
          },
        ],
      },
    ],
    visitors: [
      {
        name: 'Visitors',
        data: [
          {
            x: '2021-02-22T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-02-25T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-02-28T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-03-03T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-03-06T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-03-31T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-04-03T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-04-06T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-04-25T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-04-28T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-05-01T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-05-04T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-05-29T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-06-01T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-06-04T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-07-03T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-07-25T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-07-28T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-07-31T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-08-03T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-08-28T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-08-31T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-09-03T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-09-29T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-10-02T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-10-05T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-10-30T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-11-02T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-11-28T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2022-01-13T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2022-01-26T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2022-01-29T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2022-02-01T09:21:32.664Z',
            y: 0,
          },
        ],
      },
    ],
    tours: [
      {
        name: 'Tours',
        data: [
          {
            x: '2021-02-22T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-02-25T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-02-28T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-03-03T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-03-06T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-03-31T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-04-03T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-04-06T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-04-25T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-04-28T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-05-01T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-05-04T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-05-29T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-06-01T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-06-04T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-07-03T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-07-25T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-07-28T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-07-31T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-08-03T09:21:32.663Z',
            y: 0,
          },
          {
            x: '2021-08-28T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-08-31T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-09-03T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-09-29T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-10-02T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-10-05T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-10-30T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-11-02T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2021-11-28T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2022-01-13T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2022-01-26T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2022-01-29T09:21:32.664Z',
            y: 0,
          },
          {
            x: '2022-02-01T09:21:32.664Z',
            y: 0,
          },
        ],
      },
    ],
  },
  conversions: {
    amount: 4123,
    labels: [
      '05 Jan - 12 Jan',
      '13 Jan - 20 Jan',
      '21 Jan - 28 Jan',
      '29 Jan - 05 Feb',
      '06 Feb - 13 Feb',
      '14 Feb - 21 Feb',
    ],
    series: [
      {
        name: 'Conversions',
        data: [4412, 4345, 4541, 4677, 4322, 4123],
      },
    ],
  },
  impressions: {
    amount: 46085,
    labels: [
      '21 Jan - 28 Jan',
      '29 Jan - 05 Feb',
      '06 Feb - 13 Feb',
      '14 Feb - 21 Feb',
    ],
    series: [
      {
        name: 'Impressions',
        data: [11577, 11441, 11544, 11523],
      },
    ],
  },
  visits: {
    amount: 62083,
    labels: [
      '21 Jan - 28 Jan',
      '29 Jan - 05 Feb',
      '06 Feb - 13 Feb',
      '14 Feb - 21 Feb',
    ],
    series: [
      {
        name: 'Visits',
        data: [15521, 15519, 15522, 15521],
      },
    ],
  },
  visitorsVsPageViews: {
    overallScore: 472,
    averageRatio: 45,
    predictedRatio: 55,
    series: [
      {
        name: 'Page Views',
        data: [
          {
            x: '2021-12-18T09:21:32.665Z',
            y: 4769,
          },
          {
            x: '2021-12-19T09:21:32.665Z',
            y: 4901,
          },
          {
            x: '2021-12-20T09:21:32.665Z',
            y: 4640,
          },
          {
            x: '2021-12-21T09:21:32.665Z',
            y: 5128,
          },
          {
            x: '2021-12-22T09:21:32.665Z',
            y: 5015,
          },
          {
            x: '2021-12-23T09:21:32.665Z',
            y: 5360,
          },
          {
            x: '2021-12-24T09:21:32.665Z',
            y: 5608,
          },
          {
            x: '2021-12-25T09:21:32.665Z',
            y: 5272,
          },
          {
            x: '2021-12-26T09:21:32.665Z',
            y: 5660,
          },
          {
            x: '2021-12-27T09:21:32.665Z',
            y: 6026,
          },
          {
            x: '2021-12-28T09:21:32.665Z',
            y: 5836,
          },
          {
            x: '2021-12-29T09:21:32.665Z',
            y: 5659,
          },
          {
            x: '2021-12-30T09:21:32.665Z',
            y: 5575,
          },
          {
            x: '2021-12-31T09:21:32.665Z',
            y: 5474,
          },
          {
            x: '2022-01-01T09:21:32.665Z',
            y: 5427,
          },
          {
            x: '2022-01-02T09:21:32.665Z',
            y: 5865,
          },
          {
            x: '2022-01-03T09:21:32.665Z',
            y: 5700,
          },
          {
            x: '2022-01-04T09:21:32.665Z',
            y: 6052,
          },
          {
            x: '2022-01-05T09:21:32.665Z',
            y: 5760,
          },
          {
            x: '2022-01-06T09:21:32.665Z',
            y: 5648,
          },
          {
            x: '2022-01-07T09:21:32.665Z',
            y: 5510,
          },
          {
            x: '2022-01-08T09:21:32.665Z',
            y: 5435,
          },
          {
            x: '2022-01-09T09:21:32.665Z',
            y: 5239,
          },
          {
            x: '2022-01-10T09:21:32.665Z',
            y: 5452,
          },
          {
            x: '2022-01-11T09:21:32.665Z',
            y: 5416,
          },
          {
            x: '2022-01-12T09:21:32.665Z',
            y: 5195,
          },
          {
            x: '2022-01-13T09:21:32.665Z',
            y: 5119,
          },
          {
            x: '2022-01-14T09:21:32.665Z',
            y: 4635,
          },
          {
            x: '2022-01-15T09:21:32.665Z',
            y: 4833,
          },
          {
            x: '2022-01-16T09:21:32.665Z',
            y: 4584,
          },
          {
            x: '2022-01-17T09:21:32.665Z',
            y: 4822,
          },
          {
            x: '2022-01-18T09:21:32.665Z',
            y: 4330,
          },
          {
            x: '2022-01-19T09:21:32.665Z',
            y: 4582,
          },
          {
            x: '2022-01-20T09:21:32.665Z',
            y: 4348,
          },
          {
            x: '2022-01-21T09:21:32.665Z',
            y: 4132,
          },
          {
            x: '2022-01-22T09:21:32.665Z',
            y: 4099,
          },
          {
            x: '2022-01-23T09:21:32.665Z',
            y: 3849,
          },
          {
            x: '2022-01-24T09:21:32.665Z',
            y: 4010,
          },
          {
            x: '2022-01-25T09:21:32.665Z',
            y: 4486,
          },
          {
            x: '2022-01-26T09:21:32.665Z',
            y: 4403,
          },
          {
            x: '2022-01-27T09:21:32.665Z',
            y: 4141,
          },
          {
            x: '2022-01-28T09:21:32.665Z',
            y: 3780,
          },
          {
            x: '2022-01-29T09:21:32.665Z',
            y: 3929,
          },
          {
            x: '2022-01-30T09:21:32.665Z',
            y: 3524,
          },
          {
            x: '2022-01-31T09:21:32.665Z',
            y: 3212,
          },
          {
            x: '2022-02-01T09:21:32.665Z',
            y: 3568,
          },
          {
            x: '2022-02-02T09:21:32.665Z',
            y: 3800,
          },
          {
            x: '2022-02-03T09:21:32.665Z',
            y: 3796,
          },
          {
            x: '2022-02-04T09:21:32.665Z',
            y: 3870,
          },
          {
            x: '2022-02-05T09:21:32.665Z',
            y: 3745,
          },
          {
            x: '2022-02-06T09:21:32.665Z',
            y: 3751,
          },
          {
            x: '2022-02-07T09:21:32.665Z',
            y: 3310,
          },
          {
            x: '2022-02-08T09:21:32.665Z',
            y: 3509,
          },
          {
            x: '2022-02-09T09:21:32.665Z',
            y: 3311,
          },
          {
            x: '2022-02-10T09:21:32.665Z',
            y: 3187,
          },
          {
            x: '2022-02-11T09:21:32.665Z',
            y: 2918,
          },
          {
            x: '2022-02-12T09:21:32.665Z',
            y: 3191,
          },
          {
            x: '2022-02-13T09:21:32.665Z',
            y: 3437,
          },
          {
            x: '2022-02-14T09:21:32.665Z',
            y: 3291,
          },
          {
            x: '2022-02-15T09:21:32.665Z',
            y: 3317,
          },
          {
            x: '2022-02-16T09:21:32.665Z',
            y: 3716,
          },
          {
            x: '2022-02-17T09:21:32.665Z',
            y: 3260,
          },
          {
            x: '2022-02-18T09:21:32.665Z',
            y: 3694,
          },
          {
            x: '2022-02-19T09:21:32.665Z',
            y: 3598,
          },
          {
            x: '2022-02-20T09:21:32.665Z',
            y: 3812,
          },
        ],
      },
      {
        name: 'Visitors',
        data: [
          {
            x: '2021-12-18T09:21:32.665Z',
            y: 1654,
          },
          {
            x: '2021-12-19T09:21:32.665Z',
            y: 1900,
          },
          {
            x: '2021-12-20T09:21:32.665Z',
            y: 1647,
          },
          {
            x: '2021-12-21T09:21:32.665Z',
            y: 1315,
          },
          {
            x: '2021-12-22T09:21:32.665Z',
            y: 1807,
          },
          {
            x: '2021-12-23T09:21:32.665Z',
            y: 1793,
          },
          {
            x: '2021-12-24T09:21:32.665Z',
            y: 1892,
          },
          {
            x: '2021-12-25T09:21:32.665Z',
            y: 1846,
          },
          {
            x: '2021-12-26T09:21:32.665Z',
            y: 1966,
          },
          {
            x: '2021-12-27T09:21:32.665Z',
            y: 1804,
          },
          {
            x: '2021-12-28T09:21:32.665Z',
            y: 1778,
          },
          {
            x: '2021-12-29T09:21:32.665Z',
            y: 2015,
          },
          {
            x: '2021-12-30T09:21:32.665Z',
            y: 1892,
          },
          {
            x: '2021-12-31T09:21:32.665Z',
            y: 1708,
          },
          {
            x: '2022-01-01T09:21:32.665Z',
            y: 1711,
          },
          {
            x: '2022-01-02T09:21:32.665Z',
            y: 1570,
          },
          {
            x: '2022-01-03T09:21:32.666Z',
            y: 1507,
          },
          {
            x: '2022-01-04T09:21:32.666Z',
            y: 1451,
          },
          {
            x: '2022-01-05T09:21:32.666Z',
            y: 1522,
          },
          {
            x: '2022-01-06T09:21:32.666Z',
            y: 1801,
          },
          {
            x: '2022-01-07T09:21:32.666Z',
            y: 1977,
          },
          {
            x: '2022-01-08T09:21:32.666Z',
            y: 2367,
          },
          {
            x: '2022-01-09T09:21:32.666Z',
            y: 2798,
          },
          {
            x: '2022-01-10T09:21:32.666Z',
            y: 3080,
          },
          {
            x: '2022-01-11T09:21:32.666Z',
            y: 2856,
          },
          {
            x: '2022-01-12T09:21:32.666Z',
            y: 2745,
          },
          {
            x: '2022-01-13T09:21:32.666Z',
            y: 2750,
          },
          {
            x: '2022-01-14T09:21:32.666Z',
            y: 2728,
          },
          {
            x: '2022-01-15T09:21:32.666Z',
            y: 2436,
          },
          {
            x: '2022-01-16T09:21:32.666Z',
            y: 2289,
          },
          {
            x: '2022-01-17T09:21:32.666Z',
            y: 2657,
          },
          {
            x: '2022-01-18T09:21:32.666Z',
            y: 2804,
          },
          {
            x: '2022-01-19T09:21:32.666Z',
            y: 2777,
          },
          {
            x: '2022-01-20T09:21:32.666Z',
            y: 3024,
          },
          {
            x: '2022-01-21T09:21:32.666Z',
            y: 2657,
          },
          {
            x: '2022-01-22T09:21:32.666Z',
            y: 2218,
          },
          {
            x: '2022-01-23T09:21:32.666Z',
            y: 1964,
          },
          {
            x: '2022-01-24T09:21:32.666Z',
            y: 1674,
          },
          {
            x: '2022-01-25T09:21:32.666Z',
            y: 1721,
          },
          {
            x: '2022-01-26T09:21:32.666Z',
            y: 2005,
          },
          {
            x: '2022-01-27T09:21:32.666Z',
            y: 1613,
          },
          {
            x: '2022-01-28T09:21:32.666Z',
            y: 1295,
          },
          {
            x: '2022-01-29T09:21:32.666Z',
            y: 1071,
          },
          {
            x: '2022-01-30T09:21:32.666Z',
            y: 799,
          },
          {
            x: '2022-01-31T09:21:32.666Z',
            y: 1133,
          },
          {
            x: '2022-02-01T09:21:32.666Z',
            y: 1536,
          },
          {
            x: '2022-02-02T09:21:32.666Z',
            y: 2016,
          },
          {
            x: '2022-02-03T09:21:32.666Z',
            y: 2256,
          },
          {
            x: '2022-02-04T09:21:32.666Z',
            y: 1934,
          },
          {
            x: '2022-02-05T09:21:32.666Z',
            y: 1832,
          },
          {
            x: '2022-02-06T09:21:32.666Z',
            y: 2075,
          },
          {
            x: '2022-02-07T09:21:32.666Z',
            y: 1709,
          },
          {
            x: '2022-02-08T09:21:32.666Z',
            y: 1932,
          },
          {
            x: '2022-02-09T09:21:32.666Z',
            y: 1831,
          },
          {
            x: '2022-02-10T09:21:32.666Z',
            y: 1434,
          },
          {
            x: '2022-02-11T09:21:32.666Z',
            y: 993,
          },
          {
            x: '2022-02-12T09:21:32.666Z',
            y: 1064,
          },
          {
            x: '2022-02-13T09:21:32.666Z',
            y: 618,
          },
          {
            x: '2022-02-14T09:21:32.666Z',
            y: 1032,
          },
          {
            x: '2022-02-15T09:21:32.666Z',
            y: 1280,
          },
          {
            x: '2022-02-16T09:21:32.666Z',
            y: 1344,
          },
          {
            x: '2022-02-17T09:21:32.666Z',
            y: 1835,
          },
          {
            x: '2022-02-18T09:21:32.666Z',
            y: 2287,
          },
          {
            x: '2022-02-19T09:21:32.666Z',
            y: 2226,
          },
          {
            x: '2022-02-20T09:21:32.666Z',
            y: 2692,
          },
        ],
      },
    ],
  },
  newVsReturning: {
    uniqueVisitors: 46085,
    series: [80, 20],
    labels: ['New', 'Returning'],
  },
  gender: {
    uniqueVisitors: 46085,
    series: [55, 45],
    labels: ['Male', 'Female'],
  },
  age: {
    uniqueVisitors: 46085,
    series: [35, 65],
    labels: ['Under 30', 'Over 30'],
  },
  language: {
    uniqueVisitors: 46085,
    series: [25, 75],
    labels: ['English', 'Other'],
  },
};

export const theme = {
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
    unit: 'px',
  },
  direction: 'ltr',
  components: {
    MuiDateTimePicker: {
      defaultProps: {
        PopperProps: {
          className: 'z-9999',
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        enableColorOnDark: true,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'text',
        color: 'inherit',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        sizeMedium: {
          borderRadius: 20,
          height: 40,
          minHeight: 40,
          maxHeight: 40,
        },
        sizeSmall: {
          borderRadius: '15px',
        },
        sizeLarge: {
          borderRadius: '28px',
        },
        contained: {
          boxShadow: 'none',
          '&:hover, &:focus': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        contained: {
          borderRadius: 18,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        rounded: {
          borderRadius: 16,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiSelect: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: 40,
          lineHeight: 1,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&:before, &:after': {
            display: 'none',
          },
        },
      },
    },
    MuiSlider: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiTypography: {
      variants: [
        {
          props: {
            color: 'text.secondary',
          },
          style: {
            color: 'text.secondary',
          },
        },
      ],
    },
  },
  palette: {
    mode: 'dark',
    text: {
      primary: 'rgb(255,255,255)',
      secondary: 'rgb(148, 163, 184)',
      disabled: 'rgb(156, 163, 175)',
      icon: 'rgba(255, 255, 255, 0.5)',
    },
    common: {
      black: 'rgb(17, 24, 39)',
      white: 'rgb(255, 255, 255)',
    },
    primary: {
      light: '#64748b',
      main: '#1e293b',
      dark: '#0f172a',
      contrastDefaultColor: 'light',
      contrastText: 'rgb(255,255,255)',
    },
    secondary: {
      light: '#818cf8',
      main: '#4f46e5',
      dark: '#3730a3',
      contrastText: 'rgb(255,255,255)',
    },
    background: {
      paper: '#1E2125',
      default: '#121212',
    },
    error: {
      light: '#ffcdd2',
      main: '#f44336',
      dark: '#b71c1c',
      contrastText: '#fff',
    },
    divider: 'rgba(241,245,249,.12)',
    warning: {
      main: '#ffa726',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      main: '#29b6f6',
      light: '#4fc3f7',
      dark: '#0288d1',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    success: {
      main: '#66bb6a',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#f5f5f5',
      A200: '#eeeeee',
      A400: '#bdbdbd',
      A700: '#616161',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    action: {
      active: '#fff',
      hover: 'rgba(255, 255, 255, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(255, 255, 255, 0.16)',
      selectedOpacity: 0.16,
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(255, 255, 255, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: 'Plus Jakarta Sans var,Roboto,"Helvetica",Arial,sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    htmlFontSize: 10,
    fontSize: 14,
    body1: {
      fontSize: '1.4rem',
      fontFamily: 'Plus Jakarta Sans var,Roboto,"Helvetica",Arial,sans-serif',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '1.4rem',
      fontFamily: 'Plus Jakarta Sans var,Roboto,"Helvetica",Arial,sans-serif',
      fontWeight: 400,
      lineHeight: 1.43,
    },
    fontWeightBold: 700,
    h1: {
      fontFamily: 'Plus Jakarta Sans var,Roboto,"Helvetica",Arial,sans-serif',
      fontWeight: 300,
      fontSize: '9.6rem',
      lineHeight: 1.167,
    },
    h2: {
      fontFamily: 'Plus Jakarta Sans var,Roboto,"Helvetica",Arial,sans-serif',
      fontWeight: 300,
      fontSize: '6rem',
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: 'Plus Jakarta Sans var,Roboto,"Helvetica",Arial,sans-serif',
      fontWeight: 400,
      fontSize: '4.8rem',
      lineHeight: 1.167,
    },
    h4: {
      fontFamily: 'Plus Jakarta Sans var,Roboto,"Helvetica",Arial,sans-serif',
      fontWeight: 400,
      fontSize: '3.4rem',
      lineHeight: 1.235,
    },
    h5: {
      fontFamily: 'Plus Jakarta Sans var,Roboto,"Helvetica",Arial,sans-serif',
      fontWeight: 400,
      fontSize: '2.4rem',
      lineHeight: 1.334,
    },
    h6: {
      fontFamily: 'Plus Jakarta Sans var,Roboto,"Helvetica",Arial,sans-serif',
      fontWeight: 500,
      fontSize: '2rem',
      lineHeight: 1.6,
    },
    subtitle1: {
      fontFamily: 'Plus Jakarta Sans var,Roboto,"Helvetica",Arial,sans-serif',
      fontWeight: 400,
      fontSize: '1.6rem',
      lineHeight: 1.75,
    },
    subtitle2: {
      fontFamily: 'Plus Jakarta Sans var,Roboto,"Helvetica",Arial,sans-serif',
      fontWeight: 500,
      fontSize: '1.4rem',
      lineHeight: 1.57,
    },
    button: {
      fontFamily: 'Plus Jakarta Sans var,Roboto,"Helvetica",Arial,sans-serif',
      fontWeight: 500,
      fontSize: '1.4rem',
      lineHeight: 1.75,
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: 'Plus Jakarta Sans var,Roboto,"Helvetica",Arial,sans-serif',
      fontWeight: 400,
      fontSize: '1.2rem',
      lineHeight: 1.66,
    },
    overline: {
      fontFamily: 'Plus Jakarta Sans var,Roboto,"Helvetica",Arial,sans-serif',
      fontWeight: 400,
      fontSize: '1.2rem',
      lineHeight: 2.66,
      textTransform: 'uppercase',
    },
  },
  status: {
    danger: 'orange',
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px)': {
        '@media (orientation: landscape)': {
          minHeight: 48,
        },
      },
      '@media (min-width:600px)': {
        minHeight: 64,
      },
    },
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    fab: 1050,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
};
