import React, { useContext, useEffect, useState } from 'react';
import {
  CopyClipboardIcon,
  CopyClipboardSuccessIcon,
  LinkIcon,
  LocationIcon,
  PlayIcon2,
} from './Icons';
import MagnetContext from './MagnetContext';
import ShareButtons from './ShareButtons';
import './ShareOverlay.css';
import { EMBED_HOST } from './util';

const ShareOverlay = ({
  location_card,
  title,
  shareInfo,
  onClickLink,
  setShowShare,
  links,
  type,
  backgroundWhite,
  extraPadding = 0,
}) => {
  const { uuid, currentCategory, currentScreen, startCategory, startScreen } =
    useContext(MagnetContext);
  const isStartScreen =
    currentCategory === startCategory && currentScreen === startScreen;

  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [shareCustomLink, setShareCustomLink] = useState(false);
  const [sharedUrl, setSharedUrl] = useState(shareInfo.url);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(sharedUrl);
    setCopiedToClipboard(true);
  };

  useEffect(() => {
    setSharedUrl(
      shareCustomLink || !shareInfo?.url
        ? `${EMBED_HOST}?uuid=${uuid}&screen=${startCategory}.${startScreen}&inline=true`
        : shareInfo.url
    );
    setCopiedToClipboard(false);
  }, [shareInfo, shareCustomLink, uuid, currentCategory, currentScreen]);

  if (type === 'chapter') {
    return (
      <div
        className="center-button-page"
        style={{
          backgroundImage: backgroundWhite
            ? 'linear-gradient(to bottom, #fff 0%, #C4C4C4 100%)'
            : 'linear-gradient(315deg, #66000000 0%, #000000 74%)',
          // background: 'linear-gradient(to bottom, #33000000 0%, #000000 100%)',
          // backgroundColor: "#537895",
          // backgroundImage: 'linear-gradient(315deg, #66000000 0%, #000000 74%)',
          // backgroundImage: 'linear-gradient(315deg, #CC537895 0%, #CC09203f 74%)'
        }}
      >
        <div
          className="title"
          style={{
            color: backgroundWhite ? '#000' : '#fff',
          }}
        >
          {title}
        </div>
        {/* <div
          className="tag"
          style={{
            color: backgroundWhite ? '#000' : '#fff',
          }}
        >
          <span className="icon">
            <LocationIcon />
          </span>
          <span>{location_card}</span>
        </div> */}
        <div className="buttons-grid" style={{ paddingBottom: extraPadding }}>
          {links.map((link, index) => {
            return (
              <div key={index} className="button-blk fade-in-up-center">
                <div
                  className="poster"
                  style={{
                    backgroundImage: link?.img
                      ? `url(${link.img})`
                      : `url(https://placeimg.com/640/480/tech?${index})`,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickLink(link);
                    if (!link?.href) {
                      setShowShare(false);
                    }
                  }}
                >
                  <div className="overlay">
                    <div className="video-title">{link?.name}</div>
                  </div>
                  <div className="play-btn">
                    {!link?.href ? <PlayIcon2 /> : <LinkIcon />}
                  </div>
                  {/* <div className="link-btn">
                <LinkIcon2 />
              </div> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="share-container" onClick={(e) => e.stopPropagation()}>
      <div className="share-title">Share this tour</div>
      <div className="share-link">
        <div className="share-link-default">
          <span className="share-link-text">{sharedUrl}</span>
          {navigator.clipboard && (
            <>
              <span className="vertical-divider" />
              {copiedToClipboard ? (
                <CopyClipboardSuccessIcon data-accent />
              ) : (
                <CopyClipboardIcon onClick={copyToClipboard} />
              )}
            </>
          )}
        </div>
        {/* Don't show custom share screen if we're on the start screen, since the start screen
            will already be shared using the regular link */}
        {!isStartScreen && shareInfo?.url && (
          <div
            className="share-link-custom"
            onClick={() => setShareCustomLink((prevLink) => !prevLink)}
          >
            <input
              type="checkbox"
              id="custom"
              name="custom"
              value="custom"
              checked={shareCustomLink}
              readOnly
            />
            <span className="share-link-caption">Share this screen</span>
          </div>
        )}
      </div>
      <ShareButtons shareInfo={shareInfo} url={sharedUrl} />
    </div>
  );
};

export default ShareOverlay;
