import React, { useState } from 'react';
import EditGoalModal, {
  calcNeededReviewsForGoal,
  displayStars,
} from './EditGoalModal';
import { TargetIcon } from '../../../assets/svg';
import { RiPencilLine } from 'react-icons/ri';

const VALID_REVIEW_RATINGS = [5, 4, 3, 2, 1];

export default function GoalSummary({
  reviewMetadata,
  setReviewMetadata,
  saveReviewMetadata,
}) {
  const [showGoalModal, setShowGoalModal] = useState(false);
  return (
    <div className="mt-2">
      <h4 className="font-bold text-2xl mb-2">Review & Goal Summary</h4>
      <button
        className=" flex items-center gap-1 cursor-pointer"
        onClick={() => setShowGoalModal(true)}
      >
        <div className=" h-5 text-pr">
          <TargetIcon />
        </div>
        <div>
          <div className="text-pr">
            {reviewMetadata?.goalRating ? (
              reviewMetadata?.goalRating < reviewMetadata?.rating ? (
                <div className="flex">
                  Congratulations, you reached your previous goal of{' '}
                  {reviewMetadata?.goalRating} stars! Set a new goal?
                  <RiPencilLine className=" text-gray-600 ml-1" />
                </div>
              ) : (
                <div className="flex">
                  Your next goal rating is {reviewMetadata?.goalRating} stars
                  (approx{' '}
                  {Math.ceil(
                    calcNeededReviewsForGoal(
                      reviewMetadata.reviews,
                      reviewMetadata.rating,
                      reviewMetadata.goalRating,
                      reviewMetadata.expectedRating
                    )
                  )}{' '}
                  reviews)
                  <RiPencilLine className=" text-gray-600 ml-1" />
                </div>
              )
            ) : (
              <div className="flex">
                Set your community goal
                <RiPencilLine className=" text-gray-600 ml-1" />
              </div>
            )}
          </div>
        </div>
      </button>

      {reviewMetadata?.goalRating &&
        reviewMetadata?.goalRating > reviewMetadata?.rating && (
          <ul>
            To reach this goal, you will need:
            {VALID_REVIEW_RATINGS.map((expectedRating, index) => {
              if (expectedRating < reviewMetadata.goalRating) {
                return <></>;
              } else {
                return (
                  <li key={expectedRating} className="ml-4">
                    {Math.ceil(
                      calcNeededReviewsForGoal(
                        reviewMetadata.reviews,
                        reviewMetadata.rating,
                        reviewMetadata.goalRating,
                        expectedRating
                      )
                    )}{' '}
                    new {displayStars(expectedRating)} reviews
                    {index != VALID_REVIEW_RATINGS.length - 1 ? ', or' : ''}
                  </li>
                );
              }
            })}
          </ul>
        )}
      <RatingDistribution reviewMetadata={reviewMetadata} />
      <EditGoalModal
        reviewMetadata={reviewMetadata}
        setReviewMetadata={setReviewMetadata}
        saveReviewMetadata={saveReviewMetadata}
        open={showGoalModal}
        setOpen={setShowGoalModal}
      />
    </div>
  );
}

function RatingDistribution({ reviewMetadata }) {
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  return (
    <div
      className="flex flex-col gap-2 w-full"
      onMouseLeave={() => setHoveredIndex(-1)}
    >
      <p className="text-lg">Current Rating Distribution:</p>
      {VALID_REVIEW_RATINGS.map((rating, index) => (
        <div
          className="w-full flex items-center gap-2"
          onMouseOver={() => setHoveredIndex(index)}
          key={index}
        >
          <span className="w-8">⭐{rating}</span>
          <div
            className={`${
              hoveredIndex === index ? 'bg-blue-400' : 'bg-blue-200'
            } rounded-full h-4 transition-colors`}
            style={{
              width: `${
                (reviewMetadata?.reviews_per_score?.[rating.toString()] /
                  reviewMetadata.reviews) *
                80
              }%`,
            }}
          ></div>
          <span
            className={`text-gray-500 transition-opacity ${
              hoveredIndex !== index && 'opacity-0'
            }`}
          >
            {reviewMetadata?.reviews_per_score?.[rating.toString()]}
          </span>
        </div>
      ))}
    </div>
  );
}
