import { Hidden, Typography } from '@material-ui/core';
import MatGrid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from '../../utils/base';
import { font } from '../../utils/constants';
import '../signin/signin.css';

const SignOut = () => {
  const history = useHistory();
  const [logoutMessage, setLogoutMessage] = useState(
    'Are you sure you want to sign out?'
  );

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const onContinue = async () => {
    setLogoutMessage('See you soon!');
    await delay(1000);
    firebase.signOut();
    await delay(1000);

    history.push('/');
    history.go();
  };

  return (
    <div className="SignOut">
      <a href="#">
        {' '}
        <img
          src="https://assets.website-files.com/60558f1b1e791c307943f510/609163d42e66b49539df4040_tour.video%20logo%20TYG%202%20.png"
          class="logo"
        />
      </a>

      <MatGrid
        container
        spacing={1}
        item
        justify="space-between"
        alignItems="center"
        direction="row"
        style={{ width: '70vw' }}
        classes={{ root: 'center_container' }}
      >
        <MatGrid
          container
          item
          xs={12}
          sm={5}
          justify="center"
          alignItems="center"
        >
          <img style={{ width: '70%' }} src="https://i.imgur.com/dueT3XY.png" />
          {/* <Typography variant="h5" align="center" style={{fontWeight: 900, fontFamily: font, marginTop: '20px'}}>
              Digitalize your best tour
            </Typography>
            <Typography variant="h5" align="center" style={{fontWeight: 900, fontFamily: font, marginTop: '5px'}}>
              <span class='blue-highlight'> & deliver it perfectly 24/7 </span>
            </Typography> */}
        </MatGrid>

        <MatGrid container item xs={12} sm={6}>
          <MatGrid container item xs={12} direction="column">
            <Hidden only="xs">
              <Typography
                variant="h4"
                style={{ color: '#262E37', fontWeight: 900, fontFamily: font }}
              >
                {logoutMessage}
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ color: '#262E37', fontFamily: font, marginTop: '5px' }}
              >
                We don't want to see you go.{' '}
              </Typography>{' '}
              <br />
            </Hidden>
            <Hidden smUp>
              <Typography
                variant="h6"
                align="center"
                style={{ color: '#262E37', fontWeight: 900, fontFamily: font }}
              >
                {logoutMessage}
              </Typography>
              <Typography
                variant="subtitle2"
                align="center"
                style={{ color: '#262E37', fontFamily: font, marginTop: '5px' }}
              >
                We don't want to see you go.{' '}
              </Typography>{' '}
              <br />
            </Hidden>
          </MatGrid>

          <MatGrid container item xs={12} justify="space-between">
            <button className="button signin w-button" onClick={onContinue}>
              Log Me Out
            </button>
            <button
              className="button create w-button"
              onClick={() => window.location.replace('/')}
            >
              Cancel
            </button>
          </MatGrid>
        </MatGrid>
      </MatGrid>
    </div>
  );
};

export default SignOut;
