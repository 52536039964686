import moment from 'moment';
/**
 * Gives one unit of accuracy for the difference between two moments in time
 * If the difference is less than an hour, showes both minutes and seconds
 *
 * @param {Moment} firstMoment - a moment.js object representing a time
 * @param {Moment} secondMoment - the later moment.js object representing a time
 * @returns
 */
export const getDurationString = (firstMoment, secondMoment) => {
  const duration = moment.duration(secondMoment.diff(firstMoment));
  if (duration.days()) {
    return `${Math.round(duration.asDays())} days`;
  } else if (duration.hours()) {
    return `${duration.hours()} hrs`;
  } else if (duration.minutes()) {
    return `${duration.minutes()} min, ${duration.seconds()} sec`;
  } else {
    return `${duration.seconds()} sec`;
  }
};
