//import queryString from 'query-string';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Analytics from './Analytics';

export default function ShareDashboard() {
  const history = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const values = {
    id: params.get('id'),
    analytics: params.get('analytics'),
    activity: params.get('activity'),
    name: params.get('name'),
  };

  if (!values.id || (!values.analytics && !values.activity)) {
    history.push('/');
  }

  console.log('data', values.analytics, values.activity);

  return (
    <>
      <Navbar />
      <div className="container apartment-page">
        <Analytics
          community_id={values.id}
          name={values.name}
          analytics={Boolean(values.analytics)}
          activity={Boolean(values.activity)}
        />
      </div>
    </>
  );
}
