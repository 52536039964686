import React from 'react';
import Slider from 'react-slick';
const Talk = () => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div
      style={{ background: 'rgba(97, 130, 245, 0.3)' }}
      className=" w-full py-10 mt-10"
    >
      <div className=" w-full flex items-center justify-center flex-col gap-3">
        <h1 className=" text-center text-2xl font-bold">
          We’re the talk of the town, <br />
          and for good reason!
        </h1>
        <div className=" w-full mt-10">
          <Slider {...settings}>
            <OneCard />

            <div>
              <OneCard />
            </div>
            <div>
              <OneCard />
            </div>
            <div>
              <OneCard />
            </div>
            <div>
              <OneCard />
            </div>
            <div>
              <OneCard />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

const OneCard = () => {
  return (
    <div className="p-10 bg-white rounded-md w-full h-full">
      <p className=" text-sm font-semibold">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. 🧑 Jane Doe Founder
        of Let’s Get It!
      </p>
      <div className=" mt-6 flex items-center gap-2">
        <h1 className=" text-3xl">🧑</h1>
        <div>
          <h6 className=" text-lg font-bold">Jane Doe</h6>
          <p className=" text-sm">Founder of Let’s Get It!</p>
        </div>
      </div>
    </div>
  );
};
export default Talk;
