import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { BsFillCalendarCheckFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { get } from '../../utils/request';
import { useParams } from 'react-router-dom';

function BookingSchedule(props) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedScheduleDate, setSelectedScheduleDate] = useState(new Date());
  const [step, setStep] = useState(1);
  const [viewSpecial, setViewSpecial] = useState(false);
  const [selectedTourType, setSelectedTourType] = useState('person');
  const [selectedBedroom, setSelectedBedroom] = useState(0);
  const [selectedBathroom, setSelectedBathroom] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [floorPlansJson, setfloorPlansJson] = useState({});
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [propertyinfo, setpropertyinfo] = useState('');
  const [allhours, setallhours] = useState([
    '12:00 AM',
    '1:00 AM',
    '2:00 AM',
    '3:00 AM',
    '4:00 AM',
    '5:00 AM',
    '6:00 AM',
    '7:00 AM',
    '8:00 AM',
    '9:00 AM',
    '10:00 AM',
    '11:00 AM',
    '12:00 PM',
    '1:00 PM',
    '2:00 PM',
    '3:00 PM',
    '4:00 PM',
    '5:00 PM',
    '6:00 PM',
    '7:00 PM',
    '8:00 PM',
    '9:00 PM',
    '10:00 PM',
    '11:00 PM',
  ]);
  const [availablepropertyhours, setavailablepropertyhours] = useState([]);
  const [userinfo, setuserinfo] = useState({});
  const { magnetId } = useParams();
  console.log('params', params);
  const getfloorplans = async (data) => {
    console.log('data', data);
    try {
      const res = await axios.post(
        `https://api.leasemagnets.com/integrations/entrata/getMitsPropertyUnits`,
        {
          creds: {
            username: data.username,
            password: data.password,
          },
          propertyId: data.property_id,
        }
      );
      console.log(res);
      setfloorPlansJson(res.data.resp);
      const special_res = await axios.post(
        `https://api.leasemagnets.com/integrations/entrata/getSpecials`,
        {
          creds: {
            username: data.username,
            password: data.password,
          },
          propertyId: data.property_id,
        }
      );
      console.log('special_res', special_res);
      // console.log(
      //   'thedata',
      //   res.data.resp?.result?.PhysicalProperty.Property[0].ILS_Unit
      // );
    } catch (err) {
      console.log(err);
    }
  };

  const getproperty = async (data) => {
    console.log('data', data);
    try {
      const res = await axios.post(
        `https://api.leasemagnets.com/integrations/entrata/getPropertyinfo`,
        {
          creds: {
            username: data.username,
            password: data.password,
          },
          propertyId: data.property_id,
        }
      );
      console.log('getproperty', res);
      setpropertyinfo(res?.data?.resp?.result?.PhysicalProperty?.Property[0]);
    } catch (err) {
      console.log(err);
    }
  };
  const [appoinmentloading, setappoinmentloading] = useState(false);
  const bookappointment = async (data, obj) => {
    //var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    console.log('data', data);
    if (
      !userinfo.firstname ||
      !userinfo.lastname ||
      !userinfo.email ||
      !userinfo.phone
    ) {
      return toast.error('Please fill all fields');
    } else if (userinfo.phone.length != 10) {
      return toast.error('phone number length should be 10');
    }
    setappoinmentloading(true);
    //return setStep(9)
    console.log('thedata', {
      creds: {
        username: data.username,
        password: data.password,
      },
      propertyId: data.property_id,
      ...obj,
    });
    //return
    try {
      const res = await axios.post(
        `https://api.leasemagnets.com/integrations/entrata/bookappointment`,
        {
          creds: {
            username: data.username,
            password: data.password,
          },
          propertyId: data.property_id,
          property_id: data.property_id,
          first_name: userinfo.firstname,
          last_name: userinfo.lastname,
          email: userinfo.email,
          phone: `+1${userinfo.phone}`,
          originating_source_id: data.originating_source_id,
          ...obj,
        }
      );

      console.log('the_response', res);
      if (
        res?.data?.response?.result?.prospects?.prospect[0]?.status == 'Success'
      ) {
        setStep(9);
        setappoinmentloading(false);
      } else if (
        res?.data?.response?.result?.prospects?.prospect[0]?.status == 'Failure'
      ) {
        return toast.error(
          res?.data?.response?.result?.prospects?.prospect[0]?.message
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const [entratadata, setentratadata] = useState({});
  const [selectedhour, setselectedhour] = useState('');
  const getentratadata = async () => {
    const res = await axios.get(
      `https://api.directual.com/good/api/v5/data/message/getAndCreateMessage?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&messageId=${params.integrationsId}`
    );
    console.log('res', res);
    if (res.data.payload[0].data) {
      getfloorplans(JSON.parse(res.data.payload[0].data));
      setentratadata(JSON.parse(res.data.payload[0].data));
      getproperty(JSON.parse(res.data.payload[0].data));
    }
  };
  useEffect(() => {
    getentratadata();
  }, []);
  const floorPlan = [
    {
      id: 1,
      title: 'How many bedrooms do you need?',
      options: [
        { label: 'Any', value: 0 },
        { label: 'Studio', value: 'studio' },
        { label: '1 Bed', value: 1 },
        { label: '2 Bed', value: 2 },
      ],
    },
    {
      id: 2,
      title: 'How many bathrooms do you need?',
      options: [
        { label: 'Any', value: 0 },
        { label: '1 Bath', value: 1 },
        { label: '2 Bath', value: 2 },
      ],
    },
  ];

  console.log('selectedDate', selectedDate);

  function handleSelected(value, id) {
    console.log('handle', value, id);
    if (id === 1) {
      setSelectedBedroom(value);
    } else if (id === 2) {
      setSelectedBathroom(value);
    }
    // else if(id == 0) {
    //   setSelectedBathroom(value);
    // }
  }
  let floorPlans = [];
  if (floorPlansJson?.result?.PhysicalProperty.Property[0].Floorplan) {
    floorPlans = floorPlansJson?.result?.PhysicalProperty.Property[0].Floorplan;
  }

  const concessions =
    floorPlansJson?.result?.PhysicalProperty.Property[0].Concession;
  const ilsUnits =
    floorPlansJson?.result?.PhysicalProperty.Property[0].ILS_Unit;

  const propertyInfo =
    floorPlansJson?.result?.PhysicalProperty.Property[0].PropertyID;

  console.log('floorplans', floorPlans);
  console.log('ilsUnits', ilsUnits);
  const floorPlanObj = floorPlans?.map((item, idx) => {
    const pic = item?.File?.map((file, fidx) => {
      if (file?.Src.includes('.jpg') || file?.Src.includes('.png')) {
        return file?.Src;
      }
      return null;
    }).filter((c) => c !== null);

    let roomTypeJson = {};
    item?.Room?.map((room, ridx) => {
      roomTypeJson[room?.['@attributes'].RoomType] = room?.Count;
    });

    let dynamicItems = ilsUnits
      ?.map((unit, uidx) => {
        if (item?.Name && item?.Name == unit?.Units.Unit.FloorplanName) {
          // console.log(
          //   "uidx: ",
          //   item?.Name,
          //   unit?.Units.Unit.FloorplanName,
          //   unit
          // );
          if (unit?.Availability?.VacancyClass == 'Unoccupied') {
            return unit?.Availability?.VacateDate['@attributes'];
          }
        }
        return null;
      })
      .filter((c) => c !== null);

    let itemObj = {
      name: item?.Name,
      rents: item?.MarketRent['@attributes'],
      unitsAvailable: item?.UnitsAvailable,
      sqFt: item?.SquareFeet['@attributes'],
      pic: pic?.[0],
      info: item?.FloorplanAvailabilityURL,
      rooms: roomTypeJson,
      available: dynamicItems?.[0],
    };
    console.log('items: ', itemObj);
    return itemObj;
  });
  console.log('selectedPlan', selectedPlan);
  const [message, setmessage] = useState(
    `I am interested in the Floor plans and looking to begin renting on ${selectedPlan?.available?.Month}-${selectedPlan?.available?.Day}-${selectedPlan?.available?.Year}`
  );
  useEffect(() => {
    setmessage(
      `I am interested in the Floor plans and looking to begin renting on ${selectedPlan?.available?.Month}-${selectedPlan?.available?.Day}-${selectedPlan?.available?.Year}`
    );
  }, [selectedPlan]);
  const [filtereditems, setfiltereditems] = useState([]);
  useEffect(() => {
    let filtered = floorPlanObj.filter((item, index) => {
      //console.log('item', item);
      /// check bedroom
      let bedroomstatus;
      let bathroomstatus;
      let datestatus;

      // console.log(
      //   'selecteduseffect',
      //   selectedBathroom,
      //   selectedBedroom,
      //   selectedDate
      // );

      // check bedroom
      if (selectedBedroom == 0) {
        bedroomstatus = true;
      } else if (item.rooms.Bedroom === selectedBedroom) {
        bedroomstatus = true;
      }
      // check bathroom
      if (selectedBathroom == 0) {
        bathroomstatus = true;
      } else if (item.rooms.Bathroom === selectedBathroom) {
        bathroomstatus = true;
      }
      // check datestatus
      if (item.available) {
        if (
          new Date(
            `${item.available.Month}-${item.available.Day}-${item.available.Year}`
          ).getTime() <= selectedDate.getTime()
        ) {
          datestatus = true;
        }
      } else {
        datestatus = false;
      }
      console.log('statusss', bedroomstatus, bathroomstatus, datestatus);
      return bedroomstatus && bathroomstatus && datestatus;
    });
    console.log('filtered', filtered);
    setfiltereditems(filtered);
  }, [selectedDate]);

  useEffect(() => {
    let weekdays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    let theday = '';
    if (selectedDate && propertyinfo) {
      console.log('selectedDate', selectedDate);
      theday = weekdays[new Date(selectedDate).getDay()];
      console.log('theday', theday);
      let selectedday = {};
      console.log('propertyinfo', propertyinfo);
      console.log('propertyinfo', propertyinfo?.PropertyHours?.OfficeHours);
      propertyinfo?.PropertyHours?.OfficeHours?.OfficeHour?.map((item) => {
        if (item.Day == theday) {
          selectedday = item;
        }
      });
      console.log('selectedday', selectedday);
      let availhours = [];
      let matched = false;
      allhours.map((item) => {
        if (selectedday?.OpenTime == item) {
          matched = true;
          availhours.push(item);
        } else if (matched) {
          availhours.push(item);
          if (item == selectedday?.CloseTime) {
            matched = false;
          }
        }
      });
      console.log('availhours', availhours);
      setavailablepropertyhours(availhours);
    }
  }, [selectedDate, propertyinfo]);

  return (
    <>
      <div className="booking-scheduler">
        <div
          className={
            step === 2
              ? 'scheduler-container w-full lg:w-3/6  flex flex-col'
              : 'scheduler-container w-full lg:w-1/3  flex flex-col'
          }
        >
          <div className="container-hdr">
            <div className="left">
              <button
                disabled={step <= 1}
                className="back-btn"
                onClick={() => {
                  if (!ilsUnits) {
                    if (step == 8) {
                      setStep(2);
                    } else if (step == 9) {
                      setStep(step - 1);
                    }
                  } else {
                    setStep(step - 1);
                  }
                }}
              >
                Go Back
              </button>
            </div>
            <div className="right">
              {/* <button
                className=" flex items-center justify-center bg-gray-100 px-2  rounded-full text-2xl"
                onClick={() => setStep(1)}
              >
                &times;
              </button> */}
            </div>
          </div>
          <div className="scheduler-content">
            {step === 1 && (
              <div className="step-1 flex flex-col">
                <div className="meta">
                  <div className="title font">Floor Plans</div>
                  <div className="txt font">
                    Help us match you with a floor plan
                  </div>
                </div>
                {floorPlan &&
                  floorPlan.map(({ title, id, options }) => (
                    <div className="block" key={id}>
                      <div className="label font s14 black">{title}</div>
                      <div className="options flex aic">
                        {options &&
                          options.map(({ label, value }, index) => (
                            <div
                              key={index}
                              onClick={() => handleSelected(value, id)}
                              className={`item font ${
                                id === 1
                                  ? selectedBedroom === value
                                    ? 'active'
                                    : ''
                                  : selectedBathroom === value
                                  ? 'active'
                                  : ''
                              }`}
                            >
                              {label}
                            </div>
                          ))}
                        {/* <div className="item font active">Any</div> */}
                      </div>
                    </div>
                  ))}

                <div className="block">
                  <div className="label font s14 black">
                    When is your desired move-in date?
                  </div>
                  <div className="calender" style={{ width: '382px' }}>
                    <Calendar
                      onChange={(value) => {
                        if (new Date(value).getTime() > new Date().getTime()) {
                          setSelectedDate(value);
                          setStep(2);
                        }
                        //setSelectedDate(value);
                      }}
                      value={selectedDate}
                    />
                  </div>
                </div>
              </div>
            )}
            {step === 2 && selectedDate && (
              <div className="step-2 flex flex-col">
                {ilsUnits ? (
                  <>
                    <div className="meta">
                      <div className="title font">Floor Plans</div>
                      <div className="txt font">Browse the matching</div>
                      <div className="txt-2 font">
                        <span className="b6">
                          {/* {item?.rooms?.Bedroom} Bed/{item?.rooms?.Bathroom} Bath */}
                        </span>{' '}
                        floor plans below.
                      </div>
                    </div>
                    <div className="plans-list">
                      {filtereditems?.map((item, index) => (
                        <div
                          key={index}
                          className="plane-card flex aic"
                          onClick={() => {
                            setStep(3);
                            setSelectedPlan(item);
                          }}
                        >
                          <div className="graphic">
                            <img src={item?.pic} className="img" />
                          </div>
                          <div className="content flex flex-col">
                            <div className="nam font s13 b5 color">
                              {item?.name}
                            </div>
                            <p>
                              {item?.available?.Month} - {item?.available?.Day}{' '}
                              - {item?.available?.Year}
                            </p>
                            <div className="txt font">
                              {item?.rooms?.Bedroom} Bed /{' '}
                              {item?.rooms?.Bathroom} Bath
                            </div>
                            <div className="txt font">
                              Start from ${item?.rents?.['Min']}
                            </div>
                            <div className="txt font b6">Special Available</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <div className=" grid-cols-1  gap-10 w-full hidden lg:flex">
                      <div className=" flex items-center flex-col">
                        <div
                          className=" w-full flex items-center justify-center "
                          style={{ width: '382px' }}
                        >
                          <Calendar
                            onChange={(value) => {
                              if (
                                new Date(value).getTime() > new Date().getTime()
                              ) {
                                setSelectedDate(value);
                              }
                              //setStep(2);
                            }}
                            value={selectedDate}
                          />
                        </div>

                        <button
                          onClick={() => {
                            if (!selectedhour) {
                              console.log('got here');
                              return toast(
                                "You have'nt selected time for the appointment"
                              );
                            } else {
                              setStep(8);
                            }
                          }}
                          className=" bg-pr w-full px-4 mt-4 text-sm py-3  rounded-md text-white cursor-pointer "
                        >
                          Next
                        </button>
                      </div>
                      <div className=" w-full">
                        <p className=" font-light">
                          {moment(selectedDate).format('MMMM Do YYYY')}
                        </p>
                        <div className=" mt-2 flex items-center flex-col gap-2 w-full h-96 overflow-y-scroll">
                          {availablepropertyhours.map((item) => (
                            <div
                              onClick={() => {
                                setselectedhour(item);
                              }}
                              style={{ border: '1px solid #006EDC' }}
                              className={` w-full flex items-center cursor-pointer justify-center py-2 font-medium text-pr hover:bg-blue-100 ${
                                selectedhour === item ? 'bg-blue-100' : ''
                              }`}
                            >
                              {item}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="grid-cols-1 w-full grid lg:hidden">
                      <h1 className=" w-full text-center text-3xl font-light">
                        Book an appointment
                      </h1>
                      <p className=" font-medium flex items-center gap-2 py-2 text-center justify-center w-full text-pr">
                        {' '}
                        <BsFillCalendarCheckFill /> June 15th, 2022
                      </p>
                      <div className=" grid grid-cols-3 gap-5 my-4">
                        {availablepropertyhours.map((item) => (
                          <div
                            onClick={() => {
                              setselectedhour(item);
                            }}
                            className={` w-full flex items-center cursor-pointer justify-center text-sm py-2  text-gray-500 bg-blue-50`}
                          >
                            {item}
                          </div>
                        ))}
                      </div>
                      <div className=" flex items-center justify-center ">
                        <button
                          onClick={() => setStep(step - 1)}
                          className=" text-pr font-medium"
                        >
                          Go back
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {step === 3 && selectedPlan && (
              <div className="step-3 flex flex-col">
                <div
                  className="graphic"
                  style={{
                    backgroundImage: `url(https://medialibrarycdn.entrata.com/media_library/2342/5b807d4ea6e9a8.34412431408.jpg)`,
                  }}
                />
                <div className="actions flex aic">
                  {viewSpecial ? (
                    <button
                      className="action-btn font"
                      onClick={() => setViewSpecial(false)}
                    >
                      View Details
                    </button>
                  ) : (
                    <button
                      className="action-btn font"
                      onClick={() => setViewSpecial(true)}
                    >
                      View Specials
                    </button>
                  )}
                </div>
                <div className="content flex flex-col">
                  {viewSpecial ? (
                    <>
                      <div className="name">Example special</div>
                      <div className="txt font">
                        Sign a lease today to get this special deal.
                      </div>
                      <button className="apply-btn">Apply Today</button>
                      <div className="stamp">Hurry, offer ends 12/31/2022</div>
                    </>
                  ) : (
                    <>
                      <div className="name">{selectedPlan?.name}</div>
                      <div className="txt font">
                        {selectedPlan?.rooms?.Bedroom} Bed /{' '}
                        {selectedPlan?.rooms?.Bedroom} Bath
                      </div>
                      <div className="txt font">
                        ${selectedPlan?.rents?.['Min']} - $
                        {selectedPlan?.rents?.['Max']}
                      </div>
                      <div className="txt font">
                        {' '}
                        {selectedPlan?.sqFt?.['Min']} -{' '}
                        {selectedPlan?.sqFt?.['Max']} Sq ft.
                      </div>
                      <div className="act-btns">
                        <div className="act-btn" onClick={() => setStep(4)}>
                          Apply
                        </div>
                        <div
                          className="act-btn transparent"
                          onClick={() => setStep(5)}
                        >
                          Schedule A Tour
                        </div>
                      </div>
                      <button className="send-msg-btn font">
                        Take a Virtual Tour
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
            {step === 4 && (
              <div className="step-4 flex flex-col">
                <div className="meta">
                  <div className="title font">Contact Us</div>
                  <div className="txt font">
                    Fill out the form below and we'll respond soon.
                  </div>
                  <div className="contact-form">
                    <div className="field">
                      <div className="lbl font">First Name</div>
                      <input
                        type="text"
                        placeholder="Your first name"
                        className="iput font"
                      />
                    </div>
                    <div className="field">
                      <div className="lbl font">Last Name</div>
                      <input
                        type="text"
                        placeholder="Your Last name"
                        className="iput font"
                      />
                    </div>
                    <div className="field">
                      <div className="lbl font">Email</div>
                      <input
                        type="text"
                        placeholder="Your email"
                        className="iput font"
                      />
                    </div>
                    <div className="field">
                      <div className="lbl font">Phone</div>
                      <input
                        type="text"
                        placeholder="Your phone number"
                        className="iput font"
                      />
                    </div>
                    <div className="field">
                      <div className="lbl font">Message</div>
                      <textarea
                        type="text"
                        placeholder="Your message"
                        className="iput area font"
                        value={message}
                      />
                    </div>
                    <button className="send-msg-btn font">
                      Schedule a Tour
                    </button>
                  </div>
                </div>
              </div>
            )}
            {step === 5 && (
              <div className="step-5 flex flex-col w-96">
                <div className="meta">
                  <div className="title font">Schedule A Tour</div>
                  <div className="txt font">Select your tour type </div>
                </div>
                <div className="actions flex aic">
                  <button
                    className={`action-btn font ${
                      selectedTourType === 'person' ? 'active' : ''
                    }`}
                    onClick={() => setSelectedTourType('person')}
                  >
                    In Person
                  </button>
                  <button
                    className={`action-btn font ${
                      selectedTourType === 'virtual' ? 'active' : ''
                    }`}
                    onClick={() => setSelectedTourType('virtual')}
                  >
                    Virtual
                  </button>
                </div>
                <div className="calender w-96">
                  <Calendar
                    onChange={(value) => {
                      setSelectedScheduleDate(value);
                      setStep(6);
                    }}
                    value={selectedScheduleDate}
                  />
                </div>
              </div>
            )}
            {step === 6 && (
              <div className="step-6 flex flex-col">
                <div className="meta">
                  <div className="title font">Schedule A Tour</div>
                  <div className="stamp flex aic color">
                    <div className="icon icon-calendar1" />
                    <div className="lbl font s14 b3">March 25th, 2022</div>
                  </div>
                </div>
                <div className="time-table">
                  <div className="tit font s14 black">Select a tour time</div>
                  <div className="time-list">
                    {[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].map(
                      (item, index) => (
                        <div
                          key={index}
                          className="time font"
                          onClick={() => setStep(7)}
                        >
                          9:30 am
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            )}
            {step === 7 && (
              <div className="step-4 flex flex-col">
                <div className="meta">
                  <div className="title font">Schedule A Tour</div>
                  <div className="stamp flex aic color">
                    <div className="icon icon-calendar1" />
                    <div className="lbl font s14 b3">March 25th, 2022</div>
                  </div>
                  <div className="contact-form">
                    <div className="field">
                      <div className="lbl font">First Name</div>
                      <input
                        type="text"
                        placeholder="Your first name"
                        className="iput font"
                      />
                    </div>
                    <div className="field">
                      <div className="lbl font">Last Name</div>
                      <input
                        type="text"
                        placeholder="Your Last name"
                        className="iput font"
                      />
                    </div>
                    <div className="field">
                      <div className="lbl font">Email</div>
                      <input
                        type="text"
                        placeholder="Your email"
                        className="iput font"
                      />
                    </div>
                    <div className="field">
                      <div className="lbl font">Phone</div>
                      <input
                        type="text"
                        placeholder="Your phone number"
                        className="iput font"
                      />
                    </div>
                    <button className="send-msg-btn font">Schedule Tour</button>
                  </div>
                </div>
              </div>
            )}
            {step === 8 && (
              <div className="step-4 flex flex-col">
                <div className="meta">
                  <div className="title font">Contact info</div>
                  <div className="txt font">
                    Fill out the form below and we'll respond soon.
                  </div>
                  <div className="contact-form">
                    <div className="field">
                      <div className="lbl font">First Name</div>
                      <input
                        type="text"
                        placeholder="Your first name"
                        className="iput font"
                        onChange={(e) => {
                          setuserinfo({
                            ...userinfo,
                            firstname: e.target.value,
                          });
                        }}
                        value={userinfo?.firstname}
                      />
                    </div>
                    <div className="field">
                      <div className="lbl font">Last Name</div>
                      <input
                        type="text"
                        placeholder="Your Last name"
                        className="iput font"
                        onChange={(e) => {
                          setuserinfo({
                            ...userinfo,
                            lastname: e.target.value,
                          });
                        }}
                        value={userinfo?.lastname}
                      />
                    </div>
                    <div className="field">
                      <div className="lbl font">Email</div>
                      <input
                        type="text"
                        placeholder="Your email"
                        className="iput font"
                        onChange={(e) => {
                          setuserinfo({ ...userinfo, email: e.target.value });
                        }}
                        value={userinfo?.email}
                      />
                    </div>
                    <div className="field">
                      <div className="lbl font">Phone</div>
                      <input
                        type="text"
                        placeholder="XXXXXXXXXX"
                        className="iput font"
                        onChange={(e) => {
                          setuserinfo({ ...userinfo, phone: e.target.value });
                        }}
                        value={userinfo?.phone}
                      />
                    </div>
                    {/* <div className="field">
                      <div className="lbl font">Message</div>
                      <textarea
                        type="text"
                        placeholder="Your message"
                        className="iput area font"
                        value={message}
                      />
                    </div> */}
                    <button
                      onClick={() => {
                        console.log(
                          'dateformated',
                          moment(selectedDate).format('L')
                        );
                        console.log(
                          'dateformated',
                          `${moment(new Date()).format('L')}T${moment(
                            new Date()
                          ).format('hh:mm:ss')}`
                        );
                        bookappointment(entratadata, {
                          date: `${moment(new Date()).format('L')}T${moment(
                            new Date()
                          ).format('hh:mm:ss')}`,
                          appointmentDate: moment(selectedDate).calendar(),
                          timeFrom: `${selectedhour} PST`,
                          timeTo: `${selectedhour?.split(':')[0]}:30 ${
                            selectedhour?.split(' ')[1]
                          } PST`,
                          eventReasons: 'testing',
                          comments: 'testing',
                        });
                      }}
                      className="send-msg-btn font"
                    >
                      {appoinmentloading
                        ? 'Please wait...'
                        : 'Book an appointment'}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {step === 9 && (
              <div>
                <div className=" w-full flex mt-3 items-center justify-center">
                  <div className=" w-32 h-32 rounded-full overflow-hidden">
                    <img
                      alt="waveTYG"
                      className="w-32 h-32 rounded-full"
                      src="https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/2e43454b-af4a-4018-3e0f-bd1841f0d000/public"
                    />
                  </div>
                </div>
                <h4 className=" pt-2 text-pr text-xl font-semibold w-full text-center">
                  {userinfo.firstname}, thanks for scheduling a tour at{' '}
                  {propertyinfo?.MarketingName}!
                </h4>
                <p className=" text-gray-600 text-lg font-light pt-3 w-full text-center">
                  We have received your request and have scheduled your tour on
                </p>
                <p className=" font-bold text-pr w-full text-center pt-2">
                  {moment(selectedDate).format('MMMM Do YYYY')} at{' '}
                  {selectedhour}
                </p>
                <div className=" flex items-center justify-center mt-10">
                  <button
                    onClick={() => setStep(step - 1)}
                    className=" text-pr font-medium"
                  >
                    Go back
                  </button>
                </div>
              </div>
            )}

            {/* <div className="content-ftr">
              <img
                src={
                  require('../../assets/tour-video-NEW-TYG-logo.png').default
                }
                className="ftr-img"
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default BookingSchedule;
