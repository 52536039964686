import { Button, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
// Material-UI
// Tabler
// style sheets
import EditButton from '../../components/Reusable/EditButton';
import { generateID } from '../../core';
import { EMBED_HOST } from '../../utils/request';
import { generateRoutes } from '../manage-magnets/ManageMagnet';
import DragAndDrop from './DragAndDrop';
import { uploadVideoFromLocalUrl } from './MagnetSummary';
import SendRecording from './SendRecording';
import TemplatePreviewMessage from './TemplatePreviewMessage';
import UploadForm from './UploadForm';
const Upload = ({
  currVid,
  setCurrVid,
  handleUpdateScreen,
  handleCreateScreen,
  newUploadHasStarted,
  setNewUploadHasStarted,
  loadScreenTextIndex,
  setLoadScreenTextIndex,
  setShowShareVideoDialog,
  showShareVideoDialog,
  setShowEmbedVideoDialog,
  showEmbedVideoDialog,
}) => {
  const {
    screenToEdit,
    newVideoTitle,
    magnetId,
    magnetType,
    previewTemplate,
    newScreenCloudLibrary,
    newScreenVideoCurrVid,
    newScreenFormTemplate,
    newScreenIframeOption,
  } = useSelector((state) => state.magnetStates);
  const EmbedCodeRef = useRef();
  const EmbedScriptCodeRef = useRef();

  const { template } = useSelector((state) => state.getMagnet);
  const { community_id } = useParams();

  const _dispatch = useDispatch();

  const [category, screen] = screenToEdit.split('.');
  const screenObj = template?.categories[category]?.screens[screen];

  // If project is not created, there iwll be a defined project id, else there will not be
  const [projectID, setProjectID] = useState(
    Math.floor(Math.random() * 1000000 + 1)
  );
  const [definedProjectID, setDefinedProjectID] = useState(false);
  const [cloudLibrary, setCloudLibrary] = useState(newScreenCloudLibrary);
  const [cloudLibraryProgress, setCloudLibraryProgress] = useState();

  console.log('newScreenCloudLibrary: ', newScreenCloudLibrary);

  // Keep track of button name & tour routes - USAGE: {name: route: id: }

  console.log('cloudLibrary', cloudLibrary);

  const [buttons, setButtons] = useState([]);
  const [centerButtons, setCenterButtons] = useState({
    pageEnabled: false,
    buttonEnabled: false,
    backgroundWhite: false,
    links: [],
  });
  const [bottomButtons, setBottomButtons] = useState({
    invisibleBackground: false,
    roundedVideoPlayer: false,
    extraPadding: false,
    links: [],
  });

  const [thumbnailImg, setThumbnailImg] = useState('');

  const [caption, setCaption] = useState(
    template?.categories[category]?.screens[screen]?.caption || ''
  );
  const [location, setLocation] = useState(
    template?.categories[category]?.screens[screen]?.location_card || ''
  );

  const [formTemplate, setFormTemplate] = useState(
    template?.categories[category]?.screens[screen]?.form ||
      newScreenFormTemplate || {
        enabled: true,
        inputs: [],
        text_data: {
          title: 'Redeem your special offer{{, {name}|}}!',
          body_text:
            '{{{firstName}: j|J}}ust fill out this form to redeem your offer of 10% off your first month',
          submit_success: 'Your submission has been received!',
          submit_fail:
            'Oops! Something went wrong while submitting the form. Please try again in a moment.',
          submit_body:
            'You can share this tour with a friend using the buttons below.',
        },
        contact: {
          enabled: false,
        },
        opacity: 1.0,
        text_color: 'black',
      }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [iframeOptions, setIframeOptions] = useState(
    template?.categories[category]?.screens[screen]?.iframe ||
      newScreenIframeOption || {
        enabled: false,
        src: '',
        icon_color: 'red',
        background_color: '#fff',
      }
  );

  const [shareInfo, setShareInfo] = useState(
    template?.categories[category]?.screens[screen]?.share_info || {}
  );

  useEffect(() => {
    if (newScreenVideoCurrVid.length) {
      setCurrVid(newScreenVideoCurrVid);
    }
  }, [newScreenVideoCurrVid]);

  useEffect(() => {
    if (screen && screen !== 'new') {
      if (screenObj?.additionalInfo?.projectID) {
        setProjectID(screenObj.additionalInfo.projectID);
        if (screenObj.additionalInfo.definedProjectID) {
          setDefinedProjectID(screenObj.additionalInfo.definedProjectID);
        }
      }
      if (screenObj?.cloudLibrary) {
        setCloudLibrary(screenObj.cloudLibrary);
      }
      // Preload the video url if there is not already a cloudlibrary

      if (!screenObj?.cloudLibrary && screenObj?.video) {
        setCloudLibrary([
          {
            name: screenObj.video.substring(
              screenObj.video.lastIndexOf('/') + 1
            ),
            type: 'video/mp4',
            dataURL: screenObj.video,
            formData: { video: screenObj.video },
            saveToCloud: true,
          },
        ]);
      }
    }
  }, []);

  // Difference between FileReader and URL createObjectURL https://stackoverflow.com/questions/31742072/filereader-vs-win -url-createobjecturl
  // Convert dropped files into blobs
  async function handleDrop(files) {
    //loop through all the dropped files
    for (let i = 0; i < files.length; ++i) {
      //Get video name
      const name = files[i].name;
      //Get file type (make sure it's a video)
      // files[i].type = "video/mp4"
      const type = files[i].type;
      //Convert file into a data url
      const dataURL = URL.createObjectURL(files[i]);
      // //Sending file to google cloud platform
      const formData = new FormData();
      formData.append('video', files[i]);

      //filter out non-videos
      if (
        type === 'video/mp4' ||
        type === 'video/quicktime' ||
        type === 'video/webm'
      ) {
        //add it to videos
        setCloudLibrary((prevList) => [
          ...prevList,
          {
            name: name,
            type: type,
            origName: name,
            dataURL: dataURL,
            formData: formData,
            saveToCloud: true,
          },
        ]);

        setCurrVid([
          { name: name, type: type, source: dataURL, formData: formData },
        ]);
      }
    }
  }

  window.onpopstate = () => {
    _dispatch({
      type: 'SCREEN_TO_EDIT',
      payload: '',
    });
  };

  async function handleUpdateProjectID(newProjectID, update) {
    const screenToUpdate = screenObj;
    if (newProjectID) {
      setProjectID(newProjectID);
      if (update) {
        setDefinedProjectID(false);
      } else {
        setDefinedProjectID(true);
      }
    }
    if (screen !== 'new' && screenToUpdate !== undefined) {
      if (!screenToUpdate['additionalInfo']) {
        screenToUpdate['additionalInfo'] = {};
      }
      if (update) {
        screenToUpdate['additionalInfo']['projectID'] = newProjectID;
        screenToUpdate['additionalInfo']['definedProjectID'] = false;
      } else if (newProjectID) {
        screenToUpdate['additionalInfo']['projectID'] = newProjectID;
        screenToUpdate['additionalInfo']['definedProjectID'] = true;
      } else {
        screenToUpdate['additionalInfo']['projectID'] = projectID;
        screenToUpdate['additionalInfo']['definedProjectID'] = false;
      }

      _dispatch({
        type: 'updateScreen',
        payload: {
          screen_key: screen,
          category_key: category,
          screen: screenToUpdate,
          magnet_id: magnetId,
          magnet_type: magnetType,
        },
      });
    }
  }

  async function handlePushCloudLibrary(newCloudLibrary) {
    const screenToUpdate = screenObj;

    if (screen !== 'new' && screenToUpdate !== undefined) {
      screenToUpdate['cloudLibrary'] = newCloudLibrary;

      _dispatch({
        type: 'updateScreen',
        payload: {
          screen_key: screen,
          category_key: category,
          screen: screenToUpdate,
          magnet_id: magnetId,
          magnet_type: magnetType,
        },
      });
    }
  }

  // When a button a save or update button is clicked, run the function below to dispatch the reducer on the Videos.js

  useEffect(() => {
    if (newVideoTitle) {
      setVideoName(newVideoTitle);
      _dispatch({
        type: 'NEW_VIDEO_TITLE',
        payload: videoName,
      });
    }
  }, [newVideoTitle]);

  var categoryTitle = template?.categories[category]?.title;
  var screenTitle =
    template?.categories[category].screens[screen]?.title || newVideoTitle;
  const [videoName, setVideoName] = useState(
    template?.categories[category].screens[screen]?.title || newVideoTitle
  );

  const [buttonsIsDragging, setButtonsIsDragging] = useState(true);

  // var categoryTitle = template.categories[category]?.title
  // var screenTitle = template.categories[category].screens[screen]?.title || newVideoTitle

  // const [videoName, setVideoName] = useState(template.categories[category].screens[screen]?.title || newVideoTitle)

  // reset state in case user goes back to add a new title
  // setNewVideoTitle('');

  // useEffect(() => {
  //   if (!previewTemplate.enablePreview) {
  //     _dispatch({
  //       type: 'SCREEN_TO_EDIT',
  //       payload: '',
  //     });
  //     window.history.pushState({}, `View ${videoName}`, `#`);
  //   }
  // }, [previewTemplate.enablePreview]);

  const { showScreenRecording } = useSelector((state) => state.generalReducers);

  const [shareCloud, setShareCloud] = useState([]);
  const [showShareMessageRecorder, setShowShareMessageRecorder] =
    useState(false);
  const [currVidToShare, setCurrVidToShare] = useState();
  const [shareLoading, setShareLoading] = useState(false);

  const onCancel = () => {
    _dispatch({
      type: 'SCREEN_TO_EDIT',
      payload: '',
    });
    window.history.pushState({}, `View ${videoName}`, `#`);
  };

  // Share Video Stats
  const [magnetObj, setMagnetObj] = useState('');
  const [magnetUuid, setMagnetUuid] = useState('');
  const [routeOpts, setRouteOpts] = useState([]);

  const [screenChoice, setScreenChoice] = useState('');
  const [videoStartChoice, setVideoStartChoice] = useState('');
  const [routeStartChoice, setRouteStartChoice] = useState('');

  const [shareVideo, setShareVideo] = useState({
    inlineCta: 'Take a tour',
    srcUrlCopied: false,
    hideButtons: false,
  });
  const inlineSrcUrlRef = useRef();

  useEffect(() => {
    setMagnetObj(template);
    setMagnetUuid(magnetId);
  }, []);

  useEffect(() => {
    if (magnetObj) {
      const tempRoutes = generateRoutes(magnetObj);
      console.log('tempRoutes', { magnetObj, template });
      setRouteOpts(tempRoutes);

      const currentTempRoute = tempRoutes.find(
        (item) => item.route === screenToEdit
      );
      setScreenChoice(
        currentTempRoute
          ? currentTempRoute.route + '|' + currentTempRoute.video
          : ''
      );
      setVideoStartChoice(currentTempRoute ? currentTempRoute.video : '');
      setRouteStartChoice(currentTempRoute ? currentTempRoute.route : '');
    }
  }, [magnetObj]);

  console.log('Generated routeOpts', routeOpts);

  const handleChangeMenu = (event) => {
    setScreenChoice(event.target.value);
    if (event.target.value.includes('|')) {
      const route = event.target.value.split('|', 2)[0];
      const videourl = event.target.value.split('|', 2)[1];
      setRouteStartChoice(route);
      setVideoStartChoice(videourl);
    }
  };

  const handleCloseShareVideoDialog = () => {
    setShowShareVideoDialog(false);
  };
  const handleCloseEmbedVideoDialog = () => {
    setShowEmbedVideoDialog(false);
  };

  let shareVideoUrl = `${EMBED_HOST}?uuid=${magnetUuid}&inline=true&screen=${routeStartChoice}&inlineCTA=${encodeURI(
    shareVideo.inlineCta
  )}`;

  function copy(e, ref) {
    ref.current.select();
    document.execCommand('copy');
    e.target.focus();
    onCopyText();
  }

  const onCopyText = () => {
    setTimeout(() => {
      setShareVideo({
        ...shareVideo,
        srcUrlCopied: false,
      });
    }, 1000);
  };

  return (
    <>
      <div className="upload-page">
        {/* Template Preview Block */}
        <div className="prev-msg">
          <TemplatePreviewMessage onCancel={onCancel} />
        </div>
        <button
          className="back-btn mt-3 cleanbtn font s15 items-center gap-1 text-pr black flex "
          onClick={() => {
            _dispatch({
              type: 'SCREEN_TO_EDIT',
              payload: '',
            });
            window.history.pushState({}, `View ${videoName}`, `#`);
            // setCurrVid([]);
            // window.history.back();
          }}
        >
          <BsArrowLeft />
          Back
        </button>
        <div className="upload-page-hdr flex aic">
          <div className="left flex aic">
            <EditButton
              label={
                <div className="video-nam font s16 b6 black">
                  {videoName}
                  <EditIcon />
                </div>
              }
              placeholder={'Video Name'}
              initialValue={videoName}
              setState={setVideoName}
              // sampleVal={newVideoTitle}
              // startEditable={screen === 'new' ? true : false}
            />
          </div>
        </div>
        <Dragger handleDrop={handleDrop}>
          <div style={{ backgroundColor: '#FFF', height: '100%' }}>
            <div className=" container grid grid-cols-1 lg:grid-cols-2 gap-5">
              <div className=" w-full">
                <UploadForm
                  community_id={community_id}
                  screenTitle={screenTitle}
                  categoryTitle={categoryTitle}
                  category={category}
                  screen={screen}
                  currVid={currVid}
                  setCurrVid={setCurrVid}
                  cloudLibrary={cloudLibrary}
                  setCloudLibrary={setCloudLibrary}
                  screenObj={screenObj}
                  handleUpdateScreen={handleUpdateScreen}
                  handleCreateScreen={handleCreateScreen}
                  newUploadHasStarted={newUploadHasStarted}
                  setNewUploadHasStarted={setNewUploadHasStarted}
                  loadScreenTextIndex={loadScreenTextIndex}
                  setLoadScreenTextIndex={setLoadScreenTextIndex}
                  centerButtons={centerButtons}
                  buttons={buttons}
                  bottomButtons={bottomButtons}
                  setCenterButtons={setCenterButtons}
                  thumbnailImg={thumbnailImg}
                  setThumbnailImg={setThumbnailImg}
                  setButtons={setButtons}
                  setBottomButtons={setBottomButtons}
                  caption={caption}
                  setCaption={setCaption}
                  location={location}
                  setLocation={setLocation}
                  formTemplate={formTemplate}
                  setFormTemplate={setFormTemplate}
                  iframeOptions={iframeOptions}
                  setIframeOptions={setIframeOptions}
                  shareInfo={shareInfo}
                  setShareInfo={setShareInfo}
                  projectID={projectID}
                  handleUpdateProjectID={handleUpdateProjectID}
                  definedProjectID={definedProjectID}
                  name={videoName}
                  setName={setVideoName}
                  cloudLibraryProgress={cloudLibraryProgress}
                  setCloudLibraryProgress={setCloudLibraryProgress}
                  setButtonsIsDragging={setButtonsIsDragging}
                  magnetId={magnetId}
                />
              </div>
              <div className=" w-full">
                <DragAndDrop
                  community_id={community_id}
                  magnetId={magnetId}
                  magnetType={magnetType}
                  category={category}
                  screen={screen}
                  currVid={currVid}
                  setCurrVid={setCurrVid}
                  cloudLibrary={cloudLibrary}
                  setCloudLibrary={setCloudLibrary}
                  buttons={buttons}
                  centerButtons={centerButtons}
                  bottomButtons={bottomButtons}
                  caption={caption}
                  location={location}
                  formTemplate={formTemplate}
                  iframeOptions={iframeOptions}
                  shareInfo={shareInfo}
                  handleUpdateProjectID={handleUpdateProjectID}
                  projectID={projectID}
                  setProjectID={setProjectID}
                  definedProjectID={definedProjectID}
                  setDefinedProjectID={setDefinedProjectID}
                  cloudLibraryProgress={cloudLibraryProgress}
                  handlePushCloudLibrary={handlePushCloudLibrary}
                />
              </div>
            </div>
          </div>
        </Dragger>
      </div>
      {/* {showScreenRecording && (
        <>
          {console.log('showScreenRecording')}
          <SendRecording
            cloudLibrary={cloudLibrary}
            setCloudLibrary={setCloudLibrary}
            setCloudLibraryHandler={setCloudLibraryHandler}
          />
        </>
      )} */}

      {/* Share Vidoe Dialog */}
      <AnimatedDialog
        open={showShareVideoDialog}
        keepMounted
        onClose={handleCloseShareVideoDialog}
        maxWidth={'sm'}
      >
        <div className="share-video-dialog flex flex-col">
          <div className="hdr flex aic">
            <div className="title font s20 b6 c000">
              Share <span className="color">Video</span>
            </div>
            <button
              className="cross-btn cleanbtn fontr"
              onClick={handleCloseShareVideoDialog}
            >
              &times;
            </button>
          </div>
          <div className="wrap flex flex-col">
            <div className="feild flex flex-col">
              <div className="lbl font s14 b4 c000">Default Screen</div>
              <Select
                labelId="select screen"
                id="select screen"
                value={screenChoice} // If at least one routeOpts exist, choose the first, else return empty
                onChange={handleChangeMenu}
                disableUnderline
                style={{
                  border: '1px solid #ddd',
                  padding: '8px 15px 5px 15px',
                  borderRadius: '4px',
                }}
              >
                {routeOpts.map(({ id, route, video }) => (
                  <MenuItem
                    key={id + route + video}
                    value={route + '|' + video}
                    style={{ backgroundColor: '#fff' }}
                  >
                    {id}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="feild flex flex-col">
              <div className="lbl font s14 b4 c000">
                Custom Message <span className="s12">(Optional)</span>
              </div>
              <div className="itm flex aic">
                <input
                  className="cleanbtn iput font s15 b4 c000"
                  defaultValue={shareVideo.inlineCta}
                  onChange={(e) => {
                    setShareVideo({
                      ...shareVideo,
                      inlineCta: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <label className="feild checkbox flex aic">
              <button
                onClick={() =>
                  setShareVideo({
                    ...shareVideo,
                    hideButtons: !shareVideo.hideButtons,
                  })
                }
                className={`cleanbtn checkbox-btn rel ${
                  shareVideo.hideButtons ? 'on' : ''
                }`}
              >
                <div className="icon icon-check1 anim" />
              </button>
              <div className="lbl font s14 b4 c000">Hide Button</div>
            </label>
            <div className="feild flex flex-col">
              <div className="lbl font s14 b4 c000">Video url value</div>

              <div className="itm flex aic">
                <input
                  ref={inlineSrcUrlRef}
                  className="cleanbtn iput font s15 b4 c000"
                  value={`http://tour.video?${magnetUuid}?screen=${routeStartChoice}&inlineCTA=${encodeURI(
                    shareVideo.inlineCta
                  )}&hideButtons=${
                    shareVideo.hideButtons
                  }&tourMsg=${currVidToShare}`}
                  readOnly
                />
                <button
                  className="cleanbtn copy-btn font s13 cfff rel"
                  onClick={(e) => {
                    copy(e, inlineSrcUrlRef);
                    setShareVideo({
                      ...shareVideo,
                      srcUrlCopied: true,
                    });
                  }}
                >
                  Copy
                  <div
                    className={`copied font s14 b4 cfff anim ${
                      shareVideo.srcUrlCopied ? 'sho' : 'hid'
                    }`}
                  >
                    Copied!
                  </div>
                </button>
              </div>

              {showScreenRecording && (
                <SendRecording
                  cloudLibrary={cloudLibrary}
                  setCloudLibrary={setCloudLibrary}
                />
              )}

              <div className="advanced-upload-btns">
                <button
                  className="cleanbtn record-new-video-btn flex aic"
                  style={{ width: '100%' }}
                  onClick={() => {
                    _dispatch({
                      type: 'SHOW_SCREEN_RECORDING',
                      payload: true,
                    });
                  }}
                >
                  <div
                    className={`${
                      shareCloud.length >= 1
                        ? 'grey-record-icon'
                        : 'record-icon'
                    } `}
                  />
                  <div className="font s14 b5 c333">
                    {shareCloud.length >= 1
                      ? `${shareCloud.length} video recorded`
                      : 'Record personal video (optional)'}
                  </div>
                </button>
              </div>

              {shareCloud.map((vid, idx) => {
                return (
                  <div>
                    <video
                      controls
                      autoPlay
                      src={vid.dataURL}
                      style={{ width: '100%' }}
                    />
                    <div className="print-btn flex aic">
                      <button
                        className="btn font"
                        onClick={async () => {
                          const localUrl = vid.dataURL;
                          setShareLoading(idx);
                          let firebaseUrl = '';
                          let messageMomentDirectualId = generateID(3, 4);

                          uploadVideoFromLocalUrl(localUrl).then(
                            async (url) => {
                              firebaseUrl = url;
                              setShareLoading(-1);
                              const shareCloudTemp = [...shareCloud];
                              shareCloudTemp[idx] = {
                                dataURL: url,
                                messageDirectualId: messageMomentDirectualId,
                                name: 'test_video_TYG',
                                prefix: '/route/TYG/',
                                type: 'video/mp4',
                                origName: 'test_video_TYG.mp4',
                              };

                              const res = await axios.post(
                                `https://api.directual.com/good/api/v5/data/message/getAndCreateMessage?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&messageId=${messageMomentDirectualId}`,
                                {
                                  id: messageMomentDirectualId,
                                  type: 'video_message',
                                  data: {
                                    video_url: firebaseUrl,
                                  },
                                }
                              );

                              setShareCloud(shareCloudTemp);
                              setCurrVidToShare(messageMomentDirectualId);
                            }
                          );
                        }}
                      >
                        {shareLoading == idx
                          ? 'Currently Uploading'
                          : 'Share Video'}
                      </button>
                      <button
                        className="btn font"
                        onClick={() =>
                          window.open(
                            `https://theruckusliving.com?tourMsg=${shareCloud[idx]?.messageDirectualId}&openLeaseMagnets=true&inlineCta=Tyler, hope this helps`,
                            '_blank'
                          )
                        }
                      >
                        Open in new Url
                      </button>

                      {/* {vid.dataURL} */}
                      {/* {shareLoading == idx && <>Currently uploading {idx}</>} */}
                    </div>
                  </div>
                );
              })}
            </div>

            {screenObj?.gif && (
              <>
                <a
                  href={`${EMBED_HOST}?uuid=${magnetUuid}&inline=true&screen=${routeStartChoice}&inlineCTA=${encodeURI(
                    shareVideo.inlineCta
                  )}&hideButtons=${shareVideo.hideButtons}`}
                  target="_blank"
                >
                  <img src={screenObj?.gif} height="224px" />
                </a>
              </>
            )}
          </div>
        </div>
      </AnimatedDialog>

      {/* Embed Video Dialog */}

      <EmbedDialog
        showEmbedVideoDialog={showEmbedVideoDialog}
        handleCloseEmbedVideoDialog={handleCloseEmbedVideoDialog}
        EmbedCodeRef={EmbedCodeRef}
        magnetId={magnetId}
        EmbedScriptCodeRef={EmbedScriptCodeRef}
        screen={screen}
        primaryColor={template?.default_config?.primaryColor}
      />
    </>
  );
};

export default Upload;

function EmbedDialog({
  showEmbedVideoDialog,
  handleCloseEmbedVideoDialog,
  EmbedCodeRef,
  EmbedScriptCodeRef,
  magnetId,
  screen,
  primaryColor,
}) {
  const [embedCodeFunctionType, setEmbedCodeFunctionType] = useState('onclick');
  const [embedCodeVideoSetting, setEmbedCodeVideoSetting] = useState('');
  const [inlineCTAVal, setInlineCTA] = useState('');
  const [iframeColor, setIframeColor] = useState(primaryColor);

  return (
    <AnimatedDialog
      open={showEmbedVideoDialog}
      keepMounted
      onClose={handleCloseEmbedVideoDialog}
      maxWidth={'sm'}
    >
      <div className="share-video-dialog flex flex-col">
        <div className="hdr flex aic">
          <div className="title font s20 b6 c000">
            Embed this <span className="color">Video</span>
          </div>
          <button
            className="cross-btn cleanbtn fontr"
            onClick={handleCloseEmbedVideoDialog}
          >
            &times;
          </button>
        </div>

        <div className="wrap flex flex-col">
          <div className="flex justify-content-between mb-2">
            <div className="flex-center-vt ">
              <input
                type="radio"
                name="videoSetting"
                checked={embedCodeVideoSetting === 'inline'}
                onChange={() => setEmbedCodeVideoSetting('inline')}
              />
              <p className="ml-2 font s14 b6 c000">Inline</p>
            </div>
            <div className="flex-center-vt  ml-2 mr-2">
              <input
                type="radio"
                name="videoSetting"
                checked={embedCodeVideoSetting === 'widget'}
                onChange={() => setEmbedCodeVideoSetting('widget')}
              />
              <p className="ml-2 font s14 b6 c000">Widget</p>
            </div>
            <div className="flex-center-vt  ml-2 mr-2">
              <input
                type="radio"
                name="videoSetting"
                checked={embedCodeVideoSetting === 'viewBtn'}
                onChange={() => setEmbedCodeVideoSetting('viewBtn')}
              />
              <p className="ml-2 font s14 b6 c000">Video Button</p>
            </div>
            <div className="flex-center-vt">
              <input
                type="radio"
                name="videoSetting"
                checked={embedCodeVideoSetting === 'textBtn'}
                onChange={() => setEmbedCodeVideoSetting('textBtn')}
              />
              <p className="ml-2 font s14 b6 c000">Text Button</p>
            </div>
          </div>
          <div>
            <TextField
              inputRef={EmbedCodeRef}
              variant="filled"
              value={`<iframe src="https://embed.tour.video/?uuid=${magnetId}&inline=true&screen=intro.main&inlineCTA=${inlineCTAVal}&hideButtons=false&tourMsg=undefined&primaryColor=${
                iframeColor && '%' + iframeColor?.replace('#', '')
              }"
allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
width="100%"
height="600px"
style="border: none; border-radius: 24px"
>
</iframe>`}
              style={{ width: '100%' }}
              multiline={true}
            />
          </div>
          <div className="flex justify-content-between">
            <div className="flex-center-vt">
              <Button color="primary" onClick={(e) => copy(e, EmbedCodeRef)}>
                Copy
              </Button>
              <input
                type="text"
                value={inlineCTAVal}
                onChange={(e) => setInlineCTA(e.target.value)}
                placeholder="Say something"
                className="cleanbtn iput font s15 b4 c000"
              />
            </div>

            <input
              type="color"
              value={iframeColor}
              onChange={(e) => setIframeColor(e.target.value)}
            />
          </div>

          <div>
            <div className="title font s20 b6 c000 mt-3">
              Send custom analytics to event pixels (like Google Analytics / FB
              Pixel) Embed
            </div>
            <p className="mt-2">
              We make it easy to send intent-based traffic to marketing pixels
              to optimize your conversion and organize your intent based events
            </p>
          </div>

          <div className="flex flex-row mt-3">
            <div className="flex-center-vt ">
              <input
                type="radio"
                name="callingEvent"
                checked={embedCodeFunctionType === 'onclick'}
                onChange={() => setEmbedCodeFunctionType('onclick')}
              />
              <p className="ml-2 font s14 b5 c000">On-click</p>
            </div>
            <div className="flex-center-vt  ml-2 mr-2">
              <input
                type="radio"
                name="callingEvent"
                checked={embedCodeFunctionType === 'onload'}
                onChange={() => setEmbedCodeFunctionType('onload')}
              />
              <p className="ml-2 font s14 b5 c000">Onload</p>
            </div>
            <div className="flex-center-vt">
              <input
                type="radio"
                name="callingEvent"
                checked={embedCodeFunctionType === 'tagManager'}
                onChange={() => setEmbedCodeFunctionType('tagManager')}
              />
              <p className="ml-2 font s14 b5 c000">Google Tag Magager</p>
            </div>
          </div>

          <div className="mt-3">
            <TextField
              inputRef={EmbedScriptCodeRef}
              variant="filled"
              value={`<script src=”https://embed.tour.video/leasemagnets.js"/> /*     */

<script>
window.triggerTourPixel({ 
   type: "event-external", 
  intent: "${screen}"
})
</script> `}
              style={{ width: '100%' }}
              multiline={true}
            />
          </div>
          <div>
            <Button
              color="primary"
              onClick={(e) => copy(e, EmbedScriptCodeRef)}
            >
              Copy
            </Button>
          </div>
        </div>
      </div>
    </AnimatedDialog>
  );
}

const Dragger = (props) => {
  const [dragging, setDragging] = useState(false);
  const [dragCounter, setDragCounter] = useState(parseInt(0));

  const dropRef = useRef(null);

  const { showVideoDragging, buttonDragging } = useSelector((state) => state);

  const _dispatch = useDispatch();

  useEffect(() => {
    if (buttonDragging) {
      _dispatch({ type: 'SHOW_VIDEO_DRAGGING', payload: false });
      setDragCounter(parseInt(-1));
    } else {
      if (dragCounter < 0) {
        _dispatch({ type: 'SHOW_VIDEO_DRAGGING', payload: false });
      } else {
        _dispatch({ type: 'SHOW_VIDEO_DRAGGING', payload: true });
      }
    }
  }, [dragCounter]);

  useEffect(() => {
    if (showVideoDragging) {
      let div = dropRef.current;
      setDragCounter(0);
      div.addEventListener('dragenter', handleDragIn);
      div.addEventListener('dragleave', handleDragOut);
      div.addEventListener('dragover', handleDrag);
      div.addEventListener('drop', handleDrop);
    }

    return () => {
      if (showVideoDragging) {
        let div = dropRef.current;
        div.removeEventListener('dragenter', handleDragIn);
        div.removeEventListener('dragleave', handleDragOut);
        div.removeEventListener('dragover', handleDrag);
        div.removeEventListener('drop', handleDrop);
      }
    };
  }, [dragging]);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter((dragCounter) => parseInt(dragCounter + 1));
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter((dragCounter) => dragCounter - 1);
    if (dragCounter > 0) return;
    if (dragCounter === 0) {
      setDragging(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      setDragCounter(0);
    }
    // shouldOpen1 = true;
  };

  return (
    <div
      // style={{ display: 'inline-block', position: 'relative'}}
      ref={dropRef}
    >
      {dragging && showVideoDragging && (
        <div
          style={{
            border: 'dashed grey 4px',
            backgroundColor: 'rgba(255,255,255,.8)',
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'grey',
              fontSize: 36,
              height: '100vh',
            }}
          >
            <CircularProgress />
            <div> Drop Video Here</div>
          </div>
        </div>
      )}
      {props.children}
    </div>
  );
};

//Dragger copied directly from https://medium.com/@650egor/simple-drag-and-drop-file-upload-in-react-2cb409d88929

// class Dragger extends React.Component {
//   state = {
//     dragging: false,
//     dragCounter: parseInt(0),
//   };
//   dropRef = React.createRef();
//   wrapperRef = React.createRef();

//   componentDidMount() {
//     // if(this.props.showVideoDragging){
//       let div = this.dropRef.current;
//       this.dragCounter = 0;
//       div.addEventListener('dragenter', this.handleDragIn);
//       div.addEventListener('dragleave', this.handleDragOut);
//       div.addEventListener('dragover', this.handleDrag);
//       div.addEventListener('drop', this.handleDrop);
//     // }
//   }

//   componentWillUnmount() {
//     // if(this.props.showVideoDragging){
//       let div = this.dropRef.current;
//       div.removeEventListener('dragenter', this.handleDragIn);
//       div.removeEventListener('dragleave', this.handleDragOut);
//       div.removeEventListener('dragover', this.handleDrag);
//       div.removeEventListener('drop', this.handleDrop);
//     // }
//   }

//   handleDrag = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//   };

//   handleDragIn = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     this.dragCounter = parseInt(this.dragCounter + 1);
//     if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
//       this.setState({ dragging: true });
//     }
//   };

//   handleDragOut = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     this.dragCounter = this.dragCounter - 1;
//     if (this.dragCounter > 0) return;
//     if (this.dragCounter === 0) {
//       this.setState({ dragging: false });
//     }
//   };

//   handleDrop = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     this.setState({ dragging: false });
//     if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
//       this.props.handleDrop(e.dataTransfer.files);
//       e.dataTransfer.clearData();
//       this.dragCounter = 0;
//     }
//     // shouldOpen1 = true;
//   };

//   render() {
//     return (
//       <div
//         // style={{ display: 'inline-block', position: 'relative'}}
//         ref={this.dropRef}
//       >
//         {this.state.dragging && (
//           <div
//             style={{
//               border: 'dashed grey 4px',
//               backgroundColor: 'rgba(255,255,255,.8)',
//               position: 'fixed',
//               top: 0,
//               bottom: 0,
//               left: 0,
//               right: 0,
//               zIndex: 99,
//             }}
//           >
//             <div
//               style={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 color: 'grey',
//                 fontSize: 36,
//                 height: '100vh',
//               }}
//             >
//               <CircularProgress />
//               <div>Drop Video Here</div>
//             </div>
//           </div>
//         )}
//         {this.props.children}
//       </div>
//     );
//   }
// }
