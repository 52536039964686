import { Button, Grid, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { PatternFormat } from 'react-number-format';
import DebouncedTextField from '../../../components/Reusable/DebouncedTextField';

const AdditionalInfo = ({
  additionalInformation,
  setAdditionalInformation,
}) => {
  function addFeature() {
    let currentFeatures = additionalInformation.features;
    currentFeatures.push('');
    setAdditionalInformation({
      ...additionalInformation,
      features: currentFeatures,
    });
  }

  function deleteFeature(idxToDelete) {
    let currentFeatures = additionalInformation.features;
    currentFeatures.splice(idxToDelete, 1);
    setAdditionalInformation({
      ...additionalInformation,
      features: currentFeatures,
    });
  }

  function updateFeature(idxToUpdate, value) {
    let currentFeatures = additionalInformation.features;
    currentFeatures[idxToUpdate] = value;
    setAdditionalInformation({
      ...additionalInformation,
      features: currentFeatures,
    });
  }

  return (
    <div className="adt-page flex flex-col">
      <Grid container spacing={1}>
        <div className="feild-blk flex flex-col">
          <div className="feild-lbl font s14 b6 c333">Share Caption</div>
          <DebouncedTextField
            id="shareCaption"
            placeholder="I think you'll like this beautiful 2-bedroom with a gorgeous view..."
            variant="outlined"
            value={additionalInformation.shareCaption}
            onChange={(newValue) => {
              setAdditionalInformation({
                ...additionalInformation,
                shareCaption: newValue,
              });
            }}
            style={{ width: 'calc(100%)' }}
          />
        </div>

        <div className="feild-blk flex flex-col">
          <div className="feild-lbl font s14 b6 c333">Multi Line Summary</div>
          <DebouncedTextField
            id="summary"
            placeholder="Here in this 2x3, you can find gorgeous views..."
            variant="outlined"
            value={additionalInformation.detailedDescription}
            onChange={(newValue) => {
              setAdditionalInformation({
                ...additionalInformation,
                detailedDescription: newValue,
              });
              console.log(additionalInformation, '| update additional info');
            }}
            style={{ width: 'calc(100%)' }}
            multiline
            rowsMax={8}
          />
        </div>

        <div className="feild-blk flex flex-col">
          <div className="feild-lbl font s14 b6 c333">Price</div>
          <PatternFormat
            value={
              additionalInformation?.price
                ? String(additionalInformation.price)
                : '0'
            }
            onChange={(e) =>
              setAdditionalInformation({
                ...additionalInformation,
                price: Number(e.target.value.substring(1).replace(',', '')),
              })
            }
            thousandSeparator={true}
            prefix={'$'}
            style={{ width: 'calc(100%)', height: '50px' }}
          />
        </div>

        <div className="feild-blk flex flex-col">
          {/* DATE ITEM */}
          <div className="feild-lbl font s14 b6 c333">Move in date</div>
          <DebouncedTextField
            id="move-in-date"
            label="Move in date"
            type="date"
            value={additionalInformation.moveInDate}
            onChange={(newValue) =>
              setAdditionalInformation({
                ...additionalInformation,
                moveInDate: newValue,
              })
            }
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: 'calc(100%)' }}
          />
        </div>

        <div className="feild-blk flex flex-col">
          {/* FEATURES ITEM */}
          <div className="feild-lbl font s14 b6 c333">Features</div>
          {additionalInformation.features.map((feature, feature_idx) => (
            <div key={feature + feature_idx} style={{ marginBottom: '10px' }}>
              <DebouncedTextField
                id={`Feature ${feature_idx}`}
                placeholder="feature "
                variant="outlined"
                value={feature}
                size="small"
                onChange={(newValue) => updateFeature(feature_idx, newValue)}
                style={{ width: 'calc(100%)' }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      color="secondary"
                      onClick={() => deleteFeature(feature_idx)}
                    >
                      <DeleteIcon style={{ fontSize: 18 }} />
                    </IconButton>
                  ),
                }}
              />
            </div>
          ))}

          <Grid item xs={10}>
            <Button variant="outlined" onClick={addFeature}>
              + Add feature
            </Button>
          </Grid>
        </div>

        {/* ADD ADDITOINAL MESSAGES */}
      </Grid>
    </div>
  );
};

export default AdditionalInfo;
