import React from 'react';
import classes from './Switch.module.css';

const Switch = (props) => {
  const {
    leftLabel,
    rightLabel,
    backgroundColor,
    className,
    name,
    ...inputProps
  } = props;
  return (
    <label className={classes.switch} htmlFor={name}>
      <input
        name={name}
        className={`${classes.switchInput} ${className}`}
        {...inputProps}
      />
      <span className={classes.descriptionLeft}>{leftLabel}</span>
      <span className={classes.switchIndicator} />
      <span className={classes.descriptionRight}>{rightLabel}</span>
    </label>
  );
};

export default Switch;
