import MaterialTable from '@material-table/core';
import { Button, Grid } from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import moment from 'moment';
import React, { forwardRef, useRef, useState } from 'react';
import ContentLoader from '../../components/ContentLoader';
import { useRequest } from '../../utils/request';

const tableIcons = {
  Add: forwardRef((props, ref) => (
    <AddBox {...props} ref={ref} keyboard-shortcut="add-icon-handler" />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Leads = (props) => {
  const {
    tableTitle = 'Template',
    getContactsUrl = './leads.json',
    leadsPerPage = 10,
    form,
    form_id,
  } = props;

  const [leads, setLeads] = useState([]);
  // const { form_id } = useParams();
  const [loading, error, data, makeRequest] = useRequest({ loading: false });

  // let forms = require("./forms.json");
  const forms = form;
  const temp2 = form;

  const tableRef = useRef(null);

  // const temp = require('./metrics-1mo.json');
  // const temp2 = require('./forms.json');

  // var poll_option_counts = {}
  // for (var i in temp2.questions) {
  //     var q_title = temp2.questions[i].metadata.text;
  //     for (var j in temp2.questions[i].poll_options) {
  //         var poll_title = temp2.questions[i].poll_options[j].content;
  //         var poll_counts = temp2.questions[i].poll_options[j].total_count;
  //         poll_option_counts[poll_title] = (poll_option_counts[poll_title] + poll_counts) || poll_counts
  //     }
  // }

  // {{base_url}}/forms/{{form_id}}/contacts/{{contact_id}}?include_answers=true&include_messages=true
  // {{base_url}}/forms/{{form_id}}/contacts?include_answers=true&limit=101&offset=1
  return (
    <div>
      <ContentLoader loading={loading} error={error}>
        <MaterialTable
          // https://stackoverflow.com/questions/60009133/how-to-get-reactjs-material-table-search-results-count
          tableRef={tableRef}
          localization={{
            toolbar: { searchPlaceholder: 'Search w/Stats update: Agent' },
          }}
          // onSearchChange={() => {

          //     updateLeadStats(tableRef.current.state.data)
          // }}

          icons={tableIcons}
          options={{
            headerStyle: {
              backgroundColor: '#01579b',
              color: '#FFF',
            },
            rowStyle: {
              backgroundColor: '#EEE',
            },
            filtering: true,
            pageSize: leadsPerPage,
            pageSizeOptions: [
              5,
              10,
              20,
              { value: temp2.questions.length, label: 'All' },
            ],
          }}
          columns={[
            {
              title: 'Title',
              field: 'title',
              render: (rowData) => {
                // createTimestamp.toLocaleFormat('%d-%b-%Y');
                return <span>{rowData.metadata.text}</span>;
              },
            },
            // {
            //     title: "Gif", field: "gif",
            //     // editComponent: editProps => (
            //     //     <Input
            //     //         autoFocus={true}
            //     //         onChange={e => editProps.onChange(e.target.value)}
            //     //     />
            //     // )
            // },
            { title: 'Question Id', field: 'question_id' },
            // { title: "Share Url", field: "share_url" },
            // { title: "Media Url", field: "media_url" },
            {
              title: 'Date Updated',
              field: 'updated_at',
              render: (rowData) => {
                const updateTimestamp = Date.parse(rowData.created_at);
                // createTimestamp.toLocaleFormat('%d-%b-%Y');
                return (
                  <div className="badge font s14 cfff info">
                    {moment(updateTimestamp).format('MMMM Do YYYY, h:mm:ss a')}
                  </div>
                );
              },
              customSort: (a, b) => {
                return moment(Date.parse(a.updated_on)).diff(
                  moment(Date.parse(b.updated_on))
                );
              },
              // defaultSort: 'desc',
              editable: 'never',
            },
          ]}
          // components={{
          //     Row: props => (
          //         <MTableBodyRow
          //             {...props}
          //         // onDoubleClick={e => {
          //         //     console.log(props.actions);
          //         //     props.actions[1]().onClick(e, props.data);
          //         // }}
          //         />
          //     )
          // }}

          // components={{
          //     Body: props => <MTableBody {...props} onFilterChanged={(columnId, value) => {
          //         props.onFilterChanged(columnId, value);
          //         // Do you job here :)
          //         updateLeadStats(tableRef.current.state.data);
          //     }} />
          // }}

          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  /* setData([...data, newData]); */
                  // addLead({ "name": newData.name, "email": newData.email })
                  resolve();
                }, 1000);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                resolve();
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  /* setData([...data, newData]); */
                  // deleteLead(oldData.id)
                  resolve();
                }, 1000);
                resolve();
              }),
          }}
          data={temp2.questions}
          // https://github.com/mbrn/material-table/issues/978
          // data={async (query) => {
          //     console.log("query: ", query);
          //     let leads = data.length > 1 ? data : await getLeads();

          //     return {
          //         data: data,
          //     }
          // }}

          title={tableTitle}
          detailPanel={(rowData) => {
            return (
              <>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <video
                      loop
                      autoPlay
                      controls
                      src={rowData.media_url}
                      className="gallery"
                      style={{ height: 'auto', width: '650px' }}
                    />
                    <label htmlFor="upload-photo">
                      <input
                        style={{ display: 'none' }}
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                      />

                      <Button
                        color="secondary"
                        variant="contained"
                        component="span"
                      >
                        Upload New Video
                      </Button>
                    </label>

                    {/* <a href={rowData.media_url} target="_blank" rel="noopener noreferrer" download> */}
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        fetch(
                          `https://cors-anywhere.herokuapp.com/${rowData.media_url}`,
                          {
                            method: 'GET',
                            // headers: {
                            //     'Content-Type': 'video/mp4',
                            // },
                          }
                        )
                          .then((response) => response.blob())
                          .then((blob) => {
                            // Create blob link to download
                            const url = window.URL.createObjectURL(
                              new Blob([blob])
                            );
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute(
                              'download',
                              `${rowData.metadata.text}.mp4`
                            );

                            // Append to html link element page
                            document.body.appendChild(link);

                            // Start download
                            link.click();

                            // Clean up and remove the link
                            link.parentNode.removeChild(link);
                          });
                      }}
                    >
                      <i className="fas fa-download" />
                      Download File
                    </Button>
                    {/* </a> */}

                    <br />
                    <br />

                    <label htmlFor="upload-photo">
                      <Button
                        color="secondary"
                        variant="outlined"
                        component="span"
                        fullWidth
                      >
                        Save Video
                      </Button>
                    </label>
                  </Grid>
                </Grid>
              </>
            );
          }}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
        />
      </ContentLoader>
    </div>
  );
};

export default Leads;
