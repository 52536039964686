import { useState, useEffect } from 'react';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import GetAppIcon from '@material-ui/icons/GetApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import moment from 'moment';
import formatDuration from 'format-duration';
import { getRecordingPeers } from '../../utils/hms';

export function PastRecordingCard({ thumbnailUrl, participants, session }) {
  const [peers, setPeers] = useState([]);

  useEffect(() => {
    const getPeers = async () => {
      console.log('peers', await getRecordingPeers(session[0].session_id));
      setPeers(await getRecordingPeers(session[0].session_id));
    };
    getPeers();
  }, []);

  return (
    <>
      {session.length > 1 ? (
        <Accordion
          /* onClick={(e) => {
            e.stopPropagation();
          }} */
          style={{
            borderRadius: '16px',
            padding: '8px',
            boxShadow: 'none',
            background: 'white',
            opacity: '1',
          }}
          className="accordion"
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className="rounded-2xl w-full bg-white flex flex-wrap p-0 align-items-center gap-12 border-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={100}
                height={60}
                fill="none"
              >
                <g clipPath="url(#a)">
                  <rect width={84} height={48} y={12} fill="#E6E6E6" rx={6} />
                  <rect width={84} height={48} x={8} y={6} fill="#CCC" rx={6} />
                  <rect width={84} height={48} x={16} fill="#B3B3B3" rx={6} />
                  <path fill="#fff" d="M70 24 52 34.392V13.608L70 24Z" />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h100v60H0z" />
                  </clipPath>
                </defs>
              </svg>

              <div className="flex flex-col flex-shrink-0 w-20">
                <div className="text-gray-800">Date</div>
                <div className="font-bold">
                  {moment(session[0].date).format('MMM Do')}
                </div>
              </div>

              <div className="flex flex-col flex-shrink-0 w-52">
                <div className="text-gray-800">Time</div>
                <div className="font-bold">
                  {moment(session[0].date).format('h:mm A')}
                </div>
              </div>

              {peers.length > 0 && (
                <div className="flex flex-col flex-shrink-0 w-20 flex-grow">
                  <div className="text-gray-800">Participants</div>
                  <div className="font-bold">{peers.join(', ')}</div>
                </div>
              )}

              <div className="flex flex-col flex-shrink-0 w-20 flex-grow">
                <div className="text-gray-800">Room</div>
                <div className="font-bold">
                  {session[0].room_name.replaceAll('_', ' ')}
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails
            style={{ borderTop: '1px solid #cacaca' }}
            className="flex-col pt-2"
          >
            {session.map((elem) => (
              <div className="rounded-2xl w-full bg-white flex flex-wrap p-2 px-0 align-items-center gap-12 border-none">
                <a href={elem.path} target="_blank" className="relative">
                  <video
                    width="100"
                    height="60"
                    className="rounded-md pointer-events-none"
                  >
                    <source src={elem.path} type="video/mp4"></source>
                  </video>
                  <PlayArrowIcon
                    htmlColor="white"
                    className="absolute bottom-2 left-1"
                    fontSize="large"
                  />
                </a>

                <div className="flex flex-col flex-shrink-0 w-20">
                  <div className="text-gray-800">Date</div>
                  <div className="font-bold">
                    {moment(elem.date).format('MMM Do')}
                  </div>
                </div>

                <div className="flex flex-col flex-shrink-0 w-20">
                  <div className="text-gray-800">Time</div>
                  <div className="font-bold">
                    {moment(elem.date).format('h:mm A')}
                  </div>
                </div>

                <div className="flex flex-col flex-shrink-0 w-20 flex-grow">
                  <div className="text-gray-800">Duration</div>
                  <div className="font-bold">
                    {formatDuration(elem.duration * 1000)}
                  </div>
                </div>

                <div className="flex gap-3">
                  <a
                    className="pointer bg-gray-100 rounded-xl p-3 text-gray-600 hover:bg-blue-500 hover:text-white duration-200 ease-in-out"
                    href={elem.path}
                    target="_blank"
                    download
                  >
                    <GetAppIcon />
                  </a>
                </div>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      ) : (
        <div
          style={{
            borderRadius: '16px',
            padding: '20px 24px',
            boxShadow: 'none',
            border: 'none',
            background: 'white',
          }}
        >
          <div className="rounded-2xl w-full flex flex-wrap p-0 align-items-center gap-12 border-none">
            <a href={session[0].path} target="_blank" className="relative">
              <video
                width="100"
                height="60"
                className="rounded-md pointer-events-none"
              >
                <source src={session[0].path} type="video/mp4"></source>
              </video>
              <PlayArrowIcon
                htmlColor="white"
                className="absolute bottom-2 left-1"
                fontSize="large"
              />
            </a>

            <div className="flex flex-col flex-shrink-0 w-20">
              <div className="text-gray-800">Date</div>
              <div className="font-bold">
                {moment(session[0].date).format('MMM Do')}
              </div>
            </div>

            <div className="flex flex-col flex-shrink-0 w-20">
              <div className="text-gray-800">Time</div>
              <div className="font-bold">
                {moment(session[0].date).format('h:mm A')}
              </div>
            </div>

            <div className="flex flex-col flex-shrink-0 w-20">
              <div className="text-gray-800">Duration</div>
              <div className="font-bold">
                {formatDuration(session[0].duration * 1000)}
              </div>
            </div>

            {peers.length > 0 && (
              <div className="flex flex-col flex-shrink-0 w-20 flex-grow">
                <div className="text-gray-800">Participants</div>
                <div className="font-bold">{peers.join(', ')}</div>
              </div>
            )}

            <div className="flex flex-col flex-shrink-0 w-20 flex-grow">
              <div className="text-gray-800">Room</div>
              <div className="font-bold">
                {session[0].room_name.replaceAll('_', ' ')}
              </div>
            </div>

            <div className="flex gap-3">
              <a
                className="pointer bg-gray-100 rounded-xl p-3 text-gray-600 hover:bg-blue-500 hover:text-white duration-200 ease-in-out"
                href={session[0].path}
                target="_blank"
                download
              >
                <GetAppIcon />
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
