import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
// import sound from '../../assets/clockSound.wav';
import {
  getRandomId,
  uploadBlobToStorage,
  validFileName,
} from '../../utils/files';
import { API_HOST } from '../../utils/request';
import RecordedVideoTitleDialog from './RecordedVideoTitleDialogue';
import { generateAndSaveVideoData } from './utils';

function SendRecording({
  name,
  cloudLibrary = [],
  setCloudLibrary,
  fromPage,
  inline = false,
  questionToAsk = '',
  questionIndex,
  onVideoUrl = function (url) {
    console.log(url);
  },
  closeDialogue,
  dontEncode,
  communityId,
  onVideoSaved,
  onVideoTitleAndUrl,
}) {
  const { encodingQ, currVid, screenToEdit, magnetId } = useSelector(
    (state) => state.magnetStates
  );
  const { showScreenRecording } = useSelector((state) => state.generalReducers);
  const _dispatch = useDispatch();

  // const [audio] = useState(new Audio(sound));
  const [isVideoTitleDialogueOpen, setIsVideoTitleDialogueOpen] =
    useState(false);
  const [showCounter, setShowCounter] = useState(false);
  const [onCamera, setOnCamera] = useState(true);
  const [showSticker, setShowSticker] = useState(true);
  const [onMicroPhone, setOnMicroPhone] = useState(true);
  const [onSetting, setOnSetting] = useState(false);
  const [blobVideos, setBlobVideos] = useState([]);
  const [selectedMode, setSelectedMode] = useState('Webcam Only');
  const [step, setStep] = useState(1);
  const [recorder, setRecorder] = useState(null);
  const [recording, setRecording] = useState('');
  const [streaming, setStreaming] = useState(null);
  const [webCamStream, setWebCamStream] = useState(null);
  const [showbutton, setshowbutton] = useState(null);
  const [picInPicStream, setPicInPicStream] = useState(null);
  const [previewVideo, setPreViewVideo] = useState();
  const [webCamErrorDialog, setWebCamErrorDialog] = useState(false);
  const [screenSharingErrorDialog, setScreenSharingErrorDialog] =
    useState(false);
  const videoRef = useRef(null);
  const webCamRef = useRef(null);
  const picInPicRef = useRef(null);
  const countRef = useRef(null);
  const buttonref = useRef(null);
  const modes = [
    { label: 'Webcam Only', icon: 'icon-webcam' },
    // { label: 'Screen + Webcam', icon: 'icon-widescreen-user' },
    { label: 'Share Screen', icon: 'icon-monitor-share' },
  ];

  let startEndRecordTime = useRef({
    start: new Date(),
    end: new Date(),
  });

  // SNACKBAR Alert management
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarColor, setSnackbarColor] = React.useState('success');
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [showProgressIndicator, setShowProgressIndicator] = useState(false);

  const [recordedVideoTitle, setRecordedVideoTitle] = useState(null);
  const [recordedVideoUrl, setRecordedVideoUrl] = useState(null);

  function restartStartEndTime() {
    startEndRecordTime.current = {
      start: new Date(),
      end: new Date(),
    };
  }

  const [currentMediaDevices, setCurrentMediaDevices] = useState({
    audio: [],
    video: [],
    openAudioList: false,
    openVideoList: false,
  });
  const [mediaSetting, setMediaSetting] = useState({
    selectedAudioId: '',
    selectedVideoId: '',
    activeNoise: false,
  });

  const getDeviceTypes = () => {
    let _devices = {
      audio: [],
      video: [],
    };
    navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
      for (let i = 0; i !== deviceInfos.length; ++i) {
        let deviceInfo = deviceInfos[i],
          facing = 'user',
          re = /(front|back)/gi,
          match = deviceInfo.label.match(re);
        match && match[0].toLowerCase() == 'back' && (facing = 'environment'),
          deviceInfo.kind === 'audioinput'
            ? _devices.audio.push({
                label: deviceInfo.label,
                id: deviceInfo.deviceId,
              })
            : deviceInfo.kind === 'videoinput' &&
              _devices.video.push({
                label: deviceInfo.label,
                id: deviceInfo.deviceId,
                facing,
              });
      }
    });

    setCurrentMediaDevices({
      ...currentMediaDevices,
      video: _devices.video,
      audio: _devices.audio,
    });
  };

  useEffect(() => {
    if (showScreenRecording) {
      getDeviceTypes();
      if (selectedMode === 'Share Screen' && step === 1) {
        fetchDisplayMedia();
      }
      if (selectedMode === 'Webcam Only' && step === 1) {
        console.log('Webcam Only called');
        fetchUserMedia();
      }
      if (selectedMode === 'Screen + Webcam' && step === 1) {
        fetchUserMedia();
        fetchDisplayMedia();
        setTimeout(() => {
          getPictureInPicture();
        }, 700);
        setTimeout(() => {
          fetchUserMedia();
        }, 1000);
      }
    }
    return () => {
      function stopBothVideoAndAudio(stream) {
        localStream.getVideoTracks()[0].stop();
        localStream?.getTracks()?.forEach((track) => {
          track.stop();
        });
        setStreaming('');
        setRecorder('');
      }
      stopBothVideoAndAudio(streaming);
    };
  }, [selectedMode || onCamera, showScreenRecording, onSetting]);

  {
    /* Handle Start && Stop Recoding Button */
  }

  useEffect(() => {
    if (recording == 'start') {
      //setShowCounter(true);
      countdown_init();
    }
    if (recording === 'stop') {
      getRecordedMedia();
      streaming?.getTracks().forEach((t) => t.stop());
      setRecorder(null);
      setStreaming(null);
      setSelectedMode('');
    }
  }, [recording]);

  {
    /* Browser Stop Sharing Button */
  }

  useEffect(() => {
    if (
      streaming &&
      (selectedMode !== 'Webcam Only' || selectedMode !== 'Screen + Webcam') &&
      recording === 'start'
    ) {
      streaming.getVideoTracks()[0].onended = function () {
        setRecording('stop');
      };
    }
    if (
      streaming &&
      (selectedMode !== 'Webcam Only' || selectedMode !== 'Screen + Webcam') &&
      (recording === 'stop' || recording === '')
    ) {
      streaming.getVideoTracks()[0].onended = function () {
        setSelectedMode('Webcam Only');
      };
    }
  });

  useEffect(() => {
    (async function sideEffectSaveVideo() {
      // If only video title is available
      if (recordedVideoTitle?.length > 0 && !recordedVideoUrl) {
        try {
          uploadVideo();
        } catch (err) {
          alert('Could not upload video, Please Try Again !');
        }
      }
    })();
  }, [recordedVideoTitle, recordedVideoUrl]);

  // snackbar for the alerts imports
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleSnackbar =
    (message, alertColor = 'success') =>
    () => {
      setSnackbarMessage(message);
      setSnackbarColor(alertColor);
      setOpenSnackbar(true);
    };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  function setCurrVid(val) {
    _dispatch({ type: 'CURR_VID', payload: val });
  }

  const fetchUserMedia = async () => {
    setshowbutton(false);
    try {
      let stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: mediaSetting.selectedVideoId
            ? { exact: mediaSetting.selectedVideoId }
            : void 0,
        },
        audio: {
          deviceId: mediaSetting.selectedAudioId
            ? { exact: mediaSetting.selectedAudioId }
            : void 0,
        },
      });
      window.localStream = stream;
      // deviceId: mediaSetting.selectedVideoId
      //       ? { exact: mediaSetting.selectedVideoId }
      //       : void 0,

      if (selectedMode === 'Webcam Only') {
        console.log('Webcam Only called');
        setStreaming(stream);
        setRecorder(new MediaRecorder(stream));
        setshowbutton(true);
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }

      if (selectedMode === 'Screen + Webcam') {
        setPicInPicStream(stream);
        setshowbutton(true);
        picInPicRef.current.srcObject = stream;
        picInPicRef.current.play();
      }
    } catch (err) {
      console.log('fetching webcam error...', err);
      setWebCamErrorDialog(true);
    }
  };

  const fetchDisplayMedia = async () => {
    setshowbutton(false);
    try {
      // let stream = await navigator.mediaDevices.getDisplayMedia({
      //   video: true,
      //   audio: true,
      // });
      // let audioTrack, videoTrack, stream; // define initial variable here for ability to call stop() on each track
      let videoStream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          MediaSource: 'screen',
        },
      });

      let audioStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          sampleSize: 100,
          frameRate: { max: 30 },
          noiseSuppression: true,
          echoCancellation: true,
          channelCount: 2,
          deviceId: mediaSetting.selectedAudioId
            ? { exact: mediaSetting.selectedAudioId }
            : void 0,
          // 'f62373ff9e03e1f4b2c0b24214120a8898da2cc9c7915aa143bd86e916e68f4c',
        },
      });

      var audioTrack = audioStream.getAudioTracks()[0];

      videoStream.addTrack(audioTrack);
      setStreaming(videoStream);

      let recorder = new MediaRecorder(videoStream);
      setRecorder(recorder);

      setshowbutton(true);

      // setStreaming(stream);
      // setRecorder(new MediaRecorder(stream, { mimeType: 'video/mp4' }));
      videoRef.current.srcObject = videoStream;
    } catch (e) {
      console.log('error opening screenshare: ', e.message);
      setScreenSharingErrorDialog(true);
    }
  };

  // https://ourcodeworld.com/articles/read/1249/how-to-solve-javascript-exception-failed-to-execute-requestpictureinpicture-on-htmlvideoelement-metadata-for-the-video-element-are-not-loaded-yet

  const getPictureInPicture = async () => {
    try {
      if (picInPicRef.current !== document.pictureInPictureElement) {
        await picInPicRef.current.requestPictureInPicture();
      }
      // If already playing exit mide
      else {
        await document.exitPictureInPicture();
      }
    } catch (error) {
      console.log('picture in picture error:', error);
    } finally {
      //enable toggle at last
    }
  };

  const getRandomArbitrary = (min = 1000000, max = 9999999) => {
    return Math.random() * (max - min) + min;
  };

  // function calculateProgress() {
  //   let delta = new Date().getTime() - recordTimestamp.getTime();
  //   this.progress = Math.min(delta / maxRecord * 1e3), 1);
  // }
  // clearTimeouts: function() {
  //   this.recordTimeout = window.clearTimeout(this.recordTimeout), this.recordStopTimeout = window.clearTimeout(this.recordStopTimeout), this.progressTimer = window.clearInterval(this.progressTimer);
  // },
  // this.isRecording = !0, this.recordTimestamp = new Date(), this.progressTimer = setInterval(this.calculateProgress, 1e3)
  // stop: function(e) {
  //  new Date().getTime() - this.recordTimestamp.getTime() > this.minRecord * 1e3 && (this.clearTimeouts(), this.recorder.stop());
  //},

  const getRecordedMedia = () => {
    const blobs_recorded = [];
    if (
      selectedMode === 'Share Screen' ||
      (selectedMode === 'Screen + Webcam' && recorder)
    ) {
      recorder.ondataavailable = (e) => blobs_recorded.push(e.data);
      recorder.stop();
      recorder.addEventListener('stop', (e) => {
        setRecording('stop');
        const recording = new File(
          blobs_recorded,
          `temp-recording${getRandomArbitrary()}.mp4`,
          {
            type: 'video/mp4',
          }
        );

        let video_local = URL.createObjectURL(
          new Blob(blobs_recorded, {
            type: 'video/mp4',
          })
        );

        const formData = new FormData();
        formData.append('video', recording);

        setBlobVideos([
          ...blobVideos,
          {
            name: selectedMode + '.webm',
            type: 'video/webm',
            origName: selectedMode + '.webm',
            dataURL: video_local,
            formData: formData,
            saveToCloud: true,
            duration: Math.round(
              (new Date().getTime() -
                startEndRecordTime?.current?.start.getTime()) /
                1000
            ),
          },
        ]);

        setPreViewVideo({
          name: selectedMode + '.webm',
          type: 'video/webm',
          origName: selectedMode + '.webm',
          dataURL: video_local,
          formData: formData,
          saveToCloud: true,
          duration: Math.round(
            (new Date().getTime() -
              startEndRecordTime?.current?.start.getTime()) /
              1000
          ),
        });

        restartStartEndTime();
        setStep(2);
        setSelectedMode('');
      });
    }

    if (selectedMode === 'Webcam Only' && recorder) {
      recorder.ondataavailable = (e) => blobs_recorded.push(e.data);
      recorder.stop();
      recorder.addEventListener('stop', (e) => {
        setRecording('stop');
        const recording = new File(
          blobs_recorded,
          `temp-recording${getRandomArbitrary()}.mp4`,
          {
            type: 'video/mp4,',
          }
        );

        let video_local = URL.createObjectURL(
          new Blob(blobs_recorded, {
            type: 'video/mp4',
          })
        );

        const formData = new FormData();
        formData.append('video', recording);

        setBlobVideos([
          ...blobVideos,
          {
            name: selectedMode + '.mp4',
            type: 'video/mp4',
            origName: selectedMode + '.mp4',
            dataURL: video_local,
            formData: formData,
            saveToCloud: true,
            duration: Math.round(
              (new Date().getTime() -
                startEndRecordTime?.current?.start.getTime()) /
                1000
            ),
          },
        ]);

        setPreViewVideo({
          name: selectedMode + '.webm',
          type: 'video/webm',
          origName: selectedMode + '.webm',
          dataURL: video_local,
          formData: formData,
          saveToCloud: true,
          duration: Math.round(
            (new Date().getTime() -
              startEndRecordTime?.current?.start.getTime()) /
              1000
          ),
        });

        restartStartEndTime();
        setStep(2);
      });
    }
  };

  let countdown_number;

  const countdown_trigger = () => {
    //videoRef.current.srcObject = streaming;

    if (countdown_number > 0) {
      countdown_number--;
      countRef.current.innerHTML = countdown_number - 1;
      // audio.play(); // Play Clock Audio Sound
      //videoRef.current.srcObject = streaming;

      if (countdown_number > 0) {
        setTimeout(countdown_trigger, 1000);
        //videoRef.current.srcObject = streaming;
      }
      //videoRef.current.srcObject = streaming;

      if (countdown_number === 0) {
        //setShowCounter(false);
        countRef.current.innerHTML = '';
        //let recorder2 = new MediaRecorder(streaming);
        recorder.start();
        //setRecorder(recorder2);
        buttonref.current.className = '';
        startEndRecordTime.current = { start: new Date() };
        //videoRef.current.srcObject = streaming;
      }
    }
  };

  const countdown_init = () => {
    videoRef.current.srcObject = streaming;
    buttonref.current.className = 'hideme';
    countdown_number = 5;
    countdown_trigger();
  };
  const handleCloseDialog = () => {
    _dispatch({
      type: 'SHOW_SCREEN_RECORDING',
      payload: false,
    });
    setShowCounter(false);
    if (closeDialogue) {
      closeDialogue();
    }
  };

  const handleTimeUpdate = () => {
    const { currentTime, duration } = videoRef.current || {};
  };

  const uploadToCloud = () => {
    setCloudLibrary([...cloudLibrary, previewVideo]);

    setBlobVideos([]);
  };

  const uploadToCloudByImportVideo = () => {
    setShowProgressIndicator(true);
    uploadToCloudLibrary();
  };
  const { community_id } = useParams();

  async function uploadVideo(
    setCloudLibraryProgress,
    addScreen = false,
    prefix = `recordings/${getRandomId()}`
  ) {
    setShowProgressIndicator(true);
    console.log('uploadVideo');
    // data to be returned
    let filteredCloudLibrary = [];
    let outCurrVid = '';
    if (
      previewVideo?.saveToCloud ||
      previewVideo?.dataURL === currVid[0]?.source
    ) {
      if (previewVideo.formData || addScreen) {
        const validUrl = new RegExp(
          /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
        );
        try {
          let url;
          let uploadedToFirebase = false;

          if (validUrl.test(previewVideo.dataURL)) {
            // if we uploaded from a url
            url = previewVideo.dataURL;
            console.log('url', url);
          } else {
            url = await uploadBlobToStorage(
              previewVideo.dataURL,
              previewVideo.name,
              prefix,
              previewVideo.type,
              previewVideo.origName,
              (progress, timeestimate) => {
                // setCloudLibraryProgress({
                //   uploading: true,
                //   idx: i,
                //   progress: progress * 0.75,
                // });
                console.log('progress', progress);
                setUploadingProgress(Math.round(progress));
                if (progress >= 99) {
                  setShowProgressIndicator(false);
                }
              },
              false
            );
            uploadedToFirebase = true;
            handleCloseDialog();
            _dispatch({
              type: 'SHOW_SCREEN_RECORDING',
              payload: false,
            });

            // if (previewVideo?.duration) {
            //   url = url +   previewVideo?.duration ? `#t=0,${previewVideo?.duration}` : '';
            // }
          }
          onVideoUrl(url);
          onVideoTitleAndUrl({ title: recordedVideoTitle, url: url });
          setRecordedVideoUrl(url);

          // if dontEncode=true then dont encode
          if (dontEncode) {
            return;
          }

          const response = await axios({
            method: 'POST',
            url: `${API_HOST}/upload-url?prefix=${prefix}&copy=${!uploadedToFirebase}`,
            data: { video: url },
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              console.log(
                'response running',
                Math.floor((loaded * 95) / total)
              );

              // setUploadingProgress(0.75 + loaded * 0.25);
            },
            headers: {
              'Content-Type': 'application/json',
            },
          });

          console.log('response', response);

          if (response.data.status == 'success') {
            setShowProgressIndicator(false);
            setUploadingProgress(0);
            setBlobVideos([]);
            handlePreviewBack();
            setPreViewVideo(null);
            console.log('response success');

            //updates the currVid if this is it
            if (currVid[0].source === previewVideo.dataURL) {
              outCurrVid = url + '?&coconut_id=' + response?.data?.coconut_job;

              setCurrVid([
                {
                  source: url + '?&coconut_id=' + response?.data?.coconut_job,
                  type: previewVideo.type,
                  formData: previewVideo.formData ? previewVideo.formData : '',
                },
              ]);
            }
            handleCloseDialog();

            console.log('URL FINAL', url);

            // uploadToCloud();

            // filteredCloudLibrary.push(temp[i]);
          } else {
            //console.log('response = ', response);
          }
        } catch (error) {
          //console.log(error);
          handleSnackbar(`Unsuccessfully upload. Error: ${error}`, 'error');
        }
      } else {
        if (currVid[0].source === previewVideo.dataURL) {
          outCurrVid = previewVideo.dataURL;
        }
        // filteredCloudLibrary.push(previewVideo);
      }
    }
  }

  // Upload Import Vidoes on Cloud library
  async function uploadToCloudLibrary(
    setCloudLibraryProgress,
    addScreen = false
  ) {
    const [category, screen] = screenToEdit.split('.');

    const prefix =
      'magnets' +
      '/' +
      validFileName(magnetId) +
      '/' +
      validFileName(category) +
      '/' +
      validFileName('Main Intro');

    // data to be returned
    let filteredCloudLibrary = [];
    let outCurrVid = '';
    for (let i = 0; i < blobVideos.length; i++) {
      if (
        blobVideos[i].saveToCloud ||
        blobVideos[i].dataURL === currVid[0].source
      ) {
        if (blobVideos[i].formData || addScreen) {
          // //Sending file to google cloud platform
          _dispatch({
            type: 'ENCODING_Q',
            payload: [...encodingQ, `${category}.${screen}`],
          });

          const validUrl = new RegExp(
            /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
          );
          try {
            let url;
            let uploadedToFirebase = false;

            if (validUrl.test(blobVideos[i].dataURL)) {
              // if we uploaded from a url
              url = blobVideos[i].dataURL;
            } else {
              // if we need to upload to firebase
              // _dispatch({
              //   type: 'SET_CURR_UPLOADING_VIDEO',
              //   payload: blobVideos[i].name,
              // });
              url = await uploadBlobToStorage(
                blobVideos[i].dataURL,
                blobVideos[i].name,
                prefix,
                blobVideos[i].type,
                blobVideos[i].origName,
                (progress, timeestimate) => {
                  // progress updates
                  //

                  // setCloudLibraryProgress({
                  //   uploading: true,
                  //   idx: i,
                  //   progress: progress * 0.75,
                  // });
                  _dispatch({
                    type: 'LOAD_SCREEN',
                    payload: {
                      name: blobVideos[i].name,
                      progress: (progress * 0.75).toFixed(0),
                      timeestimate,
                      screen_name: name,
                      //[screen_id]: (progress * 0.75).toFixed(0),
                    },
                  });
                }
              );
              uploadedToFirebase = true;
            }

            const response = await axios({
              method: 'POST',
              url: `${API_HOST}/upload-url?prefix=${prefix}&copy=${!uploadedToFirebase}`,
              data: { video: url },
              onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                // setCloudLibraryProgress({
                //   uploading: true,
                //   idx: i,
                //   progress: 0.75 + loaded * 0.25,
                // });
                _dispatch({
                  type: 'LOAD_SCREEN',
                  payload: {
                    route: blobVideos[i].route,
                    name: blobVideos[i].name,
                    progress: Math.floor((loaded * 95) / total),
                    //[screen_id]: Math.floor((loaded * 95) / total),
                  },
                });
                setUploadingProgress(0.75 + loaded * 0.25);
              },
              headers: {
                'Content-Type': 'application/json',
              },
            });

            // _dispatch({
            //   type: 'ENCODING_Q',
            //   payload: encodingQ.filter(
            //     (val) => val !== `${category}.${screen}`
            //   ),
            // });

            if (response.data.status == 'success') {
              setShowProgressIndicator(false);
              setUploadingProgress(0);

              // _dispatch({
              //   type: 'LOAD_SCREEN',
              //   payload: {
              //     route: blobVideos[i].route,
              //     name: blobVideos[i].name,
              //     progress: 100,
              //     //[screen_id]: 100,
              //   },
              // });

              // _dispatch({
              //   type: 'POTENTIALLY_UPLOADING',
              //   payload: potentiallyUploading - 1,
              // });

              //updates the currVid if this is it
              if (currVid[0].source === blobVideos[i].dataURL) {
                outCurrVid =
                  url + '?&coconut_id=' + response?.data?.coconut_job;
                setCurrVid([
                  {
                    source: url + '?&coconut_id=' + response?.data?.coconut_job,
                    type: blobVideos[i].type,
                    formData: blobVideos[i].formData
                      ? blobVideos[i].formData
                      : '',
                  },
                ]);
              }

              // updated filtered cloud library and local library
              const temp = blobVideos;
              temp[i] = {
                dataURL: url + '?&coconut_id=' + response?.data?.coconut_job,
                name: blobVideos[i].name,
                saveToCloud: true,
                type: 'video/mp4',
              };

              // uploadToCloud();

              // filteredCloudLibrary.push(temp[i]);
            } else {
              //console.log('response = ', response);
            }
          } catch (error) {
            //console.log(error);
            handleSnackbar(`Unsuccessfully upload. Error: ${error}`, 'error');
          }
        } else {
          if (currVid[0].source === blobVideos[i].dataURL) {
            outCurrVid = blobVideos[i].dataURL;
          }
          // filteredCloudLibrary.push(blobVideos[i]);
        }
      }
    }
    // _dispatch({ type: 'SHOW_PROGRESS_BAR', payload: false });
    // return {
    //   currVidURL: outCurrVid || currVid[0].source,
    //   filteredCloudLibrary: filteredCloudLibrary,
    // };
  }
  const RecorderComponent = () => {
    const [openNote, setOpenNote] = useState(false);
    console.log('recorder_rerender');
    const readFile = (e) => {
      e.preventDefault();
      // let reader = new FileReader();'
      const files = e.target.files;
      console.log('readfile', files);
      let file = files[0];
      let duration;
      const loadvideo = () => {
        const name = file.name;
        const type = file.type;
        //setShowProgressIndicator(true);
        //validateFile(file)
        //Convert file into a data url
        const dataURL = URL.createObjectURL(file);
        // //Sending file to google cloud platform
        const formData = new FormData();
        formData.append('video', file);

        setBlobVideos([
          ...blobVideos,
          {
            name: name,
            type: type,
            origName: selectedMode + '.mp4',
            dataURL: dataURL,
            formData: formData,
            saveToCloud: true,
            duration,
          },
        ]);

        setPreViewVideo({
          name: name,
          type: type,
          origName: selectedMode + '.webm',
          dataURL: dataURL,
          formData: formData,
          saveToCloud: true,
          duration,
        });

        restartStartEndTime();
        setStep(2);
      };
      var video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        console.log('duration', video.duration);
        duration = video.duration;
        if (video.duration > 7000) {
          toast.error('Invalid Video! video should be less than 10 second');
        } else {
          loadvideo();
        }
      };

      video.src = URL.createObjectURL(file);
    };

    return (
      <>
        {fromPage === 'upload-video-import' && (
          <div className="hdr-blk">
            <div className="slogn font s26 b5 color">
              What gets you up the morning
            </div>
          </div>
        )}
        <div className="hdr flex aic">
          {modes.map((item) => (
            <button
              className={`cleanbtn hdr-btn flex flex-col aic ${
                item.label === selectedMode ? 'on' : ''
              }`}
              onClick={() => {
                if (recording !== 'start') {
                  setSelectedMode(item.label);
                }
              }}
            >
              <div className={`ico c000 ${item.icon}`} />
              <div className="lbl font c000">{item.label}</div>
            </button>
          ))}
        </div>
        <div className="record-container">
          <div className="screen-layout rel">
            {/* Video Action Buttons */}
            <div className="video-acitons flex flex-col aic">
              <button
                className={`cleanbtn video-action-btn  ${
                  onCamera ? 'icon-video' : 'icon-video-off'
                }`}
                onClick={() => setOnCamera(!onCamera)}
                disabled={selectedMode === 'Webcam Only'}
              />
              <button
                className={`cleanbtn video-action-btn ${
                  onMicroPhone ? 'icon-mic' : 'icon-mic-off'
                }`}
                onClick={() => setOnMicroPhone(!onMicroPhone)}
                disabled={selectedMode === 'Webcam Only'}
              />
              <button
                onClick={() => {
                  setOnSetting(!onSetting);
                  setMediaSetting({
                    ...mediaSetting,
                    selectedAudioId: currentMediaDevices?.audio[0]?.id,
                    selectedVideoId: currentMediaDevices?.video[0]?.id,
                  });
                }}
                className="cleanbtn video-action-btn fa fa-gear "
              />
            </div>

            {/* Video Block */}
            <div className="video-block rel">
              <button
                className="note-btn"
                onClick={() => setOpenNote(!openNote)}
              >
                {openNote ? (
                  <>
                    <span className="icon-clear icon s14" />
                    <span>Close Note</span>
                  </>
                ) : (
                  <>
                    <span className="icon-create icon s12" />
                    <span>Add Note</span>
                  </>
                )}
              </button>
              <div
                className={`video-block-overlay ${openNote ? 'show' : 'hide'}`}
              >
                <div className="meta flex flex-col">
                  <div className="title font s22 b6 cfff">{questionToAsk}</div>
                  <div className="field">
                    <textarea
                      // onChange={(e) => {
                      //   const updatedQuestion = review.question.map(
                      //     (field, i) => {
                      //       if (i == questionIndex) {
                      //         return { ...field, answer: e.target.value };
                      //       }
                      //       return field;
                      //     }
                      //   );

                      //   setReview({ ...review, question: updatedQuestion });
                      // }}
                      className="iput area font s15 cfff"
                      placeholder="Add your notes here"
                      // value={review?.question[questionIndex]?.answer}
                    />
                  </div>
                </div>
              </div>
              <video
                ref={videoRef}
                onTimeUpdate={handleTimeUpdate}
                className="video"
                muted
                autoPlay
              >
                {/* <source src="" type="video/mp4" /> */}
                Your browser does not support HTML5 Video.
              </video>

              {/* Picture In Picture Video block */}
              <video ref={picInPicRef} className="video hid" muted autoPlay>
                {/* <source src="" type="video/mp4" /> */}
                Your browser does not support HTML5 Video.
              </video>
              {/* <div className="vid-stamp flex aic abs">
                <div className="item flex aic">
                  <div className="icon-clock ico s10 cfff" />
                  <div className="tim font s11 cfff">
                    {calculateMinSecs(
                      blobVideos
                        .filter((a) => a?.duration)
                        .reduce((a, b) => a + b),
                      0
                    )}{' '}
                    / 8:00
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="ftr flex aic justify-center">
            <div className="text-xl font-bold	" ref={countRef}>
              {showCounter && '3'}{' '}
            </div>
            {showbutton && (
              <div>
                <div ref={buttonref} className="">
                  <button
                    onClick={() => {
                      if (recording === 'stop' || recording == '') {
                        setRecording('start');
                      } else {
                        setRecording('stop');
                      }
                    }}
                    className={`cleanbtn recording-btn flex aic anim`}
                  >
                    {recording === 'stop' || recording === '' ? (
                      <>
                        <div className="dot" />
                        <div className="lbl font s14 b5 cfff upc">
                          Start Recording
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="ico icon-pause cfff" />
                        <div className="lbl font s14 b5 cfff upc">
                          Stop Recording{' '}
                        </div>
                      </>
                    )}
                  </button>
                </div>
                {recording === 'stop' ||
                  (recording === '' && (
                    <>
                      <p className="flex justify-center">or</p>
                      <div
                        //style={{marginLeft : '35px'}}
                        className="flex justify-center mt-2"
                      >
                        <input
                          accept=".mp4,.webm,.mov"
                          multiple
                          style={{ display: 'none' }}
                          name="video"
                          id="contained-button-file"
                          type="file"
                          onChange={readFile}
                        />
                        <label htmlFor="contained-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            // startIcon={<SearchIcon />}
                          >
                            UPLOAD A VIDEO
                          </Button>
                        </label>
                      </div>
                    </>
                  ))}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const handlePreviewBack = () => {
    setStep(1);
    setSelectedMode('Webcam Only');
  };

  const PreviewComponent = () => {
    return (
      <div className="preview-container">
        {fromPage === 'upload-video-import' && (
          <div className="hdr-blk">
            <div className="slogn font s38 b5 color">Preview</div>
            <div className="sub-title font s18 b3 c333">
              Press continue to upload this video, or hit the record video
              button to record next video. We'll edit your video in the next
              step.
            </div>
          </div>
        )}
        <div className="wrap flex flex-col">
          <div className="meta flex aic">
            <button
              onClick={handlePreviewBack}
              className="back-btn cleanbtn s20 c000 flex aic icon-arrow-left1"
            />
          </div>
          <div className="screen-layout rel">
            {/* Video Block */}
            <div className="video-block rel">
              <video
                src={`${
                  previewVideo?.dataURL ||
                  blobVideos[blobVideos.length - 1]?.dataURL
                }#t=${
                  previewVideo?.duration ||
                  blobVideos[blobVideos.length - 1]?.duration
                }`}
                className="video"
                controls
                muted={false}
                autoPlay
              >
                <source
                  src="https://d1lrjy0kkf9pj7.cloudfront.net/app/media/screenVB/WELCOME/HD.mp4"
                  type="video/mp4"
                />
                Your browser does not support HTML5 Video.
              </video>
              {/* <div className="vid-stamp flex aic abs">
                <div className="item flex aic">
                  <div className="icon-clock ico s10 cfff" />
                  <div className="tim font s11 cfff">0:00 / 8:00</div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="ftr flex aic">
            {fromPage === 'upload-video-import' ? (
              <>
                <button
                  onClick={uploadToCloudByImportVideo}
                  className="but cleanbtn flex aic"
                >
                  <div className="lbl font s15 b4 cfff">Continue</div>
                </button>
                <button
                  className="but next cleanbtn flex aic"
                  onClick={handlePreviewBack}
                >
                  <div className="lbl font s15 b4 cfff">Record Next</div>
                </button>
              </>
            ) : (
              <>
                {' '}
                <button
                  onClick={handlePreviewBack}
                  className="but cleanbtn flex aic"
                >
                  <div className="icon-arrow-left1 ico s20 c000" />
                  <div className="lbl font s14 upc b6 c000">Try Again</div>
                </button>
                {'  '}
                {(previewVideo?.dataURL ||
                  blobVideos[blobVideos.length - 1]?.dataURL) && (
                  <a
                    download="UserExport.webm"
                    href={
                      previewVideo?.dataURL ||
                      blobVideos[blobVideos.length - 1]?.dataURL
                    }
                  >
                    <button
                      onClick={() => console.log('attempt to download')}
                      className="but cleanbtn flex aic"
                    >
                      <div className="icon-arrow-down1 ico s20 c000" />
                      <div className="lbl font s14 upc b6 c000">Download</div>
                    </button>{' '}
                    {'  '}
                  </a>
                )}
                <button
                  onClick={() => {
                    setIsVideoTitleDialogueOpen(true);
                    /*
                    try {
                      uploadToCloud();
                      setIsVideoTitleDialogueOpen(true);
                    } catch (e) {
                      try {
                        uploadVideo();
                        setIsVideoTitleDialogueOpen(true);
                        console.log('error_uploadToCloud', e);
                      } catch (error) {
                        console.log('error_uploadVideo', error);
                      }
                    }
                    */
                    // handleCloseDialog();
                  }}
                  className="but cleanbtn flex aic"
                >
                  <div className="icon-arrow-right1 ico s20 c000" />
                  <div className="lbl font s14 upc b6 c000">Next</div>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const closemediaSetting = () => {
    setOnSetting(false);
    setSelectedMode('Webcam Only');
  };

  function leftFillNum(num, targetLength) {
    return num.toString().padStart(targetLength, 0);
  }
  function calculateMinSecs(seconds) {
    return `${Math.round(seconds / 60)}:${Math.round(seconds % 60, 2)}`;
  }

  const SendRecordingScreen = () => {
    return (
      <div className="record-joy-dialog flex flex-col rel">
        <button
          onClick={handleCloseDialog}
          className="cleanbtn cross-btn flex aic fontr s30"
        >
          &times;
        </button>
        <>{step === 1 ? <RecorderComponent /> : <PreviewComponent />}</>
        <div className="recorded-blk videos flex aic">
          {blobVideos.length > 0 ? (
            <div className="wrap flex aic">
              {blobVideos.map((item, index) => {
                return (
                  <div className="item rel" key={index}>
                    <button
                      onClick={() => {
                        let videos = blobVideos.filter((v, i) => i !== index);
                        setBlobVideos(videos);
                      }}
                      className="cleanbtn font but icon-cross1"
                    ></button>
                    <label
                      className="vid cleanbtn rel"
                      onClick={() => {
                        setSelectedMode('');
                        setStep(2);
                        setPreViewVideo(item);
                      }}
                    >
                      <div className="overlay" />
                      <video
                        src={`${item.dataURL}#t=${item?.duration}`}
                        className="video"
                        muted
                      />
                      <div className="stamp font">
                        {calculateMinSecs(item?.duration)}
                      </div>
                    </label>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="label font s15 b4">
              Your recorded sections will be shown here
            </div>
          )}
        </div>
      </div>
    );
  };

  // var percentageComplete = 80;
  var strokeDashOffsetValue = 100 - uploadingProgress;

  return (
    <>
      <RecordedVideoTitleDialog
        open={isVideoTitleDialogueOpen}
        onClose={(title) => {
          if (title?.length === 0)
            return alert('Please Enter a Title for the video !');
          setRecordedVideoTitle(title);
          setIsVideoTitleDialogueOpen(false);
        }}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarColor}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {fromPage === 'upload-video-import' || inline == true ? (
        <SendRecordingScreen />
      ) : (
        <Dialog
          open={showScreenRecording}
          keepMounted
          // onClose={handleCloseDialog}
          maxWidth={'xl'}
          hideBackdrop={false}
          BackdropProps={{
            style: { backgroundColor: 'rgba(17, 24, 39, 0.9)' },
          }}
        >
          <SendRecordingScreen />
        </Dialog>
      )}

      {/* Screen Sharing Not Allow then Display Dialog */}
      <Dialog
        open={screenSharingErrorDialog}
        keepMounted
        maxWidth={'xl'}
        hideBackdrop={false}
        BackdropProps={{ style: { backgroundColor: 'rgba(17, 24, 39, 0.9)' } }}
      >
        <div className="screen-error-dialog flex flex-col">
          <div className="hdr flex aic">
            <div className="title font s24 b4 c000">Error</div>
          </div>
          <div className="content flex flex-col">
            <div className="msg flex aic">
              <div className="ico">&#x21;</div>
              <div className="lbl font s14 b4 c000">
                It seems we cannot access your screen. Sorry for the
                inconvenience! Please visit this
              </div>
            </div>
          </div>
          <div className="ftr flex aic">
            <button
              onClick={() => {
                setSelectedMode('Webcam Only');
                setScreenSharingErrorDialog(false);
              }}
              className="cleanbtn close-btn font s15 b5"
            >
              OK
            </button>
          </div>
        </div>
      </Dialog>

      {/* WebCam Error Dialog */}
      <Dialog
        open={webCamErrorDialog}
        keepMounted
        maxWidth={'xl'}
        hideBackdrop={false}
        BackdropProps={{ style: { backgroundColor: 'rgba(17, 24, 39, 0.9)' } }}
      >
        <div className="screen-error-dialog flex flex-col">
          <div className="hdr flex aic">
            <div className="title font s24 b4 c000">Error</div>
          </div>
          <div className="content flex flex-col">
            <div className="msg flex aic">
              <div className="ico">&#x21;</div>
              <div className="lbl font s14 b4 c000">
                Impossible to get webcam device
              </div>
            </div>
          </div>
          <div className="ftr flex aic">
            <button
              onClick={() => {
                setSelectedMode('Webcam Only');
                setWebCamErrorDialog(false);
              }}
              className="cleanbtn close-btn font s15 b5"
            >
              OK
            </button>
          </div>
        </div>
      </Dialog>

      {/* Media Setting Dialog */}
      <Dialog
        open={onSetting}
        keepMounted
        fullWidth
        onClose={() => setOnSetting(false)}
        hideBackdrop={false}
        BackdropProps={{ style: { backgroundColor: 'rgba(17, 24, 39, 0.9)' } }}
      >
        <div className="media-setting-dialog flex flex-col">
          <div className="hdr flex aic">
            <div className="title font s22 b6 black">Setting</div>
          </div>
          <div className="wrap flex flex-col">
            {/* Audio Input */}
            <div className="feild flex flex-col">
              <div className="lbl font s14 c000">Audio Input</div>
              <button
                className="cleanbtn flex aic font s14 c000 cstm-slt rel"
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentMediaDevices({
                    ...currentMediaDevices,
                    openAudioList: !currentMediaDevices.openAudioList,
                    openVideoList: false,
                  });
                }}
              >
                <div
                  className={`arrow-ico s12 abs anim c000 ${
                    currentMediaDevices.openAudioList
                      ? 'icon-chevron-up1'
                      : 'icon-chevron-down1'
                  }`}
                />
                {!mediaSetting.selectedAudioId && (
                  <div className="lb font s14 c000 wordwrap">Select Audio</div>
                )}
                {currentMediaDevices?.audio?.map(
                  (item) =>
                    mediaSetting.selectedAudioId == item.id && (
                      <div className="lb wordwrap">{item.label}</div>
                    )
                )}
                {currentMediaDevices.openAudioList && (
                  <div className="options flex flex-col abs">
                    {currentMediaDevices?.audio?.map((item) => (
                      <button
                        className="cleanbtn item flex aic anim"
                        onClick={() => {
                          setCurrentMediaDevices({
                            ...currentMediaDevices,
                            openAudioList: !currentMediaDevices.openAudioList,
                          });
                          setMediaSetting({
                            ...mediaSetting,
                            selectedAudioId: item.id,
                          });
                        }}
                      >
                        <div className="txt font s14 c000 wordwrap">
                          {item.label}
                        </div>
                      </button>
                    ))}
                  </div>
                )}
              </button>
            </div>

            {/* Video Input */}
            <div className="feild flex flex-col">
              <div className="lbl font s14 c000">Video Input</div>
              <button
                className="cleanbtn flex aic font s14 c000 cstm-slt rel"
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentMediaDevices({
                    ...currentMediaDevices,
                    openAudioList: false,
                    openVideoList: !currentMediaDevices.openVideoList,
                  });
                }}
              >
                <div
                  className={`arrow-ico s12 abs anim c000 ${
                    currentMediaDevices.openVideoList
                      ? 'icon-chevron-up1'
                      : 'icon-chevron-down1'
                  }`}
                />
                {!mediaSetting.selectedVideoId && (
                  <div className="lb font s14 c000 wordwrap">Select</div>
                )}
                {currentMediaDevices?.video?.map(
                  (item) =>
                    mediaSetting.selectedVideoId == item.id && (
                      <div className="lb wordwrap">{item.label}</div>
                    )
                )}
                {currentMediaDevices.openVideoList && (
                  <div className="options flex flex-col abs">
                    {currentMediaDevices?.video?.map((item) => (
                      <button
                        className="cleanbtn item flex aic anim"
                        onClick={() => {
                          setCurrentMediaDevices({
                            ...currentMediaDevices,
                            openVideoList: !currentMediaDevices.openVideoList,
                          });
                          setMediaSetting({
                            ...mediaSetting,
                            selectedVideoId: item.id,
                          });
                        }}
                      >
                        <div className="txt font s14 c000 wordwrap">
                          {item.label}
                        </div>
                      </button>
                    ))}
                  </div>
                )}
              </button>
            </div>

            {/* Activity Noise */}
            <button
              className="cleanbtn feild active-noice flex aic"
              onClick={() =>
                setMediaSetting({
                  activeNoise: !mediaSetting.activeNoise,
                })
              }
            >
              <div className="lbl font s14 c000">
                Activate noise cancellation
              </div>
              <button
                className={`cleanbtn toggle-checkbox rel ${
                  mediaSetting.activeNoise ? 'on' : 'off'
                }`}
              >
                <div
                  className={`circle abs ${
                    mediaSetting.activeNoise ? 'gooeyIn' : 'gooeyOut'
                  }`}
                />
              </button>
            </button>

            <div className="ftr flex aic">
              <button
                onClick={closemediaSetting}
                className="cleanbtn close-btn font s14 cfff"
              >
                Save &#38; Close
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Upload to Cloud Indicator */}
      <div
        className={`uploading-overlay anim ${
          showProgressIndicator ? 'sho' : 'hid'
        }`}
      >
        <div className="circle-progress-bar">
          <svg className="circle" viewBox="-1 -1 38 38">
            <circle
              cx="19"
              cy="19"
              r="15.9155"
              className="progress-bar__background"
            />
            <circle
              cx="19"
              cy="19"
              r="15.9155"
              className="progress-bar__progress"
              style={{ strokeDashoffset: strokeDashOffsetValue }}
            />
          </svg>
          <div className="label font s36 b6 c000">{`${uploadingProgress}%`}</div>
        </div>
      </div>
    </>
  );
}

export default SendRecording;
