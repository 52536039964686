import React from 'react';
import AnnualCost from './AnnualCost';
import FirstSlider from './FirstSlider';
import NewHeader from './NewHeader';
import NewHero from './NewHero';
import Output from './Output';
import Outsource from './Outsource';
import Solution from './Solution';
import Subscribe from './Subscribe';
import Switchto from './Switchto';
import Talk from './Talk';

const NewHome = () => {
  return (
    <div className=" min-h-screen w-full overflow-x-hidden">
      <div
        className=" w-full h-auto"
        style={{
          background:
            'linear-gradient(360deg, rgba(134, 242, 170, 0.7) -32.25%, rgba(134, 242, 170, 0) 77.39%), #FFFFFF',
        }}
      >
        <NewHeader />
        <NewHero />
      </div>
      <FirstSlider />
      <AnnualCost />
      <Subscribe />
      <Solution />
      <Talk />
      <Output />
      <Outsource />
      <Switchto />
    </div>
  );
};

export default NewHome;
