import React, { useEffect, useRef, useState } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { DonutChart, ReverseBarChart } from '../../components/charts';
import { getanalyicsbigquery, count_details_from_rows } from './utils';
import axios from 'axios';
import useCacheState from '../../hooks/useCacheState';

const TourAnalysisComponent = ({
  magnetUuid,
  filterdate,
  community_id,
  mangetDetails,
}) => {
  // Data from backend
  const [categoryData, setCategoryData] = useCacheState(
    undefined,
    'analytics_categoryData',
    community_id
  );

  // Bar chart
  const [barChartData, setBarChartData] = useCacheState(
    undefined,
    'analytics_barChartData',
    community_id
  );

  const [form_data, setform_data] = useCacheState(
    undefined,
    'analytics_formData',
    community_id
  );

  useEffect(() => {
    if (!magnetUuid || !mangetDetails) {
      return;
    }
    // get tours
    count_details_from_rows(
      magnetUuid,
      mangetDetails,
      setBarChartData,
      setCategoryData
    );
  }, [magnetUuid, mangetDetails]);

  function handleCategorySelection(selection) {
    if (selection[0][0] === undefined) {
      setBarChartData({
        title: 'All Screens',
        titles: categoryData.allScreenNames,
        data: categoryData.allScreenTotals,
      });
    } else {
      setBarChartData({
        title: categoryData.categoryNames[selection],
        titles: categoryData.categoryScreenNames[selection],
        data: categoryData.categoryScreenTotals[selection],
      });
    }
  }
  const [barChartSize, setBarChartSize] = useState(10);

  const getData = async () => {
    let form_view_res = await getanalyicsbigquery(
      'select * from  ( SELECT details_form_title, details_form_route,details_open_tour_type,count(*) as submitted FROM `leasemagnets-app.default.events`where details_form_title is not null ' +
        "and event_type = 'form_submission' and magnet_uuid = " +
        `'${magnetUuid}'` +
        'AND utc_time > ' +
        `'${filterdate.date}' ` +
        'group by details_form_title,details_open_tour_type,  details_form_route ORDER BY `submitted` DESC ) ' +
        'as a left join ' +
        '(SELECT details_form_route,details_iframe, count(*) as views, details_iframe_src FROM `leasemagnets-app.default.events` where details_form_title is not null ' +
        ` and event_type = 'form_view' and magnet_uuid = '${magnetUuid}' ` +
        'AND utc_time > ' +
        `'${filterdate.date}' ` +
        'group by details_form_route,details_iframe,details_iframe_src ORDER BY `views` DESC) ' +
        'as b on a.details_form_route = b.details_form_route  ORDER BY submitted DESC,views DESC'
    );

    console.log('form_view_res', form_view_res);

    let forms = [];
    form_view_res?.res?.map((item, index) => {
      let route_link = '';
      try {
        route_link = `${
          item[1]
            ? typeof JSON.parse(item[1]) == 'object'
              ? JSON.parse(item[1])[0]
              : item[1]?.split(',')[0]
            : ''
        }.${
          item[1]
            ? typeof JSON.parse(item[1]) == 'object'
              ? JSON.parse(item[1])[1]
              : item[1]?.split(',')[1]
            : ''
        }`;
      } catch (error) {
        console.log('error', error);
      }
      forms.push({
        title: item[0],
        subtitle: item[1],
        views: item[6],
        submissions: item[3],
        open_tour_type: item[2] ? `${item[2]} : ` : null,
        iframe: item[5] ? 'Iframe : ' : 'Form : ',
        details_iframe_src: item[7],
        route_link: route_link,
      });
    });
    setform_data(forms);
  };
  console.log('form_data', form_data);
  useEffect(() => {
    getData();
  }, [filterdate]);
  return (
    <div className=" my-6 w-full grid grid-cols-1 lg:grid-cols-2 gap-5 px-4">
      <div>
        <div className=" mt-3">
          <p className=" text-gray-400">Form View Stats </p>
          <div className=" w-full py-4 px-4 border mt-3">
            <div className=" grid grid-cols-4 py-2">
              <div className=" col-span-2">
                <strong className=" text-sm">Form or MiniApp Title</strong>
              </div>
              <p className=" text-sm ml-5 text-center">Interactions</p>
              <p className=" text-sm text-center">Completions</p>
            </div>
            {form_data
              ? form_data.map((item) => (
                  <div className=" grid grid-cols-4 py-2">
                    <div className="col-span-2">
                      <a
                        href={
                          item?.details_iframe_src ||
                          `https://app.usetour.com/dashboard/${community_id}/tour#${item?.route_link}`
                        }
                        target="_blank"
                      >
                        <p>{item?.title.substring(0, 25)}</p>
                      </a>

                      <div className=" flex items-center">
                        {item?.route_link != '.' && (
                          <a
                            href={`https://app.usetour.com/dashboard/${community_id}/tour#${item?.route_link}`}
                            className="flex items-center gap-1 text-gray-500 border-b cursor-pointer hover:text-pr"
                          >
                            <BiLinkExternal /> {item?.subtitle}
                          </a>
                        )}
                      </div>
                    </div>
                    <h2 className=" text-2xl text-center">{item?.views}</h2>
                    <h2 className=" text-2xl text-center">
                      {item?.submissions}
                    </h2>
                  </div>
                ))
              : ''}
          </div>
        </div>
      </div>
      <div className="">
        <p className=" text-gray-400 text-sm pb-3">Path Analytics</p>
        <div className=" border p-4 flex items-center justify-center">
          <div className=" w-full lg:w-2/3">
            {/* <Chart
          options={ .options}
          series={chartOptions3.series}
          
          type="donut"
        /> */}
            {!categoryData ? (
              <button
                disabled
                type="button"
                className="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
              >
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#1C64F2"
                  />
                </svg>
                Loading Most Popular Categories ...
              </button>
            ) : (
              <DonutChart
                title="All Categories"
                labels={categoryData.categoryNames}
                data={categoryData.categoryTotals}
                selected={handleCategorySelection}
              />
            )}
          </div>
        </div>
        <div className=" border p-4 flex items-center mt-4 justify-center">
          <div className=" w-full">
            {/* <Chart
          options={chartOptions4.options}
          series={chartOptions4.series}
          type="bar"
          height={350}
        /> */}
            {barChartData && (
              <ReverseBarChart
                title={barChartData.title}
                categories={barChartData.titles.slice(0, barChartSize)}
                data={barChartData.data.slice(0, barChartSize)}
                size={barChartSize}
                customIcons={[
                  {
                    icon:
                      barChartSize === 10
                        ? '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/></svg>'
                        : '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z"/></svg>',
                    index: 0,
                    title:
                      barChartSize === 10
                        ? 'View all screens'
                        : 'View less screens',
                    class: 'custom-icon',
                    click: function (chart, options, e) {
                      if (
                        barChartSize === 10 &&
                        barChartData.data.length > 10
                      ) {
                        setBarChartSize(barChartData.data.length);
                      } else if (barChartSize === 10) {
                        setBarChartSize(15);
                      } else {
                        setBarChartSize(10);
                      }
                    },
                  },
                ]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourAnalysisComponent;
