import CallIcon from '@material-ui/icons/Call';
import React from 'react';

export default function Banner({
  name,
  phone,
  email,
  backgroundImg = '',
  intro_video = '',
}) {
  let bgImg =
    'https://ds4.cityrealty.com/img/5cf6a38d4a3c89074f908fd693dfe2a489ebe89c+w+h+0+60/the-puck-penthouses-293-lafayette-street-00.jpg';
  if (backgroundImg) {
    bgImg = backgroundImg;
  }

  return (
    <div className="slider-content">
      <div
        className="slide-content-wrap"
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.65), rgba(20, 20, 20, 0.65)), url("${bgImg}")`,
        }}
      >
        <div className="share-video-container">
          <div className="white-fader animated animatedFadeInUp fadeInUp">
            <h1 id="welcome-message" className="display-heading">
              Welcome{name && <>, {name}</>}!
            </h1>

            <div className="tour-banner-flex">
              <div>
                <p className="hero-paragraph">
                  Here is a tour we built just for you.
                </p>{' '}
                <br />
                {/*(fullTour) &&
                <a href={fullTour} target="_blank" className="plain-link-wrap">
                  <div className="button">
                    See full tour {"▶️"}
                  </div>
                </a>
              */}
                {(phone || email) && (
                  <a
                    href={phone ? `tel:${phone}` : `mailto:${email}`}
                    target="_blank"
                    className="plain-link-wrap"
                  >
                    <div className="button">
                      Schedule a tour <CallIcon />
                    </div>
                  </a>
                )}
              </div>
            </div>
          </div>

          {/* TODO: @MUBASHIR Update this css to be flex same line */}
          {intro_video && (
            <div className="white-fader animated animatedFadeInUp fadeInUp custom-share-video  ">
              <video
                autoPlay
                controls
                src={intro_video}
                width={'500px'}
                height={'500px'}
              />
            </div>
          )}
        </div>
        <div className="hero-block"></div>
      </div>
    </div>
  );
}
