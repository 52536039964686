import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import BillingTable from './BillingTable';

const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

const CommunityBilling = ({
  communitiesFormatedList,
  setForceFetchCommunityIndex,
}) => {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [invoices, setinvoices] = useState([]);
  const [org_invoices, setorg_invoices] = useState([]);

  /*
  const [mod_communities, setmod_comminities] = useState([]);
  const [org_communities, setorg_communities] = useState('');
  const [fetchcounter, setfetchcounter] = useState(-1);

  const startfetching = (the_communities) => {
    fetchanalytics(
      setmod_comminities,
      the_communities,
      the_communities[fetchcounter]?.item?.id,
      fetchcounter,
      setfetchcounter
    );
  };

  useEffect(() => {
    if (org_communities?.length > 0) {
      if (fetchcounter != -1) {
        startfetching(org_communities);
      }
    }
  }, [org_communities, fetchcounter]);
  useEffect(() => {
    return () => {
      console.log('called_cleanup');
      setmod_comminities([]);
      setorg_communities('');
      setfetchcounter(-1);
    };
  }, []);
  useEffect(() => {
    if (communities && !org_communities) {
      console.log('called_again', communities);
      let the_communities = [];
      communities.map((item) => {
        the_communities.push({ loading: true, item: item, queryinfo: [] });
      });
      console.log('fetchcounter_setting_communities');
      setmod_comminities([...the_communities]);
      setorg_communities([...the_communities]);
      setfetchcounter(0);
      //startfetching([...the_communities]);
    }
  }, [communities]);

  useEffect(() => {
    let filtered_invoices = [];
    if (searchbymonth || searchbyref) {
      if (searchbyref) {
        org_invoices.map((item, index) => {
          if (number.includes(searchbyref)) {
            filtered_invoices.push(item);
          }
        });
      } else if (searchbymonth) {
        org_invoices.map((item, index) => {
          if (
            moment(item.due_date * 1000)
              .format('MMMM')
              .includes(searchbymonth)
          ) {
            filtered_invoices.push(item);
          }
        });
      }
      setinvoices([...filtered_invoices]);
    } else if (searchbyref == null) {
      setinvoices([...org_invoices]);
    }
  }, [searchbymonth, searchbyref]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + 5;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    // initial woudl be  invoices.slice(0, 5)
    setCurrentItems(invoices.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(invoices.length / 5));
  }, [itemOffset, 5]);
  */

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 5) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <div>
      {communitiesFormatedList?.map((thisCommunity, idx) => (
        <BillingTable
          key={idx}
          communityIdx={idx}
          data={thisCommunity}
          setForceFetchCommunityIndex={setForceFetchCommunityIndex}
          handlePageClick={handlePageClick}
          pageCount={pageCount}
        />
      ))}
      <div className=" flex items-center justify-end paginate_react mt-3">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};

export default CommunityBilling;
