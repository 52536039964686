// Material UI imports
import Rating from '@material-ui/lab/Rating';
import React, { useRef, useState } from 'react';
import Badges from './Badges';
import './question.css';

export default function RateReview({
  staff,
  value,
  setFeedback,
  setRoute,
  apt_template,
  selectedStaffBadges,
  selectedAptBadges,
  setSelectedStaffBadges,
  setSelectedAptBadges,
}) {
  const [rating, setRating] = useState(value);
  const _badges = JSON.parse(apt_template?.badges);

  // Reference textarea's text
  const feedbackRef = useRef();

  // Preset responses based on the rating the user picks
  const [presets] = useState([
    `My interaction with ${
      staff ? staff : apt_template.name
    } was terrible because`,
    `My interaction with ${staff ? staff : apt_template.name} was poor because`,
    `My interaction with ${staff ? staff : apt_template.name} was okay because`,
    `My interaction with ${staff ? staff : apt_template.name} was good because`,
    `My interaction with ${
      staff ? staff : apt_template.name
    } was great because`,
  ]);

  // Handle button click on Rate component
  function handleContinue() {
    setRoute(rating);
  }

  return (
    <div>
      <div className="font s22 b4 question-text c000">
        Your review for {staff ? staff : apt_template.name}:
      </div>
      <div className="review-container">
        <div className="blk flex flex-col">
          <div className="label font s22 b6 c000">Rating: {rating}</div>
          <div className="rating-star flex aic jcc">
            <Rating
              name="rating-component"
              defaultValue={rating}
              precision={0.5}
              onChange={(event) => {
                setRating(event.target.value);
              }}
              size="large"
            />
          </div>
        </div>
        {rating >= 2.5 ? (
          <>
            <div className="blk flex flex-col">
              <div className="label font s22 b6 c000">Staff feedback:</div>
              <div className="flex aic">
                <Badges
                  badges={_badges.user_badges}
                  selected={selectedStaffBadges}
                  setSelected={setSelectedStaffBadges}
                />
              </div>
            </div>

            <div className="blk flex flex-col">
              <div className="label font s22 b6 c000">Apartment feedback:</div>
              <div className="flex aic">
                <Badges
                  badges={_badges.community_badges}
                  selected={selectedAptBadges}
                  setSelected={setSelectedAptBadges}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="blk flex flex-col">
              <div className="label font s22 b6 c000">
                What can we do better? (optional):
              </div>
              <textarea
                onChange={(e) => setFeedback(e.target.value)}
                className="iput area cleanbtn font s15 c000"
              >
                {/* {presets[parseInt(Math.ceil(rating - 1))]} */}
              </textarea>
            </div>
          </>
        )}
        <button className="continue-btn flex aic font" onClick={handleContinue}>
          Continue
        </button>
      </div>
    </div>
  );
}
