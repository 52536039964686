import React, { useEffect, useState, useRef } from 'react';
import { getDurationString } from '../../utils/moment';
import firebase from '../../utils/base';
import CobrowseWrapper from './cobrowse/CobrowseWrapper';
import { sendrealtimemessage, initializeSupabaseRealtime } from './utils';
import moment from 'moment';
import { AiOutlineVideoCameraAdd } from 'react-icons/ai';
import LoadingIndicator from '../../components/LoadingIndicator';
import { supabase } from '../../services/supabase';
import {
  getRoom,
  getActiveRoom,
  getRoomCodes,
  getPeers,
} from '../../utils/hms';

import EngageHowTo from './EngageHowTo';
import InfoScreen from './cobrowse/InfoScreen';
import { FiSettings } from 'react-icons/fi';
import VirtualRooms from './VirtualRooms';
import LinkIcon from '@material-ui/icons/Link';
import { toast } from 'react-toastify';
import { SiGooglemeet } from 'react-icons/si';
import Talk from 'talkjs';
import generateUsername from '../../utils/usernameGeneration';
import { generateLocationStr } from './utils';
function Engage({
  community_id,
  communityName,
  magnet_uuid,
  activevisitors,
  allvisitorsdata,
  setactivevisitors,
  channelref,
  chatMe,
  defaultVisitorUuid = '',
  defaultVisitorData = null,
  accessLevel
}) {
  const [cobrowseKey, setCobrowseKey] = useState(crypto.randomUUID());

  const [currentVisitorUuid, setCurrentVisitorUuid] =
    useState(defaultVisitorUuid);
  const [currentVisitorData, setCurrentVisitorData] =
    useState(defaultVisitorData);

  useEffect(() => {
    setCobrowseKey(crypto.randomUUID());
  }, [currentVisitorUuid]);

  const sendCallToVisitor = (visitorId, callUrl, greeting = '') => {
    console.log('visitorId call', visitorId);
    sendrealtimemessage(visitorId, {
      bold: `${
        firebase?.user()?.displayName?.split(' ')?.[0]
      } from ${communityName}`,
      regular: ' wants to chat',
      greeting: greeting,
      initials: firebase
        .user()
        ?.displayName?.split(' ')
        ?.map((word) => word[0]?.toUpperCase())
        ?.join(''),
      callUrl: callUrl,
      timestamp: Date.now(),
      origin: window.location.href,
    });
  };

  const [messages, setMessages] = useState([]);
  const currentVisitorUuidRef = useRef(currentVisitorUuid);
  const updateMesssage = (data) => {
    console.log("mod_activevisitors_data", data);
    if (data?.visitor_id == currentVisitorUuidRef?.current) {
      setMessages(data?.messages);
    } else {
      console.log("mod_activevisitors_else",);
      setactivevisitors(prev => {
        let mod_activevisitors = [...(prev || [])];
        const index = mod_activevisitors.findIndex(item => item?.data?.visitorId == data?.visitor_id);

        if (index !== -1) {
          let modifiedItem = {
            ...mod_activevisitors[index],
            data: {
              ...mod_activevisitors[index].data,
              unreadCount: (mod_activevisitors[index].data.unreadCount || 0) + 1,
              unreadMessage: data?.messages[data?.messages?.length - 1]?.message
            }
          };

          mod_activevisitors.splice(index, 1);

          // Add the item to the beginning of the array
          mod_activevisitors.unshift(modifiedItem);
        }

        console.log("mod_activevisitors", mod_activevisitors);
        return mod_activevisitors;
      });
    }
  };

  useEffect(() => {
    const channel = supabase
      .channel("schema-db-changes")
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "chat" },
        async (payload) => {
          console.log("postgres_changes_INSERT", payload);
          console.log("myStateRef.current", currentVisitorUuidRef.current);
          updateMesssage(payload?.new);
        }
      )
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "chat" },
        (payload) => {
          console.log("postgres_changes_UPDATE", payload);
          // check if message came from selected visitor id
          updateMesssage(payload?.new);
          console.log("currentVisitorUuidRef.current", currentVisitorUuidRef.current);
          // optimisticUpdateMessage(payload.new as Imessage);
        }
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, []);


  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-9 gap-3">
        <div className=" mt-5 px-3 lg:col-span-2">
          <TourRooms communityId={community_id} accessLevel={accessLevel}/>

          <OnlineUser
            allData={activevisitors}
            allvisitorsdata={allvisitorsdata}
            onView={(visitorId, visitorData) => {
              setactivevisitors((prev) =>
                prev.map((elem) => {
                  return {
                    data: {
                      ...elem.data,
                      unreadCount:
                        elem.data.visitorId === visitorId
                          ? 0
                          : elem.data.unreadCount,
                      unreadMessage:
                      elem.data.visitorId === visitorId
                        ? ''
                        : elem.data.unreadMessage,
                    },
                  };
                })
              );
              setCurrentVisitorUuid(
                currentVisitorUuid === visitorId ? '' : visitorId
              );
              setCurrentVisitorData(
                currentVisitorUuid === visitorId ? null : visitorData
              );
            }}
            activeVisitorUuid={currentVisitorUuid}
          />
        </div>
        {/* Connection Screen */}
        <div className="w-full lg:col-span-7">
          {currentVisitorData ? (
            <InfoScreen
              onCall={sendCallToVisitor}
              visitorData={currentVisitorData}
              magnet_uuid={magnet_uuid}
              currentVisitorUuid={currentVisitorUuid}
              communityId={community_id}
              communityName={communityName}
              meChatId={chatMe.id}
              setCobrowseKey={setCobrowseKey}
              activevisitors={activevisitors}
              setactivevisitors={setactivevisitors}
              currentVisitorUuidRef={currentVisitorUuidRef} 
              messages={messages} 
              setMessages={setMessages}   
            >
              {/* <CobrowseScreen visitorUuid={currentVisitorUuid} /> */}
              <div
                className="overflow-hidden p-4"
                style={{
                  width: 'calc(100% + 110px)',
                  padding: '0px !important',
                }}
              >
                <div style={{ position: 'relative', left: '-80px' }}>
                  <CobrowseWrapper
                    key={cobrowseKey}
                    visitorUuid={currentVisitorUuid}
                    setVisitorUuid={setCurrentVisitorUuid}
                    currentVisitorData={currentVisitorData}
                    className="h-full"
                  />
                </div>
              </div>
              {/* <UsersList
                allData={activevisitors}
                onConnect={(visitorId) => sendCallToVisitor(visitorId)}
                onView={(visitorId, visitorData) => {
                  setCurrentVisitorUuid(visitorId);
                  setCurrentVisitorData(visitorData);
                }}
                activeVisitorUuid={currentVisitorUuid}
              /> */}
            </InfoScreen>
          ) : (
            /* how to screen */
            <div
              style={{ background: '#fbfbfb' }}
              className="border m-2 p-8 text-xl text-gray-400 rounded-xl flex justify-center items-start h-full"
            >
              <EngageHowTo />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Engage;

export const TourRooms = ({ communityId,accessLevel }) => {
  const [rooms, setRooms] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [expandedModalOpen, setExpandedModalOpen] = useState(false);
  const [communityName, setCommunityName] = useState('');
  const getRoomIds = async () => {
    const { data, error } = await supabase
      .from('Community')
      .select('rooms, name')
      .eq('id', communityId);
    if (error) {
      console.error('Error fetching from supabase for roomIds', error);
      return;
    }
    if (!data.length) {
      console.error(`Community with id ${communityId} not found in supabase`);
      return;
    }
    if (initialLoading) setRooms(data[0].rooms);

    setCommunityName(data[0].name);
    return data[0].rooms;
  };
  console.log("rooms",rooms)
  const getRooms = async () => {
    // console.log('getRooms started');
    const newRoomsState = [];
    const roomIds = await getRoomIds();

    for (const roomId of roomIds) {
      // for loop because we don't want to break the control flow by calling this as an async callback

      const roomInfo = {
        id: roomId.id,
        enabled: roomId.enabled,
        name: roomId.name,
      };

      if (roomId.enabled) {
        // if room name is changed externally in 100ms dashboard, this dashboard will
        // not know about that
        const [activeRoomInfo, roomCodes] = await Promise.all([
          getActiveRoom(roomId.id),
          getRoomCodes(roomId.id),
        ]);

        if (activeRoomInfo.code === 404) {
          roomInfo.active = false;
        } else {
          roomInfo.active = true;
          roomInfo.started = activeRoomInfo.session.created_at;
          const peers = await getPeers(roomId.id);
          roomInfo.peers = Object.values(peers).map((elem) => elem.name);
        }

        roomInfo.roomCodes = roomCodes.map(({ code, role }) => ({
          code,
          role,
        }));
      }
      newRoomsState.push(roomInfo);
    }
    if (initialLoading) {
      const newSupabaseRooms = getSupabaseVersionOfState(newRoomsState);
      updateSupabaseRoomsArray(newSupabaseRooms);
    }
    setRooms(newRoomsState);
    // console.log('getRooms ended naturally');
    setInitialLoading(false);
  };

  const getSupabaseVersionOfState = (rooms) => {
    return rooms.map(({ id, enabled, name, roomCodes }) => ({
      id,
      enabled,
      name,
      roomCodes,
    }));
  };

  const updateSupabaseRoomsArray = async (newSupabaseRooms) => {
    // console.log('updateSupabaseRoomsArray started');
    const { error } = await supabase
      .from('Community')
      .update({ rooms: newSupabaseRooms })
      .eq('id', communityId);
    if (error) {
      console.error(
        `Error creating rooms for community ${communityId} in supabase:`,
        error
      );
    }
    // console.log('supabase rooms updated');
  };

  const refreshCallback = useRef();
  const refresh = () => {
    // console.log('refresh attempted');
    if (!expandedModalOpen) {
      // console.log('refresh successfully started');
      getRooms();
    }
  };

  useEffect(() => {
    refreshCallback.current = refresh;
  });

  useEffect(() => {
    getRooms();
    function intervalRefresh() {
      refreshCallback.current();
    }
    const refreshIntervalId = setInterval(intervalRefresh, 15000);
    return () => clearInterval(refreshIntervalId);
  }, []);

  return (
    <div>
      <div className="flex w-full justify-between pb-2 border-b">
        <p className="text-gray-500 text-sm">Tour Rooms ({rooms.length})</p>
        {
          accessLevel != null
          &&
          <FiSettings
          className="pointer"
          onClick={() => setExpandedModalOpen(true)}
        />
        }

      </div>
      {rooms.map(
        (room, index) =>
          room.enabled && (
            <div className="pt-3 flex items-center gap-3">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <a
                  // href={`https://meet.tour.video/meeting/${
                  //   room?.roomCodes?.find((elem) => elem.role === 'builder-101')
                  //     ?.code
                  // }?cid=${communityId}&uid=${firebase.uid()}&room_id=${room?.id}&role=host`}
                  href={`https://meet.tour.video/preview/${
                    room?.id
                  }/builder-101?cid=${communityId}&uid=${firebase.uid()}&room_id=${room?.id}&role=host`}
                  target="_blank"
                >
                  <button className=" px-3 py-2 flex items-center gap-2 text-sm text-white bg-pr rounded-md">
                    <SiGooglemeet className="w-5 h-5" />
                    Join
                  </button>
                </a>
                <div
                  className="hover:underline cursor text-gray-400 ml-2"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://meet.tour.video/meeting/${
                        room.roomCodes.find(
                          (elem) => elem.role === 'builder-101'
                        )?.code
                      }?cid=${communityId}&uid=${firebase.uid()}&room_id=${room?.id}&role=viewer`
                    );
                    toast('Copied meeting link');
                  }}
                  style={{ display: 'flex', cursor: 'pointer' }}
                >
                  Copy
                  <LinkIcon className="text-gray-400" />
                </div>
              </div>

              <div className="w-full flex flex-col">
                <div className="w-full flex flex-wrap justify-between">
                  <p className="whitespace-nowrap overflow-hidden	">
                    {room?.name?.substring(0, 14)}
                  </p>
                  <p
                    className={`text-right ${
                      room?.active
                        ? 'text-black font-bold'
                        : initialLoading
                        ? 'text-gray-300 italic'
                        : 'text-blue-500'
                    }`}
                  >
                    {room?.active
                      ? getDurationString(moment(room?.started), moment())
                      : initialLoading
                      ? 'Loading...'
                      : 'Empty'}
                  </p>
                </div>
                <p className="self-end">
                  {room?.peers
                    ?.filter((peer) => peer.name !== 'Beam')
                    ?.join(', ')}
                </p>
              </div>
            </div>
          )
      )}
      <VirtualRooms
        community_id={communityId}
        communityName={communityName}
        meetingModal={expandedModalOpen}
        setMeetingModal={setExpandedModalOpen}
        externalRooms={rooms}
        setExternalRooms={(rooms) => setRooms(rooms)}
        externalInitialLoading={initialLoading}
        setExternalInitialLoading={setInitialLoading}
      />
    </div>
  );
};


export const OnlineUser = ({ allData, allvisitorsdata = [], onView, activeVisitorUuid }) => {
  const [isOnlineMinimized, setIsOnlineMinimized] = useState(false);
  const [isOfflineMinimized, setIsOfflineMinimized] = useState(false);

  const toggleOnlineMinimize = () => {
    setIsOnlineMinimized(!isOnlineMinimized);
  };

  const toggleOfflineMinimize = () => {
    setIsOfflineMinimized(!isOfflineMinimized);
  };

  return (
    <>
      <div className="flex justify-between items-center pb-2 border-b mt-4 mb-2">
        <p className="text-gray-500 text-sm">
          Online Users ({allData?.length})
        </p>
        <button onClick={toggleOnlineMinimize} className="text-gray-500 text-sm">
          {isOnlineMinimized ? '▼' : '▲'}
        </button>
      </div>

      {!isOnlineMinimized && (
        <div className="overflow-scroll">
          {allData?.map((item) => {
            let itemObj = item?.data;

            function onViewLocal() {
              onView(itemObj.visitorId, itemObj);
            }

            const visitorName =
              item.data.userInfo?.name ?? generateUsername(item.data.visitorId);

            return (
              <div
                onClick={onViewLocal}
                className={`flex items-center gap-3 ${
                  activeVisitorUuid === itemObj.visitorId ? 'bg-gray-100' : ''
                } hover:bg-gray-100 cursor-pointer px-2 py-3 rounded-xl`}
                fullWidth
                key={itemObj.visitorId}
              >
                <div className="w-16 h-16 flex-shrink-0 border rounded-full relative flex items-center justify-center bg-gray-50">
                  <h1 className="text-2xl font-bold text-gray-400">
                    {visitorName
                      .split(' ')
                      .map((word) => word[0])
                      .join('')
                      .slice(0, 2)}
                  </h1>
                  {itemObj?.unreadCount > 0 && (
                    <div className="w-6 h-6 bg-pr rounded-full absolute text-white text-center font-bold bottom-0 right-0 z-10">
                      {itemObj?.unreadCount}
                    </div>
                  )}
                </div>
                <div>
                  <h1 className="text-xl">{visitorName}</h1>
                  {itemObj?.unreadMessage ? (
                    <p className="bg-pr text-white text-center font-bold ">
                      {itemObj?.unreadMessage?.slice(0, 20)}...
                    </p>
                  ) : (
                    <p>{moment(new Date(itemObj?.started)).fromNow()}</p>
                  )}
                  <p>{generateLocationStr(itemObj)}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div className="flex justify-between items-center pb-2 border-b mt-4 mb-2">
        <p className="text-gray-500 text-sm">
          All Users ({allvisitorsdata?.length})
        </p>
        <button onClick={toggleOfflineMinimize} className="text-gray-500 text-sm">
          {isOfflineMinimized ? '▼' : '▲'}
        </button>
      </div>

      {!isOfflineMinimized && (
        <div className="overflow-scroll">
          {allvisitorsdata?.map((item) => {
            console.log("itemmm",item)
            let itemObj = item;

            function onViewLocal() {
              onView(itemObj.visitor_id, itemObj);
            }

            const visitorName =
              item?.name ?? item?.visitor_id ? generateUsername(item?.visitor_id) : "Undefined";

            return (
              <div
                onClick={onViewLocal}
                className={`flex items-center gap-3 ${
                  activeVisitorUuid === itemObj?.visitor_id ? 'bg-gray-100' : ''
                } hover:bg-gray-100 cursor-pointer px-2 py-3 rounded-xl`}
                fullWidth
                key={itemObj.visitor_id}
              >
                <div className="w-16 h-16 flex-shrink-0 border rounded-full relative flex items-center justify-center bg-gray-50">
                  <h1 className="text-2xl font-bold text-gray-400">
                    {visitorName
                      .split(' ')
                      .map((word) => word[0])
                      .join('')
                      .slice(0, 2)}
                  </h1>
                  {itemObj?.unreadCount > 0 && (
                    <div className="w-6 h-6 bg-pr rounded-full absolute text-white text-center font-bold bottom-0 right-0 z-10">
                      {itemObj?.unreadCount}
                    </div>
                  )}
                </div>
                <div>
                  <h1 className="text-xl">{visitorName}</h1>
                  {itemObj?.unreadMessage ? (
                    <p className="bg-pr text-white text-center font-bold ">
                      {itemObj?.unreadMessage?.slice(0, 20)}...
                    </p>
                  ) : (
                    <p>{moment(new Date(itemObj?.created_at)).fromNow()}</p>
                  )}
                  <p>{generateLocationStr(itemObj)}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};




