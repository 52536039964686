import React, { useState, useEffect } from 'react';
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai';
import ChipInput from 'material-ui-chip-input';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { v4 } from 'uuid';

const fieldValues = {
  url: {
    readableName: 'Url',
    operators: ['includes', 'excludes', 'exactly'],
    value: 'chip',
  },
  layout: {
    readableName: 'Layout',
    operators: ['isMobile', 'isDesktop'],
  },
  date: {
    readableName: 'Date',
    operators: ['<=', '>='],
    value: 'datetime-local',
  },
  tourPixelHistory: {
    readableName: 'Tour Pixel History',
    route: 'route',
    operators: ['>=', '>', '<'],
    value: 'text',
  },
  viewCount: {
    readableName: 'Bubble View Count',
    route: 'bubble',
    operators: ['<=', '<', '=', '>', '>='],
    value: 'int',
  },
  closeCount: {
    readableName: 'Bubble Close Count',
    route: 'bubble',
    operators: ['<=', '<', '=', '>', '>='],
    value: 'int',
  },
  pathViewCount: {
    readableName: 'Website Path View Count',
    route: 'text',
    operators: ['<=', '<', '=', '>', '>='],
    value: 'int',
  },
  sessionPathViewCount: {
    readableName: 'Website Path View Count (Session)',
    route: 'text',
    operators: ['<=', '<', '=', '>', '>='],
    value: 'int',
  },
  sessionCount: {
    readableName: 'Session Count',
    operators: ['<=', '<', '=', '>', '>='],
    value: 'int',
  },
  probability: { readableName: 'Probability', value: 'float' },
  compound: { readableName: 'Compound', operators: ['or', 'and'] },
};

export function TriggerHeadline({ trigger, index, chatbot }) {
  if (trigger.type === 'url') {
    return (
      <>
        {fieldValues[trigger.type].readableName} {trigger.operator}{' '}
        {trigger.value.map((elem, index) => (
          <>
            <b>{elem}</b>
            {index !== trigger.value.length - 1 &&
              (trigger.operator === 'excludes'
                ? trigger.value.length === 2
                  ? ' and '
                  : ', and '
                : trigger.value.length === 2
                ? ' or '
                : ', or ')}
          </>
        ))}
      </>
    );
  } else if (trigger.type === 'layout') {
    return (
      <>
        {fieldValues[trigger.type].readableName} is{' '}
        <b>{trigger.operator === 'isMobile' ? 'mobile' : 'desktop'}</b>
      </>
    );
  } else if (trigger.type === 'viewCount' || trigger.type === 'closeCount') {
    const otherBubbleIndex = chatbot.findIndex(
      ({ id }) => id === trigger.route
    );
    return (
      <>
        {fieldValues[trigger.type].readableName}{' '}
        {otherBubbleIndex !== index && (
          <>
            of <i>bubble {otherBubbleIndex + 1}</i>
          </>
        )}{' '}
        {trigger.operator} <b>{trigger.value}</b>
      </>
    );
  } else if (trigger.type === 'compound') {
    return (
      <>
        Compound -{' '}
        {trigger.subConditions?.map(({ type }, index) => (
          <>
            {fieldValues[type].readableName}{' '}
            {index !== trigger.subConditions.length - 1 && (
              <b>{trigger.operator} </b>
            )}
          </>
        ))}
      </>
    );
  }
  return (
    <>
      {fieldValues[trigger.type].readableName} {trigger.operator}{' '}
      <b>{trigger.value}</b>
    </>
  );
}

export default function ConditionRow({
  condition,
  setCondition,
  removeCondition,
  routeOpts,
  chatbot,
  selectedRow,
}) {
  const conditionFields = fieldValues?.[condition?.type];
  const addSubCondition = () => {
    setCondition((oldCondition) => ({
      ...oldCondition,
      subConditions: [
        ...(oldCondition?.subConditions ?? []),
        {
          type: '',
          route: '',
          operator: '',
          value: [],
          id: v4(),
        },
      ],
    }));
  };

  const setSubCondition = (index, newValue) => {
    setCondition((oldCondition) => {
      const newSubConditions = [...oldCondition.subConditions];
      if (typeof newValue === 'function') {
        newSubConditions[index] = newValue(oldCondition.subConditions[index]);
      } else {
        newSubConditions[index] = newValue;
      }
      console.log('newSubConditions', newSubConditions);
      return { ...oldCondition, subConditions: newSubConditions };
    });
  };

  console.log('condition', condition);

  const removeSubCondition = (indexToRemove) => {
    setCondition((oldCondition) => ({
      ...oldCondition,
      subConditions: oldCondition.subConditions.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  return (
    <div className="flex-grow">
      <div className="flex w-full gap-2">
        <div className="flex-grow flex flex-col gap-2">
          <h2 className=" text-sm text-gray-500">Type*</h2>
          <div className="flex items-center gap-2">
            <FormControl variant="outlined" className=" w-full">
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={condition?.type || ''}
                className=" w-full"
                onChange={(e) => {
                  const newValue =
                    e.target.value === 'url'
                      ? []
                      : e.target.value.length > 0
                      ? ''
                      : condition?.value;
                  setCondition((oldCondition) => ({
                    ...oldCondition,
                    type: e.target.value,
                    value: newValue,
                  }));
                }}
              >
                {Object.entries(fieldValues).map(([key, { readableName }]) => (
                  <MenuItem key={key} value={key}>
                    {readableName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {condition?.type && (
          <>
            {/*Route*/}
            {conditionFields?.route && (
              <div className="flex-grow flex flex-col gap-2">
                <h2 className=" text-sm text-gray-500">Route*</h2>
                {conditionFields.route === 'text' ? (
                  <input
                    style={{ padding: '15px 0' }}
                    className="px-2 bg-transparent outline-none w-full border rounded-md"
                    onChange={(e) =>
                      setCondition((oldCondition) => ({
                        ...oldCondition,
                        route: e.target.value,
                      }))
                    }
                    value={condition.route ?? ''}
                    type="text"
                  />
                ) : (
                  <FormControl variant="outlined" className="w-full">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined1"
                      value={condition.route || ''}
                      onChange={(e) =>
                        setCondition((oldCondition) => ({
                          ...oldCondition,
                          route: e.target.value,
                        }))
                      }
                      className="w-full"
                    >
                      {conditionFields?.route === 'route'
                        ? routeOpts.map(({ id, route, video }) => (
                            <MenuItem
                              key={id + route + video}
                              value={route + '|' + video}
                            >
                              {id}
                            </MenuItem>
                          ))
                        : /*conditionFields?.route === 'bubble' ?*/ chatbot.map(
                            ({ id, type }, index) => (
                              <MenuItem key={id} value={id}>
                                {index === selectedRow
                                  ? 'Current bubble'
                                  : `Bubble ${index + 1} (${type})`}
                              </MenuItem>
                            )
                          )}
                    </Select>
                  </FormControl>
                )}
              </div>
            )}

            {/* Operator */}
            {conditionFields?.operators && (
              <div className="flex-grow flex flex-col gap-2">
                <h2 className=" text-sm text-gray-500">Operator*</h2>
                <FormControl variant="outlined" className=" w-full">
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={condition.operator || ''}
                    onChange={(e) =>
                      setCondition((oldCondition) => ({
                        ...oldCondition,
                        operator: e.target.value,
                      }))
                    }
                    className="w-full"
                  >
                    {conditionFields?.operators?.map((op) => (
                      <MenuItem key={op} value={op}>
                        {op}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}

            {/* Value */}
            {conditionFields?.value && (
              <div
                className="flex-grow flex-col flex gap-2"
                style={{ maxWidth: '40%' }}
              >
                <h2 className=" text-sm text-gray-500">Value*</h2>
                {conditionFields?.value === 'chip' ? (
                  <ChipInput
                    variant="outlined"
                    // style={{ height: '57px' }}
                    blurBehavior="add"
                    value={
                      Array.isArray(condition.value) ? condition.value : []
                    }
                    onAdd={(chip) => {
                      setCondition((oldCondition) => ({
                        ...oldCondition,
                        value: [...oldCondition.value, chip],
                      }));
                    }}
                    onDelete={(chip, index) =>
                      setCondition((oldCondition) => ({
                        ...oldCondition,
                        value: oldCondition.value.filter(
                          (item, ind) => ind !== index && item !== chip
                        ),
                      }))
                    }
                  />
                ) : conditionFields?.value === 'int' ? (
                  <input
                    style={{ padding: '15px 0' }}
                    className="px-2 bg-transparent outline-none w-full border rounded-md"
                    onChange={(e) =>
                      setCondition((oldCondition) => ({
                        ...oldCondition,
                        value: parseInt(e.target.value),
                      }))
                    }
                    value={
                      Array.isArray(condition.value) ? '' : condition.value
                    }
                    type="number"
                    name=""
                    id=""
                  />
                ) : conditionFields?.value === 'float' ? (
                  <input
                    type="number"
                    style={{ padding: '15px 0' }}
                    className="px-2 bg-transparent outline-none w-full border rounded-md"
                    onChange={(e) =>
                      setCondition((oldCondition) => ({
                        ...oldCondition,
                        value: parseFloat(e.target.value),
                      }))
                    }
                    value={
                      Array.isArray(condition.value)
                        ? '0.5'
                        : condition.value ?? '0.5'
                    }
                  />
                ) : (
                  <input
                    type={conditionFields?.value}
                    style={{ padding: '15px 0' }}
                    className="px-2 bg-transparent outline-none w-full border rounded-md"
                    onChange={(e) =>
                      setCondition((oldCondition) => ({
                        ...oldCondition,
                        value: e.target.value,
                      }))
                    }
                    value={
                      Array.isArray(condition.value) ? '' : condition.value
                    }
                  />
                )}
              </div>
            )}
          </>
        )}
        <button className="self-center px-3 py-3 bg-gray-50 border text-sm hover:bg-gray-200 text-gray-500">
          <AiOutlineDelete
            onClick={removeCondition}
            className="w-5 h-5 cursor-pointer text-gray-500"
          />
        </button>
      </div>
      {condition?.type === 'compound' && (
        <div className="flex flex-col">
          {condition?.subConditions?.map((subCondition, index) => (
            <div className="flex w-full">
              <div className="w-6 ml-6 flex flex-col self-stretch">
                <div
                  className={`border-pr border-l-2 border-b-2 h-14 flex-none ${
                    index !== condition.subConditions.length - 1
                      ? ''
                      : 'rounded-bl-xl'
                  }`}
                ></div>
                <div
                  className={`border-pr flex-grow ${
                    index !== condition.subConditions.length - 1
                      ? 'border-l-2'
                      : ''
                  }`}
                ></div>
              </div>
              <ConditionRow
                key={subCondition.id}
                condition={subCondition}
                setCondition={(newValue) => setSubCondition(index, newValue)}
                removeCondition={() => removeSubCondition(index)}
                routeOpts={routeOpts}
                chatbot={chatbot}
                selectedRow={selectedRow}
              />
            </div>
          ))}
          <button
            onClick={() => addSubCondition()}
            className="ml-12 flex items-center gap-1 bg-gray-700 text-sm px-3 py-1 rounded-md text-white  mr-2 hover:opacity-80"
          >
            <AiOutlinePlus className=" w-5 h-5" />
            Add
          </button>
        </div>
      )}
    </div>
  );
}
