import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { currentDateTime, generateID } from '../../../core';
import {
  createRequestHistory,
  getCommunityUsers,
  getRequestHistory,
  getReviewCommunity,
} from '../../../store/actions/reviewsActions';
import { RESIDENT_HOST, useRequest } from '../../../utils/request.js';
import { getSlug } from '../../../utils/slug';
import { Exit, Question, Review, SpecialOffer, Thanks } from './index.js';
// Question templates
import {
  // apt_template,
  question_template,
  question_template1,
  question_template2,
  question_template3,
  question_template4,
} from './question.test.js';

export default function Survey() {
  const { community_id } = useParams();
  const _dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const requestID = urlParams.get('request');

  const [step, setStep] = useState(1);
  const [staffType, setStaffType] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [userReviewLoading, setUserReviewLoading] = useState(false);
  const [questionType, setQuestionType] = useState('');

  const { requestHistoryData, requestHistoryLoading } = useSelector(
    (state) => state.getRequestHistoryReducer
  );

  const { reviewscommunityLoading, reviewscommunityData } = useSelector(
    (state) => state.getReviewCommunityReducer
  );

  const { communityUsersLoading, communityUsers } = useSelector(
    (state) => state.communityUsersReducer
  );

  const [requestHistory, setRequestHistory] = useState();
  const [reviewData, setReviewData] = useState();

  // Track which route user is on
  const [route, setRoute] = useState('/');

  console.log('requestHistory', requestHistory);

  // Track 'who' for tour, lease or maintenance route
  const [who, setWho] = useState('');
  // Track selected badges for staff
  const [selectedStaffBadges, setSelectedStaffBadges] = useState([]);
  // Track selected badges for apt
  const [selectedAptBadges, setSelectedAptBadges] = useState([]);
  // Track optional feedback
  const [feedback, setFeedback] = useState('');

  // Save templates
  const [aptTemplate, setAptTemplate] = useState();
  const [staffTemplate, setStaffTemplate] = useState();
  const [leasedTemplate, setLeasedTemplate] = useState();
  const [maintenanceTemplate, setMaintenanceTemplate] = useState();

  // Loading bar for new get requests
  const [loading, error, data, makeRequest] = useRequest({ loading: false });

  const [apartments, setApartments] = useState();

  console.log('step', step);

  // Get apartment info using url slug
  function getApartment(setState, slug) {
    // fetch(`http://localhost:8080/apartments/${slug}`)
    makeRequest(`/apartments/${slug}`, 'GET', null, {
      auth: false,
      host: RESIDENT_HOST,
    })
      .then((response) => {
        return response;
      })
      .then((data) => {
        setState(data.apartment);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  useEffect(() => {
    _dispatch(getRequestHistory(requestID));
  }, []);

  useEffect(() => {
    if (!requestHistoryLoading && requestHistoryData) {
      let _data =
        requestHistoryData?.data && JSON.parse(requestHistoryData?.data);
      setRequestHistory({
        id: requestHistoryData.id,
        type: requestHistoryData.type,
        src_community_id: requestHistoryData.src_community_id,
        src_user_id: requestHistoryData.src_user_id,
        reviewId: requestHistoryData.review_id,
        leadName: requestHistoryData.leadName,
        leadEmail: requestHistoryData.leadEmail,
        leadPhone: requestHistoryData.leadPhone,
        leadUserId: requestHistoryData.leadUserId,
        data: _data,
      });

      console.log('requestHistory: ', requestHistoryData);
    }

    setRequestHistory(requestHistoryData);
  }, [requestHistoryLoading]);

  useEffect(() => {
    _dispatch(getReviewCommunity(community_id));
    makeRequest(`/apartments`, 'GET', null, {
      auth: false,
      host: RESIDENT_HOST,
    })
      .then((response) => response)
      .then((data) => setApartments(data.apartments))
      .catch((error) => console.log(error.message));
  }, []);

  useEffect(() => {
    if (staffType.length) {
      _dispatch(
        getCommunityUsers(encodeURIComponent(staffType.join(',')), community_id)
      );
    }
  }, [staffType]);

  const updateReview = async (updatedUserReviewData) => {
    //console.log("updatedUserReviewData",updatedUserReviewData)
    await axios.post(
      `https://api.directual.com/good/api/v5/data/reviews/newReview?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
      updatedUserReviewData
    );
  };

  const updateRequestHistory = async ({ updatedReviewRequestData }) => {
    await axios.post(
      `https://api.directual.com/good/api/v5/data/request_history/newRequestHistory?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
      updatedReviewRequestData
    );
  };

  const [context, setContext] = useState({});
  // Handle updating the route
  const handleRouteUpdate = async (answer) => {
    let reviewId = generateID(3, 4);
    let newUserId = requestHistory?.data?.leadUserId || generateID(3, 4);
    let tempRequestHistory = {};

    console.log('context', context);
    console.log('answer', answer);

    if (step === 1) {
      // Request history is how we check who sent the message
      tempRequestHistory = JSON.parse(requestHistory?.data || '{}');
      console.log('tempRequestHistory', tempRequestHistory);

      setRequestHistory({
        ...requestHistory,
        data: {
          ...tempRequestHistory,
          contextId: answer.id,
        },
      });

      setStaffType(answer.staff_type);
      setQuestionType(answer.question_type);
      setContext({ ...context, [step]: answer });
      setStep(step + 1);
    }
    if (step === 2) {
      tempRequestHistory = requestHistory?.data || '{}';
      console.log('tempRequestHistory', tempRequestHistory);

      setRequestHistory({
        data: {
          ...tempRequestHistory,
          staffId: answer.id,
          requesterName: answer?.name,
          requesterEmail: answer?.email,
          requesterPhone: answer?.phone,
        },
      });
      setContext({ ...context, [step]: answer });
      setStep(step + 1);
    }
    if (step === 3) {
      setUserReviewLoading(true);
      setRequestHistory({
        ...requestHistory,
        data: {
          ...requestHistory?.data,
          defaultReviewStars: answer,
          reviewId: reviewId,
        },
      });
      let userReviewData = {
        id: requestHistoryData?.reviewId || reviewId,
        date_created: currentDateTime(),
        community_id: community_id,
        from_user: requestHistory?.data?.leadUserId,
        from_user_name: requestHistory?.data?.leadName,
        message: feedback,
        to_user: context?.[2]?.id,
        rating: answer,
        metadata: {
          requestID: requestID,
        },
        badges: {
          community_badges: selectedAptBadges,
          user_badges: selectedStaffBadges,
        },
      };

      console.log('userReviewData', userReviewData, answer);

      try {
        //console.log('user review data', userReviewData, requestHistory);
        //console.log('userReviewData', userReviewData);
        const review_res = await axios.post(
          `https://api.directual.com/good/api/v5/data/reviews/newReview?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
          userReviewData
        );
        console.log('review_res success', review_res);

        if (review_res.data.status === 'OK') {
          setReviewData(userReviewData);
          toast('Review placed successfully', {
            position: 'top-right',
            type: 'success',
            autoClose: 5000,
          });
          const finishedReviewId = review_res.data.result[0].id;
          const requestHistoryRes = await axios.post(
            `https://api.directual.com/good/api/v5/data/request_history/newRequestHistory?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
            {
              ...requestHistory,
              reviewId: finishedReviewId,
              src_user_id: requestHistory.src_user_id?.id,
            }
          );
          setRequestHistory({
            ...requestHistory,
            reviewId: finishedReviewId,
            src_user_id: requestHistory.src_user_id?.id,
          });

          // Create the user after review is created_date
          if (
            (requestHistory?.data?.leadName &&
              requestHistory?.data?.leadPhone) ||
            requestHistory?.data?.leadEmail
          ) {
            const userCreated = await axios.post(
              `https://api.directual.com/good/api/v5/data/users/newUser?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
              {
                id: newUserId,
                name: requestHistory?.data?.leadName,
                email: requestHistory?.data?.leadEmail,
                phone: requestHistory?.data?.leadPhone,
                community: community_id,
                type: 'lead-review',
                metadata: {
                  review: userReviewData,
                },
              }
            );
            console.log('userCreated after review TYG: ', userCreated);
          }

          setUserReviewLoading(false);
          setStep(step + 1);
        } else {
          setUserReviewLoading(false);
        }
      } catch (error) {
        console.log('user review error...', error.message);
        setUserReviewLoading(false);
      }
    }
    if (step === 4) {
      setUserReviewLoading(true);
      setRequestHistory({
        ...requestHistory,
        data: {
          ...requestHistory.data,
          leadName: answer.name,
          leadPhone: answer.phone,
          leadEmail: answer.email,
          leadUserId: answer.id,
          completedBonusReview: true,
          src_user_id: newUserId,
        },
      });
      let newRequestHistory = {
        id: requestID,
        type: 'review_request',
        src_community_id: community_id,
        src_user_id: newUserId,
        reviewId: requestHistory.data.reviewId,
        data: {
          requesterName: requestHistory.data.requesterName,
          requesterEmail: requestHistory.data.requesterEmail,
          requesterPhone: requestHistory.data.requesterPhone,
          leadName: answer.name,
          leadPhone: answer.phone,
          leadEmail: answer.email,
          leadUserId: answer.id,
          completedBonusReview: true,
          staffId: requestHistory.data.staffId,
          defaultReviewStars: requestHistory.data.defaultReviewStars,
          contextId: requestHistory.data.contextId,
          specialOffer: '',
        },
      };
      let newUserData = {
        id: newUserId,
        community: community_id,
        name: answer.name,
        email: answer.email,
        phone: answer.phone,
        type: questionType,
      };
      console.log('newRequestHistory', newRequestHistory);
      try {
        let newUserRes = await axios.post(
          `https://api.directual.com/good/api/v5/data/users/newUser?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
          newUserData
        );
        console.log('success', step);
        if (newUserRes.data.status === 'OK') {
          toast('Request sent successfully', {
            position: 'top-right',
            type: 'success',
            autoClose: 5000,
          });
          _dispatch(createRequestHistory(newRequestHistory));
          setUserReviewLoading(false);
        } else {
          setUserReviewLoading(false);
        }
      } catch (error) {
        console.log('user review error...', error.message);
        setUserReviewLoading(false);
      }
    }
  };

  // Get all leasing staff and append to existing question template
  function getLeasingStaff(slug) {
    // fetch(`${HOST}/users/${slug}/Staff`)
    makeRequest(`/users/${slug}/Staff`, 'GET', null, {
      auth: false,
      host: RESIDENT_HOST,
    })
      .then((response) => {
        return response;
      })
      .then((data) => {
        // Tour template
        const temp = question_template3;
        createAnswer(data.users, temp.answers);
        setStaffTemplate(temp);
        // Leased template
        const temp1 = question_template4;
        createAnswer(data.users, temp1.answers);
        setLeasedTemplate(temp1);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  // Get all maintenance staff and append to existing question template
  function getMaintenance(slug) {
    // fetch(`${HOST}/users/${slug}/Maintenance`)
    makeRequest(`/users/${slug}/Maintenance`, 'GET', null, {
      auth: false,
      host: RESIDENT_HOST,
    })
      .then((response) => {
        return response;
      })
      .then((data) => {
        const temp = question_template2;
        createAnswer(data.users, temp.answers);
        setMaintenanceTemplate(temp);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  // Helper function to combine user info with question template
  // Parameters: list of objects, empty list to be modified
  function createAnswer(newList, emptyList) {
    // Loop through newList
    for (let item of newList) {
      // Create obj with the items we want
      let newItem = {
        id: item.id,
        text: item.name,
        nextURL: '/special-offer',
      };
      // Push newItem to emptyList
      emptyList.push(newItem);
    }
  }

  useEffect(() => {
    getApartment(setAptTemplate, getSlug());
    getLeasingStaff(getSlug());
    getMaintenance(getSlug());
  }, []);

  return (
    <div className="survey-page">
      {reviewscommunityLoading ||
      (requestHistoryLoading &&
        Object.keys(reviewscommunityData).length === 0 &&
        reviewscommunityData?.payload?.length !== 0) ? (
        <>
          <div className="loading-overlay flex aic">
            <CircularProgress />
          </div>
        </>
      ) : (
        <>
          {(communityUsersLoading || userReviewLoading) && (
            <div className="loading-overlay flex aic">
              <CircularProgress />
            </div>
          )}
          <div
            style={{
              background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${
                Object.keys(reviewscommunityData).length !== 0 &&
                reviewscommunityData?.payload[0]?.banner_img
              }) center center`,
            }}
            className="survey-banner"
          >
            <div className="question-banner-text">
              <div className="font s20 cfff">Welcome to</div>
              <div className="font community-name s34 b6 cfff">
                {Object.keys(reviewscommunityData).length !== 0 &&
                  reviewscommunityData?.payload[0]?.name}
              </div>
            </div>
          </div>
          <div className="wrapper flex flex-col">
            <div className="hdr flex aic">
              {step <= 3 && (
                <button
                  onClick={() => {
                    setStep(step - 1);
                    setRequestHistory(null);
                  }}
                  className={`cleanbtn back-btn font s15 ${
                    step <= 1 ? 'c999' : 'c000'
                  }`}
                  disabled={step <= 1}
                >
                  Go Back
                </button>
              )}
            </div>
            {step == 1 && reviewscommunityData?.payload && (
              <Question
                step={step}
                setRoute={handleRouteUpdate}
                apt_template={reviewscommunityData?.payload[0]}
                question_template={
                  (reviewscommunityData?.payload[0]?.settings &&
                    JSON.parse(reviewscommunityData?.payload[0]?.settings)
                      .question_template) ||
                  question_template
                }
              />
            )}
            {step == 2 &&
              communityUsers?.payload &&
              reviewscommunityData?.payload && (
                <Question
                  setWho={setWho}
                  step={step}
                  setRoute={handleRouteUpdate}
                  setQuestionType={setQuestionType}
                  apt_template={reviewscommunityData?.payload[0]}
                  question_template={communityUsers?.payload}
                />
              )}
            {step == 3 && communityUsers?.payload && (
              <Review
                staff={who.name}
                setRoute={handleRouteUpdate}
                setFeedback={setFeedback}
                value={requestHistory.data.defaultReviewStars}
                apt_template={reviewscommunityData?.payload[0]}
                selectedStaffBadges={selectedStaffBadges}
                selectedAptBadges={selectedAptBadges}
                setSelectedStaffBadges={setSelectedStaffBadges}
                setSelectedAptBadges={setSelectedAptBadges}
              />
            )}
            {step == 4 && reviewscommunityData?.payload && (
              <Thanks
                reviewData={reviewData}
                updateRequestHistory={updateRequestHistory}
                updateReview={updateReview}
                setRoute={handleRouteUpdate}
                requestHistory={requestHistory}
                rating={reviewData?.rating}
                apt_template={reviewscommunityData?.payload[0]}
              />
            )}

            {route === '/maintenance-completed' &&
              aptTemplate &&
              maintenanceTemplate && (
                <Question
                  setWho={setWho}
                  step={step}
                  setRoute={handleRouteUpdate}
                  apt_template={reviewscommunityData?.payload[0]}
                  question_template={maintenanceTemplate}
                />
              )}
            {route === '/signed-lease' && aptTemplate && leasedTemplate && (
              <Question
                setWho={setWho}
                step={step}
                setRoute={handleRouteUpdate}
                apt_template={reviewscommunityData?.payload[0]}
                question_template={leasedTemplate}
              />
            )}
            {route === '/ask-review' && aptTemplate && (
              <Question
                step={step}
                setRoute={handleRouteUpdate}
                apt_template={reviewscommunityData?.payload[0]}
                question_template={question_template1}
              />
            )}
            {route === '/special-offer' && aptTemplate && (
              <SpecialOffer
                setRoute={handleRouteUpdate}
                apt_template={aptTemplate}
              />
            )}
            {route === '/exit-page' && aptTemplate && (
              <Exit
                getSlug={getSlug}
                who={who}
                apt_template={aptTemplate}
                rating={rating}
                feedback={feedback}
                selectedStaffBadges={selectedStaffBadges}
                selectedAptBadges={selectedAptBadges}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
