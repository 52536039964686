import { useState } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { Button } from '@material-ui/core';

const WidgetColorPicker = ({ widgetColorPrimary, setWidgetColorPrimary }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setWidgetColorPrimary(color.hex);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: '16px',
        height: '16px',
        borderRadius: '8px',
        background: `${widgetColorPrimary}`,
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  return (
    <div>
      <Button
        variant="outlined"
        startIcon={<div style={styles.color} />}
        onClick={handleClick}
      >
        {widgetColorPrimary}
      </Button>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker
            color={widgetColorPrimary}
            onChange={handleChange}
          />{' '}
          {/* originally widgetColorPrimary was color */}
        </div>
      ) : null}
    </div>
  );
};

export default WidgetColorPicker;
