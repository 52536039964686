import React from 'react';

export default function TourBubble({ className, src, defaultConfig }) {
  return (
    <div
      className={`flex ${
        defaultConfig?.layout === 'right' && 'justify-end'
      } ${className}`}
    >
      <div
        style={{
          position: 'relative',
          height: 120,
          width: 120,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `8px solid ${defaultConfig?.primaryColor || '#224493'}`,
          borderRadius: 100,
        }}
      >
        <video
          src={src}
          muted
          autoPlay
          playsInline
          loop
          style={{
            height: '100%',
            width: '100%',
            borderRadius: 100,
            overflow: 'hidden',
            objectFit: 'cover',
          }}
        />
        <span
          style={{
            position: 'absolute',
            textAlign: 'center',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '16px',
            fontFamily: 'inherit',
            textShadow: '0px 0px 4px black',
          }}
        >
          {defaultConfig?.buttonLabel}
        </span>
      </div>
    </div>
  );
}
