import axios from 'axios';

export const getAllReferrals = (community_id) => async (dispatch) => {
  try {
    dispatch({ type: 'GET_ALL_REFERRALS_REQUEST' });

    const res = await axios.get(
      `https://api.directual.com/good/api/v5/data/conversations/queryConversationsBy?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
    );

    // console.log(res.data);

    dispatch({
      type: 'GET_ALL_REFERRALS_SUCCESS',
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: 'GET_ALL_REFERRALS_FAIL',
      payload: error.message,
    });
    console.log(error.message);
  }
};

export const getReferralUsers = (community_id) => async (dispatch) => {
  try {
    dispatch({ type: 'GET_REFERRAL_USERS_REQUEST' });

    const res = await axios.get(
      `https://api.directual.com/good/api/v5/data/users/getReferralUsersInCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
    );

    // console.log('referralUsers...', res.data);

    dispatch({
      type: 'GET_REFERRAL_USERS_SUCCESS',
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: 'GET_REFERRAL_USERS_FAIL',
      payload: error.message,
    });
    console.log(error.message);
  }
};
