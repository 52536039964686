import React from 'react';
import './BottomBar.css';
import { Calendar, DollarSign, Phone } from './Icons';

const BottomBar = ({
  bottomButtons = [],
  onClickLink,
  setIframeVideoUnderlayToggle,
  iframeVideoUnderlayToggle,
  url,
  img,
  currentRoute,
}) => {
  const IS_DESKTOP = !window.matchMedia('only screen and (max-width: 600px)')
    .matches;

  const iconStyle = {
    width: 20,
    height: 20,
    marginRight: 9,
  };
  return (
    <>
      <div
        style={{
          width: '100%',
          height: 1,
          backgroundColor: '#e2e8f0',
        }}
      />
      <div
        style={{
          height: bottomButtons?.length > 0 ? 50 : 30,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          borderTop: '1px solid white',
          cursor: 'pointer',
          zIndex: 3,
        }}
      >
        {bottomButtons?.map((button, i) => {
          console.log({ underlay: button });
          return (
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (button?.iframe_underlay === iframeVideoUnderlayToggle) {
                  setIframeVideoUnderlayToggle(null);
                  return;
                }
                if (button?.iframe_underlay) {
                  // set the iframevideounderlaytoggle state to the iframe underlay url
                  setIframeVideoUnderlayToggle(button.iframe_underlay);
                } else {
                  onClickLink(button);
                }
              }}
              className={`boldOnHover ctaTab ${
                iframeVideoUnderlayToggle === button.iframe_underlay ||
                currentRoute === button.route
                  ? 'selectedTab'
                  : ''
              }`}
            >
              {button?.icon === 'phone' ? (
                <Phone style={iconStyle} />
              ) : button?.icon === 'money' ? (
                <DollarSign style={iconStyle} />
              ) : button?.icon === 'calendar' ? (
                <Calendar style={iconStyle} />
              ) : null}

              {IS_DESKTOP ? <p className="boldOnHover">{button.text}</p> : null}
            </button>
          );
        })}

        <a
          href={
            url || 'https://tour.video' //'https://leasemagnets.com'
          }
          target="_blank"
          className="permanentButton boldOnHover"
          style={{
            height: '100%',
            backgroundColor: 'white',
            color: 'black',
            border: 'none',

            fontSize: 'small',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          rel="noreferrer"
        >
          <img
            className="opacityShiftOnHover"
            style={{
              width: '90%',
              maxHeight: bottomButtons?.length > 0 ? 25 : 20,
              objectFit: 'contain',
            }}
            src={
              img ||
              'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/f1241207-f4a8-4e8a-030e-110332ad6200/public'
              // 'https://assets.website-files.com/5e45b17b74507185e599a039/62d2d73c5bbb74b37358fc16_LeaseMagnets%20Logo%20TYG%204.png'
            }
          />
        </a>
      </div>
    </>
  );
};

export default BottomBar;
