import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import firebase from './base';
import axios from 'axios';
import { API_HOST, get } from './request';

export function getRandomId() {
  let hexString = uuidv4();
  console.log('hex:   ', hexString);

  // remove decoration
  hexString = hexString.replace(/-/g, '');

  let base64String = Buffer.from(hexString, 'hex').toString('base64');
  console.log('base64:', base64String);

  return base64String;
}

export function titleCleaner(str) {
  if (str) {
    var strLower = str.toLowerCase();
    return strLower.replace(/\W/g, '');
  }

  return false;
}

export const validFileName = (inFileName) => {
  const validFileNameChars =
    'abcdefghijklmnopqrstuvwxy-ABCDEFGHIJKLMNOPQRSTUVWXYZ_1234567890';
  const reducer = (accumulator, currentValue) =>
    accumulator +
    (validFileNameChars.includes(currentValue) ? currentValue : '_');
  const outFileName = inFileName.split('').reduce(reducer, '');
  console.log('filename is = ', outFileName);
  return outFileName;
};

const getFileBlob = function (url) {
  return new Promise(function (resolve, reject) {
    let out;
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.addEventListener('load', function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      }
    });
    xhr.send();
  });
};
// gets the filename including the path to this screen and gets lowest unused extension
async function getExtension(
  storageRef,
  prefix,
  name,
  origName,
  findextension = true
) {
  if (!name) {
    name = 'untitled';
  }
  console.log('origName', { prefix, name, origName, findextension });
  //console.log('origName12',origName)

  // check if in filename exists
  let filename =
    prefix +
    '/' +
    name +
    '.' +
    origName.split('.')[origName.split('.').length - 1];
  console.log('filename', filename);
  let storageFile = storageRef.child(filename);
  try {
    if (!findextension) {
      return filename;
    }
    const res = await storageFile.getDownloadURL();
    console.log('res', res);
  } catch (err) {
    console.log('storage_error', err);
    // will throw an error if doesn't exists yet
    return filename;
  }

  // find lowest unused extension
  let extension = 1;

  while (true) {
    filename =
      prefix +
      '/' +
      name +
      '_' +
      extension +
      '.' +
      origName.split('.')[origName.split('.').length - 1];
    storageFile = storageRef.child(filename);
    try {
      await storageFile.getDownloadURL();
      extension += 1;
    } catch (err) {
      console.log('storage_error', err);
      // will throw an error if doesn't exists yet
      return filename;
    }
  }
}
export async function uploadFile(file, setProgress, bucket, subBucket) {
  setProgress(null);

  const storageRef = await firebase.storage.ref(bucket).child(subBucket);
  const uploadTask = storageRef.put(file);
  uploadTask.on('state_changed', function (snapshot) {
    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setProgress(Math.round(progress));
  });
  const url = await uploadTask
    .then((snapshot) => snapshot.ref.getDownloadURL())
    .then(async (url) => {
      console.log(url);
      setProgress(null);
      return url;
    })
    .catch((err) => {
      setProgress(null);
      console.log(err);
    });
  return url;
}

export const handleUpload = async (
  file,
  setProgress,
  outerBucketPath,
  innerBucketpath,
  onSuccess = (url) => console.log(url),
  onError = (err) => console.log(err)
) => {
  try {
    const url = await uploadFile(
      file,
      setProgress,
      outerBucketPath,
      innerBucketpath
    );

    onSuccess(url);

    return url;
  } catch (err) {
    onError(err);
    console.log(err);
  }
};

export const BasicFileUploadComponent = ({
  defaultFile,
  outerPath = 'imagebucket',
  innerPath = `/`,
  onSuccess,
  video = false,
  placeholder = 'Upload a profile picture',
}) => {
  const [progress, setProgress] = useState(null);
  const [url, setUrl] = useState(defaultFile);

  useEffect(() => {
    setUrl(defaultFile);
  }, [defaultFile]);

  const onSelectImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setUrl(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
      console.log(e.target.files[0]);
      // setFileName(e.target.files[0].name);
      handleUpload(
        e.target.files[0],
        setProgress,
        outerPath,
        innerPath + `/${uuidv4()}`,
        (url) => {
          setUrl(url);
          onSuccess(url);
        }
      );
    }
  };

  return (
    <div className="flex items-center justify-center w-full mt-2 ">
      <label className="flex justify-center flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300 cursor-pointer">
        <>
          <div className="flex flex-col items-center justify-center pt-7">
            {url ? (
              video ? (
                <video className="aspect-video h-28 " src={url} />
              ) : (
                <img src={url} className="h-16 object-contain" />
              )
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=" h-12 text-gray-400 group-hover:text-gray-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                  clip-rule="evenodd"
                />
              </svg>
            )}

            <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
              {placeholder}
            </p>
          </div>
        </>

        <input
          onClick={(e) => (e.target.value = '')}
          onChange={onSelectImage}
          type="file"
          accept={video ? 'video/*' : 'image/*'}
          className="opacity-0"
        />
      </label>
    </div>
  );
};

export const uploadBlobToStorage = (
  localVideoURL,
  name,
  prefix = '',
  type,
  origName,
  onProgress,
  findextension
) => {
  return new Promise(function (resolve, reject) {
    //console.log('local video url: ', localVideoURL);
    console.log(
      'uploadBlobToStorage params',
      localVideoURL,
      name,
      prefix,
      type,
      origName,
      findextension
    );
    const fileBlob = getFileBlob(localVideoURL);
    const storageRef = firebase.storage.ref();
    const extension = getExtension(
      storageRef,
      prefix,
      validFileName(name),
      origName,
      findextension
    );

    console.log('extension: ', extension, fileBlob);

    let upload;
    // wait until we have both the fileBlob and extension before continuing
    Promise.all([fileBlob, extension]).then((results) => {
      console.log('upload data = ', results);
      console.log('extension: ', extension, fileBlob);

      // sends to firebase
      upload = storageRef
        .child(results[1])
        .put(results[0], { contentType: type });

      let timeStarted = new Date();
      upload.on('state_changed', function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('extension: ', extension, fileBlob);

        //var timecontroller = setInterval(function(){
        let timeElapsed = new Date() - timeStarted; // Assuming that timeStarted is a Date Object
        let uploadSpeed = snapshot.bytesTransferred / (timeElapsed / 1000); // Upload speed in second
        const timeestimate =
          (snapshot.totalBytes - snapshot.bytesTransferred) / uploadSpeed;
        onProgress(progress, timeestimate);
        switch (snapshot.state) {
          case 'paused':
            console.log('local video Upload is paused');
            break;
          case 'running':
            console.log('local video Upload is running');
            break;
        }
      });
      // on complete get the url and return it
      upload.then(() => {
        upload.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log('local video download url: ', downloadURL);
          resolve(downloadURL);
        });
      });
    });
  });
};

export const deletevideo = (path) => {
  const storageRef = firebase.storage.ref();
  // Create a reference to the file to delete
  var desertRef = storageRef.child(path);

  // Delete the file
  desertRef
    .delete()
    .then(() => {
      console.log('File deleted successfully');
      // File deleted successfully
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
      console.log(error);
    });
};

export const deletefilefromcloudstorage = (path) => {
  let res = axios({
    method: 'POST',
    url: API_HOST + `/delete_file?old_file=` + path,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  console.log('deletefilefromcloudstorage', res);
};
