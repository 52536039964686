import axios from 'axios';
import { API_HOST } from './request';
import { v4 } from 'uuid';

export const getRoom = async (roomId) => {
  const response = await axios.get(`${API_HOST}/hms/get_room?roomId=${roomId}`);
  return response.data.res;
};

export const getActiveRoom = async (roomId) => {
  const response = await axios.get(
    `${API_HOST}/hms/get_active_room?roomId=${roomId}`
  );
  return response.data.res;
};

export const getPeers = async (roomId) => {
  const response = await axios.get(
    `${API_HOST}/hms/list_peers?roomId=${roomId}`
  );
  return response.data.res.peers;
};

export const getRoomCodes = async (roomId) => {
  const response = await axios.get(
    `${API_HOST}/hms/get_room_codes?roomId=${roomId}`
  );
  return response.data.res.data;
};

export const updateRoom = async (roomId, data) => {
  const response = await axios.post(`${API_HOST}/hms/update_room`, {
    data,
    roomId,
  });
  return response.data.res;
};

export const createRoom = async (data) => {
  const sanitizedName = data?.name.replace(/[^a-zA-Z0-9.:-_]/g, '');
  try {
    const response = await axios.post(`${API_HOST}/hms/create_room`, {
      data : {name : sanitizedName},
    });
    return response.data.res;    
  } catch (error) {
    console.log("create_room",error)
  }

};

// export const getRecordingsByRoom = async (roomId) => {
//   return await axios.get(`https://api.100ms.live/v2/recording-assets/<asset_id>`)
// }

export const getRecordings = async (community_id) => {
  const response = await axios.get(
    `${API_HOST}/hms/get_recordings?communityId=${community_id}`
  );
  return response.data.res;
};

export const getRecordingPeers = async (session_id) => {
  const response = await axios.get(
    `${API_HOST}/hms/get_recording_peers?sessionId=${session_id}`
  );
  return response.data.res;
};

export const regenerateRoom = async (roomId, data) => {
  const response = await axios.post(`${API_HOST}/hms/regenerate_room`, {
    roomId,
    data,
  });

  return response.data.res;
};

export const disableRoom = async (roomId) => {
  await updateRoom(roomId, { name: v4() });
  const response = await axios.post(`${API_HOST}/hms/disable_room`, {
    roomId,
  });

  return response.data.res;
};
