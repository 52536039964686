import { Grid, Snackbar, IconButton } from '@material-ui/core';
import DomainIcon from '@material-ui/icons/Domain';
import Switch from '@material-ui/core/Switch';
import MemoryIcon from '@material-ui/icons/Memory';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import DateRangeIcon from '@material-ui/icons/DateRange';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import GroupIcon from '@material-ui/icons/Group';
import WifiIcon from '@material-ui/icons/Wifi';
import SubjectIcon from '@material-ui/icons/Subject';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import PersonIcon from '@material-ui/icons/Person';
import CodeIcon from '@material-ui/icons/Code';
import RefreshIcon from '@material-ui/icons/Refresh';
import PublicIcon from '@material-ui/icons/Public';
import AssessmentIcon from '@material-ui/icons/Assessment';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import ContentLoader from '../../components/ContentLoader';
import { getanalyicsbigquery } from '../tour-magnet-tabs/utils';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import moment from 'moment';
import { getDurationString } from '../../utils/moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useRequest } from '../../utils/request';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { supabase } from '../../services/supabase';

/*
 * These following *Info Components are the "cards" within the dropdown that show various
 * information about the lead and what information they left
 */

const LeadUserInfo = ({
  name,
  email,
  phone,
  time,
  leased,
  city,
  region,
  country,
  os,
  numVisits,
  utm_source,
}) => {
  return (
    <Grid item xs={3} className="flex-col flex gap-y-3">
      <h1 className="font-bold text-xl mb-2 break-words">{name}</h1>
      <div className="flex gap-x-3 items-center">
        <EmailIcon className="text-gray-500" />
        <h2
          className="text-md font-medium break-words"
          style={{ width: 'calc(100% - 24px - 0.75rem)' }}
        >
          {email}
        </h2>
      </div>
      <div className="flex gap-x-3 items-center">
        <PhoneIcon className="text-gray-500" />
        <h2
          className="text-md font-medium break-words"
          style={{ width: 'calc(100% - 24px - 0.75rem)' }}
        >
          {phone}
        </h2>
      </div>
      {city || region || country ? (
        <div className="flex gap-x-3 items-center">
          <PublicIcon className="text-gray-500" />
          <h2
            className="text-md font-medium break-words"
            style={{ width: 'calc(100% - 24px - 0.75rem)' }}
          >
            {city
              ? `${city}, ${region}, ${country}`
              : region
              ? `${region}, ${country}`
              : `${country}`}
          </h2>
        </div>
      ) : (
        <></>
      )}
      <div className="flex gap-x-3 items-center" title="Lead Creation Date">
        <DateRangeIcon className="text-gray-500" />
        <h2
          className="text-md font-medium break-words"
          style={{ width: 'calc(100% - 24px - 0.75rem)' }}
        >
          {time}
        </h2>
      </div>
      {os ? (
        <div className="flex gap-x-3 items-center" title="Operating System">
          <MemoryIcon className="text-gray-500" />
          <h2
            className="text-md font-medium break-words"
            style={{ width: 'calc(100% - 24px - 0.75rem)' }}
          >
            {os}
          </h2>
        </div>
      ) : (
        <></>
      )}
      {numVisits ? (
        <div
          className="flex gap-x-3 items-center"
          title="Number of times this user opened the magnet"
        >
          <AssessmentIcon className="text-gray-500" />
          <h2
            className="text-md font-medium break-words"
            style={{ width: 'calc(100% - 24px - 0.75rem)' }}
          >
            {numVisits} visits
          </h2>
        </div>
      ) : (
        <></>
      )}
      {utm_source ? (
        <div className="flex gap-x-3 items-center" title="UTM Source">
          <DomainIcon className="text-gray-500" />
          <h2
            className="text-md font-medium break-words"
            style={{ width: 'calc(100% - 24px - 0.75rem)' }}
          >
            {utm_source}
          </h2>
        </div>
      ) : (
        <></>
      )}
      {leased && (
        <div className="flex gap-x-3 items-center">
          <CheckCircleIcon className="text-gray-500" />
          <h2
            className="text-md font-medium break-words"
            style={{ width: 'calc(100% - 24px - 0.75rem)' }}
          >
            {leased}
          </h2>
        </div>
      )}
    </Grid>
  );
};

const LeadTourInfo = ({
  tour_type,
  tour_time_from,
  tour_time_to,
  tour_date,
  tour_link,
}) => {
  return (
    <Grid item xs={3} className="flex-col flex gap-y-3">
      <h1 className="font-bold text-xl mb-2">Tour Details</h1>
      {tour_type === 'Virtual' ? (
        <div className="flex gap-x-3 items-center">
          <WifiIcon className="text-gray-500" />
          <h2 className="text-md font-medium">Virtual</h2>
          <a href={tour_link} target="_blank">
            <button className="bg-blue-400 rounded border px-2 py-1 text-white font-bold">
              Join
            </button>
          </a>
        </div>
      ) : (
        <div className="flex gap-x-3 items-center">
          <GroupIcon className="text-gray-500" />
          <h2 className="text-md font-medium">In Person</h2>
        </div>
      )}
      <div className="flex gap-x-3 items-center" title="Appointment Date">
        <DateRangeIcon className="text-gray-500" />
        <h2 className="text-md font-medium w-full">
          {moment(tour_date).format('MMM Do, YYYY')}
        </h2>
      </div>
      <div className="flex gap-x-3 items-center">
        <ScheduleIcon className="text-gray-500" />
        <h2 className="text-md font-medium">{`${tour_time_from} - ${tour_time_to}`}</h2>
      </div>
    </Grid>
  );
};

const OldLeadNoteInfo = ({ message }) => {
  return (
    <>
      <h1 className="font-bold text-xl mb-2">Note</h1>
      <div className="flex gap-x-3 items-center">
        <SubjectIcon className="text-gray-500" />
        <h2 className="text-md font-medium">{message}</h2>
      </div>
    </>
  );
};

const LeadQuestionInfo = ({ floor_plan_code, message }) => {
  return (
    <>
      <h1 className="font-bold text-xl mb-2">Question</h1>
      {floor_plan_code && ( // if floor plan question
        <div className="flex gap-x-3 items-center">
          <MeetingRoomIcon className="text-gray-500" />
          <h2 className="text-md font-medium">Floor Plan: {floor_plan_code}</h2>
        </div>
      )}
      <div className="flex gap-x-3 items-center">
        <SubjectIcon className="text-gray-500" />
        <h2 className="text-md font-medium">{message}</h2>
      </div>
    </>
  );
};

const LeadGeneralInfo = ({ message }) => {
  return (
    <>
      <h1 className="font-bold text-xl mb-2">Lead Capture</h1>
      <div className="flex gap-x-3 items-center">
        <SubjectIcon className="text-gray-500" />
        <h2 className="text-md font-medium">{message}</h2>
      </div>
    </>
  );
};

const LeadPromotionInfo = ({ message }) => {
  return (
    <>
      <h1 className="font-bold text-xl mb-2">Promotion</h1>
      <div className="flex gap-x-3 items-center">
        <SubjectIcon className="text-gray-500" />
        <h2 className="text-md font-medium">Claiming promotion: {message}</h2>
      </div>
    </>
  );
};

const LeadReferralInfo = ({ referral_source, message }) => {
  return (
    <>
      <h1 className="font-bold text-xl mb-2">Referral</h1>
      <div className="flex gap-x-3 items-center">
        <PersonIcon className="text-gray-500" />
        <h2 className="text-md font-medium">Source: {referral_source}</h2>
      </div>
      <div className="flex gap-x-3 items-center">
        <SubjectIcon className="text-gray-500" />
        <h2 className="text-md font-medium">{message}</h2>
      </div>
    </>
  );
};

/**
 * Renders a timeline using the history prop passed in. The top of the component
 * has the date and external page at the top of it, and durations between events are
 * calculated
 * @param {Array, Object} props - history is an array of the events, template is the template from redux
 * @returns A timeline with durations and events
 */
const HistoryTimeline = ({ history, template }) => {
  const getScreenName = (event_type, to) => {
    if (event_type === 'close_tour') {
      return 'Tour closed';
    } else if (event_type === 'form_submission') {
      return 'Form submitted';
    }

    const [categoryKey, screenKey] = to.split('.');
    if (
      categoryKey in template.categories &&
      screenKey in template.categories[categoryKey].screens
    ) {
      return template.categories[categoryKey].screens[screenKey].title;
    } else {
      return to;
    }
  };

  return (
    <div key={history[0][0]}>
      <div className="text-base italic text-gray-500 mt-2">
        {moment(history[0][0]).format('MMM DD, YYYY, hh:mm a')}
      </div>
      <div className="text-base italic text-gray-500 mb-1">
        Path: {history[0][3]}
      </div>
      <div className="flex flex-col gap-4 relative">
        <div
          className={`border-r-4 border-blue-500 top-1 left-1.5 absolute`}
          style={{
            height: `${
              4 * history.length -
              1.5 *
                history.reduce(
                  // counts how many times a single line entry appears in the timeline and accounts for the 1.5 rem of missed space because of that
                  (count, [_, event_type, __]) =>
                    count + (event_type === 'close_tour'),
                  0
                ) -
              1.5 // an extra one because of no gap at end + line height bigger than dot by .5 rem
            }rem`,
          }}
        ></div>
        {history.map(([timestamp, event_type, to], index) => (
          <div className="flex gap-2" key={timestamp}>
            {/*this long class is the blue dot */}
            <div className="w-4 h-4 bg-blue-500 rounded-full flex justify-center items-center flex-shrink-0 top-1 relative z-10">
              <div className="w-2 h-2 bg-white rounded-full"></div>
            </div>
            <div>
              <h2 className="text-base font-bold whitespace-nowrap">
                {getScreenName(event_type, to)}
              </h2>
              <h2 className="text-base font-medium text-gray-500 whitespace-nowrap">
                {event_type !== 'close_tour' &&
                  index < history.length - 1 &&
                  getDurationString(
                    moment(timestamp),
                    moment(history[index + 1][0])
                  )}
              </h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

/**
 * Renders partial timelines in a list sorted with the most recent timeline first
 * @param {Array} history an array of events with no duplicates and starting with an open_tour
 * @returns a container that vertically renders HistoryTimelines, split on each close_tour event
 */
const HistoryInfo = ({ history }) => {
  const { template, magnetLoading } = useSelector((state) => state.getMagnet);
  // template is used to map raw route names to human readable ones

  /**
   * Splits the array by the elements causing isDelimiter to be true - these elements will
   * be included at the end of the previous array
   * @param {*} arr array of elements to split
   * @param {*} isDelimiter function that takes in an element and returns true or false
   * @returns array of arrays in the same order with each array being a slice of the
   * original array ending in the element that matches isDelimiter
   * For example:
   * [1, 3, 2, 5, 4, 4] with isDelimeter checking if even
   * should return [[1, 3, 2], [5, 4], [4]]
   */
  function splitArrayByDelimiter(arr, isDelimiter) {
    let res = arr.reduce(
      (result, item) => {
        if (isDelimiter(item)) {
          result[result.length - 1].push(item);
          result.push([]);
        } else {
          result[result.length - 1].push(item);
        }
        return result;
      },
      [[]]
    );
    return res[res.length - 1].length === 0 ? res.slice(0, -1) : res;
  }

  return (
    <Grid
      item
      xs={4}
      className="flex-col flex gap-y-3"
      style={{ maxWidth: '400px' }}
    >
      <h1 className="font-bold text-xl mb-2">Tour History</h1>

      <div
        className="flex flex-col gap-4 relative max-h-80 overflow-y-scroll overflow-x-visible scroll-shadows"
        style={{ minHeight: '16rem' }}
      >
        {!history || magnetLoading ? (
          <>
            <div className="flex gap-2">
              <Skeleton variant="circle" height={16} width={16} />
              <div className="flex flex-col gap-2">
                <Skeleton variant="rect" height={16} width={200} />
                <Skeleton variant="rect" height={16} width={60} />
              </div>
            </div>
            <div className="flex gap-2">
              <Skeleton variant="circle" height={16} width={16} />
              <div className="flex flex-col gap-2">
                <Skeleton variant="rect" height={16} width={200} />
                <Skeleton variant="rect" height={16} width={60} />
              </div>
            </div>
            <div className="flex gap-2">
              <Skeleton variant="circle" height={16} width={16} />
              <div className="flex flex-col gap-2">
                <Skeleton variant="rect" height={16} width={200} />
                <Skeleton variant="rect" height={16} width={60} />
              </div>
            </div>
            <div className="flex gap-2">
              <Skeleton variant="circle" height={16} width={16} />
              <div className="flex flex-col gap-2">
                <Skeleton variant="rect" height={16} width={200} />
                <Skeleton variant="rect" height={16} width={60} />
              </div>
            </div>
          </>
        ) : history.length ? (
          splitArrayByDelimiter(
            history,
            ([_, event_type]) => event_type === 'close_tour'
          )
            .reverse()
            .map((partialHistory) => (
              <HistoryTimeline
                history={partialHistory}
                template={template}
                key={partialHistory[0][0]}
              />
            ))
        ) : (
          <div className="text-gray-500">
            No history found for this lead in this community
          </div>
        )}
      </div>
    </Grid>
  );
};

const Notes = ({ leadData, magnetData }) => {
  let metadata = [];
  let message = '';
  if (!Array.isArray(leadData?.details?.metadata)) {
    metadata = [
      {
        ...leadData?.details?.metadata,
        lead_type: leadData?.details?.lead_type,
      },
    ];
    message = 'not array';
  } else {
    metadata = [...leadData?.details?.metadata];
  }

  console.log('metadata 123', metadata);

  const tours = [];
  const notes = [];
  for (const entry of metadata) {
    if (entry.lead_type == 'Tour') {
      tours.push(entry);
    } else notes.push(entry);
  }

  console.log('tours 123', tours);
  console.log('notes 123', notes);

  return (
    <>
      {/*Either type of tour*/}
      {tours.map((elem) => (
        <LeadTourInfo
          tour_type={elem?.tour_type}
          tour_date={elem?.tour_time_start}
          tour_time_from={elem?.tour_time_from}
          tour_time_to={elem?.tour_time_to}
          tour_link={encodeURI(
            `https://meet.jit.si/tour/${magnetData?.magnets[0]?.name} ${
              leadData?.name
            } ${moment(elem?.tour_time_from, 'hh:mm A').format('hhA')}`
          )}
        />
      ))}
      {notes.length > 0 && (
        <Grid item xs={3} className="flex-col flex gap-y-3">
          {notes.map((elem, index) => {
            console.log(`ELEMENTAL ${index}`, elem);
            return (
              <div key={index}>
                {/*Question, Floor Plan*/}
                {(elem.lead_type === 'Question' ||
                  elem.lead_type === 'Floor Plan') && (
                  <LeadQuestionInfo
                    floor_plan_code={elem?.floor_plan_code}
                    message={elem?.message}
                  />
                )}
                {/* Contact */}
                {elem.lead_type === 'Contact' && (
                  <LeadGeneralInfo message={elem?.message} />
                )}
                {/* Promotion */}
                {/* previously elem.actions?.specialOfferEmailTitle */}
                {elem.lead_type === 'Promotion' && (
                  <LeadPromotionInfo message={elem.message} />
                )}
                {/* Referral */}
                {elem.lead_type === 'Referral' && (
                  <LeadReferralInfo
                    referral_source={elem?.referral_source}
                    message={elem?.message}
                  />
                )}
              </div>
            );
          })}
        </Grid>
      )}
    </>
  );
};

/**
 * The entire dropdown of a lead. Conditionally renders panels based on what exists in the
 * data. Will query BigQuery to load history on the "first" render to get a list of history.
 * Should work on the schema right before 2023-04-30 and the one after with more functionality
 * @param {Object} props
 * @returns The dropdown
 */
const LeadActionPanel = (props) => {
  // leadData is the content of a supabase row as an object in js
  const { leadData, groupVisitors, leads, setLeads } = props;
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [loading, error, data, makeRequest] = useRequest({ loading: false });
  const [leased, setLeased] = useState(leadData?.leased);
  const [showRawData, setShowRawData] = useState(false);

  /**
   * history is an array of events that look like:
   * [
   *  [time, event_type, destination, ...],
   *  [time, event_type, destination, ...],
   *  [time, event_type, destination, ...]
   * ]
   */
  const [history, setHistory] = useState(null);
  const { magnetData, magnetLoading } = useSelector((state) => state.getMagnet);

  async function handleLeased() {
    post('/handle/leased', {
      visitor_uuid: leadData.visitor_uuid,
      leased: !leased,
    });
    console.log('leads updated = ', error, data);
    if (error) {
      console.log(error);
    } else {
      setSnackbarMessage(
        'Successfully updated ' +
          (leadData?.name ? leadData?.name + "'s " : '') +
          'leased status'
      );
      const temp = leads;
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].visitor_uuid === leadData.visitor_uuid) {
          temp[i].leased = !leased;
        }
      }
      setLeased(!leased);
      setLeads(temp);
      //setLeadCount(temp?.leads?.length);
    }
  }

  /**
   * this useEffect queries bigQuery for all the events matching the visitor_uuid, and
   * updates the history ref to show the events in ascending order like so:
   * [
   *  [timestamp (day of week, DD MMM YYYY hh:mm:ss TZ), event_type, to],
   *  [timestamp (day of week, DD MMM YYYY hh:mm:ss TZ), event_type, to],
   *  ...
   * ]
   */
  useEffect(() => {
    const getHistory = async () => {
      if (typeof leadData.details === 'string') {
        // using older, deprecated, possibly corrupted schema, visitor_uuid won't match up so don't call for history
        return;
      }
      if (history === null && 'visitor_uuid' in leadData) {
        const bigQueryHistoryResponse =
          // location is used as an indicator of whether we have tried to query bigquery for location, utm_source, os
          // it is set as a key regardless of whether the data was found or not
          'location' in leadData.details
            ? await getanalyicsbigquery(
                `SELECT utc_time, event_type, \`to\`, doc_path FROM \`leasemagnets-app.default.events\` WHERE visitor_uuid = '${leadData?.visitor_uuid}' AND magnet_uuid = '${leadData?.magnet_uuid}' ORDER BY utc_time ASC LIMIT 100`
              )
            : await getanalyicsbigquery(
                `SELECT utc_time, event_type, \`to\`, doc_path, location_city, location_region, location_country, utm_source, parsed_ua_os_family FROM \`leasemagnets-app.default.events\` WHERE visitor_uuid = '${leadData?.visitor_uuid}' AND magnet_uuid = '${leadData?.magnet_uuid}' ORDER BY utc_time ASC LIMIT 100`
              );

        if (bigQueryHistoryResponse.status !== 'success') {
          console.error(
            'Error fetching history from BigQuery in LeadActionPanel: ',
            bigQueryHistoryResponse.error
          );
        }

        const filteredHistoryObj = bigQueryHistoryResponse.res
          .filter(
            ([_, event_type], index, array) =>
              !(
                (
                  event_type === 'form_view' || // form_view is redundant because it occurs at same time as button_click
                  (event_type === 'close_tour' &&
                    (index === 0 ||
                      (index >= 1 && array[index - 1][1] === 'close_tour')))
                )
                // only keep close_tour which is not preceded by close_tour and not first elem
              )
          )
          .filter(
            ([timestamp, event_type], index, array) =>
              !(
                event_type !== 'close_tour' &&
                index < array.length - 1 &&
                getDurationString(
                  moment(timestamp),
                  moment(array[index + 1][0])
                ) === '0 sec'
              ) // remove events that are 0 seconds long (possibly unreal)
          );

        setHistory(filteredHistoryObj);

        // we did not request from bigquery so we don't have metadata to update
        if ('location' in leadData.details || !filteredHistoryObj.length) {
          return;
        }

        // use larger history object to set missing properties (location, utm_source, etc.)
        // and update supabase value (this does not update the local leadData obj.)
        const lastEvent = filteredHistoryObj[filteredHistoryObj.length - 1];
        const { error } = await supabase
          .from('Lead')
          .update({
            details: {
              ...leadData.details,
              location: {
                city: lastEvent[4],
                region: lastEvent[5],
                country: lastEvent[6],
              },
              utm_source: lastEvent[7],
              os: lastEvent[8],
              numVisits: filteredHistoryObj.reduce(
                (acc, elem) => acc + (elem[1] === 'open_tour'),
                0
              ),
            },
          })
          .eq('id', leadData.id);

        if (error) {
          console.error(
            'Error updating supabase values with bigQuery results:',
            error
          );
        }
      }
    };

    getHistory();
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarMessage ? true : false}
        autoHideDuration={6000}
        onClose={() => setSnackbarMessage()}
        message={snackbarMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => window.location.reload()}
            >
              <RefreshIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <ContentLoader loading={loading} error={error}>
        <Grid container spacing={6} className="bg-gray-50 m-0 w-auto">
          <LeadUserInfo
            name={leadData?.name}
            email={leadData?.email}
            phone={leadData?.phone}
            city={
              leadData?.details?.location?.city ||
              (history?.length && history[history.length - 1][4])
            }
            region={
              leadData?.details?.location?.region ||
              (history?.length && history[history.length - 1][5])
            }
            country={
              leadData?.details?.location?.country ||
              (history?.length && history[history.length - 1][6])
            }
            os={
              leadData?.details?.location?.os ||
              (history?.length && history[history.length - 1][8])
            }
            time={moment(leadData?.time, 'YYYY-MM-DD hh:mm:ss').format(
              'MMM Do, YYYY'
            )}
            leased={leadData?.leased}
            numVisits={
              leadData?.details?.numVisits ||
              history?.reduce((acc, elem) => acc + (elem[1] === 'open_tour'), 0)
            }
            utm_source={
              leadData?.details?.utm_source ||
              (history?.length && history[history.length - 1][7])
            }
          />
          {typeof leadData?.details === 'string' ? (
            <Editor value={leadData} />
          ) : 'metadata' in leadData?.details /*using new schema*/ ? (
            <Notes leadData={leadData} magnetData={magnetData} />
          ) : (
            <>
              {' '}
              {/* using old schema */}
              {leadData?.details?.entrata_event_type == 'VirtualTour' ||
              leadData?.details?.entrata_event_type == 'Appointment' ? (
                <LeadTourInfo
                  tour_type={
                    leadData?.details?.entrata_event_type === 'VirtualTour'
                      ? 'Virtual'
                      : 'In-Person'
                  }
                  tour_date={leadData?.details?.appointmentDate}
                  tour_time_from={leadData?.details?.timeFrom}
                  tour_time_to={leadData?.details?.timeTo}
                />
              ) : (
                <Grid item xs={3} className="flex-col flex gap-y-3">
                  <OldLeadNoteInfo
                    message={
                      leadData?.details?.comments ||
                      leadData?.details.your_question ||
                      leadData?.details?.note
                    }
                  />
                </Grid>
              )}
            </>
          )}
          <HistoryInfo history={history} />
        </Grid>

        <div className="bg-gray-50 pl-4 pb-4">
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={leased}
                onChange={() => {
                  handleLeased();
                }}
              />
            }
            label="Leased"
          />
          <div
            className="mt-2 rounded cursor-pointer"
            onClick={() => setShowRawData(!showRawData)}
          >
            <CodeIcon className="text-slate-500" />
            <p className="text-slate-500 inline-block ml-2 underline ">
              {showRawData ? 'Hide' : 'Show'} raw data
            </p>
          </div>
        </div>

        {showRawData && (
          <>
            <Editor value={leadData?.details} />
            <Editor value={leadData} />
          </>
        )}
      </ContentLoader>
    </>
  );
};

export default LeadActionPanel;
