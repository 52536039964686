import React from 'react';
import x1 from '../../assets/x1.png';
import x2 from '../../assets/x2.png';
import x3 from '../../assets/x3.png';
import x4 from '../../assets/x4.png';
const Output = () => {
  return (
    <div className=" my-20 w-full ">
      <h1 className=" text-center text-2xl font-bold w-full ">
        Get 2x the output* of an in-house video <br /> editor, and save money
        while you’re at it.
      </h1>
      <div className=" my-10 flex items-center flex-col lg:flex-row justify-center gap-9">
        <div className=" flex flex-col lg:block items-center lg:w-60">
          <img src={x1} alt="" />
          <p className=" pt-4 text-sm font-bold ">
            Guaranteed 1-2 days turnaround.{' '}
          </p>
          <p className=" text-sm pt-1">
            Our video editors promptly answer feedback and deliver revisions in
            as little as 2 hours.
          </p>
        </div>
        <div className=" flex flex-col lg:block items-center lg:w-60">
          <img src={x2} alt="" />
          <p className=" pt-4 text-sm font-bold ">
            Guaranteed 1-2 days turnaround.{' '}
          </p>
          <p className=" text-sm pt-1">
            Our video editors promptly answer feedback and deliver revisions in
            as little as 2 hours.
          </p>
        </div>
        <div className=" flex flex-col lg:block items-center lg:w-60">
          <img src={x3} alt="" />
          <p className=" pt-4 text-sm font-bold ">
            Guaranteed 1-2 days turnaround.{' '}
          </p>
          <p className=" text-sm pt-1">
            Our video editors promptly answer feedback and deliver revisions in
            as little as 2 hours.
          </p>
        </div>
        <div className=" flex flex-col lg:block items-center lg:w-60">
          <img src={x4} alt="" />
          <p className=" pt-4 text-sm font-bold ">
            Guaranteed 1-2 days turnaround.{' '}
          </p>
          <p className=" text-sm pt-1">
            Our video editors promptly answer feedback and deliver revisions in
            as little as 2 hours.
          </p>
        </div>
      </div>
      <div className=" mt-10 flex items-center justify-center w-full">
        <button className=" px-4 text-sm hover:bg-nhvr bg-nvr font-medium py-2 rounded-md text-white">
          View Pricing
        </button>
      </div>
    </div>
  );
};

export default Output;
