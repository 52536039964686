import {
  InputAdornment,
  Select,
  TextField,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import React from 'react';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import { AiOutlineClose } from 'react-icons/ai';

export default function BehaviorModal({
  open,
  setOpen,
  chatbot,
  setChatbot,
  selectedRow,
}) {
  function handleCloseTypeChange(newValue, layout) {
    const newChatbot = [...chatbot];
    const newCloseDuration =
      newValue === 'instant'
        ? 0
        : newValue === '1min'
        ? 60000
        : newValue === '5min'
        ? 300000
        : newValue === '10min'
        ? 600000
        : newValue === '30min'
        ? 1800000
        : newValue === '60min'
        ? 3600000
        : 0;

    newChatbot[selectedRow] = {
      ...chatbot[selectedRow],
      closeType: { [layout]: newValue },
      closeDuration: { [layout]: newCloseDuration },
    };
    setChatbot(newChatbot);
  }

  function handleCloseDurationChange(newValue, layout) {
    const newChatbot = [...chatbot];
    newChatbot[selectedRow] = {
      ...chatbot[selectedRow],
      closeDuration: { [layout]: newValue * 1000 },
    };
    setChatbot(newChatbot);
  }
  console.log(
    'chatbot[selectedRow].closeDuration',
    chatbot?.[selectedRow]?.closeDuration
  );

  return (
    <AnimatedDialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      maxWidth="sm"
    >
      <div className="p-4 w-full">
        <div className="flex items-center justify-between border-b border-gray-300 w-full">
          <h4 className=" pb-3 text-xl font-semibold">Bubble Behaviors</h4>
          <AiOutlineClose
            className=" w-7 h-7 cursor-pointer text-gray-600"
            onClick={() => setOpen(false)}
          />
        </div>
        <div className="flex w-full gap-4 justify-between items-center mt-4">
          <h2 className="w-80">Close Behavior on Desktop:</h2>
          <FormControl variant="outlined" className="flex-grow">
            <Select
              value={chatbot?.[selectedRow]?.closeType?.desktop ?? 'instant'}
              onChange={(e) => {
                handleCloseTypeChange(e.target.value, 'desktop');
              }}
            >
              <MenuItem value="instant">hide until reload</MenuItem>
              <MenuItem value="1min">hide for 1 minute</MenuItem>
              <MenuItem value="5min">hide for 5 minutes</MenuItem>
              <MenuItem value="10min">hide for 10 minutes</MenuItem>
              <MenuItem value="30min">hide for 30 minutes</MenuItem>
              <MenuItem value="60min">hide for an hour</MenuItem>
              <MenuItem value="custom">hide for custom duration</MenuItem>
            </Select>
          </FormControl>
          {chatbot?.[selectedRow]?.closeType?.desktop === 'custom' && (
            <TextField
              type="number"
              label="Close time"
              variant="outlined"
              value={
                (chatbot?.[selectedRow]?.closeDuration?.desktop ?? 0) / 1000
              }
              onChange={(e) => {
                handleCloseDurationChange(Number(e.target.value), 'desktop');
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">s</InputAdornment>,
              }}
            />
          )}
        </div>
        <div className="flex w-full gap-4 justify-between items-center mt-4">
          <h2 className="w-80">Close Behavior on Mobile:</h2>
          <FormControl variant="outlined" className="flex-grow">
            <Select
              value={chatbot?.[selectedRow]?.closeType?.mobile ?? 'instant'}
              onChange={(e) => {
                handleCloseTypeChange(e.target.value, 'mobile');
              }}
            >
              <MenuItem value="instant">hide until reload</MenuItem>
              <MenuItem value="1min">hide for 1 minute</MenuItem>
              <MenuItem value="5min">hide for 5 minutes</MenuItem>
              <MenuItem value="10min">hide for 10 minutes</MenuItem>
              <MenuItem value="30min">hide for 30 minutes</MenuItem>
              <MenuItem value="60min">hide for an hour</MenuItem>
              <MenuItem value="custom">hide for custom duration</MenuItem>
            </Select>
          </FormControl>
          {chatbot?.[selectedRow]?.closeType?.mobile === 'custom' && (
            <TextField
              type="number"
              label="Close time"
              variant="outlined"
              value={
                (chatbot?.[selectedRow]?.closeDuration?.mobile ?? 0) / 1000
              }
              onChange={(e) => {
                handleCloseDurationChange(Number(e.target.value), 'mobile');
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">s</InputAdornment>,
              }}
            />
          )}
        </div>
      </div>
    </AnimatedDialog>
  );
}
