import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
//material ui dialog box
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ButtonDialog = ({
  children,
  title = 'summary',
  titleCoded = React.Fragment,
  icon = React.Fragment,
  width = '100px',
  height = '100px',
  color = '',
  image = '',
  roundCorner = '5px',
  textIn = true,
  customButtonText,
  customButtonFunction,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Button onClick={handleClickOpen}>
          {textIn && (
            <Card>
              <div
                style={{
                  width: width,
                  height: height,
                  textAlign: 'center',
                  alignContent: 'center',
                  borderTopRightRadius: '5px',
                  borderTopLeftRadius: '5px',
                  borderRadius: roundCorner,
                }}
              >
                <div
                  style={{
                    width: width,
                    height: '60%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: color,
                  }}
                >
                  <img src={image} alt="" />
                  {/* {icon} */}
                </div>
                <div
                  style={{
                    width: width,
                    height: '40%',
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontFamily: 'Poppins, sans-serif',
                    color: '#121212',
                    lineHeight: 1.4,
                  }}
                >
                  {title}
                </div>
              </div>
            </Card>
          )}
          {!textIn && (
            <>
              <div>
                <Card>
                  <div
                    style={{
                      width: width,
                      height: height,
                      textAlign: 'center',
                      alignContent: 'center',
                      borderTopRightRadius: '5px',
                      borderTopLeftRadius: '5px',
                      borderRadius: roundCorner,
                    }}
                  >
                    <div
                      style={{
                        width: width,
                        height: height,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: color,
                      }}
                    >
                      <img src={image} alt="" />
                      {/* {icon} */}
                    </div>
                  </div>
                </Card>
                <div
                  style={{
                    width: width,
                    textAlign: 'center',
                    alignContent: 'center',
                  }}
                >
                  {title}
                </div>
              </div>
            </>
          )}
        </Button>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth="true"
        >
          <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Back
            </Button>
            {customButtonText && (
              <Button
                variant="contained"
                onClick={async () => {
                  const res = await customButtonFunction();
                  setOpen(res);
                }}
                color="primary"
              >
                {customButtonText}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}; // end of ButtonDialog

export default ButtonDialog;
