import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function Notification() {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('This is a success message');
  const [severity, setSeverity] = React.useState('success');

  const handleClick = () => {
    setOpen(true);
    console.log('open value: ', open);
    setTimeout(function () {
      setOpen(false);
      console.log('open value: ', open);
    }, 5000);
  };

  function setNotification(
    messageUpdate = 'Success' /* MESSAGE (usage: string) | severity(warning / error / info / success) */,
    severityUpdate = 'success'
  ) {
    setMessage(messageUpdate);
    setSeverity(severityUpdate);
    handleClick();
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const NotificationBar = ({
    fullWidth = true,
    floating = true,
    buttonTitle = '',
    customBtnHandler = null,
    floatingBottomMargin = '1vh',
    floatingBottom = '1vh',
  }) => {
    const classes = useStyles();

    let handleButtonClick = () => {
      console.log('alert button click');
    };

    if (floating) {
      return (
        <div className={classes.root}>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            style={{
              marginBottom: floatingBottomMargin,
              bottom: floatingBottom,
            }}
          >
            <Alert onClose={handleClose} severity={severity}>
              {message}
              {'              '}
              {buttonTitle !== '' && (
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => {
                    if (customBtnHandler) {
                      customBtnHandler();
                    } else {
                      handleButtonClick();
                    }
                  }}
                >
                  {' '}
                  {buttonTitle}{' '}
                </Button>
              )}
            </Alert>
          </Snackbar>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        {/* TYG */}
        {open && (
          <Alert onClose={handleClose} severity={severity}>
            {message}
          </Alert>
        )}
      </div>
    );
  };
  {
    /* <div className={classes.root}> */
  }

  {
    /* <Button variant="outlined" onClick={handleClick}>
        Open success snackbar
      </Button> */
  }
  {
    /* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar> */
  }
  {
    /* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */
  }
  // </div >
  // );

  return [NotificationBar, setNotification];
}
