/* 
    Authentication Levels:
        Global: Internal LeaseMagnets team only, needs to be manually assigned on Firestore (find user's document via matching uuid) by creating a boolean property "global_admin"=true
        (the following levels are community specific)
        Company: Assigned to the user who creates the community (only one company admin per community)
        Property: Assigned by "Company" Admin, allows multiple per community
        User: Assigned by "Company" or "Property" Admin, allows multiple per community
*/
export const ACCESS_LEVEL = Object.freeze({
  none: 0,
  user: 1,
  property: 2,
  company: 3,
  global: 4,
});

export const font = 'Epilogue';
