import React, { forwardRef } from 'react';

const Messages = forwardRef(({ message, username }, ref) => {
  const isUser = username === message.username;

  return (
    <div className={`message-blk flex aic ${isUser ? 'user' : 'frd'}`}>
      <div ref={ref} className="item">
        {isUser === false && (
          <div className="dp">
            <div
              className="img"
              style={{
                backgroundImage: 'url(https://placeimg.com/350/350/people?5)',
              }}
            />
          </div>
        )}
        <div className={`txt font s14 c333 ${isUser && 'user-txt'}`}>
          {message.text}
        </div>
      </div>
    </div>
  );
});
export default Messages;
