import React from 'react';
import stt from '../../assets/stt.png';
const Outsource = () => {
  return (
    <div style={{ background: '#FFF8D7' }} className=" w-full py-10 mt-10">
      <h1 className=" text-center text-2xl font-bold w-full text-gray-700">
        Outsource all your video needs to the top 1% <br /> of the video talent
        in the world.
      </h1>
      <div className=" mt-10 flex items-center gap-16 w-full justify-center flex-col lg:flex-row">
        <img src={stt} alt="" />
        <div className=" lg:w-80">
          <h1 className=" text-xl font-light">
            At video.pro, you get only top <br /> quality, vetted video editors.
          </h1>
          <p className=" pt-4 pb-2 text-sm font-bold">Screen</p>
          <p className=" text-sm">
            Video.pro has a low acceptance rate. Only the best get through. We
            sort through thousands of applications and portfolios to get to the
            top video talent.
          </p>
          <p className=" pt-4 pb-2 text-sm font-bold">Test</p>
          <p className=" text-sm">
            All qualified video editors get tested on their skills to ensure
            quality.
          </p>
          <p className=" pt-4 pb-2 text-sm font-bold">Train</p>
          <p className=" text-sm">
            Our video editors are always sculpting their skills through our
            dedicated internal training program, “Video.pro Academy”
          </p>
        </div>
      </div>
    </div>
  );
};

export default Outsource;
