import IconButton from '@material-ui/core/IconButton';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { supabase } from '../../services/supabase';

export default function MagnetSummaryRow({ community_id, title }) {
  // const { encodingQ, magnetId, magnetType, previewTemplate, screenToEdit } =
  //   useSelector((state) => state.magnetStates);

  // const { template, magnetLoading } = useSelector((state) => state.getMagnet);

  const [videos, setvideos] = useState([]);

  const getcommunity_videos = async () => {
    const { data, error } = await supabase
      .from('VideoLibrary')
      .select('*')
      .eq('community_id', community_id);
    console.log('data', data);
    if (data) {
      setvideos(data);
    }
  };

  useEffect(() => {
    getcommunity_videos();
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {videos?.map((item) => (
        <button
          onClick={() => {
            console.log('pressed');
          }}
          style={{ width: '100px', height: '100px' }}
        >
          {/* {item?.filename} */}
        </button>
      ))}
    </div>
  );
}
