import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import BlackChart from '../main/BlackChart';
import moment from 'moment';
import { supabase } from '../../../services/supabase';
import { CHART_DURATION } from '../main/Review';

export default function ReviewAnalytics({ reviews }) {
  // const [blackChartOptions, setBlackChartOptions] = useState();

  const blackChartOptions = useMemo(getBlackChartOptions);

  function getBlackChartOptions() {
    let ranges = {};

    let series = [
      {
        name: 'Reviews',
        data: reviews?.map(({ created_at, review_rating }) => [
          new Date(created_at).getTime(),
          review_rating,
        ]),
        type: 'scatter',
        fill: {
          type: 'image',
          opacity: 1,
          image: {
            src: [
              'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/70812f1c-bb57-4ed1-26da-d6ba093ded00/w=40,sharpen=1',
            ],
            width: 40,
            height: 40,
          },
        },
      },
    ];
    return { ranges, series };
  }

  // useEffect(() => {
  //   getBlackChartOptions();
  // }, [reviews]);

  // useEffect(() => {
  //   console.log(
  //     'blackChartOptions seen from ReviewAnalytics',
  //     blackChartOptions
  //   );
  // }, [blackChartOptions]);

  return (
    <div className="flex items-start mt-2 gap-3 flex-col ">
      <h1 className="font-bold text-2xl">Review Analytics</h1>

      {!reviews ? (
        <>
          <div className="border border-blue-100  shadow rounded-md p-4 max-w-lg w-full ">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-3 py-1">
                <div className="h-2 bg-gray-200 rounded"></div>
                <div className="h-2 bg-gray-200 rounded w-32"></div>
                <div className="space-y-4 bg-gray-100 p-3">
                  <div className="h-2 bg-gray-200 rounded "></div>
                  <div className=" flex items-center flex-wrap justify-center gap-10">
                    <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                      G
                    </div>
                    <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                      G
                    </div>
                    <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                      G
                    </div>
                  </div>
                  <div className=" flex items-center flex-wrap justify-center gap-10">
                    <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                      G
                    </div>
                    <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                      G
                    </div>
                  </div>
                  <div className=" flex items-center flex-wrap justify-center gap-10">
                    <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                      G
                    </div>
                    <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                      G
                    </div>
                    <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                      G
                    </div>
                    <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                      G
                    </div>
                  </div>
                  <div className="flex items-center gap-6 flex-wrap  justify-center">
                    <div className="h-2 bg-gray-200 w-12 rounded "></div>
                    <div className="h-2 bg-gray-200 w-12 rounded "></div>
                    <div className="h-2 bg-gray-200 w-12 rounded "></div>
                    <div className="h-2 bg-gray-200 w-12 rounded "></div>
                    <div className="h-2 bg-gray-200 w-12 rounded "></div>
                  </div>
                  <div className="flex items-center gap-6  justify-center">
                    <div className="h-2 bg-gray-200 w-24 rounded "></div>
                    <div className="h-2 bg-gray-200 w-24 rounded "></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {isEmpty(reviews) && (
            <div>
              <h1 className="text-2xl text-gray-400">No data available</h1>
            </div>
          )}
          {blackChartOptions && (
            <BlackChart blackChartOptions={blackChartOptions} />
          )}
        </>
      )}
    </div>
  );
}
