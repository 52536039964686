import React from 'react';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import * as notFound from '../../lottie/404.json';
import './style.scss';

function PageNotFound(props) {
  const _notFound_ = {
    loop: true,
    autoplay: true,
    animationData: notFound.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="not-found-p flex flex-col aic">
      <div className="vector">
        <Lottie options={_notFound_} width={300} />
      </div>
      <div className="meta">
        <div className="lbl font s22 c000">Opps, sorry page does not found</div>
        <div className="txt font s14 c777">
          Sorry you have not access to dashboard communities
        </div>
        <Link to="/" classnames=""></Link>
      </div>
    </div>
  );
}

export default PageNotFound;
