import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Rating from '@material-ui/lab/Rating';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { parseJsonPayloadHorizontally } from '../../utils/eventUtils';
import { BasicFileUploadComponent } from '../../utils/files';
import SendRecording from '../manage-magnets/SendRecording';

export default function ChampionDialogEdit() {
  const history = useHistory();
  const _dispatch = useDispatch();
  const { id, userId } = useParams();
  const [championUser, setChampionUser] = useState(null);
  const [champions, setChampions] = useState(null);
  const [championBadges, setChampionBadges] = useState([]);
  const [communityBadges, setCommunityBadges] = useState([]);
  const [subBadges, setSubBadges] = useState([]);
  const [reviewBadges, setReviewBadges] = useState([]);

  const [inputData, setInputData] = useState({
    name: '',
    email: '',
    bio: '',
    reviewRating: '',
    reviewText: '',
    reviewVideo: '',
  });

  const CustomToastWithLink = () => (
    <div>
      User updated successfully,
      <span
        className="text-pr hover:underline"
        onClick={() => history.push(`/referrers/champions/community/${id}`)}
      >
        See updated champions
      </span>
    </div>
  );

  const getcommunity = async () => {
    const res = await axios.get(
      `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${id}`
    );
    console.log('response', res, res.data.payload);
    let temp = parseJsonPayloadHorizontally(res.data.payload);
    console.log('response2', temp);
    if (temp?.settings?.goasl) {
      // setgoals({ ...temp.settings.goals });
    }
    if (res.data.payload.length > 0) {
      // setShowStepForm(false);

      try {
        console.log(
          'thejson commbadges: ',
          temp?.[0]?.communitybadges?.communitybadges || []
        );
        let thejson = {
          ...temp[0],
          champions: temp?.[0]?.champions?.champions || [],
          communitybadges: temp?.[0]?.communitybadges?.communitybadges || [],
          reviews: temp?.[0]?.reviews?.reviews || [],
          settings: temp?.[0]?.settings || {},
          team: temp?.[0]?.team?.team || [],
        };
        //console.log('response',JSON.parse(res.data?.payload[0].champions))
        console.log('thejson champions', thejson);
        setChampionUser(thejson?.champions[userId]);
        setChampions(thejson?.champions);

        setInputData({
          ...inputData,
          name: thejson?.champions[userId]?.name,
          email: thejson?.champions[userId]?.email,
          bio: thejson?.champions[userId]?.bio || '',
          reviewRating: thejson?.champions[userId]?.reviewRating || '',
          reviewText: thejson?.champions[userId]?.reviewText || '',
          reviewVideo: thejson?.champions[userId]?.reviewVideo || '',
        });
        setChampionBadges(thejson?.settings?.championssettings);
        setSubBadges(thejson?.champions[userId]?.badges || []);
        setReviewBadges(thejson?.champions[userId]?.reviewBadges || []);
        setCommunityBadges(thejson?.communitybadges);

        // setDirectualCommunity({ ...thejson });
        // console.log('after', directualCommunity);
        // setcreatecommunityenable(true);
        // setloading(false);
      } catch (error) {
        console.log('thejson errr', error);
      }
    } else {
      // setShowStepForm(true);
      console.log('thejson response_empty');
    }
  };

  useEffect(() => {
    getcommunity();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  let badges = championBadges
    ? Object.keys(championBadges).map((key) => {
        return {
          type: key,
          names: championBadges[key].map((item) => item.subcategory),
        };
      })
    : [];

  const reformatedBadges = badges.reduce((akku, row) => {
    const badgeRows = [];
    row.names.map((name) => {
      badgeRows.push({
        type: row.type,
        name: name,
      });
    });
    akku = akku.concat(badgeRows);
    return akku;
  }, []);

  console.log('communityBadges', { badges, reviewBadges });

  useEffect(() => {
    setInputData({ ...inputData, badges: subBadges });
  }, [subBadges]);
  useEffect(() => {
    setInputData({ ...inputData, reviewBadges });
  }, [reviewBadges]);
  useEffect(() => {
    setChampionUser({ ...championUser, ...inputData });
  }, [inputData, subBadges, reviewBadges]);

  const updatecommunity = async (community_obj) => {
    let tempChamp = [...champions];
    tempChamp[userId] = championUser;

    let postData = {
      id: id,
      champions: {
        champions: tempChamp,
      },
    };

    console.log('postData', postData);
    toast.success(CustomToastWithLink);
    try {
      console.log('postData', postData);
      const community = await axios.post(
        `https://api.directual.com/good/api/v5/data/community/newCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
        postData
      );

      console.log('userUpdated after review TYG: ', community);
    } catch (e) {
      console.log(e.response);
    }
  };

  const openRecordVideoModal = () => {
    _dispatch({
      type: 'SHOW_SCREEN_RECORDING',
      payload: true,
    });
  };

  return (
    <div className="referral-dialog">
      <div className="referrals-container flex flex-col">
        <div className="agent-detail">
          <BasicFileUploadComponent
            defaultFile={championUser?.profile}
            outerPath={id}
            innerPath={
              `/community/${id}/champions/profile/` + userId.toString()
            }
            onSuccess={(url) => {
              console.log('huzzah, the upload component works TYG', url);
              setInputData({ ...inputData, profile: url });
            }}
          />

          <div className="user flex aic">
            <div
              className="img"
              style={{
                backgroundImage: `url(${
                  championUser?.profile ||
                  `https://ui-avatars.com/api/?name=${encodeURI(
                    championUser?.name
                  )}`
                } )`,
              }}
            />
            <div className="meta flex flex-col">
              <div className="name">{championUser?.name}</div>
              <div className="values">
                {subBadges?.map((value, idx) => (
                  <div key={idx} className="txt">
                    <span className="dot">•</span>
                    <span>{value.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="about font">
            I'm a mountain climber, working hard USC 21 Lets connect you two !
          </div>
          <div className="form flex flex-col">
            <div className="field-row">
              <div className="field">
                <input
                  onChange={(e) => handleChange(e)}
                  name="name"
                  value={inputData?.name}
                  type="text"
                  placeholder="Name"
                  className="iput font"
                />
              </div>
            </div>
            <div className="field-row">
              <div className="field">
                <input
                  onChange={(e) => handleChange(e)}
                  name="email"
                  value={inputData?.email}
                  type="text"
                  placeholder="Email"
                  className="iput font"
                />
              </div>
            </div>
            <div className="field-row">
              <div className="field">
                <input
                  onChange={(e) => handleChange(e)}
                  name="reviewText"
                  value={inputData?.reviewText}
                  type="text"
                  placeholder="Review Text"
                  className="iput font"
                />
              </div>
            </div>
            <div className="field-row">
              <div className="field">
                <textarea
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder={`Bio`}
                  className="iput font"
                  value={inputData?.bio}
                  name="bio"
                />
              </div>
            </div>
            <div className="extra-detail">
              <div className="reviews">
                <div className="lbl font s14 black">
                  What Magdalena has to say about the Latitude
                </div>
                <Rating
                  name="reviewRating"
                  value={inputData?.reviewRating}
                  precision={0.5}
                  size="medium"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="user-badges">
                <Autocomplete
                  multiple
                  limitTags={2}
                  className="my-4"
                  id="multiple-limit-tags"
                  options={reformatedBadges}
                  value={subBadges}
                  onChange={(event, newValue) => {
                    setSubBadges(newValue);
                  }}
                  groupBy={(option) => option?.type}
                  getOptionLabel={(option) => option?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Badges"
                      placeholder="Choose Bades"
                    />
                  )}
                />

                <Autocomplete
                  multiple
                  limitTags={2}
                  className="my-4"
                  id="multiple-limit-tags"
                  options={communityBadges}
                  value={reviewBadges}
                  onChange={(event, newValue) => {
                    setReviewBadges(newValue);
                  }}
                  groupBy={(option) => option?.category}
                  getOptionLabel={(option) => option?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Review Badges"
                      placeholder="Choose Bades"
                    />
                  )}
                />
              </div>
            </div>
            <BasicFileUploadComponent
              video
              placeholder="Upload a review video"
              defaultFile={championUser?.reviewVideo}
              outerPath={id}
              innerPath={
                `/community/${id}/champions/reviewVideo/` + userId.toString()
              }
              onSuccess={(url) => {
                setInputData({ ...inputData, reviewVideo: url });
              }}
            />
            Or
            <div className="field-row">
              <div className="field">
                <button
                  onClick={openRecordVideoModal}
                  className="cleanbtn connect-btn font"
                >
                  Record a Video
                </button>
              </div>
            </div>
            <SendRecording
              onVideoUrl={(url) => {
                console.log('Video URL======>', url);
                setInputData({ ...inputData, reviewVideo: url });
              }}
              // questionIndex={selectedQuestionIndex}
              // setReview={setReview}
              // review={review}
              // setdefaultquestion={setdefaultquestion}
              // defaultquestion={defaultquestion}
            />
            <div className="field-row">
              <div className="field">
                <button
                  onClick={updatecommunity}
                  className="cleanbtn connect-btn font"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
