import React from 'react';
import Slider from 'react-slick';
import slider1 from '../../assets/slider1.png';
import slider2 from '../../assets/slider2.png';
import slider3 from '../../assets/slider3.png';
const FirstSlider = () => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className=" w-full bg-black py-3">
      <Slider {...settings}>
        <div>
          <img className=" h-7 object-contain" src={slider1} alt="" />
        </div>
        <div>
          <img className=" h-7 object-contain" src={slider2} alt="" />
        </div>

        <div>
          <img className=" h-7 object-contain" src={slider3} alt="" />
        </div>
        <div>
          <img className=" h-7 object-contain" src={slider1} alt="" />
        </div>
        <div>
          <img className=" h-7 object-contain" src={slider2} alt="" />
        </div>

        <div>
          <img className=" h-7 object-contain" src={slider3} alt="" />
        </div>
        <div>
          <img className=" h-7 object-contain" src={slider1} alt="" />
        </div>
        <div>
          <img className=" h-7 object-contain" src={slider2} alt="" />
        </div>

        <div>
          <img className=" h-7 object-contain" src={slider3} alt="" />
        </div>
      </Slider>
    </div>
  );
};

export default FirstSlider;
