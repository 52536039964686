import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Header } from '../index';
export default function CustomHeader({ url, name, community_id, title }) {
  const _dispatch = useDispatch();
  return (
    <Header>
      <div className="dashboard-hdr flex aic w-full">
        <div className="left flex aic">
          <a href={url} target="_blank">
            <OpenInNewIcon />
          </a>
          &nbsp;&nbsp;
          <Link to="/" className="item flex aic">
            <div className="txt font s14 b3 anim">{name}</div>
            {name ? <span className="slash">&nbsp;/&nbsp;</span> : null}
          </Link>
          <div className="item flex aic">
            <div
              onClick={() => {
                _dispatch({
                  type: 'SCREEN_TO_EDIT',
                  payload: '',
                });
                window.history.pushState({}, `View ${community_id}`, `#`);
              }}
              className="txt font s14 b5 black"
            >
              {title}
            </div>
          </div>
        </div>
      </div>
    </Header>
  );
}
