import React, { useEffect, useState } from 'react';
import FlipMove from 'react-flip-move';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { keyCodes, keyupListener } from '../../core/index';
import Messages from './Messages';

function ChatBox(props) {
  const [messages, setMessages] = useState([
    { username: 'Ahad', text: 'Hy!' },
    { username: 'Ahad', text: 'How are you' },
  ]);
  const [input, setInput] = useState('');
  const [username, setUsername] = useState('Mubashir');
  const [searchbar, setSearchbar] = useState(false);
  const [gallery, setGallery] = useState(false);
  const [nav, setNav] = useState([
    { label: 'Media', slug: '/media' },
    { label: 'Audio', slug: '/audio' },
    { label: 'Doc', slug: '/doc' },
    { label: 'Voice', slug: '/voice' },
    { label: 'Link', slug: '/link' },
  ]);
  const [topMenu, setTopMenu] = useState(false);

  const media = [
    { media: 'https://placeimg.com/350/350/people?12' },
    { media: 'https://placeimg.com/350/350/people?13' },
    { media: 'https://placeimg.com/350/350/people?14' },
    { media: 'https://placeimg.com/350/350/people?15' },
    { media: 'https://placeimg.com/350/350/people?16' },
    { media: 'https://placeimg.com/350/350/people?17' },
    { media: 'https://placeimg.com/350/350/people?18' },
    { media: 'https://placeimg.com/350/350/people?19' },
    { media: 'https://placeimg.com/350/350/people?20' },
  ];
  const community = [
    { media: 'https://placeimg.com/350/350/people?21' },
    { media: 'https://placeimg.com/350/350/people?22' },
    { media: 'https://placeimg.com/350/350/people?23' },
    { media: 'https://placeimg.com/350/350/people?24' },
    { media: 'https://placeimg.com/350/350/people?25' },
    { media: 'https://placeimg.com/350/350/people?26' },
    { media: 'https://placeimg.com/350/350/people?27' },
  ];

  const _dispatch = useDispatch();

  const sendMessages = () => {
    setMessages([...messages, { username: username, text: input }]);
    setInput('');
  };

  useEffect(() => {
    document.body.addEventListener('click', () => {
      setTopMenu(false);
    });
  });

  return (
    <div className="user-chat-box-section flex rel">
      <div className="block flex flex-col">
        {/* Header */}
        <div className="head flex flex-col sticky anim">
          <div className="flex aic">
            <div className="lef flex flex-col">
              <div className="name font s22 b c333">Muhammad Ali</div>
              <div className="flex aic">
                <div className="status font s13 c777 anim">Online</div>
                <button
                  className="btn cleanbtn font c555 flex aic anim"
                  onClick={() => setGallery(!gallery)}
                >
                  <div className="ico s15 icon-image" />
                  <div className="s13">Gallery</div>
                </button>
                <button
                  className="btn cleanbtn font c555 flex aic anim"
                  onClick={() => setSearchbar(!searchbar)}
                >
                  <div className="ico s15 icon-search" />
                  <div className="s13">Find</div>
                </button>
              </div>
            </div>
            <div className="rig flex aic">
              {/* large Screen */}
              <div className={`full flex aic ${topMenu && 'display'}`}>
                <div className="flex flex-col aic">
                  <button className="cleanbtn icon-btn icon-video s20 c555 anim" />
                  <div className="lbl font s14 c555">Video call</div>
                </div>
                <div className="flex flex-col aic">
                  <button className="cleanbtn icon-btn icon-phone s20 c555 anim" />
                  <div className="lbl font s14 c555">Voice call</div>
                </div>
                <div className="flex flex-col aic">
                  <button className="cleanbtn icon-btn icon-user-plus s20 c555 anim" />
                  <div className="lbl font s14 c555">Add friend</div>
                </div>
                <div className="flex flex-col aic">
                  <button
                    className="cleanbtn icon-btn back icon-cross s20 c555 anim"
                    onClick={(e) => {
                      setTopMenu(false);
                    }}
                  />
                  <div className="lbl font s14 c555">Close</div>
                </div>
              </div>

              {/* Mobile Screen */}
              <div className="min flex aic">
                <button
                  className="cleanbtn icon-btn icon-align-justify s20 c555 anim"
                  onClick={(e) => {
                    e.stopPropagation();
                    setTopMenu(!topMenu);
                  }}
                />
                <button
                  className="cleanbtn icon-btn back icon-cross s20 c555 anim"
                  onClick={() =>
                    _dispatch({ type: 'CHAT_BOX', payload: false })
                  }
                />
              </div>
            </div>
          </div>
          <div className={`search anim ${searchbar && 'show'}`}>
            <div className={`srch flex aic`}>
              <div className="search-bar flex aic anim">
                <div className="icon icon-search s20 c555" />
                <input
                  type="text"
                  placeholder="Find Messages in current conversation"
                  className="iput font s14 c333"
                />
              </div>
              <div className="action flex aic">
                <div className="counter font s14 c333">0&#47;0</div>
                <div className="btns flex aic">
                  <button className="cleanbtn btn s16 flex aic icon-chevron-up" />
                  <button className="cleanbtn btn s16 flex aic icon-chevron-down1" />
                </div>
                <button
                  className="cleanbtn cancel font s13 c333 anim"
                  onClick={() => setSearchbar(!searchbar)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Content Block */}
        <div className="content">
          {messages == '' ? (
            <div className="empty-section anim">
              <div className="font s18 b c333">Start Conversation</div>
              <div className="txt font s14 c777">
                write hi and start chat with your friend
              </div>
            </div>
          ) : (
            <FlipMove>
              {messages.map((data, index) => (
                <Messages key={index} username={username} message={data} />
              ))}
            </FlipMove>
          )}
        </div>

        {/* Footer */}
        <div className="chatbox-ftr flex aic sticky">
          <div className="left flex aic anim">
            <div className="typing-area flex aic anim">
              <div className="stickers">
                <div className="icon-smile s24 c333" />
              </div>
              <input
                type="text"
                placeholder="Type a Messages"
                value={input}
                onKeyUp={(e) => {
                  keyupListener(e, keyCodes.ENTER, () => {
                    sendMessages();
                  });
                }}
                onChange={(e) => setInput(e.target.value)}
                className="cleanbtn iput font s15 c333"
              />
            </div>
            {input !== '' && (
              <button
                onClick={sendMessages}
                className="cleanbtn icon-btn icon-send s20 c555 anim"
              />
            )}
          </div>
          {input === '' && (
            <div className="actions flex aic anim">
              <button className="cleanbtn icon-btn icon-mic s20 c333 anim" />
              <button className="cleanbtn icon-btn icon-file-text s20 c333 anim" />
              <button className="cleanbtn icon-btn icon-more-horizontal s20 c333 anim" />
            </div>
          )}
        </div>
        {topMenu && <div className="cover abs anim"></div>}
      </div>
      {/* Gallery */}
      <div
        className={`chatbox-gallery flex flex-col sticky anim ${
          gallery && 'show'
        }`}
      >
        <div className="hdr flex aic">
          <div className="lef flex aic">
            <button
              className="cleanbtn ico icon-close s20 c555"
              onClick={() => setGallery(!gallery)}
            />
            <div className="font s22 b black">Gallery</div>
          </div>
        </div>
        <div className="wrap-data flex flex-col">
          <div className="connect flex flex-col aic">
            <div className="frd-pic rel">
              <div
                className="img"
                style={{
                  backgroundImage: 'url(https://placeimg.com/350/350/people?5)',
                }}
              />
              <div className="my-pic abs">
                <div
                  className="img"
                  style={{
                    backgroundImage:
                      'url(https://placeimg.com/350/350/people?12)',
                  }}
                />
              </div>
            </div>
            <div className="names font s14 b6 c777">Jhon & Denny</div>
          </div>
          <div className="shared-media flex flex-col">
            <div className="font s14 b6 c555">Shared Media</div>
            <div className="links flex aic">
              {nav.map((item) => (
                <Link to={item.slug} className="lin font s13 c333 rel">
                  {item.label}
                </Link>
              ))}
            </div>
            <div className="shared-result">
              {media.map((item) => (
                <div className="item rel anim">
                  <div
                    className="img anim"
                    style={{ backgroundImage: `url(${item.media})` }}
                  />
                </div>
              ))}
            </div>
            {/* Related Communitys */}
            <div className="related-community flex flex-col">
              <div className="lbl font s14 b6 c555">Related Communities</div>
              <div className="community-result flex">
                {community.map((item) => (
                  <div className="item rel anim">
                    <div
                      className="img anim"
                      style={{ backgroundImage: `url(${item.media})` }}
                    />
                  </div>
                ))}
                <div className="item rel anim flex aic">
                  <div className="font s15 b6 cfff">20+</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatBox;
