import Rating from '@material-ui/lab/Rating';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-collapse';
import { AiOutlineClose, AiOutlinePause } from 'react-icons/ai';
import { FaRegPlayCircle } from 'react-icons/fa';
import { FiPlay } from 'react-icons/fi';
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import firebase from '../../utils/base';

function ChampionDialog({
  customId = '',
  champions,
  badges,
  customCommunityName,
  championHeaderTitleP = '',
  championHeaderSubtitleP = '',
}) {
  const { id } = useParams();
  const [communityId, setCommunityid] = useState(customId || id || '');

  console.log('useParams', id);

  const [openFilter, setOpenFilter] = useState('');
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [appliedFilter, setAppliedFilter] = useState(false);
  const [settings, setSettings] = useState(null);
  const [filtersList, setFiltersList] = useState([]);
  const [usersList, setUsersList] = useState(champions || []);

  const [searchResult, setSearchResult] = useState(usersList);
  const [agentDetail, setAgentDetail] = useState('');
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [step, setStep] = useState(1);
  const [communityName, setCommunityName] = useState(customCommunityName || '');
  const [championHeaderTitle, setChampionHeaderTitle] = useState('');
  const [championHeaderSubtitle, setChampionHeaderSubtitle] = useState('');
  const [verificationDetail, setVerificationDetail] = useState(null);
  const [community, setCommunity] = useState(null);

  const [emailCodeVal, setEmailCodeVal] = useState('');
  const [phoneCodeVal, setPhoneCodeVal] = useState('');

  const [currentReferral, setCurrentReferral] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loginConnect, setLoginConnect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [connectData, setConnectData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
  });
  const [loginConnectData, setLoginConnectData] = useState({
    emailLogin: '',
    passwordLogin: '',
  });

  const handleChangeConnectData = (e) => {
    setConnectData({ ...connectData, [e.target.name]: e.target.value });
  };
  const handleChangeLoginConnectData = (e) => {
    setLoginConnectData({
      ...loginConnectData,
      [e.target.name]: e.target.value,
    });
  };

  function parseJsonPayloadHorizontally(dataArr = null) {
    return dataArr.map((data, i) => {
      let tempObj = {};
      Object.keys(data).map((k, idx) => {
        console.log('response jsonp: ', k, data[k]);
        try {
          let t1 = JSON.parse(data[k]);
          tempObj[k] = t1;
          return t1;
        } catch (e) {
          console.log(e);
        }
        tempObj[k] = data[k];
        return data[k];
      });
      console.log('response jsonfull: ', tempObj);
      return tempObj || {};
    });
  }
  const [credits, setCredits] = useState(null);

  useEffect(() => {
    if (champions) {
      setUsersList(champions);
      setSelectedFilter([]);
    }
  }, [champions]);

  console.log('championHeaderTitle', {
    championHeaderSubtitleP,
    championHeaderTitleP,
  });

  useEffect(() => {
    championHeaderTitleP && setChampionHeaderTitle(championHeaderTitleP);
    championHeaderSubtitleP &&
      setChampionHeaderSubtitle(championHeaderSubtitleP);
  }, [championHeaderSubtitleP, championHeaderTitleP]);

  useEffect(() => {
    localStorage.getItem('credits') &&
      setCredits(localStorage.getItem('credits'));

    let time = localStorage.getItem('time');
    console.log('hours', time);
    if (time) {
      let today = Date.now();
      let localStorageTime = new Date(Number(time)).getTime();
      console.log('hours localStorageTime', { localStorageTime, today });
      console.log('today >  localStorageTime', today > localStorageTime);
      if (today > localStorageTime) {
        let timeMiliSeconds = today - localStorageTime;

        // 24*60*60*1000 = 24 hours
        if (timeMiliSeconds > 24 * 60 * 60 * 1000) {
          localStorage.setItem('time', Date.now());
          localStorage.setItem('credits', 5);
          toastNotification('🎉 Congrats you received 5 credits now');
        }
      }
    }
  }, []);

  useEffect(() => {
    if (badges) {
      setFiltersList(
        badges.map((item) => {
          return {
            title: item.type,
            list: item.names.map((item) => {
              return { label: item, value: item.toLowerCase() };
            }),
          };
        })
      );
    }
  }, [badges]);

  console.log('filtersList', filtersList);

  const toastNotification = (message, type) => {
    toast(message, {
      position: 'top-right',
      type: type,
      autoClose: 5000,
    });
  };
  useEffect(() => {
    localStorage.getItem('letsConnect') &&
      setCurrentUser(JSON.parse(localStorage.getItem('letsConnect')));
  }, []);

  const getcommunity = async () => {
    const res = await axios.get(
      `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${communityId}`
    );
    console.log('response', res, res.data.payload);
    let temp = parseJsonPayloadHorizontally(res.data.payload);
    console.log('response2', temp);
    if (temp?.settings?.goasl) {
      // setgoals({ ...temp.settings.goals });
    }
    if (res.data.payload.length > 0) {
      // setShowStepForm(false);

      try {
        console.log(
          'thejson commbadges: ',
          temp?.[0]?.communitybadges?.communitybadges || []
        );
        let thejson = {
          ...temp[0],
          champions: temp?.[0]?.champions?.champions || [],
          communitybadges: temp?.[0]?.communitybadges?.communitybadges || [],
          reviews: temp?.[0]?.reviews?.reviews || [],
          settings: temp?.[0]?.settings || {},
          team: temp?.[0]?.team?.team || [],
        };
        //console.log('response',JSON.parse(res.data?.payload[0].champions))
        console.log('thejson champions', thejson);
        setUsersList(thejson?.champions);
        setCommunityName(thejson?.name);
        setChampionHeaderTitle(thejson?.settings?.championHeaderTitle);
        setChampionHeaderSubtitle(thejson?.settings?.championHeaderSubtitle);
        let communitybadges = thejson?.settings?.championssettings
          ? Object.keys(thejson?.settings?.championssettings).map((key) => {
              return {
                type: key,
                names: thejson?.settings?.championssettings[key].map(
                  (item) => item.subcategory
                ),
              };
            })
          : [];

        setFiltersList(
          communitybadges.map((item) => {
            return {
              title: item.type,
              list: item.names.map((item) => {
                return { label: item, value: item.toLowerCase() };
              }),
            };
          })
        );
        // setDirectualCommunity({ ...thejson });
        // console.log('after', directualCommunity);
        // setcreatecommunityenable(true);
        // setloading(false);
      } catch (error) {
        console.log('thejson errr', error);
      }
    } else {
      // setShowStepForm(true);
      console.log('thejson response_empty');
    }
  };

  // const getcommunity = async (community_id) => {
  //   const res = await axios.get(
  //     `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
  //   );
  //   console.log('response', res, res.data.payload);
  //   let temp = parseJsonPayloadHorizontally(res.data.payload);
  //   console.log('response2', temp);
  //   if (res.data.payload.length > 0) {
  //     try {
  //       let thejson = {
  //         ...temp[0],
  //         champions: temp?.[0]?.champions?.champions || [],
  //         communitybadges: temp?.[0]?.communitybadges?.communitybadges || [],
  //         reviews: temp?.[0]?.reviews?.reviews || [],
  //         settings: temp?.[0]?.settings || {},
  //         team: temp?.[0]?.team?.team || [],
  //       };
  //       //console.log('response',JSON.parse(res.data?.payload[0].champions))
  //       console.log('thejson', thejson);
  //       setDirectualCommunity({ ...thejson });
  //       console.log('after', directualCommunity);
  //       setcreatecommunityenable(true);
  //       setloading(false);
  //     } catch (error) {
  //       console.log('thejson errr', error);
  //     }
  //   } else {
  //     console.log('thejson response_empty');
  //   }
  // };
  // useEffect(() => {
  //   getcommunity();
  // }, []);

  useEffect(() => {
    if (communityId) {
      getcommunity();
    }
  }, []);

  useEffect(() => {
    if (usersList) {
      console.log('userList', usersList);
      setSearchResult(usersList);
    }
  }, [usersList]);

  const handleApplyFilters = () => {
    setAppliedFilter(true);

    console.log('run the handleApplyFilters function here');
    let newResults = [];
    selectedFilter.map((filter) => {
      console.log('filter', filter);
      champions.map((user) => {
        console.log('user', user);
        let result = user?.badges?.some(
          (value) => value.name.toLowerCase() === filter.value.toLowerCase()
        );
        if (result) {
          newResults.push(user);
        }
      });
    });

    // const ids = newResults.map((user) => user.id);
    // const removedDuplicateItems = newResults.filter(
    //   ({ id }, index) => !ids.includes(id, index + 1)
    // );
    console.log('findthis', newResults);
    setSearchResult(newResults);
    setOpenFilter('');
  };

  const handleCloseAppliedFilters = () => {
    console.log('run the handleCloseAppliedFilters function here');
    setAppliedFilter(false);
    setSearchResult(usersList);
    setSelectedFilter([]);
    setOpenFilter('');
  };

  const handleCloseLoginDialog = () => {
    setOpenLoginDialog(false);
    setCurrentReferral(null);
  };

  const createNewUser = async () => {
    let postData = {
      email: connectData.email,
      name: connectData.name,
      phone: connectData.phone,
      password: connectData.password,
      community: communityId, // communityId
    };

    if (!validation(postData)) {
      return;
    }
    registerInFirebase(postData);
  };

  const loginConnectUser = async () => {
    console.log('login');
    let postData = {
      email: loginConnectData?.emailLogin,
      password: loginConnectData?.passwordLogin,
    };

    if (!validation(postData, true)) {
      return;
    }
    loginWithFirebase(postData);
  };

  const registerInFirebase = async (data) => {
    await firebase.letsConnectNewUser(
      data,
      toastNotification,
      handleCloseLoginDialog,
      setCurrentUser,
      setLoading,
      setCredits
    );
  };
  const loginWithFirebase = async (data) => {
    await firebase.letsConnectLogin(
      data,
      toastNotification,
      handleCloseLoginDialog,
      setCurrentUser,
      setLoading,
      setCredits
    );
  };

  const validation = (data, login) => {
    if (!data.name && !login) {
      toast('Name is required', {
        position: 'top-right',
        type: 'error',
        autoClose: 5000,
      });
      return false;
    }
    if (!data.email) {
      toast('Email is required', {
        position: 'top-right',
        type: 'error',
        autoClose: 5000,
      });
      return false;
    }
    if (!data.phone && !login) {
      toast('Phone is required', {
        position: 'top-right',
        type: 'error',
        autoClose: 5000,
      });
      return false;
    }
    if (!data.password) {
      toast('Password is required', {
        position: 'top-right',
        type: 'error',
        autoClose: 5000,
      });
      return false;
    }
    return true;
  };

  // const handleVerification = async () => {
  //   let postData = {
  //     email: connectData.email,
  //     name: connectData.name,
  //     phone: connectData.phone,
  //     community: id, // communityId
  //   };
  //
  //   const doc = await firebase.emailPhoneVerify(postData.email, postData.phone);
  //   if (doc.exists) {
  //     console.log('user exist', doc.data());
  //     setVerificationDetail(doc.data());
  //     if (doc.data().emailVerify === true && doc.data().phoneVerify === true) {
  //       const userExistRes = await axios.get(
  //         `https://api.directual.com/good/api/v5/data/users/findUserbyEmailOrPhone?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=asdf&email=${postData.email}&phone=${postData.phone}`
  //       );
  //       const user = userExistRes?.data?.payload[0];
  //       setCurrentUser(user);
  //       if (user) {
  //         setOpenLoginDialog(false);
  //         toast('🥳You are connected, Now you can send the messages :)', {
  //           position: 'top-right',
  //           type: 'success',
  //           autoClose: 5000,
  //         });
  //         setOpenLoginDialog(false);
  //         return;
  //       } else {
  //         const userRes = await axios.post(
  //           `https://api.directual.com/good/api/v5/data/users/newUser?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83`,
  //           postData
  //         );
  //         console.log('userUpdated after review TYG: ', userRes?.data?.result);
  //         setCurrentUser(userRes?.data?.result);
  //         toast('🥳You are connected, Now you can send the messages :)', {
  //           position: 'top-right',
  //           type: 'success',
  //           autoClose: 5000,
  //         });
  //         setOpenLoginDialog(false);
  //         return;
  //       }
  //     }
  //     if (typeof doc.data().emailVerify === 'string') {
  //       setStep(2);
  //
  //       return;
  //     }
  //     if (typeof doc.data().phoneVerify === 'string') {
  //       setStep(3);
  //       return;
  //     }
  //   } else {
  //     toast(
  //       'This user is not register in our temporary verification process. we are working on that. Please enter the registered email and phone for testing purpose',
  //       {
  //         position: 'top-right',
  //         type: 'error',
  //         autoClose: 5000,
  //       }
  //     );
  //   }
  // };
  // console.log('verificationDetail', verificationDetail);
  //
  // const handleVerificationCode = async () => {
  //   if (step === 2) {
  //     if (emailCodeVal === verificationDetail?.emailVerify) {
  //       firebase
  //         .verify(connectData.email, connectData?.phone, 'emailVerify')
  //         .then(() => {
  //           setStep(3);
  //         })
  //         .catch((error) => console.log(error.message));
  //     } else {
  //       toast('Please enter the correct code', {
  //         position: 'top-right',
  //         type: 'error',
  //         autoClose: 5000,
  //       });
  //     }
  //   }
  //   if (step === 3) {
  //     if (phoneCodeVal === verificationDetail?.phoneVerify) {
  //       firebase
  //         .verify(connectData?.email, connectData?.phone, 'phoneVerify')
  //         .then(() => handleVerification())
  //         .catch((error) => console.log(error.message));
  //     } else {
  //       toast('Please enter the correct code', {
  //         position: 'top-right',
  //         type: 'error',
  //         autoClose: 5000,
  //       });
  //     }
  //   }
  // };

  const [hideMessages, setHideMessages] = useState(true);
  const customuser = {
    name: 'sohan',
    bio: '          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi ipsam labore ex qui autem illo, commodi ea atque reiciendis repellat?',
    badges: [
      {
        name: 'friendly',
      },
      {
        name: 'parent',
      },
    ],
    reviewText:
      'this is a awesome review This is made by sohan khan also it will splice a little bit',
    video:
      'http://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/31/magnet/0d8aa956-2d8d-4254-b018-15c1672c5d9a/intro_Main_Intro_2/Simple_tour_video_ad__masked_shirt_2_mp4_1.mp4',
    pic: '',
    rating: 4,
    selectedBadge: [
      {
        name: 'Helpfull team',
      },
      {
        name: 'Best america',
      },
      {
        name: 'My team',
      },
      {
        name: 'Best team',
      },
      {
        name: 'New glass',
      },
      {
        name: 'of water',
      },
    ],
  };

  const vidRef = useRef(null);
  const isplaying = useRef(false);
  const handlePlayVideo = () => {
    console.log(vidRef);
    if (isplaying.current == false) {
      //isplaying.current = true
      let playPromise = vidRef.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
            isplaying.current = true;
            vidRef.current.play();
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
            console.log(error);
          });
      }
    }
  };

  const handlePauseVideo = () => {
    if (isplaying.current == true) {
      isplaying.current = false;
      vidRef.current.pause();
    }
  };
  const [play, setPlay] = useState({
    status: false,
    ref: false,
  });
  return (
    <>
      <div className="referral-dialog ">
        <div id="recaptcha-container"></div>
        <div className="referrals-container flex flex-col min-h-screen overflow-auto">
          <Collapse isOpened={agentDetail}>
            {agentDetail && (
              <div className=" w-full  flex items-center overflow-y-scroll h-screen justify-center">
                <div className="agent-detail mt-20" style={{ width: '450px' }}>
                  <div className=" w-full flex items-center justify-end">
                    <div
                      className="back-btn flex items-center justify-center border px-2 py-1 cursor-pointer rounded-full"
                      onClick={() => setAgentDetail('')}
                    >
                      <AiOutlineClose className=" w-5 h-5" />
                    </div>
                  </div>
                  <div className="user flex aic">
                    <div className=" relative">
                      <img
                        src={
                          agentDetail?.profile
                            ? agentDetail?.profile
                            : `https://ui-avatars.com/api/?name=${encodeURI(
                                agentDetail?.name || ''
                              )}`
                        }
                        className=" w-16 h-16 rounded-full"
                        style={{ objectFit: 'cover' }}
                        alt=""
                      />
                      {agentDetail?.reviewVideo && (
                        <div className=" absolute -bottom-1 -right-1 z-10">
                          <FaRegPlayCircle className=" w-6 h-6" />
                        </div>
                      )}
                    </div>
                    <div className="meta flex flex-col">
                      <div className="name">{agentDetail.name}</div>
                      <div className="values">
                        {agentDetail?.badges?.map((value, idx) => (
                          <div key={idx} className="txt">
                            <span className="dot">•</span>
                            <span>{value.name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <>
                    {/* {hideMessages ? (

                ) : (

                )} */}

                    <>
                      <div className=" my-2 w-full">
                        {agentDetail?.bio && (
                          <div className=" text-xs py-1">
                            <span className=" text-sm font-semibold">
                              Bio:{' '}
                            </span>
                            {agentDetail?.bio}
                          </div>
                        )}
                        {agentDetail?.reviewText && (
                          <h1 className=" text-xl py-2 flex items-start gap-2">
                            <RiDoubleQuotesL className=" w-10 h-10 " />{' '}
                            {agentDetail.reviewText}
                            <RiDoubleQuotesR className=" w-10 h-10 " />
                          </h1>
                        )}

                        {agentDetail?.reviewRating && (
                          <Rating
                            className=" py-1"
                            name="rating-component"
                            defaultValue={agentDetail?.reviewRating}
                            precision={0.5}
                            readOnly
                            size="medium"
                          />
                        )}
                        <div className="about font pt-2">
                          <h6 className=" font-semibold text-lg">
                            {' '}
                            Connect with {agentDetail?.name}
                          </h6>
                        </div>
                        <div className="form flex flex-col">
                          <div className="field-row">
                            <div className="field">
                              <input
                                type="text"
                                placeholder="Name"
                                className="iput font"
                              />
                            </div>
                          </div>
                          <div className="field-row">
                            <div className="field">
                              <input
                                type="text"
                                placeholder="Email"
                                className="iput font"
                              />
                            </div>
                            <div className="field">
                              <input
                                type="text"
                                placeholder="Phone"
                                className="iput font"
                              />
                            </div>
                          </div>
                          <div className="field-row">
                            <div className="field">
                              <textarea
                                type="text"
                                placeholder={`Write your message to ${agentDetail.name}`}
                                className="iput font"
                              />
                            </div>
                          </div>
                          <div className="field-row">
                            <div className="field">
                              <input
                                type="text"
                                placeholder="move in date"
                                className="iput area font"
                              />
                            </div>
                          </div>
                          <div className="field-row">
                            <div className="field">
                              <button className="cleanbtn connect-btn font">
                                Lets's Connect
                              </button>
                            </div>
                          </div>
                        </div>
                        {agentDetail?.reviewVideo && (
                          <div className=" w-full relative py-3 rounded-md overflow-hidden">
                            <video
                              ref={vidRef}
                              className="item-video"
                              style={{ height: '100%' }}
                              loop
                            >
                              <source
                                src={agentDetail?.reviewVideo}
                                type="video/mp4"
                              />
                              Your browser does not support HTML5 Video.
                            </video>
                            <div className=" absolute bottom-6 left-3 z-20">
                              {play.status === true && play.ref === true ? (
                                <AiOutlinePause
                                  onClick={() => {
                                    handlePauseVideo();
                                    setPlay({
                                      status: false,
                                      ref: false,
                                    });
                                  }}
                                  className=" w-7 h-7 text-white cursor-pointer"
                                />
                              ) : (
                                <FiPlay
                                  onClick={() => {
                                    handlePlayVideo();
                                    setPlay({
                                      status: true,
                                      ref: true,
                                    });
                                  }}
                                  className=" w-7 h-7 text-white cursor-pointer"
                                />
                              )}
                            </div>
                          </div>
                        )}

                        <div className=" flex items-center gap-2 flex-wrap">
                          {agentDetail?.reviewBadges && (
                            <>
                              <>
                                {agentDetail?.reviewBadges.map((item, ind) => (
                                  <button
                                    key={ind}
                                    className=" px-2 py-2 rounded-sm bg-black text-xs text-white"
                                  >
                                    {item.name}
                                  </button>
                                ))}
                              </>
                              <></>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  </>
                </div>
              </div>
            )}
          </Collapse>
          <Collapse isOpened={!agentDetail}>
            <>
              <div className="hdr">
                <div className="title font flex justify-between">
                  <div>{championHeaderTitle}</div>
                  {credits && <div>Credits : {credits}</div>}
                </div>
                <div className="txt font">{championHeaderSubtitle}</div>
              </div>
              <div className="latitude-blk">
                <div className="label font"> Community at {communityName}</div>
                <div className="tabs-bar rel">
                  {filtersList.length > 0 &&
                    filtersList.map((filter, index) => (
                      <div key={index}>
                        {filter?.title && (
                          <div
                            className={`tab ${
                              selectedFilter.find((x) => {
                                return filter.list.find(
                                  (itm) => itm.label == x.label
                                );
                              }) && appliedFilter
                                ? 'active'
                                : ''
                            }`}
                            onClick={() => {
                              if (filter.title === openFilter?.title) {
                                setOpenFilter('');
                              } else {
                                setOpenFilter(filter);
                              }
                            }}
                          >
                            <div className="lbl font">{filter.title}</div>
                            <div
                              className={`ico ${
                                filter.title === openFilter?.title
                                  ? 'icon-arrow_drop_up'
                                  : 'icon-arrow_drop_down'
                              }`}
                            />
                          </div>
                        )}

                        {openFilter && openFilter?.list?.length > 0 && (
                          <div className="filters-block">
                            <div className="filters-wrap">
                              {openFilter.list.map((filter, idx) => (
                                <div
                                  key={idx}
                                  className="filter"
                                  onClick={() => {
                                    let filterFound = selectedFilter.find(
                                      (x) => x.label == filter.label
                                    );
                                    if (filterFound) {
                                      let currentSelectedFilter =
                                        selectedFilter.filter(
                                          (x) => x.label !== filter.label
                                        );
                                      setSelectedFilter([
                                        ...currentSelectedFilter,
                                      ]);
                                    } else {
                                      setSelectedFilter([
                                        ...selectedFilter,
                                        filter,
                                      ]);
                                    }
                                  }}
                                >
                                  <div
                                    className={`checkbox ${
                                      selectedFilter.find(
                                        (x) => x.label == filter.label
                                      )
                                        ? 'active'
                                        : ''
                                    }`}
                                  />
                                  <div className="lbl">{filter.label}</div>
                                </div>
                              ))}
                            </div>
                            <div className="filter-actions">
                              <button
                                className="action-btn font"
                                onClick={() => setOpenFilter('')}
                              >
                                Cancel
                              </button>
                              <button
                                className="action-btn filled font"
                                onClick={handleApplyFilters}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  {appliedFilter && (
                    <div
                      className="cross-btn font"
                      onClick={handleCloseAppliedFilters}
                    >
                      &times;
                    </div>
                  )}
                </div>

                <div className=" w-full overflow-y-scoll h-screen">
                  <div className="users flex items-start justify-center pt-6  gap-1 flex-wrap ">
                    {searchResult.length ? (
                      <>
                        {searchResult.map((item, index) => (
                          <UserBlock
                            setOpenLoginDialog={setOpenLoginDialog}
                            key={index}
                            secondUser={item}
                            // secondUser={customuser}
                            firstUser={currentUser}
                            community={community}
                            setAgentDetail={setAgentDetail}
                            toastNotification={toastNotification}
                            setCredits={setCredits}
                            hideMessages={hideMessages}
                          />
                        ))}
                      </>
                    ) : (
                      <div className="empty-user-list">
                        <div className="txt font">Result Not Found.</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          </Collapse>
        </div>
      </div>
      <AnimatedDialog
        open={openLoginDialog}
        onClose={handleCloseLoginDialog}
        fullWidth
        maxWidth={'xs'}
      >
        <div className="referral-login-dialog">
          <div className="hdr">
            <div className="title font">
              {loginConnect ? 'Connect Again' : 'Connect'}
            </div>
            <div className="cross-btn font" onClick={handleCloseLoginDialog}>
              &times;
            </div>
          </div>
          {step === 1 && (
            <div className="content">
              {loginConnect ? (
                <div>
                  <div className="field">
                    <div className="lbl font">Email</div>
                    <input
                      name="emailLogin"
                      onChange={(e) => handleChangeLoginConnectData(e)}
                      type="text"
                      className="iput font"
                      placeholder="Enter your Email"
                      value={connectData.emailLogin}
                    />
                  </div>
                  <div className="field">
                    <div className="lbl font">Password</div>
                    <input
                      name="passwordLogin"
                      onChange={(e) => handleChangeLoginConnectData(e)}
                      type="password"
                      className="iput font"
                      placeholder="Enter your Password"
                      value={connectData.passwordLogin}
                    />
                  </div>
                  <div>
                    <p
                      onClick={() => setLoginConnect(false)}
                      className="no-underline hover:underline cursor-pointer"
                    >
                      Make a new account
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="field">
                    <div className="lbl font">Name</div>
                    <input
                      name="name"
                      onChange={(e) => handleChangeConnectData(e)}
                      type="text"
                      className="iput font"
                      placeholder="Enter your name"
                      value={connectData.name}
                    />
                  </div>
                  <div className="field">
                    <div className="lbl font">Email</div>
                    <input
                      name="email"
                      onChange={(e) => handleChangeConnectData(e)}
                      type="text"
                      className="iput font"
                      placeholder="Enter your Email"
                      value={connectData.email}
                    />
                  </div>
                  <div className="field">
                    <div className="lbl font">Phone Number</div>
                    <input
                      name="phone"
                      onChange={(e) => handleChangeConnectData(e)}
                      type="text"
                      className="iput font"
                      placeholder="Enter your Phone Number"
                      value={connectData.phone}
                    />
                  </div>
                  <div className="field">
                    <div className="lbl font">Password</div>
                    <input
                      name="password"
                      onChange={(e) => handleChangeConnectData(e)}
                      type="password"
                      className="iput font"
                      placeholder="Enter your Password"
                      value={connectData.password}
                    />
                  </div>
                  <div>
                    <p
                      onClick={() => setLoginConnect(true)}
                      className="no-underline hover:underline cursor-pointer"
                    >
                      Already have an account
                    </p>
                  </div>
                </div>
              )}

              <div className="actions flex aic">
                <button
                  className="action transparent font"
                  onClick={handleCloseLoginDialog}
                >
                  Cancel
                </button>
                <button
                  className="action font"
                  onClick={() => {
                    loginConnect ? loginConnectUser() : createNewUser();
                  }}
                >
                  {loading ? 'Loading' : 'Lets Connect'}
                </button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="content">
              <div className="field">
                <div className="lbl font">Verify your Email</div>
                <input
                  onChange={(e) => setEmailCodeVal(e.target.value)}
                  value={emailCodeVal}
                  type="text"
                  className="iput font"
                  placeholder="Enter the code that sent to your Email"
                />
              </div>

              <div className="actions flex aic">
                <button
                  className="action transparent font"
                  onClick={handleCloseLoginDialog}
                >
                  Cancel
                </button>
                <button
                  className="action font"
                  onClick={handleVerificationCode}
                >
                  Verify Email
                </button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="content">
              <div className="field">
                <div className="lbl font">Verify your Phone</div>
                <input
                  name="email"
                  onChange={(e) => setPhoneCodeVal(e.target.value)}
                  value={phoneCodeVal}
                  type="text"
                  className="iput font"
                  placeholder="Enter the code that sent to your Phone"
                />
              </div>

              <div className="actions flex aic">
                <button
                  className="action transparent font"
                  onClick={handleCloseLoginDialog}
                >
                  Cancel
                </button>
                <button
                  className="action font"
                  onClick={handleVerificationCode}
                >
                  Verify Phone
                </button>
              </div>
            </div>
          )}
        </div>
      </AnimatedDialog>
    </>
  );
}

export default ChampionDialog;
const UserBlock = ({
  secondUser,
  setOpenLoginDialog,
  firstUser,
  community,
  setAgentDetail,
  toastNotification,
  setCredits,
  hideMessages,
}) => {
  const [message, setMessage] = useState('');

  console.log('secondUser', secondUser);

  const sendMessagesHandler = async () => {
    if (!firstUser) {
      setOpenLoginDialog(true);
    } else {
      if (!secondUser?.email) {
        toastNotification('Email is not found', 'error');
        return;
      }
      // let postData = {
      //   userOneId: firstUser?.id,
      //   userTwoId: secondUser?.id,
      //   message: message,
      //   community,
      //   requesterMessage: firstUser?.name,
      // };
      if (Number(localStorage.getItem('credits') < 1)) {
        toastNotification(
          "You don't have enough credits for messages. Please wait for 24 hours, after that it will automatically be added by 5",
          'error'
        );
        return;
      }
      var data = {
        message: {
          to: {
            email: secondUser?.email,
          },
          data: {
            recipientName: secondUser.name,
            referralRequestorEmail: firstUser.email,
            referralRequestorName: firstUser.name,
            referralRequestorMessage: message,
            communityName: community?.name,
            currentReferralSpecial: 'ghghf',
          },
          template: 'VMYH3GN47H4KEEGMA813PQ1DJQ2E',
        },
      };

      var config = {
        method: 'post',
        url: 'https://api.leasemagnets.com/send_courier',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);

          toastNotification('Email sent successfully :)', 'success');
          if (localStorage.getItem('credits')) {
            let credits = parseInt(localStorage.getItem('credits'));
            localStorage.setItem('credits', `${--credits}`);
            setCredits(localStorage.getItem('credits'));
          }
        })
        .catch(function (error) {
          console.log(error.message);

          toastNotification('An error occurred :)', 'error');
        });
    }
  };

  return (
    // col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1
    <div className="user-blk " style={{ width: '450px' }}>
      <div className="user flex aic">
        <div
          className=" relative cursor-pointer"
          onClick={() => setAgentDetail(secondUser)}
        >
          <img
            src={
              secondUser?.profile
                ? secondUser?.profile
                : `https://ui-avatars.com/api/?name=${encodeURI(
                    secondUser?.name || ''
                  )}`
            }
            className=" w-16 h-16 rounded-full"
            style={{ objectFit: 'cover' }}
            alt=""
          />
          {secondUser?.reviewVideo && (
            <div className=" absolute -bottom-1 -right-1 z-10">
              <FaRegPlayCircle className=" w-6 h-6" />
            </div>
          )}
        </div>
        <div
          className="meta flex flex-col cursor-pointer"
          onClick={() => setAgentDetail(secondUser)}
        >
          <div className="name">{secondUser?.name}</div>
          <div
            className="values cursor-pointer"
            onClick={() => setAgentDetail(secondUser)}
          >
            {secondUser?.badges &&
              secondUser?.badges.map((value, idx) => (
                <div key={idx} className="txt  name">
                  <span className="dot">•</span>
                  <span>{value.name}</span>
                </div>
              ))}
            {secondUser?.bio && (
              <div className="flex items-center txt pl-1 hover:border-b name">
                <span className="dot">•</span>"{secondUser?.bio.slice(0, 20)}
                ..."
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {hideMessages === false ? (
      
      ) : (
      
      )} */}
      <div className="field-row">
        <div className="field">
          <textarea
            type="text"
            placeholder={`Write your message to ${secondUser?.name}.`}
            className="iput area font"
            defaultValue={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <button
          disabled={!message}
          className="cleanbtn send-btn font"
          onClick={() => sendMessagesHandler(secondUser)}
        >
          Send
        </button>
      </div>
      <div
        className=" my-2 w-full cursor-pointer"
        onClick={() => setAgentDetail(secondUser)}
      >
        {secondUser?.reviewText && (
          <h1 className=" text-xl name">
            {secondUser.reviewText.slice(0, 40)}...
          </h1>
        )}

        {secondUser?.reviewRating && (
          <Rating
            name="rating-component"
            onClick={() => setAgentDetail(secondUser)}
            className=" name cursor-pointer"
            defaultValue={secondUser?.reviewRating}
            precision={0.5}
            readOnly
            size="medium"
          />
        )}
        <div
          className=" flex items-center gap-2 cursor-pointer"
          onClick={() => setAgentDetail(secondUser)}
        >
          {secondUser?.reviewBadges && (
            <>
              <>
                {secondUser?.reviewBadges.slice(0, 2).map((item, ind) => (
                  <button
                    key={ind}
                    className=" px-2 py-2 name rounded-sm bg-black text-xs text-white"
                  >
                    {item.name}
                  </button>
                ))}
              </>
              <>
                {secondUser?.reviewBadges &&
                  secondUser.reviewBadges.length > 2 && (
                    <h6
                      className=" text-lg name cursor-pointer"
                      onClick={() => setAgentDetail(secondUser)}
                    >
                      {' '}
                      +{secondUser.reviewBadges.length - 2}
                    </h6>
                  )}
              </>
            </>
          )}
        </div>
      </div>
      {message && (
        <div className="field-row">
          <div className="field">
            <div className="lbl font">Expected Move in Date*</div>
            <input type="date" placeholder="Name" className="iput font" />
          </div>
        </div>
      )}
    </div>
  );
};
