import MaterialTable from '@material-table/core';
import { Button, Grid } from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import moment from 'moment';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import ContentLoader from '../../components/ContentLoader';
import notification from '../../components/Notification';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import firebase from '../../utils/base';
import { useRequest } from '../../utils/request';

const tableIcons = {
  Add: forwardRef((props, ref) => (
    <AddBox {...props} ref={ref} keyboard-shortcut="add-icon-handler" />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const addLeadToLeased = async (form_id, leadInfo) => {
  // Firebase cheatsheet https://medium.com/@aaron_lu1/firebase-cloud-firestore-add-set-update-delete-get-data-6da566513b1b
  // check if leads field is live
  if (!leadInfo.email) {
    return false;
  }

  try {
    const leadsRef = firebase.db
      .collection('videoembed')
      .doc(form_id)
      .collection('leads');
    const doc = await firebase.db
      .collection('videoembed')
      .doc(form_id)
      .collection('leads')
      .doc(leadInfo.email)
      .get();

    if (doc.exists) {
      console.log('leads field exists');
      // https://docs.microsoft.com/en-us/dotnet/api/system.datetime.tostring?view=netcore-3.1
      leadsRef.doc(leadInfo.email).set({
        name: leadInfo.name,
        email: leadInfo.email,
        phone_number: leadInfo.phone_number,
        leased: true,
        answers: leadInfo.answers,
        created_at: leadInfo.created_at,
        updated_at: Date().toString('R'),
      });
    } else {
      console.log('leads field does not exists');
      leadsRef.doc(leadInfo.email).set({
        name: leadInfo.name,
        email: leadInfo.email,
        phone_number: leadInfo.phone_number,
        leased: true,
        answers: leadInfo.answers,
        created_at: leadInfo.created_at,
        updated_at: Date().toString('R'),
      });
    }
    return true;
  } catch (err) {
    console.log('Failure getting leads document, check firebase key :', err);
    return false;
  }

  // try {
  //     const doc = await firebase.db.collection('videoembed').doc(form_id).collection('leads').add(
  //         leadInfo
  //     ).then(function () {
  //         console.log('Leads Added firebaseLeadsStuff');
  //     })
  //         .catch(function (error) {
  //             console.error('Error adding lead firebaseLeadsStuff: ', error);
  //         });

  // } catch (err) {
  //     console.log('Failure getting document, check firebase key :', err);
  // }
};

function getSummaryStats(leads) {
  // Params moment timestamp calculate the number minutes of the day //https://github.com/moment/moment/issues/1199#issuecomment-26682404
  const minutesOfDay = function (m) {
    return m.minutes() + m.hours() * 60;
  };

  // Params: Moment timestamp,
  function isInBusinessHours(timestamp1) {
    const opening = minutesOfDay(moment('9:30am', 'h:mma'));
    const closing = minutesOfDay(moment('4:00pm', 'h:mma'));
    const sample = minutesOfDay(timestamp1);

    if (sample >= opening && sample <= closing) {
      return true;
    }
    return false;
  }

  let total_steps = 0;
  let during_business_hours = 0;
  var avg_tours_day = 0;
  const created_at_times = {};

  for (const i in leads.results) {
    const created_timestamp = moment(Date.parse(leads.results[i].created_at));
    total_steps += leads.results[i].answers.length;
    if (isInBusinessHours(created_timestamp)) {
      during_business_hours += 1;
    }

    created_at_times[created_timestamp.format('MMM Do YYYY')] =
      created_at_times[created_timestamp.format('MMM Do YYYY')] + 1 || 1;
  }

  const avg_steps = total_steps / leads.results.length;
  const outside_business_hours = leads.results.length - during_business_hours;

  const arr = Object.values(created_at_times);
  const sum = (accumulator, obj) => accumulator + obj;
  var avg_tours_day = arr.reduce(sum, 0) / arr.length;

  return [avg_steps, outside_business_hours, avg_tours_day];
}

const LeadActionPanel = (props) => {
  const { leadData, form_id } = props;
  console.log('props_leaddata', leadData);

  const [loading, error, data, makeRequest] = useRequest({ loading: false });

  const answers = leadData?.rowData?.answers.map((answer, idx) => {
    return answer?.poll_option_content;
  });

  const [NotificationBar, setNotification] = notification();

  return (
    <>
      <ContentLoader loading={loading} error={error}>
        <div>
          <br />
          <br />
          <Grid container spacing={6}>
            <Grid item xs={5}>
              <DebouncedTextField
                label="Name"
                value={leadData.name}
                disabled
                variant="outlined"
                fullWidth
              />{' '}
              <br />
              <br />
              <DebouncedTextField
                label="Email"
                value={leadData.email}
                disabled
                variant="outlined"
                fullWidth
              />{' '}
              <br />
              <br />
              <DebouncedTextField
                label="Phone"
                value={leadData.phone_number}
                disabled
                variant="outlined"
                fullWidth
              />{' '}
              <br />
              <br />
              <br />
            </Grid>

            <Grid item xs={1} />

            <Grid item xs={5}>
              Created on{' '}
              {moment(Date.parse(leadData?.rowData?.created_at)).format('LLL')}
              <br />
              <br />
              Interests include:{' '}
              {answers.map((item, idx) => (
                <span key={item + idx}>
                  {item}
                  {idx != answers.length - 1 && ','}
                </span>
              ))}
              <br />
              <br />
              <br />
              <Button
                onClick={() => {
                  console.log(leadData);
                  const resp = addLeadToLeased(form_id, {
                    ...leadData,
                    answers,
                  });
                  if (resp) {
                    setNotification('Added lead to lease list successfully');
                  }
                }}
                variant="outlined"
                size="large"
              >
                + Add Lead to Leased List
              </Button>
            </Grid>

            <NotificationBar />
          </Grid>
        </div>
      </ContentLoader>
    </>
  );
};

const Leads = (props) => {
  const {
    form,
    metrics,
    status = '',
    tableTitle = 'Leads',
    getContactsUrl = './leads.json',
    leadsPerPage = 10,
    form_id,
  } = props;
  //const { form_id } = useParams();

  const [loading, error, data, makeRequest] = useRequest({ loading: false });
  const {} = props;

  const [leads, setLeads] = useState({
    count: 9,
    next: null,
    previous:
      'https://api.leasemagnets.com/forms/4627c93e-462c-4b8c-9a14-66a314a7eb3f/contacts?include_answers=true&limit=100&status=completed',
    results: [
      {
        contact_id: 'b35d3e07-271a-4447-a20a-81dfc5015657',
        name: 'Terence patterson',
        email: 'terence_pttrsn23@yahoo.com',
        phone_number: '+16786809595',
        thumbnail: null,
        status: 'completed',
        created_at: '2020-10-23T16:17:29.319476Z',
        updated_at: '2020-10-23T16:18:59.390958Z',
        answers: [
          {
            answer_id: '35af592f-82fd-41ad-97cd-39d7201a937a',
            created_at: '2020-10-23T16:18:07.642969Z',
            is_public: false,
            poll_options: [
              {
                id: 'cde14111-6b20-4620-8d2a-bd4f24838418',
                content: ' 🍹 Explore Amenities',
              },
            ],
            question_id: '97e88002-8190-4b5f-809f-b6c5c4d7f2d7',
            share_id: 'a5iexe4pnw3jomc87i9kaxgc3jbxpf5xlha5rmcv',
            share_url:
              'https://www.leasemagnets.com/a5iexe4pnw3jomc87i9kaxgc3jbxpf5xlha5rmcv',
            type: 'poll',
            poll_option_id: 'cde14111-6b20-4620-8d2a-bd4f24838418',
            poll_option_content: ' 🍹 Explore Amenities',
          },
        ],
      },
    ],
  });

  async function getContacts(
    real_form_id,
    real_status = '',
    limit = '100',
    offset = '0'
  ) {
    if (real_form_id) {
      console.log('leads request using template id: ', real_form_id);
      const res = await makeRequest(
        `${'/getcontacts' + '?form_id='}${String(real_form_id)}&limit=${String(
          limit
        )}&offset=${String(offset)}&status=${String(real_status)}`,
        'GET',
        null,
        { auth: false }
      );
      setLeads(res);
      console.log('leads results: ', res);
      setSummaryStats(getSummaryStats(res));
    }
  }
  // let leads = require(`${getContactsUrl}`);

  // getSummaryStats(leads);

  const [summaryStats, setSummaryStats] = useState([0, 0, 0]);

  useEffect(() => {
    console.log('form _Id leads', form_id);
    getContacts(form_id, status);
  }, [form_id]);

  // Notification Bar
  const [NotificationBar, setNotification] = notification();

  const tableRef = useRef(null);

  // {{base_url}}/forms/{{form_id}}/contacts/{{contact_id}}?include_answers=true&include_messages=true
  // {{base_url}}/forms/{{form_id}}/contacts?include_answers=true&limit=101&offset=1
  return (
    <div className="leads-comp">
      <NotificationBar />

      <ContentLoader loading={loading} error={error}>
        <div className="states-blk leads-states flex flex-col">
          <div className="states-items flex aic flex-col lg:flex-row items-center">
            <div className="state-item flex aic">
              <div className="flex aic ico icon-crosshair s20" />
              <div className="flex flex-col">
                <div className="lbl font s16 b5 c333">{`${summaryStats[0].toFixed(
                  2
                )} Avg number of steps`}</div>
                <div className="txt font s13 c999"></div>
              </div>
            </div>

            <div className="state-item flex aic">
              <div className="flex aic ico icon-users s20" />
              <div className="flex flex-col">
                <div className="lbl font s16 b5 c333">{`${(
                  (summaryStats[1] / leads.results.length) *
                  100
                ).toFixed(1)}% Outside Business Hours`}</div>
                <div className="txt font s13 c999"></div>
              </div>
            </div>

            <div className="state-item flex aic">
              <div className="flex aic ico icon-shopping-cart s20" />
              <div className="flex flex-col">
                <div className="lbl font s16 b5 c333">{`${(
                  summaryStats[2] * 1.75
                ).toFixed(4)} Avg Number of tours per day`}</div>
                <div className="txt font s13 c999"></div>
              </div>
            </div>
          </div>
        </div>

        <MaterialTable
          // https://stackoverflow.com/questions/60009133/how-to-get-reactjs-material-table-search-results-count
          tableRef={tableRef}
          localization={{
            toolbar: { searchPlaceholder: 'Search w/Stats update: Agent' },
          }}
          // onSearchChange={() => {

          //     updateLeadStats(tableRef.current.state.data)
          // }}

          icons={tableIcons}
          options={{
            headerStyle: {
              backgroundColor: '#01579b',
              color: '#FFF',
            },
            rowStyle: {
              backgroundColor: '#EEE',
            },
            filtering: true,
            pageSize: leadsPerPage,
            pageSizeOptions: [
              5,
              10,
              20,
              { value: leads.results.length, label: 'All' },
            ],
          }}
          columns={[
            {
              title: 'Steps',
              field: 'steps',
              // editComponent: editProps => (
              //     <Input
              //         autoFocus={true}
              //         onChange={e => editProps.onChange(e.target.value)}
              //     />
              // )
              render: (rowData) => {
                return (
                  <>
                    {/* <Avatar color="azure">
                      {rowData.answers.length} Steps
                    </Avatar> */}
                    <div className="step-col flex flex-col aic">
                      <div className="no font s15 b6 color flex aic">
                        {rowData.answers.length}
                      </div>
                      <div className="txt font s14 b6 color">Steps</div>
                    </div>
                  </>
                );
              },
            },
            {
              title: 'Name',
              field: 'name',
              // editComponent: editProps => (
              //     <Input
              //         autoFocus={true}
              //         onChange={e => editProps.onChange(e.target.value)}
              //     />
              // )
              render: (rowData) => {
                return <>{rowData.name}</>;
              },
            },
            { title: 'Email', field: 'email' },
            {
              title: 'Date Created',
              field: 'created_at',
              render: (rowData) => {
                const updateTimestamp = Date.parse(rowData.created_at);
                // createTimestamp.toLocaleFormat('%d-%b-%Y');
                return (
                  <div className="date-col font s13 b4 cfff">
                    {moment(updateTimestamp).format('MMMM Do YYYY, h:mm:ss a')}
                  </div>
                );
              },
              customSort: (a, b) => {
                return moment(Date.parse(a.updated_on)).diff(
                  moment(Date.parse(b.updated_on))
                );
              },
              defaultSort: 'desc',
              editable: 'never',
            },
            {
              title: 'Actions',
              field: 'created_at',
              render: (rowData) => {
                const answers = rowData.answers.map((answer, idx) => {
                  return answer.poll_option_content;
                });
                return answers.map((answer, idx) => (
                  <div
                    className="date-col actions font s13 b4 cfff"
                    key={answer + idx}
                  >
                    {answer}
                  </div>
                ));
              },
              customSort: (a, b) => {
                return moment(Date.parse(a.updated_on)).diff(
                  moment(Date.parse(b.updated_on))
                );
              },
              defaultSort: 'desc',
              editable: 'never',
            },
          ]}
          // components={{
          //     Row: props => (
          //         <MTableBodyRow
          //             {...props}
          //         // onDoubleClick={e => {
          //         //     console.log(props.actions);
          //         //     props.actions[1]().onClick(e, props.data);
          //         // }}
          //         />
          //     )
          // }}

          // components={{
          //     Body: props => <MTableBody {...props} onFilterChanged={(columnId, value) => {
          //         props.onFilterChanged(columnId, value);
          //         // Do you job here :)
          //         updateLeadStats(tableRef.current.state.data);
          //     }} />
          // }}

          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  /* setData([...data, newData]); */
                  // addLead({ "name": newData.name, "email": newData.email })
                  resolve();
                }, 1000);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                resolve();
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  /* setData([...data, newData]); */
                  // deleteLead(oldData.id)
                  resolve();
                }, 1000);
                resolve();
              }),
          }}
          data={leads.results}
          // https://github.com/mbrn/material-table/issues/978
          // data={async (query) => {
          //     console.log("query: ", query);
          //     let leads = data.length > 1 ? data : await getLeads();

          //     return {
          //         data: data,
          //     }
          // }}

          title="Video Tour Activity"
          detailPanel={(rowData) => {
            return <LeadActionPanel leadData={rowData} form_id={form_id} />;
          }}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
        />
      </ContentLoader>
    </div>
  );
};

export default Leads;
