import React, { useEffect, useState } from 'react';
import useCacheState from '../../../hooks/useCacheState';
import VisitorsOverviewWidget from './VisitorsOverviewWidget';
import {
  generatechartoptions,
  generatechartoptionsmultipleseries,
  generate_data_frombigquery,
  fetch_events_analytics
} from '../utils';
import { supabase } from '../../../services/supabase';
function BlackChart({ magnetuuid, filterdate, community_id }) {
  const [launchDate,setlaunchDate] = useState()
  useEffect(() => {
    if (!magnetuuid) {
      return;
    }
    (async () => {
      const {data} = await supabase.from('Community').select("launchDate").eq("community_magnets",magnetuuid)
      //console.log("resssss",res)
      let launchdate = data?.[0]?.launchDate
      setlaunchDate(launchdate)
      console.log("launchdate",launchdate)
      // get tours
      generate_data_frombigquery(
        filterdate,
        magnetuuid,
        settotaltours,
        settotalleads,
        setuniquevisitor,
        generatechartoptions,
        () => {},
        generatechartoptionsmultipleseries,
        () => {},
        setblackChartOptions,
        launchdate
      );
    })()
   
  }, [magnetuuid, filterdate]);
  //console.log('chartOptions', chartOptions);
  const [totalleads, settotalleads] = useCacheState(
    0,
    'analytics_totalLeads',
    community_id
  );
  const [totaltours, settotaltours] = useCacheState(
    0,
    'analytics_totalTours',
    community_id
  );
  const [uniquevisitor, setuniquevisitor] = useCacheState(
    0,
    'analytics_uniqueVisitors',
    community_id
  );

  const [blackChartOptions, setblackChartOptions] = useCacheState(
    undefined,
    'analytics_blackChartOptions',
    community_id
  );

  return (
    <VisitorsOverviewWidget
      blackChartOptions={blackChartOptions}
      uniquevisitor={uniquevisitor}
      totalleads={totalleads}
      totaltours={totaltours}
      magnetuuid={magnetuuid}
      launchDate={launchDate}
      setlaunchDate={setlaunchDate}
    />
  );
}

export default BlackChart;
