import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete, Edit, Save } from '@material-ui/icons';
import axios from 'axios';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import ContentLoader from '../../components/ContentLoader';
import CustomHeader from '../../components/Reusable/CustomHeader';
import notification from '../../components/Reusable/Notification';
import * as loader from '../../lottie/loader.json';
import { get, post, useRequest } from '../../utils/request';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
}));

const MagnetAdminSettings = ({
  name,
  url,
  img_url,
  email,
  phone,
  community_id,
  community_hidden,
  title,
}) => {
  const [NotificationBar, setNotification] = notification();
  const classes = useStyles();

  const [loading, error, data, makeRequest] = useRequest({ loading: false });

  const [editName, setEditName] = useState(false);
  const [editURL, setEditURL] = useState(false);
  const [editEmail, setEditEmail] = useState('');
  const [editPhone, setEditPhone] = useState('');
  const [newName, setNewName] = useState('');
  const [newURL, setNewURL] = useState(url);
  const [newEmail, setNewEmail] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [newImageURL, setNewImageURL] = useState(img_url);
  const [editImageURL, setEditImageURL] = useState(false);
  const [magnetIntegrations, setMagnetIntegrations] = useState('');
  const [uuid, setUuid] = useState();
  const [response, setResponse] = useState();
  const [magnetTemplate, setMagnetTemplate] = useState();

  const [submit, setSubmit] = useState(false);
  const [encodingloading, setencodingloading] = useState(false);
  const [generatecaptionsloading, setgeneratecaptionsloading] = useState(false);
  const [generate_thumbnails_loading, setgenerate_thumbnails_loading] =
    useState(false);
  const [conversionloading, setconversionloading] = useState(false);

  useEffect(() => {
    getMagnet();
  }, []);

  async function encodevideos() {
    setencodingloading(true);
    const res = await get(
      `/encode_all_videos_in_acommunity?community_id=${community_id}`,
      {
        auth: false,
      }
    );
    setencodingloading(false);
  }
  async function generatecaptionss() {
    setgeneratecaptionsloading(true);
    const res = await get(
      `/generate_caption_of_all_vidoes_in_community?community_id=${community_id}`,
      {
        auth: false,
      }
    );
    setgeneratecaptionsloading(false);
  }
  async function generate_thumbnails() {
    setgenerate_thumbnails_loading(true);
    toast('Please be patient! This process will take sometime.');
    const res = await get(
      `/generate_video_thumbnails_through_screenshoot_api?community_id=${community_id}`,
      {
        auth: false,
      }
    );
    setgenerate_thumbnails_loading(false);
  }

  async function convertlinks() {
    setconversionloading(true);
    const res = await post(
      `/convert_links_http_to_https_in_community?community_id=${community_id}`,
      {
        auth: false,
      }
    );
    setconversionloading(false);
  }

  async function getMagnet() {
    const res = await get(`/magnets?queryByCommunity=${community_id}`, {
      auth: false,
    });
    setUuid(res['magnets'][0]['uuid']);
    setMagnetIntegrations(res['magnets'][0]['integration_details']);
    setMagnetTemplate(res['magnets'][0]['magnet_details']['template']);
  }

  function confirmName() {
    post('/edit_community', {
      id: community_id,
      type: 'rename',
      new_name: newName,
    }).then(() => {
      setNewName('');
      setEditName(false);
      window.location.reload(true);
    });
  }
  const [DirectualCommunitytemplate, setDirectualCommunitytemplate] =
    useState();
  const updatecommunityinDirectual = async (template) => {
    try {
      let postData = {
        id: community_id,
        //...directualCommunity,
        name: DirectualCommunitytemplate.name,
        website: DirectualCommunitytemplate.website,
        communitybadges: {
          communitybadges: DirectualCommunitytemplate.communitybadges,
        },
        team: {
          team: DirectualCommunitytemplate.team,
        },
        reviews: {
          reviews: DirectualCommunitytemplate.reviews,
        },
        champions: {
          champions: DirectualCommunitytemplate.champions,
        },
        questions: {
          questions: DirectualCommunitytemplate.questions,
        },
        settings: {
          ...DirectualCommunitytemplate.settings,
        },
      };
      console.log('postData ', postData);

      const res = await axios.post(
        `https://api.directual.com/good/api/v5/data/community/newCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
        postData
      );
      console.log(res);
    } catch (error) {
      console.log('error', error);
      console.log('error', error.response);
    }
  };
  const getcommunity = async () => {
    const res = await axios.get(
      `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
    );
    console.log('response', res);
    if (res.data) {
      try {
        let thejson = {
          name: res.data?.payload[0].name,
          website: res.data?.payload[0].website,
        };
        if (res.data?.payload[0]?.communitybadges) {
          thejson.communitybadges = JSON.parse(
            res.data?.payload[0].communitybadges
          ).communitybadges;
        }
        if (res.data?.payload[0]?.champions) {
          thejson.champions = JSON.parse(
            res.data?.payload[0].champions
          ).champions;
        }
        if (res.data?.payload[0]?.reviews) {
          thejson.reviews = JSON.parse(res.data?.payload[0].reviews).reviews;
        }
        if (res.data?.payload[0]?.settings) {
          thejson.settings = JSON.parse(res.data?.payload[0]?.settings);
        }
        if (res.data?.payload[0]?.team) {
          thejson.team = JSON.parse(res.data?.payload[0].team).team;
        }
        if (res.data?.payload[0]?.questions) {
          thejson.questions = JSON.parse(
            res.data?.payload[0].questions
          ).questions;
        }
        setDirectualCommunitytemplate(thejson);
      } catch (error) {
        console.log('errr', error);
      }
    } else {
      console.log('response_empty');
    }
  };
  console.log('DirectualCommunitytemplate', DirectualCommunitytemplate);
  useEffect(() => {
    getcommunity();
  }, []);
  console.log('magnetTemplate', magnetTemplate);
  function confirmImage() {
    post('/edit_community', {
      id: community_id,
      type: 'image',
      img_url: newImageURL,
    }).then(() => {
      setEditImageURL(false);
      window.location.reload(true);
    });
  }

  function confirmURL() {
    // if (!newURL.startsWith('https://')) {
    //   alert('The URL needs to start with https://');
    //   return;
    // }
    post('/edit_community', {
      id: community_id,
      type: 'change url',
      new_url: newURL,
    }).then(() => {
      setNewURL('');
      setEditURL(false);
      window.location.reload(true);
    });
  }

  function confirmEmail() {
    post('/edit_community', {
      id: community_id,
      type: 'change email',
      newEmail: newEmail,
    }).then(() => {
      setNewEmail('');
      setEditEmail(false);
      window.location.reload(true);
    });
  }

  function confirmPhone() {
    post('/edit_community', {
      id: community_id,
      type: 'change phone',
      newPhone: newPhone,
    }).then(() => {
      setNewPhone('');
      setEditPhone(false);
      window.location.reload(true);
    });
  }

  function disableCommunity() {
    let popup = window.confirm(
      `Are you sure you want to disable community ${name} | ${community_id} `
    );
    if (popup) {
      post('/edit_community', {
        id: community_id,
        type: 'disable',
      }).then(() => {
        window.location.replace('/');
      });
    }
  }

  function enableCommunity() {
    let popup = window.confirm(
      `Are you sure you want to re-enable community ${name} | ${community_id}`
    );
    if (popup) {
      post('/edit_community', {
        id: community_id,
        type: 'reenable',
      }).then(() => {
        window.location.reload(true);
      });
    }
  }

  function deleteCommunity() {
    let popup = window.confirm(`Are you sure you want to this community`);
    if (popup) {
      get(`/delete_community?community_id=${community_id}`).then(() => {
        window.location.replace('/');
      });
    }
  }

  const [analyticsApiKey, setAnalyticsApiKey] = useState('');

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CustomHeader
        url={url}
        name={name}
        community_id={community_id}
        title="Admin"
      />
      <div className="admin-tab-page">
        <ContentLoader loading={loading} error={error}>
          {!submit && (
            <Grid container spacing={1}>
              <Grid container item xs={12} direction="column" spacing={2}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Change Community Name
                </Typography>
                <Divider style={{ margin: '1vh 0' }} />
                <Typography variant="body1">Current Name: {name} </Typography>
                {editName ? (
                  <>
                    <TextField
                      style={{ margin: '1vh 0 3vh' }}
                      value={newName}
                      onChange={(e) => {
                        setNewName(e.target.value);
                      }}
                      label="Name"
                      placeholder="Community Name"
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') confirmName();
                      }}
                    />
                    <Button
                      variant="outlined"
                      startIcon={<Save />}
                      onClick={confirmName}
                    >
                      Change Community Name
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<Edit />}
                      onClick={() => {
                        setEditName(true);
                      }}
                    >
                      Change Community Name
                    </Button>
                  </>
                )}

                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Change Community URL
                </Typography>
                <Divider style={{ margin: '1vh 0' }} />
                <Typography variant="body1">Current URL: {url} </Typography>
                {editURL ? (
                  <>
                    <TextField
                      style={{ margin: '1vh 0 3vh' }}
                      value={newURL}
                      onChange={(e) => {
                        setNewURL(e.target.value);
                      }}
                      label="Community URL"
                      placeholder="Community URL"
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          confirmURL();
                        }
                      }}
                    />
                    <Button
                      variant="outlined"
                      startIcon={<Save />}
                      onClick={confirmURL}
                    >
                      Change Community URL
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={<Edit />}
                    onClick={() => {
                      setEditURL(true);
                    }}
                  >
                    Change Community URL
                  </Button>
                )}

                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Change Community email
                </Typography>
                <Divider style={{ margin: '1vh 0' }} />
                <Typography variant="body1">Current Email: {email} </Typography>
                {editEmail ? (
                  <>
                    <TextField
                      style={{ margin: '1vh 0 3vh' }}
                      value={newEmail}
                      onChange={(e) => {
                        setNewEmail(e.target.value);
                      }}
                      label="Community email"
                      placeholder="Community email"
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          confirmEmail();
                        }
                      }}
                    />
                    <Button
                      variant="outlined"
                      startIcon={<Save />}
                      onClick={confirmEmail}
                    >
                      Change Community email
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={<Edit />}
                    onClick={() => {
                      setEditEmail(true);
                    }}
                  >
                    Change Community Email
                  </Button>
                )}

                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Change Community Phone
                </Typography>
                <Divider style={{ margin: '1vh 0' }} />
                <Typography variant="body1">Current Phone: {phone} </Typography>
                {editPhone ? (
                  <>
                    <TextField
                      style={{ margin: '1vh 0 3vh' }}
                      value={newPhone}
                      onChange={(e) => {
                        setNewPhone(e.target.value);
                      }}
                      label="Community phone"
                      placeholder="Community phone"
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          confirmPhone();
                        }
                      }}
                    />
                    <Button
                      variant="outlined"
                      startIcon={<Save />}
                      onClick={confirmPhone}
                    >
                      Change Community Phone
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={<Edit />}
                    onClick={() => {
                      setEditPhone(true);
                    }}
                  >
                    Change Community Phone
                  </Button>
                )}

                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Change Community Image URL
                </Typography>
                <img src={img_url} width="250px" />
                <Divider style={{ margin: '1vh 0' }} />
                <Typography variant="body1">Current URL: {img_url} </Typography>
                {editImageURL ? (
                  <>
                    <TextField
                      style={{ margin: '1vh 0 3vh' }}
                      value={newImageURL}
                      onChange={(e) => {
                        setNewImageURL(e.target.value);
                      }}
                      label="Community URL"
                      placeholder="Community URL"
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          confirmImage();
                        }
                      }}
                    />
                    <button
                      onClick={() => {
                        console.log('before opengraph call');
                        fetch(
                          `https://api.opengraphr.com/v1/og?api_token=zOoUHUX4mLywRUgL3UocGf7cPkyjKbuM4BPZi3wr0AxrjcRcqcX6aaKKIlDB8ms5cXqL0soSsn70FhMm&url=${url}`
                        )
                          .then((response) => response.json())
                          .then((data) => {
                            console.log('opengraph: ', data, newImageURL);
                            setNewImageURL(data.image);
                          });
                      }}
                    >
                      Fetch Opengraph Image
                    </button>
                    <Button
                      variant="outlined"
                      startIcon={<Save />}
                      onClick={confirmImage}
                    >
                      Change Community Image URL
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={<Edit />}
                    onClick={() => {
                      setEditImageURL(true);
                    }}
                  >
                    Change Community Image URL
                  </Button>
                )}

                <Typography
                  variant="h6"
                  style={{ fontWeight: 'bold', color: 'red' }}
                >
                  {community_hidden ? 'Re-Enable' : 'Disable'} Community
                </Typography>
                <Button
                  variant="outlined"
                  startIcon={<Delete />}
                  onClick={
                    community_hidden ? enableCommunity : disableCommunity
                  }
                >
                  {community_hidden ? 'Re-Enable' : 'Disable'}
                </Button>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 'bold', color: 'red' }}
                >
                  Encode all videos
                </Typography>
                <Button variant="outlined" onClick={encodevideos}>
                  {/* {encodingloading && <Lottie options={defaultOptions} width={10} />} */}
                  {!encodingloading ? 'Encode Vidoes' : 'Loading..'}
                </Button>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 'bold', color: 'red' }}
                >
                  Generate captions all videos
                </Typography>
                <Button variant="outlined" onClick={generatecaptionss}>
                  {/* {encodingloading && <Lottie options={defaultOptions} width={10} />} */}
                  {!generatecaptionsloading ? 'Generate captions' : 'Loading..'}
                </Button>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 'bold', color: 'red' }}
                >
                  Generate screen videos thumbnails/images
                </Typography>
                <Button variant="outlined" onClick={generate_thumbnails}>
                  {/* {encodingloading && <Lottie options={defaultOptions} width={10} />} */}
                  {!generate_thumbnails_loading
                    ? 'Generate thumbnails'
                    : 'Loading..'}
                </Button>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 'bold', color: 'red' }}
                >
                  Delete this community
                </Typography>
                <Button variant="outlined" onClick={deleteCommunity}>
                  {/* {encodingloading && <Lottie options={defaultOptions} width={10} />} */}
                  Delete community
                </Button>

                <Typography
                  variant="h6"
                  style={{ fontWeight: 'bold', color: 'red' }}
                >
                  convert links to https
                </Typography>
                <Button variant="outlined" onClick={convertlinks}>
                  {/* {encodingloading && <Lottie options={defaultOptions} width={10} />} */}
                  {!conversionloading ? 'Convert links' : 'Loading..'}
                </Button>

                <Typography
                  variant="h6"
                  style={{ fontWeight: 'bold', color: 'grey' }}
                >
                  Analytics API Key
                </Typography>
                <input
                  defaultValue={magnetTemplate?.magnetSettings?.analyticsApiKey}
                  onChange={(e) => setAnalyticsApiKey(e.target.value)}
                />

                <Button
                  fullWidth
                  variant="contained"
                  onClick={async () => {
                    const res = await post('/magnets/template', {
                      magnet_uuid: uuid,
                      magnet_type: 'video',
                      template: {
                        ...magnetTemplate,
                        magnetSettings: {
                          ...magnetTemplate?.magnetSettings,
                          analyticsApiKey: analyticsApiKey,
                        },
                      },
                    });
                    if (!res.error) {
                      setResponse('success -- see logs for details');
                    } else {
                      setResponse('fail -- see log for details');
                    }
                    console.log('response = ', res);
                  }}
                >
                  Save Analytics API Key
                </Button>
              </Grid>

              <Divider style={{ margin: '1vh 0' }} />

              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    Change Integrations JSON
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {magnetIntegrations && (
                    <Editor
                      value={magnetIntegrations}
                      onChange={(e) => {
                        setMagnetIntegrations(e);
                        console.log('magnetIntegrations: ', magnetIntegrations);
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={async () => {
                      const res = await post('/integration/details', {
                        magnet_uuid: uuid,
                        data: magnetIntegrations,
                        merge: false,
                      });
                      if (!res.error) {
                        setResponse('success -- see logs for details');
                      } else {
                        setResponse('fail -- see log for details');
                      }
                      console.log('response = ', res);
                    }}
                  >
                    Save Integrations
                  </Button>
                  {response}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    Change Template JSON
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {magnetTemplate && (
                    <Editor
                      value={magnetTemplate}
                      onChange={(e) => setMagnetTemplate(e)}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={async () => {
                      const res = await post('/magnets/template', {
                        magnet_uuid: uuid,
                        magnet_type: 'video',
                        template: magnetTemplate,
                      });
                      if (!res.error) {
                        setResponse('success -- see logs for details');
                      } else {
                        setResponse('fail -- see log for details');
                      }
                      console.log('response = ', res);
                      updatecommunityinDirectual(magnetTemplate);
                    }}
                  >
                    Save
                  </Button>
                  {response}
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    Change Template JSON in Directual
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {DirectualCommunitytemplate && (
                    <Editor
                      value={DirectualCommunitytemplate}
                      onChange={(e) => setDirectualCommunitytemplate(e)}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={async () => {
                      updatecommunityinDirectual(magnetTemplate);
                    }}
                  >
                    Save
                  </Button>
                  {response}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <br />
              </Grid>
            </Grid>
          )}

          {submit && (
            <Grid container direction="column">
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Updated Team
              </Typography>
              <Typography variant="caption">
                New team members will receive a welcome email from LeaseMagnets
                to get their new accounts set up!
              </Typography>
            </Grid>
          )}
        </ContentLoader>
      </div>
    </>
  );
};

export default MagnetAdminSettings;
