import moment from 'moment';
import { toast } from 'react-toastify';
import { convert_date_by_tz, saveLeadinDB } from '../utilities';

export const isUserDataValid = (data) => {
  if (!data.firstname || !data.lastname || !data.email || !data.phone) {
    return 'Please fill all fields';
  } else if (data?.phone?.length != 10) {
    return 'phone number length should be 10';
  }

  return null;
};

export const sendMessage = async (
  magnetId,
  obj,
  userInfo,
  { onError, showLogs }
) => {
  if (showLogs)
    console.log('thedata', {
      creds: {
        username: data.username,
        password: data.password,
      },
      propertyId: data.property_id,
      ...obj,
    });
  try {
    // const data = await getEntrataData(integrationId);
    const query = new URLSearchParams(window.location.search);
    const timezone = query.get('timezone');
    let created_date = await convert_date_by_tz(
      new Date(),
      timezone ? timezone : 'MST'
    );
    let form_data = {
      ...obj,
      date: `${moment(created_date).format('L')}T${moment(created_date).format(
        'hh:mm:ss'
      )}`,
      source: 'cta_tour_mini_app',
      lead_name: `${userInfo.firstname} ${userInfo.lastname}`,
      lead_phone: `+1${userInfo.phone}`,
      lead_email: userInfo.email,
    };
    console.log('contact_us_form_data', form_data);
    // tested Lead instered in entrata
    saveLeadinDB(magnetId, {
      form_data,
      form_route: null,
      video_time: 0,
    });
    toast('Contact us question & lead has been submitted');
    return true;
  } catch (err) {
    console.log(err);
  }
};
