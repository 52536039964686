import React from 'react';

function SidebarVideosIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 25 18"
    >
      <path
        fill="#446888"
        d="M24.26 14.633V2.615c0-1.322-1.244-2.404-2.764-2.404H3.529C2.009.21.764 1.293.764 2.615v12.018c0 1.322 1.244 2.404 2.764 2.404h17.968c1.52 0 2.764-1.082 2.764-2.404zM9.057 13.431V3.816l8.293 4.808-8.293 4.807z"
      ></path>
    </svg>
  );
}

export default SidebarVideosIcon;
