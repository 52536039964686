import { Grid } from '@material-ui/core';
import React, { memo } from 'react';
import DebouncedTextField from '../../../components/Reusable/DebouncedTextField';
import './Settings.css';

const ShareSettings = ({ shareInfo, setShareInfo }) => (
  <Grid item xs={12}>
    <div className="form-group flex flex-col">
      <div className="feild-title font s15 b6 c000">URL</div>
      <DebouncedTextField
        value={shareInfo?.url}
        onChange={(newValue) => setShareInfo({ ...shareInfo, url: newValue })}
        error={!shareInfo?.url}
        label={!shareInfo?.url && 'Required'}
        required
        type="url"
        placeholder="https://website.com"
        variant="outlined"
        size="small"
        fullWidth
      />
    </div>
    <div className="form-group flex flex-col">
      <div className="feild-title font s15 b6 c000">Sharing Message</div>
      <DebouncedTextField
        value={shareInfo?.text}
        onChange={(newValue) => setShareInfo({ ...shareInfo, text: newValue })}
        placeholder="Check out this great apartment!"
        variant="outlined"
        size="small"
        fullWidth
      />
    </div>
    <div className="form-group flex flex-col">
      <div className="feild-title font s15 b6 c000">Hashtag</div>
      <Grid container>
        <Grid item style={{ margin: 'auto 2px' }}>
          <strong>#</strong>
        </Grid>
        <Grid item>
          <DebouncedTextField
            value={
              shareInfo?.hashtag?.[0] === '#'
                ? shareInfo?.hashtag.slice(1)
                : shareInfo?.hashtag
            }
            onChange={(newValue) =>
              setShareInfo({ ...shareInfo, hashtag: `#${newValue}` })
            }
            placeholder="apartment"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  </Grid>
);

export default memo(ShareSettings);
