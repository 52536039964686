import Snackbar from '@material-ui/core/Snackbar';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Header } from '../../components';
import notification from '../../components/Notification';
import { getMagnetData } from '../../store/actions/magnetActions';
import {
  deletevideo,
  uploadBlobToStorage,
  validFileName,
  deletefilefromcloudstorage,
} from '../../utils/files';
import { API_HOST, get } from '../../utils/request';
import { createScreenKey } from './CreateKeys';
import MagnetSummary from './MagnetSummary';
import Upload from './Upload';
import { supabase } from '../../services/supabase';
import { toast } from 'react-toastify';

// snackbar for the alerts imports
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// returns the dispatch action object
const actionJsonGenerator = (type, screen) => ({
  type,
  screen,
});

// update encoding states
const encodingTemplateReducer = (state, action) => {
  switch (action.type) {
    case 'loadExisting':
      return state;

    /*

    if coconut_job is inside response
- >  add coconut job to the state object list of (
        {
      “category.screen-name” : {
        job_id: “8123123123”,
        old_url: “…mp4”,
        new_url: “…mp4”,
        progress: “50%”
        },
      }

    */

    case 'checkJob':
      const screenToCheck = action.screen;

      var job_id = action.job_id;
      var encoding_status = get(`/job/encoding/${job_id}`);
      //console.log(`job ${job_id}: `, encoding_status);

      return state;

    case 'checkAllJobs':
      Object.keys(state).map((screen_k, screen_idx) => {
        var temp = state[screen_k];
        var job_id_curr = temp?.job_id;

        var encoding_status = get(`/job/encoding/${job_id_curr}`);
        //console.log(`${screen_k} || job ${job_id}: `, encoding_status);
      });

      return state;

    case 'addJob':
      // ADD ITEM TO SCREEN KEYS
      const screenToAdd = action.screen;
      // const screenKeyToAdd = action.screen_key;
      // const screenCategory = action.category_key;
      const coconutJobId = action.coconut_id;
      const oldUrl = action.old_url;
      const newUrl = action.new_url;
      const progress = action.progres;

      var newJob = {};

      var new_template = {
        ...state,
        screenToAdd,
      };

      return new_template;

    case 'deleteJob':
      const screenToRemove = action.screen;
      var filter_template = state;

      delete filter_template[screenToRemove];

      return filter_template;

    case 'resetAll':
      return {};

    default:
      return state;
  }
};

export const generateRoutes = (template) => {
  const templateRoutes = [];
  template?.category_keys?.forEach((category_k) => {
    const category_title = template.categories[category_k].title;
    template?.categories[category_k].screen_keys.forEach((screen_k) => {
      const screen_title =
        template?.categories[category_k]?.screens[screen_k]?.title;
      const videoval =
        template?.categories[category_k]?.screens[screen_k]?.video;
      templateRoutes.push({
        id: `${category_title} - ${screen_title}`,
        route: `${category_k}.${screen_k}`,
        video: videoval,
        title: screen_title,
        img: template?.categories[category_k]?.screens[screen_k]?.img,
      });
    });
  });

  templateRoutes.push({
    id:
      'Promotions - ' +
      (template?.magnetSettings?.specialOffer
        ? template?.magnetSettings?.specialOffer
        : 'Latest Special Offer '),
    route: `_promotions_.latest`,
    title:
      'Apply and get ' +
      (template?.magnetSettings?.specialOffer
        ? template?.magnetSettings?.specialOffer
        : 'Latest Special Offer  '),
    img: '',
  });

  return templateRoutes;
};

// react component
const ManageMagnet = ({
  community_id,
  name,
  url,
  magnetLoading,
  setOpenCategoryDialog,
  title,
  accessLevel
}) => {
  const _magnetData = useSelector((state) => state.getMagnet);
  const { magnetData, magnetError, template } = _magnetData;
  const { magnets, error, status } = magnetData;
  const {
    screenToEdit,
    encodingQ,
    magnetId,
    magnetType,
    loadScreen,
    potentiallyUploading,
    previewTemplate,
    currVid,
    newScreenCloudLibrary,
    newScreenVideoCurrVid,
    newScreenFormTemplate,
    newScreenIframeOption,
  } = useSelector((state) => state.magnetStates);

  const [showShareVideoDialog, setShowShareVideoDialog] = useState(false);
  const [showEmbedVideoDialog, setShowEmbedVideoDialog] = useState(false);
  const [category, screen] = useMemo(
    () => screenToEdit.split('.'),
    [screenToEdit]
  );
  var categoryTitle = useMemo(
    () => (category ? template?.categories[category]?.title : ''),
    [category, template]
  );

  const _dispatch = useDispatch();

  useEffect(() => {
    _dispatch(getMagnetData(community_id));
  }, [community_id]);

  useEffect(() => {
    // important: Reset screenToEdit state, because it contains stale data
    _dispatch({
      type: 'SCREEN_TO_EDIT',
      payload: '',
    });
  }, []);

  useEffect(() => {
    if (screenToEdit === '') {
      _dispatch({ type: 'CURR_VID', payload: [] });
      _dispatch({ type: 'NEW_SCREEN_VIDEOS_CURRVID', payload: [] });
      _dispatch({ type: 'NEW_SCREEN_FORM_TEMPLATE', payload: {} });
      _dispatch({ type: 'NEW_VIDEO_TITLE', payload: '' });
    }
  }, [screenToEdit]);

  useEffect(() => {
    if (magnets && !previewTemplate.enablePreview) {
      _dispatch({
        type: 'updateState',
        payload: template,
      });
      _dispatch({ type: 'MAGNET_ID', payload: magnets[0].uuid });
      _dispatch({ type: 'MAGNET_TYPE', payload: magnets[0].magnet_type });
    }
    if (magnets && previewTemplate.enablePreview) {
      _dispatch({
        type: 'updateState',
        payload: template,
      });
    }
  }, [magnets]);

  const deleteurlfromrecords = (community_id) => async (dispatch) => {
    try {
      const res = await get(
        `/delete_all_videourl_in_a_community?community_id=${community_id}`,
        {
          auth: false,
        }
      );

      if (res.data.status === 'OK') {
        toast('Setting updated successfully', {
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
      }
    } catch (error) {
      dispatch({
        type: 'CREATE_REVIEWS_COMMUNITY_FAIL',
        payload: error.message,
      });
      console.log('error', error);
    }
  };

  const deleteallvideos = async () => {
    console.log('template_', template);

    //console.log('keys',Object.keys(template.categories))
    template['category_keys'].map((item) => {
      console.log(item);
      template.categories[item].screen_keys.map((item2) => {
        console.log('keys', template.categories[item].screens[item2]);
        template.categories[item]?.screens[item2]?.cloudLibrary?.map(
          (item3) => {
            console.log('item3', item3);
            const path = item3['dataURL']
              ?.split('leasemagnets---dummy-db.appspot.com/')[1]
              ?.split('coconut_id')[0];
            console.log('path', path);
            if (path) {
              deletevideo(path);
            }
          }
        );
      });
    });
    //let path = 'leasemagnets---dummy-db.appspot.com/community/2/magnet/6bdd5fdd-fab9-445c-a196-58cc4788b90d/intro_Intro_video_2/Y2Mate_is_-_Habibi_Song_Velocity_Edit__velocity__velocityedit__shake__habibi__habibistatus-BaLQdeVR9RI-480p-1644425728405_mp4_1.mp4'

    //deletevideo(path)
  };

  // Update Backend and Local state
  async function updateTemplate(action) {
    action = {
      ...action,
      magnet_id: magnetId,
      magnet_type: magnetType,
    };
    dispatch(action);
  }

  // SNACKBAR Alert management
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarColor, setSnackbarColor] = React.useState('success');
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const handleSnackbar =
    (message, alertColor = 'success') =>
    () => {
      setSnackbarMessage(message);
      setSnackbarColor(alertColor);
      setOpenSnackbar(true);
    };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const [NotificationBar, setNotification] = notification();
  const [newUploadHasStarted, setNewUploadHasStarted] = React.useState(false);
  // const [currVid, setCurrVid] = useState([]);

  function setCurrVid(val) {
    _dispatch({ type: 'CURR_VID', payload: val });
  }

  const loadScreenText = [
    'Uploading Video to Cloud',
    'Transcoding Video to Stream Formats',
  ];
  const [loadScreenTextIndex, setLoadScreenTextIndex] = useState(0);

  async function handleScreen(
    thumbnailImg,
    actionType,
    name,
    caption,
    locCard,
    video_url,
    cloudLibrary,
    links,
    centerLinks,
    bottomLinks,
    screen_key,
    category_key,
    metadata,
    screenObj,
    isButtonSeprated
  ) {
    console.log('cloudLibrary=2', cloudLibrary);
    console.log(
      'cloudLibrary=3',
      actionType,
      name,
      caption,
      locCard,
      video_url,
      cloudLibrary,
      links,
      centerLinks,
      bottomLinks,
      screen_key,
      category_key,
      metadata,
      screenObj
    );
    let screenToUpdate = {
      title: name,
      location_card: locCard,
      caption,
      video: video_url,
      cloudLibrary: cloudLibrary,
      links,
      centerLinks,
      bottomLinks,
      form: metadata.form,
      iframe: metadata.iframe,
      additionalInfo: metadata.additionalInfo,
      share_info: metadata.share_info,
      gif: screenObj?.gif,
      img: thumbnailImg || screenObj?.img,
      dataURL: screenObj?.dataURL,
      addOns: screenObj?.addOns || {},
      // show_title_time: 0.25, Hide links time?
      // show_nav_links_time: 0.5,
    };

    let screenKeyToUpdate = '';

    // If screen key does not exist, then create one, else use existing
    if (screen_key === 'new') {
      screenKeyToUpdate = createScreenKey(name);
    } else {
      screenKeyToUpdate = screen_key;
    }

    console.log(
      'iframelyObj screenToUpdate',
      screenToUpdate,
      screenKeyToUpdate,
      category_key,
      actionType,
      screen_key,
      actionType
    );

    const screenCategoryToUpdate = category_key;

    // check iframes for t
    if (screenToUpdate?.iframe?.enabled) {
      console.log('iframelyObj iframe enabled');

      try {
        const iframelyObj = await axios.get(
          `https://iframe.ly/api/oembed?url=${screenToUpdate?.iframe?.src}&api_key=f7fb93bd90dcaf2eb4fc0a`
        );
        console.log('iframelyObj', iframelyObj.data);
        if (iframelyObj?.data?.thumbnail_url) {
          screenToUpdate.img = iframelyObj?.data?.thumbnail_url;
          console.log('iframelyObj screemUpdated', screenToUpdate);
        }
      } catch (err) {
        // Handle Error Here
        console.log('iframelyObj_error', err);
      }
    }

    if (!screenToUpdate.img && screenToUpdate?.iframe?.enabled) {
      try {
        let url = '';
        if (screenToUpdate?.iframe?.enabled) {
          url = `https://iframe.ly/api/oembed?url=${screenToUpdate?.iframe?.src}&api_key=f7fb93bd90dcaf2eb4fc0a`;
        }
        const response = await axios.get(
          `https://shot.screenshotapi.net/screenshot?token=FTAC8ZZ-P5343ZS-HZMENSV-4J2XYVV&url=${screenToUpdate?.iframe?.src}&output=image&file_type=png&wait_for_event=load&output=json&fresh=true`
        );
        console.log('screenshot_ response', response);
        if (response?.data) {
          screenToUpdate.img = response?.data?.screenshot;
          console.log('img', screenToUpdate);
        }
      } catch (error) {
        console.log('screenToUpdate_error', error);
        console.log('screenToUpdate_error', error?.response);
      }
    }
    // else if (!screenToUpdate.img && screenToUpdate?.video) {
    //   try {
    //     let url = '';
    //     const response = await axios.get(
    //       `https://shot.screenshotapi.net/screenshot?token=FTAC8ZZ-P5343ZS-HZMENSV-4J2XYVV&url=${screenToUpdate?.video}&output=image&file_type=png&wait_for_event=load&output=json&fresh=true`
    //     );
    //     console.log('screenshot_ response', response);
    //     if (response?.data) {
    //       screenToUpdate.img = response?.data?.screenshot;
    //       console.log('img', screenToUpdate);
    //     }
    //   } catch (error) {
    //     console.log('screenToUpdate_error', error);
    //     console.log('screenToUpdate_error', error?.response);
    //   }
    // }

    //video screenshot sample https://screenshotapi-dot-net.storage.googleapis.com/storage_googleapis_com_leasemagnets___dummy_db_app_749536150c15.png
    //if there is no thumbnail yet for any reason, get screenshot here
    // https://shot.screenshotapi.net/screenshot?token=93BXFRT-QPNMHND-QNSN4C3-R6SNJZS&url=https://leasemagnets.com/&output=image&file_type=png&wait_for_event=load&output=json&delay=1500
    // sample json response: {"screenshot":"https://screenshotapi-dot-net.storage.googleapis.com/leasemagnets_com__160107f5db06.png","url":"https://leasemagnets.com/","created_at":"2022-08-07T14:42:00.561Z","is_fresh":true,"token":"93BXFRT-QPNMHND-QNSN4C3-R6SNJZS","output":["image","json"],"file_type":"png","wait_for_event":"load","delay":"1500","ttl":"2022-09-06T14:41:50.914Z"}
    console.log('taking screenshot_after');
    _dispatch({
      type: actionType,
      payload: {
        screen_key: screenKeyToUpdate,
        category_key: screenCategoryToUpdate,
        screen: screenToUpdate,
        magnet_id: magnetId,
        magnet_type: magnetType,
        isButtonSeprated,
      },
    });
  }

  function checkIfEligible(category, screen, name, caption) {
    if (
      ((screen !== 'new' &&
        (!template?.categories[category]?.screens[screen]?.video ||
          !template.categories[category].screens[screen]?.iframe?.enabled)) ||
        (screen === 'new' &&
          !template.categories[category].screens[screen]?.iframe?.enabled)) &&
      (!currVid || !currVid[0] || !('formData' in currVid[0]))
    ) {
      setNotification('No video found to upload', 'error');
      return false;
    }
    // console.log('check if video is selected: ', currVid[0]);
    // console.log(
    //   'video: ',
    //   template.categories[category].screens[screen].video,
    //   currVid[0].formData
    // );

    // console.log('screen name: no new in name or period ', screen);
    if (
      !name ||
      name.toLowerCase().includes('new') ||
      name.toLowerCase().includes('.')
    ) {
      setNotification(
        "Invalid screen name, don't include the word new or period",
        'error'
      );
      return false;
    }

    const tempName = createScreenKey(name);
    if (
      screen.toLowerCase().trim() === 'new' &&
      template.categories[category].screen_keys.includes(tempName)
    ) {
      setNotification(
        'Invalid screen name, same screen name already exists in category',
        'error'
      );
      return false;
    }
    // no screen name in the same category
    // console.log(
    //   'no screen name in the same category: ',
    //   template.categories[category].screen_keys
    // );
    if (caption && caption.length > 50) {
      setNotification('Caption is too long', 'error');
      return false;
    }
    // console.log('check the length of ', screen, category, caption);
  }

  // let interval; FIXME
  //
  // // Creates background auto refresh if nothing in encodingQ
  // // or the user is not currently editing a screen
  // useEffect(() => {
  //   if (encodingQ.length === 0 && !screenToEdit) {
  //     interval = setInterval(() => {
  //       updateMagnetInBackground();
  //     }, 30000);
  //   }
  // }, [encodingQ.length, screenToEdit]);
  //
  // // Stops background auto refresh if there is something
  // // in encodingQ or user is editing something
  // useEffect(() => {
  //   if (encodingQ.length !== 0 || screenToEdit) {
  //     console.log("Clearing interval", interval);
  //     clearInterval(interval);
  //   }
  // }, [encodingQ.length, screenToEdit]);

  // returns the currVidURL and filtered cloud library to be updated in the template
  async function uploadToCloudLibrary(
    thumbnailImg,
    screen,
    name,
    category,
    cloudLibrary,
    setCloudLibrary,
    setCloudLibraryProgress,
    caption,
    locCard,
    buttons,
    centerButtons,
    bottomButtons,
    formTemplate,
    additionalInformation,
    iframeOptions,
    shareInfo,
    addScreen = false,
    videolibrary_option
  ) {
    // showprogressbar
    _dispatch({ type: 'SHOW_PROGRESS_BAR', payload: true });
    var screen_id = `${category}.${screen}`;
    const prefix =
      'community/' +
      validFileName(community_id) +
      '/' +
      validFileName(category) +
      '_' +
      validFileName(screen);

    // data to be returned
    let filteredCloudLibrary = [];
    let outCurrVid = '';
    console.log('cloudLibrary=5', cloudLibrary);
    for (let i = 0; i < cloudLibrary.length; i++) {
      if (
        cloudLibrary[i].saveToCloud ||
        cloudLibrary[i].dataURL === currVid[0].source
      ) {
        if (cloudLibrary[i].formData || addScreen) {
          // //Sending file to google cloud platform
          _dispatch({
            type: 'ENCODING_Q',
            payload: [...encodingQ, `${category}.${screen}`],
          });

          const validUrl = new RegExp(
            /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
          );
          try {
            let url;
            let uploadedToFirebase = false;

            if (validUrl.test(cloudLibrary[i].dataURL)) {
              // if we uploaded from a url
              url = cloudLibrary[i].dataURL;
            } else {
              // if we need to upload to firebase
              _dispatch({
                type: 'SET_CURR_UPLOADING_VIDEO',
                payload: cloudLibrary[i].name,
              });
              url = await uploadBlobToStorage(
                cloudLibrary[i].dataURL,
                cloudLibrary[i].name,
                prefix,
                cloudLibrary[i].type,
                cloudLibrary[i].origName,
                (progress, timeestimate) => {
                  // progress updates
                  //

                  setCloudLibraryProgress({
                    uploading: true,
                    idx: i,
                    progress: progress * 0.75,
                  });
                  _dispatch({
                    type: 'LOAD_SCREEN',
                    payload: {
                      name: cloudLibrary[i].name,
                      progress: (progress * 0.75).toFixed(0),
                      timeestimate,
                      screen_name: name,
                      //[screen_id]: (progress * 0.75).toFixed(0),
                    },
                  });
                }
              );
              uploadedToFirebase = true;
            }

            console.log('new video url = ', url, uploadedToFirebase);
            console.log('videolibrary_option', videolibrary_option);
            console.log('filename', cloudLibrary[i].name);
            const mod_name = name.toLowerCase().split(' ').join('_');
            let videolib_supabase_id = '';
            try {
              if (videolibrary_option == 'archive') {
                // put old video to archive folder
                // we should be saving specific id in template obj
                console.log('isActive', `'${category}` + '.' + `${screen}'`);
                const res = await supabase
                  .from('VideoLibrary')
                  .update({ folder: 'archive' })
                  .eq('isActive', `${category}` + '.' + `${screen}`)
                  .eq('community_id', community_id)
                  .eq('folder', 'active');
                console.log('archive_done', res);
                // create new row in videolibrary
                const res2 = await supabase
                  .from('VideoLibrary')
                  .insert({
                    fileUrl: url,
                    filename: cloudLibrary[i].name,
                    isActive: `${category}` + '.' + `${screen}`,
                    community_id,
                    magnet_uuid: magnetId,
                    folder: 'active',
                  })
                  .select();
                console.log('new video res2', res2);
                console.log('new video added', res2.data[0]['id']);
                videolib_supabase_id = res2.data[0]['id'];
                console.log('new video added', res2);
              } else if (videolibrary_option == 'replace') {
                // get previous url and delete video from cloud storage
                const res = await supabase
                  .from('VideoLibrary')
                  .select('*')
                  .eq('isActive', `${category}` + '.' + `${screen}`)
                  .eq('community_id', community_id)
                  .eq('folder', 'active');
                if (res?.data?.length > 0) {
                  console.log('res', res);
                  console.log('old_file', res?.data?.[0]?.fileUrl);
                  console.log('got_reps', res);
                  // delete video
                  deletefilefromcloudstorage(res?.data?.[0]?.fileUrl);
                  // delete gif
                  deletefilefromcloudstorage(res?.data?.[0]?.thumbnail_gif);
                  // delete img
                  deletefilefromcloudstorage(res?.data?.[0]?.thumbnail_img);

                  // delete object from cloudlibrary
                  filteredCloudLibrary = filteredCloudLibrary.filter(
                    (item) => item.video !== res?.data?.[0]?.fileUrl
                  );

                  // update url
                  const res2 = await supabase
                    .from('VideoLibrary')
                    .update({
                      fileUrl: url,
                      filename: cloudLibrary[i].name,
                      thumbnail_img: '',
                      thumbnail_gif: '',
                    })
                    .eq('isActive', `${category}` + '.' + `${screen}`)
                    .eq('community_id', community_id)
                    .eq('folder', 'active')
                    .select();
                  videolib_supabase_id = res2.data?.[0]?.['id'];
                  console.log('updated library', res2);
                } else {
                  const repsonse = await supabase
                    .from('VideoLibrary')
                    .insert({
                      fileUrl: url,
                      filename: cloudLibrary[i].name,
                      isActive: `${category}` + '.' + `${screen}`,
                      community_id,
                      magnet_uuid: magnetId,
                      folder: 'active',
                    })
                    .select();
                  console.log('new video repsonse', repsonse);
                  console.log('new video added', repsonse.data[0]['id']);
                  videolib_supabase_id = repsonse.data[0]['id'];
                }
              }
            } catch (error) {
              console.log('Error_supabase', error);
            }
            console.log('videolib_supabase_id', videolib_supabase_id);
            let data = {
              video: url,
            };
            if (videolib_supabase_id) {
              data.videolib_supabase_id == videolib_supabase_id;
            }
            const response = await axios({
              method: 'POST',
              url: `${API_HOST}/upload-url?prefix=${prefix}&copy=${!uploadedToFirebase}&category=${category}&screen=${mod_name}&videolib_supabase_id=${videolib_supabase_id}`,
              //url: `http://localhost:8080/upload-url?prefix=${prefix}&copy=${!uploadedToFirebase}&category=${category}&screen=${mod_name}`,
              data,
              onUploadProgress: (progressEvent) => {
                setNewUploadHasStarted(true);
                const { loaded, total } = progressEvent;
                setCloudLibraryProgress({
                  uploading: true,
                  idx: i,
                  progress: 0.75 + loaded * 0.25,
                });
                if (loaded > 95.0) {
                  setLoadScreenTextIndex(1);
                }
                _dispatch({
                  type: 'LOAD_SCREEN',
                  payload: {
                    route: cloudLibrary[i].route,
                    name: cloudLibrary[i].name,
                    progress: Math.floor((loaded * 95) / total),
                    //[screen_id]: Math.floor((loaded * 95) / total),
                  },
                });
              },
              headers: {
                'Content-Type': 'application/json',
              },
            });

            _dispatch({
              type: 'ENCODING_Q',
              payload: encodingQ.filter(
                (val) => val !== `${category}.${screen}`
              ),
            });

            if (response.data.status == 'success') {
              console.log('heres what we found: ', response.data);

              _dispatch({
                type: 'LOAD_SCREEN',
                payload: {
                  route: cloudLibrary[i].route,
                  name: cloudLibrary[i].name,
                  progress: 100,
                  //[screen_id]: 100,
                },
              });

              _dispatch({
                type: 'POTENTIALLY_UPLOADING',
                payload: potentiallyUploading - 1,
              });
              // updates the currVid if this is it
              if (currVid[0].source === cloudLibrary[i].dataURL) {
                console.log('outCurrVid_called');
                outCurrVid =
                  url + '?&coconut_id=' + response?.data?.coconut_job;
                setCurrVid([
                  {
                    source: url + '?&coconut_id=' + response?.data?.coconut_job,
                    type: cloudLibrary[i].type,
                    formData: cloudLibrary[i].formData
                      ? cloudLibrary[i].formData
                      : '',
                  },
                ]);
              }
              console.log('cloudLibrary=4', cloudLibrary);
              // updated filtered cloud library and local library
              const temp = cloudLibrary;
              temp[i] = {
                dataURL: url + '?&coconut_id=' + response?.data?.coconut_job,
                name: cloudLibrary[i].name,
                saveToCloud: true,
                type: 'video/mp4',
                videolib_supabase_id,
              };
              setCloudLibrary(temp);
              filteredCloudLibrary.push(temp[i]);
              handleScreen(
                thumbnailImg,
                // this just creates the screen the first time and after that updates it.
                // If we ever change to allow screens to be made without a video we will need to update this
                addScreen && filteredCloudLibrary.length === 1
                  ? 'addScreen'
                  : 'updateScreen',
                name,
                caption,
                locCard,
                outCurrVid || temp[i].dataURL,
                filteredCloudLibrary,
                buttons,
                centerButtons,
                bottomButtons,
                screen,
                category,
                {
                  form: formTemplate,
                  additionalInfo: additionalInformation,
                  iframe: iframeOptions,
                  share_info: shareInfo,
                }
              );
            } else {
              //filteredCloudLibrary.push(temp[i]);
              console.log('responsecheck = ', response);
              console.log('errorr = ', response);
              setNotification(
                `Unsuccessfully upload. Error: ${response?.data?.error}`,
                'error'
              );
            }
          } catch (error) {
            //console.log(error);
            //filteredCloudLibrary.push(cloudLibrary[i]);
            console.log('responsecheck = ', error);
            handleSnackbar(`Unsuccessfully upload. Error: ${error}`, 'error');
          }
        } else {
          if (currVid[0].source === cloudLibrary[i].dataURL) {
            outCurrVid = cloudLibrary[i].dataURL;
          }
          filteredCloudLibrary.push(cloudLibrary[i]);
        }
      }
    }
    _dispatch({ type: 'SHOW_PROGRESS_BAR', payload: false });
    console.log('filteredCloudLibrary22', filteredCloudLibrary);
    return {
      currVidURL: outCurrVid || currVid[0].source,
      filteredCloudLibrary: filteredCloudLibrary,
    };
  }

  const validateCustomFormLabels = (formTemplate) => {
    const reservedIds = ['lead_type', 'metadata', 'source', 'actions'];

    // checks that all of the keys do not have ids in the resrevedIds
    // metadata and lead_type are used in LeadActionPanel to display the lead
    return formTemplate?.inputs?.reduce((res, elem) => {
      console.log(elem.id, reservedIds.includes(elem.id));
      if (reservedIds.includes(elem.id)) {
        setNotification(
          `Invalid label. Please do not use label '${elem.label}'`,
          'error'
        );
      }
      return res && !reservedIds.includes(elem.id);
    }, true);
  };

  async function handleUpdateScreen(
    thumbnailImg,
    centerButtons,
    bottomButtons,
    name,
    caption,
    locCard,
    buttons,
    screen,
    category,
    formTemplate,
    additionalInformation,
    iframeOptions,
    shareInfo,
    screenTitle,
    categoryTitle,
    cloudLibrary,
    setCloudLibrary,
    setCloudLibraryProgress,
    screenObj,
    isButtonSeprated,
    videolibrary_option
  ) {
    if (checkIfEligible(category, screen, name, caption) === false) {
      return;
    }

    console.log('formTemplate.inputs', formTemplate);
    console.log(
      'validateCustomFormlabels',
      validateCustomFormLabels(formTemplate)
    );
    if (
      !validateCustomFormLabels(formTemplate) &&
      Object.keys(formTemplate).length !== 0
    )
      return;

    // return

    setCloudLibraryProgress({ uploading: true });

    console.log('name = ', name, 'cloudLibrary= ', cloudLibrary);

    const outData = await uploadToCloudLibrary(
      thumbnailImg,
      screen,
      name,
      category,
      cloudLibrary,
      setCloudLibrary,
      setCloudLibraryProgress,
      caption,
      locCard,
      buttons,
      centerButtons,
      bottomButtons,
      formTemplate,
      additionalInformation,
      iframeOptions,
      shareInfo,
      '',
      videolibrary_option
    );

    handleScreen(
      thumbnailImg,
      'updateScreen',
      name,
      caption,
      locCard,
      outData.currVidURL,
      outData.filteredCloudLibrary,
      buttons,
      centerButtons,
      bottomButtons,
      screen,
      category,
      {
        form: formTemplate,
        additionalInfo: additionalInformation,
        iframe: iframeOptions,
        share_info: shareInfo,
      },
      screenObj,
      isButtonSeprated
    );
    setNotification('Updated screen successfully', 'success');
    setCloudLibraryProgress();
  }

  async function handleCreateScreen(
    thumbnailImg,
    name,
    caption,
    locCard,
    buttons,
    centerButtons,
    bottomButtons,
    screen,
    category,
    formTemplate,
    additionalInformation,
    iframeOptions,
    shareInfo,
    screenTitle,
    categoryTitle,
    cloudLibrary,
    setCloudLibrary,
    setCloudLibraryProgress
  ) {
    if (checkIfEligible(category, screen, name, caption) === false) {
      return;
    }
    if (!validateCustomFormLabels(formTemplate)) {
      return;
    }
    setCloudLibraryProgress({ uploading: true });

    const outData = await uploadToCloudLibrary(
      thumbnailImg,
      screen,
      name,
      category,
      cloudLibrary,
      setCloudLibrary,
      setCloudLibraryProgress,
      caption,
      locCard,
      buttons,
      centerButtons,
      bottomButtons,
      formTemplate,
      additionalInformation,
      iframeOptions,
      shareInfo,
      true
    );

    // new screen is already created in uploadToCloudLibrary just need to update it now
    await handleScreen(
      thumbnailImg,
      'updateScreen',
      name,
      caption,
      locCard,
      outData.currVidURL,
      outData.filteredCloudLibrary,
      buttons,
      centerButtons,
      bottomButtons,
      screen,
      category,
      {
        form: formTemplate,
        additionalInfo: additionalInformation,
        iframe: iframeOptions,
        share_info: shareInfo,
      }
    );

    handleSnackbar('Screen successfully created', 'success');
    setTimeout(async () => {
      _dispatch({
        type: 'SCREEN_TO_EDIT',
        payload: '',
      });
    }, 1000);

    setCloudLibraryProgress();
  }

  function reorderCategories(prop, hoverIndex, dragIndex) {
    if (!prop) {
      const categoryKeysTemp = [...template.category_keys];
      const dragMagnetSummaryRow = categoryKeysTemp[dragIndex];
      categoryKeysTemp.splice(dragIndex, 1);
      categoryKeysTemp.splice(hoverIndex, 0, dragMagnetSummaryRow);
      _dispatch({
        type: 'reorderCategory',
        payload: {
          category_keys_reordered: categoryKeysTemp,
          magnet_id: magnetId,
          magnet_type: magnetType,
        },
      });
      return;
    }
    _dispatch({
      type: 'reorderCategory',
      payload: {
        category_keys_reordered: prop,
        magnet_id: magnetId,
        magnet_type: magnetType,
      },
    });
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarColor}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <div>
        <div className="magnnet-container">
          <Header>
            <div className="dashboard-hdr flex aic">
              <div className="left flex aic">
                <a href={url} target="_blank">
                  <OpenInNewIcon />
                </a>
                &nbsp;&nbsp;
                <Link to="/" className="item flex aic">
                  <div className="txt font s14 b3 anim">{name}</div>
                  {name ? <span className="slash">&nbsp;/&nbsp;</span> : null}
                </Link>
                <div className="item flex aic">
                  <div
                    onClick={() => {
                      _dispatch({
                        type: 'SCREEN_TO_EDIT',
                        payload: '',
                      });
                      window.history.pushState({}, `View ${community_id}`, `#`);
                    }}
                    className="txt font s14 b5 black"
                  >
                    Tour
                  </div>
                  {categoryTitle && (
                    <span className="slash">&nbsp;/&nbsp;</span>
                  )}
                </div>
                {categoryTitle && (
                  <div className="item flex aic">
                    <div className="txt font s14 b5 black">{categoryTitle}</div>
                  </div>
                )}
              </div>
              <div className="right flex aic">
                {!screenToEdit ? (
                  <>
                    {/* <button
                      className="cleanbtn add-category font s14 b5 cfff flex aic"
                      onClick={() => deleteallvideos()}
                    >
                      Delete all videos
                    </button> */}
                    <button
                      className="cleanbtn add-category font s14 b5 cfff flex aic"
                      // onClick={() => setOpenCategoryDialog(true)}
                      onClick={() => {
                        if(!accessLevel) {
                         return toast.error("Please log in to your community")
                        }
                        _dispatch({
                          type: 'PREVIEW_TEMPLATE',
                          payload: {
                            ...previewTemplate,
                            showTemplatesDialog: true,
                          },
                        });
                      }}
                    >
                      See Templates
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="cleanbtn embed-code font s14 b5 cfff flex aic"
                      onClick={() => setShowEmbedVideoDialog(true)}
                    >
                      {'</>'}
                    </button>
                    <button
                      className="cleanbtn add-category font s14 b5 cfff flex aic"
                      onClick={() => setShowShareVideoDialog(true)}
                    >
                      Share Video
                    </button>
                  </>
                )}
              </div>
            </div>
          </Header>
          {!screenToEdit ? (
            <>
              {/* <div className="apart-intro-slider">
                <IntroSlider />
              </div> */}
              <MagnetSummary
                communityName={name}
                communityId={community_id}
                currVid={currVid}
                setCurrVid={setCurrVid}
                magnetLoading={magnetLoading}
                reorderCategories={reorderCategories}
                setOpenCategoryDialog={setOpenCategoryDialog}
              />
            </>
          ) : (
            <Upload
              communityName={name}
              currVid={currVid}
              setCurrVid={setCurrVid}
              handleUpdateScreen={handleUpdateScreen}
              handleCreateScreen={handleCreateScreen}
              newUploadHasStarted={newUploadHasStarted}
              setNewUploadHasStarted={setNewUploadHasStarted}
              loadScreenTextIndex={loadScreenTextIndex}
              setLoadScreenTextIndex={setLoadScreenTextIndex}
              setShowShareVideoDialog={setShowShareVideoDialog}
              showShareVideoDialog={showShareVideoDialog}
              setShowEmbedVideoDialog={setShowEmbedVideoDialog}
              showEmbedVideoDialog={showEmbedVideoDialog}
            />
          )}
        </div>
      </div>

      <NotificationBar
        buttonTitle={screenToEdit == '' ? 'Refresh' : 'Done'}
        customBtnHandler={() => {
          if (screenToEdit == '') {
            window.location.reload(false);
          } else {
            _dispatch({
              type: 'SCREEN_TO_EDIT',
              payload: '',
            });
            window.history.pushState({}, `View ${screenToEdit}}`, `#`);
          }
        }}
        floating={true}
        floatingBottom="5vh"
      />
    </>
  );
};

export default ManageMagnet;
