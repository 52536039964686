import React from 'react';
import sub1 from '../../assets/sub1.png';
import sub2 from '../../assets/sub2.png';
import sub3 from '../../assets/sub3.png';
import sub4 from '../../assets/sub4.png';
const Subscribe = () => {
  return (
    <div className=" w-full py-5" style={{ background: '#86F2AA' }}>
      <div className=" w-full flex items-center justify-center flex-col gap-3">
        <h1 className=" text-xl text-center w-full  lg:w-96  font-bold text-gray-900">
          Subscribe to an expert video team for the cost of one inexperienced
          in-house video editor.
        </h1>
        <button className=" px-4 text-sm hover:bg-nhvr bg-nvr font-medium py-2 rounded-md text-white">
          View Pricing
        </button>
        <p className=" text-xs py-4">Imagine videos that:</p>
        <div className=" flex items-center gap-5">
          <div className=" w-96 p-3 bg-white shadow-md flex items-center gap-2 rounded-md">
            <img src={sub1} className=" w-6 object-contain" alt="" />
            <p className=" text-sm text-gray-700 font-medium">
              Get you more leads
            </p>
          </div>
          <div className=" w-96 p-3 bg-white shadow-md flex items-center gap-2 rounded-md">
            <img src={sub2} className=" w-6 object-contain" alt="" />
            <p className=" text-sm text-gray-700 font-medium">
              Don’t burn a hole in your pocket!
            </p>
          </div>
        </div>
        <div className=" flex items-center gap-5 mt-2">
          <div className=" w-96 p-3 bg-white shadow-md flex items-center gap-2 rounded-md">
            <img src={sub3} className=" w-6 object-contain" alt="" />
            <p className=" text-sm text-gray-700 font-medium">
              Look stunning and catch audience’s attention
            </p>
          </div>
          <div className=" w-96 p-3 bg-white shadow-md flex items-center gap-2 rounded-md">
            <img src={sub4} className=" w-6 object-contain" alt="" />
            <p className=" text-sm text-gray-700 font-medium">
              Help you scale you business effectively
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
