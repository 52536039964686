import React from 'react';

export default function PropertyBanner({ property, summary, price, size }) {
  console.log(`PRICE IS: ${price}`, typeof price);
  return (
    <div class="header-dark">
      <div class="share-video-container">
        <div class="property-title-wrap">
          <div class="header-content-left">
            <div class="property-detail-text">{property}</div>
            <div class="property-text-light">{summary}</div>
          </div>
          <div class="header-content-left">
            {price && price !== 'null' && price !== '0' && (
              <div class="property-detail-text">
                <span>
                  ${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/mo
                </span>
              </div>
            )}
            <div class="property-text-light">{size}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
