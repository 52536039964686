const Pagination = ({ page, setPage, perPage, count }) => {
  const totalLinks = Math.ceil(count / perPage); // 10 / 5 = 2
  let startLoop = page - 1;

  let diff = totalLinks - page;
  if (diff <= 3) {
    startLoop = totalLinks - 3;
  }

  let endLoop = startLoop + 3;
  if (startLoop <= 0) {
    startLoop = 1;
  }

  const links = () => {
    const allLinks = [];
    for (let i = startLoop; i <= endLoop; i++) {
      allLinks.push(
        <li key={i}>
          <div
            className={`pagination-btn ${page === i && 'active'}`}
            onClick={() => setPage(i)}
          >
            {i}
          </div>
        </li>
      );
    }
    return <div className="pagination-links">{allLinks}</div>;
  };

  console.log('count...', count);

  const next = () => {
    return (
      <>
        <button
          disabled={page >= totalLinks}
          className="pagination-btn"
          onClick={() => setPage(page + 1)}
        >
          <i className="icon-chevron-right"></i>
        </button>
        <button
          disabled={page >= totalLinks}
          className="pagination-btn"
          onClick={() => setPage(totalLinks)}
        >
          Last
        </button>
      </>
    );
  };

  const prev = () => {
    return (
      <>
        <button
          className="pagination-btn"
          disabled={page <= 1}
          onClick={() => setPage(1)}
        >
          First
        </button>
        <button
          className="pagination-btn"
          disabled={page <= 1}
          onClick={() => setPage(page - 1)}
        >
          <i className="icon-chevron-left"></i>
        </button>
      </>
    );
  };

  return (
    count > 3 && (
      <div className="pagination flex aic">
        {prev()}
        {links()}
        {next()}
      </div>
    )
  );
};

export default Pagination;
