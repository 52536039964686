import React, { useEffect, useRef, useState, useMemo } from 'react';
import { API_HOST } from '../../../utils/request';
import { StarIcon } from '../../talk-and-triggers/Icons';
import { Bookmark, BookmarkBorder } from '@material-ui/icons';
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  withStyles,
  InputBase,
} from '@material-ui/core';
import { supabase } from '../../../services/supabase';
import { Pagination, Skeleton } from '@material-ui/lab';
import { debounce, isEmpty } from 'lodash';

const REVIEWS_PER_PAGE = 5;

const BorderlessInput = withStyles((theme) => ({
  input: {
    border: 0,
    padding: 0,
  },
}))(InputBase);

export default function FeaturedReviewSelection({
  reviews,
  reviewMetadata,
  setReviewMetadata,
  saveReviewMetadata,
  fetchMoreReviews,
  allReviewsFetched,
  sortingMethod,
  setSortingMethod,
}) {
  const [pageNumber, setPageNumber] = useState(1);
  const [featuredPageNumber, setFeaturedPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  // emulate useState for featuredReviews
  const featuredReviews = reviewMetadata?.featured ?? [];
  const setFeaturedReviews = (newValue) => {
    if (typeof newValue === 'function') {
      setReviewMetadata((oldReviewMetadata) => ({
        ...oldReviewMetadata,
        featured: newValue(oldReviewMetadata.featured),
      }));
    } else {
      setReviewMetadata((oldReviewMetadata) => ({
        ...oldReviewMetadata,
        featured: newValue,
      }));
    }
  };

  function handleReviewClick(review) {
    const exsitingFeaturedReviewIndex = featuredReviews?.findIndex(
      (r) => r.id === review.id
    );
    if (exsitingFeaturedReviewIndex !== -1) {
      const newFeaturedReviews = [...featuredReviews];
      newFeaturedReviews.splice(exsitingFeaturedReviewIndex, 1);
      setFeaturedReviews(newFeaturedReviews);
    } else {
      makeReviewFeatured(review);
    }
  }

  function makeReviewFeatured(review) {
    setFeaturedReviews([...featuredReviews, review]);
  }

  const debouncedSave = useMemo(
    () => debounce(saveReviewMetadata, 1000),
    [saveReviewMetadata]
  );

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  useEffect(() => {
    if (!isEmpty(reviewMetadata)) debouncedSave();
  }, [featuredReviews]);

  const currentFeaturedReviewsPage =
    featuredReviews
      ?.slice((featuredPageNumber - 1) * 5, featuredPageNumber * 5)
      ?.map((review) => (
        <ReviewCard
          review={review}
          key={review.id}
          onClick={() => handleReviewClick(review)}
          isFeatured={true}
        />
      )) ??
    'Click the bookmark button on any review to feature it. Featured reviews will be shown in Review CTA Bubbles.';

  const currentReviewsPage = reviews
    ?.slice((pageNumber - 1) * REVIEWS_PER_PAGE, pageNumber * REVIEWS_PER_PAGE)
    ?.map((review) => (
      <ReviewCard
        review={review}
        key={review.id}
        onClick={() => handleReviewClick(review)}
        isFeatured={featuredReviews?.find(({ id }) => id === review.id)}
      />
    ));

  const totalReviewPages = Math.ceil(reviews?.length / REVIEWS_PER_PAGE);

  return (
    <div className="w-full grid grid-cols-2 gap-2">
      <div className="flex flex-col gap-2 flex-1 items-center">
        <div className="text-xl">Featured Reviews</div>
        <div
          className="overflow-scroll flex flex-col gap-2 items-center"
          style={{ maxHeight: '800px' }}
        >
          {isEmpty(reviewMetadata) ? (
            <>
              {Array(2)
                .fill(null)
                .map((_, index) => (
                  <Skeleton
                    variant="rect"
                    width={512}
                    height={256}
                    key={index}
                  />
                ))}
            </>
          ) : (
            currentFeaturedReviewsPage
          )}
          {(featuredReviews?.length ?? 0) > REVIEWS_PER_PAGE && (
            <Pagination
              count={Math.ceil(featuredReviews?.length / REVIEWS_PER_PAGE)}
              page={featuredPageNumber}
              onChange={(_, newPageNumber) =>
                setFeaturedPageNumber(newPageNumber)
              }
            />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-2 flex-1 items-center">
        <Select
          value={sortingMethod}
          variant="outlined"
          onChange={(e) => {
            if (e.target.value !== sortingMethod)
              setSortingMethod(e.target.value);
          }}
          input={<BorderlessInput />}
        >
          <MenuItem value="timeAscending">
            <div className="text-xl hover:text-pr cursor-pointer">
              Oldest Reviews
            </div>
          </MenuItem>
          <MenuItem value="timeDescending">
            <div className="text-xl hover:text-pr cursor-pointer">
              Newest Reviews
            </div>
          </MenuItem>
          <MenuItem value="ratingAscending">
            <div className="text-xl hover:text-pr cursor-pointer">
              Worst Reviews
            </div>
          </MenuItem>
          <MenuItem value="ratingDescending">
            <div className="text-xl hover:text-pr cursor-pointer">
              Best Reviews
            </div>
          </MenuItem>
        </Select>

        <div
          className="overflow-scroll flex flex-col gap-2 items-center"
          style={{ maxHeight: '800px' }}
        >
          {isEmpty(reviews) ? (
            <>
              {Array(4)
                .fill(null)
                .map((_, index) => (
                  <Skeleton
                    variant="rect"
                    width={440}
                    height={320}
                    key={index}
                  />
                ))}
            </>
          ) : (
            <>
              {currentReviewsPage}
              {pageNumber === totalReviewPages && !allReviewsFetched() && (
                <button
                  className="rounded-full bg-gray-500 text-white hover:bg-gray-300 px-4 py-2"
                  onClick={fetchMoreReviews}
                >
                  show more
                </button>
              )}
            </>
          )}
          {reviews && (
            <Pagination
              count={totalReviewPages}
              page={pageNumber}
              onChange={(_, newPageNumber) => setPageNumber(newPageNumber)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function ReviewCard({ review, onClick = () => {}, isFeatured }) {
  return (
    <div className="flex border-2 border-gray-300 rounded-xl w-full">
      <Button
        className="flex items-center justify-center pointer p-3 ease-out transition-colors duration-150"
        style={{
          backgroundColor: isFeatured
            ? 'rgba(77, 138, 229, .1)'
            : 'rgba(0, 0, 0, .1)',
        }}
        onClick={onClick}
      >
        {isFeatured ? (
          <Bookmark style={{ color: '#4D8AE5' }} />
        ) : (
          <BookmarkBorder style={{ color: '#000000' }} />
        )}
      </Button>
      <div className="flex flex-col gap-2 p-2 w-full">
        <div className="w-full flex justify-between items-center">
          <div className="font-bold">{review.author_title}</div>
          <div className="flex gap-1 mr-2">
            <StarIcon />
            <StarIcon
              fillColor={review.review_rating >= 2 ? '#feb12c' : '#e5e5e5'}
            />
            <StarIcon
              fillColor={review.review_rating >= 3 ? '#feb12c' : '#e5e5e5'}
            />
            <StarIcon
              fillColor={review.review_rating >= 4 ? '#feb12c' : '#e5e5e5'}
            />
            <StarIcon
              fillColor={review.review_rating === 5 ? '#feb12c' : '#e5e5e5'}
            />
          </div>
        </div>
        <div>{review.review_text}</div>
      </div>
    </div>
  );
}
