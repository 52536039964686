import axios from 'axios';
import firebase from '../../utils/base';
import { API_HOST } from '../../utils/request';

export const getMagnetData = (community_id) => async (dispatch) => {
  try {
    dispatch({ type: 'GET_MAGNET_REQUEST' });

    const authToken = await firebase.user().getIdToken(true);

    const { data } = await axios.get(
      `${API_HOST}/magnets?queryByCommunity=${community_id}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
      }
    );

    dispatch({
      type: 'GET_MAGNET_SUCCESS',
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: 'GET_MAGNET_FAIL',
      payload: `Failed to make request: ${error}`,
    });
  }
};

export const getScreenObj = (template, category, screen) => {
  return template.categories[category].screens[screen];
};

export const getScreenButtons = (template, category, screen) => {
  return template.categories[category].screens[screen]?.links;
};
