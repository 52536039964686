import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { mergeNestedArray } from '../../utils/eventUtils';

const UploadVideoImport = () => {
  const { form_id } = useParams();

  console.log('form_id', form_id);
  const BASE_URL = 'https://share.tour.video';

  const [recordedVideos, sedRecordedVideos] = useState([
    // {
    //   video:
    //     'https://images.hypno.com/7/9_qwhnD2s15t/9_qwhnD2s15t_750.jpg?width=300',
    //   counter: 0,
    //   stemp: '2 days ago',
    // },
    // {
    //   video:
    //     'https://images.hypno.com/7/BMPIe4Q2qxdc/BMPIe4Q2qxdc_750.jpg?width=300',
    //   counter: 0,
    //   stemp: '8 days ago',
    // },
    // {
    //   video:
    //     'https://images.hypno.com/7/XFneHEs2qhso/XFneHEs2qhso_750.jpg?width=300',
    //   counter: 0,
    //   stemp: '14 days ago',
    // },
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
  ]);
  const [openSearchbar, setOpenSearchbar] = useState(false);

  console.log('recordedVideos', recordedVideos);

  useEffect(() => {
    const fetchResponses = async () => {
      const response = await axios.get(
        `https://api.directual.com/good/api/v5/data/videoresponses/crudResponse?appID=8c488cc7-adf1-4a94-b9ca-c765230cfd13&formId=${form_id}`
      );

      console.log('response', response);

      const data = mergeNestedArray(
        response?.data?.payload.map((item) => {
          let data = JSON.parse(item?.response)?.questions.map((videoData) => {
            return {
              ...videoData,
              name: item?.name,
              email: item?.email,
              id: item?.id,
            };
          });

          return data;
        })
      );
      sedRecordedVideos(data);
    };
    fetchResponses().catch((error) => console.log(error));
  }, [form_id]);

  const handlePreview = (id, index) => {
    window.open(`${BASE_URL}/testimonial-preview?id=${id}&index=${index}`);
  };

  const handleRecordVideo = () => {
    window.open(`${BASE_URL}/collect-testimonial?formId=${form_id}`);
  };

  return (
    <div className="upload-video-import rel">
      <div className="nav-blk flex flex-col fixed">
        <div className="meta">
          <div className="nam font s38 b6 color"> Amulya </div>
          <div className="font sub-txt"> blessed </div>
          <div
            onClick={handleRecordVideo}
            // to={`${BASE_URL}/collect-testimonial?formId=${form_id}`}
            className="record-btn font s16"
          >
            Record a video➔
          </div>
        </div>
        <div className="nav-ftr flex aic">
          <div className="copy-rit flex aic">
            <div className="lbl font c000 b6 s15"> Made with </div>
            <div className="txt font s15 cfff"> tour.video </div>
          </div>
        </div>
      </div>
      <div className="videos-collection rel">
        {recordedVideos &&
          recordedVideos?.map((item, idx) => (
            <div
              onClick={() => handlePreview(item?.id, item?.index)}
              key={idx}
              className={`item rel ${
                item?.url && openSearchbar == false ? 'video' : 'empty'
              }`}
            >
              <video
                src={item?.url}
                // className="img"
              />
              <div className="upper-right abs">
                <button className="cleanbtn del-btn flex aic">
                  <div className="ico icon-delete2 cfff" />
                </button>
              </div>
              <div className="lower-left abs name">
                <div className="stemp font s12 b4 cfff"> {item?.name} </div>
              </div>
              <div className="lower-left abs">
                <div className="stemp font s12 b4 cfff"> {item?.email} </div>
              </div>
              {/* <button className="cleanbtn add-btn">
                                  <img
                                    src={
                                      require('../../assets/upload-video-import/btn-add.svg')
                                        .default
                                    }
                                    className="ico"
                                  />
                                </button> */}
            </div>
          ))}
        {/* Search Button */}
        <div
          className={`cleanbtn search-bar flex aic ${
            openSearchbar ? 'active' : ''
          }`}
        >
          {openSearchbar ? (
            <button
              className="cleanbtn"
              onClick={() => setOpenSearchbar(!openSearchbar)}
            >
              <img
                src={
                  require('../../assets/upload-video-import/close.svg').default
                }
                className="ico"
              />
            </button>
          ) : (
            <button
              className="cleanbtn"
              onClick={() => setOpenSearchbar(!openSearchbar)}
            >
              <img
                src={
                  require('../../assets/upload-video-import/search.svg').default
                }
                className="ico"
              />
            </button>
          )}
          <input type="text" placeholder="Search" className="iput font" />
        </div>
      </div>
    </div>
  );
};
export default UploadVideoImport;
