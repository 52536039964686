import { Divider, FormControlLabel, Grid, Switch } from '@material-ui/core';
import React, { memo, useState } from 'react';
import DebouncedTextField from '../../../components/Reusable/DebouncedTextField';
import NewQuestion, { UNIQUE_TYPES } from './NewQuestion';
import './Settings.css';
import Slider from 'react-slick';
import calendly from '../../../assets/calendly.png';
import metaport from '../../../assets/metaport.png';
import youtube from '../../../assets/youtube.png';
import { useEffect } from 'react';
const iframeData = [
  {
    id: 1,
    name: 'Your interests',
    src: 'https://app.usetour.com/cta/floorplans/integration/0baf7485-4dde-4772-a14e-1d0fe0108561?type=inperson&timezone=EST&start=1&end=24&entratauser=leasemagnets@peakmade&entratapass=L3as3Magn3ts&entratapropertyId=639744',
    desc: 'Help us deliver the best tour by answering some questions',
  },
  {
    id: 2,
    name: 'NPS Form',
    src: 'https://app.usetour.com/cta/floorplans/integration/0baf7485-4dde-4772-a14e-1d0fe0108561?type=inperson&timezone=EST&start=1&end=24&entratauser=leasemagnets@peakmade&entratapass=L3as3Magn3ts&entratapropertyId=639744',
    desc: 'Find out how your customers feel about your business',
  },
  {
    id: 3,
    name: 'Scheduler',
    src: 'https://app.usetour.com/cta/scheduler/integration/2',
    desc: 'Easily create appointments and schedule meetings',
  },
  {
    id: 4,
    name: 'Referral',
    src: 'https://app.usetour.com/cta/scheduler/integration/2',
    desc: 'Easily create and share a referral form',
  },
  {
    id: 5,
    name: 'Share your tour',
    src: 'https://app.usetour.com/cta/scheduler/integration/2',
    desc: 'Easy way to share you your experience',
  },
  {
    id: 6,
    name: 'Contact Us',
    src: 'https://app.usetour.com/cta/contactus/integration/2',
    desc: 'Create a contact form',
  },

  // {
  //   name: 'Nearby Businesses (Maps)',
  //   src: ' https://calendly.com/amulyaparmar/15min',
  //   desc: 'Showcase some businesses, transportation, restaurants etc that we can showcase.',
  // },
];

const FormSettings = ({ formTemplate, setFormTemplate }) => {
  const usedUniqueTypes = formTemplate?.inputs
    ?.map((question) => question?.type)
    .filter((type) => UNIQUE_TYPES.includes(type));

  const contact = formTemplate?.contact || {}; // `formTemplate.contact` may be undefined
  const setContact = (newContact) =>
    setFormTemplate((formTemplate) => ({
      ...formTemplate,
      contact: newContact,
    }));

  function setQuestions(updatedFormQuestions) {
    setFormTemplate((formTemplate) => ({
      ...formTemplate,
      inputs: updatedFormQuestions,
    }));
  }

  function isIdUsed(checkId) {
    return formTemplate?.inputs.map((input) => input.id).includes(checkId);
  }

  // Handle add question button click
  function addQuestion(questionTemplate = {}) {
    const existingIds = formTemplate?.inputs.map((input) => input.id);
    let newId = undefined;
    while (!newId || existingIds.includes(newId)) {
      newId = Math.floor(Math.random() * 7237 * (Math.random() * 23));
    }

    const newQuestion = {
      label: '',
      type: 'text',
      id: newId,
      // Note that some types are locked to "required" or "not required" (text isn't)
      required: false,
      options: undefined,
      ...questionTemplate,
    };

    setQuestions([...formTemplate.inputs, newQuestion]);
  }

  async function addDefaultFormTemplate() {
    setFormTemplate({
      ...formTemplate,
      inputs: [
        {
          label: 'Your Name',
          type: 'lead_name',
          id: 'lead_name',
          // Note that some types are locked to "required" or "not required" (text isn't)
          required: true,
        },
        {
          label: 'Your Email',
          type: 'lead_email',
          id: 'lead_email',
          // Note that some types are locked to "required" or "not required" (text isn't)
          required: true,
        },
        {
          label: 'Your Phone',
          type: 'lead_phone',
          id: 'lead_phone',
          // Note that some types are locked to "required" or "not required" (text isn't)
          required: true,
        },
      ],
      opacity: 1.0,
      text_color: '#000',
    });
  }
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  const [activeSetup, setActiveSetup] = useState(1);

  const defaultSetupMessage = (option) => {
    if (option === 1) {
      setFormTemplate((formTemplate) => ({
        ...formTemplate,
        text_data: {
          ...formTemplate.text_data,
          title: 'Your interests',
          body_text: 'Please let us know about your interest.',
        },
      }));
    }
    if (option === 2) {
      setFormTemplate((formTemplate) => ({
        ...formTemplate,
        text_data: {
          ...formTemplate.text_data,
          title: 'NPS Form',
          body_text: 'Please give us your feedback.',
        },
      }));
    }
    if (option === 3) {
      setFormTemplate((formTemplate) => ({
        ...formTemplate,
        text_data: {
          ...formTemplate.text_data,
          title: 'Your time is important Schedule a tour ',
          body_text: 'Please select a date and time',
        },
      }));
    }
    if (option === 4) {
      setFormTemplate((formTemplate) => ({
        ...formTemplate,
        text_data: {
          ...formTemplate.text_data,
          title: 'Ask a referral',
          body_text: 'Please fill out the following details',
        },
      }));
    }
    if (option === 5) {
      setFormTemplate((formTemplate) => ({
        ...formTemplate,
        text_data: {
          ...formTemplate.text_data,
          title: 'Share the tour with friends, family and colleagues',
        },
      }));
      setFormTemplate({
        ...formTemplate,
        text_data: {
          ...formTemplate.text_data,
          body_text: 'Please fill out the details to share ',
        },
      });
    }
    if (option === 6) {
      setFormTemplate((formTemplate) => ({
        ...formTemplate,
        text_data: {
          ...formTemplate.text_data,
          title: 'Contact us',
        },
      }));
      setFormTemplate({
        ...formTemplate,
        text_data: {
          ...formTemplate.text_data,
          body_text: 'Please fill out this contact form.',
        },
      });
    }
  };
  // useEffect(() => {
  //   setFormTemplate((formTemplate) => ({
  //     ...formTemplate,
  //     text_data: {
  //       ...formTemplate.text_data,
  //       title: 'Your interests',
  //       body_text: 'Please let us know about your interest.',
  //     },
  //   }));
  // }, []);

  return (
    <div
      className="advanced-settings-form"
      key={`${formTemplate?.text_data?.title}${formTemplate?.text_data?.body_text} ${formTemplate?.opacity} ${formTemplate?.text_color}`}
    >
      <div>
        <p className=" mt-4 text-sm text-gray-400">Suggested Forms</p>
      </div>

      {/* <Grid container spacing={1}>
        <div className=" w-full my-4">
          <Slider {...settings}>
            {iframeData.map((item) => (
              <div className=" w-full h-44 border p-3 rounded-xl">
                <div className=" flex items-center gap-2">
                  {item.name === 'Youtube Video' && (
                    <img
                      src={youtube}
                      className=" w-8 h-8 object-contain"
                      alt=""
                    />
                  )}
                  {item.name === 'Matterport' && (
                    <img
                      src={metaport}
                      className=" w-8 h-8 object-contain"
                      alt=""
                    />
                  )}
                  {item.name === 'Calendly' && (
                    <img
                      src={calendly}
                      className=" w-8 h-8 object-contain"
                      alt=""
                    />
                  )}
                  <p>{item.name}</p>
                </div>
                <p className=" py-3 text-sm text-gray-500">{item.desc}</p>
                <div className=" flex items-center justify-end gap-3">
                  <div
                    onClick={() => {
                      defaultSetupMessage(item?.id);
                      setActiveSetup(item?.id);
                    }}
                    className={`${
                      activeSetup === item?.id ? 'bg-pr' : 'bg-blue-300'
                    }  px-2 py-2 hover:bg-pr cursor-pointer text-sm text-white rounded-md`}
                  >
                    Setup
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Grid> */}

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={formTemplate.enabled}
                name="isFormTemplateEnabled"
                onChange={(e) => {
                  setFormTemplate({
                    ...formTemplate,
                    enabled: e.target.checked,
                  });
                }}
              />
            }
            label="Enable form on this video"
          />
        </Grid>

        {/* Form Basic settings like Title, Body, opacity, etc */}
        <Grid item xs={12}>
          <div className="form-group flex flex-col">
            <div className="feild-title font s15 b6 c000">Form Title</div>
            <DebouncedTextField
              id="form-title"
              placeholder="Form title"
              variant="outlined"
              size="small"
              value={formTemplate?.text_data?.title}
              onBlur={(e) => {
                console.log('e', e.target.value);
                setFormTemplate({
                  ...formTemplate,
                  text_data: {
                    ...formTemplate.text_data,
                    title: e.target.value,
                  },
                });
              }}
              style={{ width: 'calc(100%)' }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={8}>
          <div className="form-group flex flex-col">
            <div className="feild-title font s15 b6 c000">Form Body</div>
            <DebouncedTextField
              id="form-body"
              placeholder="Details (optional)"
              variant="outlined"
              size="small"
              value={formTemplate?.text_data?.body_text}
              onBlur={(e) => {
                setFormTemplate({
                  ...formTemplate,
                  text_data: {
                    ...formTemplate.text_data,
                    body_text: e.target.value,
                  },
                });
              }}
              style={{ width: 'calc(100%)' }}
            />
          </div>
        </Grid>

        <Grid item xs={6} sm={2}>
          <div className="form-group flex flex-col">
            <div className="feild-title font s15 b6 c000">Opacity</div>
            <DebouncedTextField
              id="form-opacity"
              type="number"
              variant="outlined"
              inputProps={{ min: 0, max: 1, step: 0.1 }}
              size="small"
              value={formTemplate?.opacity}
              onBlur={(e) => {
                setFormTemplate({
                  ...formTemplate,
                  opacity: e.target.value,
                });
              }}
              style={{ width: 'calc(100%)' }}
            />
          </div>
        </Grid>

        <Grid item xs={6} sm={2}>
          <div className="form-group flex flex-col">
            <div className="feild-title font s15 b6 c000">Text Color</div>
            <DebouncedTextField
              id="form-text-color"
              type="color"
              variant="outlined"
              value={formTemplate?.text_color}
              size="small"
              onChange={(newValue) => {
                setFormTemplate({
                  ...formTemplate,
                  text_color: newValue,
                });
              }}
              style={{ width: 'calc(100%)' }}
            />
          </div>
        </Grid>
      </Grid>

      <br />
      <Divider />
      <br />

      {formTemplate?.inputs?.map(
        (question, idx) =>
          question && (
            <NewQuestion
              question={question}
              usedUniqueTypes={usedUniqueTypes}
              setQuestion={(updatedQuestion) => {
                const temp = formTemplate.inputs;
                temp[idx] = updatedQuestion;
                setQuestions(temp);
              }}
              deleteQuestion={() => {
                const temp = formTemplate.inputs.filter((_, i) => i !== idx);
                setQuestions(temp);
              }}
              key={question.id}
              isIdUsed={isIdUsed}
            />
          )
      )}

      <button onClick={() => addQuestion()}>+ Add new question</button>
      {!formTemplate?.inputs?.length && (
        <button className="ml-2" onClick={addDefaultFormTemplate}>
          {' '}
          + Default Form
        </button>
      )}

      <br />
      <Divider />
      <br />
      {/* Contact info for confrence TODO either remove or make much better */}
      <Grid container>
        {contact.enabled && (
          <>
            <Grid item xs={12}>
              <DebouncedTextField
                id="contact name"
                placeholder="Required: Name (or Full Name)"
                variant="outlined"
                value={contact?.name}
                size="small"
                onChange={(newValue) =>
                  setContact({
                    ...contact,
                    name: newValue,
                  })
                }
                style={{ width: 'calc(100%)' }}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                id="profile picture"
                placeholder="Profile Picture (URL)"
                variant="outlined"
                size="small"
                value={contact?.profile_picture}
                onChange={(newValue) =>
                  setContact({
                    ...contact,
                    profile_picture: newValue,
                  })
                }
                style={{ width: 'calc(100%)' }}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                id="contact email"
                placeholder="Email"
                variant="outlined"
                size="small"
                value={contact?.email}
                onChange={(newValue) =>
                  setContact({
                    ...contact,
                    email: newValue,
                  })
                }
                style={{ width: 'calc(100%)' }}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                id="contact phone"
                placeholder="Phone"
                variant="outlined"
                value={contact?.phone}
                size="small"
                onChange={(newValue) =>
                  setContact({
                    ...contact,
                    phone: newValue,
                  })
                }
                style={{ width: 'calc(100%)' }}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                id="contact website"
                placeholder="Website"
                variant="outlined"
                value={contact?.website}
                size="small"
                onChange={(newValue) =>
                  setContact({
                    ...contact,
                    website: newValue,
                  })
                }
                style={{ width: 'calc(100%)' }}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                id="contact linkedin"
                placeholder="LinkedIn (url)"
                variant="outlined"
                value={contact?.linkedin}
                size="small"
                onChange={(newValue) =>
                  setContact({
                    ...contact,
                    linkedin: newValue,
                  })
                }
                style={{ width: 'calc(100%)' }}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                id="contact company"
                placeholder="Company"
                variant="outlined"
                value={contact?.org}
                size="small"
                onChange={(newValue) =>
                  setContact({
                    ...contact,
                    org: newValue,
                  })
                }
                style={{ width: 'calc(100%)' }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default memo(FormSettings);
