import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { AiOutlineClose } from 'react-icons/ai';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '5px',
  },
}));
export function EditBottomButton({
  routeOpts,
  editingButtonId,
  bottomBarButtons,
  config,
  setConfig,
  saveChatbot,
  setEditingButtonId,
  setBottomBarButtons,
  removeButton,
}) {
  let thisButton = bottomBarButtons.find(
    (button) => button.id === editingButtonId
  );
  const actionType = thisButton.hasOwnProperty('iframe_underlay')
    ? 'iframe_underlay'
    : thisButton.hasOwnProperty('route')
    ? 'route'
    : 'href';

  const classes = useStyles();

  return (
    // <div id="outside"
    //     className="fixed top-0 left-0 z-50 flex h-screen w-screen items-center justify-center  backdrop-blur-[2px]"
    //     onClick={(e) => {
    //         if (e.target.id === "outside") {
    //             setEditingButtonId(null);
    //         }
    //     }}>

    // </div>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={editingButtonId !== null}
      onClose={() => {
        setEditingButtonId(null);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={editingButtonId !== null}>
        <div className="flex px-3 w-2/6 border border-gray-300   flex-col rounded-xl bg-white pt-6 pb-6 relative">
          <div className=" flex items-center justify-between border-b mb-4 pb-2">
            <h6 className=" font-semibold text-sm uppercase">
              Customize your button
            </h6>
            <AiOutlineClose
              onClick={() => {
                setEditingButtonId(null);
              }}
              className=" w-6 h-6 cursor-pointer"
            />
          </div>
          <div className=" font-semibold">Button Label</div>
          <TextField
            value={thisButton.text}
            onChange={(e) => {
              e.persist();
              setBottomBarButtons((bottomBarButtons) => {
                return bottomBarButtons.map((button) => {
                  if (button.id === editingButtonId) {
                    return { ...button, text: e.target.value };
                  }
                  return button;
                });
              });
            }}
            className=""
            placeholder="Button Title"
          />
          <div className="mt-3 font-semibold">Button Action</div>
          <div className="flex flex-row border border-gray-300 w-full h-10 rounded-xl mb-3  overflow-hidden">
            <button
              onClick={() => {
                setBottomBarButtons((bottomBarButtons) => {
                  return bottomBarButtons.map((bottomBarButton) => {
                    if (bottomBarButton.id === editingButtonId) {
                      delete bottomBarButton.route;
                      delete bottomBarButton.href;

                      return { ...bottomBarButton, iframe_underlay: '' };
                    }
                    return bottomBarButton;
                  });
                });
              }}
              style={{
                backgroundColor:
                  actionType === 'iframe_underlay' ? 'lightgray' : 'white',
              }}
              className="w-1/3 h-10 grid place-items-center text-sm font-medium"
            >
              Embed Url
            </button>
            <button
              onClick={() => {
                setBottomBarButtons((bottomBarButtons) => {
                  return bottomBarButtons.map((bottomBarButton) => {
                    if (bottomBarButton.id === editingButtonId) {
                      delete bottomBarButton.route;
                      delete bottomBarButton.iframe_underlay;
                      return { ...bottomBarButton, href: '' };
                    }
                    return bottomBarButton;
                  });
                });
              }}
              style={{
                backgroundColor: actionType === 'href' ? 'lightgray' : 'white',
              }}
              className="w-1/3 h-10 grid place-items-center text-sm font-medium"
            >
              Open in New Tab
            </button>
            <button
              onClick={() => {
                setBottomBarButtons((bottomBarButtons) => {
                  return bottomBarButtons.map((bottomBarButton) => {
                    if (bottomBarButton.id === editingButtonId) {
                      delete bottomBarButton.href;
                      delete bottomBarButton.iframe_underlay;
                      return { ...bottomBarButton, route: routeOpts[0] };
                    }
                    return bottomBarButton;
                  });
                });
              }}
              style={{
                backgroundColor: actionType === 'route' ? 'lightgray' : 'white',
              }}
              className="w-1/3 h-10 grid place-items-center text-sm font-medium"
            >
              Go to Route
            </button>
          </div>

          {actionType === 'route' ? (
            <>
              <p className=" font-semibold">Select A Route</p>

              <select
                value={
                  bottomBarButtons.find(
                    (button) => button.id === editingButtonId
                  ).route
                }
                onChange={(e) => {
                  e.persist();
                  console.log(e.target.value);
                  setBottomBarButtons((bottomBarButtons) => {
                    return bottomBarButtons.map((bottomBarButton) => {
                      if (bottomBarButton.id === editingButtonId) {
                        return { ...bottomBarButton, route: e.target.value };
                      }
                      return bottomBarButton;
                    });
                  });
                }}
                className="mb-3 border text-sm border-gray-300 rounded-md py-2 px-1"
              >
                {routeOpts.map((opt) => {
                  return <option value={opt.route}>{opt.id}</option>;
                })}
              </select>
            </>
          ) : null}

          {actionType !== 'route' ? (
            <>
              <p className=" font-semibold">Button Destination:</p>

              <input
                value={
                  bottomBarButtons.find(
                    (button) => button.id === editingButtonId
                  )[actionType]
                }
                onChange={(e) => {
                  console.log(e.target.value);
                  e.persist();
                  setBottomBarButtons((bottomBarButtons) => {
                    return bottomBarButtons.map((bottomBarButton) => {
                      if (bottomBarButton.id === editingButtonId) {
                        return {
                          ...bottomBarButton,
                          [actionType]: e.target.value,
                        };
                      }
                      return bottomBarButton;
                    });
                  });
                }}
                className="mb-3 border border-gray-300 text-sm rounded-md py-2 px-1"
              >
                {/* {routeOpts.map(opt => {
  
                                          return (<option value={opt.route}>
                                              {opt.id}
                                          </option>)
                                      })} */}
              </input>
            </>
          ) : null}

          {/* <RouteInput
                      routes={routeOpts}
                      enableHrefUnderlay={true}
                      onChange={(value) => setNewRouteInput(value)}
                      value={newRouteInput || ''}
                  /> */}
          <div>
            <p className="mb-1  font-semibold">Choose An Icon</p>
            <div className="flex flex-row p-2 items-center border rounded bg-gray-50 gap-2">
              <div
                className={`${
                  thisButton.icon === 'money'
                    ? 'bg-blue-200 p-1 rounded-xl'
                    : ''
                }`}
                onClick={() => {
                  setBottomBarButtons((bottomBarButtons) => {
                    return bottomBarButtons.map((button) => {
                      if (button.id === editingButtonId) {
                        return { ...button, icon: 'money' };
                      }
                      return button;
                    });
                  });
                }}
              >
                <DollarSign
                  className="cursor-pointer text-gray-500 hover:text-black"
                  style={{ width: 28, height: 28 }}
                />
              </div>

              <div
                className={`${
                  thisButton.icon === 'phone'
                    ? 'bg-blue-200 p-1 rounded-xl'
                    : ''
                }`}
                onClick={() => {
                  setBottomBarButtons((bottomBarButtons) => {
                    return bottomBarButtons.map((button) => {
                      if (button.id === editingButtonId) {
                        return { ...button, icon: 'phone' };
                      }
                      return button;
                    });
                  });
                }}
              >
                <Phone
                  className="cursor-pointer text-gray-500 hover:text-black"
                  style={{ width: 28, height: 28 }}
                ></Phone>
              </div>

              <div
                className={`${
                  thisButton.icon === 'calendar'
                    ? 'bg-blue-200 p-1 rounded-xl'
                    : ''
                }`}
                onClick={() => {
                  setBottomBarButtons((bottomBarButtons) => {
                    return bottomBarButtons.map((button) => {
                      if (button.id === editingButtonId) {
                        return { ...button, icon: 'calendar' };
                      }
                      return button;
                    });
                  });
                }}
              >
                <Calendar
                  style={{ width: 28, height: 28 }}
                  className="cursor-pointer text-gray-500 hover:text-black"
                ></Calendar>
              </div>
            </div>
          </div>
          <div className=" flex items-center gap-2 mt-3">
            <button
              className="bg-red-500 hover:bg-red-600 active:bg-red-700 w-full py-2 px-4 text-white rounded"
              onClick={() => {
                setEditingButtonId(null);
                removeButton(editingButtonId);
              }}
            >
              Delete
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-600 active:bg-red-700 w-full py-2 px-4 text-white rounded"
              onClick={() => {
                setEditingButtonId(null);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

const DollarSign = ({ style, className }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const Phone = ({ style, className }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
    />
  </svg>
);

const Calendar = ({ style, className }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
    />
  </svg>
);

function getScreenFromTemplateUsingRoute(route, template) {
  try {
    const routeNames = route.split('.');
    const categoryName = routeNames[0];
    const screenName = routeNames[1];

    console.log(
      'ABCD [getScreenFromTemplateUsingRoute]: ',
      routeNames,
      categoryName,
      screenName
    );

    return template?.categories[categoryName]?.screens[screenName];
  } catch (err) {
    console.log('ABCD Error: ', err.message, route);
    return;
  }
}

const defaultPreviewTemplateScreen = {
  caption: 'Welcome!',
  cloudLibrary: [],
  form: {
    enabled: false,
    inputs: [],
    text_data: {
      body_text: '',
      title: '',
    },
  },
  gif: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/299/magnet/f39eb022-5c26-4cfa-b8e0-6fb3d3d1cb84/intro_Main_Intro/intro__1__mp4_1.gif',
  iframe: {
    background_color: '#000000',
    enabled: false,
    icon_color: '#ffffff',
    src: '',
  },
  img: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/299/magnet/f39eb022-5c26-4cfa-b8e0-6fb3d3d1cb84/intro_Main_Intro/intro__1__mp4_1.jpg',
  links: [],
  location_card: 'Where to next{{, {firstName}|}}?',
  share_info: {},
  title: 'Main Intro',
  video:
    'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/299/magnet/f39eb022-5c26-4cfa-b8e0-6fb3d3d1cb84/intro_Main_Intro/intro__1__mp4_1.mp4?isButtonSeprated=true',
};

// const defaultBottomBarButtons = [
//   {
//     text: 'Book Tour',
//     icon: 'calendar',
//     // route: "thank_you.available_floorplans"
//     iframe_underlay:
//       'https://app.usetour.com/cta/scheduler/integration/8e205f7d-e0b0-4d1d-ad2d-a051e7cd83fa?timezone=CST&start=&end=&entratauser=lease_magnets_api@trinitasventures.lcl&entratapass=Leasemagnets1032022^&entratapropertyId=1109467',
//     id: 1,
//   },
//   {
//     text: '234-423-4390',
//     icon: 'phone',
//     // route: "thank_you.schedule_a_tour",
//     // href: "https://www.apple.com"
//     iframe_underlay:
//       'https://app.usetour.com/cta/contactus/integration/8e205f7d-e0b0-4d1d-ad2d-a051e7cd83fa?',
//     id: 2,
//   },
//   {
//     text: 'Availability',
//     icon: 'money',
//     // route: "thank_you.thank_youquestion",
//     iframe_underlay:
//       'https://app.usetour.com/cta/floorplans/integration/8e205f7d-e0b0-4d1d-ad2d-a051e7cd83fa?timezone=&start=&end=&entratauser=lease_magnets_api@trinitasventures.lcl&entratapass=Leasemagnets1032022^&entratapropertyId=1109467',
//     id: 3,
//   },
// ];

const defaultBottomBarButtons = [];
