export const createScreenKey = (str) => {
  /*
    Whenever you create a new screen you will need to create a Screen Key

    VALIDATION
    1. Ensure no other item in the screens for this category have the same name (to prevent )
    2. Cannot have periods or exclamation or any form of punctuation
    3.
    4.
    5. Cant have empty screen -- that can be check if eligible

    The Floor Plan  => the_floor_plan
    The Floor Plan  123  => the_floor_plan__123

    */

  return str
    .trim()
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/\s\s+/g, ' ')
    .replace(/\s/g, '_')
    .toLowerCase()
    .trim();
  //
};

export const createCategoryKey = (str) => {
  return str
    ?.trim()
    ?.replace(/[^a-zA-Z0-9 ]/g, '')
    ?.replace(/\s\s+/g, ' ')
    ?.replace(/\s/g, '_')
    ?.toLowerCase()
    ?.trim();
};

export const createFormInputKey = (str) => {
  console.log('what is str: ', str);
  return str
    .trim()
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/\s\s+/g, ' ')
    .replace(/\s/g, '_')
    .toLowerCase()
    .trim();
};
