// snackbar for the alerts imports
import {
  Button,
  Chip,
  Dialog,
  InputAdornment,
  DialogTitle,
  MenuItem,
  Slide,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DynamicIframdialogue from '../../components/DynamicIframdialogue';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import Customchart from '../../components/Reusable/CustomChart';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import { getMagnetData } from '../../store/actions/magnetActions';
import firebase from '../../utils/base';
import { uploadBlobToStorage } from '../../utils/files';
import SimpleLeads from '../videoembed/SimpleLeadsTable';
import {
  RESIDENT_DASHBOARD,
  TOUR_HOST,
  RESIDENT_HOST,
  useRequest,
  API_HOST,
  post,
  get,
} from '../../utils/request';
import { slugToString } from '../../utils/slug';
import MagnetPopup from './MagnetPopup';
import styles from './MagnetSummary.module.css';
import MagnetSummaryRow from './MagnetSummaryRow.jsx';
import PreviewVideoMagnet from './PreviewVideoMagnet';
import SendRecording from './SendRecording';
import ShareableTour, { SharableTourList } from './ShareableTour';
import TemplatePreviewMessage from './TemplatePreviewMessage';
//import {Customchart} from '../tour-magnet-tabs/Analytics'
//import 'tabler-react/dist/Tabler.css';
// import Chart from 'react-apexcharts';
import { MdOutlineClose, MdPhonelinkRing } from 'react-icons/md';
import {
  RiCalendar2Fill,
  RiShareBoxFill,
  RiShareCircleFill,
  RiVideoChatFill,
} from 'react-icons/ri';
import DragItem from './DragItem';
import { LinkIcon } from '../../components/VideoMagnet/Icons';
import { BsClipboard } from 'react-icons/bs';
import { Autocomplete } from '@material-ui/lab';
import { FaQrcode, FaShareAltSquare } from 'react-icons/fa';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import useGetSupabaseTable from '../../hooks/useGetSupabaseTable';
import {
  generateAndSaveVideoData,
  generateInitialVideoMetadata,
  encodeVideo,
  saveVideoToSupabase,
  supaUpdateVideoScreen,
  supaDeleteVideo,
  subscribeRealtimeUpdates,
  unsubscribeRealtimeUpdates,
  deleteVideoFromCloud,
} from './utils';
import { FiExternalLink, FiHelpCircle, FiPlusCircle } from 'react-icons/fi';
import { HiOutlineVideoCamera } from 'react-icons/hi';
import { AiOutlineCopy, AiOutlineMail } from 'react-icons/ai';
import { MdOutlineTextsms, MdClose } from 'react-icons/md';
import { GoCheck } from 'react-icons/go';
import './MagnetSummary.css';
import TalkAndTriggers from '../talk-and-triggers';
import VideoLibrary from './VideoLibrary';
import Embed from './Embed';
import Analytics3 from '../tour-magnet-tabs/Analytics3';
import MagnetSettings from './MagnetSettings';
import Engage, { OnlineUser, TourRooms } from './Engage';
import SettingDialog from './SettingDialog';
import VerticalLinearStepper from './VerticalLineStepper';
import { IoMdClose } from 'react-icons/io';
import { MagnetSummaryTypewriter } from './MagnetSummaryTypewriter';
import { MdEdit } from 'react-icons/md';
import ChatbotDialog from '../talk-and-triggers/ChatbotDialog';
import { generateID } from '../../core';
import UpdateSpecialOfferSection from './UpdateSpecialOfferSection';
import { SiGooglemeet } from 'react-icons/si';
import VirtualRooms from './VirtualRooms';
import { sendrealtimemessage, initializeSupabaseRealtime } from './utils';
import { supabase } from '../../services/supabase.js';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*

This page lists out all the Screens, Categories and new Screen items on the dashboard.

Important notes:
- In order to switch back from Upload page to the main summary screen, we use a string called 'screenToEdit'.
If 'screenToEdit' is empty we then we return to the main page
*/

export const uploadVideoFromLocalUrl = async (localUrl) => {
  let url;
  let uploadedToFirebase = false;
  const validUrl = new RegExp(
    /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
  );
  if (validUrl.test(localUrl)) {
    // if we uploaded from a url
    url = localUrl;
  } else {
    // if we need to upload to firebase
    // console.log('cloudlibrary', shareCloud[0]);
    // _dispatch({
    //   type: 'SET_CURR_UPLOADING_VIDEO',
    //   payload: cloudLibrary[i].name,
    // });
    url = await uploadBlobToStorage(
      localUrl,
      'test_video_TYG',
      '/route/TYG/',
      'video/mp4',
      'test_video_TYG.mp4',
      // shareCloud[0].dataURL,
      // shareCloud[0].name,
      // "/route/TYG/",
      // shareCloud[0].type,
      // shareCloud[0].origName,
      (progress, timeestimate) => {
        // progress updates
        //
        console.log('progress....', progress);
        console.log('time estimate', timeestimate);
      }
    );
    uploadedToFirebase = true;
  }

  console.log('new video url = ', url, uploadedToFirebase);
  return url;
};

const MagnetSummary = ({
  communityName,
  communityId,
  currVid,
  setCurrVid,
  reorderCategories,
  setOpenCategoryDialog,
}) => {
  const {
    encodingQ,
    magnetId,
    magnetType,
    loadScreen,
    accessLevel,
    previewTemplate,
  } = useSelector((state) => state.magnetStates);
  const { community_id } = useParams();
  const urlParams = new URLSearchParams(window.location.search);

  const { template, magnetLoading, magnetData } = useSelector(
    (state) => state.getMagnet
  );
  console.log("template",template)
  useEffect(() => {
    const getMagnet = async () => {
      const res = await axios.get(
        `https://api.leasemagnets.com/magnets?queryByCommunity=${community_id}`,
        );
      console.log("res.magnets",res)
      _dispatch({
        type : "GET_MAGNET_SUCCESS",
        payload : res?.data
      })
      //let _magnetObj = res.magnets[0]?.magnet_details?.template;
      // GET_MAGNET_SUCCESS
      // setStartRoute(_magnetObj.start_route);
      // setGaPropertyId(_magnetObj.gaPropertyId);
      // setFbPixelId(_magnetObj.fbPixelId);
      // setMagnetType(res.magnets[0].magnet_type);
  
      // let tempDict = {};
  
      
    };
    getMagnet()
  },[])
  const [magnetalldata, setmagnetalldata] = useState({});
  //console.log("magnetData_magnetData",magnetData)
  const _dispatch = useDispatch();
  const [analyticsloading, setanalyticsloading] = useState(true);
  useEffect(() => {
    if (Object.keys(magnetalldata).length === 0) {
      setmagnetalldata(magnetData);
    }
  }, [magnetData]);
  const [refreshanalyticsloading, setrefreshanalyticsloading] = useState(false);
  const [analyticsdata, setanalyticsdata] = useState();
  let {
    data: fetchedCustomVideosData,
    isLoading: isCustomVideosLoading,
    error: fetchedCustomVideosError,
  } = useGetSupabaseTable('CustomVideos', '', {
    column_name: 'community_id',
    column_value: community_id,
  });

  function addNewSharableTour() {
    magnetId, communityId;
  }

  const [customVideosData, setCustomVideosData] = useState([]);
  const [recordedVideoUrl, setRecordedVideoUrl] = useState(null);
  const [loading, error, data, makeRequest] = useRequest({ loading: false });
  useEffect(() => {
    subscribeRealtimeUpdates(setCustomVideosData, customVideosData);
    return () => unsubscribeRealtimeUpdates();
  }, []);

  useEffect(() => {
    if (!isCustomVideosLoading) setCustomVideosData(fetchedCustomVideosData);
  }, [isCustomVideosLoading]);

  useEffect(() => {
    if (recordedVideoUrl) console.log('ABCD VIDEO URL: ', recordedVideoUrl);
  }, [recordedVideoUrl]);
  useEffect(() => {
    console.log('customVideosData: ', customVideosData);
  }, [customVideosData]);
  console.log('community_id', community_id);

  function setCustomVideosDataAtIndex(payload, idx) {
    if (idx >= 0) {
      setCustomVideosData((prev) => {
        if (prev && prev.length > idx) {
          let thisData = prev;
          thisData[idx] = payload;
          return thisData;
        }
        console.log(
          `[setCustomVideosDataAtIndex] Not setting state becasue index: ${idx} does not exist on customVideosData`
        );
        return prev;
      });
    }
  }

  // SNACKBAR 2
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSnackbar = (message, alertColor) => () => {
    setSnackbarMessage(message);
    setSnackbarColor(alertColor);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const [cardChart1Data, setcardChart1Data] = useState({
    series: [
      {
        name: 'Profits',
        data: [],
      },
    ],
    height: '100%',
    type: 'area',
    options: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      xaxis: {
        type: 'datetime',
      },
      labels: [],
      //colors: [colors.blue],
      fill: {
        opacity: 0.16,
      },
      sparkline: {
        enabled: true,
      },
    },
  });

  // HANDLE SHARE SNACKBAR
  const [openShare, setOpenShare] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  useEffect(() => {
    console.log('selectedVideos', selectedVideos);
    if (selectedVideos && selectedVideos?.length === 0)
      setShareTourType('Full Tour URL');
    if (selectedVideos && selectedVideos?.length > 0)
      setShareTourType('Slides Share');
  }, [selectedVideos]);

  const [searchFilter, setSearchFilter] = useState({
    query: '',
    minPrice: 0,
    maxPrice: Infinity,
    fromDate: '',
    toDate: moment().format('YYYY-MM-DD'),
  });
  console.log('magnetId', magnetId);
  // const updateScreenToEditOnHashChange = () => {
  //   if (!window.location.hash) {
  //     setScreenToEdit("");
  //   }
  // }

  // window.removeEventListener("hashchange", updateScreenToEditOnHashChange(), false);

  // function locationHashChanged() {
  //   console.log('updated hash : ', window.location.hash);

  //   if (window.location.hash === '') {
  //     console.log('lets use hash to set screentoEdit');
  //   }
  // }

  // React.useEffect(() => {
  //   window.addEventListener('hashchange', console.log('window location hash: ', window.location.hash));

  //   // cleanup this component
  //   return () => {
  //     window.removeEventListener('hashchange', locationHashChanged);
  //   };
  // }, []);

  const { showScreenRecording } = useSelector((state) => state.generalReducers);
  const [shareCloud, setShareCloud] = useState([]);
  const [introVideo, setIntroVideo] = useState('');
  const [hyperIframe, setHyperFrame] = useState('');

  const [shareName, setShareName] = useState('');
  const [agentName, setAgentName] = useState(0);
  const [shareTourType, setShareTourType] = useState('Full Tour URL');
  const [customTourStep, setCustomTourStep] = useState(1);
  const [openDelete, setOpenDelete] = useState(false);
  const [openMove, setOpenMove] = useState(false);

  const _templates = [
    {
      name: 'Apps & Software',
      image: 'https://i.imgur.com/wz64mhf.png',
      community_id: 200,
      url: 'https://tour.video/dashboard/200/tour#',
    },
    {
      name: 'E-Learning & Online Universities',
      image: 'https://i.imgur.com/UVtMu28.png',
      community_id: 333,
      url: 'https://tour.video/dashboard/333/tour#',
    },
    {
      name: 'Universities & Colleges',
      image: 'https://i.imgur.com/BjOMV81.png',
      community_id: 346,
      url: 'https://tour.video/dashboard/346/tour#',
    },
    {
      name: 'Onboarding',
      image: 'https://i.imgur.com/V7GxJk5.png',
      community_id: 194,
      url: 'https://tour.video/dashboard/194/tour#',
    },
    {
      name: 'Co-working Spaces',
      image: 'https://i.imgur.com/1BLUM8t.png',
      community_id: 39,
      url: 'https://tour.video/dashboard/39/tour#',
    },
    {
      name: 'Senior Living',
      image: 'https://i.imgur.com/D1v6xpS.png',
      community_id: 211,
      url: 'https://tour.video/dashboard/211/tour#',
    },
    {
      name: 'MultiFamily',
      image: 'https://i.imgur.com/vTmJCUu.png',
      community_id: 43,
      url: 'https://tour.video/dashboard/43/tour#',
    },
    {
      name: 'Student Housing',
      image: 'https://i.imgur.com/t1gAyMy.png',
      community_id: 487,
      url: 'https://tour.video/dashboard/487/tour/#',
    },
    {
      name: 'Testimonial & Courses Template',
      image: 'https://i.imgur.com/8Vzobp5.png',
      community_id: 333,
      url: 'https://tour.video/dashboard/333/tour#',
    },
  ];

  const [category, setCategory] = useState('');
  // Add new video category
  // function addNewCategory() {
  //   if (category && category.length >= 3) {
  //     _dispatch({
  //       type: 'addCategory',
  //       payload: {
  //         category_title: category,
  //         magnet_id: magnetId,
  //         magnet_type: magnetType,
  //       },
  //     });
  //   }
  // }

  useEffect(() => {
    console.log('shareTourType: TYG', shareTourType);
  }, [shareTourType]);

  // COPY TO CLIPBOARD
  const [copySuccess, setCopySuccess] = useState(false);
  const textAreaRef = useRef(null);
  const refreshanalyticsdata = async () => {
    const authToken = await firebase.user().getIdToken(true);
    setrefreshanalyticsloading(true);
    try {
      const currDate = new Date();
      currDate.setDate(currDate.getDate() - 89);
      const end = new Date();
      end.setDate(end.getDate() + 1);
      const { data } = await axios.get(
        //`https://api.leasemagnets.com/custom_event?magnet_uuid=${magnetId}&selectedlength=90&start=2022-01-02T12:00:40.895&end=2022-04-01T12:00:41.168`,
        `https://api.leasemagnets.com/custom_event?refreshcache=true&magnet_uuid=${magnetId}&selectedlength=90&start=${currDate
          .toISOString()
          .replace('Z', '')}&end=${end.toISOString().replace('Z', '')}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      console.log('test_datass', data);
      setanalyticsdata(data.response);
      setrefreshanalyticsloading(false);

      //InDataTime = data;
    } catch (error) {
      console.log('errord', error);
    }
  };

  useEffect(() => {
    const count_details_from_rows_bigquery = async (magnetuuid) => {
      try {
        const { data } = await axios.post(
          `https://api.leasemagnets.com/count_details_from_rows`,
          {
            magnet_uuid: magnetuuid,
          }
        );
        console.log('got count_details_from_rows', data);
        let screenobj = {};
        if (data?.res) {
          data?.res?.map((item) => {
            let category_name = [item[0].split('.')[0]];
            let screen_name = [item[0].split('.')[1]];
            if (screenobj[category_name]) {
              if (screenobj[category_name][screen_name]) {
                screenobj[category_name][screen_name] =
                  screenobj[category_name][screen_name]['count'] + item[1];
              } else {
                screenobj[category_name][screen_name] = { count: item[1] };
              }
            } else {
              screenobj[category_name] = { [screen_name]: { count: item[1] } };
            }
          });
        }
        setanalyticsdata({ response: { category: screenobj } });
      } catch (error) {
        console.log('errror', error);
      }
    };
    const getanalyticsdata = async () => {
      const authToken = await firebase.user().getIdToken(true);
      try {
        const currDate = new Date();
        currDate.setDate(currDate.getDate() - 89);
        // 89 + 1 = 90
        const end = new Date();
        end.setDate(end.getDate() + 1);
        const { data } = await axios.get(
          `https://api.leasemagnets.com/custom_event?magnet_uuid=${magnetId}&selectedlength=90&start=${currDate
            .toISOString()
            .replace('Z', '')}&end=${end.toISOString().replace('Z', '')}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: authToken,
            },
          }
        );
        console.log('test_datass', data);
        setanalyticsdata(data.response);
        setanalyticsloading(false);

        //InDataTime = data;
      } catch (error) {
        console.log('errord', error);
      }
    };
    if (magnetId) {
      //getanalyticsdata();
      count_details_from_rows_bigquery(magnetId);
    }
  }, [magnetId]);

  useEffect(() => {
    // const getanalyticsdata = async () => {
    //   const authToken = await firebase.user().getIdToken(true);
    //   try {
    //     const { data } = await axios.get(
    //       //`https://api.leasemagnets.com/custom_event?magnet_uuid=${magnetId}&selectedlength=90&start=2022-01-02T12:00:40.895&end=2022-04-01T12:00:41.168`,
    //       {
    //         headers: {
    //           'Content-Type': 'application/json',
    //           Authorization: authToken,
    //         },
    //       }
    //     );
    //     console.log('test_data', data);
    //     setanalyticsdata(data.response);
    //     setanalyticsloading(false);

    //     //InDataTime = data;
    //   } catch (error) {
    //     console.log('errord', error.response);
    //   }
    // };
    // getanalyticsdata();
    if (previewTemplate.enablePreview) {
      _dispatch(getMagnetData(previewTemplate.selectedTemplate.community_id));
    } else {
      _dispatch(getMagnetData(communityId));
    }
  }, [previewTemplate]);

  const [librarydata, setlibrarydata] = useState([]);
  const getlibraryvideos = async () => {
    console.log('calledus');
    try {
      const { data } = await axios.get(
        `https://api.directual.com/good/api/v5/data/video_library__tyg/getAndPostVideoLibraryItem?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=&linkId=${communityId}`
      );
      console.log('datarra', data.payload);
      setlibrarydata(data.payload);
    } catch (error) {
      console.log('errord', error);
    }
  };

  const postlibraryvideo = async (url) => {
    console.log('calledus');
    try {
      const { data } = await axios.post(
        'https://api.directual.com/good/api/v5/data/video_library__tyg/getAndPostVideoLibraryItem?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=',
        {
          linkId: communityId,
          metadata: {
            video: url,
            name: 'recording',
            dateCreated: new Date(),
          },
        }
      );
      console.log('datarra', JSON.parse(data.payload));
      getlibraryvideos();
    } catch (error) {
      console.log('errord', error);
    }
  };

  const deletelibraryvideo = async (index) => {
    console.log('calledus');
    try {
      const item = librarydata[index];
      console.log('item', item);
      //deletevideo(JSON.parse(item.metadata).video)
      //console.log('datarra',JSON.parse(data.payload))
      //getlibraryvideos()
    } catch (error) {
      console.log('errord', error);
    }
  };

  useEffect(() => {
    getlibraryvideos();
  }, []);

  const copyToClipboard = React.useCallback((e) => {
    navigator.clipboard.writeText(
      `${TOUR_HOST}/share?magnet_uuid=${magnetId}&share=${selectedVideos.join(
        ','
      )}&agent=${encodeURI(agentName)}&name=${encodeURI(
        shareName
      )}&intro_video=${encodeURI(introVideo)}&hyper_iframe=${hyperIframe}`
    );
    setCopySuccess(1);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  });

  const copyToClipboard2 = React.useCallback((e) => {
    navigator.clipboard.writeText(
      `https://share.tour.video/link?magnet_uuid=${magnetId}&share=${selectedVideos.join(
        ','
      )}&agent=${encodeURI(agentName)}&name=${encodeURI(
        shareName
      )}&intro_video=${encodeURI(introVideo)}&hyper_iframe=${hyperIframe}`
    );
    setCopySuccess(2);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  });

  function checkNotNull(item) {
    return item !== null && item !== undefined;
  }

  const PrettyPrintJson = ({ data }) => {
    // (destructured) data could be a prop for example
    return (
      <div>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    );
  };
  const [open, setOpen] = React.useState(false);
  const [openrecorder, setOpenrecorder] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [openiframedialogue, setopeniframedialogue] = useState(false);
  const [selectoption, setselectoption] = useState('recorder');
  const onCancel = () => {
    _dispatch({
      type: 'SCREEN_TO_EDIT',
      payload: '',
    });
    window.history.pushState({}, `View ${communityId}`, `#`);
  };
  console;

  const [chartlist, setchartlist] = useState([]);
  useEffect(() => {
    // if (magnetId) {
    //   setchartlist([
    //     {
    //       Heading: 'Tour interaction',
    //       showDropdown: false,
    //       value: 90,
    //       queryp1:
    //         'SELECT timestamp_trunc(`default.events`.`_timestamp`, day) AS `_timestamp`, count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL -',
    //       queryp2:
    //         ' day), day) AND magnet_uuid=' +
    //         `"${magnetId}"` +
    //         ' AND `default.events`.`event_type` = "open_tour" GROUP BY `_timestamp` ORDER BY `_timestamp` ASC',
    //     },
    //     {
    //       Heading: 'Business Hour tour interaction',
    //       showDropdown: false,
    //       value: 90,
    //       queryp1:
    //         'SELECT timestamp_trunc(`default.events`.`_timestamp`, day) AS `_timestamp`, count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL -',
    //       queryp2:
    //         ' day), day) AND EXTRACT(HOUR from `utc_time`) between 7 and 19 AND magnet_uuid=' +
    //         `"${magnetId}"` +
    //         ' AND `default.events`.`event_type` = "open_tour" GROUP BY `_timestamp` ORDER BY `_timestamp` ASC',
    //     },
    //     {
    //       Heading: 'Special Offer interaction',
    //       showDropdown: false,
    //       value: 90,
    //       queryp1:
    //         'SELECT timestamp_trunc(`default.events`.`_timestamp`, day) AS `_timestamp`, count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL -',
    //       queryp2:
    //         ' day), day) AND magnet_uuid=' +
    //         `"${magnetId}"` +
    //         ' AND `default.events`.`event_type` = "form_interaction" GROUP BY `_timestamp` ORDER BY `_timestamp` ASC',
    //     },
    //     {
    //       Heading: 'Special Offer Completions',
    //       showDropdown: false,
    //       value: 90,
    //       queryp1:
    //         'SELECT timestamp_trunc(`default.events`.`_timestamp`, day) AS `_timestamp`, count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL -',
    //       queryp2:
    //         ' day), day) AND EXTRACT(HOUR from `utc_time`) between 7 and 19 AND magnet_uuid=' +
    //         `"${magnetId}"` +
    //         ' AND `default.events`.`event_type` = "form_submission" GROUP BY `_timestamp` ORDER BY `_timestamp` ASC',
    //     },
    //   ]);
    // }
  }, [magnetId]);

  const [previewVideoIndex, setPreviewVideoIndex] = useState(null);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const [openSignOutDialog, setOpenShareDialog] = useState(false);
  const handleCloseShareDialog = () => {
    setOpenShareDialog(false);
  };
  const [modalStep, setModalStep] = useState(0);
  const [showQrCode, setShowQrCode] = useState(false);

  function generateSharableTourUrl(
    share_tour_type = '',
    lead_name = '',
    magnet_uuid = '',
    selected_videos = '',
    agent_id = '',
    hyper_iframe = '',
    intro_video = ''
  ) {
    if (share_tour_type === 'Instagram Stories') {
      return `https://share.tour.video/link?magnet_uuid=${magnet_uuid}&share=${selected_videos.join(
        ','
      )}&agent=${encodeURI(agent_id)}&name=${encodeURI(lead_name)}`;
    } else if (share_tour_type === 'Mini-Website') {
      return `${TOUR_HOST}/share?magnet_uuid=${magnet_uuid}&share=${selected_videos.join(
        ','
      )}&agent=${encodeURI(agent_id)}&name=${encodeURI(
        lead_name
      )}&intro_video=${encodeURI(intro_video)}&hyper_iframe=${hyper_iframe}`;
    } else if (share_tour_type === 'Slides Share') {
      // if (share_tour_type === 'Slides Share') {
      return `https://app.usetour.com/customtour?magnet_uuid=${magnet_uuid}&share=${selected_videos.join(
        ','
      )}&agent=${encodeURI(agent_id)}&name=${encodeURI(lead_name)}`;
    } else {
      // if (share_tour_type === 'Full Tour URL') {
      return `https://embed.tour.video?uuid=${magnet_uuid}&inline=true`;
    }
  }

  const [bgHeaderImg, setBgHeaderImg] = useState(
    'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/6ebcb98a-5b2b-49b5-5fc2-f196129cba00/public'
  );

  useEffect(() => {
    newBgImage();
  }, []);
  function get_random(list) {
    return list[Math.floor(Math.random() * list.length)];
  }

  function newBgImage() {
    let bgHeaders=[
      "https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/d46c880d-37b3-4eb5-3ed6-7c0a4fbb3400/original",
      "https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/81dd95b2-fde6-42f7-8037-e7375e23c700/original",
      "https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/61a10000-0fcb-41f0-548b-2df22b185700/big",
      "https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/1cd56107-a3d4-471d-fefa-74d0c675a400/big",
      "https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/eb6642f8-c1d0-4366-81e8-f5ec3861e100/big",
      "https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/b41e3d2a-cb48-45a5-b549-efec5ef77a00/big",
      "https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/a7fe7ec6-2196-4bea-97ee-5d72ff215100/big",
      "https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/9f0a3322-c89e-488c-4ae9-f5ebc5d30200/big",
      "https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/553ceb5f-cdfa-4ea6-c2a3-bc4973a4ba00/original",
      "https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/18ea5a6c-718f-4c67-cb73-855aa3d26900/original",
      "https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/0187eb73-c960-47b6-a2b8-5f940b65eb00/big"

    ]

      // pick random image from bgheaders array
      let randomIndex = Math.floor(Math.random() * bgHeaders.length);
      let randomImage = bgHeaders[randomIndex];
  
      setBgHeaderImg(randomImage);
  }

  const [tourTab, setTourTab] = useState(urlParams.get('tourTab') ?? 'tour');

  // Ahad please add data get functions here
  //  # of tours (30 days, 1 year), # of leads, # of live visitors in the past 2 hours
  let requestID = generateID(3, 4);
  const [meetingModal, setMeetingModal] = useState(false);
  const [meetingStep, setMeetingStep] = useState(0);
  const [is_email_sms, setIs_email_sms] = useState(0);
  const [title, setTitle] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  // new plugin integration
  const [managePluginsModal, setManagePluginsModal] = useState(false);
  const [managePluginsStep, setManagePluginsStep] = useState(0);

  const [talkLoaded, markTalkLoaded] = useState(false);
  Talk.ready.then(() => markTalkLoaded(true));

  const me = {
    id: `${community_id}${firebase?.user()?.uid}v2`,
    name: `${firebase?.user()?.displayName?.split(' ')?.[0]}`,
    role: 'default',
  };

  useEffect(() => {
    if (talkLoaded) {
      console.log('chat ready');
      // safe to use SDK here

      const currentUser = new Talk.User(me);

      window.talkSession = new Talk.Session({
        appId: 'tPxDGb1X',
        me: currentUser,
      });

      window.talkSession.unreads.on('change', function (unreadConversations) {
        console.log('unreadConversations', unreadConversations);
        unreadConversations.forEach((unread) => {
          setactivevisitors((prev) =>
            prev.map((item) => {
              return unread.conversation.id.split('_')[1] ===
                item.data.visitorId
                ? {
                    data: {
                      ...item.data,
                      unreadCount: item.data.unreadCount + 1,
                    },
                  }
                : item;
            })
          );
        });
      });
    }
  }, [talkLoaded]);

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + match[2] + match[3];
    }
    return false;
  }
  function getAptSlug() {
    // Split URL by '/'
    const url = window.location.href.split('/');
    return url[url.length - 2];
  }
  // Handle send message
  function handleSubmit(e) {
    // Create data object
    e.preventDefault();

    let data = {};
    requestID;
    if (is_email_sms === 1) {
      const phoneNumber = formatPhoneNumber(phone);
      if (phoneNumber === false) {
        toast(`Phone number is invalid`, {
          position: 'top-center',
          type: 'error',
          autoClose: 5000,
        });
        return null;
      }

      data = {
        to: phoneNumber,
        message: `Hi, here is the meeting link: https://meet.jit.si/tour/${encodeURIComponent(
          `${communityName}${title}`
        )}`,
      };

      makeRequest('/send_sms', 'POST', data, { auth: false, host: API_HOST })
        .then((data) => {
          // Notify user message was successful
          toast(`Message sent`, {
            position: 'top-center',
            type: 'success',
            autoClose: 5000,
          });
          // Clear inputs

          // setErrors('');
          setPhone('');
          setEmail('');

          setIs_email_sms(0);
        })
        .catch((error) => {
          console.log(error.message);
          toast(`Message Sent Failed ${error.message}`, {
            position: 'top-center',
            type: 'error',
            autoClose: 5000,
          });
        });
    } else {
      // type === email

      data = {
        to: email,
        subject: `Meeting Link: ${title}`,
        message: `Hey👋, <br/><br/> here is the meeting link: ${`https://meet.jit.si/tour/${encodeURIComponent(
          `${communityName}${title}`
        )}`}`,
      };

      post('/email/plain', data, { auth: false })
        .then((data) => {
          toast(`Email Sent Successfully`, {
            position: 'top-center',
            type: 'success',
            autoClose: 5000,
          });
          setPhone('');
          setEmail('');
          setIs_email_sms(0);
        })
        .catch((error) => {
          toast(`Email Sent Failed ${error.message}`, {
            position: 'top-center',
            type: 'error',
            autoClose: 5000,
          });
          console.log(error.message);
        });
    }
  }
  useEffect(() => {
    setTitle(`${communityName} <> Tour`);
  }, [communityName]);

  const [activevisitors, setactivevisitors] = useState();
  const [allvisitorsdata, setallvisitorsdata] = useState();
  const [channelref, setchannelref] = useState('');
  const [defaultVisitorUuid, setDefaultVisitorUuid] = useState(
    urlParams.get('visitorId') ?? ''
  );
  const [defaultVisitorData, setDefaultVisitorData] = useState(null);
  console.log('activevisitors', activevisitors);
  useEffect(() => {
    let temp_activevisitors = [];
    if (magnetId) {
      initializeSupabaseRealtime(
        setchannelref,
        activevisitors,
        setactivevisitors,
        magnetId,
        temp_activevisitors,
        'magnetSummary'
      );
      // fetch all chats
      const fetchallVisitorsChat = async () => {
        let {data,error} = await supabase
        .from('chat')
        .select('*')
        .eq('magnet_uuid', magnetId);
        console.log("chat_data",data)
        console.log("chat_data_map", data?.map(item => {
          return {
            ...item,
            ...item?.user_info
          };
        }));
        setallvisitorsdata(data)
      }
      fetchallVisitorsChat()
    }
  }, [magnetId]);



  useEffect(() => {
    if (defaultVisitorUuid && !defaultVisitorData && activevisitors) {
      const foundVisitor = activevisitors.find(
        ({ data }) => data.visitorId === defaultVisitorUuid
      );
      if (foundVisitor) {
        setDefaultVisitorData(foundVisitor.data);
      } else {
        setDefaultVisitorUuid('');
      }
    }
  }, [activevisitors, defaultVisitorData, defaultVisitorUuid]);

  return (
    <div className="magnet-sumary">
      {selectoption === 'special-stepper' && (
        <AnimatedDialog
          open={selectoption === 'special-stepper'}
          onClose={() => setselectoption('')}
          style={{ p: '10px' }}
        >
          <div className="p-4 flex justify-between items-center">
            <Typography component="h2" style={{ fontWeight: 'bold' }}>
              Update Special Offer
            </Typography>
            <button className="pointer" onClick={() => setselectoption('')}>
              <IoMdClose />
            </button>
          </div>
          <div className="px-4 py-1">
            <UpdateSpecialOfferSection onFinished={() => setselectoption('')} />
          </div>
        </AnimatedDialog>
      )}

      <div className="sumary-content flex flex-col">
        {/* Template Preview Block */}
        {false && recordedVideoUrl ? (
          <RecordedVideoTitleDialog
            open={recordedVideoUrl}
            onClose={() => setRecordedVideoUrl('')}
            /*
            onVideoSaved={(response) => {
              console.log('ABCD SUPABASE VIDEO SAVE RESPONSE: ', response);
              if (response.error) {
                window.alert(
                  `Please try again ! vide could not be saved because ${response.error.message}`
                );
              } else {
                // Add the new data into existing row of videos
                console.log('ABCD response :', response?.data);
                setCustomVideosData((prev) => [response.data, ...prev]);
              }
            }}
            magnetId={magnetId}
            communityId={communityId}
            videoUrl={recordedVideoUrl}
            */
          />
        ) : null}
        {checkNotNull(previewVideoIndex) && template && magnetId && (
          <Dialog
            open={checkNotNull(previewVideoIndex)}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              _dispatch({
                type: 'SHOW_SCREEN_RECORDING',
                payload: false,
              });
              setPreviewVideoIndex(null);
            }}
            maxWidth={'l'}
            PaperProps={{
              style: {
                height: '90vh',
                width: '70vw',
                borderRadius: 13,
                background: 'transparent',
                boxShadow: 'none',
                //  overflow: 'hidden',
              },
            }}
          >
            {/* // <AnimatedDialog
          //   open={checkNotNull(previewVideoIndex)}
          //   keepMounted
          //   onClose={() => {
          //     _dispatch({
          //       type: 'SHOW_SCREEN_RECORDING',
          //       payload: false,
          //     });
          //     setopen(false);
          //     // setPreviewVideoIndex(null);
          //   }}
          //   maxWidth={'l'}
          //   fullHeight
          //   > */}

            {/* Top Section */}
            <VideoTitleBar
              videoTitle={customVideosData[previewVideoIndex]?.title}
              onTitleSave={async (newTitle) => {
                const supaResponse = await supaUpdateVideoScreen(
                  {
                    ...customVideosData[previewVideoIndex]?.screen,
                    title: newTitle,
                  },
                  customVideosData[previewVideoIndex]?.id
                );
                if (!supaResponse.error) {
                  setCustomVideosData((prev) => {
                    const items = prev;
                    const item = {
                      ...prev[previewVideoIndex],
                      screen: {
                        ...prev[previewVideoIndex].screen,
                        title: newTitle,
                      },
                    };
                    //items[previewVideoIndex] = item;

                    return [item, ...items];
                  });
                  window.alert('Video title update !');
                } else {
                  window.alert('Could not update title, Please try again !');
                }
              }}
              onDelete={async () => {
                if (window.confirm('Do you want to delete this video ?')) {
                  deleteVideoFromCloud(
                    `recordings${
                      customVideosData[previewVideoIndex]?.video?.split(
                        '/recordings'
                      )[1]
                    }`
                  );
                  const supaResponse = await supaDeleteVideo(
                    customVideosData[previewVideoIndex]?.id
                  );
                  if (!supaResponse.error) {
                    setCustomVideosData((prev) =>
                      prev.filter((item, idx) => idx !== previewVideoIndex)
                    );
                    window.alert('Video Deleted Successfully !');
                  } else {
                    window.alert('Could not delete video, Please try again !');
                  }
                }
              }}
            />
            {/* Video Section */}
            <div className="relative h-full w-full">
              <div className="flex z-10 mt-20">
                {/* If we render when not open, then the video starts playing in the background */}
                {checkNotNull(previewVideoIndex) && (
                  <PreviewVideoMagnet
                    previewTemplateScreen={{
                      additionalInfo: {
                        detailedDescription: '',
                        features: [],
                        images: [],
                        moveInDate: '2022-02-15',
                        price: 0,
                        projectID: 309860,
                        shareCaption: '',
                      },
                      caption: 'Welcome to Arba San Marcos!',
                      cloudLibrary: [],
                      form: {
                        enabled: false,
                        inputs: [],
                        text_data: {
                          body_text: '',
                          title: '',
                        },
                      },
                      gif: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/299/magnet/f39eb022-5c26-4cfa-b8e0-6fb3d3d1cb84/intro_Main_Intro/intro__1__mp4_1.gif',
                      iframe: {
                        background_color: '#000000',
                        enabled: false,
                        icon_color: '#ffffff',
                        src: '',
                      },
                      img: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/299/magnet/f39eb022-5c26-4cfa-b8e0-6fb3d3d1cb84/intro_Main_Intro/intro__1__mp4_1.jpg',
                      links: [
                        {
                          id: 'Floor Plans - Floor Plan Intro ',
                          name: 'Tour Floor Plans',
                          route: 'floor_plans.floor_plan_1',
                        },
                        {
                          id: 'Amenities - Amenity Intro',
                          name: 'Explore Growth',
                          route: 'amenities.amenity_intro',
                        },
                      ],
                      location_card: 'Where to next{{, {firstName}|}}?',
                      share_info: {},
                      title: 'Main Intro',
                      video: customVideosData[previewVideoIndex]?.video,
                    }}
                    uuid={magnetId}
                    template={template}
                    category={'_preview_'}
                    screen={'new'}
                    inline={false}
                    singleScreen={true}
                    onClose={() => {
                      setPreviewVideoIndex(null);
                    }}
                  />
                )}
              </div>
            </div>
          </Dialog>
        )}

        <div className="prev-msg">
          <TemplatePreviewMessage onCancel={onCancel} />
        </div>
        <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 ">
          {chartlist.map((item, index) => (
            <Customchart
              Heading={item.Heading}
              index={index}
              setDropdown={() => {
                chartlist[index]['showDropdown'] =
                  !chartlist[index]['showDropdown'];
                setchartlist([...chartlist]);
              }}
              setdays={(val) => {
                chartlist[index]['value'] = val;
                setchartlist([...chartlist]);
              }}
              showDropdown={item.showDropdown}
              query={item.queryp1 + item.value + item.queryp2}
            />
          ))}
        </div>
        <DynamicIframdialogue
          open={openiframedialogue}
          setOpen={setopeniframedialogue}
          hyperIframeId={hyperIframe}
          updateIframe={(value) => {
            setHyperFrame(value);
          }}
        />
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarColor}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <div className=" relative">
          <img
            src={bgHeaderImg}
            id="bg-img-top"
            className=" w-full object-cover"
            alt=""
            style={{ height: '330px' }}
          />
          <div
            className=" absolute font_uber top-0 left-0 w-full h-full  z-20 "
            style={{
              background:
                'linear-gradient(180deg, rgba(0, 0, 0, .10) 10%, #000000 90.56%)',
            }}
          >
            <div className=" mt-1 relative px-4 lg:px-0 ">
              <h1 className="text-2xl font-bold text-white font_uber mt-4">
                Welcome back, here are some updates!
              </h1>

              <MagnetSummaryTypewriter magnetId={magnetId} />

              <div className=" mt-3">
                {/* action links */}
                <div
                  className=" w-full individual_button rounded-sm px-3 py-2 flex items-center gap-3 input_blur "
                  onClick={() =>
                    window.open(
                      'https://leasemagnets-support-site-tyg.webflow.io/',
                      '_blank'
                    )
                  }
                >
                  {/* <img src={imgSrc} alt="" /> */}
                  <i className="fa  fa-question-circle mt-1 mr-2 text-white "></i>{' '}
                  <p className=" text-white text-base">Support </p>
                </div>
              </div>

              <div className=" mt-4 mb-2 flex items-center gap-2 overflow-auto">
                <button
                  onClick={() => {
                    setTourTab('tour');
                  }}
                  className={`${
                    tourTab === 'tour'
                      ? ' bg-blue-500'
                      : 'hover:bg-gray-200 hover:bg-opacity-20 individual_button'
                  }   text-white font-bold py-2 px-4 mx-1 rounded-md`}
                >
                  Build
                </button>

                <button
                  onClick={() => {
                    setTourTab('engage');
                  }}
                  className={`${
                    tourTab === 'engage'
                      ? ' bg-blue-500'
                      : 'hover:bg-gray-200 hover:bg-opacity-20 individual_button'
                  }   text-white font-bold py-2 px-4 mx-1 rounded-md`}
                >
                  Engage
                </button>

                <button
                  onClick={() => {
                    setTourTab('customize');
                  }}
                  className={`${
                    tourTab === 'customize'
                      ? ' bg-blue-500'
                      : 'hover:bg-gray-200 hover:bg-opacity-20 individual_button'
                  }  text-white font-bold py-2 px-4 mx-1 rounded-md`}
                >
                  Customize
                </button>

                <button
                  onClick={() => {
                    setTourTab('embed');
                  }}
                  className={`${
                    tourTab === 'embed'
                      ? ' bg-blue-500'
                      : 'hover:bg-gray-200 hover:bg-opacity-20 individual_button'
                  }  text-white font-bold py-2 px-4 mx-1 rounded-md`}
                >
                  Embed
                </button>

                <button
                  onClick={() => {
                    setTourTab('analytics');
                  }}
                  className={`${
                    tourTab === 'analytics'
                      ? ' bg-blue-500'
                      : 'hover:bg-gray-200 hover:bg-opacity-20 individual_button'
                  }  text-white font-bold py-2 px-4 mx-1 rounded-md`}
                >
                  Analytics
                </button>

                {/* <button
                  onClick={() => {
                    setTourTab('settings');
                  }}
                  className={`${
                    tourTab === 'settings'
                      ? ' bg-blue-500'
                      : 'hover:bg-gray-200 hover:bg-opacity-20 individual_button'
                  }  text-white font-bold py-2 px-4 mx-1 rounded-md`}
                >
                  Settings
                </button> */}

                {/* <button
                  onClick={() => {
                    window.open(
                      'https://leasemagnets-support-site-tyg.webflow.io/',
                      '_blank'
                    );
                  }}
                  className={`${
                    tourTab === 'track'
                      ? ' bg-blue-500'
                      : 'hover:bg-gray-200 hover:bg-opacity-20 individual_button'
                  }  text-white font-bold py-2 px-4 mx-1 rounded-md`}
                >
                  Support
                </button> */}

                {/* <button
                  onClick={() => {
                    setTourTab('leads');
                  }}
                  className={`${
                    tourTab === 'leads'
                      ? ' bg-blue-500'
                      : 'hover:bg-gray-200 hover:bg-opacity-20 individual_button'
                  }  text-white font-bold py-2 px-4 mx-1 rounded-md`}
                >
                  Leads
                </button> */}

                {/* <button
                      onClick={() => {
                          // setTourTab('share');
                          setOpenShareDialog(true)
                        }}
                        className={`${tourTab === 'share2' ? ' bg-blue-500' : 'hover:bg-gray-200  hover:bg-opacity-20 individual_button'}  text-white font-bold py-2 px-4 mx-1 rounded-md`}
                      >
                      

                        <div className=' flex'>
                        <span className=" text-white text-base">Share</span>
                        {selectedVideos?.length > 0 ? (
                          <div
                            className="rounded-md text-xs align-center justify-center"
                            style={{
                              backgroundColor: 'white',
                              color: '#3898ec',
                              height: '20px',
                              marginRight: '5px',
                              marginLeft: '12px',
                              paddingTop: '1px',
                              paddingLeft: '2px',
                              paddingRight: '2px',
                              marginTop: '2px',
                            }}
                          >
                            <span className="m-1">{selectedVideos?.length}</span>
                          </div>
                        ) : (
                          <></>
                        )}
                        </div> 

                      </button>*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* share dialog */}
      <AnimatedDialog
        open={openSignOutDialog}
        onClose={handleCloseShareDialog}
        fullWidth
        maxWidth="sm"
      >
        <div className=" p-4">
          <div className=" flex items-center justify-between w-full pb-3">
            <MdOutlineClose
              onClick={handleCloseShareDialog}
              className=" w-8 h-8 cursor-pointer"
            />
            <div className=" flex items-center gap-2">
              <div
                onClick={() => setModalStep(0)}
                className={` ${
                  modalStep === 0 ? 'w-8 bg-pr' : 'w-2 bg-gray-400'
                } h-2 cursor-pointer  rounded-2xl`}
              ></div>
              <div
                className={` ${
                  modalStep === 1 ? 'w-8 bg-pr' : 'w-2 bg-gray-400'
                } h-2  rounded-2xl`}
              ></div>
            </div>
            <div></div>
          </div>
          {modalStep === 0 ? (
            <h1 className=" text-2xl font-semibold">
              <span className=" text-pr">Share</span> this Tour
            </h1>
          ) : (
            <h1 className=" text-2xl font-semibold">
              {' '}
              <span className=" text-pr">Save</span> this Sharable Tour
            </h1>
          )}
          {modalStep === 0 && (
            <>
              <div>
                Share Type
                <TextField
                  id="outlined-basic"
                  select
                  label=""
                  variant="outlined"
                  value={shareTourType}
                  onClick={(e) => {
                    console.log('shareTourType selector: ', e.target.value);
                    const eTarget = e.target.value;
                    if (eTarget !== undefined && eTarget !== 0) {
                      setShareTourType(eTarget);
                    }
                  }}
                  style={{ width: '100%', marginTop: '5px' }}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem
                    disabled={selectedVideos?.length == 0}
                    value="Slides Share"
                  >
                    Slides Share
                  </MenuItem>
                  <MenuItem
                    disabled={selectedVideos?.length == 0}
                    value="Instagram Stories"
                  >
                    Instagram Stories
                  </MenuItem>
                  <MenuItem
                    disabled={selectedVideos?.length == 0}
                    value="Mini-Website"
                  >
                    Mini-Website
                  </MenuItem>
                  <MenuItem value="Full Tour URL">Full Tour URL</MenuItem>
                </TextField>
              </div>

              <div className="">
                <TextField
                  id="outlined-name"
                  label={
                    shareTourType !== 'Full Tour URL'
                      ? `Tour URL ${shareName ? 'for ' + shareName : ''}`
                      : 'Full Tour URL'
                  }
                  value={generateSharableTourUrl(
                    shareTourType,
                    shareName,
                    magnetId,
                    selectedVideos,
                    agentName
                  )}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <>
                        <Tooltip title="Copy to clipboard">
                          <InputAdornment
                            position="start"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                generateSharableTourUrl(
                                  shareTourType,
                                  shareName,
                                  magnetId,
                                  selectedVideos,
                                  agentName
                                )
                              );
                              toast('Copied to clipboard', {
                                position: 'top-right',
                                type: 'success',
                                autoClose: 1500,
                              });
                            }}
                          >
                            <BsClipboard />
                          </InputAdornment>
                        </Tooltip>
                        <Tooltip title="Open new link">
                          <InputAdornment
                            position="start"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              window.open(
                                generateSharableTourUrl(
                                  shareTourType,
                                  shareName,
                                  magnetId,
                                  selectedVideos,
                                  agentName
                                ),
                                '_blank'
                              );
                            }}
                          >
                            <FiExternalLink />
                          </InputAdornment>
                        </Tooltip>
                      </>
                    ),
                  }}
                />
              </div>

              {!showQrCode && (
                <>
                  <div className={styles.share_popup_dropdown}>
                    Agent Name:
                    <TextField
                      id="outlined-basic"
                      select
                      label=""
                      variant="filled"
                      value={agentName}
                      onClick={(e) => {
                        console.log('agent selector: ', e.target.value);
                        const eTarget = e.target.value;
                        if (eTarget !== undefined) {
                          setAgentName(eTarget);
                        }
                      }}
                      style={{ width: '100%', marginTop: '5px' }}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {template &&
                        template?.magnetSettings?.agents &&
                        template?.magnetSettings.agents.map(
                          (agent, agent_idx) => {
                            return (
                              <MenuItem key={agent.name} value={agent_idx}>
                                {typeof agent.name == 'object'
                                  ? 'object'
                                  : agent.name}
                              </MenuItem>
                            );
                          }
                        )}
                    </TextField>
                  </div>

                  <div className={styles.share_popup_dropdown}>
                    <div className="justify-between flex">
                      <span>Prospect Name: (optional)</span>
                      {/* <div>
                        <Tooltip
                          title={
                            shareName
                              ? `Create Video Meeting with ${shareName}`
                              : 'Create Video Meeting'
                          }
                        >
                          <div
                            className="flex cursor-pointer hover:text-blue-500 transform transition hover:-translate-y-1 opacity-75"
                            onClick={() => {
                              window.open(
                                `https://meet.jit.si/tour/${communityName}${shareName}`,
                                '_blank'
                              );
                            }}
                          >
                            <HiOutlineVideoCamera className="mt-1 mr-1" />{' '}
                            <span className="hidden sm:block">
                              Create a Video Chat
                            </span>
                          </div>
                        </Tooltip>
                      </div> */}
                    </div>
                    {/* <DebouncedTextField
                          id="outlined-basic"
                          label=""
                          placeholder="ie: Jim"
                          variant="outlined"
                          value={shareName}
                          onChange={setShareName}
                          style={{ width: '100%', marginTop: '5px' }}
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        /> */}
                    <TextField
                      id="outlined-name"
                      value={shareName}
                      placeholder="ie: Jim"
                      onChange={(e) => setShareName(e.target.value)}
                      variant="filled"
                      fullWidth
                      // InputProps={{
                      //   startAdornment: (
                      //     <>
                      //       <Tooltip title={ shareName ? `Create Video Meeting with ${shareName}` : "Create Video Meeting"}>
                      //         <InputAdornment
                      //           position="start"
                      //           style={{ cursor: 'pointer',  }}
                      //           onClick={() => {
                      //             window.open(
                      //               `https://meet.jit.si/${communityName}${shareName}`,
                      //               '_blank'
                      //             );
                      //             // toast('Copied to clipboard', {
                      //             //   position: 'top-right',
                      //             //   type: 'success',
                      //             //   autoClose: 1500,
                      //             // });
                      //           }}
                      //         >
                      //           <HiOutlineVideoCamera />
                      //           {/* <BsClipboard /> */}
                      //         </InputAdornment>
                      //       </Tooltip>
                      //     </>
                      //   ),
                      // }}
                    />
                  </div>
                </>
              )}
              {showQrCode && (
                <div className="flex items-center justify-center w-full pb-4 mt-4">
                  <QRCode
                    value={generateSharableTourUrl(
                      shareTourType,
                      shareName,
                      magnetId,
                      selectedVideos,
                      agentName
                    )}
                    height={'200px'}
                    width={'200px'}
                  />
                </div>
              )}

              {/* {showQrCode && (
                    <TextField
                      id="outlined-name"
                      label="UTM Source"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <Tooltip title="Copy to clipboard">
                            <InputAdornment
                              position="start"
                              style={{ cursor: 'pointer' }}
                            >
                              <MdPhonelinkRing />
                            </InputAdornment>
                          </Tooltip>
                        ),
                      }}
                    />
                  )} */}
              {/* 
                  {openrecorder && !open && showScreenRecording && (
                    <SendRecording
                      cloudLibrary={shareCloud}
                      setCloudLibrary={setShareCloud}
                      currVid={currVid}
                    />
                  )} */}

              {(!selectedVideos || selectedVideos.length === 0) && (
                <>
                  <a
                    href="https://stream.tour.video/v/f471bee728c89e08fa767c7f24317682/iframe"
                    target="_blank"
                    className="text-xs font-gray-700 flex"
                  >
                    <FiHelpCircle className="mr-1" />
                    <span className="mb-1">
                      Tutorial: How you can select videos to share:
                    </span>
                  </a>
                </>
              )}
              <div className=" flex items-center justify-center w-full">
                <DragItem
                  getScreen={(category, screen) =>
                    template?.categories?.[category]?.screens?.[screen]
                  }
                  setSelectedVideos={setSelectedVideos}
                  selectedVideos={selectedVideos}
                />
              </div>

              <hr className="color-gray-500" />
              <div className=" justify-center ml-1 mt-2">
                <span className="text-xs font-light ml-3">
                  Sharable Tour Library
                </span>

                {magnetId ? (
                  <ShareableTour
                    selectedVideos={selectedVideos}
                    setSelectedVideos={setSelectedVideos}
                    getScreen={(category, screen) =>
                      template?.category[category]?.screen[screen]
                    }
                    generateSharableTourUrl={generateSharableTourUrl}
                    toggleQrCode={() => setShowQrCode(!showQrCode)}
                    sharableTourType={shareTourType} // slideshare, instagram stories, or mini-website
                    magnet_uuid={magnetId}
                    community_id={community_id}
                  />
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </div>
      </AnimatedDialog>
      {/** End of Share Dialog */}

      {tourTab === 'tour' && (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-9 gap-3">
            <div className=" mt-5 px-3 lg:col-span-2">
              <TourRooms communityId={community_id} />

              <OnlineUser
                allData={activevisitors}
                allvisitorsdata={allvisitorsdata}
                onConnect={(visitorId) => sendCallToVisitor(visitorId)}
                onView={(visitorId, visitorData) => {
                  setTourTab('engage');
                  setDefaultVisitorUuid(visitorId);
                  setDefaultVisitorData(visitorData);
                  setactivevisitors((prev) =>
                    prev.map((elem) => {
                      return {
                        data: {
                          ...elem.data,
                          unreadCount:
                            elem.data.visitorId === visitorId
                              ? 0
                              : elem.data.unreadCount,
                        },
                      };
                    })
                  );
                  console.log('onView clicked', visitorId, visitorData);
                }}
              />
            </div>
            {/* Connection Screen */}
            <div className="w-full lg:col-span-7">
              <div className="summary-hdr flex aic mt-6">
                <div className="left flex aic">
                  <div className="video-search-bar">
                    <input
                      type="text"
                      className="cleanbtn iput font s15 c333"
                      placeholder="Search for your videos"
                      value={searchFilter.query}
                      onChange={(newValue) =>
                        setSearchFilter({
                          ...searchFilter,
                          query: newValue.target.value,
                        })
                      }
                    />
                  </div>

                  {/* <div className="">
              <button
                className={`border px-3 py-2 flex align-center justify-center rounded-md`}
                style={{ color: '#3898ec', borderColor: '#3898ec' }}
                onClick={() => setOpenShareDialog(true)}
              >
                <i className="fa fa-share-alt mt-1 mr-2 "></i>{' '}
                {selectedVideos?.length > 0 ? (
                  <div
                    className="rounded-sm text-xs align-center justify-center"
                    style={{
                      backgroundColor: '#3898ec',
                      color: 'white',
                      height: '20px',
                      marginRight: '5px',
                      paddingTop: '1px',
                    }}
                  >
                    <span className="m-1">{selectedVideos?.length}</span>
                  </div>
                ) : (
                  <></>
                )}
                <span className="hidden sm:block">Share Tour</span>
              </button>
            </div> */}

                  {/* <button
              onClick={() => {
                handleOpen();
                _dispatch({
                  type: 'SHOW_SCREEN_RECORDING',
                  payload: true,
                });
              }}F
              className="ml-2 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded "
            >
              +
            </button> */}
                </div>

                {!openrecorder && (
                  <AnimatedDialog
                    open={open}
                    onClose={() => {
                      _dispatch({
                        type: 'SHOW_SCREEN_RECORDING',
                        payload: false,
                      });
                      setopen(false);
                    }}
                    fullWidth
                    maxWidth={'lg'}
                    fullHeight
                  >
                    <div className="w-full h-full">
                      <div className="flex justify-center mb-4	">
                        <p
                          onClick={() => {
                            setselectoption('recorder');
                            handleOpen();
                            _dispatch({
                              type: 'SHOW_SCREEN_RECORDING',
                              payload: true,
                            });
                          }}
                          className={`mr-3 cursor-pointer	 bg-blue ${
                            selectoption == 'recorder' &&
                            'border-b-4 border-blue-500'
                          }`}
                        >
                          Record Video
                        </p>
                        <p
                          onClick={() => {
                            setselectoption('library');
                          }}
                          className={`bg-blue cursor-pointer	 ${
                            selectoption == 'library' &&
                            'border-b-4 border-blue-500'
                          }`}
                        >
                          Video Library
                        </p>
                      </div>
                      <div className="flex 	overflow-scroll justify-center h-screen		">
                        {open && selectoption == 'recorder' && (
                          <SendRecording
                            currVid={currVid}
                            inline={true}
                            onVideoUrl={(url) => {
                              console.log('urll', url);
                              // postlibraryvideo(url);
                              setRecordedVideoUrl(url);
                            }}
                            onVideoTitleAndUrl={({ title, url }) => {
                              generateAndSaveVideoData({
                                title: title,
                                videoUrl: url,
                                magnetId: magnetId,
                                communityId: communityId,
                                onVideoSaved: (response) => {
                                  if (response.error) {
                                    window.alert(
                                      `Please try again ! vide could not be saved because ${response.error.message}`
                                    );
                                  } else {
                                    // Add the new data into existing row of videos
                                    console.log(
                                      'ABCD response :',
                                      response?.data
                                    );
                                    setCustomVideosData((prev) => [
                                      response.data,
                                      ...prev,
                                    ]);
                                  }
                                },
                              });
                            }}
                            closeDialogue={handleClose}
                            dontEncode={true}
                          />
                        )}
                        {/* <div onClick={() => setAddopen(true)}> */}
                        {selectoption == 'library' && (
                          <div className="flex">
                            {librarydata.map((item, index) => (
                              <div className=" ml-2  px-3 py-3 h-52 w-50 mb-6 cursor-pointer bg-gray-200	 ">
                                <video
                                  //ref={vidRef}
                                  className="item-video cursor-pointer h-52 w-50 "
                                  //style={{ height: '50px' }}
                                  loop
                                >
                                  <source
                                    src={JSON.parse(item.metadata).video}
                                    type="video/mp4"
                                  />
                                  Your browser does not support HTML5 Video.
                                </video>
                                <span
                                  onClick={() => deletelibraryvideo(index)}
                                  className="ico icon-delete1 b6 s16"
                                ></span>
                                {/* <div className=" absolute bottom-3 left-3 z-20">
                {play.id === ind &&
                play.status === true &&
                play.ref === true ? (
                  <AiOutlinePause
                    onClick={() => {
                      handlePauseVideo();
                      setPlay({
                        id: ind,
                        status: false,
                        ref: false,
                      });
                    }}
                    className=" w-7 h-7 text-white cursor-pointer"
                  />
                ) : (
                  <FiPlay
                    onClick={() => {
                      handlePlayVideo();
                      setPlay({
                        id: ind,
                        status: true,
                        ref: true,
                      });
                    }}
                    className=" w-7 h-7 text-white cursor-pointer"
                  />
                )}
              </div> */}

                                <h1 className=" font-semibold text-lg pr-4">
                                  Name : {JSON.parse(item.metadata).name}
                                </h1>
                                <h1 className=" font-semibold text-lg pr-4">
                                  Date : {JSON.parse(item.metadata).dateCreated}
                                </h1>
                              </div>
                            ))}
                          </div>
                        )}

                        {/* </div> */}
                      </div>
                    </div>
                  </AnimatedDialog>
                )}
                {
                  accessLevel != null
                  &&
                  <div className="right flex aic">
                  {/* See template button */}
                  <button
                    className={`cleanbtn see-temp-btn flex aic font s15 color ml-2 ${
                      !previewTemplate.enablePreview ? 'sho' : 'hid'
                    }`}
                    onClick={() => setOpenCategoryDialog(true)}
                  >
                    <div
                      style={{
                        fontSize: '24px',
                        marginTop: '-5px',
                        marginRight: '3px',
                      }}
                    >
                      +{' '}
                    </div>{' '}
                    Add Category
                  </button>
                </div>
                }
               
              </div>
              <VirtualRooms
                community_id={community_id}
                meetingModal={meetingModal}
                setMeetingModal={setMeetingModal}
              />
              <div className="gap-5">
                {/* <div
              className="  "
              style={{
                width: '240px',
                paddingTop: '20px',
                paddingLeft: '24px',
              }}
            >
              <div
                onClick={() => setMeetingModal(true)}
                style={{
                  borderRadius: '10px',
                  height: '130px',
                  width: '180px',
                }}
                className=" bg-gray-100 cursor-pointer h-35 rounded-xl center flex items-center	justify-center transition-all  transform  hover:-translate-y-2  "
                // onClick={() => {
                //   setselectoption('recorder');
                //   handleOpen();
                //   _dispatch({
                //     type: 'SHOW_SCREEN_RECORDING',
                //     payload: true,
                //   });
                // }}
              >
                <RiVideoChatFill className="w-7 h-7 text-pr" />
              </div>

              <div>
                <div className="flex items-center justify-center">
                  New Meeting
                </div>
              </div>
            </div> */}
                <div
                  style={{ overflowX: 'auto' }}
                  className=" w-full flex gap-5 pt-4  "
                >
                  {/* {isCustomVideosLoading ? (
            <p>Loading</p>
          ) : (
            customVideosData?.map((videoData, idx) => {
              return (
                <SampleVideoLibraryItem
                  screen={videoData}
                  setScreen={(payload) =>
                    setCustomVideosDataAtIndex(payload, idx)
                  }
                  onThumbnailClick={() => {
                    console.log('PREVIEW VIDEO INDEX SET TO ', idx);
                    setPreviewVideoIndex(idx);
                  }}
                />
              );
            })
          )} */}

                  <div
                    className="  "
                    style={{
                      // width: '240px',
                      // paddingTop: '20px',
                      paddingLeft: '24px',
                    }}
                  >
                    <div
                      onClick={() => setOpenShareDialog(true)}
                      style={{
                        borderRadius: '10px',
                        height: '130px',
                        width: '180px',
                      }}
                      className=" bg-gray-100 cursor-pointer h-35 rounded-xl center flex items-center	justify-center transition-all  transform  hover:-translate-y-2  "
                      // onClick={() => {
                      //   setselectoption('recorder');
                      //   handleOpen();
                      //   _dispatch({
                      //     type: 'SHOW_SCREEN_RECORDING',
                      //     payload: true,
                      //   });
                      // }}
                    >
                      <FaShareAltSquare className="w-8 h-8 text-pr mr-1" />
                      {selectedVideos?.length > 0 ? (
                        <div
                          className="rounded-sm text-xs align-center justify-center"
                          style={{
                            backgroundColor: '#3898ec',
                            color: 'white',
                            height: '20px',
                            marginRight: '5px',
                            paddingTop: '1px',
                          }}
                        >
                          <span className="m-1">{selectedVideos?.length}</span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div>
                      <div className="flex items-center justify-center">
                        Share Tour
                      </div>
                    </div>
                  </div>

                  {/* <div
              className="  "
              style={{
                // width: '240px',
                // paddingTop: '20px',
                paddingLeft: '24px',
              }}
            >
              <div
                onClick={() => setOpenShareDialog(true)}
                style={{
                  borderRadius: '10px',
                  height: '130px',
                  width: '180px',
                }}
                className=" bg-gray-100 cursor-pointer h-35 rounded-xl center flex items-center	justify-center transition-all  transform  hover:-translate-y-2  "
                // onClick={() => {
                //   setselectoption('recorder');
                //   handleOpen();
                //   _dispatch({
                //     type: 'SHOW_SCREEN_RECORDING',
                //     payload: true,
                //   });
                // }}
              >
                <RiCalendar2Fill  className="w-7 h-7 text-pr" />
              </div>

              <div>
                <div className="flex items-center justify-center">
                  Schedule a Tour
                </div>
              </div>
            </div> */}
            
                  <div
                    className="  "
                    style={{ width: '240px', paddingLeft: '12px' }}
                  >
                    <div
                      style={{
                        borderRadius: '10px',
                        height: '130px',
                        width: '200px',
                      }}
                      className=" bg-gray-100 cursor-pointer h-35 rounded-xl center flex items-center	justify-center transition-all  transform  hover:-translate-y-2  "
                      onClick={() => {
                        if(accessLevel != null) {
                          setselectoption('special-stepper');
                        }
                        // handleOpen(); opens the screen recording modal for some reason
                      }}
                    >
                      {/* <img
                className=" w-7 h-7"
                src="https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/e2c7c9de-2a17-4d36-b6a7-78b1d97a2100/w=40,sharpen=1"
              /> */}
                      <div className="flex flex-col items-center justify-center p-2">
                        <span className="text-blue-400 font-bold text-sm">
                          {template?.default_config?.specialOffer ||
                            'Add Special Offer'}
                          <br />
                          {template?.default_config?.specialOfferExpiryDate && (
                            <span className="text-gray-500 font-semibold text-sm">
                              Expires{' '}
                              {template?.default_config?.specialOfferExpiryDate}
                            </span>
                          )}
                        </span>
                        <span className="text-gray-500 font-semibold text-sm">
                          {template?.default_config?.specialOffer
                            ? ''
                            : 'to increase conversion'}
                        </span>
                      </div>
                    </div>

                    <div>
                      <div className="flex items-center justify-center">
                        {template?.default_config?.specialOffer
                          ? 'Update Special Offer'
                          : 'Create Special Offer'}
                      </div>
                    </div>
                  </div>

                  {magnetId && template && (
                    <SharableTourList
                      magnet_uuid={magnetId}
                      community_id={communityId}
                      generateSharableTourUrl={generateSharableTourUrl}
                      setSelectedVideos={setSelectedVideos}
                      setOpenShareDialog={setOpenShareDialog}
                    />
                  )}
                </div>
              </div>

              {/* Tour Library END */}
              {/* <hr style={{ color: '#ebf0f5' }} className="mx-4" /> */}

              {/* <span className="text-sm font-bold mt-4 mb-1 ml-4">Tour Library</span> */}
              <div className="content-body pb-5">
                <div
                  key={
                    encodingQ.join('-') +
                    '-' +
                    currVid?.map((v) => v.source).join('-')
                  }
                >
                  {magnetLoading? (
                    <>
                      <div className="sumary-body holders">
                        <div className="row-title holder" />
                        <div className="sumary-holders-wrapper">
                          <div className="magnet-item  holder" />
                          <div className="magnet-item  holder" />
                          <div className="magnet-item  holder" />
                          <div className="magnet-item  holder" />
                          <div className="magnet-item  holder" />
                          {/* <div className="magnet-item  holder" /> */}
                          {/* <div className="magnet-item  holder" /> */}
                        </div>
                        <div className="row-title holder" />
                        <div className="sumary-holders-wrapper">
                          <div className="magnet-item  holder" />
                          <div className="magnet-item  holder" />
                          <div className="magnet-item  holder" />
                          <div className="magnet-item  holder" />
                          <div className="magnet-item  holder" />
                          {/* <div className="magnet-item  holder" /> */}
                          {/* <div className="magnet-item  holder" /> */}
                        </div>
                      </div>
                    </>
                  ) : (
                    template?.category_keys?.map(
                      (category_key, category_idx) => {
                        // if (searchFilter?.query) {
                        //   let hasScreenKeyFound = true;
                        //   Object.keys( template?.category_keys?.[category_key]?.screens ).map((screen, screen_idx) => {

                        //   })
                        // }

                        let filteredScreenKeys =
                          template?.categories?.[
                            category_key
                          ]?.screen_keys.filter((screen_key) => {
                            // SEARCH FILTER
                            const screen =
                              template?.categories?.[category_key]?.screens?.[
                                screen_key
                              ] || {};
                            let searchResults = false;
                            //console.log(`${category_key}.${screen_key} original obj`);

                            // BASIC SEARCH QUERY not found
                            if (
                              screen?.title
                                ?.toLowerCase()
                                .includes(searchFilter.query.toLowerCase())
                            ) {
                              // console.log(
                              //   `screen key ${category_key}.${screen_key} missing: query`
                              // );
                              searchResults = true;
                            }

                            // BASIC SEARCH CAPTION not found
                            if (
                              screen?.caption
                                ?.toLowerCase()
                                .includes(searchFilter.query.toLowerCase())
                            ) {
                              // console.log(
                              //   `screen key ${category_key}.${screen_key} missing: caption`
                              // );
                              searchResults = true;
                            }

                            if (screen?.additionalInfo) {
                              // DETAILED DESCRIPTION
                              if (
                                screen?.additionalInfo.detailedDescription &&
                                screen?.additionalInfo.detailedDescription
                                  .toLowerCase()
                                  .includes(searchFilter.query.toLowerCase())
                              ) {
                                // console.log(
                                //   `screen key ${category_key}.${screen_key} missing: detailedDescription`
                                // );
                                searchResults = true;
                              }

                              // BASIC CAPTION SEARCH IF ADDITIONAL INFO IS VALID
                              if (screen?.additionalInfo.features) {
                                for (
                                  let i = 0;
                                  i < screen?.additionalInfo.features.length;
                                  i++
                                ) {
                                  if (
                                    screen?.additionalInfo.features[i]
                                      .toLowerCase()
                                      .includes(
                                        searchFilter.query.toLowerCase()
                                      )
                                  ) {
                                    searchResults = true;
                                  }
                                }
                              }
                            }

                            return searchResults;
                          }) || [];

                        if (
                          searchFilter?.query &&
                          filteredScreenKeys?.length === 0
                        ) {
                          return <></>;
                        } // This statement does not let the categories with 0 screens to be rendered as MagnetSummaryRow.

                        return (
                          <MagnetSummaryRow
                            communityId={communityId}
                            accessLevel={accessLevel}
                            analyticsdata={analyticsdata}
                            handleSnackbar={handleSnackbar}
                            selectedVideos={selectedVideos}
                            setSelectedVideos={setSelectedVideos}
                            category_key={category_key}
                            searchFilter={searchFilter}
                            filteredScreenKeys={filteredScreenKeys}
                            dragItem={reorderCategories}
                            index={category_idx}
                            key={category_idx}
                            reorderCategories={reorderCategories}
                            openMove={openMove}
                            magnetLoading={magnetLoading}
                            currVid={currVid}
                            setCurrVid={setCurrVid}
                          />
                        );
                      }
                    )
                  )}

                  {/* <div className="divider" /> */}

                  {/* Plugins divider */}
                  <AnimatedDialog
                    open={managePluginsModal}
                    onClose={() => setManagePluginsModal(false)}
                    fullWidth
                    maxWidth="sm"
                  >
                    <div className=" p-4">
                      <div className=" flex items-center justify-between w-full pb-3">
                        {managePluginsStep === 0 && (
                          <h1 className="text-2xl font-semibold">
                            Manage your Plugins
                          </h1>
                        )}
                        {managePluginsStep === 1 && (
                          <h1 className="text-2xl font-semibold">
                            ✅ Your plugin is ready
                          </h1>
                        )}
                        {/* on close reset the plugins */}
                        <MdOutlineClose
                          onClick={() => {
                            setManagePluginsModal(false);
                            setManagePluginsStep(0);
                          }}
                          className=" w-8 h-8 cursor-pointer"
                        />
                      </div>

                      {managePluginsStep === 0 && title !== '' ? (
                        <button
                          onClick={() => setMeetingStep(1)}
                          className="rounded-md bg-pr border-none outline-none w-full py-3 cursor-pointer text-white font-semibold mt-4"
                        >
                          Save
                        </button>
                      ) : (
                        <>
                          {managePluginsStep === 0 && (
                            <button
                              disabled
                              className="rounded-md bg-blue-200 border-none outline-none w-full py-3 cursor-not-allowed text-gray-500 font-semibold mt-4"
                              onClick={() => setManagePluginsStep(1)}
                            >
                              Save
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </AnimatedDialog>

                  {/* <div className="divider" /> */}
                  {/* <span className="text-sm font-bold mt-4 mb-6 ml-4" style={{ marginBottom: "10px"}}>Plugins</span>
          <div>
              <div className="content-start flex pt-4 pb-5">
                  
                  
                <div
                  style={{
                    // width: '240px',
                    // paddingTop: '20px',
                    paddingLeft: '24px',
                  }}
                >
                  <div
                    onClick={() => setManagePluginsModal(true)}
                    style={{
                      borderRadius: '10px',
                      height: '130px',
                      width: '180px',
                    }}
                    className=" bg-gray-100 cursor-pointer h-35 rounded-xl center flex items-center	justify-center transition-all  transform  hover:-translate-y-2  "
                    // onClick={() => {
                    //   setselectoption('recorder');
                    //   handleOpen();
                    //   _dispatch({
                    //     type: 'SHOW_SCREEN_RECORDING',
                    //     payload: true,
                    //   });
                    // }}
                  >
                    
                    <FiPlusCircle className="w-6 h-6 text-pr"  />
                  </div>

                  <div>
                    <div className="flex items-center justify-center">
                      New/Manage Plugin
                    </div>
                  </div>
                </div>



              </div>
              
          </div> */}

                  {/* <div className="content-body pb-5">
            <div className="sumary-body holders">
              <div className="row-title holder" />
              <div className="sumary-holders-wrapper">
                <div className="magnet-item  holder" />
                <div className="magnet-item  holder" />
                <div className="magnet-item  holder" />
              </div>
            </div>
          </div>  */}

                  {/* <span className="text-sm font-bold mt-4 mb-1 ml-4">Forms</span>
                
                <div className='flex content-start'  style={{ paddingTop: "12px" }}>
                  <div
                    className="  "
                    style={{
                      // width: '240px',
                      // paddingTop: '20px',
                      paddingLeft: '24px',
                    }}
                  >
                    <div
                      onClick={() => setManagePluginsModal(true)}
                      style={{
                        borderRadius: '10px',
                        height: '130px',
                        width: '180px',
                      }}
                      className=" bg-gray-100 cursor-pointer h-35 rounded-xl center flex items-center	justify-center transition-all  transform  hover:-translate-y-2  "
                      // onClick={() => {
                      //   setselectoption('recorder');
                      //   handleOpen();
                      //   _dispatch({
                      //     type: 'SHOW_SCREEN_RECORDING',
                      //     payload: true,
                      //   });
                      // }}
                    >
                      
                      <FiPlusCircle className="w-6 h-6 text-pr"  />
                    </div>

                    <div>
                      <div className="flex items-center justify-center">
                        New/Manage Forms
                      </div>
                    </div>
                  </div>

                </div> */}

                  {/* <div className="content-body pb-5">
                <div className="sumary-body holders">
                  <div className="row-title holder" />
                  <div className="sumary-holders-wrapper">
                    <div className="magnet-item  holder" />
                    <div className="magnet-item  holder" />
                    <div className="magnet-item  holder" />
                  </div>
                </div>
              </div> */}
                </div>

                {/* <button onClick={() => console.log(selectedVideos)}>
          print selected Videos
        </button> */}
                <div
                  className={styles.share_popup_overlay}
                  style={{ display: openShare ? 'flex' : 'none' }}
                >
                  <div className={styles.share_popup_container}>
                    <div className={styles.share_popup_header}>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          cursor: 'pointer',
                          position: 'absolute',
                          left: '25px',
                          top: '25px',
                        }}
                        onClick={() => {
                          setOpenShare(false);
                          setCustomTourStep(1);
                          setCopySuccess(false);
                        }}
                      >
                        <path
                          d="M2 2L15.9901 16"
                          stroke="black"
                          stroke-width="2.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16 2L2 15.9901"
                          stroke="black"
                          stroke-width="2.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <svg
                        width="46"
                        height="7"
                        viewBox="0 0 46 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginTop: '6.5px' }}
                        transform={customTourStep === 2 ? 'rotate(180)' : ''}
                      >
                        <rect width="31" height="7" rx="3.5" fill="#347DEF" />
                        <circle cx="42.5" cy="3.5" r="3.5" fill="#C4C4C4" />
                      </svg>
                    </div>
                    <div className={styles.share_popup_title}>
                      <span style={{ color: '#347DEF' }}>Share</span> this Tour
                    </div>
                    <div
                      className={styles.share_popup_title}
                      style={{ fontSize: '14px', margin: '0' }}
                    >
                      {customTourStep === 1
                        ? 'Who are you sharing the customized tour with?'
                        : 'Copy the link to share'}
                    </div>
                    {customTourStep === 1 && (
                      <>
                        <div className={styles.share_popup_dropdown}>
                          Agent Name:
                          <TextField
                            id="outlined-basic"
                            select
                            label=""
                            variant="outlined"
                            value={agentName}
                            onClick={(e) => {
                              console.log('agent selector: ', e.target.value);
                              const eTarget = e.target.value;
                              if (eTarget !== undefined) {
                                setAgentName(eTarget);
                              }
                            }}
                            style={{ width: '100%', marginTop: '5px' }}
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            {template &&
                              template?.magnetSettings?.agents &&
                              template?.magnetSettings.agents.map(
                                (agent, agent_idx) => {
                                  return (
                                    <MenuItem
                                      key={agent.name}
                                      value={agent_idx}
                                    >
                                      {typeof agent.name == 'object'
                                        ? 'object'
                                        : agent.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </TextField>
                        </div>

                        <div className={styles.share_popup_dropdown}>
                          {/* Lead Name: (optional) */}
                          <DebouncedTextField
                            id="outlined-basic"
                            label=""
                            placeholder="ie: Jim"
                            variant="outlined"
                            value={shareName}
                            onChange={setShareName}
                            style={{ width: '100%', marginTop: '5px' }}
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>

                        {openrecorder && !open && showScreenRecording && (
                          <SendRecording
                            cloudLibrary={shareCloud}
                            setCloudLibrary={setShareCloud}
                            currVid={currVid}
                          />
                        )}

                        <div className="advanced-upload-btns ">
                          <button
                            className="cleanbtn record-new-video-btn flex aic"
                            style={{ width: '100%' }}
                            onClick={() => {
                              setOpenrecorder(true);
                              _dispatch({
                                type: 'SHOW_SCREEN_RECORDING',
                                payload: true,
                              });
                            }}
                          >
                            <div
                              className={`${
                                shareCloud.length >= 1
                                  ? 'grey-record-icon'
                                  : 'record-icon'
                              } `}
                            />
                            <div className="font s14 b5 c333">
                              {shareCloud.length >= 1
                                ? `${shareCloud.length} video recorded`
                                : 'Record personal video (optional)'}
                            </div>
                          </button>
                        </div>
                        <div
                          className={styles.share_popup_next}
                          style={{
                            background:
                              agentName !== '' && shareName !== ''
                                ? '#347DEF'
                                : '#8498A4',
                            cursor:
                              agentName !== '' && shareName !== ''
                                ? 'pointer'
                                : 'not-allowed',
                          }}
                          onClick={
                            agentName !== undefined &&
                            shareName &&
                            (() => setCustomTourStep(2))
                          }
                        >
                          Next
                        </div>
                      </>
                    )}
                    {customTourStep === 2 && (
                      <>
                        <div className={styles.share_popup_dropdown}>
                          Start sharing your tour by copying the web link below:
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: '5px',
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              label=""
                              placeholder="ie: Jim"
                              variant="outlined"
                              value={`https://app.usetour.com/customtour?magnet_uuid=${magnetId}&share=${selectedVideos.join(
                                ','
                              )}&agent=${encodeURI(agentName)}&name=${encodeURI(
                                shareName
                              )}&intro_video=${encodeURI(
                                introVideo
                              )}&hyper_iframe=${hyperIframe}`}
                              style={{ width: '65%' }}
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              ref={textAreaRef}
                              disabled
                            />
                            {document.queryCommandSupported('copy') && (
                              <div
                                className={styles.share_popup_next}
                                style={{
                                  background:
                                    copySuccess == 1 ? '#EEF1F2' : '#347DEF',
                                  color: copySuccess == 1 ? 'black' : 'white',
                                  margin: 0,
                                }}
                                onClick={copyToClipboard}
                              >
                                {copySuccess == 1 ? 'Copied!' : 'Copy'}
                              </div>
                            )}
                          </div>
                          <br />
                          Instagram stories
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: '5px',
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              label=""
                              placeholder="ie: Jim"
                              variant="outlined"
                              value={`https://share.tour.video/link?magnet_uuid=${magnetId}&share=${selectedVideos.join(
                                ','
                              )}&agent=${encodeURI(agentName)}&name=${encodeURI(
                                shareName
                              )}&intro_video=${encodeURI(
                                introVideo
                              )}&hyper_iframe=${hyperIframe}`}
                              style={{ width: '65%' }}
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              ref={textAreaRef}
                              disabled
                            />
                            {document.queryCommandSupported('copy') && (
                              <div
                                className={styles.share_popup_next}
                                style={{
                                  background:
                                    copySuccess == 2 ? '#EEF1F2' : '#347DEF',
                                  color: copySuccess == 2 ? 'black' : 'white',
                                  margin: 0,
                                }}
                                onClick={copyToClipboard2}
                              >
                                {copySuccess == 2 ? 'Copied!' : 'Copy'}
                              </div>
                            )}
                          </div>
                          <div
                            className={`${styles.share_popup_next}`}
                            style={{
                              background:
                                copySuccess == 2 ? '#EEF1F2' : '#EEF1F2',
                              color: copySuccess == 2 ? 'black' : 'black',
                              margin: 0,
                              width: '80%',
                              marginTop: '5px',
                            }}
                            onClick={() => setopeniframedialogue(true)}
                          >
                            + Generate intro video with text
                          </div>
                        </div>
                        {shareCloud.length >= 1 && (
                          <div>
                            <video
                              controls
                              autoPlay
                              src={shareCloud[0].dataURL}
                              style={{ width: '100%' }}
                            />
                            <button
                              onClick={async () => {
                                const localUrl = shareCloud[0].dataURL;
                                const firebaseUrl =
                                  await uploadVideoFromLocalUrl(localUrl);
                                console.log('firebaseURL Test: ', firebaseUrl);
                                await setShareCloud([
                                  {
                                    dataURL: firebaseUrl,
                                    name: 'test_video_TYG',
                                    prefix: '/route/TYG/',
                                    type: 'video/mp4',
                                    origName: 'test_video_TYG.mp4',
                                  },
                                ]);
                                await setIntroVideo(firebaseUrl);
                              }}
                            >
                              + add the video to shared tour page
                            </button>
                          </div>
                        )}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '50px',
                          }}
                        >
                          <span
                            style={{ color: '#8498A4', cursor: 'pointer' }}
                            onClick={() => setCustomTourStep(1)}
                          >
                            Previous Step
                          </span>
                          <div
                            className={styles.share_popup_next}
                            style={{
                              float: 'none',
                              margin: 0,
                              background: copySuccess ? '#347DEF' : '#8498A4',
                            }}
                            onClick={() => {
                              setOpenShare(false);
                              setSelectedVideos([]);
                              setCustomTourStep(1);
                              setCopySuccess(false);
                            }}
                          >
                            Done!
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {/* Share Footer bar */}
                <div
                  className={`share-footer-container ${
                    selectedVideos.length > 0 && !openShare ? 'open' : 'close'
                  }`}
                >
                  <div className="selected-videos flex aic">
                    <div className="ico icon-check1 s16 black" />
                    <div className="font s14 b5 black">
                      {selectedVideos.length} video
                      {selectedVideos.length !== 1 && 's'} selected
                    </div>
                  </div>
                  <div
                    className="action-btn flex flex-col aic"
                    onClick={() => setOpenShareDialog(true)}
                  >
                    <div className="ico icon-share2 anim" />
                    <div className="lbl font s14 black anim">Share</div>
                  </div>
                  {/* <div
              className="action-btn  flex flex-col aic"
              onClick={() => setOpenMove(true)}
            >
              <div className="ico icon-move anim" />
              <div className="lbl font s14 black anim">Move</div>
            </div> */}
                  <div
                    className="cross-btn anim font"
                    onClick={() => {
                      setSelectedVideos([]);
                      setOpenMove(false);
                    }}
                  >
                    &times;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {tourTab === 'customize' && (
        <>
          <TalkAndTriggers
            community_id={community_id}
            magnet_uuid={magnetId}
            name={communityName}
            title="Talk & Triggers"
          />
        </>
      )}

      {tourTab === 'videolibrary' && (
        <>
          <VideoLibrary
            community_id={community_id}
            magnet_uuid={magnetId}
            title="Video Library"
          />
        </>
      )}

      {tourTab === 'embed' && (
        <>
          <Embed
            community_id={community_id}
            magnet_uuid={magnetId}
            title="Embed"
          />
        </>
      )}

      {tourTab === 'settings' && (
        <>
          <MagnetSettings
            community_id={community_id}
            name={communityName}
            magnet_uuid={magnetId}
            title="Settings"
          />
        </>
      )}

      {tourTab === 'engage' && (
        <>
          <Engage
            chatMe={me}
            community_id={community_id}
            communityName={communityName}
            magnet_uuid={magnetId}
            activevisitors={activevisitors}
            setactivevisitors={setactivevisitors}
            allvisitorsdata={allvisitorsdata}
            channelref={channelref}
            defaultVisitorUuid={defaultVisitorUuid}
            defaultVisitorData={defaultVisitorData}
            accessLevel={accessLevel}
          />
        </>
      )}


      {/* {tourTab === 'leads' && (
          <>
            <SimpleLeads
              showTitle={false}
              showLeadCount={false}
              //setLeadCount={setLeadCount}
              magnet_uuid={magnetId}
              name={name}
            />
          </>
        )} */}
      {tourTab === 'analytics' && (
        <>
          <Analytics3
            community_id={community_id}
            magnet_uuid={magnetId}
            title="Analytics"
            url="http:/"
          />
        </>
      )}

      {/* Delete Screen Dialog */}
      <DeleteScreenDialog
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        selectedVideos={selectedVideos}
      />
      {/* See Templates Dialogbox */}
      <TemplateDialogbox
        _templates={_templates}
        previewTemplate={previewTemplate}
      />
      {/* Show Confirm Template Dialog */}
      <ConfirTemplateDialog
        previewTemplate={previewTemplate}
        magnetData={magnetalldata}
      />
    </div>
  );

  function SampleVideoLibraryItem({ screen, setScreen, onThumbnailClick }) {
    if (screen?.img || screen?.gif) {
      return (
        <div
          className=" items-center align-baseline		"
          style={{ width: '240px' }}
        >
          <div
            style={{
              //backgroundImage: `url("https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/15e4f413-1e57-4c59-c7eb-10010c789f00/public")`,
              backgroundImage: `url(${screen?.img || screen?.gif})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderRadius: '10px',
              height: '130px',
              width: '180px',
            }}
            className="w-full cursor-pointer h-35 rounded-xl center flex items-center	 transition-all  transform  hover:-translate-y-2  "
            onClick={onThumbnailClick}
          >
            <div className="absolute top-0 left-0">
              <input
                type="checkbox"
                className="cursor-pointer w-5 h-5 m-2 hover:opacity-100  "
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-center text text-sm mt-2">
              {screen?.title}
            </div>
            {/* 
            <div className="flex items-center justify-center text-gray-500 ">
              <div className="ico icon-play" />
              <span className="text-xs">145</span>
            </div> */}
          </div>
        </div>
      );
    }

    if (screen?.video) {
      return (
        <div
          className="items-center align-baseline		"
          style={{ width: '240px', height: '131px' }}
        >
          <video
            style={{
              borderRadius: '10px',
              minHeight: '120px',
              minWidth: '173px',
              display: 'relative',
              objectFit: 'cover',
            }}
            className="cursor-pointer rounded-xl center flex items-center	 transition-all  transform  hover:-translate-y-2  "
            muted
            autoPlay
            loop
            src={screen?.video}
            onClick={onThumbnailClick}
          >
            <div className="absolute top-0 left-0 z-10">
              <input
                type="checkbox"
                className="cursor-pointer w-5 h-5 m-2 hover:opacity-100  "
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </video>

          <div>
            <div className="flex items-center justify-center text text-sm mt-2">
              {screen?.title}
            </div>

            {/* <div className="flex items-center justify-center text-gray-500 ">
              <div className="ico icon-play" />
              <span className="text-xs">145</span>
            </div> */}
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
};

export default MagnetSummary;

const DeleteScreenDialog = React.memo(({ openDelete, setOpenDelete }) => {
  const { magnetId, magnetType } = useSelector((state) => state.magnetStates);
  const _dispatch = useDispatch();
  {
    /* Show Confirm Template Dialog */
  }
  return (
    <AnimatedDialog
      open={openDelete}
      onClose={() => setOpenDelete(false)}
      fullWidth
      maxWidth="xs"
    >
      <div className="add-category-dialog">
        <div className="hdr flex aic">
          <div className="title font s18 b6 c000">Delete Screen</div>
          <button
            className="cross-btn cleanbtn fontr"
            onClick={() => setOpenDelete(false)}
          >
            &times;
          </button>
        </div>
        <div className="wrap flex flex-col">
          <div className="txt font s15 black">
            Are you sure you want to delete this screen?
          </div>
        </div>
        <div className="ftr flex aic">
          <button
            className="cleanbtn add-cata font s15 cfff"
            onClick={() => {
              selectedVideos.map((video) => {
                const screen_info = video.split('.');
                _dispatch({
                  type: 'removeScreen',
                  payload: {
                    screen_key: screen_info[1],
                    category_key: screen_info[0],
                    magnet_id: magnetId,
                    magnet_type: magnetType,
                  },
                });
              });
              setOpenDelete(false);
            }}
          >
            Confirm
          </button>
          <button
            className="cleanbtn add-cata cancel font s15 c333"
            onClick={() => setOpenDelete(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </AnimatedDialog>
  );
});

const TemplateDialogbox = React.memo(({ _templates, previewTemplate }) => {
  const _dispatch = useDispatch();
  {
    /* Show Confirm Template Dialog */
  }
  return (
    <AnimatedDialog
      open={previewTemplate.showTemplatesDialog}
      onClose={() => {
        _dispatch({
          type: 'PREVIEW_TEMPLATE',
          payload: { ...previewTemplate, showTemplatesDialog: false },
        });
      }}
    >
      <div className="see-temp-section flex flex-col rel">
        <div className="hdr flex aic sticky">
          <div className="title font s16 b6 c000">Select a template</div>
          <div
            className="cross-btn flex aic s24 c333 anim"
            onClick={() => {
              _dispatch({
                type: 'PREVIEW_TEMPLATE',
                payload: { ...previewTemplate, showTemplatesDialog: false },
              });
            }}
          >
            &times;
          </div>
        </div>
        <div className="temp-content flex aic">
          {_templates.map((item, idx) => (
            <div
              key={idx}
              className="temp-item"
              onClick={() => {
                _dispatch({
                  type: 'PREVIEW_TEMPLATE',
                  payload: {
                    ...previewTemplate,
                    showConfirmTemplateDialog: true,
                    selectedTemplate: item,
                  },
                });
              }}
            >
              <div className="media rel">
                <div
                  className="image"
                  style={{ backgroundImage: `url(${item.image})` }}
                />
                <div className="overylay abs flex aic anim">
                  <div className="txt font s13 cfff anim">Select Template</div>
                </div>
              </div>
              <div className="meta">
                <div className="lbl font s14 b5 c333 wordwrap">{item.name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </AnimatedDialog>
  );
});

const ConfirTemplateDialog = React.memo(({ previewTemplate, magnetData }) => {
  const _dispatch = useDispatch();
  {
    /* Show Confirm Template Dialog */
  }
  return (
    <AnimatedDialog
      open={previewTemplate.showConfirmTemplateDialog}
      onClose={() => {
        _dispatch({
          type: 'PREVIEW_TEMPLATE',
          payload: { ...previewTemplate, showConfirmTemplateDialog: false },
        });
      }}
    >
      <div className="confirm-temp-sec rel">
        <div className="hdr flex aic sticky">
          <div className="title font s16 b6 c000">Confirm Template</div>
          <div
            className="cross-btn flex aic s22 c333 anim"
            onClick={() => {
              _dispatch({
                type: 'PREVIEW_TEMPLATE',
                payload: {
                  ...previewTemplate,
                  showConfirmTemplateDialog: false,
                },
              });
            }}
          >
            &times;
          </div>
        </div>
        <div className="content">
          <div className="font s15 c333">
            Are you sure you want to clone of{' '}
            <span className="b6">{previewTemplate.selectedTemplate?.name}</span>{' '}
            template ?
          </div>
        </div>
        <div className="actions flex aic">
          <button
            className="btn cancel font s15 anim"
            onClick={() => {
              _dispatch({
                type: 'PREVIEW_TEMPLATE',
                payload: {
                  ...previewTemplate,
                  showConfirmTemplateDialog: false,
                  showTemplatesDialog: true,
                  enablePreview: false,
                },
              });
            }}
          >
            Cancel
          </button>
          <button
            className="btn font s15 anim"
            onClick={() => {
              console.log('magnetData', magnetData);
              window.oldmagnetDatajson = magnetData?.magnets[0];
              _dispatch({
                type: 'PREVIEW_TEMPLATE',
                payload: {
                  ...previewTemplate,
                  showTemplatesDialog: false,
                  showConfirmTemplateDialog: false,
                  enablePreview: true,
                },
              });
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </AnimatedDialog>
  );
});

function VideoTitleBar({ videoTitle, onTitleSave, onDelete }) {
  const [title, setTitle] = useState(videoTitle);
  return (
    <div className="relative flex w-full flex-wrap items-stretch z-40 p-2">
      <div className="w-full py-1 px-2 rounded flex gap-4 bg-white">
        <input
          className="w-full"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <button
          className="text-blue-400 hover:underline"
          onClick={() => {
            if (onTitleSave) onTitleSave(title);
          }}
        >
          Save
        </button>
        <button
          className="text-red-400 hover:underline"
          onClick={() => {
            if (onDelete) {
              onDelete();
              onClose();
            }
          }}
        >
          Delete
        </button>
      </div>
    </div>
  );
}

const updateDefaultConfig = async (newConfigValues = {}) => {
  const { community_id } = useParams();
  const { template } = useSelector((state) => state.getMagnet);
  const { magnetId: magnetUuid } = useSelector((state) => state.magnetStates);

  console.log('saving_default_config', {
    ...template?.default_config,
    ...newConfigValues,
  });
  post(
    '/magnets/template/updateMagnet/dynamic/defaultEmbedupdated',
    {
      magnet_uuid: magnetUuid,
      default_config: {
        ...template?.default_config,
        ...newConfigValues,
      },
    },
    {
      auth: false,
      //, host: "http://localhost:8080"
    }
  )
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.

      toast(
        props?.toastMessage
          ? props?.toastMessage
          : `Successfully Saved CTAs & Special Offers`,
        {
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        }
      );
    })
    .catch(() => {
      toast('No internet connection found. App is running in offline mode.', {
        position: 'top-center',
        type: 'success',
        autoClose: 5000,
      });
    });
};
