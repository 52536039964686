import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import RouteInput from '../../components/Reusable/RouteInput';
import VideoMagnet from '../../components/VideoMagnet/VideoMagnet';
import PreviewVideoMagnet from '../manage-magnets/PreviewVideoMagnet';
import { ButtonPreview } from './ButtonPreview';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
import { toast } from 'react-toastify';

export function BottomBarConfig({
  routeOpts,
  magnetUuid,
  template,
  config,
  setConfig,
  saveChatbot,
}) {
  const [defaultRoute, setDefaultRoute] = useState(routeOpts[0] || '');
  const [bottomBarButtons, setBottomBarButtons] = useState(
    defaultBottomBarButtons
  );
  const [newRouteInput, setNewRouteInput] = useState({});
  const [newButtonText, setNewButtonText] = useState('');
  const [selectedIcon, setSelectedIcon] = useState(null);

  useEffect(() => {
    if (
      (!bottomBarButtons || bottomBarButtons?.length === 0) &&
      config?.bottomBarButtons
    ) {
      setBottomBarButtons(config.bottomBarButtons || []);
    }
  }, [config]);

  const addBottomBarButton = (buttonObj) => {
    const newBottomBarButtonState = [...bottomBarButtons, buttonObj];
    setBottomBarButtons(newBottomBarButtonState);
    // Add the new bottomBarButton into default config
    setConfig((prev) => {
      return {
        ...prev,
        bottomBarButtons: newBottomBarButtonState,
      };
    });
  };

  const removeBottomBarButton = (buttonIndex) => {
    const newBottomBarButtons = bottomBarButtons.filter(
      (button, index) => index !== buttonIndex
    );
    setBottomBarButtons(newBottomBarButtons);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const deleteButton = (id) => {
    const newBottomBarButtonsState = bottomBarButtons.filter(
      (button) => button.id !== id
    );

    // setBottomBarButtons((buttons) => {
    //   return buttons.filter((button) => button.id !== id);
    // });

    setBottomBarButtons(newBottomBarButtonsState);

    setConfig((prev) => {
      return {
        ...prev,
        bottomBarButtons: newBottomBarButtonsState,
      };
    });
  };

  function handleDragEnd(event) {
    const { active, over } = event;

    if (!over?.id) return;
    if (active.id !== over?.id) {
      setBottomBarButtons((bottomBarButtons) => {
        const oldIndex = bottomBarButtons.findIndex(
          (button) => button.id === active.id
        );
        const newIndex = bottomBarButtons.findIndex(
          (button) => button.id === over.id
        );

        return arrayMove(bottomBarButtons, oldIndex, newIndex);
      });
    }
  }

  function showErrorToast(msg) {
    toast.error(msg, {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: 'colored',
    });
  }

  // const { magnetId, magnetType } = useSelector((state) => state.magnetStates);

  return (
    <div className={` grid grid-cols-2 gap-4 p-3`} style={{ minHeight: 700 }}>
      <div className="flex flex-col justify-center gap-4 w-full">
        <TextField
          value={newButtonText}
          onChange={(e) => {
            setNewButtonText(e.target.value);
          }}
          placeholder="Button Title"
        />
        <RouteInput
          routes={routeOpts}
          enableHrefUnderlay={true}
          onChange={(value) => setNewRouteInput(value)}
          value={newRouteInput || ''}
        />
        <div>
          <p className="mb-1 text-gray-600">Choose an Icon</p>
          <div className="flex flex-row p-2 items-center border rounded bg-gray-50 gap-2">
            <div
              className={`${
                selectedIcon === 'money' ? 'bg-blue-200 p-1 rounded-xl' : ''
              }`}
              onClick={() => {
                setSelectedIcon('money');
              }}
            >
              <DollarSign
                className="cursor-pointer text-gray-500 hover:text-black"
                style={{ width: 32, height: 32 }}
              />
            </div>

            <div
              className={`${
                selectedIcon === 'phone' ? 'bg-blue-200 p-1 rounded-xl' : ''
              }`}
              onClick={() => {
                setSelectedIcon('phone');
              }}
            >
              <Phone
                className="cursor-pointer text-gray-500 hover:text-black"
                style={{ width: 32, height: 32 }}
              ></Phone>
            </div>

            <div
              className={`${
                selectedIcon === 'calendar' ? 'bg-blue-200 p-1 rounded-xl' : ''
              }`}
              onClick={() => {
                setSelectedIcon('calendar');
              }}
            >
              <Calendar
                style={{ width: 32, height: 32 }}
                className="cursor-pointer text-gray-500 hover:text-black"
              ></Calendar>
            </div>
          </div>
        </div>
        <button
          className="bg-blue-500 hover:bg-blue-600 active:bg-blue-700 w-full py-2 px-4 text-white rounded"
          onClick={() => {
            console.log({ newRouteInput });
            if (!newButtonText) {
              showErrorToast('Please define the button title first !');
              return;
            }
            if (!selectedIcon) {
              showErrorToast('Please select an icon first !');
              return;
            }
            let buttonObj = {
              text: newButtonText,
              id: crypto.randomUUID(),
              icon: selectedIcon,
            };
            if (newRouteInput?.route) {
              buttonObj.route = newRouteInput?.route;
              addBottomBarButton(buttonObj);
              setNewRouteInput({});
              setNewButtonText('');
              setSelectedIcon(null);
              return;
            }
            if (newRouteInput?.inputValue?.href_underlay) {
              buttonObj.iframe_underlay =
                newRouteInput?.inputValue.href_underlay;
              addBottomBarButton(buttonObj);
              setNewRouteInput({});
              setNewButtonText('');
              setSelectedIcon(null);
              return;
            }
            if (newRouteInput?.inputValue) {
              buttonObj.href = newRouteInput?.inputValue;
              addBottomBarButton(buttonObj);
              setNewRouteInput({});
              setNewButtonText('');
              setSelectedIcon(null);
              return;
            }
            showErrorToast('Please define a path first !');
          }}
        >
          Add Button
        </button>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <div className=" w-full flex flex-row items-center cursor-pointer border-2 border-blue-100 rounded-md">
            <SortableContext
              items={bottomBarButtons?.map((button) => button.id)}
              strategy={horizontalListSortingStrategy}
            >
              {bottomBarButtons?.map((button, i) => {
                return (
                  <ButtonPreview
                    key={button.id}
                    deleteButton={deleteButton}
                    button={button}
                  ></ButtonPreview>
                );
              })}
            </SortableContext>
          </div>
        </DndContext>
        <button
          onClick={() => {
            saveChatbot({ toastMessage: 'Updated Bottom Bar' });
          }}
          className="bg-blue-500 hover:bg-blue-600 active:bg-blue-700 w-full py-2 px-4 text-white rounded"
        >
          Save
        </button>
      </div>
      {/* Preview Video */}
      <div className="w-full h-full">
        <div className="relative h-full w-full">
          <div className="flex z-10">
            <PreviewVideoMagnet
              previewTemplateScreen={
                getScreenFromTemplateUsingRoute(
                  routeOpts[0]?.route,
                  template
                ) || defaultPreviewTemplateScreen
              }
              bottomBarButtons={bottomBarButtons}
              uuid={magnetUuid}
              template={template}
              category={'_preview_'}
              screen={'new'}
              inline={false}
              singleScreen={false}
              onClose={() => {}}
              autoplay={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const DollarSign = ({ style, className }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const Phone = ({ style, className }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
    />
  </svg>
);

const Calendar = ({ style, className }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
    />
  </svg>
);

function getScreenFromTemplateUsingRoute(route, template) {
  try {
    const routeNames = route.split('.');
    const categoryName = routeNames[0];
    const screenName = routeNames[1];

    console.log(
      'ABCD [getScreenFromTemplateUsingRoute]: ',
      routeNames,
      categoryName,
      screenName
    );

    return template?.categories[categoryName]?.screens[screenName];
  } catch (err) {
    console.log('ABCD Error: ', err.message, route);
    return;
  }
}

const defaultPreviewTemplateScreen = {
  caption: 'Welcome!',
  cloudLibrary: [],
  form: {
    enabled: false,
    inputs: [],
    text_data: {
      body_text: '',
      title: '',
    },
  },
  gif: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/299/magnet/f39eb022-5c26-4cfa-b8e0-6fb3d3d1cb84/intro_Main_Intro/intro__1__mp4_1.gif',
  iframe: {
    background_color: '#000000',
    enabled: false,
    icon_color: '#ffffff',
    src: '',
  },
  img: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/299/magnet/f39eb022-5c26-4cfa-b8e0-6fb3d3d1cb84/intro_Main_Intro/intro__1__mp4_1.jpg',
  links: [],
  location_card: 'Where to next{{, {firstName}|}}?',
  share_info: {},
  title: 'Main Intro',
  video:
    'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/299/magnet/f39eb022-5c26-4cfa-b8e0-6fb3d3d1cb84/intro_Main_Intro/intro__1__mp4_1.mp4?isButtonSeprated=true',
};

// const defaultBottomBarButtons = [
//   {
//     text: 'Book Tour',
//     icon: 'calendar',
//     // route: "thank_you.available_floorplans"
//     iframe_underlay:
//       'https://app.usetour.com/cta/scheduler/integration/8e205f7d-e0b0-4d1d-ad2d-a051e7cd83fa?timezone=CST&start=&end=&entratauser=lease_magnets_api@trinitasventures.lcl&entratapass=Leasemagnets1032022^&entratapropertyId=1109467',
//     id: 1,
//   },
//   {
//     text: '234-423-4390',
//     icon: 'phone',
//     // route: "thank_you.schedule_a_tour",
//     // href: "https://www.apple.com"
//     iframe_underlay:
//       'https://app.usetour.com/cta/contactus/integration/8e205f7d-e0b0-4d1d-ad2d-a051e7cd83fa?',
//     id: 2,
//   },
//   {
//     text: 'Availability',
//     icon: 'money',
//     // route: "thank_you.thank_youquestion",
//     iframe_underlay:
//       'https://app.usetour.com/cta/floorplans/integration/8e205f7d-e0b0-4d1d-ad2d-a051e7cd83fa?timezone=&start=&end=&entratauser=lease_magnets_api@trinitasventures.lcl&entratapass=Leasemagnets1032022^&entratapropertyId=1109467',
//     id: 3,
//   },
// ];

const defaultBottomBarButtons = [];
