export const getReviewCommunityReducer = (
  state = { reviewscommunityData: {} },
  action
) => {
  switch (action.type) {
    case 'GET_REVIEWS_COMMUNITY_REQUEST':
      return { reviewscommunityLoading: true, reviewscommunityData: {} };
    case 'GET_REVIEWS_COMMUNITY_SUCCESS':
      return {
        reviewscommunityLoading: false,
        reviewscommunityData: action.payload,
      };
    case 'GET_REVIEWS_COMMUNITY_FAIL':
      return {
        reviewscommunityLoading: false,
        reviewscommunityError: action.payload,
      };
    default:
      return state;
  }
};

export const getPendingReviewReducer = (
  state = { pendingReviews: [] },
  action
) => {
  switch (action.type) {
    case 'GET_PENDING_REVIEWS_REQUEST':
      return {
        pendingReviewsLoading: true,
        pendingReviews: [
          {
            src_user_id: 'jokg-n8is-ibln',
            data: '{"requesterEmail":"amulya@leasemagnets.com","requesterId":"jokg-n8is-ibln","leadName":"Blessed NEW TYG","requesterPhone":"5862588588","contextId":"","leadUserId":"gxlh-3fd9-7o40","leadEmail":"","requesterName":"Sandra","completedBonusReview":false,"specialOffer":"","leadPhone":"5862588588","defaultReviewStars":4}',
            src_community_id: '206',
            id: 'w6rz-mtof-f60r',
            type: 'review_request',
          },
        ],
      };
    case 'GET_PENDING_REVIEWS_SUCCESS':
      return {
        pendingReviewsLoading: false,
        pendingReviews: action.payload,
      };
    case 'GET_PENDING_REVIEWS_FAIL':
      return {
        pendingReviewsLoading: false,
        pendingReviewsError: action.payload,
      };
    default:
      return state;
  }
};

export const createReviewCommunityReducer = (
  state = { createdStatus: '' },
  action
) => {
  switch (action.type) {
    case 'CREATE_REVIEWS_COMMUNITY_REQUEST':
      return { communityCreating: true, createdStatus: '' };
    case 'CREATE_REVIEWS_COMMUNITY_SUCCESS':
      return {
        communityCreating: false,
        createdStatus: action.payload,
      };
    case 'CREATE_REVIEWS_COMMUNITY_FAIL':
      return {
        communityCreating: false,
        creatingCommunityError: action.payload,
      };
    default:
      return state;
  }
};

export const getCommunityReviewsReducer = (
  state = { communityReviews: {} },
  action
) => {
  switch (action.type) {
    case 'GET_COMMUNITY_REVIEWS_REQUEST':
      return { communityReviewsLoading: true, communityReviews: {} };
    case 'GET_COMMUNITY_REVIEWS_SUCCESS':
      return {
        communityReviewsLoading: false,
        communityReviews: action.payload,
      };
    case 'GET_COMMUNITY_REVIEWS_FAIL':
      return {
        communityReviewsLoading: false,
        communityReviewsError: action.payload,
      };
    default:
      return state;
  }
};

export const communityUsersReducer = (
  state = { communityUsers: {} },
  action
) => {
  switch (action.type) {
    case 'GET_COMMUNITY_USERS_REQUEST':
      return { communityUsersLoading: true, communityUsers: {} };
    case 'GET_COMMUNITY_USERS_SUCCESS':
      return {
        communityUsersLoading: false,
        communityUsers: action.payload,
      };
    case 'GET_COMMUNITY_USERS_FAIL':
      return {
        communityUsersLoading: false,
        communityUsersError: action.payload,
      };
    default:
      return state;
  }
};

export const communityUserByIDReducer = (
  state = { communityUser: {} },
  action
) => {
  switch (action.type) {
    case 'GET_COMMUNITY_USER_BY_ID_REQUEST':
      return { communityUserLoading: true, communityUser: {} };
    case 'GET_COMMUNITY_USER_BY_ID_SUCCESS':
      return {
        communityUserLoading: false,
        communityUser: action.payload,
      };
    case 'GET_COMMUNITY_USER_BY_ID_FAIL':
      return {
        communityUserLoading: false,
        communityUserError: action.payload,
      };
    default:
      return state;
  }
};

export const communityUserReviewReducer = (
  state = { communityUserReviews: {} },
  action
) => {
  switch (action.type) {
    case 'GET_COMMUNITY_USER_REVIEWS_REQUEST':
      return { communityUserReviewsLoading: true, communityUserReviews: {} };
    case 'GET_COMMUNITY_USER_REVIEWS_SUCCESS':
      return {
        communityUserReviewsLoading: false,
        communityUserReviews: action.payload,
      };
    case 'GET_COMMUNITY_USER_REVIEWS_FAIL':
      return {
        communityUserReviewsLoading: false,
        communityUserReviewsError: action.payload,
      };
    default:
      return state;
  }
};

export const communityAllUserReviewsReducer = (
  state = { communityUserReviews: {} },
  action
) => {
  switch (action.type) {
    case 'GET_COMMUNITY_All_USER_REVIEWS_REQUEST':
      return {
        communityAllUserReviewsLoading: true,
        communityAllUserReviews: {},
      };
    case 'GET_COMMUNITY_All_USER_REVIEWS_SUCCESS':
      return {
        communityAllUserReviewsLoading: false,
        communityAllUserReviews: action.payload,
      };
    case 'GET_COMMUNITY_All_USER_REVIEWS_FAIL':
      return {
        communityAllUserReviewsLoading: false,
        communityAllUserReviewsError: action.payload,
      };
    default:
      return state;
  }
};

export const createRequestHistoryReducer = (
  state = { createRequestHistoryRes: {} },
  action
) => {
  switch (action.type) {
    case 'CREATE_REQUEST_HISTORY_REQUEST':
      return { createRequestHistoryLoading: true, createRequestHistoryRes: {} };
    case 'CREATE_REQUEST_HISTORY_SUCCESS':
      return {
        createRequestHistoryLoading: false,
        createRequestHistoryRes: action.payload,
      };
    case 'CREATE_REQUEST_HISTORY_FAIL':
      return {
        createRequestHistoryLoading: false,
        requestHistoryError: action.payload,
      };
    default:
      return state;
  }
};

export const getRequestHistoryReducer = (
  state = { requestHistoryData: {} },
  action
) => {
  switch (action.type) {
    case 'GET_REQUEST_HISTORY_REQUEST':
      return { requestHistoryLoading: true, requestHistoryData: {} };
    case 'GET_REQUEST_HISTORY_SUCCESS':
      return {
        requestHistoryLoading: false,
        requestHistoryData: action.payload,
      };
    case 'GET_REQUEST_HISTORY_FAIL':
      return {
        requestHistoryLoading: false,
        requestHistoryError: action.payload,
      };
    default:
      return state;
  }
};
