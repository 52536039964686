import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import ReviewCard from '../reviews/main/ReviewCard';
const EmbedReview = () => {
  const { community_id } = useParams();
  const [directualCommunity, setDirectualCommunity] = useState({
    // id: 'temporary',
    name: 'Marshall Lousville',
    website: 'https://themarshalllouisville.com/', //(optional)
    communitybadges: [],
    team: [],
    reviews: [],
    champions: [],
    credits: 0,
    settings: {
      logo: 'https://i.imgur.com/NrZ3kph.png',
      address: '', //(optional)
      phone: '', // (optional),

      reviewSettings: {
        googleReviews:
          'https://search.google.com/local/writereview?placeid=ChIJsbivTn4F9YgR2YmRg350ljg&pli=1',
        form: {
          rating: false,
          video: true,
          text: true,
          name: true,
          email: true,
          questions: [], // if empt
        },
        goals: {
          CurrentRating: 3.5,
          CurrentReviewCount: 399,
          GoalNumberofReviews: 265.55555555555543,
          GoalRating: 4.1,
          ReviewScoreExpected: 5,
        },
      },
      referralSettings: {
        demographics: {},
      },
    },
  });

  function parseJsonPayloadHorizontally(dataArr = null) {
    return dataArr.map((data, i) => {
      let tempObj = {};
      Object.keys(data).map((k, idx) => {
        console.log('response jsonp: ', k, data[k]);
        try {
          let t1 = JSON.parse(data[k]);
          tempObj[k] = t1;
          return t1;
        } catch (e) {
          console.log(e);
        }
        tempObj[k] = data[k];
        return data[k];
      });
      console.log('response jsonfull: ', tempObj);
      return tempObj || {};
    });
  }

  const getcommunity = async () => {
    const res = await axios.get(
      `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
    );
    console.log('response', res, res.data.payload);
    let temp = parseJsonPayloadHorizontally(res.data.payload);
    console.log('response2', temp);

    if (temp?.settings?.goals) {
      setgoals({ ...temp.settings.goals });
    }
    if (res.data.payload.length > 0) {
      try {
        console.log(
          'thejson commbadges: ',
          temp?.[0]?.communitybadges?.communitybadges || []
        );
        let thejson = {
          ...temp[0],
          champions: temp?.[0]?.champions?.champions || [],
          communitybadges: temp?.[0]?.communitybadges?.communitybadges || [],
          reviews: temp?.[0]?.reviews?.reviews || [],
          settings: temp?.[0]?.settings || {},
          team: temp?.[0]?.team?.team || [],
        };
        //console.log('response',JSON.parse(res.data?.payload[0].champions))
        console.log('thejson', thejson);
        setDirectualCommunity({ ...thejson });
      } catch (error) {
        console.log('thejson errr', error);
      }
    } else {
      setShowStepForm(true);
      console.log('thejson response_empty');
    }
  };

  useEffect(() => {
    getcommunity();
  }, []);
  var introSlider = {
    dots: false,

    speed: 500,
    slidesToShow: 5,
    autoplay: true,
    slidesToScroll: 1,
    infinite: true,
    speed: 4000,
    autoplaySpeed: 50,
    cssEase: 'linear',
  };
  const deletereviewbyindex = async (index, setAddopen, setloading) => {
    try {
      // to avoiding to upload same file again
      const reviews = directualCommunity.reviews;
      reviews.splice(index, 1);
      console.log('reviews');
      let postData = {
        id: params.community_id,
        reviews: {
          reviews: reviews,
        },
      };
      const community = await axios.post(
        `https://api.directual.com/good/api/v5/data/community/newCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
        postData
      );
      console.log('userUpdated after review TYG: ', community);
      setloading(false);
      setAddopen(false);
      getcommunity();
    } catch (err) {
      console.log('err', err);
      console.log('err', err.response);
    }
  };
  let embeded = true;
  return (
    <div className=" min-h-screen">
      <Slider {...introSlider}>
        {!directualCommunity?.reviews?.length &&
          directualCommunity?.reviews.length == 0 && (
            <div
              className=" mx-2
       "
            >
              <ReviewCard
                key={0}
                item={{
                  type: 1,
                  feedback:
                    'No reviews found yet. Request a review to get started',
                  name: 'No reviews found yet. Request a review to get started',
                  rating: 5,
                }}
                ind={0}
              />
            </div>
          )}
        {directualCommunity?.reviews.length !== 0 &&
          directualCommunity?.reviews.map((item2, ind) => (
            <ReviewCard
              key={ind}
              item={item2?.question?.[0]}
              ind={ind}
              item2={item2}
              embeded={embeded}
              deletereviewbyindex={deletereviewbyindex}
            />
          ))}
      </Slider>
    </div>
  );
};

export default EmbedReview;
