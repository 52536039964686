import React, { useEffect, useRef, useState } from 'react';

const WhereAmI = ({ whereAmIData, currentTime, setNewVideoTime }) => {
  if (!(whereAmIData?.layouts?.length > 0)) {
    return <></>;
  }

  whereAmIData = whereAmIData?.layouts?.[0] || [];
  const [aspectRatio, setAspectRatio] = useState(null);
  const imageRef = useRef();

  const [minimized, setMinimized] = useState(false);

  useEffect(() => {
    if (!imageRef.current) return;
    setAspectRatio(
      imageRef?.current?.clientWidth / imageRef?.current?.clientHeight
    );
  }, [imageRef?.current?.clientWidth, imageRef?.current?.clientHeight]);

  let currentPositionIndex = whereAmIData.keyFrames.findIndex(
    (keyFrame) => currentTime < keyFrame.timeSeconds
  );
  currentPositionIndex =
    (currentPositionIndex === -1
      ? whereAmIData.keyFrames.length
      : currentPositionIndex) - 1;

  const currentPosition =
    whereAmIData?.keyFrames?.[currentPositionIndex]?.position ?? null;

  const IS_DESKTOP = !window.matchMedia('only screen and (max-width: 600px)')
    .matches;

  return (
    <>
      {IS_DESKTOP && (
        <div
          style={{
            pointerEvents: 'auto',
            zIndex: 3,
            gridColumn: 1,
            gridRow: 3,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            height: minimized ? 75 : 175,
            opacity: minimized ? 0.5 : 1,
            transition: 'all 1s ease-in-out',
          }}
        >
          <div
            ref={imageRef}
            onClick={(e) => {
              e.stopPropagation();
              setMinimized((minimized) => !minimized);
            }}
            style={{
              borderRadius: 10,
              marginLeft: 'auto',
              marginRight: '5%',
              paddingLeft: 10,
              paddingRight: 10,
              backgroundColor: minimized
                ? 'rgba(0,0,0,0.5)'
                : 'rgba(255,255,255,0.5)',
              position: 'relative',
              width: 'fit-content',
            }}
          >
            <img
              style={{
                height: '100%',
              }}
              src={whereAmIData.imgUrl}
            />

            {currentPosition && aspectRatio && !minimized ? (
              <div
                style={{
                  transition: 'all 1s ease-in-out',
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  width: 28,
                  height: 28,
                  position: 'absolute',
                  transform: 'translate(-50%, -50%)',
                  left: currentPosition.left * (aspectRatio * 175),
                  top: currentPosition.top * 175,
                  display: 'grid',
                  placeItems: 'center',
                  zIndex: 14,
                }}
              >
                <div
                  style={{
                    backgroundColor: 'rgb(125, 66, 63)',
                    borderRadius: '50%',
                    width: 24,
                    height: 24,
                    display: 'grid',
                    placeItems: 'center',
                  }}
                >
                  <p
                    style={{
                      fontSize: 14,
                      margin: 0,
                    }}
                  >
                    👋
                  </p>
                </div>
              </div>
            ) : null}

            {!minimized &&
              whereAmIData?.keyFrames?.map((keyframe, idx) => {
                return (
                  <div
                    style={{
                      zIndex: 12,
                      transition: 'all 1s ease-in-out',
                      backgroundColor: 'lightblue',
                      borderRadius: '50%',
                      position: 'absolute',
                      transform: 'translate(-50%, -50%)',
                      left: keyframe?.position?.left * (aspectRatio * 175),
                      top: keyframe?.position?.top * 175,
                      border:
                        keyframe?.timeSeconds > currentTime
                          ? '2px solid white'
                          : '2px solid rgba(65, 147, 234, 0.70)',
                      width: keyframe?.timeSeconds > currentTime ? 18 : 14,
                      height: keyframe?.timeSeconds > currentTime ? 18 : 14,
                      display: 'grid',
                      placeItems: 'center',
                    }}
                    onClick={(e) => {
                      setNewVideoTime(keyframe?.timeSeconds);
                      e.stopPropagation();
                    }}
                  >
                    {keyframe.label ? (
                      <div
                        style={{
                          fontSize: 9,
                          margin: 0,
                          paddingLeft: 2,
                          paddingRight: 2,
                          left: '50%',
                          backgroundColor: 'black',
                          opacity: 0.5,
                          borderRadius: '15%',
                          transform: 'translate(-50%, 0)',
                          position: 'absolute',
                          top: 15,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'clip',
                          opacity: idx === currentPositionIndex ? 1 : 0,
                          transition: 'opacity 1s ease-in-out',
                        }}
                      >
                        {keyframe.label}
                      </div>
                    ) : null}
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default WhereAmI;
