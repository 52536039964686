export const fakeReviewData = {
  totalReview: 30,
  rating: 4.2,
  members: [
    {
      name: 'amulya pharma',
      email: 'amulya@gmail.com0',
      link: 'https://feedbackjoy.com/asdf1231',
    },
    {
      name: 'amulya pharma',
      email: 'amulya@gmail.com0',
      link: 'https://feedbackjoy.com/asdf1231',
    },
  ],
  feedbacktype: [
    {
      name: 'Great Amenties',
      reviews: [
        {
          feedback:
            'Super amazing community and team that actually cares we should make it easy to build it.',
          rating: 5,
          from: 'Gretchen Thomas',
          to: 'Amulya Pharma',
          date: 'April 14, 2022, 7:22 PM',

          type: 1,
          tags: ['Great Amenities', 'On-time', 'Friendly', 'Clean'],
        },
        {
          feedback:
            'Super amazing community and team that actually cares we should make it easy to build it.',
          rating: 5,
          from: 'Gretchen Thomas',
          to: 'Amulya Pharma',
          date: 'April 14, 2022, 7:22 PM',
          url: 'https://media.videoask.com/transcoded/374292ae-e958-49d8-807d-06e29b827139/video.mp4',
          type: 2,
          tags: ['Great Amenities', 'On-time', 'Friendly', 'Clean'],
        },
        {
          feedback: 'Super amazing community and team that actually.',
          rating: 5,
          from: 'Gretchen Thomas',

          type: 1,
        },
      ],
    },
    {
      name: 'Close To Campus',
      reviews: ['fdsf', 'fdsf'],
    },
    {
      name: 'Clean',
      reviews: ['fdsf', 'fdsf'],
    },
    {
      name: 'On-time',
      reviews: ['fdsf', 'fdsf'],
    },
    {
      name: 'Friendly',
      reviews: ['fdsf', 'fdsf'],
    },
  ],
};
