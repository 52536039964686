import moment from 'moment';
import { useState } from 'react';
import { AiFillEye, AiOutlineSearch } from 'react-icons/ai';
import { BiFilterAlt } from 'react-icons/bi';
import {
  MdDownload,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import { AiOutlineReload } from 'react-icons/ai';
import ReactPaginate from 'react-paginate';
import LoadingIndicator from '../../components/LoadingIndicator';
import { createStripeId, updateStripeId } from '../../utils/api';
import AddStripeIdModal from './AddStripeIdModal';
import { filterArrOfObjs } from '../../utils/helpers';
import { Select, MenuItem, InputLabel } from '@material-ui/core';

const BillingTable = ({
  data,
  handlePageClick,
  pageCount,
  setForceFetchCommunityIndex,
  communityIdx, // The community index in the list of communities
}) => {
  const [active, setActive] = useState(0);
  const [down, setDown] = useState(false);
  const [isStripeModalOpen, setIsStripeModalOpen] = useState(false);
  const [cidForStripe, setCidForStripe] = useState();
  const [stripeId, setStripeId] = useState();
  const [detaobj, setdetaobj] = useState();
  const [searchByRef, setSearchByRef] = useState();
  const [searchByMonth, setSearchByMonth] = useState();
  const [invoiceStatusFilter, setInvoiceStatusFilter] = useState('');

  console.log('data?.stripeInfo?', data?.stripeInfo);

  function filterInvoicesByMonthAndRef(invoicesArr) {
    const statusFilteredArr = invoiceStatusFilter
      ? invoicesArr?.filter((invoiceItem, idx) => {
          if (invoiceStatusFilter === 'paid') return invoiceItem.paid;
          if (invoiceStatusFilter === 'unpaid') return !invoiceItem.paid;
        })
      : invoicesArr;

    // INFO: month filter has a higher priority then reference number filter
    const monthFilteredArr = searchByMonth
      ? statusFilteredArr.filter((invoiceItem, idx) => {
          const formattedMonthVal = moment(invoiceItem?.due_date * 1000)
            .format('MMMM')
            .toLowerCase();
          return formattedMonthVal.includes(searchByMonth.toLowerCase());
        })
      : statusFilteredArr;

    const monthAndRefFilteredArr = filterArrOfObjs(
      monthFilteredArr,
      'number',
      searchByRef
    );

    return monthAndRefFilteredArr;

    // INFO: Reference number filter has a higher priority then month filter
    /*
    const refFilteredArr = filterArrOfObjs(invoicesArr, 'number', searchByRef);
    const refAndMonthFilteredArr = searchByMonth
      ? refFilteredArr.filter((invoiceItem, idx) => {
          const formattedMonthVal = moment(invoiceItem?.due_date * 1000)
            .format('MMMM')
            .toLowerCase();
          return formattedMonthVal.includes(searchByMonth.toLowerCase());
        })
      : refFilteredArr;
    return refAndMonthFilteredArr;
    */
  }

  return (
    <div className=" w-full mb-8" id={`community_${data.item?.id}`}>
      <div className=" bg-blue-100 pb-2 flex items-center justify-between">
        <h1 className=" text-xl ml-2">
          {data?.name} ({data?.id})
        </h1>
        <div className="flex items-center justify-center gap-1">
          <AiOutlineReload
            className="cursor-pointer"
            onClick={() => {
              //console.log("Setup force fetch for community", communityIdx);
              setForceFetchCommunityIndex(communityIdx);
            }}
            size={24}
          />
          {down === false ? (
            <MdKeyboardArrowDown
              onClick={() => setDown(true)}
              className=" w-9 h-9 cursor-pointer"
            />
          ) : (
            <MdKeyboardArrowUp
              onClick={() => setDown(false)}
              className=" w-9 h-9 cursor-pointer"
            />
          )}
        </div>
      </div>
      {down === false && (
        <>
          <div className=" w-full  flex items-center gap-8 px-3"></div>
          <div className="w-full flex items-center gap-3 justify-between pt-3">
            <div>
              {data?.stripeInfo?.customer_id ? (
                <>
                  <p className="text-xs text-black whitespace-nowrap">
                    Stripe Customer Id:{' '}
                    <span className="text-xs text-blue-400">
                      {data?.stripeInfo?.customer_id}
                    </span>
                  </p>
                  <p
                    className="text-xs text-blue-400"
                    onClick={() => {
                      setCidForStripe(data?.id);
                      setStripeId(data?.stripeInfo?.customer_id);
                      setIsStripeModalOpen(true);
                      setdetaobj(data.stripeInfo);
                    }}
                  >
                    {' '}
                    + Adjust Stripe Customer ID
                  </p>
                </>
              ) : (
                <button
                  onClick={() => {
                    setCidForStripe(data?.id);
                    setStripeId(data?.stripeInfo?.customer_id);
                    setIsStripeModalOpen(true);
                  }}
                  className="text-xs text-blue-400 cursor-pointer whitespace-nowrap"
                >
                  + Add Stripe Customer ID
                </button>
              )}
            </div>
            <div className="w-full flex items-center gap-3 justify-end">
              <div className=" flex items-center relative">
                <input
                  style={{ padding: '6px' }}
                  type="text"
                  onChange={(e) => setSearchByRef(e.target.value)}
                  value={searchByRef}
                  placeholder="Search by ref no"
                  className=" w-full border text-xs bg-transparent rounded-sm font-semibold"
                />

                <AiOutlineSearch className=" text-gray-500 z-10 absolute top-2 right-1 cursor-pointer" />
              </div>
              <div className=" flex items-center relative">
                <input
                  style={{ padding: '6px' }}
                  type="text"
                  onChange={(e) => setSearchByMonth(e.target.value)}
                  value={searchByMonth}
                  placeholder="Filter by Month"
                  className=" w-full border text-xs pl-4 bg-transparent rounded-sm font-semibold"
                />

                <BiFilterAlt className=" text-gray-500 z-10 absolute top-2 left-1 cursor-pointer" />
              </div>
              <div>
                <Select
                  labelId="invoice-status-label"
                  value={invoiceStatusFilter}
                  label="status"
                  onChange={(event) =>
                    setInvoiceStatusFilter(event.target.value)
                  }
                  variant={'standard'}
                  size="small"
                  inputProps={{ 'aria-label': 'Without label' }}
                  displayEmpty
                >
                  <MenuItem value={''}>All</MenuItem>
                  <MenuItem value={'paid'}>Paid</MenuItem>
                  <MenuItem value={'unpaid'}>Unpaid</MenuItem>
                </Select>
              </div>
            </div>
          </div>
          <div className=" w-full    mt-4 overflow-x-scroll lg:overflow-hidden border rounded-md">
            <table className="min-w-full ">
              <thead className="bg-gray-50 border-b">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-2 text-left    tracking-wider"
                  >
                    Invoice Ref#
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-2 text-left  tracking-wider"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-2 text-left  tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-2 text-left  tracking-wider"
                  >
                    Billing Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-2 text-left  tracking-wider"
                  >
                    Billing Month
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-2 text-left  tracking-wider"
                  >
                    Invoice
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white  ">
                {data?.loading == false ? (
                  filterInvoicesByMonthAndRef(data?.stripeInfo?.invoices)?.map(
                    (item, index) => (
                      <tr className=" border-b" key={index}>
                        <td className="px-4 py-3 text-gray-600 font-medium whitespace-nowrap">
                          <p>{item.number}</p>
                        </td>
                        <td className="px-4 py-3 text-gray-600 font-medium whitespace-nowrap">
                          <p>${item.amount_due / 100}</p>
                        </td>
                        <td
                          className={`px-4 py-3 ${
                            item.paid ? 'text-green-500' : 'text-red-500'
                          } text-gray-600 font-medium whitespace-nowrap`}
                        >
                          <p>{item.paid ? 'Paid' : 'Unpaid'}</p>
                        </td>

                        <td className="px-4 py-3 text-gray-600 font-medium whitespace-nowrap">
                          <p>{moment(item.due_date * 1000).calendar()}</p>
                        </td>

                        <td className="px-4 py-3 text-gray-600 font-medium  whitespace-nowrap">
                          <p>{moment(item.due_date * 1000).format('MMMM')}</p>
                        </td>
                        <td className="px-4 py-3 text-gray-600 font-medium  whitespace-nowrap flex items-center gap-4">
                          <AiFillEye
                            onClick={() => {
                              window.open(item.hosted_invoice_url, '_blank');
                            }}
                            className="text-pr w-6 h-6 cursor-pointer"
                          />
                          <MdDownload
                            onClick={() => {
                              window.open(item.invoice_pdf, '_blank');
                            }}
                            className="text-pr w-6 h-6 cursor-pointer"
                          />
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <LoadingIndicator />
                )}
              </tbody>
            </table>
          </div>
          <div className=" flex items-center justify-end paginate_react mt-3">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
            />
          </div>
          {isStripeModalOpen ? (
            <AddStripeIdModal
              stripeId={stripeId}
              handleClose={() => setIsStripeModalOpen(false)}
              handleSave={(stripe_id) => {
                if (data?.stripeInfo) {
                  updateStripeId({
                    ...data?.stripeInfo,
                    customer_id: stripe_id,
                  });
                } else {
                  createStripeId(stripe_id, cidForStripe);
                }
              }}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default BillingTable;
