import { useEffect, useState } from 'react';
import firebase from '../../utils/base';

function ModalExampleModal() {
  const [open, setOpen] = useState(false);

  const [step, setStep] = useState(1);
  const [submitErrors, setSubmitErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('US');
  const [otp, setOtp] = useState('');

  console.log(phoneNumber);
  useEffect(() => {
    fetch('https://extreme-ip-lookup.com/json/?key=xMAttOE7njY3pUxS059g')
      .then((res) => res.json())
      .then((response) => {
        console.log('Country is : ', response);
        setCountryCode(response.countryCode);
      })
      .catch((data, status) => {
        console.log('Request failed:', data);
      });
  }, []);

  const onSignInSubmit = async () => {
    setLoading(true);

    firebase.configureCaptcha(onSignInSubmit, 'captcha_container');
    const phoneNumberUser = '+923452942519';
    console.log(phoneNumberUser);
    const appVerifier = window.recaptchaVerifier;

    try {
      await firebase.phoneAuth(phoneNumberUser, appVerifier);
      console.log('OTP sent');
      setStep(2);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  const onSubmitOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await window.confirmationResult.confirm(otp);
      console.log(result);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setSubmitErrors(error.message);
      setLoading(false);
    }
  };
  return (
    <div className="completeProfile_main">
      <div
        className=""
        id="kt_modal_two_factor_authentication"
        tabIndex={-1}
        aria-hidden="true"
      >
        {/*begin::Modal header*/}
        <div className="modal-dialog modal-dialog-centered mw-650px">
          {/*begin::Modal content*/}
          <div className="modal-content">
            {/*begin::Modal header*/}
            <div className="modal-header flex-stack">
              {/*begin::Title*/}
              <h2>Choose An Authentication Method</h2>
              {/*end::Title*/}
              {/*begin::Close*/}

              {/*end::Close*/}
            </div>
            {/*begin::Modal header*/}
            {/*begin::Modal body*/}
            <div className="modal-body scroll-y pt-10 pb-15 px-lg-17">
              {/*end::Options*/}
              {/*begin::SMS*/}
              {step === 1 && (
                <div>
                  {/*begin::Heading*/}
                  <h3 className="text-dark fw-bolder fs-3 mb-5">
                    SMS: Verify Your Mobile Number
                  </h3>
                  {/*end::Heading*/}
                  {/*begin::Notice*/}

                  {/*end::Notice*/}
                  {/*begin::Form*/}
                  <form data-kt-element="sms-form" className="form" action="#">
                    {/*begin::Input group*/}

                    <input
                      type="text"
                      placeholder="Enter Phone"
                      style={{ margin: '20px 0', border: '1px solid black' }}
                    />

                    <p className="text-danger">
                      {submitErrors && submitErrors}
                    </p>
                    <div id="captcha_container"></div>

                    {/*end::Input group*/}
                    {/*begin::Actions*/}
                    <div className="d-flex flex-center">
                      <button
                        onClick={() => setPhoneNumber('')}
                        type="reset"
                        data-kt-element="sms-cancel"
                        className="btn btn-light me-3"
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={onSignInSubmit}
                        disabled={
                          (phoneNumber?.length > 0 &&
                            !isPossiblePhoneNumber(phoneNumber)) ||
                          phoneNumber == undefined
                        }
                        type={'submit'}
                      >
                        {step === 1 ? (
                          loading ? (
                            <>
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span style={{ marginLeft: 10 }}>
                                Please wait...
                              </span>
                            </>
                          ) : (
                            'Next'
                          )
                        ) : (
                          'Submit'
                        )}
                      </button>
                    </div>
                    {/*end::Actions*/}
                  </form>
                  {/*end::Form*/}
                </div>
              )}

              {step === 2 && (
                <div>
                  {/*begin::Heading*/}
                  <h3 className="text-dark fw-bolder fs-3 mb-5">
                    SMS: Verify Your Mobile Number
                  </h3>
                  {/*end::Heading*/}
                  {/*begin::Notice*/}
                  <div className="text-muted fw-bold mb-10">
                    Enter the OTP, which has sent to your phone number.
                  </div>
                  {/*end::Notice*/}
                  {/*begin::Form*/}
                  <form
                    onSubmit={onSubmitOTP}
                    data-kt-element="sms-form"
                    className="form"
                    action="#"
                  >
                    {/*begin::Input group*/}
                    <div className="mb-10 fv-row">
                      <input
                        style={{
                          height: '35px',
                          width: '100%',
                          border: 'none',
                          outline: 'none',
                          borderBottom: '1px solid #dee2e6',
                          margin: '30px 0',
                        }}
                        className="completeProfile_input"
                        placeholder="Otp"
                        autoComplete="off"
                        type="number"
                        name="otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </div>
                    <p className="text-danger">
                      {submitErrors && submitErrors}
                    </p>

                    {/*end::Input group*/}
                    {/*begin::Actions*/}
                    <div className="d-flex flex-center">
                      <button
                        onClick={() => setOtp('')}
                        type="reset"
                        data-kt-element="sms-cancel"
                        className="btn btn-light me-3"
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary"
                        disabled={otp?.length < 6}
                        type={'submit'}
                      >
                        {loading ? (
                          <>
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span style={{ marginLeft: 10 }}>
                              Please wait...
                            </span>
                          </>
                        ) : (
                          'Submit'
                        )}
                      </button>
                    </div>
                    {/*end::Actions*/}
                  </form>
                  {/*end::Form*/}
                </div>
              )}

              {/*end::SMS*/}
            </div>
            {/*begin::Modal body*/}
          </div>
          {/*end::Modal content*/}
        </div>
        {/*end::Modal header*/}
      </div>
    </div>
  );
}

export default ModalExampleModal;
