import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ACCESS_LEVEL } from '../utils/constants.js';
import { API_HOST } from '../utils/request';

// RewardsTable Table
function RewardsTable({
  rewardCheck,
  page,
  rewardList,
  setSelectedGiftTemplate,
  selectedgiftTemplate,
  templates,
  setselectedrewardId,
  setGiftAmount,
  setOpenSendGiftConfirmDialog,
  setdialoguestatus,
  managerewardindirectual,
  setReward,
  cancelexpand,
  setgifttopending,
  setselectedrewarddata,
  accesslvl,
}) {
  // Only display the current page of reviews (10 per page)
  function displayTen() {
    const ten = rewardList.filter(
      (review, idx) => idx >= 10 * (page - 1) && idx < 10 * page
    );
    console.log(ten);
    return ten;
  }

  return (
    <div className="reward-table">
      <div className="table">
        <div className={`table-hdr flex aic ${rewardCheck}`}>
          <div className="column">
            <div className="label font">Date</div>
          </div>
          <div className="column">
            <div className="label font">Name</div>
          </div>
          <div className="column">
            <div className="label font">Amount</div>
          </div>
          {rewardCheck == 'Completed' && (
            <div className="column">
              <div className="label font">Status</div>
            </div>
          )}
          {/* <div className="column">
                <div className="label font">Comments</div>
              </div> */}
        </div>
        {displayTen().map((reward, idx) => {
          let _badges = reward.badges ? JSON.parse(reward.badges) : [];
          return (
            <CompletedRow
              key={idx}
              idx={idx}
              selectedgiftTemplate={selectedgiftTemplate}
              setSelectedGiftTemplate={setSelectedGiftTemplate}
              templates={templates}
              reward={reward}
              setselectedrewardId={setselectedrewardId}
              setGiftAmount={setGiftAmount}
              setOpenSendGiftConfirmDialog={setOpenSendGiftConfirmDialog}
              setdialoguestatus={setdialoguestatus}
              managerewardindirectual={managerewardindirectual}
              setReward={setReward}
              cancelexpand={cancelexpand}
              setgifttopending={setgifttopending}
              setselectedrewarddata={setselectedrewarddata}
              rewardCheck={rewardCheck}
              accesslvl={accesslvl}
            />
          );
        })}
        {/* {reviews.length === 0 && <NoRows />} */}
      </div>
    </div>
  );
}

// Row for each table entry
function CompletedRow({
  idx,
  comments,
  reward,
  setSelectedGiftTemplate,
  selectedgiftTemplate,
  templates,
  setselectedrewardId,
  setGiftAmount,
  setOpenSendGiftConfirmDialog,
  setdialoguestatus,
  managerewardindirectual,
  setReward,
  cancelexpand,
  setgifttopending,
  setselectedrewarddata,
  rewardCheck,
  accesslvl,
}) {
  // Expand or not
  const [expand, setExpand] = useState(false);
  const [loading, setloading] = useState(true);
  const [giftstatus, setgiftstatus] = useState('');
  //console.log('reward',reward)
  // Constrain comments to 200 characters
  useEffect(() => {
    if (expand) {
      setExpand(false);
    }
  }, [cancelexpand]);
  function constrainComments() {
    if (comments) {
      if (comments.length < 90) return comments;
      else return comments.slice(0, 90) + ' ... ';
    }
    return 'N/A';
  }
  const parsedreward = JSON.parse(reward.data);
  const getgiftbitstatus = async () => {
    try {
      const res = await axios.post(`${API_HOST}/get_giftbit_campaign_by_id`, {
        id: parsedreward.giftbitid,
      });
      console.log('getgiftbitstatus', res);
      if (res?.data?.response?.campaign) {
        setgiftstatus(res?.data?.response?.campaign.status);
        setloading(false);
      }
    } catch (error) {
      console.log('errrrr', error);
    }
  };
  useEffect(() => {
    getgiftbitstatus();
  }, []);
  const [showUserEmojis, setUserEmojis] = useState(false);
  //6/3/2022 , 23:34:34
  console.log('rewardCheck', rewardCheck);
  return (
    <>
      <div
        style={{ display: 'flex' }}
        className="table-row aic"
        onClick={() => setExpand(!expand)}
      >
        <div className="column">
          <div className="txt font">{parsedreward.date}</div>
        </div>
        <div
          className="column"
          style={{ cursor: 'pointer' }}
          onMouseEnter={(e) => {
            setUserEmojis(true);
          }}
          onMouseLeave={(e) => {
            setUserEmojis(false);
          }}
        >
          {parsedreward.requesterName}
        </div>
        <div className="column">
          <div style={{ display: 'inline-block' }}>{parsedreward.cost}</div>
        </div>
        {rewardCheck == 'Completed' ? (
          loading ? (
            <div className="column">
              <div style={{ display: 'inline-block' }}>Loading</div>
            </div>
          ) : (
            <div className="column">
              <div style={{ display: 'inline-block' }}>{giftstatus}</div>
            </div>
          )
        ) : (
          ''
        )}
      </div>
      {expand && (
        <>
          <ExpandedComments
            idx={idx}
            parsedreward={parsedreward}
            rewardId={reward.id}
            selectedgiftTemplate={selectedgiftTemplate}
            setSelectedGiftTemplate={setSelectedGiftTemplate}
            templates={templates}
            setselectedrewardId={setselectedrewardId}
            setGiftAmount={setGiftAmount}
            setOpenSendGiftConfirmDialog={setOpenSendGiftConfirmDialog}
            setdialoguestatus={setdialoguestatus}
            managerewardindirectual={managerewardindirectual}
            setReward={setReward}
            reward={reward}
            setgifttopending={setgifttopending}
            setselectedrewarddata={setselectedrewarddata}
            accesslvl={accesslvl}
          />
        </>
      )}
    </>
  );
}

// Expanded comments section
function ExpandedComments({
  idx,
  parsedreward,
  rewardId,
  setSelectedGiftTemplate,
  selectedgiftTemplate,
  templates,
  setselectedrewardId,
  setGiftAmount,
  setOpenSendGiftConfirmDialog,
  setdialoguestatus,
  managerewardindirectual,
  setReward,
  reward,
  setgifttopending,
  setselectedrewarddata,
  accesslvl,
}) {
  const [requesterName, setrequesterName] = useState(
    parsedreward.requesterName
  );
  const [requesterEmail, setrequesterEmail] = useState(
    parsedreward.requesterEmail
  );
  const [recipientName, setrecipientName] = useState(
    parsedreward.recipientName
  );
  const [recipientEmail, setrecipientEmail] = useState(
    parsedreward.recipientEmail
  );
  const [subjectLine, setsubjectLine] = useState(
    parsedreward.giftingTemplate.subjectLine
  );
  const [personalMessage, setpersonalMessage] = useState(
    parsedreward.giftingTemplate.personalMessage
  );
  const [cost, setcost] = useState(parsedreward.cost);
  const [notes, setnotes] = useState(parsedreward.notes);
  const [currselectedtemplate, setcurrselectedtemplate] = useState('');
  useEffect(() => {
    if (parsedreward.giftingTemplate.id) {
      const index = templates.findIndex(
        (item) => item.giftbitTemplateId == parsedreward.giftingTemplate.id
      );
      setcurrselectedtemplate(index);
      setSelectedGiftTemplate(templates[index]);
    }
  }, []);
  console.log('rewardId', rewardId);
  return (
    <div className="expanded-reward-request flex flex-col">
      <div className="left flex flex-col">
        <div className="left-wrap flex">
          <div className="to-side">
            <div className="tit font">To:</div>
            <div className="field">
              <input
                type="text"
                className="iput font"
                placeholder="Name"
                onChange={(event) => {
                  setrecipientName(event.target.value);
                }}
                value={recipientName}
              />
            </div>
            <div className="field">
              <input
                type="text"
                className="iput font"
                placeholder="Email"
                onChange={(event) => {
                  setrecipientEmail(event.target.value);
                }}
                value={recipientEmail}
              />
            </div>
          </div>
          <div className="from-side">
            <div className="tit font">From:</div>
            <div className="field">
              <input
                type="text"
                className="iput font"
                placeholder="Name"
                onChange={(event) => {
                  setrequesterName(event.target.value);
                }}
                value={requesterName}
              />
            </div>
            <div className="field">
              <input
                type="text"
                className="iput font"
                placeholder="Amount"
                onChange={(event) => {
                  setcost(event.target.value);
                }}
                value={cost}
              />
            </div>
            <div className="field">
              <select
                value={currselectedtemplate}
                onChange={(event) => {
                  console.log('event', event.target.value);
                  setcurrselectedtemplate(event.target.value);
                  setSelectedGiftTemplate(templates[event.target.value]);
                }}
                className="iput font"
              >
                <option value="-1">Select Template Type</option>
                {templates.map((item, index) => {
                  return <option value={index}>{item.name}</option>;
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="field">
          <input
            type="text"
            className="iput font"
            placeholder="Subject line"
            onChange={(event) => {
              setsubjectLine(event.target.value);
            }}
            value={subjectLine}
          />
          <input
            type="text"
            className="iput font"
            placeholder="Message"
            onChange={(event) => {
              setpersonalMessage(event.target.value);
            }}
            value={personalMessage}
          />
          <textarea
            type="text"
            className="iput area font"
            placeholder="Write Note...."
            onChange={(event) => {
              setnotes(event.target.value);
            }}
            value={notes}
          />
        </div>
      </div>
      {accesslvl == ACCESS_LEVEL['global'] && reward.rewardId == 'pending' && (
        <div className="actions flex aic">
          <button
            onClick={async () => {
              const res = await managerewardindirectual({
                id: rewardId,
                data: {
                  ...parsedreward,
                  requesterName,
                  requesterEmail,
                  recipientName,
                  recipientEmail,
                  notes,
                  cost,
                  giftingTemplate: {
                    ...parsedreward.giftingTemplate,
                    subjectLine,
                    personalMessage,
                    id: selectedgiftTemplate.giftbitTemplateId,
                  },
                },
              });
              console.log('ressss', res);
              if (res?.data?.status == 'OK') {
                toast('Data has been modified');
              }
            }}
            className="action font"
          >
            Save
          </button>
          <button
            onClick={async () => {
              const res = await managerewardindirectual({
                id: rewardId,
                rewardId: 'deleted',
              });
              if (res?.data?.status == 'OK') {
                toast('Deleted successfully');
              }
            }}
            className="action del-btn font"
          >
            Delete
          </button>
          <button
            onClick={() => {
              setReward({
                fromName: requesterName,
                fromEmail: requesterEmail,
                toName: recipientName,
                toEmail: recipientEmail,
                subject: subjectLine,
                message: personalMessage,
              });
              setselectedrewardId(rewardId);
              setGiftAmount(parsedreward.cost);
              setgifttopending(false);
              setOpenSendGiftConfirmDialog(true);
              setselectedrewarddata(parsedreward);
              //startAnimation()
            }}
            className="action approve-btn font"
          >
            Approve
          </button>
        </div>
      )}
    </div>
  );
}

export default RewardsTable;
