import { useEffect, useState } from 'react';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import { AiOutlineClose } from 'react-icons/ai';
import { Dragger } from '../../components/Reusable/Dragger';
import { Button, Tab, Tabs, TextField } from '@material-ui/core';
import { supabase } from '../../services/supabase';
import { CloseIcon } from './Icons';

const FILE_PATH_REGEX = /(?<=PropertyPhotos\/).*(?=\?)/;
const IMAGE_TRANSFORMATIONS = {
  transform: {
    width: 200,
    height: 200,
    resize: 'cover',
  },
};

// https://awik.io/determine-color-bright-dark-using-javascript/
function isColorLight(color) {
  // Variables for red, green, blue values
  let r, g, b, hsp;

  // If hex --> Convert it to RGB: http://gist.github.com/983661
  color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

  r = color >> 16;
  g = (color >> 8) & 255;
  b = color & 255;

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  return hsp > 127.5;
}

export default function SelectSenderPhotoModal({
  open,
  setOpen,
  onClose = () => {},
  communityId,
  photoUrl,
  setPhotoUrl,
  primaryColor = '#3898ec',
  senderName,
}) {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [selectedPhoto, setSelectedPhoto] = useState();
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState();
  const [existingPhotos, setExistingPhotos] = useState([]);
  const [initialsText, setInitialsText] = useState(senderName ?? '');
  const handleDrop = ([file]) => {
    if (file.type.slice(0, 5) !== 'image') {
      // todo: should probably provide feedback here
      return;
    }
    setSelectedPhoto(file);
  };

  const readFile = (e) => {
    console.log('readFile run!');
    setSelectedPhoto(e.target.files[0]);
  };

  const handleSubmit = async () => {
    if (currentTabIndex === 1) {
      const uuid = crypto.randomUUID();
      const { data, error } = await supabase.storage
        .from('PropertyPhotos')
        .upload(`${communityId}/avatars/${uuid}`, selectedPhoto, {
          cacheControl: '3600',
          upsert: false,
        });
      if (error) {
        console.error('Error uploading photo to supabase:', error);
      }
      const { data: urlData } = supabase.storage
        .from('PropertyPhotos')
        .getPublicUrl(`${communityId}/avatars/${uuid}`, IMAGE_TRANSFORMATIONS);
      setPhotoUrl(urlData.publicUrl);
    } else if (currentTabIndex === 0) {
      const filePath =
        existingPhotos[selectedPhotoIndex].match(FILE_PATH_REGEX);
      const publicUrl = supabase.storage
        .from('PropertyPhotos')
        .getPublicUrl(filePath, IMAGE_TRANSFORMATIONS).data.publicUrl;
      setPhotoUrl(publicUrl);
    } else if (currentTabIndex === 2) {
      setPhotoUrl(
        `https://ui-avatars.com/api/?name=${encodeURIComponent(
          initialsText
        )}&background=${primaryColor?.slice(1)}&color=${
          isColorLight(primaryColor) ? '000000' : 'ffffff'
        }`
      );
    }
    setOpen(false);
    onClose();
  };

  const getExistingAvatarPhotos = async () => {
    const { data, error } = await supabase.storage
      .from('PropertyPhotos')
      .list(`${communityId}/avatars/`);
    if (error) {
      console.error('Error listing existing photos in supabase:', error);
    }
    setExistingPhotos(
      data
        .filter((file) => file.name !== '.emptyFolderPlaceholder')
        .map(
          (file) =>
            supabase.storage
              .from('PropertyPhotos')
              .getPublicUrl(`${communityId}/avatars/${file.name}`, {
                transform: {
                  width: 120,
                  height: 120,
                  resize: 'cover',
                },
              }).data.publicUrl
        )
    );
  };

  const deletePhoto = async (index) => {
    const fileUrl = existingPhotos[index];
    const filePath = fileUrl.match(FILE_PATH_REGEX)[0];
    const { error } = await supabase.storage
      .from('PropertyPhotos')
      .remove([filePath]);
    if (error) {
      console.error(
        `Error in deleting file ${fileUrl} (split to ${filePath}) from supabase:`,
        error
      );
    }
    const standardizedFileUrl = supabase.storage
      .from('PropertyPhotos')
      .getPublicUrl(filePath, IMAGE_TRANSFORMATIONS).data.publicUrl;
    if (standardizedFileUrl === photoUrl) {
      setPhotoUrl(null);
    }
    const newExistingPhotos = [...existingPhotos];
    newExistingPhotos.splice(index, 1);
    setExistingPhotos(newExistingPhotos);
    setSelectedPhotoIndex(null);
  };

  useEffect(() => {
    getExistingAvatarPhotos();
  }, [open]);

  return (
    <AnimatedDialog open={open} onClose={onClose}>
      <div className="p-4">
        <div className="flex items-center justify-between border-b border-gray-300 w-full ">
          <h4 className="pb-3 text-xl font-semibold">Choose Sender Photo</h4>
          <AiOutlineClose
            className="w-7 h-7 cursor-pointer text-gray-600"
            onClick={() => {
              setOpen(false);
              onClose();
            }}
          />
        </div>
        <Tabs
          value={currentTabIndex}
          variant="fullWidth"
          onChange={(_, newValue) => setCurrentTabIndex(newValue)}
        >
          <Tab
            label="Existing Photos"
            style={{ fontWeight: currentTabIndex === 0 ? 'bold' : 'normal' }}
          />
          <Tab
            label="Upload New"
            style={{ fontWeight: currentTabIndex === 1 ? 'bold' : 'normal' }}
          />
          <Tab
            label="Use Initials"
            style={{ fontWeight: currentTabIndex === 2 ? 'bold' : 'normal' }}
          />
        </Tabs>

        {/* Existing Photos */}
        <div className="w-full my-4" hidden={currentTabIndex !== 0}>
          <h5>Choose from existing photos:</h5>
          <div className="p-4 rounded bg-gray-200 flex flex-wrap gap-4 mt-2">
            {existingPhotos.map((url, index) => (
              <PhotoCard
                key={url}
                url={url}
                isSelected={selectedPhotoIndex === index}
                onClick={() => setSelectedPhotoIndex(index)}
                onDelete={() => deletePhoto(index)}
              />
            ))}
          </div>
        </div>

        {/* Upload New */}
        <div
          className="relative h-32 w-full my-4"
          hidden={currentTabIndex !== 1}
        >
          <Dragger handleDrop={handleDrop} style={{ top: 0 }}>
            <label className="w-full h-full flex flex-col items-center justify-center border-2 border-gray-500 border-dashed rounded pointer">
              <div className="slogn font s18 b5 c000">
                <span className="color">Drag & drop</span> your photo to upload
              </div>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
                onChange={readFile}
              />
              <div className="txt font s14">Or click to browse files</div>
              {/* <button
                  className="cleanbtn upload-btn flex"
                  // onClick={() =>
                  //   document.getElementById('contained-button-file').click()
                  // }
                >
                </button> */}
            </label>
          </Dragger>
        </div>

        {/* Use Initials */}
        <div className="w-full my-4" hidden={currentTabIndex !== 2}>
          <TextField
            variant="outlined"
            value={initialsText}
            onChange={(e) => setInitialsText(e.target.value)}
            label="Name or Initials"
            className="w-full"
          />
        </div>

        {((currentTabIndex === 0 && selectedPhotoIndex != null) ||
          (currentTabIndex === 1 && selectedPhoto) ||
          (currentTabIndex === 2 && initialsText)) && (
          <div className="flex gap-4 justify-center items-center mb-2">
            <div>Preview:</div>
            <div
              className="relative rounded-full"
              style={{
                backgroundImage: `url(${
                  currentTabIndex === 0
                    ? existingPhotos[selectedPhotoIndex]
                    : currentTabIndex === 1
                    ? URL.createObjectURL(selectedPhoto)
                    : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                        initialsText
                      )}&background=${primaryColor?.slice(1)}&color=${
                        isColorLight(primaryColor) ? '000000' : 'ffffff'
                      }`
                })`,
                backgroundSize: 'cover',
                height: '35px',
                width: '35px',
                marginLeft: '0px',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  backgroundColor: '#5CCD4D',
                  width: '11px',
                  height: '11px',
                  borderRadius: '100%',
                  bottom: '0px',
                  right: '0px',
                  border: '2px solid white',
                }}
              ></div>
            </div>
          </div>
        )}
        <div className="w-full flex justify-center gap-4">
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            disabled={
              (currentTabIndex === 0 && selectedPhotoIndex == null) ||
              (currentTabIndex === 1 && !selectedPhoto) ||
              (currentTabIndex === 2 && !initialsText)
            }
          >
            Submit
          </Button>
        </div>
      </div>
    </AnimatedDialog>
  );
}

const PhotoCard = ({ url, isSelected, onClick, onDelete }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      key={url}
      className={`w-28 h-28 rounded bg-cover bg-center pointer relative ${
        isSelected && 'border-4 border-blue-500'
      }`}
      style={{ backgroundImage: `url(${url})` }}
      onClick={onClick}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && (
        <div
          className={`absolute pointer -r-3 -t-3 h-6 w-6 items-center justify-center`}
          style={{
            background: 'rgba(86, 80, 80, 0.7)',
            borderRadius: '15px',
          }}
          onClick={onDelete}
        >
          <CloseIcon height={12} width={12} />
        </div>
      )}
    </div>
  );
};
