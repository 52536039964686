import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import Extend from './Extend';
import './Extend.css';

export default function BigVideo() {
  //Dynamic buttons - tour paths
  const [buttons] = useState([
    { name: 'Two Bedroom' },
    { name: 'Three Bedroom' },
    { name: 'Four Bedroom' },
    { name: 'See Amenities' },
  ]);

  //Dynamic testimonials
  const [reviews] = useState([
    {
      person: 'Amulya P.',
      review: 'Incredible...free laundry every week & soundproof walls!',
    },
    {
      person: 'Jon C.',
      review: 'Absolutely terrible...overpriced and unsanitary',
    },
  ]);

  //Dynamic gallery
  const [pictures] = useState([
    {
      src: 'https://www.thesprucepets.com/thmb/sfuyyLvyUx636_Oq3Fw5_mt-PIc=/3760x2820/smart/filters:no_upscale()/adorable-white-pomeranian-puppy-spitz-921029690-5c8be25d46e0fb000172effe.jpg',
      alt: 'dog',
    },
    {
      src: 'https://www.thesprucepets.com/thmb/sfuyyLvyUx636_Oq3Fw5_mt-PIc=/3760x2820/smart/filters:no_upscale()/adorable-white-pomeranian-puppy-spitz-921029690-5c8be25d46e0fb000172effe.jpg',
      alt: 'dog',
    },
    {
      src: 'https://www.thesprucepets.com/thmb/sfuyyLvyUx636_Oq3Fw5_mt-PIc=/3760x2820/smart/filters:no_upscale()/adorable-white-pomeranian-puppy-spitz-921029690-5c8be25d46e0fb000172effe.jpg',
      alt: 'dog',
    },
  ]);

  return (
    <div>
      <table className="table-margin">
        <tbody>
          <tr>
            <td>
              <div className="video-container">
                <video controls className="vid-style">
                  <source
                    src="https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support HTML5 video.
                </video>
              </div>
              <Extend />
            </td>
            <td>
              <div className="panel-container">
                <br />
                <Typography variant="h4" gutterBottom>
                  Gallery
                </Typography>
                <Grid container spacing={3} justify="center">
                  {pictures.map((picture) => {
                    return (
                      <Grid item>
                        <img
                          src={picture.src}
                          className="gallery-images"
                          alt={picture.alt}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <br />
                <Typography variant="h4" gutterBottom>
                  Testimonials
                </Typography>
                {reviews.map((review) => {
                  return (
                    <>
                      <Typography color="primary" variant="h6">
                        {review.review}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="h6"
                        gutterBottom
                      >
                        - {review.person}
                      </Typography>
                    </>
                  );
                })}
                <br />
                <Typography variant="h4" gutterBottom>
                  Where to next?
                </Typography>
                {buttons.map((button) => {
                  return (
                    <>
                      <button className="tour-button">{button.name}</button>
                      <br />
                    </>
                  );
                })}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
