import React, { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import classes from './ManageMagnet.module.css';

const MagnetScreenItem = ({
  the_screen,
  name,
  video,
  screen_id,
  id,
  key,
  addVideo,
  removeVideo,
  selectedVideos,
  encoding = false,
  dragItem,
  index,
  category,
  openMove,
  analyticsdata,
  screen_title,
}) => {
  const { encodingQ, loadScreen, currentVideo } = useSelector(
    (state) => state.magnetStates
  );
  const { template } = useSelector((state) => state.getMagnet);

  const [cata, screen] = screen_id.split('.');
  const vidRef = useRef(null);
  const isplaying = useRef(false);
  // const { data } = props;
  const [hover, setHover] = useState(false);
  const [Imghover, setImgHover] = useState(false);
  const [checked, setChecked] = useState(false);
  const [links, setLinks] = useState(false);
  const _dispatch = useDispatch();

  useEffect(() => {
    document.body.addEventListener('click', () => {
      setLinks(false);
    });
  }, []);

  const handlePlayVideo = () => {
    if (isplaying.current == false) {
      //isplaying.current = true
      let playPromise = vidRef.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
            isplaying.current = true;
            vidRef.current.play();
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
            console.log(error);
          });
      }
    }
  };

  const handlePauseVideo = () => {
    if (isplaying.current == true) {
      isplaying.current = false;
      vidRef.current.pause();
    }
  };

  if (window?.location?.hash.split('#')[1] == screen_id) {
    _dispatch({
      type: 'SCREEN_TO_EDIT',
      payload: screen_id,
    });
  }

  const [{ isDragging }, drag] = useDrag(() => ({
    type: `MagnetScreenItem-${category}`,
    item: () => {
      return { screen_id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [{ isOver }, drop] = useDrop(() => ({
    accept: `MagnetScreenItem-${category}`,
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    // hover(item, monitor) {
    //   //console.log('hover_called')
    //   if (!ref.current) {
    //     return;
    //   }
    //   const dragIndex = item.index;
    //   const hoverIndex = index;
    //   if (dragIndex === hoverIndex) {
    //     return;
    //   }
    //   console.log('hover_item',item)
    //   console.log('hover_index',index)
    //   // const hoverBoundingRect = ref.current?.getBoundingClientRect();
    //   // vertical middle of MagnetScreenItem being hovered over
    //   // const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    //   // horizontal middle of MagnetScreenItem being hovered over
    //   // const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
    //   // current mouse position
    //   // const clientOffset = monitor.getClientOffset();
    //   // original mouse position
    //   // const clientOffsetInitial = monitor.getInitialClientOffset();
    //   // mouse position top
    //   // const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    //   // mouse position left
    //   // const hoverClientX = clientOffset.x - hoverBoundingRect.left;
    //   dragItem(dragIndex, hoverIndex,monitor);
    //   item.index = hoverIndex;
    // },
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      dragItem(dragIndex, hoverIndex, monitor);
      item.index = hoverIndex;
    },
  }));

  const ref = useRef(null);

  drag(drop(ref));

  return (
    <button
      className="cleanbtn sumary-row-item rel"
      ref={ref}
      style={{
        opacity: isOver ? 0 : 1,
        backgroundColor:
          selectedVideos.includes(screen_id) && !openMove ? '#F0F4FD' : '',
        border: openMove
          ? '1px dashed #3898EC'
          : selectedVideos.includes(screen_id)
          ? '1px solid #3898EC'
          : '',
      }}
      // onMouseOver={() => {
      //   if (!openMove) setHover(true);
      // }}
      // onMouseOut={() => setHover(false)}
    >
      <div className="item flex flex-col aic anim">
        {encodingQ.includes(screen_id) ? (
          <div
            // onClick={() => {
            //   _dispatch({
            //     type: 'SCREEN_TO_EDIT',
            //     payload: screen_id,
            //   });
            // }}
            className="item-video"
          >
            <div className={classes.loader}></div>
            <span className={classes.loadingText}>
              {loadScreen.map((item) => {
                if (item.name == currentVideo) {
                  return (
                    <span className={classes.loadingText}>
                      {item.progress ? item.progress : 0}%
                    </span>
                  );
                }
              })}
            </span>
          </div>
        ) : (
          <div
            key={screen_id}
            onClick={() => {
              if (selectedVideos.length || openMove) {
                const val = !checked;
                if (val === true) {
                  addVideo(screen_id);
                }
                if (val === false) {
                  removeVideo(screen_id);
                }
                setChecked(val);
                return;
              }
              _dispatch({
                type: 'SCREEN_TO_EDIT',
                payload: screen_id,
              });
              window.history.pushState(
                {},
                `View ${screen_id}`,
                `#${screen_id}`
              );
            }}
            style={{
              cursor: !openMove ? 'pointer' : isDragging ? 'grabbing' : 'grab',
            }}
          >
            {/* {the_screen?.img && the_screen.gif &&  ( */}
            <img
              onMouseEnter={() => {
                if (the_screen?.gif) setImgHover(true);
              }}
              onMouseLeave={() => setImgHover(false)}
              src={Imghover ? the_screen?.gif : the_screen?.img}
              className="item-video"
              //alt="loading..."
            />
            {/* ) 
            // : (
            //   <video
            //     ref={vidRef}
            //     className="item-video"
            //     muted
            //     loop
            //     onMouseEnter={handlePlayVideo}
            //     onMouseLeave={handlePauseVideo}
            //     id={id}
            //     autoPlay
            //     onCanPlay={() => {
            //       setTimeout(function () {
            //         if (vidRef?.current) {
            //           vidRef.current.pause();
            //           vidRef.current.currentTime = 2;
            //         }
            //       }, 2000);
            //     }}
            //   >
            //     <source
            //       src={video}
            //       // src="https://media.videoask.com/transcoded/374292ae-e958-49d8-807d-06e29b827139/video.mp4"
            //       type="video/mp4"
            //     />
            //     Your browser does not support HTML5 Video.
            //   </video>
            // ) */}
            {/* } */}
          </div>
        )}
        {the_screen?.iframe?.enabled ? (
          <img
            className="puse-ico abs z-100"
            src={require('../../assets/videos/website-icon-TYG.svg').default}
          />
        ) : (
          <img
            className="puse-ico abs"
            src={require('../../assets/videos/play-icon.svg').default}
          />
        )}

        <input
          type="checkbox"
          className={`item-checkbox ${
            (selectedVideos.includes(screen_id) || hover) && !openMove
              ? 'sho'
              : 'hid'
          }`}
          checked={selectedVideos.includes(screen_id)}
          name={name}
          onChange={() => {
            const val = !checked;
            if (val === true) {
              addVideo(screen_id);
            }
            if (val === false) {
              removeVideo(screen_id);
            }
            setChecked(val);
          }}
        />
      </div>
      <div className="ftr rel flex">
        <div className="lbl  font s15 b5 c333">{name}</div>
        <div
          className="moveScreenHandle abs"
          style={{
            cursor: isDragging ? 'grabbing' : 'grab',
            display: hover ? 'block' : 'block',
          }}
        >
          <img
            className="drag-ico"
            src={require('../../assets/videos/drag-icon.svg').default}
          />
        </div>
        <button
          className="cleanbtn link-button icon-link black"
          onClick={() => setLinks(!links)}
        />
      </div>
      {analyticsdata?.[screen_title]?.count && (
        <div className="views font">
          <div className="ico icon-play" />
          {`${analyticsdata?.[screen_title]?.count}`}
        </div>
      )}
      {the_screen?.iframe?.enabled && <> &#60; / &#62; </>}

      <div className={`links-btns flex flex-col ${links ? 'sho' : 'hid'}`}>
        {template?.categories[cata]?.screens[screen]?.links &&
          template?.categories[cata]?.screens[screen]?.links?.map(
            (item, idx) => (
              <div
                key={item.id}
                className="link flex aic"
                onClick={() => {
                  _dispatch({
                    type: 'SCREEN_TO_EDIT',
                    payload: item.route,
                  });
                  window.history.pushState(
                    {},
                    `View ${item.route}`,
                    `#${item.route}`
                  );
                }}
              >
                <div className="link-ico icon-link cfff" />
                <div className="link-txt font s14 cfff wordwrap">
                  {item.name}
                </div>
              </div>
            )
          )}
      </div>
    </button>
  );
};
export default MagnetScreenItem;
