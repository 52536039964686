export const listCommunities = (state = { data: {} }, action) => {
  switch (action.type) {
    case 'COMMUNITIES_LIST_REQUEST':
      return { communityLoading: true, data: {} };
    case 'COMMUNITIES_LIST_SUCCESS':
      return { communityLoading: false, data: action.payload };
    case 'COMMUNITIES_LIST_FAIL':
      return { communityLoading: false, callError: action.payload };
    default:
      return state;
  }
};

export const listCommunityDetial = (
  state = {
    data: [],
  },
  action
) => {
  switch (action.type) {
    case 'COMMUNITY_DETAIL_REQUEST':
      return { loading: true, data: [] };
    case 'COMMUNITY_DETAIL_SUCCESS':
      return { loading: false, data: action.payload };
    case 'COMMUNITY_DETAIL_FAIL':
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
